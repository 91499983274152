import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import ItmMgr from '../../../../gui/ItmMgr';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import { SORT_ARROW_WIDTH } from '../../parts/XtwCol';

export default class ColumnHeaderUiHelper extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get newIconSpan() {
		const iconSpan = document.createElement( 'span' );
		iconSpan.className = 'xtwcolmi';
		if ( iconSpan.dataset instanceof DOMStringMap ) {
			iconSpan.dataset.content = "main icon";
		}
		return iconSpan;
	}

	get newTextSpan() {
		const itemManager = ItmMgr.getInst();
		const textSpan = document.createElement( 'span' );
		textSpan.className = 'xtwcoltx';
		itemManager.setTxtOvrFlw( textSpan, true );
		if ( textSpan.dataset instanceof DOMStringMap ) {
			textSpan.dataset.content = "text";
		}
		return textSpan;
	}

	get newSecondaryIconSpan() {
		const secondaryIconSpan = document.createElement( 'span' );
		secondaryIconSpan.className = 'xtwcolsi';
		if ( secondaryIconSpan.dataset instanceof DOMStringMap ) {
			secondaryIconSpan.dataset.content = "secondary icon";
		}
		return secondaryIconSpan;
	}

	get newEditingPenIconSpan() {
		const editingPenIconSpan = document.createElement( "span" );
		editingPenIconSpan.className = 'xtwcoled';
		editingPenIconSpan.style.paddingLeft = '2px';
		if ( editingPenIconSpan.dataset instanceof DOMStringMap ) {
			editingPenIconSpan.dataset.content = "editing pen icon";
		}
		return editingPenIconSpan;
	}

	get newSortingArrowSpan() {
		const sortingArrowSpan = document.createElement( "span" );
		sortingArrowSpan.className = 'xtwcolsa';
		if ( sortingArrowSpan.dataset instanceof DOMStringMap ) {
			sortingArrowSpan.dataset.content = "sorting arrow";
		}
		sortingArrowSpan.style.width = '0px';
		sortingArrowSpan.style.maxWidth = '' + SORT_ARROW_WIDTH + 'px';
		sortingArrowSpan.style.fontSize = "14px";
		return sortingArrowSpan;
	}

	get newWidthAdjustmentSpan() {
		const widthAdjustmentSpan = document.createElement( 'span' );
		if ( widthAdjustmentSpan.dataset instanceof DOMStringMap ) {
			widthAdjustmentSpan.dataset.content = "width adjustment";
		}
		widthAdjustmentSpan.className = 'xtwcolwa';
		widthAdjustmentSpan.innerHTML = '&nbsp;';
		return widthAdjustmentSpan;
	}

	get newPenIcon() {
		const wrapper = document.createElement('span');
		wrapper.className = 'xtwpenwrapper';
		wrapper.style.paddingLeft = '0';
		const italicTag = window.document.createElement( "i" );
		italicTag.classList.add( "fal", "fa-pen" );
		italicTag.style.fontSize = "10px";
		wrapper.appendChild(italicTag);
		return wrapper;
	}

	get newSelectAllSpan() {
		const selectAllSpan = window.document.createElement( "span" );
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onSelectAllSpanClick",
			callBackPrefix: "SelectAllSpan",
			element: selectAllSpan,
			useCapture: false
		} );
		const mouseDownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousedown",
			functionName: "onSelectAllSpanMouseDown",
			callBackPrefix: "SelectAllSpan",
			element: selectAllSpan,
			useCapture: false
		} );
		const mouseUpListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "onSelectAllSpanMouseUp",
			callBackPrefix: "SelectAllSpan",
			element: selectAllSpan,
			useCapture: false
		} );
		return selectAllSpan;
	}

	get newSelectColumnBorderSpan() {
		const span = window.document.createElement( 'span' );
		span.className = 'xtwselectborder';
		// span.style.height = '50%';	// we *must* set this here, the rule provided by the class gets overruled by other CSS classes; keep in sync. with "spa.xtwcolwa"
		return span;
	}

	get xtwBody() {
		return Validator.isObjectPath( this.xthWdg, "xthWdg.xtwBody" ) ?
			this.xthWdg.xtwBody : void 0;
	}

	get selectionManager() {
		const xtwBody = this.xtwBody;
		return Validator.isObjectPath( xtwBody, "xtwBody.selectionManager" ) ?
			xtwBody.selectionManager : void 0;
	}

	get firstSelectedXRowItem() {
		const xtwBody = this.xtwBody;
		return Validator.isObject( xtwBody ) && "firstSelectedXRowItem" in xtwBody ?
			xtwBody.firstSelectedXRowItem : void 0;
	}

	get firstVisibleXRowItem() {
		const xtwBody = this.xtwBody;
		return Validator.isObject( xtwBody ) && "firstVisibleXRowItem" in xtwBody ?
			xtwBody.firstVisibleXRowItem : void 0;
	}

	onSelectAllSpanMouseDown( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onSelectAllSpanMouseUp( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onSelectAllSpanClick( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		if ( selectionManager.noSelectionAllowed ||
			selectionManager.selectionIsForcedSingle ) {
			return false;
		}
		const currentRowSelection = !Validator.isSet( selectionManager.currentRowSelection ) ?
			new Set() : new Set( selectionManager.currentRowSelection );
		const selectionSize = currentRowSelection.size;
		if ( selectionManager.selectionIsCommon ) {
			return selectionSize > 0 ? this.deselectAll() : this.selectAll();
		}
		if ( selectionManager.selectionIsSingle ) {
			return selectionSize > 0 ? this.deselectAll() : this.selectOne();
		}
		if ( selectionManager.selectionIsForced ) {
			return selectionSize > 1 ? this.selectOne() : this.selectAll();
		}
		return false;
	}

	deselectAll() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.selectRowsList ) ) {
			return false;
		}
		selectionManager.selectRowsList( new Set(), selectionManager.activeRow, );
		return selectionManager.informAboutRowSelection();
	}

	selectAll() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.selectAllModelItems ) ) {
			return false;
		}
		return selectionManager.selectAllModelItems( selectionManager.activeRow, true );
	}

	selectOne() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		const focusRow = selectionManager.focusRow;
		if ( Validator.isObject( focusRow ) &&
			selectionManager.selectRowsList( focusRow.rowId, focusRow ) ) {
			return selectionManager.informAboutRowSelection();
		}
		const firstVisibleSelectedRow = this.firstSelectedXRowItem;
		if ( Validator.isObject( firstVisibleSelectedRow ) &&
			selectionManager.selectRowsList( firstVisibleSelectedRow.rowId, firstVisibleSelectedRow ) ) {
			return selectionManager.informAboutRowSelection();
		}
		const firstVisibleRow = this.firstVisibleXRowItem;
		if ( Validator.isObject( firstVisibleRow ) &&
			selectionManager.selectRowsList( firstVisibleRow.rowId, firstVisibleRow ) ) {
			return selectionManager.informAboutRowSelection();
		}
		return false;
	}

}
