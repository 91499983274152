import MBase from './MBase';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';

/**
 * abstract row item class
 * @abstract
 */
export default class MRowItem extends MBase {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( json ) {
		super();
		const idt = json.idg || 0; // --- !!! yes, json.idg !!! ---
		const idr = json.idr || 0;
		const xid = json.xid || 0;
		Object.defineProperty( this, "idt", { value: idt, writable: false, configurable: false } );
		Object.defineProperty( this, "idr", { value: idr, writable: false, configurable: false } );
		Object.defineProperty( this, "xid", { value: xid, writable: false, configurable: false } );
		if ( this.idr !== this.xid ) {
			// that's not (longer) allowed!
			throw new Error( 'Different row ID values are not allowed!' );
		}
		// this.height = json.height || 0;
		this._height = json.height || 0;
		// this.ovrHeight = null;
		this._ovrHeight = null;
		this.vPadding = 0;
		this.present = false;
		this.top = 0;
		this._flatIndex = -1;
		this._isFocused = false;
		this._isSelected = !!json.select;
		this._selectionFrozen = false;
	}

	set height( newValue ) {
		this._height = newValue;
	}

	set ovrHeight( newValue ) {
		this._ovrHeight = newValue;
	}

	get ovrHeight() {
		return this._ovrHeight;
	}

	get height() {
		return this._height;
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		delete this.present;
		delete this._ovrHeight;
		delete this._height;
		delete this.vPadding;
		delete this.top;
		delete this._isFocused;
		delete this._isSelected;
		delete this._selectionFrozen;
	}

	/**
	 * blocks selection and focus status from being set/updated
	 */
	freezeSelection() {
		this._selectionFrozen = true;
	}

	/**
	 * allows updates of selection and focus status
	 */
	unfreezeSelection() {
		this._selectionFrozen = false;
	}

	/**
	 * @returns {Number} the index of this item in the flat model
	 */
	get flatIndex() {
		return this._flatIndex;
	}

	/**
	 * gets whether the selection and focus status of this item can be
	 * updated/set or not (it specifically refers to the properties
	 * "_isSelected" and "_isFocused"); this is directly managed by the flag
	 * "_selectionFrozen"
	 * @return {Boolean} true if selection & focus state updates are allowed,
	 * false otherwise
	 */
	get selectionFrozen() {
		return !!this._selectionFrozen;
	}

	/**
	 * gets whether this item is selected or not through the "_isSelected" status
	 * @return {Boolean} true if item selected, false otherwise
	 */
	get isSelected() {
		return !!this._isSelected;
	}

	/**
	 * sets the status of the "_isSelected" property only if the updating of the
	 * "_isSelected" state is not frozen/blocked
	 */
	set isSelected( newValue ) {
		if ( this.selectionFrozen || !Validator.isBoolean( newValue ) ) {
			return;
		}
		this._isSelected = newValue;
	}

	syncSelect() {}

	syncDeselect() {}

	/**
	 * gets whether this item is focused or not through the "_isFocused" status
	 * @return {Boolean} true if item focused, false otherwise
	 */
	get isFocused() {
		return !!this._isFocused;
	}

	/**
	 * sets the status of the "_isFocused" property only if the updating of the
	 * "_isFocused" state is not frozen/blocked
	 */
	set isFocused( newValue ) {
		if ( this.selectionFrozen || !Validator.isBoolean( newValue ) ) {
			return;
		}
		this._isFocused = newValue;
	}

	syncFocus() {}

	syncUnfocus() {}

	/**
	 * indicates whether this row item is "present", i.e. whether it has received data from web server
	 * @returns {Boolean} true if the row item valid data; false otherwise
	 */
	isPresent() {
		return this.present;
	}

	/**
	 * @returns {Number} the row ID
	 */
	getRowID() {
		return this.idr;
	}

	/**
	 * @returns {Number} the group ID of the target group to which this item belongs
	 */
	getTgtID() {
		return this.idt;
	}

	/**
	 * @returns {Number} the extra ID
	 */
	getExtraID() {
		return this.xid;
	}

	/**
	 * @returns {Boolean} true if this item is a group head item; false otherwise
	 */
	isGroupHead() {
		return false;
	}

	/**
	 * @returns {Boolean} true if this item is a data row; false otherwise
	 */
	isDataRow() {
		return false;
	}

	/**
	 * @returns {Boolean} true if this item is the "dummy" row; false otherwise
	 */
	isDummyRow() {
		return false;
	}

	/**
	 * indicates whether this item has child items
	 * @returns {Boolean} true if this item has child items; false otherwise
	 */
	hasChildren() {
		return false;
	}

	/**
	 * @returns {Number} the height in pixels of this item
	 */
	getHeight() {
		return this.ovrHeight !== null ? this.ovrHeight : this.height;
	}

	/**
	 * @returns {Number} the total vertical padding in pixels
	 */
	getVPadding() {
		return this.vPadding;
	}

	/**
	 * sets the "overriden" height property
	 * @param {Number} ovh the overriden height of this item
	 * @param {Number} vpad vertical padding
	 */
	setOvrHeight( ovh, vpad ) {
		if ( ovh ) {
			this.ovrHeight = ovh;
			this.vPadding = vpad;
		} else {
			this.ovrHeight = null;
			this.vPadding = 0;
		}
	}

	/**
	 * @returns {Number} the top coordinate of this item
	 */
	getTop() {
		return this.top;
	}

	/**
	 * sets a new top coordinate
	 * @param {Number} t new top coordinate
	 */
	setTop( t ) {
		this.top = t || 0;
	}

	/**
	 * processes new data sent by the application server
	 * @param {Object} data item's data as provided by the application server
	 */
	setData( data ) {
		// this method does nothing here
	}

	/**
	 * invalidates this item so new data must be requested
	 */
	invalidate() {
		this.present = false;
	}
}
