import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';

export default class MDataRowEditingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		parentObject._edited = false;
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get edited() {
		return !!this._edited;
	}

	set edited( newValue ) {
		this._edited = !!newValue;
		this.syncEditedUi();
	}

	syncEditedUi() {
		const row = this.row;
		if ( !Validator.isObject( row ) ) {
			return false;
		}
		if ( this._edited && Validator.isFunction( row.displayAsEdited ) ) {
			return row.displayAsEdited();
		} else if ( !this._edited && Validator.isFunction( row.displayAsUnedited ) ) {
			return row.displayAsUnedited();
		}
		return false;
	}

}
