import PSA from '../../psa';

/**
 * stops the bubbling of an event
 * @param {Event} evt the event that should not bubble further up in the DOM tree
 */
function stopBubbling(evt) {
	evt.stopPropagation();
}

/**
 * adds the required "stop bubbling" listeners to make the editor work...
 * @param {HTMLElement} cnt the container element
 */
function ace_setupContainter(cnt) {
	cnt.addEventListener('mousedown', stopBubbling);
	cnt.addEventListener('mouseup', stopBubbling);
	cnt.addEventListener('mouseover', stopBubbling);
	cnt.addEventListener('wheel', stopBubbling);
	cnt.addEventListener('selectstart', stopBubbling);
	cnt.addEventListener('selectionchange', stopBubbling);
	cnt.addEventListener('dragstart', stopBubbling);
	cnt.addEventListener('dragover', stopBubbling);
	cnt.addEventListener('keydown', stopBubbling);
	cnt.addEventListener('keyup', stopBubbling);
	cnt.addEventListener('keypress', stopBubbling);
}

// an instance counter of 
let ACE_HTML_CNT = 0;

/**
 * code editor hosting class
 */
export default class CodEdt {
	constructor(properties) {
		this._psa = PSA.getInst();
		// bind event handlers
		this._psa.bindAll(this, [ "layout", "onReady", "onRender" ]);
		// default initialization
		this.wdgReady = false;
		this.edtReady = false;
		this.editor = null;
		this.iniPar = {};
		this.inUpdate = false;
		this.hasNotified = false;
		this.isDirty = false;
		this.btfMod = null;
		// setup
		const idp = properties.parent;
		this.parent = rap.getObject(idp);

		let elm = document.createElement('div');
		elm.id = 'acecnt_' + idp;
		elm.className = 'acecontainer';
		elm.style.position = 'absolute';
		ace_setupContainter(elm);
		let hst = document.createElement('pre');	// this element is "eaten" by ACE
		hst.id = 'aceedt_' + idp;
		hst.className = 'aceeditor';
		elm.appendChild(hst);

		this.parent.append(elm);
		this.element = elm;
		this.edthost = hst;

		// final initialization
		let cwd = this.parent.getData("pisasales.CSTPRP.CWD") || {};
		this._init(cwd);
		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
	}

	destroy() {
		this.btfMod = null;
		this.editor = null;
		if ( this.edthost ) {
			const hst = this.edthost;
			this.edthost = null;
			if ( hst && hst.parentElement ) {
				hst.parentElement.removeChild(hst);
			}
		}
		this.wdgReady = false;
		this.edtReady = false;
		delete this.btfMod;
		delete this.edthost;
		delete this.editor;
		delete this.iniPar;
	}

	onReady() {
		this.wdgReady = true;
	}

	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
			this.layout();
			// now's the time to create the editor instance
			this._creAce();
		}
	}

	layout() {
		// everything is done by CSS styles so far
		if ( false && this.element ) {
			const area = this.parent.getClientArea();
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			const wdt = area[2];
			const hgt = Math.max(area[3] - 1, 0);
			this.element.style.width = wdt + 'px';
			this.element.style.height = hgt + 'px';
			if ( this.editor ) {
				const cnt = this.editor.edthost;
				if ( cnt ) {
					cnt.style.left = '0px';
					cnt.style.top = '0px';
					cnt.style.width = wdt + 'px';
					cnt.style.height = hgt + 'px';
				}
			}
		}
	}

	_isRdy() {
		return this.wdgReady && this.edtReady;
	}

	_init(cwd) {
		let btf = ace.require('ace/ext/beautify');
		if ( !btf ) {
			console.warn("Failed to load beautifier module!");
		}
		this.iniPar.type = cwd.type || '';
	}
	
	_creAce() {
		if ( this.element && this.edthost ) {
			// create ACE editor instance
			const type = this.iniPar.type || '';
			try {
				const editor = ace.edit(this.edthost);
				let theme = 'tomorrow';
				let mode = '';
				let opts = {
					showLineNumbers: true,
					showFoldWidgets: true,
					showGutter: true,
					displayIndentGuides: true
				};
				switch ( type ) {
				case 'JAVA':
					mode = 'java';
					theme = 'eclipse';
					break;
				case 'HTML':
					mode = 'html';
					break;
				case 'JS':
					mode = 'javascript';
					break;
				case 'JSON':
					mode = 'json';
					break;
				case 'XML':
					mode = 'xml';
					break;
				default:
					opts.showLineNumbers = false;
					opts.showFoldWidgets = false;
					opts.showGutter = false;
					opts.displayIndentGuides = false;
					break;
				}
				const eds = editor.session;
				eds.setUseWorker(false);
				eds.setTabSize(3);
				eds.setUseSoftTabs(true);
				editor.setTheme('ace/theme/' + theme);
				if ( mode ) {
					eds.setMode('ace/mode/' + mode);
				}
				editor.setOptions(opts);
				if ( mode === 'html' ) {
					let btf = ace.require('ace/ext/beautify');
					if ( btf ) {
						this.btfMod = btf;
					}
				}
				this.editor = editor;
				this.edtReady = true;
				this.layout();
				if ( this._psa.isStr(this.iniPar.ctt) ) {
					const ctt = this.iniPar.ctt;
					this.iniPar.ctt = null;
					this.setCtt(ctt);
				}
				const scope = this;
				editor.on('change', (e) => {
					scope._onChange();
				});
				editor.on('focus', () => {
					scope._onFocus(true);
				});
				editor.on('blur', () => {
					scope._onFocus(false);
				});
			}
			catch ( err ) {
				console.error(err);
			}
		}
	}

	_beautify() {
		if ( this._isRdy() ) {
			const upd = this.inUpdate;
			try {
				this.inUpdate = true;
				if ( this.btfMod ) {
					this.btfMod.beautify(this.editor.session);
				}
			}
			finally {
				this.inUpdate = upd;
			}
		}
	}

	setCtt(args) {
		const upd = this.inUpdate;
		try {
			this.inUpdate = true;
			const ctt = args || '';
			if ( this.editor ) {
				const eds = this.editor.session;
				eds.getDocument().setValue(ctt);
				if ( this.btfMod ) {
					const self = this;
					if ( ACE_HTML_CNT === 0 ) {
						// the very first time we must go an extra cycle
						const par = {};
						par.cnt = ACE_HTML_CNT;
						this._nfySrv('aceBeautify', par);
					} else {
						self._beautify();
					}
					++ACE_HTML_CNT;
				}
			} else {
				this.iniPar.ctt = ctt;
			}
		}
		finally {
			this.inUpdate = upd;
		}
	}

	setTxtFnt(args) {

	}

	insCtt(args) {

	}

	setFocus() {
		if ( this.editor ) {
			this.editor.focus();
		}
	}

	doBeautify() {
		this._beautify();
	}

	setChgMnr(args) {
		let mnr = !!args;
		this.hasNotified = !mnr;
	}

	_setCttPar(par) {
		if ( this.editor) {
			par.ctt = this.editor.session.getDocument().getValue();
		}
	}

	_nfySrv(code, par) {
		if ( this.wdgReady ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify("CODEDT_NFY", param);
		}
	}

	_onChange() {
		if ( !this.inUpdate ) {
			this.isDirty = true;
			if ( !this.hasNotified ) {
				let par = {};
				this._setCttPar(par);
				this._nfySrv('changed', par);
				this.isDirty = false;
				this.hasNotified = true;
			}
		}
	}

	_onFocus(foc) {
		let rsd = false;
		let par = {};
		par.focusOnEditor = foc;
		if ( !foc && this.isDirty ) {
			this._setCttPar(par);
			rsd = true;
		}
		this._nfySrv('focusChanged', par);
		if ( rsd ) {
			this.isDirty = false;
		}
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget CodEdt.');
		rap.registerTypeHandler("psawidget.CodEdt", {
			factory : function(properties) {
				return new CodEdt(properties);
			},
			destructor: "destroy",
			properties: [ "ctt", "txtFnt", "chgMgr" ],
			methods: [ "insCtt", "setFocus", "doBeautify" ],
			events: ["CODEDT_NFY"]
		});
	}
}

console.log('widgets/codedt/CodEdt.js loaded.');