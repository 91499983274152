import KeyHdl from './KeyHdl';
import PSA from '../psa';

/**
 * drag'n'drop watch class
 */
export default class DndWtc {

	/**
	 * constructs a new instance
	 * @param {PSA} psa the global PSA instance
	 * @param {KeyHdl} khd key handler instance
	 * @param {Boolean} dom flag whether to handle DOM events
	 */
	constructor(psa, khd, dom) {
		this._psa = psa;
		this.keyHdl = khd;
		this.lstBlk = false;
		this.domEvt = !!dom;
		this.drgBeg = this._psa.bind(this, this._dragBegin);
		this.drgEnd = this._psa.bind(this, this._dragEnd);
		this._attachAll(true);
	}

	/**
	 * destructor method
	 */
	destroy() {
		this._attachAll(false);
		delete this.keyHdl;
		delete this.drgBeg;
		delete this.drgEnd;
		delete this.lstBlk;
	}

	/**
	 * attaches or removes DOM listeners
	 * @param {String[]} evts event names
	 * @param {Function} lsr listener to be attached or removed
	 * @param {Boolean} add flag whether to add (attach) or remove event listener
	 */
	_attachDomLsr(evts, lsr, add) {
		const elm = window.document.body;
		evts.forEach( (n) => {
			if ( add ) {
				elm.addEventListener(n, lsr, true);		// in capturing phase!
			} else {
				elm.removeEventListener(n, lsr);
			}
		});
	}

	/**
	 * attaches or removes RAP listeners
	 * @param {Boolean} add flag whether to add (attach) or remove event listener
	 */
	_attachRapLsr(add) {
		const rap_hdl = rwt.event.DragAndDropHandler.getInstance();
		if ( rap_hdl ) {
			const self = this;
			if ( add ) {
				if ( !rap_hdl._org_start ) {
					const org_start = rap_hdl.startDrag;
					if ( typeof org_start === 'function' ) {
						rap_hdl._org_start = org_start;
						rap_hdl.startDrag = function() {
							self._dragBegin({type:'syndragstart'});
							rap_hdl._org_start();
						};
					}
				}
				if ( !rap_hdl._org_end ) {
					const org_end = rap_hdl._endDragCore;
					if ( typeof org_end === 'function' ) {
						rap_hdl._org_end = org_end;
						rap_hdl._endDragCore = function() {
							self._dragEnd({type:'syndragend'});
							rap_hdl._org_end();
						};
					}
				}
			} else {
				if ( typeof rap_hdl._org_start === 'function' ) {
					rap_hdl.startDrag = rap_hdl._org_start;
					delete rap_hdl._org_start;
				}
				if ( typeof rap_hdl._org_end === 'function' ) {
					rap_hdl._endDragCore = rap_hdl._org_end;
					delete rap_hdl._org_end;
				}
			}
		}
	}

	/**
	 * attaches or removes all event listeners
	 * @param {Boolean} add flag whether to add (attach) or remove event listeners
	 */
	_attachAll(add) {
		this._attachRapLsr(add);
		if ( this.domEvt ) {
			this._attachDomLsr(DRG_BEG_EVT, this.drgBeg, add);
			this._attachDomLsr(DRG_END_EVT, this.drgEnd, add);
		}
	}

	_dragBegin(e) {
		console.log('DND WTC (BEG): ' + e.type);
		this.lstBlk = this.keyHdl.isBlocked();
		this.keyHdl.blockGlobalKeyHandling(true);
	}

	_dragEnd(e) {
		try {
			console.log('DND WTC (END): ' + e.type);
			this.keyHdl.blockGlobalKeyHandling(this.lstBlk);
		} finally {
			this.lstBlk = false;
		}
	}
}

console.log('key/DntWtc.js loaded.');