import Base from '../base/base';
import ObjReg from './ObjReg';
import Validator from './Validator';
import PSA from '../psa';

/**
 * a function request holder class that may be used to collect deferred update request
 */
export default class RequestHolder extends Base {

    /**
     * constructs a new instance
     * @param {Boolean} own flag whether to install an own RAP "render" listener
     */
    constructor(own) {
        super();
        Object.defineProperty(this, 'own', { value: own, configurable: false, enumerable: true, writable: false})
        this._unrcount = 0;
        this._requests = new ObjReg();
        if ( this.own ) {
            this.onRender = PSA.getInst().bind(this, this._onRender);
            rap.on('render', this.onRender);
        }
    }

    /**
     * destructor method
     * @override
     */
    doDestroy() {
        if ( this.alive && this.own ) {
            rap.off('render', this.onRender);
            delete this.onRender;
        }
        if ( this._requests ) {
            this._requests.destroy();
            delete this._requests;
        }
        super.doDestroy();
    }

    /**
     * adds a named request
     * @param {String} name request name
     * @param {Function} func the request function
     */
    addRequest(name, func) {
        if ( this.alive && Validator.isString(name) && Validator.isFunction(func) ) {
            this._requests.addObj(name, func);
        }
    }

    /**
     * adds an anonymous request
     * @param {Function} func the request function
     */
    addAnonRequest(func) {
        const name = `anon-r-${++this._unrcount}`;
        this.addRequest(name, func);
    }

    /**
     * checks, wtether is request with a specific name
     * @param {String} name request name
     * @returns {Boolean} true if there's a request with the specified name; false otherwise
     */
    hasRequest(name) {
        if ( !this.alive ) {
            return false;
        }
        return this._requests.hasObj(name);
    }

    /**
     * clears all pending _requests
     */
    clear() {
        if ( this.alive ) {
            this._requests.clear();
        }
    }

    /**
     * runs all _requests and clears the request container
     */
    runAll() {
        if ( !this.alive ) {
            return;
        }
        const rqu = this._requests;
        if ( !rqu.isEmpty() ) {
            try {
                rqu.forEach((func) => {
                    // just call the request function
                    func.call();
                });
            } finally {
                // drop all _requests
                rqu.clear();
            }
        }
    }

    /**
     * RAP 'render' listener
     */
    _onRender() {
        if ( this.alive ) {
            this.runAll();
        }
    }
}