import FocusHolder from '../../../../gui/FocusHolder';
import BscMgr from '../../../../gui/BscMgr';
import Validator from '../../../../utils/Validator';
import XtwBody from '../../XtwBody';

const INPUT_NOTIFICATION = "textInput";
const INPUT_EDITING_EVENT = "edit";
const INPUT_SAVE_EVENT = "save";
const INPUT_FULL_SAVE_EVENT = "fullSave";
const INPUT_CANCEL_EVENT = "cancel";

let id_counter = 0;

export default class EditableElement extends FocusHolder {

	constructor( cellObject ) {
		super();
		this.cell = cellObject;
		this._dirty = false;
		this._editingAllowed = true;
		const ids = `XTW-Editable-#${++id_counter}`;
		Object.defineProperties( this, {
			instanceID: {
				value: ids,
				writable: false,
				configurable: false
			}
		});
	}

	register() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) ||
			!Validator.isFunction( xtwBody._addCellToEditingRegistry ) ) {
			return false;
		}
		return xtwBody._addCellToEditingRegistry( {
			rowId: this.rowId,
			columnId: this.columnId,
			inputId: this.inputId
		} );
	}

	get lastlyRegistered() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) ||
			!( "lastEditedCellCoordinates" in xtwBody ) ) {
			return false;
		}
		const lastEditedCellCoordinates = xtwBody.lastEditedCellCoordinates;
		return Validator.isObject( lastEditedCellCoordinates ) &&
			lastEditedCellCoordinates.columnId === this.columnId &&
			lastEditedCellCoordinates.rowId === this.rowId &&
			lastEditedCellCoordinates.inputId == this.inputId;
	}

	render() {
		console.debug(`Rendering editable element "${this.instanceID}"...`);
		BscMgr.getInstance().setFocusHolder(this);
		this.xtwBody.setFocusHolder(this);
	}

	destroySelf() {}

	destroySelfAndRestoreCell() {
		const cell = this.cell;
		this.destroySelf();
		if ( Validator.isObject( cell ) &&
			cell.alnElm instanceof HTMLElement &&
			cell.cttElm instanceof HTMLElement ) {
			cell.alnElm.appendChild( cell.cttElm );
		}
		return true;
	}

	get newContainer() {
		const container = window.document.createElement( "div" );
		container.classList.add( "rtp-input-container" );
		return container;
	}

	get dirty() {
		return !!this._dirty;
	}

	set dirty( newValue ) {
		if ( Validator.isBoolean( newValue ) ) {
			this._dirty = !!newValue;
		}
	}

	get editingAllowed() {
		return !!this._editingAllowed;
	}

	set editingAllowed( newValue ) {
		if ( Validator.isBoolean( newValue ) ) {
			this._editingAllowed = !!newValue;
		}
		if ( this._editingAllowed ) {
			this.register();
		}
	}

	get idc() {
		const cell = this.cell;
		return Validator.isObject( cell ) &&
			Validator.isPositiveInteger( cell.idc ) ? Number( cell.idc ) : void 0;
	}

	get columnId() {
		return this.idc;
	}

	get column() {
		const cell = this.cell;
		return Validator.isObjectPath( cell, "cell.column" ) ? cell.column : null;
	}

	get element() {
		return this.container;
	}

	get cellElement() {
		if ( !Validator.isObject( this.cell ) ) {
			return null;
		}
		const alignmentElement = this.cell.alnElm
		return alignmentElement instanceof HTMLElement ? alignmentElement : void 0;
	}

	/**
	 * @returns {XtwBody} the table's body part
	 */
	get xtwBody() {
		return Validator.isObject( this.cell ) && "xtwBody" in this.cell ? this.cell.xtwBody : null;
	}

	get row() {
		return Validator.isObject( this.cell ) && "row" in this.cell ? this.cell.row : null;
	}

	get rowId() {
		const row = this.row;
		return Validator.isObject( this.row ) && "rowId" in this.row ? this.row.rowId : null;
	}

	get isRendered() {
		return this.container instanceof HTMLElement;
	}

	get isReadOnly() {
		if ( !this.editingAllowed ) {
			return true;
		}
		return Validator.isObject( this.cell ) && "isReadOnly" in this.cell ? this.cell.isReadOnly : false;
	}

	get canBeEdited() {
		if ( !this.editingAllowed ) {
			return false;
		}
		return Validator.isObject( this.cell ) && "canBeEdited" in this.cell ? this.cell.canBeEdited : false;
	}

	get shouldBeSkipped() {
		return Validator.isObject( this.cell ) && "shouldBeSkipped" in this.cell ? this.cell.shouldBeSkipped : false;
	}

	get originalValue() {
		return Validator.isObjectPath( this.cell, "cell.ctt" ) && Validator.isString( this.cell.ctt.text ) ? this.cell.ctt.text : '';
	}

	get cellContent() {
		return Validator.isObjectPath( this.cell, "cell.ctt" ) ? this.cell.ctt : null;
	}

	get link() {
		return Validator.isObject( this.cell ) ? !!this.cell.link : false;
	}

	get horizontalAlignment() {
		if ( !Validator.isObject( this.cell ) || !( "horizontalAlignment" in this.cell ) ) {
			return null;
		}
		return this.cell.horizontalAlignment;
	}

	discardUi() {
		console.debug(`Destroying editable element "${this.instanceID}"...`);
		BscMgr.getInstance().removeFocusHolder(this);
		const xtwBody = this.xtwBody;
		if ( xtwBody ) {
			this.xtwBody.removeFocusHolder(this);
		} else {
			console.debug(`Editable element "${this.instanceID}" has no valid cell reference!`);
		}
		const inputDiscarded = this.discardInput();
		const containerDiscarded = this.discardContainer();
		return inputDiscarded && containerDiscarded;
	}

	discardInput() {
		return this._discardElementProperty( "input" );
	}

	discardContainer() {
		return this._discardElementProperty( "container" );
	}

	_discardElementProperty( propertyName ) {
		if ( !Validator.isString( propertyName ) || !( propertyName in this ) ) {
			return false;
		}
		let element = this[ propertyName ];
		this[ propertyName ] = void 0;
		delete this[ propertyName ];
		if ( !( element instanceof HTMLElement ) ) {
			return true;
		}
		element.innerHTML = "";
		element.remove();
		element = void 0;
		return true;
	}

	get rowEdited() {
		const row = this.row;
		return Validator.isObject( row ) && !!row.edited;
	}

	set rowEdited( newValue ) {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "edited" in row ) ) {
			return;
		}
		row.edited = !!newValue;
	}

	informAboutEditing( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_EDITING_EVENT, parameters, !!blockScreenRequest );
	}

	informAboutSave( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_SAVE_EVENT, parameters, !!blockScreenRequest );
	}

	informAboutCancel( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_CANCEL_EVENT, parameters, !!blockScreenRequest );
	}

	informAboutFullSave( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput( INPUT_FULL_SAVE_EVENT, parameters, !!blockScreenRequest );
	}

	informAboutInput( inputBehaviorEvent, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( Validator.isString( inputBehaviorEvent ) ) {
			Object.assign( parameters, { event: inputBehaviorEvent } );
		}
		return this._nfySrv( INPUT_NOTIFICATION, parameters, !!blockScreenRequest );
	}

	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const row = this.row;
		if ( !Validator.isObject( row ) || !Validator.isFunction( row._nfySrv ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		Object.assign( parameters, {
			idc: this.columnId,
			originalValue: this.originalValue,
			userValue: this.inputValue,
			inputId: this.inputId
		} );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

}
