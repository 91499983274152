import AttachmentObject from '../../../../utils/AttachmentObject';
import BscMgr from '../../../../gui/BscMgr';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import Checkbox from './Checkbox';
import DropdownInputField from './DropdownInputField';
import InputField from './InputField';
import EditableElement from './EditableElement';
import Textarea from './Textarea';

const EDITED_CELLS_REGISTRY_MAX_LENGTH = 10;

const DO_LOG = true;

export default class XtwBodyEditingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		Object.defineProperty( parentObject, "editedCellsRegistry", {
			value: [],
			writable: false
		} );
		parentObject._keyEventsFrozen = false;
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get dropdownOpen() {
		if ( !( this.inputField instanceof DropdownInputField ) ) {
			return false;
		}
		return !!this.inputField.dropdownOpen;
	}

	set keyEventsFrozen( newValue ) {
		this._keyEventsFrozen = !!newValue;
	}

	get keyEventsFrozen() {
		return !!this._keyEventsFrozen;
	}

	get firstVisibleColumn() {
		const xtwHead = this.xtwHead;
		return Validator.isObject( xtwHead ) && "firstVisibleColumn" in xtwHead ?
			xtwHead.firstVisibleColumn : void 0;
	}

	get lastVisibleColumn() {
		const xtwHead = this.xtwHead;
		return Validator.isObject( xtwHead ) && "lastVisibleColumn" in xtwHead ?
			xtwHead.lastVisibleColumn : void 0;
	}

	get firstVisibleColumnId() {
		const xtwHead = this.xtwHead;
		return Validator.isObject( xtwHead ) && "firstVisibleColumnId" in xtwHead ?
			xtwHead.firstVisibleColumnId : void 0;
	}

	get lastVisibleColumnId() {
		const xtwHead = this.xtwHead;
		return Validator.isObject( xtwHead ) && "lastVisibleColumnId" in xtwHead ?
			xtwHead.lastVisibleColumnId : void 0;
	}

	freezeKeyEvents() {
		this.keyEventsFrozen = true;
	}

	reviveKeyEvents() {
		this.keyEventsFrozen = false;
	}

	getColumn( columnId ) {
		if ( !Validator.isFunctionPath( this.xtwHead, "xtwHead.getColumn" ) ) {
			return void 0;
		}
		return this.xtwHead.getColumn( columnId );
	}

	getModelItemByRowId( rowId ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return void 0;
		}
		return selectionManager.getModelItemByRowId( rowId );
	}

	get dummyModelItem() {
		if ( !Validator.isObject( this.model ) ||
			!( "dummyModelItem" in this.model ) ) {
			return void 0;
		}
		return this.model.dummyModelItem;
	}

	saveLastEditedCell() {
		if ( this.inputField instanceof EditableElement ) {
			return this.inputField.informAboutSave();
		}
		if ( this.textarea instanceof EditableElement ) {
			return this.textarea.informAboutSave();
		}
		const cell = this.lastEditedCell;
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		if ( cell.inputField instanceof EditableElement ) {
			return cell.inputField.informAboutSave();
		}
		if ( cell.textarea instanceof EditableElement ) {
			return cell.textarea.informAboutSave();
		}
		return false;
	}

	cancelLastEditedCell() {
		if ( this.inputField instanceof EditableElement ) {
			return this.inputField.informAboutCancel();
		}
		if ( this.textarea instanceof EditableElement ) {
			return this.textarea.informAboutCancel();
		}
		const cell = this.lastEditedCell;
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		if ( cell.inputField instanceof EditableElement ) {
			return cell.inputField.informAboutCancel();
		}
		if ( cell.textarea instanceof EditableElement ) {
			return cell.textarea.informAboutCancel();
		}
		return false;
	}

	blurLastEditedCell() {
		if ( this.inputField instanceof InputField ||
			this.inputField instanceof Checkbox ) {
			return Validator.isFunction( this.inputField.onInputBlur ) ?
				this.inputField.onInputBlur() : false;
		}
		if ( this.textarea instanceof Textarea ) {
			return Validator.isFunction( this.textarea.onTextareaBlur ) ?
				this.textarea.onTextareaBlur() : false;
		}
		const cell = this.lastEditedCell;
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		if ( cell.inputField instanceof InputField ) {
			return Validator.isFunction( cell.inputField.onInputBlur ) ?
				cell.inputField.onInputBlur() : false;
		}
		if ( cell.textarea instanceof Textarea ) {
			return Validator.isFunction( cell.textarea.onTextareaBlur ) ?
				cell.textarea.onTextareaBlur() : false;
		}
		if ( cell.checkbox instanceof Checkbox ) {
			return Validator.isFunction( cell.checkbox.onInputBlur ) ?
				cell.checkbox.onInputBlur() : false;
		}
		return false;
	}

	cleanLastEditedCell() {
		const cell = this.lastEditedCell;
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		if ( cell.textarea instanceof EditableElement ) {
			cell.textarea.destroySelfAndRestoreCell();
		}
		if ( cell.inputField instanceof EditableElement ) {
			cell.inputField.destroySelfAndRestoreCell();
		}
		return true;
	}

	cleanFromEditables() {
		if ( this.textarea instanceof EditableElement ) {
			this.textarea.destroySelfAndRestoreCell();
		}
		if ( this.inputField instanceof EditableElement ) {
			this.inputField.destroySelfAndRestoreCell();
		}
		return true;
	}

	getCell( { rowId, columnId } ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return void 0;
		}
		const row = selectionManager.getRow( rowId );
		if ( !Validator.isObjectPath( row, "row.cells" ) ) {
			return void 0;
		}
		if ( Validator.isFunction( row.cells.getItem ) ) {
			const cell = row.cells.getItem( columnId );
			if ( Validator.isObject( cell ) ) {
				return cell;
			}
		}
		if ( Validator.isFunction( row.cells.getObj ) ) {
			const cell = row.cells.getObj( columnId );
			if ( Validator.isObject( cell ) ) {
				return cell;
			}
		}
		return void 0;
	}

	setCellEditingPermission( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			// TODO log
			return false;
		}
		if ( this.textarea instanceof EditableElement &&
			this.textarea.inputId == parameters.inputId ) {
			return this.textarea.setEditingPermission( !!parameters.editingAllowed );
		}
		if ( this.inputField instanceof EditableElement &&
			this.inputField.inputId == parameters.inputId ) {
			return this.inputField.setEditingPermission( !!parameters.editingAllowed );
		}
		return false;
	}

	setCellEditingContent( parameters ) {
		this.setModelItemCellContent( parameters );
		if ( !Validator.isObject( parameters ) ) {
			// TODO log
			return false;
		}
		if ( this.inputField instanceof InputField &&
			this.inputField.inputId === parameters.inputId ) {
			return this.inputField.syncInputContentWithDropdown( parameters );
		}
		const lastEditedCellCoordinates = this.lastEditedCellCoordinates;
		if ( !Validator.isObject( lastEditedCellCoordinates ) ||
			lastEditedCellCoordinates.inputId != parameters.inputId ) {
			Warner.traceIf( DO_LOG, `The input ID of the last edited cell does` +
				` not match with the input ID provided by the server to set a cell's` +
				` content. The content will be set based on the cell's coordinates,` +
				` such as row ID and column ID.` );
			return this.setCoordinatedCellEditingContent( parameters );
		}
		parameters.rowId = lastEditedCellCoordinates.rowId;
		parameters.columnId = lastEditedCellCoordinates.columnId;
		return this.setCoordinatedCellEditingContent( parameters );
	}

	setCoordinatedCellEditingContent( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const cell = this.getCell( {
			rowId: parameters.rowId,
			columnId: parameters.columnId
		} );
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		if ( cell.checkbox instanceof Checkbox ) {
			return cell.checkbox.setCheckedStatus( parameters.content );
		}
		return cell.setContentText( parameters.content );
	}

	setModelItemCellContent( parameters ) {
		if ( !Validator.isObject( parameters ) ||
			typeof parameters.content != "string" ) {
			// TODO log
			return false;
		}
		const modelItem = this.getModelItemByRowId( parameters.rowId );
		if ( !Validator.isFunctionPath( modelItem, "modelItem.getCell" ) ) {
			return false;
		}
		const cell = modelItem.getCell( parameters.columnId );
		if ( !Validator.isObjectPath( cell, "cell.ctt" ) ) {
			return false;
		}
		cell.ctt.text = parameters.content;
		cell.ctt.rawText = parameters.content;
		return true;
	}

	setInputDropdownOpenState( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			// TODO log
			return false;
		}
		if ( this.inputField instanceof DropdownInputField &&
			this.inputField.inputId === parameters.inputId ) {
			return this.inputField.setDropdownOpenState( !!parameters.open );
		}
		const lastEditedCellCoordinates = this.lastEditedCellCoordinates;
		if ( !Validator.isObject( lastEditedCellCoordinates ) ||
			lastEditedCellCoordinates.inputId != parameters.inputId ) {
			// TODO log
			return false;
		}
		const cell = this.getCell( {
			rowId: lastEditedCellCoordinates.rowId,
			columnId: lastEditedCellCoordinates.columnId
		} );
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		if ( cell.inputField instanceof DropdownInputField &&
			cell.inputField.inputId === parameters.inputId ) {
			return cell.inputField.setDropdownOpenState( !!parameters.open );
		}
		return false;
	}

	_addCellToEditingRegistry( { columnId, rowId, inputId } ) {
		if ( !Validator.isArray( this.editedCellsRegistry ) ) {
			this.editedCellsRegistry = [];
		}
		if ( Validator.isString( columnId ) ) {
			columnId = Number( columnId );
		}
		if ( Validator.isString( rowId ) ) {
			rowId = Number( rowId );
		}
		if ( !Validator.isString( inputId ) ) {
			inputId = String( inputId );
		}
		if ( [ columnId, rowId ].some( id => !Validator.isPositiveInteger( id ) ) ) {
			return false;
		}
		const lastEditedCellCoordinates = this.lastEditedCellCoordinates;
		if ( Validator.isObject( lastEditedCellCoordinates ) &&
			lastEditedCellCoordinates.columnId === columnId &&
			lastEditedCellCoordinates.rowId === rowId &&
			lastEditedCellCoordinates.inputId == inputId ) {
			return true;
		}
		this.editedCellsRegistry.push( {
			columnId: columnId,
			rowId: rowId,
			inputId: inputId
		} );
		return this._reduceEditedCellsRegistry( true );
	}

	get lastEditedCell() {
		const lastEditedCellCoordinates = this.lastEditedCellCoordinates;
		if ( !Validator.isObject( lastEditedCellCoordinates ) ) {
			return void 0;
		}
		const cell = this.getCell( {
			rowId: lastEditedCellCoordinates.rowId,
			columnId: lastEditedCellCoordinates.columnId
		} );
		return Validator.isObject( cell ) ? cell : void 0;
	}

	get lastEditedCellCoordinates() {
		if ( !Validator.isArray( this.editedCellsRegistry ) ) {
			this.editedCellsRegistry = [];
			return void 0;
		}
		if ( this.editedCellsRegistry.length < 1 ) {
			return void 0;
		}
		return this.editedCellsRegistry[ this.editedCellsRegistry.length - 1 ];
	}

	_reduceEditedCellsRegistry( returnValue ) {
		if ( !Validator.isArray( this.editedCellsRegistry ) ) {
			this.editedCellsRegistry = [];
			return returnValue;
		}
		if ( this.editedCellsRegistry.length <= EDITED_CELLS_REGISTRY_MAX_LENGTH ) {
			return returnValue;
		}
		this.editedCellsRegistry.splice(
			0, this.editedCellsRegistry.length - EDITED_CELLS_REGISTRY_MAX_LENGTH );
		return returnValue;
	}

	setAllRowsToUnedited() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		return selectionManager.forEachModelItem( modelItem => {
			if ( !( "edited" in modelItem ) ) {
				return;
			}
			modelItem.edited = false;
		}, true, false );
	}

	markRowsAsEdited( parameters ) {
		if ( !Validator.isObject( parameters ) || this.isRowTpl ) {
			return false;
		}
		if ( this.markEditedUneditedModelItems(
				parameters.edited, parameters.notEdited ) ) {
			return true;
		}
		// TODO: should this be done twice? now AND also after model data?
		return this.setupModelDataCallback( "markRowsAsEdited-", () => {
			return this.markEditedUneditedModelItems(
				parameters.edited, parameters.notEdited );
		} );
	}

	markEditedUneditedModelItems( editedRowXtwIdsList, uneditedRowXtwIdsList ) {
		const editedModelItemsMarked = this.markModelItemsAsEdited( editedRowXtwIdsList );
		const notEditedModelItemsMarked = this.markModelItemsAsUnedited( uneditedRowXtwIdsList );
		return editedModelItemsMarked && notEditedModelItemsMarked;
	}

	markModelItemsAsEdited( rowXtwIdsList ) {
		return this._setModelItemsEditedFlag( rowXtwIdsList, true );
	}

	markModelItemsAsUnedited( rowXtwIdsList ) {
		return this._setModelItemsEditedFlag( rowXtwIdsList, false );
	}

	_setModelItemsEditedFlag( rowXtwIdsList, setToEdited = true ) {
		if ( this.isRowTpl || !Validator.isArray( rowXtwIdsList, true ) ) {
			return true;
		}
		const listOfRowXtwIds = Validator.deduplicateArray( rowXtwIdsList );
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		let atLeastOneModelItemSet = false;
		let allItemsSet = true;
		for ( let rowXtwId of listOfRowXtwIds ) {
			const modelItem = selectionManager.getModelItemByRowId( rowXtwId );
			if ( !Validator.isObject( modelItem ) || !( "edited" in modelItem ) ) {
				allItemsSet = false;
				continue;
			}
			modelItem.edited = !!setToEdited;
			atLeastOneModelItemSet = true;
		}
		return allItemsSet;
	}

	setDummyInsertionRow( parameters ) {
		if ( this._setDummyInsertionRow( parameters ) ) {
			return true;
		}
		return this.setupModelDataCallback( "setDummyInsertionRow-", () => {
			return this._setDummyInsertionRow( parameters );
		} );
	}

	_setDummyInsertionRow( parameters ) {
		if ( this.isRowTpl || !Validator.isObject( parameters ) ) {
			return false;
		}
		const modelItem = this.getModelItemByRowId( parameters.rowId );
		const dummyModelItem = this.dummyModelItem;
		const targetItem = Validator.isObject( modelItem ) ?
			modelItem : dummyModelItem;
		if ( !Validator.isObject( targetItem ) ) {
			return false;
		}
		Warner.traceIf( DO_LOG && modelItem != dummyModelItem, `The row ID (idr or XTWID)` +
			` "${ parameters.rowId }" of the row that should be marked as a dummy` +
			` insertion row does not correspond with the registered dummy` +
			` insertion row.` );
		targetItem.insertionDummy = true;
		return true;
	}

	scrollAndFocusEditableCell( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const modelItem = this.getModelItemByRowId( parameters.rowId );
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		if ( !this.scrollAndClickModelItem( modelItem ) ) {
			return false;
		}
		const body = this;
		this.setupEnsuingModelDataCallback( "scrollAndFocusEditableCell-", () => {
			return body.createAndFocusInputInsideCell( modelItem, parameters.columnId, parameters.openDropdown );
		} );
		return true;
	}

	scrollAndClickModelItem( modelItem ) {
		return this._scrollAndClickModelItem( {
			modelItem: modelItem,
			positionFromTop: this.amountOfVisibleRowItems - 3
		} );
	}

	doAfterScrollingToModelItem( modelItem, callback ) {
		if ( !Validator.isObject( modelItem ) || !Validator.isFunction( callback ) ) {
			return false;
		}
		if ( !this.scrollAndClickModelItem( modelItem ) ) {
			return false;
		}
		if ( modelItem.isRendered ) {
			callback();
			return true;
		}
		return this.setupEnsuingModelDataCallback( "doAfterScrollingToModelItem-",
			() => { return callback(); } );
	}

	createAndFocusInputInsideCell( modelItem, columnId, openDropdown = false ) {
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		if ( modelItem.isRendered ) {
			return this.doCreateAndFocusInputInsideCell( modelItem, columnId,
				!!openDropdown );
		}
		const body = this;
		const scrollCallBackRegistered = this.doAfterScrollingToModelItem( modelItem, () => {
			return body.doCreateAndFocusInputInsideCell(
				modelItem, columnId, !!openDropdown );
		} );
		if ( !this.scrollAndClickModelItem( modelItem ) ) {
			Warner.traceIf( DO_LOG, `Could not scroll to model item.` );
		}
		return scrollCallBackRegistered;
	}

	doCreateAndFocusInputInsideCell( modelItem, columnId, openDropdown = false ) {
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		const xRowItem = modelItem.row;
		if ( !Validator.isObjectPath( xRowItem, "xRowItem.cells" ) ||
			!Validator.isFunction( xRowItem.cells.getItem ) ) {
			return false;
		}
		let cell = xRowItem.cells.getItem( columnId );
		if ( !Validator.isObject( cell ) ) {
			cell = xRowItem.firstEditableInputCell;
		}
		return this._createAndFocusInputInCell( cell, !!openDropdown );
	}

	_createAndFocusInputInCell( cell, openDropdown = false ) {
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		this._clickAndFocusCell( cell, !!openDropdown );
		if ( cell.isInTheVisibleRange ) {
			return true;
		}
		if ( !Validator.isFunction( cell.ensureCellIsInTheVisibleRange ) ||
			!Validator.isFunction( this.setupAfterScrollViewUpdateCallback ) ) {
			return false;
		}
		const instance = this;
		const callbackRegistered = this.setupAfterScrollViewUpdateCallback(
			"_createAndFocusInputInCell-", () => {
				if ( !Validator.isObject( instance ) ) {
					return false;
				}
				if ( Validator.isFunction( instance.forceSyncHorizontalScrollbar ) ) {
					instance.forceSyncHorizontalScrollbar();
				}
				if ( !Validator.isFunction( instance._clickAndFocusCell ) ) {
					return false;
				}
				return instance._clickAndFocusCell( cell, !!openDropdown );
			}
		);
		cell.ensureCellIsInTheVisibleRange();
		return callbackRegistered;
	}

	_clickAndFocusCell( cell, openDropdown = false ) {
		if ( !Validator.isFunctionPath( cell, "cell.onCellContainerClick" ) ) {
			return false;
		}
		cell.onCellContainerClick( { transferredFromNeighbour: true } );
		if ( !( cell.inputField instanceof InputField ) ) {
			return false;
		}
		const selectionEnd = cell.inputField.selectionEnd;
		cell.inputField.setSelection( selectionEnd, selectionEnd );
		if ( !openDropdown || !( cell.inputField instanceof DropdownInputField ) ) {
			return true;
		}
		cell.inputField.openDropdown();
		BscMgr.hideBlockScreen();
		return true;
	}

	regulateInputOnSave() {
		if ( this.regulateInputFieldOnSave() ) {
			return true;
		}
		if ( this.regulateCheckboxOnSave() ) {
			return true;
		}
		return true;
	}

	regulateInputFieldOnSave() {
		if ( !( this.inputField instanceof InputField ) ) {
			return false;
		}
		return Validator.isFunctionPath( this.inputField,
				"inputField.saveAllAndKeepFocus" ) ?
			this.inputField.saveAllAndKeepFocus() : true;
	}

	regulateCheckboxOnSave() {
		if ( !( this.inputField instanceof Checkbox ) ) {
			return false;
		}
		if ( !this.inputField.dirty ) {
			return true;
		}
		return Validator.isFunctionPath( this.inputField,
			"inputField.onCheckboxSave" ) ? this.inputField.onCheckboxSave() : true;
	}

	fixDummyInsertionRowUI() {
		const dummyModelItem = this.dummyModelItem;
		Warner.traceIf( DO_LOG );
		if ( !Validator.isObject( dummyModelItem ) ) {
			return false;
		}
		dummyModelItem.insertionDummy = true;
		return true;
	}

}
