import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class ImgWdg implements the custom widget ImgWdg
 */
export default class ImgWdg {
	
	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this._psa.bindAll(this, [ "layout", "onReady", "onRender" ]);
		this.ready = false;
		this.parent = null;
		this.element = null;
		this.img = null;
		this.enabled = true;
		
		const parent = rap.getObject(properties.parent);
		const element = document.createElement('div');
		element.style.position = 'absolute';
		element.style.cursor = 'default';
		parent.append(element);

		this.parent = parent;
		this.element = element;
		this.itmMgr = ItmMgr.getInst();
		
		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
	}
	
	destroy() {
		this._rmvCurImg();
		this.ready = false;
		this.img = null;
		this.itmMgr = null;
		this.element = null;
		this.parent = null;
	}
	
	onReady() {
		this.ready = true;
		this.setEna(this.enabled);
	}

	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
			this.layout();
		}
	}

	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const wdt = area[2];
			const hgt = area[3];
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = wdt + 'px';
			this.element.style.height = hgt + 'px';
		}
	}
	
	setBgc(args) {
		this.itmMgr.setBkgClr(this.element, args, true);
	}
	
	setFgc(args) {
		this.itmMgr.setFgrClr(this.element, args);
	}
	
	setImg(args) {
		this._rmvCurImg();
		const obj = args || {};
		const typ = obj.typ || '';
		const img = obj.img || null;
		if ( this._psa.isStr(typ) && img ) {
			// update image
			switch ( typ ) {
			case "DSC":
				this._rdrDscIco(img);
				break;
			case "IMG":
				const svg = obj.svg || false;
				if ( svg ) {
					this._rdrSvg(img);
				} else {
					this._rdrSwtImg(img);
				}
				break;
			default:
				break;
			}
		} else {
			// remove current image
		}
	}
	
	setIcoSiz(args) {
		const ics = args || 0;
		if ( (ics > 0) && this.img ) {
			const img = this.img;
			if ( img.tagName === 'IMG' ) {
				const val = '' + ics;
				img.setAttribute('width', val);
				img.setAttribute('height', val);
			} else {
				const val = '' + ics + 'px';
				img.style.width = val;
				img.style.height = val;
			}
		}
	}

	setEna(args) {
		const ena = args && true;
		this.enabled = ena;
		if ( this.element ) {
			if ( ena ) {
				this.element.style.opacity = '';
			} else {
				this.element.style.opacity = '0.3';
			}
		}
	}
	
	_rmvCurImg() {
		if ( this.img ) {
			const img = this.img;
			this.img = null;
			img.parentElement.removeChild(img);
		}
	}
	
	_rdrDscIco(dsc) {
		if ( this.element ) {
			const cls = dsc.cssCls || '';
			const icn = dsc.icoNam || '';
			const isz = dsc.icoSiz || 0;
			if ( this._psa.isStr(cls) && this._psa.isStr(icn) && (isz > 0) ) {
				const clr = dsc.icoClr;
				const fnm = dsc.fntNam;
				const ico = this.itmMgr.creFntIco(this.element, cls, icn, isz, clr, fnm, null, null);
				this.img = ico.div;
			}
		}
	}
	
	_rdrSwtImg(swi) {
		if ( this.element ) {
			const url = swi[0] || '';
			const wdt = swi[1] || 0;
			const hgt = swi[2] || 0;
			if ( this._psa.isStr(url) ) {
				const img = document.createElement('img');
				img.setAttribute('src', url);
				if ( wdt > 0 && hgt > 0 ) {
					img.setAttribute('width', '' + wdt);
					img.setAttribute('height', '' + hgt);
				}
				this.element.appendChild(img);
				this.img = img;
			}
		}
	}
	
	_rdrSvg(svi) {
		if ( this.element ) {
			const img = this.itmMgr.creSvgImg(svi);
			if ( img ) {
				this.element.appendChild(img);
				this.img = img;
			}
		}
	}

	/** register custom widget type */
	static register() {
		console.log( 'Registering custom widget ImgWdg.' );
		/** register custom widget type */
		rap.registerTypeHandler("psawidget.ImgWdg", {
			factory : function(properties) {
				return new ImgWdg(properties);
			},
			destructor: "destroy",
			properties: [ "bgc", "fgc", "img", "icoSiz", "ena" ],
			methods: [ ],
			events: [ "IMG_NFY"]
		});
	}
}



console.log( 'widgets/psaimg/ImgWdg.js loaded.' );