import EditableElement from './EditableElement';
import EventListenerManager from '../../../../utils/EventListenerManager';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import CheckboxKeyHandlingExtension from './CheckboxKeyHandlingExtension';

const DO_LOG = true;

export default class Checkbox extends EditableElement {

	constructor( cellObject ) {
		super( cellObject );
		cellObject.checkbox = this;
		new CheckboxKeyHandlingExtension( this );
		this.nextValue = null;
	}

	get isTripleState() {
		return false;
	}

	get inputId() {
		return this.input instanceof HTMLElement && Validator.isString( this.input.id ) ? String( this.input.id ) : null;
	}

	render() {
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			Warner.traceIf( true, `Invalid cell element` );
			return false;
		}
		this.discardUi();
		cellElement.innerHTML = "";
		this.input = this.newInput;
		if ( this.isOriginallyChecked ) {
			this.input.appendChild( this.newCheckmark );
		}
		this.container = this.newContainer;
		this.container.style.justifyContent = "center";
		this.container.appendChild( this.input );
		cellElement.appendChild( this.container );
		return true;
	}

	get changed() {
		return this.userValue !== this.initialValue;
	}

	get userValue() {
		return this.isChecked;
	}

	get initialValue() {
		return this.isOriginallyChecked;
	}

	setEditingPermission( editingAllowed ) {
		this.editingAllowed = !!editingAllowed;
		this.dirty = this.editingAllowed;
		try {
			if ( this.editingAllowed ) {
				if ( this.nextValue !== null ) {
					if ( this.nextValue ) {
						this.check();
					} else {
						this.uncheck();
					}
				}
			} else {
				this.resetInput();
			}
		} finally {
			this.nextValue = null;
		}
		// this.editingAllowed ? this.toggleInput() : this.resetInput();
		if ( !this.editingAllowed ) {
			return true;
		}
		this.rowEdited = true;
		if ( (this.input instanceof HTMLElement) && (window.document.activeElement !== this.input) ) {
			this.input.focus();
		}
		const blurListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "blur",
			functionName: "onInputBlur",
			callBackPrefix: "Input",
			element: this.input,
			useCapture: false
		} );
		return true;
	}

	setCheckedStatus( newCellContent ) {
		return newCellContent == "true" ? this.check() : this.uncheck();
	}

	toggleInput() {
		return this.isChecked ? this.uncheck() : this.check();
	}

	check() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.innerHTML = "";
		this.input.appendChild( this.newCheckmark );
		return true;
	}

	uncheck() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.innerHTML = "";
		return true;
	}

	focusSelf() {
		if ( this.input instanceof HTMLElement ) {
			this.input.focus();
			return true;
		}
		if ( !Validator.isObject( this.cell ) ||
			!( this.cell.element instanceof HTMLElement ) ) {
			return false;
		}
		this.cell.element.focus();
		return true;
	}

	destroySelfAndRestoreCell() {
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) && xtwBody.inputField === this ) {
			xtwBody.inputField = void 0;
			delete xtwBody.inputField;
		}
		return this.resetInput();
	}

	resetInput() {
		this.dirty = false;
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		EventListenerManager.removeListener( this, "blur", this.input, "Input" );
		this.isOriginallyChecked ? this.check() : this.uncheck();
		return true;
	}

	discardInput() {
		[ "keydown", "keyup", "keypress", "click" ].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName, this.input, "Input" );
		} );
		return super.discardInput();
	}

	destroySelf() {
		this.discardUi();
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) && xtwBody.inputField === this ) {
			xtwBody.inputField = void 0;
			delete xtwBody.inputField;
		}
		const cellObject = this.cell;
		if ( Validator.isObject( cellObject ) ) {
			cellObject.checkbox = void 0;
			delete cellObject.checkbox;
		}
		this.cell = void 0;
		delete this.cell;
		const propertyNames = Object.getOwnPropertyNames(
			Object.getPrototypeOf( this ) );
		for ( let propertyName of propertyNames ) {
			Object.defineProperty( this, propertyName, {
				value: void 0,
				writable: true,
				configurable: true
			} );
			this[ propertyName ] = void 0;
			delete this[ propertyName ];
		}
		return true;
	}

	get isChecked() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		return this.input.innerHTML !== "";
	}

	get isOriginallyChecked() {
		const originalValue = this.originalValue;
		return originalValue === "true" || originalValue === true;
		// return Validator.isString( originalValue ) &&
		// 	originalValue.indexOf( "fp-check-1" ) > 0;
	}

	get newCheckmark() {
		const checkmarkItalicTag = window.document.createElement( "i" );
		checkmarkItalicTag.classList.add( "far", "fa-check" );
		return checkmarkItalicTag;
	}

	get newInput() {
		const checkboxDiv = window.document.createElement( "div" );
		checkboxDiv.classList.add( "rtp-checkbox" );
		checkboxDiv.id = Validator.generateRandomString( "rtp-input-" );
		checkboxDiv.tabIndex = 1;
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onInputKeyDown",
			callBackPrefix: "Input",
			element: checkboxDiv,
			useCapture: false
		} );
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onInputKeyUp",
			callBackPrefix: "Input",
			element: checkboxDiv,
			useCapture: false
		} );
		const keypressListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keypress",
			functionName: "onInputKeyPress",
			callBackPrefix: "Input",
			element: checkboxDiv,
			useCapture: false
		} );
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onInputClick",
			callBackPrefix: "Input",
			element: checkboxDiv,
			useCapture: false
		} );
		return checkboxDiv;
	}

	isHyperlinkClick( domEvent ) {
		return Validator.isObject( this.cell ) &&
			Validator.isFunction( this.cell.isHyperlinkClick ) ?
			this.cell.isHyperlinkClick( domEvent ) : false;;
	}

	onInputClick( domEvent ) {
		if ( this.isHyperlinkClick( domEvent ) && Validator.isObject( this.cell ) &&
			Validator.isFunction( this.cell.onCellContainerClick ) ) {
			return this.cell.onCellContainerClick( domEvent );
		}
		if ( !this.canBeEdited ) {
			return false;
		}
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) ) {
			xtwBody.inputField = this;
		}
		if ( this.dirty ) {
			if ( domEvent instanceof Event ) {
				domEvent.stopPropagation();
				domEvent.preventDefault();
			}
			return this.toggleInput();
		} else {
			this.nextValue = !this.isChecked;
			this.informAboutEditing();
			return true;
		}
	}

	onInputBlur( domEvent ) {
		if ( Validator.isObject( this.cell ) &&
			window.document.activeElement == this.cell.element ) {
			return true;
		}
		Warner.traceIf( DO_LOG );
		this.informAboutContentChange();
		return this.destroySelfAndRestoreCell();
	}

	informAboutContentChange() {
		if ( this.editingAllowed && (this.isOriginallyChecked !== this.isChecked) ) {
			this.informAboutSave();
			return true;
		} else {
			return false;
		}
	}

	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const row = this.row;
		if ( !Validator.isObject( row ) || !Validator.isFunction( row._nfySrv ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		const isOriginallyChecked = this.isOriginallyChecked;
		Object.assign( parameters, {
			idc: this.columnId,
			originalValue: isOriginallyChecked,
			userValue: !isOriginallyChecked,
			inputId: this.inputId
		} );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

}
