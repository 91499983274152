import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class DropdownArrowExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get newArrow() {
		const dropdownArrow = window.document.createElement( "div" );
		dropdownArrow.classList.add( "rtp-dropdown-arrow" );
		dropdownArrow.classList.add( this.isOnRightEdge ? 'rtp-dropdown-arrow-right' : 'rtp-dropdown-arrow-left');
		const arrowIconItalicElement = window.document.createElement( "i" );
		arrowIconItalicElement.classList.add( "far", "fa-angle-down" );
		dropdownArrow.appendChild( arrowIconItalicElement );
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onDropdownArrowClick",
			callBackPrefix: "DropdownArrow",
			element: dropdownArrow,
			useCapture: false
		} );
		const mouseDownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousedown",
			functionName: "onDropdownArrowMousedown",
			callBackPrefix: "DropdownArrow",
			element: dropdownArrow,
			useCapture: false
		} );
		const mouseUpListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "onDropdownArrowMouseup",
			callBackPrefix: "DropdownArrow",
			element: dropdownArrow,
			useCapture: false
		} );
		return dropdownArrow;
	}

	onDropdownArrowMousedown( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onDropdownArrowMouseup( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	pointArrowUp( arrowElement ) {
		return this.setArrowAngle( arrowElement, true );
	}

	pointArrowDown( arrowElement ) {
		return this.setArrowAngle( arrowElement, false );
	}

	setArrowAngle( arrowElement, up = true ) {
		let arrowIconItalicElement = arrowElement;
		if ( arrowIconItalicElement instanceof HTMLDivElement ) {
			const elementChildren = HtmHelper.getAllLevelChildren( arrowIconItalicElement )
				.filter( child => HtmHelper.isTagName( child, "i" ) &&
					child.classList.contains( "fa" ) );
			if ( !Validator.isArray( elementChildren, true ) ) {
				return false;
			}
			arrowIconItalicElement = elementChildren[ 0 ];
		}
		if ( !( arrowIconItalicElement instanceof HTMLElement ) ||
			!HtmHelper.isTagName( arrowIconItalicElement, "i" ) ||
			!arrowIconItalicElement.classList.contains( "fa" ) ) {
			return false;
		}
		arrowIconItalicElement.classList.remove( "fa-angle-up", "fa-angle-down" );
		arrowIconItalicElement.classList.add( ( !!up ? "fa-angle-up" : "fa-angle-down" ) );
		return true;
	}

}
