import PSA from './psa';
import globalWdgRegister from './widgets/wdgregister';

/**
 * initializes the "window.pisasales" object
 * @param {Boolean} debug debug mode flag
 * @returns {Boolean} true if everything is ok; false if we must stop this session right now
 */
function _init_pisasales(debug) {
	// get the PSA singleton
	const psa = new PSA(debug);
	// and publish it as global "pisasales" object
	window.pisasales = psa;
	// and let it complete its initialization
	return psa.completeInitialization();
}

/**
 * basic PISA Sales Web Client Initialization
 * @param {Boolean} debug debug mode flag
 */
export default function PISA_WebClientInit(debug) {
	const mode = debug ? 'DEBUG' : 'PROD';
	console.log(`+++ PISA Sales Web Client Initialization ("${mode}") - BEGIN +++`);
	// initialize "window.pisasales"
	if ( _init_pisasales(debug) ) {
		// register all custom widgets
		globalWdgRegister().registerAll();
		console.log(`+++ PISA Sales Web Client Initialization ("${mode}") - DONE +++`);
	} else {
		// incompatible browser! force a redirect to a static HTML page
		console.warn("ERROR! Cannot go on!");
		let tgt = top.window.location.href;
		let pos = tgt.lastIndexOf('/');
		if ( pos !== -1 ) {
			tgt = tgt.substring(0, pos);
		}
		tgt += '/theme/unsupported.html';
		top.window.location.href = tgt;
	}
}

console.log('init.js loaded.');