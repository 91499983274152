import AttachmentObject from '../../../../utils/AttachmentObject';
import CallbackManager from '../../../../utils/CallbackManager';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const DO_LOG = false;

export default class XtwBodyCallbackExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		parentObject._afterModelDataCallbacks = new Map();
		parentObject._ensuingModelDataCallbacks = new Map();
		parentObject._afterScrollViewUpdateCallbacks = new Map();

		// CallbackManager.addCallbackMethods( {
		//   instance: this,
		//   callbackMapName: "_afterModelDataCallbacks",
		//   setupMethodName: "setupModelDataCallback",
		//   executionMethodName: "_doAfterModelData"
		// } );

		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	_doAfterModelData() {
		Warner.traceIf( DO_LOG );
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: "_afterModelDataCallbacks"
		} );
	}

	_doEnsuingModelData() {
		Warner.traceIf( DO_LOG );
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: "_ensuingModelDataCallbacks"
		} );
	}

	_doAfterScrollViewUpdate() {
		Warner.traceIf( DO_LOG );
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: "_afterScrollViewUpdateCallbacks"
		} );
	}

	setupModelDataCallback( prefix, callback, deleteRightAfterExecution = true ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: "_afterModelDataCallbacks",
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: true,
			canBeDeletedByOthers: true
		} );
	}

	setupEnsuingModelDataCallback( prefix, callback, deleteRightAfterExecution = true ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: "_ensuingModelDataCallbacks",
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: true,
			canBeDeletedByOthers: true
		} );
	}

	setupAfterScrollViewUpdateCallback( prefix, callback, deleteRightAfterExecution = true ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: "_afterScrollViewUpdateCallbacks",
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: true,
			canBeDeletedByOthers: true
		} );
	}

}
