import Checkbox from './Checkbox';
import EventListenerManager from '../../../../utils/EventListenerManager';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const DO_LOG = true;

export default class TripleStateCheckbox extends Checkbox {

	constructor( cellObject ) {
		super( cellObject );
		cellObject.checkbox = this;
	}

	get isTripleState() {
		return true;
	}

	get userValue() {
		return this.isChecked ? true : this.isUnset ? "" : false;
	}

	get initialValue() {
		this.isOriginallyChecked ? true : this.isOriginallyUnset ? "" : false;
	}

	get inputChild() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return void 0;
		}
		const inputChildren = this.input.children;
		if ( !Validator.isIterable( inputChildren ) || inputChildren.length < 1 ) {
			return void 0;
		}
		const firstChild = inputChildren[ 0 ];
		return firstChild instanceof HTMLElement ? firstChild : void 0;
	}

	get isChecked() {
		const inputChild = this.inputChild;
		if ( !( inputChild instanceof HTMLElement ) ) {
			return false;
		}
		return inputChild.classList.contains( "fa-check" );
	}

	get isUnset() {
		const inputChild = this.inputChild;
		if ( !( inputChild instanceof HTMLElement ) ) {
			return false;
		}
		return inputChild.classList.contains( "null-value" );
		// return inputChild.classList.contains( "fa-square" );
	}

	get isOriginallyUnchecked() {
		const originalValue = this.originalValue;
		return originalValue === "false" || originalValue === false;
	}

	get isOriginallyUnset() {
		const originalValue = this.originalValue;
		return originalValue === "null" || originalValue === null;
	}

	setCheckedStatus( newCellContent ) {
		return newCellContent === "true" || newCellContent === true ? this.check() :
			newCellContent === "false" || newCellContent === false ? this.uncheck() :
			this.unset();
	}

	toggleInput() {
		return this.isChecked ? this.uncheck() : this.isUnset ? this.check() : this.unset();
	}

	unset() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		this.input.innerHTML = "";
		this.input.appendChild( this.newSquare );
		return true;
	}

	resetInput() {
		this.dirty = false;
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		EventListenerManager.removeListener( this, "blur", this.input, "Input" );
		this.isOriginallyChecked ? this.check() :
			this.isOriginallyUnset ? this.unset() : this.uncheck();
		return true;
	}

	render() {
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			Warner.traceIf( true, `Invalid cell element` );
			return false;
		}
		this.discardUi();
		cellElement.innerHTML = "";
		this.input = this.newInput;
		if ( this.isOriginallyChecked ) {
			this.input.appendChild( this.newCheckmark );
		} else if ( this.isOriginallyUnset ) {
			this.input.appendChild( this.newSquare );
		}
		this.container = this.newContainer;
		this.container.style.justifyContent = "center";
		this.container.appendChild( this.input );
		cellElement.appendChild( this.container );
		return true;
	}

	get newSquare() {
		const squareDiv = window.document.createElement( "div" );
		squareDiv.classList.add( "null-value" );
		return squareDiv;
		// const squareItalicTag = window.document.createElement( "i" );
		// squareItalicTag.classList.add( "fa", "fa-square" );
		// squareItalicTag.style.fontSize = "6px";
		// squareItalicTag.style.margin = "auto";
		// return squareItalicTag;
	}

	informAboutContentChange() {
		if ( !this.dirty || !this.editingAllowed ||
			( this.isOriginallyChecked === this.isChecked &&
				this.isOriginallyUnset === this.isUnset ) ) {
			return false;
		}
		this.informAboutSave();
		return true;
	}

	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const row = this.row;
		if ( !Validator.isObject( row ) || !Validator.isFunction( row._nfySrv ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		Object.assign( parameters, {
			idc: this.columnId,
			originalValue: this.isOriginallyChecked ? true : this.isOriginallyUnset ? "" : false,
			userValue: this.isChecked ? true : this.isUnset ? "" : false,
			inputId: this.inputId
		} );
		Warner.traceIf( DO_LOG, `A notification with the code "${ notificationCode }"` +
			` is being set to the web server from a triple state checkbox. A block` +
			` screen request is${ !blockScreenRequest ? " not" : "" } being send.` +
			` Parameters: ${ JSON.stringify( parameters ) }` );
		return row._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

}
