import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const DO_LOG = false;

export default class XtwHeadScrollingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		parentObject.hscPos = 0;
		parentObject.scbWdt = 0;
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	/**
	 * sets the current effective scrollbar width
	 * @param {Number} scb current effective scrollbar width
	 */
	setScb( scb ) {
		this.scbWdt = scb || 0;
	}

	/**
	 * called if the user scrolls horizontally
	 * @param {Number} pos current scrolling position
	 */
	onHScroll( pos ) {
		Warner.traceIf( DO_LOG );
		const vwd = this.visWdt - this.scbWdt;
		if ( ( vwd > 0 ) && this.wdtDyn && ( this.hscPos !== pos ) ) {
			let eff_pos = pos;
			const full_wdt = this.wdtFix + this.wdtDyn;
			if ( ( pos !== 0 ) && ( ( vwd + pos ) > full_wdt ) ) {
				eff_pos -= vwd + pos - full_wdt;
			}
			if ( this.hscPos !== eff_pos ) {
				this.hscPos = eff_pos;
				if ( this.ccnDyn ) {
					this.ccnDyn.style.left = '' + ( -this.hscPos ) + 'px';
				}
			}
		}
	}

	ensureHorizontalScrollPositionsAreSynced() {
		if ( Validator.isObject( this.xtwBody ) &&
			Validator.isFunction( this.xtwBody.forceSyncHorizontalScrolling ) ) {
			return this.xtwBody.forceSyncHorizontalScrolling();
		}
		return false;
	}

	scrollToColumn( column ) {
		if ( !Validator.isObject( column ) || !column.isRendered || column.fix ||
			!Validator.isObject( this.xtdTbl ) ) {
			return false;
		}
		const widgetHorizontalSelection = this.widgetHorizontalSelection;
		if ( !Validator.isObject( widgetHorizontalSelection ) ) {
			return false;
		}
		const bodyRect = this.bodyClientRect;
		if ( !( bodyRect instanceof DOMRect ) ) {
			return false;
		}
		const columnRect = column.element.getBoundingClientRect();
		if ( !( columnRect instanceof DOMRect ) ) {
			return false;
		}
		const fixedContainerRect = this.fixedContainerRect;
		if ( !( fixedContainerRect instanceof DOMRect ) ) {
			return false;
		}
		const bodyEnd = bodyRect.x + bodyRect.width;
		const columnEnd = columnRect.x + columnRect.width;
		const fixedContainerEnd = fixedContainerRect.x + fixedContainerRect.width;
		if ( columnEnd > bodyEnd ) {
			// scroll to the right
			widgetHorizontalSelection._selection += columnEnd - bodyEnd;
		} else if ( columnRect.x < bodyRect.x ) {
			// scroll to the left
			widgetHorizontalSelection._selection += columnRect.x - bodyRect.x - fixedContainerRect.width;
		} else if ( fixedContainerEnd > columnEnd ) {
			// scroll to the left
			widgetHorizontalSelection._selection += columnRect.x - fixedContainerRect.width;
		} else {
			return this.ensureHorizontalScrollPositionsAreSynced();
		}
		widgetHorizontalSelection.setSelection( widgetHorizontalSelection._selection );
		this.xtdTbl._onHScroll();
		return true;
	}

	get widgetHorizontalSelection() {
		if ( !Validator.is( this.xtdTbl, "XtwTbl" ) ||
			!Validator.isObject( this.xtdTbl.wdgSlh ) ) {
			return void 0;
		}
		return this.xtdTbl.wdgSlh;
	}

	onHorizontalScrollbarHidden() {
		this.onHScroll( 0 );
		return true;
	}

	onHorizontalScrollbarShown() {
		const widgetHorizontalSelection = this.widgetHorizontalSelection;
		if ( !Validator.isObject( widgetHorizontalSelection ) ||
			!Validator.isValidNumber( widgetHorizontalSelection._selection ) ) {
			return false;
		}
		this.onHScroll( widgetHorizontalSelection._selection );
		return true;
	}

}
