import AttachmentObject from '../../../../utils/AttachmentObject';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const ROW_ITEM_DATASET_PREFIX = "xtw-row-item-#";

const DO_LOG = false;

export default class SelectionManagerHelpers extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	/**
	 * determines whether a row (XtwRowTemplateRow) with the passed rowId is
	 * present/exists
	 * @return <true> if row (XtwRowTemplateRow) exists, <false> otherwise
	 */
	hasRow( rowId ) {
		return Validator.isObject( this.getXRowItemById( rowId ) );
	}

	getRow( rowId ) {
		return this.getXRowItemById( rowId );
	}

	getXRowItemById( rowId ) {
		return this.getXrowItem( "rowId", rowId );
	}

	getXrowItemByIdx( idx ) {
		return this.getXrowItem( "idx", idx );
	}

	getXrowItemByFlatIndex( flatIndex ) {
		return this.getXrowItem( "flatIndex", flatIndex );
	}

	getXrowItem( propertyName, index ) {
		if ( !Validator.isString( propertyName ) ) {
			return void 0;
		}
		if ( !Validator.isValidNumber( index ) && !Validator.isString( index ) ) {
			return void 0;
		}
		index = String( index );
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.isIterable( this.hostObject.rowItems ) ) {
			return void 0;
		}
		const xRowItem = this.hostObject.rowItems.find( xRowItem => (
			Validator.is( xRowItem, this.rowClassName ) &&
			String( xRowItem[ propertyName ] ) == index
		) );
		return Validator.isObject( xRowItem ) ? xRowItem : void 0;
	}

	getModelItem( propertyName, propertyValue ) {
		let modelItem;
		if ( [ "idr", "xid" ].indexOf( propertyName ) >= 0 ) {
			modelItem = this.getModelItemFromMap( propertyValue );
			if ( Validator.isObject( modelItem ) ) {
				return modelItem;
			}
		}
		return this.getModelItemFromFlatModel( propertyName, propertyValue );
	}

	getModelItemByRowId( rowId ) {
		let modelItem = this.getModelItem( "idr", rowId );
		if ( Validator.isObject( modelItem ) ) {
			return modelItem;
		}
		modelItem = this.getModelItem( "xid", rowId );
		if ( Validator.isObject( modelItem ) ) {
			return modelItem;
		}
		return void 0;
	}

	getModelItemFromMap( idrOrXid ) {
		if ( !Validator.isObjectPath( this, "this.hostObject.model" ) ||
			!Validator.isMap( this.hostObject.model.items ) ) {
			return void 0;
		}
		const id = Number( idrOrXid );
		if ( !Validator.isValidNumber( id ) ) {
			return void 0;
		}
		const modelItem = this.hostObject.model.items.get( id );
		return Validator.isObject( modelItem ) ? modelItem : void 0;
	}

	getModelItemFromFlatModel( propertyName, propertyValue ) {
		if ( !Validator.isString( propertyName ) ) {
			return void 0;
		}
		if ( !Validator.isValidNumber( propertyValue ) &&
			!Validator.isString( propertyValue ) ) {
			return void 0;
		}
		if ( !Validator.isObjectPath( this, "this.hostObject.model" ) ||
			!Validator.isArray( this.hostObject.model.flatModel ) ) {
			return void 0;
		}
		propertyValue = String( propertyValue );
		const modelItem = this.hostObject.model.flatModel.find( flatItem => (
			Validator.isObject( flatItem ) &&
			String( flatItem[ propertyName ] ) == propertyValue
		) );
		return Validator.isObject( modelItem ) ? modelItem : void 0;
	}

	forEachRow( rowCallback ) {
		if ( !Validator.isFunction( rowCallback ) ||
			!Validator.isObject( this.hostObject ) ||
			!Validator.isIterable( this.hostObject.rowItems ) ) {
			return false;
		}
		for ( let rowItem of this.hostObject.rowItems ) {
			if ( !Validator.is( rowItem, this.rowClassName ) ) {
				continue;
			}
			rowCallback( rowItem );
		}
		return true;
	}

	forEachModelItem( modelItemCallback, includeRows = true, includeGroups = true ) {
		if ( !Validator.isFunction( modelItemCallback ) ||
			!Validator.isObjectPath( this, "this.hostObject.model" ) ||
			!Validator.isArray( this.hostObject.model.flatModel ) ) {
			return false;
		}
		for ( let modelItem of this.hostObject.model.flatModel ) {
			if ( !includeRows && Validator.is( modelItem, "MDataRow" ) ) {
				continue;
			}
			if ( !includeGroups && Validator.is( modelItem, "MGroup" ) ) {
				continue;
			}
			if ( !Validator.isObject( modelItem ) ) {
				continue;
			}
			modelItemCallback( modelItem );
		}
		return true;
	}

	getXrowItemFromElementByModel( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return void 0;
		}
		const mDataRow = element.__mi;
		if ( !Validator.is( mDataRow, "MDataRow" ) ) {
			return void 0;
		}
		let xRowItem;
		for ( let idProperty of [ "idr", "idt", "xid" ] ) {
			xRowItem = this.getXrowItem( idProperty, mDataRow[ idProperty ] );
			if ( Validator.isObject( xRowItem ) ) {
				break;
			}
		}
		return Validator.is( xRowItem, this.rowClassName ) ? xRowItem : void 0;
	}

	getXrowItemFromElementByIdx( element ) {
		if ( !( element instanceof HTMLElement ) ||
			!( element.dataset instanceof DOMStringMap ) ) {
			return void 0;
		}
		const idx = this.datasetIdToIdx( element.dataset.xrowItem );
		return this.getXrowItemByIdx( idx );
	}

	datasetIdToIdx( datasetId ) {
		if ( !Validator.isString( datasetId ) ) {
			return void 0;
		}
		datasetId =
			datasetId.replace( ROW_ITEM_DATASET_PREFIX, "" ).replaceAll( /\D+/g, "" );
		if ( !Validator.isString( datasetId ) ) {
			return void 0;
		}
		datasetId = Number( datasetId );
		if ( !Validator.isPositiveInteger( datasetId ) ) {
			return void 0;
		}
		return datasetId;
	}

	/**
	 * @deprecated because after row sorting no longer functional; replaced by
	 * ~_getShiftSelectedRows
	 */
	_getShiftSelectedRowsTheOldWay( xRowItem ) {
		if ( this.noSelectionAllowed ) return void 0;
		if ( !Validator.isObject( xRowItem ) ) {
			return void 0;
		}
		const rowId = Number( xRowItem.rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ xRowItem.rowId }".` );
		}
		const focusRow = this.focusRow;
		if ( !Validator.is( focusRow, this.rowClassName ) ||
			focusRow === xRowItem ) {
			return !!xRowItem.isSelected ? new Set() : rowId;
		}
		const focusRowId = Number( focusRow.rowId );
		if ( !Validator.isValidNumber( focusRowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the focused xRowItem has an` +
				` invalid numeric value. The value of the row id before the` +
				` conversion to a number is "${ focusRowId.rowId }".` );
			return void 0;
		}
		let minIdOfRowToBeSelected = 0;
		let maxIdOfRowToBeSelected = 999;
		if ( focusRowId < rowId ) {
			minIdOfRowToBeSelected = focusRowId;
			maxIdOfRowToBeSelected = rowId;
		} else {
			minIdOfRowToBeSelected = rowId;
			maxIdOfRowToBeSelected = focusRowId;
		}
		const potentialRowsToSelect = [];
		for ( let rowIndex = minIdOfRowToBeSelected; rowIndex <= maxIdOfRowToBeSelected; rowIndex++ ) {
			if ( !this.hasRow( rowIndex ) ) {
				continue;
			}
			potentialRowsToSelect.push( rowIndex );
		}
		return new Set( potentialRowsToSelect );
	}

	/**
	 * @deprecated because it only takes into account rendered & visible rows
	 * instead of model rows; replaced by
	 * ~_getShiftSelectedRows
	 */
	_getShiftSelectedRowsTheAncientWay( xRowItem ) {
		if ( this.noSelectionAllowed ) return void 0;
		if ( !Validator.isObject( xRowItem ) ) {
			return void 0;
		}
		const rowId = Number( xRowItem.rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ xRowItem.rowId }".` );
		}
		const focusRow = this.focusRow;
		if ( this.onlyOneItemCanBeSelected ||
			!Validator.is( focusRow, this.rowClassName ) ||
			focusRow === xRowItem || !focusRow.isRendered || !xRowItem.isRendered ) {
			return this.somethingShouldAlwaysBeSelected ? rowId :
				!!xRowItem.isSelected ? new Set() : rowId;
		}
		const focusY = focusRow.clientY;
		const newRowY = xRowItem.clientY;
		if ( !Validator.isValidNumber( focusY ) || !Validator.isNumber( newRowY ) ) {
			return !!xRowItem.isSelected ? new Set() : rowId;
		}
		const siblingProperty = focusY > newRowY ? "previousElementSibling" :
			"nextElementSibling";
		const focusElement = focusRow.element;
		const xRowElement = xRowItem.element;
		const potentialRowsToSelect = [];
		let sibling = focusElement[ siblingProperty ];
		while ( sibling instanceof HTMLElement && sibling !== xRowElement ) {
			let siblingXRowItem = this.getXrowItemFromElementByIdx( sibling );
			if ( !Validator.is( siblingXRowItem, this.rowClassName ) ) {
				siblingXRowItem = this.getXrowItemFromElementByModel( sibling );
			}
			if ( Validator.is( siblingXRowItem, this.rowClassName ) &&
				!Validator.is( siblingXRowItem.item, "MGroup" ) ) {
				const siblingXrowItemRowId = Number( siblingXRowItem.rowId );
				if ( !Validator.isValidNumber( siblingXrowItemRowId ) ) {
					Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
						` numeric value. The value of the row id before the conversion` +
						` to a number is "${ siblingXRowItem.rowId }".` );
				}
				potentialRowsToSelect.push( siblingXrowItemRowId );
			}
			sibling = sibling[ siblingProperty ];
		}
		potentialRowsToSelect.push( rowId );
		const focusRowId = Number( focusRow.rowId );
		if ( !Validator.isValidNumber( focusRowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ focusRow.rowId }".` );
		}
		potentialRowsToSelect.push( focusRowId );
		return new Set( potentialRowsToSelect );
	}

	/**
	 * Executes a callback and conserves/maintains the focus on the "originally"
	 * focused row of this table, if such exists. This method allows internal
	 * focus change for one (1) specific scenario. Esentially, the method
	 * retains/remembers the table row that was focused before executing the
	 * callback and forcefully focuses it after executing the callback function,
	 * but only if the focus row initially existed and was valid. If the row did
	 * not exist or was invalid before the callback, this method has no effect
	 * and does not change anything. So if nothing valid was focused before,
	 * this method has the exact same effect as simply executing the callback
	 * function. If you want to make sure that the internal focus (on a table
	 * row) set by the callback function is removed right after its execution,
	 * it is recommended to instead use {_doWithPerservingFocus}.
	 * @see {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 * @param callback the callback function to be executed
	 *
	 * The difference from {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 * is, that this method {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus}
	 * does not "restore" the focus if nothing was focused before the callback.
	 * So if no row was focused before the callback and the callback focuses a
	 * row, this method does not remove the focus set by the callback.
	 *
	 * What this method does in 2 scenarios:
	 * row A is focused  --> callback focuses row B --> focus restored to row A
	 * no row is focused --> callback focuses row B --> focus remains on row B
	 *
	 * If you want to maintain the external focus of an Element in DOM that is
	 * not a row in this table and want to prevent/stop ("freeze") the native DOM
	 * focus() method from being called on table rows during the execution of a
	 * callback, the recommended method for this scenario is {_doWithFrozenFocus}
	 * @see {@link XtwRtpItm.js~SelectionManager#_doWithFrozenFocus}
	 * @see {@link XtwBodyUtil.js~XtwBodyFocusExtension#doWithFrozenFocus}
	 *
	 * Quiz: "which method should I use?"
	 *
	 * 1. Do you want to maintain internal focus (on a table element) or
	 * external focus (on an element in DOM outside of this table)?
	 * --> 1.1 external focus --> use {@link XtwRtpItm.js~SelectionManager#_doWithFrozenFocus}
	 * --> 1.2 internal focus --> go to question 2
	 *
	 * 2. If no row was focused before executing the callback function and the
	 * callback focuses a row, should this focus be removed?
	 * --> 2.1 YES --> use {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 * --> 2.2 NO --> use {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus} (this method)
	 * --> 2.3 I don't know --> use {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 *
	 * @return the return value of the callback function
	 */
	_doWithoutDamagingFocus( callback ) {
		if ( !Validator.isFunction( callback ) ) {
			return void 0;
		}
		if ( !Validator.is( this.focusRow, this.rowClassName ) ) {
			return callback();
		}
		const focusRow = this.focusRow;
		this.focusNotificationsFrozen = true;
		const returnValue = callback();
		this.focus( focusRow );
		Warner.outputIf( {
			doOutput: !focusRow && DO_LOG,
			text: `The focus will be restored to an invalid row.`,
			color: "#F5D300"
		} );
		this.focusNotificationsFrozen = false;
		return returnValue;
	}

	/**
	 * Executes a callback function and prevents any internal focus change from
	 * happening during the exectuion of said callback function. This method
	 * does not allow ANY internal focus change for any scenario. After the
	 * callback is executed, this method restores the focus on the table row
	 * that was initially/originally focused before the callback execution OR
	 * removes any internal focus from any row if no valid row was focused
	 * before the callback execution. Esentially, any internal-focus related
	 * action happening during the callback will have no effect. If you want to
	 * allow the callback function to set the focus internally on a table row
	 * for the case/scenario when no valid row is focused before the callback
	 * execution, it is recommended to instead use {_doWithoutDamagingFocus}.
	 * @see {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus}
	 * @param callback the callback function to be executed
	 *
	 * The difference from {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus}
	 * is, that this method {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 * does "restore" the focus if nothing was focused before the callback.
	 * So if no row was focused before the callback and the callback focuses a
	 * row, this method removes the focus set by the callback.
	 *
	 * What this method does in 2 scenarios:
	 * row A is focused  --> callback focuses row B --> focus restored to row A
	 * no row is focused --> callback focuses row B --> focus removed from row B
	 *
	 * If you want to maintain the external focus of an Element in DOM that is
	 * not a row in this table and want to prevent/stop ("freeze") the native DOM
	 * focus() method from being called on table rows during the execution of a
	 * callback, the recommended method for this scenario is {_doWithFrozenFocus}
	 * @see {@link XtwRtpItm.js~SelectionManager#_doWithFrozenFocus}
	 * @see {@link XtwBodyUtil.js~XtwBodyFocusExtension#doWithFrozenFocus}
	 *
	 * Quiz: "which method should I use?"
	 *
	 * 1. Do you want to maintain internal focus (on a table element) or
	 * external focus (on an element in DOM outside of this table)?
	 * --> 1.1 external focus --> use {@link XtwRtpItm.js~SelectionManager#_doWithFrozenFocus}
	 * --> 1.2 internal focus --> go to question 2
	 *
	 * 2. If no row was focused before executing the callback function and the
	 * callback focuses a row, should this focus be removed?
	 * --> 2.1 YES --> use {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus} (this method)
	 * --> 2.2 NO --> use {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus}
	 * --> 2.3 I don't know --> use {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus} (this method)
	 *
	 * @return the return value of the callback function
	 */
	_doWithPerservingFocus( callback ) {
		if ( !Validator.isFunction( callback ) ) {
			return void 0;
		}
		this.focusNotificationsFrozen = true;
		const focusRow = this.focusRow;
		const returnValue = callback();
		this.focus( focusRow );
		Warner.outputIf( {
			doOutput: !focusRow && DO_LOG,
			text: `The focus will be restored to an invalid row.`,
			color: "#08F7FE"
		} );
		this.focusNotificationsFrozen = false;
		return returnValue;
	}

	/**
	 * this method allows such properties as focusRow to be changed but does not
	 * allow the focus() method to be called upon any DOM element of a row; the
	 * DOM element that was focused/active in the document before executing the
	 * callback should be the same DOM element that is focused/active after
	 * executing callback, so the callback should not destroy/remove the element;
	 * structural changes such as the focusRow property and the "focused" states
	 * of other rows are allowed in the callback;
	 *
	 * for other scenarios, consider:
	 * @see {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 * @see {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus}
	 *
	 * Quiz: "which method should I use?"
	 *
	 * 1. Do you want to maintain internal focus (on a table element) or
	 * external focus (on an element in DOM outside of this table)?
	 * --> 1.1 external focus --> use {@link XtwRtpItm.js~SelectionManager#_doWithFrozenFocus} (this method)
	 * --> 1.2 internal focus --> go to question 2
	 *
	 * 2. If no row was focused before executing the callback function and the
	 * callback focuses a row, should this focus be removed?
	 * --> 2.1 YES --> use {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 * --> 2.2 NO --> use {@link XtwRtpItm.js~SelectionManager#_doWithoutDamagingFocus}
	 * --> 2.3 I don't know --> use {@link XtwRtpItm.js~SelectionManager#_doWithPerservingFocus}
	 *
	 * @see {@link XtwBodyUtil.js~XtwBodyFocusExtension#doWithFrozenFocus}
	 * @return the return value of the callback function
	 */
	_doWithFrozenFocus( callbackFunction, isTheCallbackAllowedToChangeTheFocus = false ) {
		if ( !Validator.isFunction( callbackFunction ) ) {
			return void 0;
		}
		const activeElement = window.document.activeElement;
		const activeElementWasValid = activeElement instanceof HTMLElement;
		this.freezeFocus();
		const returnValue = callbackFunction();
		this.reviveFocus();
		Warner.outputIf( {
			doOutput: DO_LOG,
			text: `The focused active DOM element is ${ window.document.activeElement.outerHTML }.`,
			color: "#7112FA"
		} );
		// console.log( window.document.activeElement );
		if ( window.document.activeElement === activeElement ) {
			return returnValue;
		}
		if ( isTheCallbackAllowedToChangeTheFocus ) {
			return returnValue;
		}
		Warner.traceIf( DO_LOG, `The focus was changed from the previously` +
			` active element despite executing the callback with frozen focus.` +
			` If the callback is supposed to change the focus, please specify` +
			` this when calling/using this method.` );
		if ( HtmHelper.isElementInDocument( activeElement ) &&
			HtmHelper.isElementInBody( activeElement ) ) {
			activeElement.focus();
			return returnValue;
		}
		if ( !activeElementWasValid ) {
			return returnValue;
		}
		Warner.traceIf( DO_LOG, `Could not restore the focus back to the` +
			` element that was active before executing the callback with` +
			` frozen focus. The element that was previously active does not` +
			` exist anymore and/or is no longer valid. The focus change must be` +
			` the result or consequence of the callback itself. ` );
		return returnValue;
	}

}
