import Validator from './Validator';

/**
 * class HtmHelper - specific HTML helper methods
 */

export default class HtmHelper {

	static getIconFontSize( referenceSize ) {
		const DEF_SIZE = 14;
		const MAX_SIZE = 127;
		if ( !Validator.isPositiveNumber( referenceSize ) ) {
			return DEF_SIZE;
		}
		return Math.min( Math.round( referenceSize * 0.825 ), MAX_SIZE );
		// let fontSize = referenceSize > 130 ? 127 :
		// 	referenceSize > 24 ? referenceSize - 4 :
		// 	referenceSize > 12 ? referenceSize - 2 :
		// 	referenceSize > 8 ? referenceSize - 1 :
		// 	referenceSize;
		// if ( fontSize <= 0 ) {
		// 	fontSize = 14;
		// }
		// return fontSize;
	}

	static getAllLevelChildren( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return [];
		}
		const allChildren = element.getElementsByTagName( "*" );
		if ( !Validator.isIterable( allChildren ) ) {
			return [];
		}
		return [ ...allChildren ];
	}

	static justifyContentBasedOnTextAlignment( flexElement, alignment = "" ) {
		if ( !( flexElement instanceof HTMLElement ) ) {
			return false;
		}
		const textAlign = Validator.isString( alignment ) ? alignment :
			flexElement.style.textAlign;
		if ( !Validator.isString( textAlign ) ) {
			return false;
		}
		let justifyContent;
		switch ( textAlign ) {
			case "left":
				justifyContent = "flex-start";
				break;
			case "right":
				justifyContent = "flex-end";
				break;
			case "center":
				justifyContent = "center";
				break;
			case "justify":
				justifyContent = "space-between";
				break;
			default:
				justifyContent = "";
		}
		if ( !Validator.isString( justifyContent ) ) {
			return false;
		}
		flexElement.style.justifyContent = justifyContent;
		return true;
	}

	static removeStyleProperty( element, propertyName ) {
		if ( !( element instanceof HTMLElement ) ||
			!Validator.isString( propertyName )
			// || !( propertyName in element.style )
		) {
			return false;
		}
		element.style[ propertyName ] = "";
		element.style.removeProperty( propertyName );
		return true;
	}

	static isElementInDocument( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		const hasValidId = Validator.isString( element.id );
		const previousElementId = hasValidId ? String( element.id ) : void 0;
		const temporaryId = Validator.generateRandomString( "temporary-id-" );
		element.id = temporaryId;
		const elementIsInDocument =
			window.document.getElementById( temporaryId ) === element;
		hasValidId ? element.is = previousElementId : element.removeAttribute( "id" );
		return elementIsInDocument;
	}

	static bodyContainsElement( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		const body = window.document.body;
		return element === body || body.contains( element );
	}

	static isElementInBody( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		const body = window.document.body;
		if ( element === body ) {
			return true; // or false? the element IS the body
		}
		let parent = element.parentElement;
		while ( parent instanceof HTMLElement && parent != body ) {
			parent = parent.parentElement;
		}
		return parent === body;
	}

	static isElementInDocumentBody( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		const allElements = HtmHelper.getAllLevelChildren( window.document.body );
		return allElements.indexOf( element ) >= 0;
	}

	static getAttributeWidth( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return void 0;
		}
		let width = element.style.width;
		if ( Validator.isString( width ) ) {
			return width;
		}
		width = element.style.minWidth;
		if ( Validator.isString( width ) ) {
			return width;
		}
		width = element.getAttribute( "width" );
		return Validator.isString( width ) ? width : void 0;
	}

	static getNumericAttributePixelWidth( element ) {
		return HtmHelper.stringPixelWidthToNumber(
			HtmHelper.getAttributeWidth( element ) );
	}

	static stringPixelWidthToNumber( width ) {
		if ( Validator.isPositiveNumber( width ) ) {
			return width;
		}
		if ( !Validator.isString( width ) ) {
			return void 0;
		}
		width = width.replaceAll( /[^\d\.\,]+/g, "" );
		width = width.replaceAll( /[\.\,]+/g, "." );
		width = Number( width );
		return Validator.isPositiveNumber( width ) ? width : void 0;
	}

	static isTagName( element, tagName ) {
		if ( !( element instanceof HTMLElement ) || !Validator.isString( tagName ) ) {
			return false;
		}
		return element.tagName.toLowerCase() === tagName.toLowerCase();
	}

	static discardElementProperty( instance, propertyName ) {
		if ( !Validator.isObject( instance ) ||
			!Validator.isString( propertyName ) || !( propertyName in instance ) ) {
			return false;
		}
		let element = instance[ propertyName ];
		instance[ propertyName ] = void 0;
		delete instance[ propertyName ];
		if ( !( element instanceof HTMLElement ) ) {
			return true;
		}
		element.innerHTML = "";
		element.remove();
		element = void 0;
		return true;
	}

	static isFirstRectInsideSecond( {
		firstRect,
		secondRect,
		testVertical = true,
		testHorizontal = true
	} ) {
		if ( [ firstRect, secondRect ]
			.some( rect => !( rect instanceof DOMRect ) ) ) {
			return false;
		}
		if ( testVertical && ( firstRect.y < secondRect.y ||
				firstRect.y + firstRect.height > secondRect.y + secondRect.height ) ) {
			return false;
		}
		if ( testHorizontal && ( firstRect.x < secondRect.x ||
				firstRect.x + firstRect.width > secondRect.x + secondRect.width ) ) {
			return false;
		}
		return true;
	}

	static isFirstRectOverflowingOnTopOfSecondRect( firstRect, secondRect ) {
		if ( [ firstRect, secondRect ]
			.some( rect => !( rect instanceof DOMRect ) ) ) {
			return false;
		}
		return firstRect.y < secondRect.y;
	}

	static isFirstRectOverflowingOnBottomOfSecondRect( firstRect, secondRect ) {
		if ( [ firstRect, secondRect ]
			.some( rect => !( rect instanceof DOMRect ) ) ) {
			return false;
		}
		return firstRect.y + firstRect.height > secondRect.y + secondRect.height;
	}

}

console.log( 'utils/HtmHelper.js loaded.' );
