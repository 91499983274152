import KeyHdl from './KeyHdl';
import PSA from '../psa';

/** minimal (time) distance between two identical key strokes */
const MIN_KEY_DISTANCE = 1500;
/** the [F5] key */
const KEY_F5 = 'F5';

/**
 * a key stroke info class
 */
class KeyStroke {

	/**
	 * constructs a new instance
	 * @param {String} key the key
	 */
	constructor(key) {
		this.key = key;
		this.blocked = false;
		this.tms = 0;
	}

	toString() {
		return `{${ this.key }: tms=${ this.tms }, blocked=${ this.blocked }}`;
	}
}

/**
 * global key listener class
 */
export default class GlbKeyLis {
	
	/**
	 * constructs a new instance
	 * @param {PSA} psa the global PSA instance
	 */
	constructor(psa) {
		this._psa = psa;
		this._dbg = psa.isDbgMode();
		this._strokeMap = new Map();
		this._strokeMap.set(KEY_F5, new KeyStroke(KEY_F5));
		const qxdoc = rwt.widgets.base.ClientDocument.getInstance();
		qxdoc.addEventListener("keydown", this._onQxKeyDown, this);
		// set keydown and keyup listeners in capturing phase
		const dom_doc = window.document;
		const self = this;
		dom_doc.addEventListener('keydown', (evt) => {
			return self._allowKeyEvent(evt, false);
		}, true);
		dom_doc.addEventListener('keyup', (evt) => {
			return self._allowKeyEvent(evt, true);
		}, true);
	}

	/**
	 * RAP key down event handler
	 * @param {Event} evt the keystroke event
	 */
	_onQxKeyDown(evt) {
		const khd = this._psa.getKeyHdl();
		if ( khd ) {
			return khd.hdlKey(evt, true);
		}
		return false;
	}

	/**
	 * checks whether a key event is allowed or it it should be suppressed
	 * @param {KeyboardEvent} key_evt the DOM keyboard event
	 * @param {Boolean} up "key up" flag
	 * @returns {Boolean} true if the key event is allowed; false if it should be suppressed and blocked
	 */
	_allowKeyEvent(key_evt, up) {
		const dbg = this._dbg;
		const key = key_evt.key || '';
		let check = false;
		// at this moment we check only for [F5] key; we *don't* care about modifiers here!
		switch ( key ) {
			case KEY_F5:
				check = true;
				break;
			default:
				break;
		}
		let allow = true;
		if ( check ) {
			if ( dbg ) {
				console.log(`Checking key stroke "${ key }" (${ (up ? 'keyup' : 'keydown') })...`);
			}
			const stroke = this._strokeMap.get(key);
			if ( stroke ) {							// should always be available, but paranoia rulez :-)
				const now = Date.now();
				if ( !up ) {
					// new "keydown" event - check timestamp
					if ( (now - stroke.tms) < MIN_KEY_DISTANCE ) {
						// block!
						allow = false;
					}
					stroke.tms = now;
				} else {
					// a "keyup" event in blocked state is never allowed, otherwise we have nothing to do here
					allow = !stroke.blocked;
				}
				// update "blocked" state
				stroke.blocked = !allow;
			}
			if ( !allow && dbg ) {
				console.log(`Key stroke "${ key }" suppressed!`);
			}
		}
		if ( !allow ) {
			key_evt.stopPropagation();
			key_evt.preventDefault();
		}
		return allow;
	}
}	

console.log('key/glbkeylis.js loaded.');
