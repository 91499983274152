import Utils from '../utils/Utils';
import UIUtil from './uiutil';
import Validator from '../utils/Validator';
import JsRect from '../utils/JsRect';
import JsSize from '../utils/JsSize';
import INFO from '../internal/info';

let singleton = null;

/**
 * item manager; manager class for icons, text objects and other GUI items
 */
export default class ItmMgr {

	/**
	 * constructs a new instance
	 */
	constructor() {
		// IMPORTANT: this class MUST NOT have any attributes! It MUST be fully stateless!
		if ( singleton === null ) {
			singleton = this;
		} else {
			throw new Error('Do not create an instance of ItmMgr! Use the singleton instead.');
		}
	}

	/**
	 * pseudo-destructor
	 */
	destroy() {
		// do nothing so far...
	}

	/**
	 * @returns {ItmMgr} the singleton instance
	 */
	static getInst() {
		return singleton;
	}

	/**
	 * sets the required minimal size of the body tag
	 * @param {Object} args arguments
	 */
	setBdyMinSiz( args ) {
		let wdt = 0;
		let hgt = 0;
		const auto = !!args.auto;
		if ( auto ) {
			// force an absolute minimum
			wdt = Math.max( 1024, Math.round( 0.8 * screen.availWidth ) );
			hgt = Math.max( 736, Math.round( 0.8 * screen.availHeight ) );
		} else {
			if ( typeof args.wdt === 'number' ) {
				wdt = Math.min( args.wdt, Math.round( 0.7 * screen.availWidth ) );
			}
			if ( typeof args.hgt === 'number' ) {
				hgt = Math.min( args.hgt, Math.round( 0.7 * screen.availHeight ) );
			}
		}
		const bdy = document.body;
		if ( wdt > 0 ) {
			bdy.style.minWidth = '' + wdt + 'px';
		}
		if ( hgt > 0 ) {
			bdy.style.minHeight = '' + hgt + 'px';
		}
	}

	/**
	 * sets the CSS class of the specified widget - internal implementation
	 * @param {Object} wdg the RAP widget
	 * @param {Object} args arguments
	 */
	_doSetCssClass( wdg, args ) {
		if ( wdg && ( wdg._element instanceof HTMLElement ) ) {
			const shell = !!args.shell;
			const title = shell ? !!args.title : false;
			const add = !!args.add;
			const csc = args.csc;
			const drop = args.drop || [];
			const has_drop = drop && ( drop.length > 0 );
			const elms = [];
			const classes = [];
			elms.push( wdg._element );
			if ( !shell ) {
				classes.push( csc );
			} else {
				classes.push( csc[ 0 ] );
				if ( title && ( wdg._element.firstElementChild instanceof HTMLElement ) ) {
					const te = wdg._element.firstElementChild.firstElementChild;
					if ( te instanceof HTMLElement ) {
						classes.push( csc[ 1 ] );
						elms.push( te );
					}
				}
			}
			const count = elms.length;
			for ( let i = 0; i < count; ++i ) {
				const e = elms[ i ];
				const c = classes[ i ];
				if ( Validator.isString( c ) ) {
					if ( add ) {
						e.classList.add( c );
					} else {
						e.className = c;
					}
				}
				if ( has_drop ) {
					this.dropCssProps(e, drop);
				}
			}
		}
	}

	/**
	 * 
	 * @param {HTMLElement} element the DOM element
	 * @param {Array<String>} drop an array of (partial) names of CSS properties to be droppend
	 */
	dropCssProps(element, drop) {
		if ( (element instanceof HTMLElement) && drop && (drop.length > 0) ) {
			const css = element.style;
			const pc = css.length;
			for ( let i = pc - 1; i >= 0; --i ) {
				const name = css.item( i );
				drop.forEach( ( r ) => {
					if ( name.includes( r ) ) {
						css.removeProperty( name );
					}
				} );
			}
		}
	}

	/**
	 * sets the CSS class of the specified widget
	 * @param {Object} args arguments
	 */
	setCssCls( args ) {
		const idw = args.idw;
		const shell = !!args.shell;
		const csc = args.csc;
		const drop = args.drop || [];
		const has_drop = drop && ( drop.length > 0 );
		if ( Validator.isString( idw ) && ( has_drop || ( !shell && Validator.isString( csc ) ) || ( shell && Validator.isArray( csc ) ) ) ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			this._doSetCssClass( wdg, args );
		}
	}

	/**
	 * sets the button status; processes font icon resources and changes their color according to the button status if required - internal implementation
	 * @param {*} wdg the RAP widget
	 * @param {*} args arguments
	 */
	 _doSetBtnStatus(wdg, args) {
		if ( wdg && (wdg._element instanceof HTMLElement) ) {
			const elm = wdg._element;
			// all we need are our <i> tags
			const icons = elm.getElementsByTagName('i');
			if ( icons && (icons.length > 0) ) {
				const enabled = !!args.enabled;
				const cnt = icons.length;
				for ( let i=0 ; i < cnt ; ++i ) {
					const ico = icons.item(i);
					if ( ico && ico.dataset && ico.dataset.iconColor ) {
						// that icon has am own color
						if ( enabled ) {
							// set icons color in "enabled" state
							ico.style.color = ico.dataset.iconColor;
						} else {
							// drop any special color in "disabled" state
							ico.style.color = '';
						}
					}
				}
			}
		}
	}

	/**
	 * sets the button status; processes font icon resources and changes their color according to the button status if required
	 * @param {*} args arguments
	 */
	setBtnStatus(args) {
		const idw = args.idw;
		if ( Validator.isString(idw) ) {
			const wdg = rwt.remote.ObjectRegistry.getObject(idw);
			this._doSetBtnStatus(wdg, args);
		}
	}

	/**
	 * sets custom widget properties
	 * @param {Object} args arguments
	 */
	setCusWdgPrp( args ) {
		let idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			let wdg = rap.getObject( idw );
			if ( !wdg ) {
				wdg = rwt.remote.ObjectRegistry.getObject( idw );
			}
			if ( wdg ) {
				let pwg = this._getPar( wdg );
				let dsc = args.ico;
				if ( dsc ) {
					let cls = dsc.cssCls;
					let icn = dsc.icoNam;
					let siz = dsc.icoSiz;
					let clr = dsc.icoClr;
					let fnm = dsc.fntNam;
					if ( ( typeof cls === "string" ) && ( typeof icn === "string" ) && ( typeof siz === "number" ) ) {
						this.creFntIco( pwg, cls, icn, siz, clr, fnm, args.pos, null );
					}
				}
				let nfo = args.nfo;
				if ( nfo ) {
					let hvrclr = this._getRgb( nfo.hvrClr );
					if ( hvrclr ) {
						try {
							let elms = pwg.get();
							if ( elms.length > 0 ) {
								let chl = elms[ 0 ].children;
								if ( chl && chl.length > 0 ) {
									for ( let i = 0; i < chl.length; ++i ) {
										let txt = chl[ i ];
										txt.style.color = hvrclr;
									}
								}
							}
						} catch ( e ) {
							/* *gulp* */
						}
					}
				}
			}
		}
	}

	creSvgImg( svi ) {
		let img = null;
		const url = svi.url || false;
		const svg = svi.svg || '';
		const ovs = svi.ovs || null;
		const wdt = ( ovs ? ovs.cx : svi.wdt ) || 0;
		const hgt = ( ovs ? ovs.cy : svi.hgt ) || 0;
		if ( Validator.isString( svg ) ) {
			const pfx = url ? '' : 'data:image/svg+xml;base64,';
			const src = pfx + svg;
			img = document.createElement( 'img' );
			img.setAttribute( 'src', src );
			if ( ( wdt > 0 ) && ( hgt > 0 ) ) {
				img.setAttribute( 'width', '' + wdt );
				img.setAttribute( 'height', '' + hgt );
				img._psa_width = wdt;
				img._psa_height = hgt;
			}
			img._psa_fixed = !!svi.fix;
		}
		return img;
	}

	setCusWdgImg( args ) {
		const idw = args[ "pisasales.CSTPRP.IDW" ] || '';
		if ( idw ) {
			let wdg = rap.getObject( idw );
			if ( !wdg ) {
				wdg = rwt.remote.ObjectRegistry.getObject( idw );
			}
			if ( wdg ) {
				const pwg = this._getPar( wdg );
				if ( pwg ) {
					const ico = args.img || {};
					const pos = args.pos || { x: 0, y: 0 };
					if ( ( 'IMG' === ico.typ ) && !!ico.svg ) {
						const svi = ico.img;
						const img = this.creSvgImg( svi );
						if ( img ) {
							const wdt = svi.wdt || 0;
							const hgt = svi.hgt || 0;
							const siz = Math.max( wdt, hgt );
							const div = this._creDiv( 'center', siz, pos );
							div.appendChild( img );
							this._addChl( pwg, div );
						}
					}
				}
			}
		}
	}

	/**
	 * sets the background color of the header row of tree or table widget
	 * @param {Object} args arguments
	 */
	setHdrBgc( args ) {
		let idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			let tbl = rwt.remote.ObjectRegistry.getObject( idw );
			if ( tbl ) {
				let bgc = args[ "bgc" ];
				if ( bgc ) {
					let rgb = this._getRgb( bgc );
					if ( rgb ) {
						let col = tbl._header._dummyColumn;
						col.setBackgroundImage( null );
						col.setBackgroundColor( rgb );
					}
				}
			}
		}
	}

	/**
	 * sets the background color of a menu
	 * @param {Object} args arguments
	 */
	setRapMnuClr( args ) {
		const idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			if ( wdg ) {
				const bgc = args.bgc || null;
				if ( bgc ) {
					const rgb = this._getRgb( bgc );
					if ( rgb ) {
						wdg.setBackgroundColor( rgb );
					}
				}
				const txc = args.txc || null;
				if ( txc && wdg._layout ) {
					const rgb = this._getRgb( txc );
					if ( rgb ) {
						const children = wdg._layout.getChildren();
						if ( children && children.length > 0 ) {
							children.forEach( ( child ) => {
								// brute force!
								const element = child._element || null;
								if ( element ) {
									element.style.color = rgb;
									const subs = element.children;
									if ( subs.length > 0 ) {
										const sc = subs.length;
										for ( let i = 0; i < sc; ++i ) {
											subs[ i ].style.color = rgb;
										}
									}
								}
							} );
						}
					}
				}
			}
		}
	}

	/**
	 * checks whether a RAP widget has a specific class
	 * @param {Object} wdg the RAP widget
	 * @param {String} cls class name
	 */
	hasRapCls( wdg, cls ) {
		return Validator.isString( cls ) && ( cls === wdg.basename );
	}

	/**
	 * HACK: changes the background image of a RAP widget; contains another HACK for sashes!
	 * @param {Object} args arguments
	 */
	setBkgImg( args ) {
		const idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			const img = args.img;
			if ( wdg && img && img.length > 0 ) {
				const uri = img[ 0 ];
				if ( Validator.isString( uri ) ) {
					const cls = args.cls;
					let tgt = wdg;
					if ( Validator.isString( cls ) ) {
						tgt = null;
						const chl = wdg._children;
						if ( chl && ( chl.length > 0 ) ) {
							for ( let i = chl.length - 1; !tgt && ( i >= 0 ); --i ) {
								let c = chl[ i ];
								if ( this.hasRapCls( c, cls ) ) {
									tgt = c;
								}
							}
						}
						if ( !tgt ) {
							if ( this.hasRapCls( wdg, cls ) ) {
								tgt = wdg;
							}
						}
					}
					if ( tgt ) {
						const bki = 'url("' + uri + '")'
						if ( cls === 'Sash' ) {
							// HACK! HACK! HACK!
							const sch = tgt._children;
							if ( sch && ( sch.length > 2 ) ) {
								for ( let i = 1; i < 3; ++i ) {
									let div = sch[ i ]._element;
									if ( div ) {
										div.style.backgroundImage = bki;
									}
								}
							}
						} else {
							const div = tgt._element;
							if ( div ) {
								div.style.backgroundImage = bki;
							}
						}
					}
				}
			}
		}
	}

	/**
	 * hacks a RAP sash widget
	 * @param {*} wdg the RAP sash widget
	 * @param {*} args arguments object providing the desired sash size and an orientation flag and/or sash styles
	 */
	_doHackSash( wdg, args ) {
		if ( wdg && wdg._element ) {
			const size = args.size || 0;
			const horz = !!args.horz;
			if ( size > 0 ) {
				const elm = wdg._element;
				const count = elm.children.length;
				if ( count > 1 ) {
					for ( let i = 1; i < count; ++i ) {
						const sub = elm.children[ i ];
						if ( horz ) {
							sub.style.height = '' + size + 'px';
						} else {
							sub.style.width = '' + size + 'px';
						}
					}
				}
			}
			if ( !!args.style ) {
				const bsize = args.bsize || 0;
				if ( bsize > 0 ) {
					const bcolor = this.getRgb( args.bcolor || null );
					const elm = wdg._element;
					const count = elm.children.length;
					const csc = args.csc || '';
					if ( count > 1 ) {
						if ( Validator.isString( csc ) ) {
							const csc_args = { csc: csc, shell: false, add: false, drop: [ 'border', 'background' ] };
							this._doSetCssClass( wdg, csc_args )
						}
						for ( let i = 1; i < count; ++i ) {
							const sub = elm.children[ i ];
							if ( horz ) {
								sub.style.borderLeftWidth = '' + bsize + 'px';
								sub.style.borderRightWidth = '' + bsize + 'px';
								sub.style.borderLeftStyle = 'solid';
								sub.style.borderRightStyle = 'solid';
								if ( bcolor ) {
									sub.style.borderLeftColor = bcolor;
									sub.style.borderRightColor = bcolor;
								}
							} else {
								sub.style.borderTopWidth = '' + bsize + 'px';
								sub.style.borderBottomWidth = '' + bsize + 'px';
								sub.style.borderTopStyle = 'solid';
								sub.style.borderBottomStyle = 'solid';
								if ( bcolor ) {
									sub.style.borderTopColor = bcolor;
									sub.style.borderBottomColor = bcolor;
								}
							}
						}
					}
				}

			}
		}
	}

	/**
	 * hacks RAP sash widgets
	 * @param {String} idw widget ID
	 * @param {*} args arguments object providing the desired sash size and an orientation flag and/or sash styles
	 */
	hackSash( idw, args ) {
		if ( idw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			this._doHackSash( wdg, args );
		}
	}

	/**
	 * hacks the sash(es) or a RAP sash form
	 * @param {String} idw widget ID
	 * @param {*} args arguments object providing the desired sash styles and an orientation flag
	 */
	hackSashForm( idw, args ) {
		const wdg = rwt.remote.ObjectRegistry.getObject( idw );
		if ( wdg && wdg._element ) {
			const horz = !!args.horz;
			const bsize = args.bsize || 0;
			const bcolor = this.getRgb( args.bcolor || null );
			if ( bsize > 0 ) {
				const children = wdg._children || [];
				if ( children.length > 2 ) {
					const count = children.length;
					for ( let i = 0; i < count; ++i ) {
						const chl = children[ i ];
						if ( this.hasRapCls( chl, 'Sash' ) ) {
							this._doHackSash( chl, args );
						}
					}
				}
			}
		}
	}

	/**
	 * creates a DIV displaying text
	 * @param {Object} args arguments
	 */
	creTxtDiv( args ) {
		let idw = args[ "pisasales.CSTPRP.IDW" ];
		let div;
		if ( idw ) {
			let wdg = rwt.remote.ObjectRegistry.getObject( idw );
			if ( wdg ) {
				let txt = args.txt;
				if ( typeof txt === "string" ) {
					div = this._creTxtDiv( this._getPar( wdg ), txt, args.fsz, args.ffm, args.mrg, args.css );
				}
			}
		}
		return div;
	}

	/**
	 * creates a common DIV element
	 * @param {Object} qxp the qooxdoo parent
	 * @param {String} css the CSS class
	 */
	creCmnDiv( qxp, css ) {
		let div = this._creDiv( null, null, null );
		if ( ( typeof css === 'string' ) && ( css.length > 0 ) ) {
			div.className = css;
		}
		this._addChl( qxp, div );
		return div;
	}

	_creDscIco( cls, icn, siz, clr, fnm, css, shrink, nva ) {
		let ico = document.createElement( "i" );
		if ( ( typeof cls === "string" ) && ( typeof icn === "string" ) && ( typeof siz === "number" ) ) {
			ico.className = cls + ' ' + icn;
			if ( fnm && ( typeof fnm === "string" ) && ( fnm.length > 0 ) ) {
				ico.style.fontFamily = fnm;
			}
			let fsz = shrink ? this.getFntSiz( siz ) : siz;
			ico.style.fontSize = fsz + 'px';
			if ( !nva ) { // the "do not set the vertical aligment" flag is *not* set...
				ico.style.verticalAlign = 'text-top';
			}
			if ( clr ) {
				this.setFgrClr( ico, clr );
			}
			if ( css ) {
				Utils.forEachProp( css, function ( n, p ) {
					ico.style[ n ] = p;
				} );
			}
		}
		return ico;
	}

	/**
	 * updates the icon of a font based icon
	 */
	updDscIco( div, dsc ) {
		let ico = div.children[ 0 ];
		ico.className = dsc.cssCls + ' ' + dsc.icoNam;
	}

	/**
	 * creates a font based icon object
	 */
	creFntIco( qxp, cls, icn, siz, clr, fnm, pos, css ) {
		let div = this._creDiv( 'center', siz, pos );
		let ico = this._creDscIco( cls, icn, siz, clr, fnm, css, true, false );
		this._addChl( qxp, div );
		div.appendChild( ico );
		let res = {};
		res.div = div;
		res.ico = ico;
		return res;
	}

	/**
	 * creates an icon from an icon descriptor (font based)
	 */
	creDscIco( dsc, css, shrink ) {
		return this._creDscIco( dsc.cssCls, dsc.icoNam, dsc.icoSiz, dsc.icoClr, dsc.fntNam, css, shrink, false );
	}

	/**
	 * creates the required HTML elements to render an image object
	 * @param {Object} img image descriptor
	 * @param {Number} ics icon size, if greater than zero, then it overrides the size specified by the icon descriptor
	 * @param {Boolean} shrink "shrink font size" flag
	 * @returns {HTMLElement} the created HTML element representing the image
	 */
	creImg( img, ics, shrink ) {
		let ime = null;
		const dsc = img.img;
		if ( img.typ === 'DSC' ) {
			// a font based icon
			const siz = ics > 0 ? ics : dsc.icoSiz;
			const fsz = shrink ? this.getFntSiz( siz ) : siz;
			ime = this._creDscIco( dsc.cssCls, dsc.icoNam, fsz, dsc.icoClr, dsc.fntNam, null, false, true );
		} else {
			// an image (PNG, GIF, JPEG or a SVG icon)
			let itg = null;
			if ( img.svg ) {
				ime = this.creSvgImg( dsc );
			} else {
				itg = document.createElement( 'img' );
				itg.src = dsc[ 0 ];
				itg.width = dsc[ 1 ];
				itg.height = dsc[ 2 ];
				ime = itg;
			}
		}
		return ime;
	}

	/**
	 * scales an image
	 * @param {HTMLElement} itg image tag
	 * @param {Object} img image descriptor
	 * @param {Number} size maximal size
	 */
	scaleImageTag( itg, img, size ) {
		if ( itg && img && ( img.typ === 'IMG' ) && !img.svg && ( size > 0 ) ) {
			const org_wdt = img.img[ 1 ];
			const org_hgt = img.img[ 2 ];
			if ( org_wdt && org_hgt && ( ( org_wdt > size ) || ( org_hgt > size ) ) ) {
				const f = Math.min( size / org_wdt, size / org_hgt );
				itg.style.width = '' + ( f * org_wdt ) + 'px';
				itg.style.height = '' + ( f * org_hgt ) + 'px';
			}
		}
	}

	/**
	 * creates the required HTML elements to render an image object; in contrast to creImg() this method returns
	 * a DIV element that hosts the image
	 * @param {Object} img image descriptor
	 * @param {Number} ics icon size, if greater than zero, then it overrides the size specified by the icon descriptor
	 * @param {Boolean} ssz flag whether to set icon sizes as size properties of the hosting div
	 * @param {Boolean} shrink "shrink font size" flag
	 * @returns {HTMLElement} the created HTML element hosting the image
	 */
	creImgObj( img, ics, ssz, shrink ) {
		const dsc = img.img;
		const imd = document.createElement( 'div' );
		const ime = this.creImg( img, ics, shrink );
		if ( ime ) {
			if ( img.typ === 'DSC' ) {
				const siz = ics > 0 ? ics : dsc.icoSiz;
				const fsz = shrink ? this.getFntSiz( siz ) : siz;
				imd.style.textAlign = 'center';
				imd.style.fontSize = fsz + 'px';
				if ( ssz ) {
					imd.style.height = fsz + 'px';
					imd.style.width = fsz + 'px';
				}
			} else if ( !img.svg ) {
				if ( ssz ) {
					imd.style.width = dsc[ 1 ] + 'px';
					imd.style.height = dsc[ 2 ] + 'px';
				}
			}
			imd.appendChild( ime );
		}
		return imd;
	}

	/**
	 * sets CSS properties
	 * @param {HTMLElement} elm target element
	 * @param {Object} css object providing CSS definitions
	 */
	setCssPrp( elm, css ) {
		if ( css ) {
			Utils.forEachProp( css, function ( n, p ) {
				elm.style[ n ] = p;
			} );
		}
	}

	/**
	 * creates a DIV displaying static text
	 */
	creTxt( qxp, txt, fsz, ffm, mrg, css ) {
		return this._creTxtDiv( qxp, txt, fsz, ffm, mrg, css );
	}

	/**
	 * returns a RGB string from a color specification
	 */
	getRgb( clr ) {
		return this._getRgb( clr );
	}

	/**
	 * sets the foreground color of a DOM element
	 * @param {HTMLElement} elm the target DOM element
	 * @param {Object} color the color descriptor
	 */
	setFgrClr( elm, clr ) {
		let rgb = null;
		if ( clr ) {
			rgb = this._getRgb( clr );
		}
		if ( rgb ) {
			elm.style.color = rgb;
		} else {
			elm.style.color = '';
		}
	}

	/**
	 * sets the background color of a DOM element
	 * @param {HTMLElement} elm the target DOM element
	 * @param {Object} color the color descriptor
	 * @param {Boolean} full flag whether to set the "background" (true) or the "backgroundColor" (false) style property of the target DOM element
	 */
	setBkgClr( elm, clr, full ) {
		let rgb = null;
		if ( clr ) {
			rgb = this._getRgb( clr );
		}
		if ( !rgb ) {
			rgb = '';
		}
		if ( full ) {
			elm.style.background = rgb;
		} else {
			elm.style.backgroundColor = rgb;
		}
	}

	/**
	 * sets the background color of a QX widget
	 */
	setQxBgkClr( qxw, clr ) {
		let rgb = this.getRgb( clr );
		if ( !rgb ) {
			rgb = clr;
		}
		qxw.setBackgroundImage( null );
		qxw.setBackgroundGradient( null );
		qxw.setBackgroundColor( rgb );
	}

	/**
	 * retrieves the label widget of a table column
	 * @param {String} itm widget ID
	 * @returns {Object} column's label widget or null
	 */
	_getColLbl( itm ) {
		let lbl = null;
		if ( itm ) {
			let column = rwt.remote.ObjectRegistry.getObject( itm );
			if ( column && column._grid && column._grid._header ) {
				const hdr = column._grid._header;
				if ( typeof hdr._renderHeaderLabel === 'function' ) {
					if ( !hdr.__org_renderHeaderLabel ) {
						// not yet hooked - hook-in our method
						const org_fnc = hdr._renderHeaderLabel;
						hdr.__org_renderHeaderLabel = org_fnc;
						hdr._renderHeaderLabel = function ( label, column ) {
							hdr.__org_renderHeaderLabel( label, column );
							if ( label.__psa_bgc ) {
								label.setBackgroundColor( label.__psa_bgc );
							}
							if ( label.__psa_txc ) {
								label.setTextColor( label.__psa_txc );
							}
						}
					}
				}
				lbl = hdr._getLabelByColumn( column );
			}
		}
		return lbl;
	}

	/**
	 * sets a color of a column header item
	 * @param {String} itm item / widget ID
	 * @param {Object} clr the color to be set
	 * @param {Boolean} bgc flag whether to set the background color (true) or the foreground color (false)
	 */
	_setHdrItmClr( itm, clr, bgc ) {
		if ( clr && itm ) {
			const lbl = this._getColLbl( itm );
			if ( lbl ) {
				let rgb = this.getRgb( clr );
				if ( !rgb ) {
					rgb = clr;
				}
				if ( bgc ) {
					lbl.setBackgroundColor( rgb );
					lbl.__psa_bgc = rgb;
				} else {
					lbl.setTextColor( rgb );
					lbl.__psa_txc = rgb;
				}
			}
		}
	}

	/**
	 * This method will dye the header background of
	 * a table-column-header to a given color
	 * @param {String} itm item / widget ID
	 * @param {Object} clr the color to be set
	 */
	setHdrColBkgClr( itm, clr ) {
		this._setHdrItmClr( itm, clr, true );
	}

	/**
	 * This method will set the header text of
	 * a table-column-header to a given color
	 * @param {String} itm item / widget ID
	 * @param {Object} clr the color to be set
	 */
	setHdrColTxtClr( itm, clr ) {
		this._setHdrItmClr( itm, clr, false );
	}

	/**
	 * removes any background style from an element
	 */
	rmkBkgStl( elm ) {
		elm.style.backgroundColor = '';
		elm.style.backgroundImage = '';
		elm.style.backgroundAttachment = '';
		elm.style.backgroundClip = '';
		elm.style.backgroundSize = '';
		elm.style.backgroundOrigin = '';
		elm.style.backgroundPosition = '';
		elm.style.backgroundRepeat = '';
	}

	/**
	 * sets the font of a DOM element
	 * @param {HTMLElement} elm the target DOM element
	 * @param {Object} font the font descriptor
	 */
	setFnt( elm, fnt ) {
		const stl = elm.style;
		stl.fontFamily = '';
		stl.fontSize = '';
		stl.fontStyle = '';
		stl.fontWeight = '';
		stl.textDecoration = '';
		if ( fnt instanceof Array ) {
			// an SWT font
			stl.fontFamily = this._getFfm( fnt[ 0 ] );
			stl.fontSize = fnt[ 1 ] === null ? '' : fnt[ 1 ] + 'px';
			stl.fontWeight = fnt[ 2 ] === null ? '' : fnt[ 2 ] ? "bold" : "normal";
			stl.fontStyle = fnt[ 3 ] === null ? '' : fnt[ 3 ] ? "italic" : "normal";
		} else if ( fnt ) {
			// a PiSA sales font descriptor
			if ( fnt.ffm ) {
				stl.fontFamily = fnt.ffm;
			}
			if ( fnt.siz ) {
				stl.fontSize = fnt.siz + 'px';
			}
			if ( fnt.wgt ) {
				stl.fontWeight = fnt.wgt;
			}
			if ( fnt.stl ) {
				stl.fontStyle = fnt.stl;
			}
			if ( Validator.isString( fnt.tdc ) && ( fnt.tdc !== '$NONE' ) ) {
				stl.textDecoration = fnt.tdc;
			}
		}
	}

	/**
	 * creates a CSS string from a font descriptor object
	 * @param {Object} font the font descriptor
	 * @returns {String} the CSS font string
	 */
	getFontCss( font ) {
		let css = '';
		let ffm = '';
		let fsz = '';
		let wgt = '';
		let itc = '';
		if ( font instanceof Array ) {
			ffm = this._getFfm( font[ 0 ] );
			fsz = font[ 1 ] === null ? '' : font[ 1 ] + 'px';
			wgt = font[ 2 ] === null ? '' : font[ 2 ] ? "bold" : "normal";
			itc = font[ 3 ] === null ? '' : font[ 3 ] ? "italic" : "normal";
		} else if ( font ) {
			ffm = font.ffm || '';
			if ( typeof font.fsz === 'number' ) {
				fsz = Math.abs( font.fsz ) + 'px';
			}
			if ( Validator.isString( font.wgt ) ) {
				switch ( font.wgt ) {
					case 'B':
						wgt = 'bold';
						break;
					case 'M':
						wgt = 'medium';
						break;
					default:
						break;
				}
			}
			itc = !!font.itc ? 'italic' : '';
		}
		const styles = [];
		if ( Validator.isString( itc ) ) {
			styles.push( itc );
		}
		if ( Validator.isString( wgt ) ) {
			styles.push( wgt );
		}
		if ( Validator.isString( fsz ) ) {
			styles.push( fsz );
		}
		if ( Validator.isString( ffm ) ) {
			styles.push( ffm );
		}
		css = styles.join( ' ' );
		return css;
	}

	/**
	 * renders the content of a DOM element
	 * @param {HTMLElement} elm the target DOM element
	 * @param {Object} ctt the new content
	 */
	renderCtt( elm, ctt ) {
		if ( ctt ) {
			const text = ctt.text || '';
			if ( ctt.html ) {
				elm.innerHTML = text;
			} else {
				elm.innerText = text;
			}
			const prop = ctt.prop || {};
			this.setFnt( elm, prop.font || null );
			this.setFgrClr( elm, prop.txc || null );
			this.setBkgClr( elm, prop.bgc || null, false );
		} else {
			elm.innerHTML = '';
		}
	}

	/**
	 * sets the overflow and text-overflow styles
	 * @param {HTMLElement} elm the target DOM element
	 * @param {Boolean} nwp "no wrap" flag
	 */
	setTxtOvrFlw( elm, nwp ) {
		elm.style.overflow = 'hidden';
		elm.style.textOverflow = 'ellipsis';
		if ( nwp ) {
			elm.style.whiteSpace = 'nowrap';
		}
	}

	/**
	 * sets the width property of a DOM element that's member of a "flex" parent element
	 * @param {HTMLElement} elm the DOM element
	 * @param {Number} wdt the width in pixels
	 * @param {Boolean} frc false: sets only the "flex-basis" CSS property, true: sets also "min-width" and "width" CSS properties
	 */
	setFlexWdt( elm, wdt, frc ) {
		const ws = wdt + 'px';
		elm.style.flexBasis = ws;
		if ( frc ) {
			elm.style.minWidth = ws;
			elm.style.width = ws;
		}
	}

	/**
	 * sets the height property of a DOM element that's member of a "flex" parent element
	 * @param {HTMLElement} elm the DOM element
	 * @param {Number} hgt the height in pixels
	 * @param {Boolean} frc false: sets only the "flex-basis" CSS property, true: sets also "min-height" and "height" CSS properties
	 */
	setFlexHgt( elm, hgt, frc ) {
		const hs = hgt + 'px';
		elm.style.flexBasis = hs;
		if ( frc ) {
			elm.style.minHeight = hs;
			elm.style.height = hs;
		}
	}

	/**
	 * calculates the size of a DOM element
	 * @param {HTMLElement} elm the DOM element
	 */
	getElementSize( elm ) {
		let res = {};
		const rct = elm.getBoundingClientRect();
		if ( rct ) {
			res.width = rct.width;
			res.height = rct.height;
		} else {
			let wdt = 0;
			let hgt = 0;
			let wds = elm.style.width;
			let hgs = elm.style.height;
			if ( wds && wds.endsWith( 'px' ) ) {
				wdt = Number( wds.substr( 0, wds.length - 2 ) );
			}
			if ( hgs && hgs.endsWith( 'px' ) ) {
				hgt = Number( hgs.substr( 0, hgs.length - 2 ) );
			}
			res.width = wdt;
			res.height = hgt;
		}
		return res;
	}

	/**
	 * measures the text of an element
	 * @param {HTMLElement} elm the DOM element
	 * @param {Boolean} wrp "text wrap" flag
	 * @param {Number} mxw maximal width in pixels; ignored if no text wrap is specified
	 * @param {Boolean} all "break-all" flag for multilne text
	 */
	measureText( elm, wrp, mxw, all ) {
		const res = { sts: new JsSize( 0, 0 ), wts: new JsSize( 0, 0 ) };
		let go = true;
		let tgt = elm;
		let cpy = false;
		let osz = this.getElementSize( elm );
		if ( ( osz.height === 0 ) || ( osz.width === 0 ) ) {
			// we must copy CSS font style
			tgt = document.body;
			cpy = true;
		}
		if ( go ) { // go is always true (?)
			const md = document.createElement( 'div' );
			md.className = 'measureText';
			const txt = elm.innerHTML;
			let frc = false;
			if ( cpy ) {
				const css = elm.parentElement ? window.getComputedStyle( elm ) : elm.style;
				md.style.font = css.font;
				const pc = css.length;
				for ( let i = 0; i < pc; ++i ) {
					const name = css.item( i );
					if ( name.startsWith( 'font' ) ) {
						md.style[ name ] = css[ name ];
					}
				}
				// sure is sure
				if ( !md.style.fontFamily ) {
					md.style.fontFamily = elm.style.fontFamily;
				}
				if ( !md.style.fontSize ) {
					md.style.fontSize = elm.style.fontSize;
				}
				if ( !md.style.fontWeight ) {
					md.style.fontWeight = elm.style.fontWeight;
				}
			}
			md.innerHTML = txt;
			if ( ( typeof mxw === 'number' ) && ( mxw > 0 ) ) {
				md.style.width = mxw + 'px';
				frc = all && ( txt.indexOf( " " ) < 0 );
			}
			tgt.appendChild( md );
			const scx = md.clientWidth; // what does "s" stand for?
			const scy = md.clientHeight;
			tgt.removeChild( md );
			res.sts = new JsSize( scx, scy ); // what is the difference between "sts" and "wts"?
			if ( wrp ) {
				md.className = 'measureTextWrap';
				if ( frc ) {
					md.style.wordBreak = 'break-all';
				}
				tgt.appendChild( md );
				const wcx = md.clientWidth;
				const wcy = md.clientHeight;
				tgt.removeChild( md );
				res.wts = new JsSize( wcx, wcy ); // we are assuming "wts" means "wrapper text size"
			}
		}
		return res;
	}

	/**
	 * calculates the minimal required text width
	 */
	fitTextWidth( elm, mxw, mxh ) {
		let txs = this.measureText( elm, true, mxw, false );
		if ( ( mxw > 2 ) && ( txs.wts.cy <= mxh ) ) {
			let ly = Math.min( mxh, Math.round( 1.25 * txs.wts.cy ) );
			let beg = 2;
			let end = mxw;
			let wdt = Math.round( ( beg + end ) / 2 );
			while ( ( beg < wdt ) && ( wdt < end ) ) {
				let cks = this.measureText( elm, true, wdt, true );
				if ( cks.wts.cy > ly ) {
					// too small - we must become wider
					beg = wdt;
					wdt = Math.round( ( wdt + end ) / 2 );
				} else if ( wdt > ( beg + 2 ) ) {
					// we may shrink event more
					end = wdt;
					wdt = Math.round( ( beg + wdt ) / 2 );
				} else {
					// stop
					beg = end = wdt;
				}
			}
			txs.wts.cx = end;
		}
		return txs;
	}

	/**
	 * calculates the effective font size
	 */
	getFntSiz( siz ) {
		let fsz = siz;
		if ( fsz > 130 ) {
			fsz = 127;
		} else if ( siz > 24 ) {
			fsz -= 4;
		} else if ( siz > 12 ) {
			fsz -= 2;
		} else if ( siz > 8 ) {
			fsz -= 1;
		}
		return fsz;
	}

	/**
	 * sets the font size style of a DOM element
	 */
	setFntSiz( elm, siz ) {
		elm.style.fontSize = '' + this.getFntSiz( siz ) + 'px';
	}

	/**
	 * returns the size of an image
	 */
	getImgSiz( img ) {
		let dsc = img.img;
		if ( "DSC" === img.typ ) {
			let siz = dsc.icoSiz;
			return new JsSize( siz, siz );
		} else {
			return new JsSize( dsc[ 1 ], dsc[ 2 ] );
		}
	}

	/**
	 * sets the placeholder text of a text or combo box widget
	 */
	setPlcTxt( args ) {
		const idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			if ( wdg ) {
				const plc = args.plc || '';
				const cbx = !!args.cbx;
				const txw = cbx ? ( wdg._field || wdg ) : wdg;
				if ( txw && ( typeof txw.getInputElement === 'function' ) ) {
					const elm = txw.getInputElement();
					if ( elm ) {
						elm.placeholder = plc;
						elm.setAttribute( "placeholder", plc );
					}
				}
			}
		}
	}

	/**
	 * sets the border style of a widget
	 * @param {String} idw widget ID
	 * @param {String} style border style
	 * @param {Number} width border width
	 * @param {Object} color color descriptor
	 */
	setWdgBorder( idw, style, width, color ) {
		if ( idw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			if ( wdg && wdg._element ) {
				const element = wdg._element;
				if ( style && width > 0 ) {
					element.style.borderStyle = style;
					element.style.borderWidth = '' + width + 'px';
					element.style.borderColor = this.getRgb( color );
				} else {
					element.style.borderStyle = 'none';
					element.style.borderWidth = '0px';
				}
			}
		}
	}

	/**
	 * Sets the selection range for an input/text widget
	 * @param  {[string]} args.idw widget ID
	 */
	setTxtSelRng( args ) {
		let idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			let wdg = rwt.remote.ObjectRegistry.getObject( idw );
			if ( wdg ) {
				let inputEle = wdg.getInputElement();
				if ( inputEle ) {
					inputEle.selectionStart = args.selSta;
					inputEle.selectionEnd = args.selEnd;
				}
			}
		}
	}

	/**
	 * sets the white space & text overflow CSS attributes for a button widget
	 */
	setNoWrpBtn( args ) {
		const idw = args[ "pisasales.CSTPRP.IDW" ];
		if ( idw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject( idw );
			if ( wdg ) {
				const nowrap = args.nowrap || false;
				const ellipsis = args.ellipsis || false;
				if ( nowrap ) {
					if ( wdg._applyWordWrap && wdg.setStyleProperty ) {
						wdg.setStyleProperty( "white-space", "nowrap" );
						if ( ellipsis ) {
							wdg.setStyleProperty( "text-overflow", "ellipsis" );
						}
						// this is for the button cells
						wdg._applyWordWrap( "nowrap" );
						if ( ellipsis ) {
							wdg._applyTextOverflow( "ellipsis" );
						}
					}
				} else {
					if ( wdg._applyWordWrap && wdg.setStyleProperty ) {
						wdg.setStyleProperty( "white-space", "" );
						wdg._applyTextOverflow( "clip" );
						wdg._applyWordWrap( "" );
					}
				}
			}
		}
	}

	/**
	 * opens an URL in a popup (hopefully...) window
	 */
	popupUrl( args ) {
		const url = args.url;
		if ( Validator.isString( url ) ) {
			const tab = args.tab || false;
			if ( tab ) {
				this.openUrl( args );
				return;
			}
			const max = !!args.max && !args.max; // i.e. false :-)
			const cbw = args.__cbw || null;
			const xid = args.xid || '_blank';
			const xtp = args.xtp || '';
			const scl = 0.90;
			const alf = screen.availLeft || 0;
			const atp = screen.availTop || 0;
			const awd = screen.availWidth || 0;
			const ahg = screen.availHeight || 0;
			const fxs = !!args.fxs;
			const avr = new JsRect( alf, atp, awd, ahg );
			let wdt = args.wdt || Math.round( scl * awd );
			let hgt = args.hgt || Math.round( scl * ahg );
			let lft = alf;
			let top = atp;
			let chk_siz = max;
			let stg_wps = null;
			if ( cbw && xtp && !fxs ) {
				let key = xtp + '_wndpos';
				let val = cbw.getStgVal( key );
				if ( Validator.isString( val ) ) {
					try {
						let wps = JSON.parse( val );
						if ( wps ) {
							if ( typeof wps.x === 'number' && typeof wps.y === 'number' && typeof wps.width === 'number' && typeof wps.height === 'number' ) {
								// we must check this! - if the previous screen is not available (undocked notebook / tablet etc.) then we must reset this!
								const ckr = new JsRect( wps.x, wps.y, wps.width, wps.height );
								if ( avr.intersectRect( ckr ) ) {
									wdt = wps.width;
									hgt = wps.height;
									lft = wps.x;
									top = wps.y;
									if ( INFO.getInstance().isFF() ) {
										// FF has a problem with screens not at 100% scaling factor
										const scn = cbw.getScrNfo();
										if ( scn && ( scn.length > 0 ) ) {
											let scr = null;
											const lim = scn.length;
											for ( let i = 0;
												( scr == null ) && ( i < lim ); ++i ) {
												let chk = scn[ i ];
												if ( chk.bnd.lft === alf ) {
													scr = chk;
												}
											}
											if ( scr ) {
												const scw = scr.wrk ? ( scr.wrk.wdt || 0 ) : 0;
												if ( scw > 0 ) {
													const cor = awd / scw;
													lft = lft * cor;
												}
											}
										}
									}
									stg_wps = wps;
									chk_siz = false;
								} else {
									stg_wps = null;
									chk_siz = true;
								}
							}
						}
					} catch ( e ) {
						/* *gulp* */
					}
				}
			}
			if ( !stg_wps ) {
				lft = max ? alf : alf + Math.round( ( awd - wdt ) / 2 );
				top = max ? atp : atp + Math.min( Math.round( ( ahg - hgt ) / 2 ), 42 );
			}
			let opt = '';
			opt = 'width=' + wdt + ',height=' + hgt + ',left=' + lft + ',top=' + top;
			opt += ',resizable=yes,menubar=no,toolbar=no,location=no';
			const puw = window.open( url, xid, opt );
			if ( !puw || !!puw.closed || ( typeof puw.closed === 'undefined' ) ) {
				// popup blocked! - try regular window
				this.openUrl( args );
			} else if ( puw ) {
				setTimeout( () => {
					// always check the object that we got - in a "native" environment that's a stupid proxy object...
					if ( puw && ( typeof puw.resizeBy === 'function' ) ) {
						const owd = puw.outerWidth || 0;
						const ohg = puw.outerHeight || 0;
						if ( stg_wps ) {
							const xwd = stg_wps.width - owd;
							const xhg = stg_wps.height - ohg;
							puw.resizeBy( xwd, xhg );
						} else if ( chk_siz && max ) {
							const xwd = screen.availWidth - owd - 1;
							const xhg = screen.availHeight - ohg - 1;
							puw.resizeBy( xwd, xhg );
						}
					}
					if ( puw && ( typeof puw.focus === 'function' ) ) {
						puw.focus();
					}
				}, 20 );
			}
		}
	}

	/**
	 * opens an external URL in a new browser window or tab
	 */
	openUrl( args ) {
		const url = args.url;
		if ( Validator.isString( url ) ) {
			window.open( url, '_blank' );
		}
	}

	/**
	 * sets the visible URL of the browser window (usually an external view)
	 */
	setTopUrl( args ) {
		const url = args.url || '';
		const sfx = args.sfx || '';
		const ttl = args.ttl || '';
		if ( Validator.isString( url ) && Validator.isString( sfx ) ) {
			if ( top.window.history && top.window.history.pushState ) {
				top.window.history.pushState( { stu: url }, sfx, url );
			}
		}
		if ( Validator.isString( ttl ) ) {
			document.title = ttl;
		}
	}

	/**
	 * sets the URL of the main window, i.e., a suffix after the '#' mark
	 */
	setMainUrl( args ) {
		let usr = args.usr;
		let app = args.app;
		if ( Validator.isString( usr ) && Validator.isString( app ) ) {
			let org = '' + top.window.location.href + '';
			let pos = org.indexOf( '#' );
			let url = '';
			if ( pos > 0 ) {
				url = org.substring( 0, pos + 1 );
			} else {
				url = org + '#';
			}
			url = url + usr + '@' + app;
			let obj = {};
			top.window.history.pushState( { psa: url }, 'PiSA sales', url );
		}
	}

	/**
	 * checks whether the specified DOM element has a specific CSS class
	 * @see pikaday.js
	 */
	hasCssCls( el, cn ) {
		return ( ' ' + el.className + ' ' ).indexOf( ' ' + cn + ' ' ) !== -1;
	}

	/**
	 * compares two date values for equality
	 */
	isEquDates( d1, d2 ) {
		return ( d1.getDate() === d2.getDate() ) && ( d1.getMonth() === d2.getMonth() ) && ( d1.getYear() === d2.getYear() );
	}

	_getPar( wdg ) {
		if ( wdg.$el ) {
			return wdg.$el;
		} else {
			let par = wdg._getTargetNode();
			if ( !par ) {
				par = wdg.getParent()._getTargetNode();
			}
			return par;
		}
	}

	_addChl( par, chl ) {
		if ( par.appendChild ) {
			par.appendChild( chl );
		} else {
			par.append( chl );
		}
	}

	/**
	 * creates a text item
	 */
	_creTxtDiv( qxp, txt, siz, fnm, mrg, css ) {
		let div = this._creDiv( null, siz, null );
		if ( mrg ) {
			div.style.marginTop = '' + mrg.y + 'px';
			div.style.marginLeft = '' + mrg.x + 'px';
			div.style.marginRight = '' + mrg.w + 'px';
			div.style.marginBottom = '' + mrg.h + 'px';
		}
		if ( fnm && ( typeof fnm === 'string' ) && ( fnm.length > 0 ) ) {
			div.style.fontFamily = fnm;
		}
		if ( ( typeof css === 'string' ) && ( css.length > 0 ) ) {
			div.className = css;
		}
		div.innerHTML = txt;
		this._addChl( qxp, div );
		return div;
	}

	/**
	 * creates a DIV
	 */
	_creDiv( aln, siz, pos ) {
		let div = document.createElement( 'div' );
		if ( siz ) {
			this.setFntSiz( div, siz );
		}
		if ( aln ) {
			div.style.textAlign = aln;
		}
		if ( pos ) {
			div.style.position = 'absolute';
			div.style.left = '' + pos.x + 'px';
			div.style.top = '' + pos.y + 'px';
			div.style.width = '' + siz + 'px';
			div.style.height = '' + siz + 'px';
		}
		return div;
	}

	/**
	 * creates a 'RGB' color description
	 */
	_getRgb( clr ) {
		return UIUtil.getInstance().getCssRgb( clr );
	}

	_getFfm( val ) {
		let ffm = "";
		for ( let i = 0; i < val.length; ++i ) {
			if ( ffm.length > 0 ) {
				ffm += ", ";
			}
			if ( val[ i ].indexOf( " " ) > 0 ) {
				ffm += '"' + val[ i ] + '"';
			} else {
				ffm += val[ i ];
			}
		}
		return ffm;
	}

	_clsAllMen() {
		if ( pisasales.ScrMen ) {
			pisasales.ScrMen.static.closeAllMenus();
		}
	}
}

// create the singleton
singleton = new ItmMgr();

console.log( 'gui/ItmMgr.js loaded.' );
