/**
 * a menu item descriptor helper class
 */
export default class XtwMnuItm {

	/**
	 * constructs a new instance
	 * @param {Number} id menu item's ID
	 * @param {String} txt menu item's text
	 * @param {Boolean} ina "inactive" flag
	 */
	constructor( id, txt, ina = false ) {
		// we create all properties that a menu item expects to get
		this.id = id || 0;
		this.txt = txt || '';
		this.img = null; // main icon
		this.ttlImg = null; // special "title" icon
		this.ina = !!ina;
		this.stc = false;
		this.ena = true;
		this.chk = false;
		this.items = null;
		this.rgtclk = false;
	}

	/**
	 * destructor method
	 */
	destroy() {
		// empty so far
	}
}
