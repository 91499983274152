import Base from '../base/base';
import Validator from './Validator';

/**
 * class ObjReg, a generic object registry
 */
export default class ObjReg extends Base {

	/**
	 * constructs a new instance
	 */
	constructor() {
		super();
		this._objReg = new Map();
	}

	/**
	 * destructor method
	 * @override
	 */
	doDestroy() {
		this.dstChl();
		delete this._objReg;
	}

	/**
	 * adds an object to the registry
	 * @param {String | Number} id object ID
	 * @param {Object} obj the object to be added
	 */
	addObj( id, obj ) {
		const oid = this._getId( id );
		if ( Validator.isString(oid) ) {
			this._objReg.set(oid, obj);
		}
	}

	/**
	 * retrieves an item
	 * @param {String | Number} id object ID
	 * @returns {Object} the registered object or undefined if not found
	 */
	getItem(id) {
		const oid = this._getId( id );
		return this._objReg.get(oid);
	}

	/**
	 * retrieves an object
	 * @param {String | Number} id object ID
	 * @returns {Object} the registered object or null if not found
	 */
	getObj( id ) {
		let obj = this.getItem(id);
		if ( obj === undefined ) {
			obj = null;
		}
		return obj;
	}

	/**
	 * checks whether an entry with the specified ID exists
	 * @param {String | Number} id object ID
	 * @returns {Boolean} true if there's an object with the specified ID; false otherwise
	 */
	hasObj(id) {
		return (this.getItem(id) !== undefined);
	}

	/**
	 * removes an object from the registry
	 * @param {String | Number} id object ID
	 * @returns {Object} the removed object or null if not found
	 */
	rmvObj( id ) {
		const obj = this.getObj(id);
		const oid = this._getId(id);
		this._objReg.delete(oid);
		return obj;
	}

	/**
	 * removes all elements
	 */
	clear() {
		this._objReg.clear();
	}

	/**
	 * destroys and removes all children
	 */
	dstChl() {
		try {
			this._objReg.forEach((o) => {
				if ( o && o.destroy && (typeof o.destroy === 'function') ) {
					o.destroy();
				}
			});
		} finally {
			this.clear();
		}
	}

	/**
	 * returns the number of elements that are currently stored
	 * @returns {Number} the number of elements
	 */
	size() {
		return this._objReg.size;
	}

	/**
	 * indicates whether this instance is empty
	 * @returns {Boolean} true if this instance is empty; false otherwise
	 */
	isEmpty() {
		return this.size() === 0;
	}

	/**
	 * returns a iterable object of all stored objects
	 * @returns {IterableIterator<any>} an iterable object of all values
	 */
	getIterable() {
		return this._objReg.values();
	}

	/**
	 * returns an array of all stored objects
	 * @returns {Array} an array of all stored objects
	 */
	getValues() {
		// we must return an actual array!
		return Array.from(this._objReg.values());
	}

	/**
	 * calls a function for each stored object
	 * @param {Function} action the action to be called for each stored object
	 */
	forEach(action) {
		this._objReg.forEach(action);
	}

	/**
	 * returns the first element of the registry or null if the registry is empty
	 * @returns {Object} the first element or null if the registry is empty
	 */
	getFirst() {
		return this._getEndElm(false);
	}

	/**
	 * returns the last element of the registry or null if the registry is empty
	 * @returns {Object} the last element or null if the registry is empty
	 */
	getLast() {
		return this._getEndElm(true);
	}

	/**
	 * helper method, ensures that we have always a valid ID string
	 * @param {String | Number} id object ID
	 * @returns {String} an always defined ID string
	 */
	_getId( id ) {
		if ( typeof id === 'number' ) {
			return 'o' + id;
		} else {
			return id || '';
		}
	}

	/**
	 * returns the element at one end of the array
	 * @param {Boolean} last flag whether to return the first element (false) or the last element (true)
	 * @returns {Object} the requested element or null if the registry is empty
	 */
	_getEndElm(last) {
		const array = this.getValues();
		return (array.length > 0) ? (last ? array[array.length-1] : array[0]) : null;
	}
}

console.log('utils/ObjReg.js loaded.');