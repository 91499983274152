import XtwMnuItm from './XtwMnuItm';

/**
 * a helper class for sorting support
 */
export default class XtwSortItem extends XtwMnuItm {

	/**
	 * constructs a new instance
	 * @param {Number} id column ID
	 * @param {String} text title text
	 * @param {Number} type data type indicator
	 * @param {Boolean} dir default sort direction
	 * @param {Boolean} ina "inactive" flag
	 */
	constructor( id, text, type, dir, ina = false ) {
		super( id, text, ina );
		this.type = type || 0;
		this.direction = !!dir;
	}

	/**
	 * @returns {Boolean} true for ascending sort order; false for descending sort order
	 */
	isAscending() {
		return !this.direction;
	}

	/**
	 * @returns {Boolean} true for descending sort order; false for ascending sort order
	 */
	isDescending() {
		return this.direction;
	}

	/**
	 * @returns {Boolean} true if this item shows common text
	 */
	isText() {
		return !this.type;
	}

	/**
	 * @returns {Boolean} true if this item shows numeric values
	 */
	isNumeric() {
		return this.type === 1;
	}

	/**
	 * @returns {Boolean} true if this item shows date/time values
	 */
	isDateTime() {
		return this.type === 2;
	}
}
