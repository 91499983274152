import Validator from './Validator';

const DO_LOG = false;

/**
 * class Warner - console output
 */
export default class Warner {

	static _trace( logMessage, errorOrErrorText = null ) {
		if ( !DO_LOG ) return;
		if ( !Validator.isString( logMessage ) ) logMessage = "";
		console.group( "[ " + Warner.getCurrentTime() + " ] " + logMessage );
		if ( Validator.isString( errorOrErrorText ) ||
			Validator.isObject( errorOrErrorText ) ) {
			console.groupCollapsed( "%cError", "color: #009688" );
			console.warn( errorOrErrorText );
			console.groupEnd();
		}
		console.groupCollapsed( "%c Call Stack", "color: #009688" );
		console.trace( "%c ", "color: #009688" );
		console.groupEnd();
		console.groupEnd();
	}

	static traceIf( doTrace = true, logMessage, errorOrErrorText = null ) {
		if ( !doTrace || !DO_LOG ) {
			return;
		}
		let fullFunctionName = Warner.getFullFunctionName( 1, "." );
		let message = "";
		if ( Validator.isString( fullFunctionName ) ) message += fullFunctionName;
		if ( Validator.isString( logMessage ) )
			message += !Validator.isString( message ) ? logMessage : "\n" + logMessage;
		Warner._trace( message, errorOrErrorText );
	}

	static _traceIf( logMessage, doTrace = true, errorOrErrorText = null ) {
		if ( !doTrace || !DO_LOG ) {
			return;
		}
		Warner._trace( logMessage, errorOrErrorText );
	}

	static trace( { logMessage, errorOrErrorText = null, returnValue = void 0 } ) {
		if ( !DO_LOG ) return returnValue;
		Warner._trace( logMessage, errorOrErrorText );
		return returnValue;
	}

	static outputIf( {
		doOutput = true,
		returnValue = void 0,
		errorText,
		text,
		pluginName,
		color,
		addTimestamp = true
	} ) {
		if ( !doOutput || !DO_LOG ) return returnValue;
		if ( !Validator.isString( text ) ) text = "";
		const hasStyle = Validator.isString( color );
		const style = !hasStyle ? "" : `color: ${ color }`;
		let prefix = "";
		if ( addTimestamp ) prefix = `[ ${ Warner.getCurrentTime() } ]`;
		const fullFunctionName = Warner.getFullFunctionName( 1, "." );
		if ( Validator.isString( fullFunctionName ) ) prefix += " " + fullFunctionName;
		if ( Validator.isString( pluginName ) ) prefix += " " + pluginName;
		if ( Validator.isString( prefix ) ) prefix = prefix + "\n";
		!hasStyle ? console.groupCollapsed( prefix + text ) :
			console.groupCollapsed( "%c" + prefix + text, style );
		if ( Validator.isString( errorText ) ) {
			!hasStyle ? console.groupCollapsed( "Error" ) :
				console.groupCollapsed( "%cError", style );
			console.warn( errorText );
			console.groupEnd();
		};
		!hasStyle ? console.trace( "Call Stack" ) :
			console.trace( "%cCall Stack", style );
		console.groupEnd();
		return returnValue;
	}

	static getCurrentTime() {
		let fullDate = new Date();
		let hours = Warner.dateNumberToString( fullDate.getHours() );
		let minutes = Warner.dateNumberToString( fullDate.getMinutes() );
		let seconds = Warner.dateNumberToString( fullDate.getSeconds() );
		let milliseconds = Warner.dateNumberToString( fullDate.getMilliseconds(), true );
		return `${ hours }:${ minutes }:${ seconds }:${ milliseconds }`;
	}

	static dateNumberToString( dateNumber, threeDigits = false ) {
		if ( !Validator.isPositiveInteger( dateNumber ) ) return "";
		let stringDateNumber = dateNumber.toString();
		while ( stringDateNumber.length < 2 )
			stringDateNumber = "0" + stringDateNumber;
		while ( threeDigits && stringDateNumber.length < 3 )
			stringDateNumber = "0" + stringDateNumber;
		return stringDateNumber;
	}

	static attachWarnerTo( hostObject ) {
		return Validator.mirrorFromClass( hostObject, Warner );
	}

	static getFullFunctionName( topEntriesToRemove = 0, separator = "#" ) {
		topEntriesToRemove = Validator.isInteger( topEntriesToRemove ) ?
			topEntriesToRemove + 2 : 2;
		let stack = Warner.getStack( topEntriesToRemove );
		if ( !Validator.isString( stack ) ) return void 0;
		if ( stack.startsWith( "at " ) ) {
			stack = stack.substring( 3 );
		}
		const spaceIndex = stack.indexOf( " " );
		if ( !Validator.isInteger( spaceIndex ) || spaceIndex < 0 ||
			stack.length - 1 < spaceIndex + 1 ) return stack;
		stack = stack.substring( 0, spaceIndex );
		const atIndex = stack.indexOf( "@" );
		if ( Validator.isInteger( atIndex ) && atIndex >= 0 &&
			stack.length - 1 >= atIndex + 1 ) stack = stack.substring( 0, atIndex );
		if ( Validator.isString( separator ) ) {
			stack = stack.replace( ".", separator );
		}
		return stack;
	}

	static getStack( topEntriesToRemove = 1 ) {
		const err = new Error();
		if ( !Validator.isObject( err ) ) return void 0;
		let stack = err.stack;
		if ( !Validator.isString( stack ) ) return void 0;
		if ( !Validator.isInteger( topEntriesToRemove ) )
			topEntriesToRemove = 1;
		if ( Validator.firstMatchIndex( stack, /\sat\s/g ) < 0 ) topEntriesToRemove--;
		while ( topEntriesToRemove > -1 ) {
			const firstNewLineIndex = stack.indexOf( "\n" );
			if ( !Validator.isInteger( firstNewLineIndex ) || firstNewLineIndex < 0 ||
				stack.length - 1 < firstNewLineIndex + 1 ) break;
			stack = stack.substring( firstNewLineIndex + 1 );
			topEntriesToRemove--;
		}
		while ( stack.length > 0 && !!stack.charAt( 0 ).match( /\s/gi ) )
			stack = stack.substring( 1 );
		return stack;
	}
}

console.log( 'utils/Warner.js loaded.' );
