import PSA from '../../psa';

const ICON_LEFT = '<i class="far fa-angle-double-left"></i>';
const ICON_RIGHT = '<i class="far fa-angle-double-right"></i>';

/**
 * toggle button for right and left hand views
 */
export default class VieTglBtn {

	/**
	 * constructs a new instance
	 * @param {PSA} psa the PSA instance
	 * @param {CliCbkWdg} cbw callback widget
	 */
	 constructor(psa, cbw) {
		this._psa = psa;
		this.cbkWdg = cbw;
		this.lftTglBtn = null;
		this.rgtTglBtn = null;
		this.startX = 0;
		this._bndRmvAllBtnLst = this._psa.bind(this, this._rmvAllBtnLsr);
		this._bndOnMouseUp = this._psa.bind(this, this._onMouseUp);
		this._bndDragObject = this._psa.bind(this, this._dragObject);
	}

	/**
	 * destructor method
	 */
	destroy() {
		this._rmvAllBtnLsr(null);
		if ( this.lftTglBtn ) {
			this.lftTglBtn.removeEventListener( "mouseup", this._bndOnMouseUp );
			this._psa.rmvDomElm(this.lftTglBtn);
			delete this.lftTglBtn;
		}
		if ( this.rgtTglBtn ) {
			this._psa.rmvDomElm(this.rgtTglBtn);
			this.rgtTglBtn.removeEventListener( "mouseup", this._bndOnMouseUp );
			delete this.rgtTglBtn;
		}
		this.cbkWdg = null;
		delete this._bndRmvAllBtnLst;
		delete this._bndOnMouseUp;
		delete this._bndDragObject;
	}

	/**
	 * draws a toggle button on top of the body element
	 * @param {Object} args argument list ( args.dir = direction as string)
	 */
	drwBtn(args) {
		const dir = 'left' !== args.dir;
		if ( dir ) {
			if ( this.rgtTglBtn == null ) {
				this._drwBtn( true, args.vis, args.id, args.top );
			} else {
				this.rgtTglBtn.__id = args.id;
			}
		} else {
			if ( this.lftTglBtn == null ) {
				this._drwBtn( false, args.vis, args.id, args.top );
			} else {
				this.lftTglBtn.__id = args.id;
			}
		}
	}

	/**
	 * removes all toggle buttons
	 * @param {Object} args arguments
	 */
	rmvBtn(args) {
		const id = args.id || '';
		const frc = args.frc || false;
		if ( this._psa.isStr( id ) ) {
			let btn = this._getBtn(id, frc, true);
			while ( btn ) {
				this._psa.rmvDomElm(btn);
				btn = this._getBtn(id, frc, true);
			}
		}
	}

	/**
	 * simulates a button click
	 * @param {Object} args arguments
	 */
	simClk( args ) {
		const id = args.id || '';
		if ( this._psa.isStr( id ) ) {
			const btn = this._getBtn(id, false, false);
			if ( btn ) {
				// ok simulate the click
				const e = {};
				e.currentTarget = btn;
				e.force = true;
				this._onMouseUp( e );
			}
		}
	}

	/**
	 * sets the current status of a toggle button
	 * @param {*} args arguments
	 */
	setTglBtn(args) {
		const id = args.id || '';
		if ( this._psa.isStr( id ) ) {
			const btn = this._getBtn(id, false, false);
			if ( btn ) {
				const vis = !!args.vis;
				btn.__cusVisFlag = vis;
				if ( btn.__direction ) {
					btn.innerHTML = vis ? ICON_LEFT : ICON_RIGHT;
				} else {
					btn.innerHTML = vis ? ICON_RIGHT : ICON_LEFT;
				}
			}
		}
	}

	/**
	 * retrieves a button element
	 * @param {String} id button ID
	 * @param {Boolean} frc "force" flag
	 * @param {Boolean} rmv "remove" flag
	 * @returns {HTMLElement} the button element or null
	 */
	_getBtn(id, frc, rmv) {
		let btn = null;
		if ( this._psa.isStr( id ) ) {
			if ( this.lftTglBtn && (frc || (id === this.lftTglBtn.__id) ) ) {
				btn = this.lftTglBtn;
				if ( rmv ) {
					this.lftTglBtn = null;
				}
			}
			else if ( this.rgtTglBtn && (frc || (id === this.rgtTglBtn.__id ) ) ) {
				btn = this.rgtTglBtn;
				if ( rmv ) {
					this.rgtTglBtn = null;
				}
			}
		}
		return btn;
	}

	/**
	 * draws the actual HTML construct
	 * @param {Boolean} direction [true == right, false == left]
	 * @param {Boolean} vis of the view at instantiation time
	 * @param {String} id button ID
	 * @param {Boolean} top "top" flag
	 */
	 _drwBtn( direction, vis, id, top ) {
		const btn = document.createElement( "div" );
		btn.className = "vieTglBtn icoDscDiv";
		const self = this;
		this._addDrgLsn( btn, self );
		this._addClkLsn( btn, self );
		if ( direction ) {
			btn.innerHTML = vis ? ICON_LEFT : ICON_RIGHT;
			btn.classList.add("vieTglRgt");
			this.rgtTglBtn = btn;
		} else {
			btn.innerHTML = vis ? ICON_RIGHT : ICON_LEFT;
			btn.classList.add("vieTglLft");
			this.lftTglBtn = btn;
		}
		btn.__cusVisFlag = vis;
		btn.__id = id;
		btn.__direction = direction;
		if ( top ) {
			btn.style.top = top;
		}
		btn.classList.add('no-print');
		document.body.appendChild( btn );
	}

	/**
	 * adds a drag listener
	 * @param {HTMLElement} aBtn the button element
	 * @param {VieTglBtn} self a 'this' reference
	 */
	_addDrgLsn( aBtn, self ) {
		aBtn.addEventListener( "mousedown", ( e ) => {
			self._startDrag( e, aBtn, self );
		} );
	}

	/**
	 * gets the button element from an HTML element
	 * @param {HTMLElement} element the DOM element
	 * @returns {HTMLElement} the button element
	 */
	_getBtnFromElement(element) {
		let btn = element;
		while ( btn && (btn !== this.lftTglBtn) && (btn !== this.rgtTglBtn) ) {
			btn = btn.parentElement;
		}
		return btn;
	}

	/**
	 * install mouse-move listener and set offset
	 * @param {MouseEvent} e the mouse event
	 * @param {HTMLElement} aBtn the button element
	 * @param {VieTglBtn} self a 'this' reference
	 */
	_startDrag( e, aBtn, self ) {
		const rect = aBtn.getBoundingClientRect();
		self.startX = rect.top;
		aBtn.style.position = 'absolute';
		const btn = this._getBtnFromElement(aBtn || e.target);
		if ( !btn ) {
			return;
		}
		if ( e.type === 'mousedown' ) {
			btn.yOffset = e.clientY - rect.top;
			aBtn.addEventListener( 'mousemove', self._bndDragObject, true );
			window.addEventListener( 'mouseup', self._bndRmvAllBtnLst, true );
		} else if ( e.type === 'touchstart' ) {
			btn.yOffset = e.targetTouches[0].clientY - rect.top;
			aBtn.addEventListener( 'touchmove', self._bndDragObject, true );
		}
	}

	/**
	 * drags an element
	 * @param {MouseEvent} e the mouse event
	 */
	_dragObject( e ) {
		e.preventDefault();
		e.stopPropagation();
		const btn = this._getBtnFromElement(e.target);
		if ( !btn ) {
			return; // no object, no fun
		} else {
			let newX = 0;
			const oldX = btn.style.top ? parseInt( btn.style.top ) : 0;
			if ( e.type == "mousemove" ) {
				newX = e.clientY - btn.yOffset - 22;
			} else if ( e.type == "touchmove" ) {
				newX = e.targetTouches[0].clientY - btn.yOffset;
			}
			const  windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
			if ( newX > 0 && newX < windowHeight && Math.abs( newX - oldX ) > 5 ) {
				btn.style.top = newX + "px";
			}
		}
	}

	/**
	 * adds a click listener
	 * @param {HTMLElement} aBtn the button element
	 * @param {VieTglBtn} self a 'this' reference
	 */
	 _addClkLsn( aBtn, self ) {
		aBtn.addEventListener( "mouseup", self._bndOnMouseUp);
	}

	/**
	 * "mouse up" listener
	 * @param {MouseEvent} e the mouse event
	 */
	_onMouseUp( e ) {
		const aBtn = this._getBtnFromElement(e.currentTarget);
		const frc = !!e.force;
		const newTop = aBtn.style.top ? aBtn.style.top : this.startX;
		if ( this && ( frc || ( Math.abs( parseInt( this.startX ) - parseInt( newTop ) ) < 30 ) ) ) {
			if ( aBtn.innerHTML.indexOf( "left" ) != -1 ) {
				aBtn.innerHTML = ICON_RIGHT;
			} else {
				aBtn.innerHTML = ICON_LEFT;
			}
			const par = {};
			par.id = aBtn.__id || '';
			par.vis = !aBtn.__cusVisFlag;
			par.viwDir = !!aBtn.__direction ? "right" : "left";
			this.cbkWdg.callSrv( null, "viwTgl", par );
			aBtn.__cusVisFlag = par.vis;
		}
	}

	/**
	 * removes several mouse listeners
	 * @param {MouseEvent} e the mouse event
	 */
	_rmvAllBtnLsr( e ) {
		let btn = this.lftTglBtn;
		if ( btn != null ) {
			btn.removeEventListener( 'mousemove', this._bndDragObject, true );
		}
		btn = this.rgtTglBtn;
		if ( btn != null ) {
			btn.removeEventListener( 'mousemove', this._bndDragObject, true );
		}
		window.removeEventListener( 'mouseup', this._bndRmvAllBtnLst, true );
	}
}

console.log('gui/misc/VieTglBtn.js loaded.');