import PSA from '../../psa';
import CallbackManager from '../../utils/CallbackManager';
import Validator from '../../utils/Validator';
import ItmMgr from '../../gui/ItmMgr';

const KEY_F2 = 'f2';
const KEY_F5 = 'f5';
const KEY_CTRL_S = 'Ctrl+S';
const KEY_CTRL_Q = 'Ctrl+Q';
const PRP_SPELLCHECK = 'cke5spellCheck';
const OBJ_LNK_RGX = '<span><a href="[^"]+(?=">.*<\/a> &nbsp;<\/span>)';
const IMG_RGX = '<img [^<>]+>';
const INS_PSA_OBJ_CMD = "insertPisaObject";
/** minimal default font size 12px (~8pt) */
const MINIMAL_DEFAULT_FONT_SIZE = 12;

// mouseup events are necessary in order to trigger the resize of the modal
// window containing the editor, which reacts to mouseup;
// so the propagation of the "mouseup" event should not be stopped
const BUBBLING_EVENTS_NAMES = [ 'mousedown',
	// 'mouseup',
	'mouseover', 'wheel',
	'selectstart', 'selectionchange', 'dragstart', 'dragover', 'keydown',
	'keyup', 'keypress'
];

/**
 * stops the bubbling of an event
 * @param {Event} evt the event that should not bubble further up in the DOM tree
 */
function stopBubbling( evt ) {
	evt.stopPropagation();
}

/**
 * adds the required "stop bubbling" listeners to make the editor work...
 * @param {HTMLElement} container the container div
 */
function setupCke5ContainerListeners( container ) {
	BUBBLING_EVENTS_NAMES.forEach( eventName => {
		container.addEventListener( eventName, stopBubbling );
	} );
}

/**
 * removes the required "stop bubbling" listeners to make the editor work...
 * @param {HTMLElement} container the container div
 */
function removeCke5ContainerListeners( container ) {
	BUBBLING_EVENTS_NAMES.forEach( eventName => {
		container.removeEventListener( eventName, stopBubbling );
	} );
}

/**
 * CK Editor 5 hosting class
 */
export default class CkEdt5 {

	/**
	 * constructs a new instance
	 * @param {Object} properties initialization properties
	 */
	constructor( properties ) {
		this._psa = PSA.getInst();
		// bind event handlers
		this._psa.bindAll( this, [ "layout", "onReady", "onRender" ] );
		this.initialValues = {
			_wdgReady: false,
			edtReady: false,
			iniCtt: null,
			iniPtx: false,
			isDirty: false,
			dirtyState: false,
			hasNotified: false,
			inSetData: false,
			hasFocus: false,
			txtFnt: null,
			rngId: 0,
			curRng: null,
			dlgAcv: false,
			focusByUser: true,
			sreenBlocked: false,
			enabled: true,
			selectionSetOnTop: false,
			tempImageData: { transaction: 1 },
			iniTtl: false,
			reaOnl: false,
			afterWidgetBecomesReadyCallbacks: new Map(),
			afterEditorInitialisationCallbacks: new Map()
		};
		this.initialProperties = properties;
		this.initialTextFontParameters = void 0;
		this.defineListenerFunctionsAsProperties();

		this.create( properties );

		// activate "render" event
		// not sure if this listener should be removed and added again during
		// every regeneration (???)
		rap.on( "render", this.onRender );
		this.addWidgetReadyLazySetter();
	}

	create( properties ) {
		this.setInitialValues();
		this.setInitialValuesBasedOnProperties( properties );
		this.createAndAttachEditorWidgetContainerElement();
		if ( typeof this.initialTextFontParameters === "object" ) {
			this.setTxtFnt( this.initialTextFontParameters );
			this.initialTextFontParameters = null;
		}
		// DO NOT wait before the hosting dialog becomes active!
		this._init( this.cwd );
		this.addRapObjectListeners();
		this.addEditorWidgetContainerElementEventListeners();
	}

	reset() {
		this.removeEditorWidgetContainerElementEventListeners();
		this.removeRapObjectListeners();
		this._resetInit();
		this.detachAndDestroyEditorWidgetContainerElement();
		this.destroy();
		this.voidAndDeletePropertyBasedInitialValues();
		this.voidAndDeleteInitialValues();
	}

	recreate() {
		this.create( this.initialProperties );
		this.onRender();
	}

	regenerate() {
		this.reset();
		this.recreate();
	}

	setInitialValues() {
		let self = this;
		Object.entries( this.initialValues ).forEach( ( flagEntry, index ) => {
			self[ flagEntry[ 0 ] ] = flagEntry[ 1 ];
		} );
	}

	voidAndDeleteInitialValues() {
		let self = this;
		Object.entries( this.initialValues ).forEach( ( flagEntry, index ) => {
			self[ flagEntry[ 0 ] ] = void 0;
			delete self[ flagEntry[ 0 ] ];
		} );
		delete this.alreadyAskedIfEditingAllowed;
	}

	setInitialValuesBasedOnProperties( properties ) {
		const idp = properties.parent;
		this.wdgId = idp;
		this.parent = rap.getObject( idp );
		this.cwd = this.parent.getData( 'pisasales.CSTPRP.CWD' ) || {};
		this.language = this.cwd ? this.cwd.lng : "en";
		this.imageUploadUrl = this.cwd ? this.cwd.imageUploadUrl : null;
		this.isHtmlAllowed = this.cwd ? this.cwd.isHtmlAllowed : true;
		this.iniPlh = ( this.cwd ? this.cwd.plh : false ) || false;
		this.basic = ( this.cwd ? this.cwd.basic : false ) || false;
		this.reaOnl = false;
		this.ovlHgsId = "pisa-hourglass-waiting-overlay";
		this.clientSideImageXHR = this.cwd.clientSideImageXHR;
		this.isSecondEditor = this.cwd ? this.cwd.isSecondEditor : false;
	}

	voidAndDeletePropertyBasedInitialValues() {
		this.wdgId = void 0;
		delete this.wdgId;
		this.parent = void 0;
		delete this.parent;
		this.cwd = void 0;
		delete this.cwd;
		this.language = void 0;
		delete this.language;
		this.imageUploadUrl = void 0;
		delete this.imageUploadUrl;
		this.iniPlh = void 0;
		delete this.iniPlh;
		this.basic = void 0;
		delete this.basic;
		this.isHtmlAllowed = void 0;
		delete this.isHtmlAllowed;
	}

	createAndAttachEditorWidgetContainerElement() {
		let editorContainerElement = document.createElement( 'div' );
		editorContainerElement.id = 'cke5elm_' + this.wdgId;
		editorContainerElement.style.position = 'absolute';
		this.parent.append( editorContainerElement );
		this.element = editorContainerElement;
	}

	detachAndDestroyEditorWidgetContainerElement() {
		let editorContainerElement = this.element;
		this.element = void 0;
		delete this.element;
		for ( let childNode of editorContainerElement.childNodes ) {
			childNode.innerHTML = "";
			editorContainerElement.removeChild( childNode );
		}
		editorContainerElement.innerHTML = "";
		editorContainerElement.remove();
		editorContainerElement = void 0;
	}

	addRapObjectListeners() {
		this.parent.addListener( "Resize", this.layout );
		this.parent.addListener( "FocusOut", this.parentFocusOutListener );
		this.parent.addListener( "FocusIn", this.parentFocusInListener );
	}

	removeRapObjectListeners() {
		this.parent.removeListener( "Resize", this.layout );
		this.parent.removeListener( "FocusOut", this.parentFocusOutListener );
		this.parent.removeListener( "FocusIn", this.parentFocusInListener );
	}

	addEditorWidgetContainerElementEventListeners() {
		this.element.addEventListener( 'contextmenu',
			this.elementContextMenuListener );
	}

	removeEditorWidgetContainerElementEventListeners() {
		this.element.removeEventListener( 'contextmenu',
			this.elementContextMenuListener );
	}

	defineListenerFunctionsAsProperties() {
		this.parentFocusOutListener = ( evt ) => {
			this._handleFocus( false );
		};
		this.parentFocusInListener = ( evt ) => {
			this._handleFocus( true );
		};
		this.elementContextMenuListener = ( evt ) => {
			this.onContextMenu( this, evt );
		};
		this.changeListener = ( eventInfo, batch ) => {
			if ( !this.watchdogEditingEnabled || !this.editorReady ||
				this.editor.notifyDirty == false ||
				typeof this._changeData != "function" ) return;
			this._changeData();
		};
		this.imageInsertListener = () => {
			if ( !this.editorReady ) return;
			this.onImageInsert( this );
		};
		this.imageDropListener = ( eventInfo, value ) => {
			if ( !this.editorReady ) return;
			this.onImageDrop( this, {
				images: value.images,
				count: value.images.length
			} );
		};
		this.imageUploadListener = ( eventInfo, images ) => {
			if ( !this.editorReady ) return;
			this.onImageUpload( images );
		};
		this.spellcheckListener = ( eventInfo, value ) => {
			if ( !this.editorReady ) return;
			this.toggleSpellcheck( this, !!value );
		};
		this.objectLinkListener = ( eventInfo, value ) => {
			if ( !this.editorReady ) return;
			this.linkPisaObject( this, value.key );
		};
		this.insertPlaceholderListener = ( eventInfo, options ) => {
			if ( !this.editorReady ) return;
			this.requestPlaceholders( this, options );
		};
		this.refreshPlaceholderListener = ( eventInfo, placeholders ) => {
			if ( !this.editorReady ) return;
			this.requestPlaceholdersRefresh( this, placeholders );
		};
		this.toolbarChangedListener = () => {
			const editor = this.editor;
			if ( !editor || typeof editor != "object" ||
				typeof editor.psaForceLayout != "function" ) return;
			editor.psaForceLayout();
		};
		this.setPlainTextListener = () => {
			this._setPlainText( true );
		};
		this.setHtmlListener = () => {
			this._setPlainText( false );
		};
		this.contentChangeListener = ( eventInfo, frc ) => {
			this._onPsaCttChn( frc );
		};
		this.focusChangedListener = ( eventInfo, parameters ) => {
			if ( !this.watchdogEditingEnabled || !this.editorReady ) return;
			this._handleFocus( parameters.gainedFocus );
			if ( !parameters.gainedFocus ) return;
			const editor = this.editor;
			if ( !editor || typeof editor != "object" ) return;
			if ( !editor.objects || typeof editor.objects != "object" ) return;
			if ( !editor.objects.selection ||
				typeof editor.objects.selection != "object" ) return;
			const selectionObject = editor.objects.selection;
			if ( selectionObject.fakeFocusRange ) {
				if ( typeof selectionObject._setToFakeFocusRange == "function" )
					selectionObject._setToFakeFocusRange();
				return;
			}
			if ( this.focusByUser ) {
				if ( typeof selectionObject._setToFirstFocusRange == "function" )
					selectionObject._setToFirstFocusRange();
				return;
			}
			this.focusByUser = true;
		};
		this.f2KeyListener = ( eventInfo ) => {
			if ( !this.editorReady ) return;
			this._handleHotkey( KEY_F2, eventInfo );
		};
		this.f5KeyListener = ( eventInfo ) => {
			if ( !this.editorReady ) return;
			this._handleHotkey( KEY_F5, eventInfo );
		};
		this.contentSetListener = ( eventInfo ) => {
			if ( !this.editorReady ) return;
			this._handleContentSet( eventInfo );
		};
		this.contentDeleteListener = () => {
			if ( !this.editorReady ) return;
			this.onContentDelete();
		};
		this.ctrlSKeyListener = ( eventInfo ) => {
			if ( !this.editorReady ) return;
			this._handleHotkey( KEY_CTRL_S, eventInfo );
		};
		this.ctrlQKeyListener = ( eventInfo ) => {
			if ( !this.editorReady ) return;
			this._handleHotkey( KEY_CTRL_Q, eventInfo );
		};
		this.documentClickListener = ( eventInfo, data ) => {
			// TODO does it need?: if ( !this.editorReady ) return;
			this.onElementClick( this, eventInfo, data );
		};
		this.stateChangeListener = ( eventInfo, name, newValue, oldValue ) => {
			if ( newValue != "crashedPermanently" ) return;
			// do something ?
			this.notifyServer( "editorStateChanged", { state: newValue } );
			this.hasNotified = true;
			this.isDirty = false;
			this.dirtyState = false;
		};
		this.onRestartContentChanged = ( eventInfo, data ) => {
			this.notifyServer( 'changed', data );
		};
		this.onLinkButtonClicked = ( eventInfo ) => {
			this.askIfEditingAllowed();
		};
	}

	destroy() {
		this._psa.getCke5ObjReg().rmvObj( this.wdgId );
		this._cleanup( true );
		this.parent = null;
	}

	onReady() {
		this.wdgReady = true;
		if ( this.dlgAcv && !this.editor ) {
			// we became active before we were rendered --> initialize editor right now
			// --- this._init(this.cwd);
		}
	}

	onRender() {
		if ( !this.element || !this.element.parentNode ) return;
		rap.off( "render", this.onRender );
		this.onReady();
		this.layout();
		if ( this.cwd && typeof this.cwd == "object" &&
			this.cwd.tlb /*&& !this.basic*/ ) this._setPrtPrp();
	}

	layout() {
		if ( !this.isReady ) return;
		var area = this.parent.getClientArea();
		this.element.style.left = '0px';
		this.element.style.top = '1px';
		var hgt = Math.max( area[ 3 ] - 1, 0 );
		this.element.style.width = area[ 2 ] + 'px';
		this.element.style.height = hgt + 'px';
	}

	_setPrtPrp() {
		if ( !this.element || !this.element.parentElement ||
			!this.element.parentElement.rwtWidget ) {
			return;
		}
		// set additional CSS properties to make sure editor's UI elements
		// that should go outside of DIV container's "limit"/"margin" (such
		// as color dropdowns) aren't "cut" by the DIV container's "limit"/
		// "margin" (the widget DIV has fixed width and absolute position)
		let zid = Number( this.element.parentElement.style.zIndex );
		let cls = this.element.parentElement.className;
		this.element.parentElement.style.zIndex = String( zid + 10 );
		this.element.parentElement.className += cls ?
			" cke5-container-widget" : "cke5-container-widget";
	}

	set isReady( newValue ) {
		// ...
	}

	get isReady() {
		return this.wdgReady && this.edtReady;
	}

	set thisEditorReady( newValue ) {
		// ...
	}

	get thisEditorReady() {
		return this._isEditorStateReady( this.editor ) &&
			this._isWatchdogStateReady( this.watchdog ) &&
			this.editor == this.watchdog.editor;
	}

	set watchdogExists( newValue ) {
		// ...
	}

	get watchdogExists() {
		return !!this.watchdog && typeof this.watchdog == "object";
	}

	set watchdogEditingEnabled( newValue ) {
		// ...
	}

	get watchdogEditingEnabled() {
		return this.watchdogExists &&
			typeof this.watchdog.editingEnabled == "boolean" &&
			!!this.watchdog.editingEnabled;
	}

	set editorReady( newValue ) {
		// ...
	}

	get editorReady() {
		// if ( !this.sreenBlocked ) return false;
		return this.isReady && this.thisEditorReady;
	}

	_isEditorStateReady( editor ) {
		return !!editor && typeof editor == "object" &&
			editor.state == "ready"; // && editor.isReadOnly == false;
	}

	_isWatchdogStateReady( watchdog ) {
		return !!watchdog && typeof watchdog == "object" &&
			watchdog.state == "ready";
	}

	createEditorContainer() {
		let container = document.createElement( 'div' );
		container.id = 'cke5cnt_' + this.wdgId;
		container.className = 'cke5container';
		setupCke5ContainerListeners( container );
		this.container = container;
		if ( !this.txtFnt ) return;
		ItmMgr.getInst().setFnt( this.container, this.txtFnt );
	}

	destroyEditorContainer() {
		let container = this.container;
		this.container = void 0;
		delete this.container;
		removeCke5ContainerListeners( container );
		container.id = "";
		container.removeAttribute( "id" );
		container.className = "";
		for ( let element of container.children ) {
			element.innerHTML = "";
			element.remove();
		}
		for ( let node of container.childNodes ) {
			container.removeChild( node );
		}
		container.innerHTML = "";
		container.remove();
		container = void 0;
	}

	getInitialEditorCreationParameters( cwd ) {
		return {
			container: this.container,
			tlb: !!cwd.tlb,
			mini: false,
			objmnu: cwd.objmnu || [],
			plhAllowed: !!cwd.plh || this.iniPlh,
			showPlhTitles: this.iniTtl, //default
			basic: this.basic,
			language: this.language,
			isSecondEditor: this.isSecondEditor,
			isHtmlAllowed: this.isHtmlAllowed
		};
	}

	setEditorInitialLayout( editor ) {
		this.element.appendChild( this.container );
		this.layout();
		editor.psaForceLayout();
	}

	reverseEditorLayout() {
		this.element.style = "";
		for ( let element of this.element.children ) {
			element.innerHTML = "";
			element.remove();
		}
		for ( let node of this.element.childNodes ) {
			this.element.removeChild( node );
		}
		this.element.innerHTML = "";
	}

	updateContent( editor, parameters ) {
		if ( this.iniPtx ) {
			this.iniPtx = false;
			this._setPlainText( true );
			editor.plainText = false;
		}
		if ( !this.iniCtt || parameters.blockSetCtt ) return;
		this.setContentToInitializationContent();
		// const iniAgain = this.iniCtt;
		// this.iniCtt = void 0;
		// !!iniAgain.ins ? this.insCtt( iniAgain ) : this.setCtt( iniAgain );
	}

	setContentToInitializationContent() {
		if ( !this.iniCtt || typeof this.iniCtt != "object" ) return false;
		const initialisationContent = this.iniCtt;
		this.iniCtt = void 0;
		delete this.iniCtt;
		!!initialisationContent.ins ?
			this.insCtt( initialisationContent ) :
			this.setCtt( initialisationContent );
		return true;
	}

	harmonizePlaceholderToggleButtons( editor ) {
		if ( !editor || typeof editor != "object" ) return false;
		if ( !editor.objects || typeof editor.objects != "object" ) return false;
		if ( !editor.objects.placeholders ||
			typeof editor.objects.placeholders != "object" ) return false;
		if ( !editor.objects.placeholders.ui ||
			typeof editor.objects.placeholders.ui != "object" ) return false;
		const ui = editor.objects.placeholders.ui;
		if ( typeof ui.harmonizeAllToggleButtons != "function" ) return false;
		ui.harmonizeAllToggleButtons();
		return true;
	};

	addEditorListeners( editor ) {
		editor.model.document.on( 'change:data',
			this.changeListener, { priority: "lowest" } );
		editor.on( 'pisaInsertImage', this.imageInsertListener );
		editor.on( 'pisaDropImage', this.imageDropListener );
		editor.on( 'pisaUploadImage', this.imageUploadListener );
		editor.on( 'pisaToggleSpellcheck', this.spellcheckListener );
		editor.on( 'pisaObjectLink', this.objectLinkListener );
		editor.on( 'pisaPlaceholder', this.insertPlaceholderListener );
		editor.on( 'requestPlaceholderRefresh', this.refreshPlaceholderListener );
		editor.on( 'pisaToolbarChanged', this.toolbarChangedListener );
		editor.on( 'setToPlain', this.setPlainTextListener );
		editor.on( 'setToHtml', this.setHtmlListener );
		editor.on( 'psaCttChn', this.contentChangeListener );
		editor.on( 'focusChanged', this.focusChangedListener );
		editor.on( 'pisaMaximize', this.f2KeyListener, { priority: 'highest' } );
		editor.on( 'editorContentSet', this.contentSetListener, { priority: 'lowest' } );
		editor.on( 'contentDelete', this.contentDeleteListener );
		editor.on( 'change:state', this.stateChangeListener );
		editor.on( 'restartContentChanged', this.onRestartContentChanged );
		editor.on( 'linkButtonClicked', this.onLinkButtonClicked );
		editor.editing.view.document.on( 'click',
			this.documentClickListener, { priority: Number.NEGATIVE_INFINITY } );
		editor.keystrokes.set( KEY_F2, this.f2KeyListener, { priority: 'highest' } );
		editor.keystrokes.set( KEY_F5, this.f5KeyListener, { priority: 'highest' } );
		editor.keystrokes.set( KEY_CTRL_S, this.ctrlSKeyListener, { priority: 'highest' } );
		editor.keystrokes.set( KEY_CTRL_Q, this.ctrlQKeyListener, { priority: 'highest' } );
	}

	removeEditorListeners( editor ) {
		editor.model.document.off( 'change:data', this.changeListener );
		editor.off( 'pisaInsertImage', this.imageInsertListener );
		editor.off( 'pisaDropImage', this.imageDropListener );
		editor.off( 'pisaUploadImage', this.imageUploadListener );
		editor.off( 'pisaToggleSpellcheck', this.spellcheckListener );
		editor.off( 'pisaObjectLink', this.objectLinkListener );
		editor.off( 'pisaPlaceholder', this.insertPlaceholderListener );
		editor.off( 'requestPlaceholderRefresh', this.refreshPlaceholderListener );
		editor.off( 'pisaToolbarChanged', this.toolbarChangedListener );
		editor.off( 'setToPlain', this.setPlainTextListener );
		editor.off( 'setToHtml', this.setHtmlListener );
		editor.off( 'psaCttChn', this.contentChangeListener );
		editor.off( 'focusChanged', this.focusChangedListener );
		editor.off( 'pisaMaximize', this.f2KeyListener );
		editor.off( 'editorContentSet', this.contentSetListener );
		editor.off( 'contentDelete', this.contentDeleteListener );
		editor.off( 'change:state', this.stateChangeListener );
		editor.off( 'restartContentChanged', this.onRestartContentChanged );
		editor.off( 'linkButtonClicked', this.onLinkButtonClicked );
		editor.editing.view.document.off( 'click', this.documentClickListener );
	}

	onWatchdogNotReady( returnValue ) {
		if ( this.editor && typeof this.editor == "object" ) {
			this.removeEditorListeners( this.editor );
			delete this.editor;
		}
		this.watchdog = void 0;
		delete this.watchdog;
		this.edtReady = false;
		if ( this.container.childNodes.length > 1 ) {
			for ( let i = 1; i < this.container.childNodes.length; i++ ) {
				this.container.childNodes[ i ].remove();
				this.container.childNodes[ i ].innerHTML = "";
				this.container.removeChild( this.container.childNodes[ i ] );
			}
		}
		return returnValue;
	}

	onWatchdogReady( isReady, watchdogInstance, parameters ) {
		if ( !isReady ) return this.onWatchdogNotReady( void 0 );

		this.watchdog = void 0;
		delete this.watchdog;

		this.watchdog = watchdogInstance;

		const self = this;
		Object.defineProperty( self, "editor", {
			configurable: true,
			enumerable: false,
			get: () => {
				return self.watchdog && typeof self.watchdog == "object" ?
					self.watchdog.editor : void 0;
			},
			set: ( value ) => {
				console.warn( `Not allowed to directly set the editor for the` +
					` current widget instance. The only refference to the editor` +
					` should be present inside the watchdog.` );
			}
		} );

		this.edtReady = true;
		const editor = this.watchdog.editor;
		editor.wdgId = this.wdgId;
		this._psa.getCke5ObjReg().addObj( this.wdgId, this );

		this.watchdog.on( "restart", ( eventInfo ) => {
			this.hasNotified = true;
			this.isDirty = false;
			this.dirtyState = false;
		} );

		this.harmonizePlaceholderToggleButtons( editor );

		if ( !this.initialEditorCreationParameters.basic ) {
			this._initSpellCheck( editor );
		}
		// add listeners
		this.addEditorListeners( editor );
		// perform initial layout
		this.setEditorInitialLayout( editor );
		// update content if required
		this.updateContent( editor, parameters );
		// execute remaining registered callbacks
		this.executeAfterEditorInitialisationCallbacks();
	};

	_init( cwd ) {
		// create the container
		this.createEditorContainer();
		// create the editor
		this.initialEditorCreationParameters = this.getInitialEditorCreationParameters( cwd );
		const onWatchdogReady = ( isReady, watchdogInstance, parameters ) => {
			this.onWatchdogReady( isReady, watchdogInstance, parameters );
		}
		pisasales.createCke5Inst( this.initialEditorCreationParameters, onWatchdogReady );
	}

	_resetInit() {
		if ( typeof this.watchdog == "object" &&
			this.watchdog.state != "destroyed" ) {
			this.watchdog.destroy();
		}
		this.onWatchdogNotReady();
		this.destroyEditorContainer();
		this.reverseEditorLayout();
	}

	regenerateEditor() {
		this.regenerate();
	}

	_sndCttChn() {
		let prm = { dirty: true };
		this.addContentParameters( prm );
		// this.editor.objects.selection.update();
		this.notifyServerAndRestoreSelection( 'changed', prm, true );
		this.hasNotified = true;
		this.curRng = null;
		return;
	}

	_changeData() {
		if ( this.inSetData ) return;
		this.isDirty = true;
		if ( this.hasNotified && this.dirtyState ) return;
		// this.hasNotified = false;
		this._sndCttChn();
	}

	_onPsaCttChn( frc ) {
		this.isDirty = true;
		if ( this.hasNotified && !frc ) return;
		this._sndCttChn();
	}

	_recoverLastRange() {
		if ( !this.editorReady ) return void 0;
		this.curRng = void 0;
		const editor = this.editor;
		let selection = editor.model.document.selection;
		if ( selection.anchor.parent.name != "$root" ) {
			++this.rngId;
			this.curRng = selection.getFirstRange();
			return this.curRng;
		}
		return void 0;
	}

	_setSelection( range ) {
		if ( !this.editorReady ) return;
		if ( range ) {
			let selection = this.editor.model.document.selection;
			selection._setTo( range );
		}
	}

	_handleFocus( newVal ) {
		if ( !this.watchdogEditingEnabled || !this.editorReady ) return;
		if ( this.hasFocus === newVal ) return;
		this.hasFocus = newVal;
		if ( !this.hasFocus ) return this._notifyFocus( newVal );
		// make sure that not a single menu is in sight
		pisasales.ScrMen.static.closeAllMenus();
		if ( this.parent ) {
			this.parent.forceFocus( true );
		}
		this._notifyFocus( newVal );
	}

	_notifyFocus( newVal, restoreSelection = true ) {
		let parameters = {
			focusOnEditor: this.hasFocus,
			newVal: !!newVal
		};
		if ( !this.hasFocus && this.isDirty ) {
			this.addContentParameters( parameters );
			this.isDirty = false;
		};
		!restoreSelection ? this.notifyServer( 'focusChanged', parameters ) :
			this.notifyServerAndRestoreSelection( 'focusChanged', parameters, newVal );
	}

	addContentParameters( parameters ) {
		parameters.ctt = this.getEditorData();
		parameters.htm = !this._isPlainText();
	}

	getEditorData( forSaving = false ) {
		const editor = this.editor;
		if ( !this.editorReady || !Validator.isObject( editor ) )
			return this.watchdogExists ? this.watchdog.editorContent : "";
		// let command = !!this.editor.commands && typeof this.editor.commands == "object" ?
		// 	this.editor.commands.get( "pisaRemoveAllMark" ) : void 0;
		// if ( !!command && typeof command == "object" )
		// 	this.editor.executeIf( "pisaRemoveAllMark" );
		if ( forSaving &&
			Validator.isObjectPath( editor, "editor.objects.placeholders" ) &&
			!editor.objects.placeholders.titlesShown )
			editor.executeIf( "pisaPlaceholder", { show: true } );
		return editor.data.processor.getCleanData();
	}

	_handleHotkey( k, e ) {
		let hdl = false;
		let stp = false;
		let par = {};
		switch ( k ) {
			case KEY_F2:
				hdl = true;
				this.showBlockScreen();
				this.addContentParameters( par );
				this.nlfSlc();
				this.hideAllBalloons();
				if ( Validator.isObjectPath( this.editor, "editor.objects.images" ) &&
					Validator.isFunction( this.editor.objects.images.cleanMap ) )
					this.editor.objects.images.cleanMap();
				this.notifyServer( 'maximize', par );
				break;
			case KEY_F5:
				// always stop this!
				hdl = true;
				stp = true;
				// notify web server...
				par.key = k;
				par.nck = true;
				this.notifyServer( 'hotkey', par );
				break;
			case KEY_CTRL_Q:
			case KEY_CTRL_S:
				hdl = true;
				par.key = k;
				par.nck = true;
				if ( KEY_CTRL_S === k ) {
					this.addContentParameters( par );
				}
				this.notifyServerAndRestoreSelection( 'hotkey', par, KEY_CTRL_S === k );
				this.clrCmdStk( "undo" );
				this.clrCmdStk( "redo" );
				this.hideAllBalloons();
				break;
			default:
				break;
		}
		if ( hdl && ( stp || !e.name ) ) {
			e.stopPropagation();
			e.preventDefault();
		}
	}

	setDirtyState( args ) {
		let wasSetAsDirty = args.dirty;
		this.dirtyState = typeof wasSetAsDirty == "boolean" ? wasSetAsDirty : false;
	}

	_handleContentDelete() {
		if ( !this.editorReady ) return;
		let par = {};
		this.notifyServer( 'HTM_EDR_CTT_DEL', par )
	}

	_cleanup( args ) {
		this.wdgReady = this.edtReady = false;
		if ( this.editor ) {
			this.removeEditorListeners( this.editor );
			delete this.editor;
		}
		if ( this.watchdog ) {
			var watchdog = this.watchdog;
			this.watchdog = void 0;
			delete this.watchdog;
			watchdog.destroy();
		}
		delete this.cwd;
	}

	notifyServer( code, par ) {
		if ( !this.wdgReady ) return;
		const tms = Date.now();
		const param = {};
		param.cod = code;
		param.par = par;
		param.tms = tms;
		rap.getRemoteObject( this ).notify( "CKE5_NFY", param );
	}

	/**
	 * Notify server and restore selection
	 */
	notifyServerAndRestoreSelection( code, par, harmonize = false ) {
		if ( !this.editorReady ) return this.notifyServer( code, par );
		this.doWithSelectionRestoration( this.notifyServer, [ code, par ], true, harmonize );
	}

	/**
	 * do with selection restoration - executes a function (fnc), and sets the
	 * editor selection to the same place where it was before the function (fnc)
	 * was executed; if the editor instance is not ready, this function only
	 * executes the parameter function (fnc);
	 * @param {Function} fnc function to be executed;
	 * @param {Array.<Object>} argLst list of parameters to give to the function
	 * @param {Boolean} iclThs include "this"
	 * @see {@link _excFnc} for more explanation on the parameters above
	 */
	doWithSelectionRestoration( fnc, argLst, iclThs, harmonize = false ) {
		if ( !this.editorReady ) return;
		if ( typeof this.editor != "object" ||
			typeof this.editor.objects != "object" ||
			typeof this.editor.objects.selection != "object" ) {
			this._excFnc( fnc, argLst, iclThs );
			return;
		}
		let selection = this.editor.objects.selection;
		selection.freeze();
		this._excFnc( fnc, argLst, iclThs );
		if ( harmonize ) this.restoreSelection();
		selection.revive();
	}

	/**
	 * executes a function
	 * @param {Function} fnc function to be executed;
	 * @param {Array.<Object>} argLst list of parameters to give to the
	 * function that has to be executed, like [ param1, param2, ... paramN ],
	 * those could be objects or primitives or a mix of both; if the function
	 * (fnc) should be called with no parameters, an invalid value like null or
	 * undefined or an empty array can be passed
	 * @param {Boolean} iclThs include this, decides whether or not to provide
	 * the function/method to be called (fnc) with a new value of this (which
	 * is the same this as the one the "_excFnc" function itself is called on
	 * @example
	 * this._excFnc( console.log, [ "hey" ], false );
	 * writes "hey" to the console;
	 */
	_excFnc( fnc, argLst = [], iclThs = true ) {
		// TODO maybe instead of iclThs an actual "this" instance should be passed?
		if ( typeof fnc !== "function" || !( argLst instanceof Array ) ) {
			return;
		}
		return fnc.apply(iclThs ? this : null, argLst);
		// let fncStr = !!iclThs ? "fnc.call(this," : "fnc.call(null,";
		// let i = 0;
		// while ( i < argLst.length ) {
		// 	fncStr += `argLst[${i++}],`;
		// }
		// fncStr = fncStr.endsWith( "," ) ? fncStr.slice( 0, -1 ) + ")" : fncStr + ")";
		// let result = eval( fncStr );
		// return result;
	}

	_tryCatch( fnc, argLst = [], iclThs = true ) {
		let success = false;
		try {
			let result = this._excFnc( fnc, argLst, iclThs );
			success = result != false;
		} catch ( e ) {
			console.warn( `Couldn't execute the function "${fnc.name}". Error:` );
			console.warn( e );
			success = false;
		}
		return success;
	}

	_getDP() {
		return this.editorReady ? this.editor.data.processor : void 0;
	}

	_setPlainText( ptx ) {
		const dp = this._getDP();
		if ( dp ) {
			dp.setPlainText( ptx );
		}
	}

	_isPlainText() {
		const dp = this._getDP();
		return dp && !!dp._plain;
	}

	/** normalize range value */
	_nrmRngVal( args ) {
		return {
			row: ( args.row > 0 ? args.row - 1 : args.row ),
			begin: ( args.begin > 0 ? args.begin - 1 : args.begin ),
			end: ( args.end > 0 ? args.end - 1 : args.end )
		}
	}

	/** check range */
	_chkRng( lastCharacterIndex, lastRowIndex, row, begin, end ) {
		if ( lastCharacterIndex == 0 && lastRowIndex == 0 &&
			( row != 0 || begin != 0 || end != 0 ) ) {
			return true;
		}
		return false;
	}

	/** handle default range value */
	_hdlDftRngVal( editor, args ) {
		let where = this._getDftRngVal( args );
		if ( !where ) return Object.assign( args, { dft: false } );
		if ( where == "start" ) return { row: 0, begin: 0, end: 0 };
		let mainRoot = editor.model.document.getRoot();
		if ( !mainRoot || typeof mainRoot != "object" || !mainRoot._children ||
			typeof mainRoot._children != "object" ||
			!( mainRoot._children._nodes instanceof Array ) )
			return { row: 0, begin: 0, end: 0 };
		let lastIndex = mainRoot._children._nodes.length - 1;
		if ( where == "last" ) {
			let maxOffset = Math.max( mainRoot._children._nodes[ lastIndex ].maxOffset, 0 );
			return { row: lastIndex, begin: maxOffset, end: maxOffset };
		}
		let lastRow = mainRoot._children._nodes[ lastIndex ];
		let offset = !!lastRow && typeof lastRow == "object" ? ( lastRow.maxOffset || 0 ) : 0;
		return {
			row: lastIndex,
			begin: offset,
			end: offset
		}
	}

	/** get default range value */
	_getDftRngVal( args ) {
		if ( args.row > 0 || args.begin > 0 || args.end > 0 ) return false;
		if ( args.row == 0 ) {
			if ( args.begin != 0 || args.end != 0 ) return false;
			return "start";
		};
		if ( args.begin == 0 && args.end == 0 ) return "last";
		if ( args.begin < 0 && args.end < 0 ) return "end";
		return false;
	}

	/** set range value */
	_setRngVal( editor, args ) {
		if ( !editor || !args || !args.row ) {
			return args;
		}
		let row = Math.max( 0, args.row );
		let begin = Math.max( 0, args.begin );
		let end = Math.max( 0, args.end );

		let modelRoot = editor.model.document.getRoot();
		let lastRowIndex = modelRoot.childCount - 1;
		let lastCharacterIndex = modelRoot._children._nodes[ lastRowIndex ].maxOffset;

		let selection = editor.model.document.selection;
		if ( this._chkRng( lastCharacterIndex, lastRowIndex, row, begin, end ) ) {
			let range = typeof selection.getFirstRange == "function" ?
				selection.getFirstRange() : void 0;;
			let lastPosition = typeof range == "object" &&
				typeof range.getLastPosition == "function" ?
				range.getLastPosition() : void 0;
			lastRowIndex = typeof lastPosition == "object" ?
				lastPosition.path[ 0 ] : lastRowIndex;
			lastCharacterIndex = typeof lastPosition == "object" ?
				lastPosition.path[ 1 ] : lastCharacterIndex;
		}
		if ( this._chkRng( lastCharacterIndex, lastRowIndex, row, begin, end ) ) {
			let range = typeof selection.getLastRange == "function" ?
				selection.getLastRange() : void 0;
			let lastPosition = typeof range == "object" ? range.end : void 0;
			lastRowIndex = typeof lastPosition == "object" ?
				lastPosition.path[ 0 ] : lastRowIndex;
			lastCharacterIndex = typeof lastPosition == "object" ?
				lastPosition.path[ 1 ] : lastCharacterIndex;
		}
		if ( this._chkRng( lastCharacterIndex, lastRowIndex, row, begin, end ) ) {
			let range = typeof selection.getLastRange == "function" ?
				selection.getLastRange() : void 0;
			let lastPosition = typeof range == "object" ? range.start : void 0;
			lastRowIndex = typeof lastPosition == "object" ?
				lastPosition.path[ 0 ] : lastRowIndex;
			lastCharacterIndex = typeof lastPosition == "object" ?
				lastPosition.path[ 1 ] : lastCharacterIndex;
		}
		if ( row > lastRowIndex ) {
			row = lastRowIndex;
			begin = lastCharacterIndex;
			end = lastCharacterIndex;
		}
		if ( row == lastRowIndex && begin > lastCharacterIndex ) {
			begin = lastCharacterIndex;
		}
		if ( row == lastRowIndex && end > lastCharacterIndex ) {
			end = lastCharacterIndex;
		}
		args.row = row;
		args.begin = begin;
		args.end = end;
		return args;
	}

	freezeObjectsSelection() {
		if ( !Validator.isObjectPath( this.editor, "editor.objects.selection" ) ) return false;
		if ( !Validator.isFunction( this.editor.objects.selection.freeze ) ) return false;
		this.editor.objects.selection.freeze();
		return true;
	}

	reviveObjectsSelection() {
		if ( !Validator.isObjectPath( this.editor, "editor.objects.selection" ) ) return false;
		if ( !Validator.isFunction( this.editor.objects.selection.revive ) ) return false;
		this.editor.objects.selection.revive();
		return true;
	}

	setCtt( args ) {
		this.freezeObjectsSelection();
		const flag = this.inSetData;
		this.inSetData = true;
		try {
			// args.format? HTML vs. plain text
			const htm = args.htm || false;
			const ini = !!args.ini;
			const ins = !!args.ins;
			// if ( typeof args == "object" && !!args )
			// args.ctt = this.clnCtt( args.ctt );
			let ctt = args.ctt || '';
			if ( this.editorReady ) {
				if ( args.ini ) {
					// force a change of the plain text setting so that all listeners get notified
					this._setPlainText( !!htm );
				}
				this._setPlainText( !htm );
				this.editor.data.processor.setContent( ctt );
				if ( !htm /*&& !this.basic*/ ) {
					this.editor.executeIf( 'pisaDisableButtons' );
				}
				this.sndIsrCbk( false );
				this.hasNotified = false;
				this.isDirty = false;
				if ( ins ) {
					this.sndIsrCbk( true );
				}
			} else if ( !!this.watchdog && typeof this.watchdog == "object" &&
				this.watchdog.state == "crashedPermanently" ) {
				let editableElement = this.watchdog.editableElement;
				if ( editableElement instanceof HTMLElement )
					editableElement.innerHTML = args.ctt;
			} else {
				// not yet ready for this
				this.iniCtt = args;
				this.iniCtt.ini = true;
				this.iniCtt.ins = false;
				this.registerAfterWidgetBecomesReadyCallback( {
					prefix: "setCtt-",
					callback: () => {
						this.setContentToInitializationContent();
					}
				} );
			}
		} finally {
			this.inSetData = flag;
		}
		this.reviveObjectsSelection();
	}

	/**
	 * Clean Content
	 * Removes and/or replaces problematic symbols in a string
	 * that is seen as prospective content for the editor;
	 * @param {String} strCtt the content that should be cleaned
	 * @return {String} cleaned content
	 */
	clnCtt( strCtt ) {
		if ( typeof strCtt != "string" || strCtt.length < 1 )
			return strCtt;
		return strCtt.replace( /\&nbsp\;/g, "&#32;" )
			// make sure "empty" DIV-tags are filled with something
			// so that they have a width (are visible) and so the editor
			// replaces the non-break space with a soft break (BR-tag)
			.replace( /\<div\>\&\#32\;\<\/div\>|\<div\>\<\/div\>/g, "<div>&nbsp;</div>" )
			// remove all zero-width spaces that are not displayed as
			// html symbols
			.replace( /\u200C/g, '' )
			.replace( /\u200B/g, '' )
			.replace( /\u180E/g, '' )
			.replace( /\uFEFF/g, '' );
	}

	setChgMnr( args ) {
		let mnr = !!args;
		this.hasNotified = !mnr;
	}

	setAvlCtp( args ) {
		const htm = !!args.htm;
		const txt = !!args.txt;
		const plv = !!args.plv;
		const plt = !!args.plt;
		const dft = args.dft || '';
		if ( plv || plt ) {
			// plv and plh are mutually exclussive, so if both specified as
			// true, give priority to plv (exception only if plt is default)
			if ( ( plt == plv == true && dft != "plt" ) || dft == "plv" ) {
				plt = false;
			}
			this.setPlhMode( !!plt );
		}
		if ( dft == "txt" || ( !htm && !plv && !plt && txt ) ) {
			// force plain text
			if ( this.editorReady ) {
				this._setPlainText( true );
			} else {
				this.iniPtx = true;
			}
		}
	}

	insCtt( args ) {
		if ( !this.editorReady ) {
			// not yet ready for this
			this.iniCtt = args;
			this.iniCtt.ini = true;
			this.iniCtt.ins = true;
			return;
		}
		let dtp = this._getDP();
		if ( dtp && typeof dtp == "object" &&
			typeof dtp.tempHtm == "string" ) {
			// to do...
		}
		const rid = args.rid || 0;
		const htm = args.cttTyp === 'htm'
		this.hasNotified = false;
		this._setPlainText( !htm );
		if ( rid && ( rid === this.rngId ) && this.curRng ) {
			const rng = this.curRng;
			this.curRng = null;
			this._setSelection( rng );
		} else {
			this.restoreSelection();
		}

		let paragraph = this._createParagraphBeforeTableAtTop();

		if ( !this._handlePisaObjectLink( args.nct ) &&
			!this._hanldeImageHtmInsertion( args.nct ) ) {
			this._getDP().insertText( args.nct || '', htm, args.asis || false );
		};

		this._removeCreatedParagraph( paragraph );

		this.sndIsrCbk( true );
		this.curRng = null;
	}

	_createParagraphBeforeTableAtTop() {
		if ( !this.editorReady ) return void 0;
		if ( !this.selectionSetOnTop ) return void 0;
		this.selectionSetOnTop = false;
		if ( !this.editor.objects.selection.isInsideTable ||
			!this.editor.objects.selection.isCollapsed ) return void 0;
		let paragraph = void 0;
		this.editor.model.change( writer => {
			paragraph = writer.createElement( 'paragraph' );
			let position = this.editor.objects.position.atRoot;
			writer.insert( paragraph, position );
		} );
		this.editor.objects.selection._setTo( {
			start: [ 0, 0 ],
			end: [ 0, 0 ]
		} );
		return paragraph;
	}

	_removeCreatedParagraph( paragraph ) {
		if ( !this.editorReady ) return;
		if ( !paragraph || typeof paragraph != "object" ) return;
		this.editor.model.change( writer => {
			writer.remove( paragraph );
		} );
	}

	_handlePisaObjectLink( linkHtm ) {
		if ( !this.editorReady ) return false;
		if ( !linkHtm || typeof linkHtm != "string" ) return false;
		if ( typeof this.editor.executeIf != "function" ) {
			console.warn( 'Missing function "executeIf" for ck editor 5.' );
			return false;
		}
		let regex = new RegExp( OBJ_LNK_RGX, "gi" );
		let matches = linkHtm.match( regex );
		if ( !matches || !( matches instanceof Array ) || matches.length != 1 ) return false;
		let link = matches[ 0 ].replace( /<span><a href="/, "" );

		if ( !link || typeof link != "string" || link.length < 1 ) {
			return false;
		}
		if ( !this._psa.isPsaObjLink( link ) ) {
			return false;
		}
		let text = linkHtm.replace( /<span><a href="[^"]+">/, "" );
		text = text.substring( 0, text.indexOf( "</a>" ) );
		this.editor.executeIf( INS_PSA_OBJ_CMD, { text: text, href: link }, true );
		return true;
	}

	_hanldeImageHtmInsertion( imageHtm ) {
		if ( !this.editorReady ) return false;
		if ( !imageHtm || typeof imageHtm != "string" || !this.edtReady ) return false;
		let regex = new RegExp( IMG_RGX, "gi" );
		let matches = imageHtm.match( regex );
		if ( !matches || !( matches instanceof Array ) || matches.length != 1 ||
			matches[ 0 ] != imageHtm ) return false;
		let dataProcessor = this._getDP();
		if ( !dataProcessor || typeof dataProcessor != "object" ||
			typeof dataProcessor.getImgAttr != "function" ) return false;
		let source = dataProcessor.getImgAttr( imageHtm, "src" );
		let alt = dataProcessor.getImgAttr( imageHtm, "alt" );
		if ( ( !source || typeof source != "string" || source.length < 1 ) &&
			( !alt || typeof alt != "string" || alt.length < 1 ) ) return false;
		let height = dataProcessor.getImgAttr( imageHtm, "height" );
		let width = dataProcessor.getImgAttr( imageHtm, "width" );
		let args = {};
		if ( !!source && typeof source == "string" && source.length > 0 )
			args.url = source;
		if ( !!alt && typeof alt == "string" && alt.length > 0 )
			args.alt = alt;
		if ( !!height && typeof height == "string" && height.length > 0 )
			args.height = height;
		if ( !!width && typeof width == "string" && width.length > 0 )
			args.width = width;
		this.insImg( args );
		return true;
	}

	restoreSelection() {
		if ( !this.editorReady ) return false;
		let success = true;
		let synchronizeSelection = () => {
			return this.editor.objects.selection._forceSynchronize();
		}
		let harmonizeSelection = () => {
			return this.editor.objects.selection._forceHarmonize();
		}
		if ( !this._tryCatch( synchronizeSelection ) ) {
			success = false;
			if ( this._tryCatch( harmonizeSelection ) ) {
				success = true;
			}
		}
		return success;
	}

	setFocus( args ) {
		if ( !this.editorReady || !this.enabled ) return;
		// let bscMgr = this._getBlockScreenManager();
		// if ( !!bscMgr && typeof bscMgr == "object" && !!bscMgr.isBscVis &&
		// 	typeof bscMgr.isBscVis == "function" && bscMgr.isBscVis() ) return;
		this.focusByUser = false;
		this.editor.objects.focus.doFocus();
	}

	requestContentRefresh( args ) {
		// if ( !this.editorReady || !this.enabled ) return;
		let prm = {};
		this.addContentParameters( prm );
		prm.sdt = true;
		this.notifyServerAndRestoreSelection( 'updateEditorContent', prm, true );
	}

	nfyDatSav( args ) {
		this.hasNotified = false;
		this.isDirty = false;
		this.dirtyState = false;
		if ( !this.editorReady ) return;
		this.editor.objects.selection._updateLastFocusPosition();
		this.clrCmdStk( "undo" );
		this.clrCmdStk( "redo" );
		this.clrPtx();
		this.hideAllBalloons();
		this.editor.fire( "editorDataSaved" );
		this.editor.objects.selection._setToLastFocusPosition();
		// document.body.focus();
	}

	nfyDatCan( args ) {
		if ( !this.editorReady ) return;
		this.clrCmdStk( "undo" );
		this.clrCmdStk( "redo" );
		this.hideAllBalloons();
		if ( typeof this.editor.objects == "object" &&
			typeof this.editor.objects.placeholders == "object" ) {
			this.editor.objects.placeholders.clearPlaceholderReferences();
		}
		// }

		// content is being set in order to update the
		// image listeners without triggering "dirty" again
		this.setCtt( args );
		this.nlfSlc();
		this.hasNotified = false;
		this.isDirty = false;
		this.dirtyState = false;
		document.body.focus();
	}

	/**
	 * Nullify editor selection
	 */
	nlfSlc( live = true, inObjects = true ) {
		if ( !this.editorReady ) return;
		if ( typeof this.editor != "object" ||
			typeof this.editor.objects != "object" ||
			typeof this.editor.objects.selection != "object" ) return;
		inObjects && this.editor.objects.selection.nullify();
		live && this.editor.objects.selection._nullifyLive();
	}

	clrCmdStk( cmdNam = "undo" ) {
		if ( !this.editorReady ) return;
		if ( cmdNam != "undo" && cmdNam != "redo" ) return;
		if ( !this.editor || !this.editor.objects ||
			!this.editor.objects.selection ) return;
		if ( cmdNam == "undo" &&
			typeof this.editor.objects.selection._clearUndoStack == "function" )
			return this.editor.objects.selection._clearUndoStack();
		if ( cmdNam == "redo" &&
			typeof this.editor.objects.selection._clearRedoStack == "function" )
			this.editor.objects.selection._clearRedoStack();
	}

	clrPtx() {
		if ( !this.editorReady ) return;
		if ( /*this.basic ||*/ !this.editor || !this.editor.plugins ||
			typeof this.editor.plugins != "object" ||
			!this.editor.plugins._plugins ||
			typeof this.editor.plugins._plugins != "object" )
			return;
		let ptxPlg = this.editor.plugins._plugins.get( "PisaPlainText" );
		if ( !ptxPlg || typeof ptxPlg != "object" ) return;
		if ( typeof ptxPlg._nullifyRichContent != "function" ) return;
		ptxPlg._nullifyRichContent();
	}

	insImg( args ) {
		if ( !this.editorReady ) return;
		this.hasNotified = false;
		this._getDP().insertImage( args );
		this.sndIsrCbk( true );
		this.hasNotified = false;
	}

	/**
	 * Sets the selection range in the editor. The selection range is provided in PiSA format.
	 * @param {Object} args JSON object containing the selection range
	 */
	setSelectionRange( args ) {
		if ( !this.editorReady ) return;
		const editor = this.editor;
		let focus = !!args.fcs;
		args = this._nrmRngVal( args );
		args = this._hdlDftRngVal( editor, args );
		if ( args.dft == false ) args = this._setRngVal( editor, args );

		this.selectionSetOnTop = args.row == 0 && args.begin == 0 && args.end == 0;

		editor.objects.selection._setTo( {
			start: [ args.row, args.begin ],
			end: [ args.row, args.end ]
		} );

		if ( focus ) this.setFocus( {} );

		!focus ? editor.objects.selection.fakeFocusRange = null :
			editor.objects.selection._setFakeFocusRange(
				[ args.row, args.begin ], [ args.row, args.end ], false );
	}

	/**
	 * sets the default text font
	 * @param {Object} args font descriptor or null
	 */
	setTxtFnt( args ) {
		this.initialTextFontParameters = args || void 0;
		this.txtFnt = args || null;
		if ( args ) {
			// force minimal size of 12px (~8pt)
			const size = Math.max( ( args.siz || 0 ), MINIMAL_DEFAULT_FONT_SIZE );
			this.txtFnt.siz = size
		}
		if ( this.container ) {
			ItmMgr.getInst().setFnt( this.container, this.txtFnt );
		}
	}

	/**
	 * sets the "dialog active" flag
	 * @param {Boolean} args activation flag
	 */
	setDlgAcv( args ) {
		const acv = args || false;
		if ( this.dlgAcv !== acv ) {
			// dialog's status has been changed
			this._onDlgAcv( acv );
		}
	}

	_onDlgAcv( acv ) {
		// !!! DO NOTHING UNTIL MEMORY LEAKS ARE NOT FIXED !!!
		//			const old_acv = this.dlgAcv;
		//			this.dlgAcv = acv;
		//			if ( old_acv ) {
		//				this.edtReady = false;
		//				if ( this.editor ) {
		//					// save current content
		//					let par = {};
		//					this.addContentParameters(par);
		//					par.ini = true;
		//					this.iniCtt = par;
		//					const isd = this.inSetData;
		//					try {
		//						// and drop the editor instance
		//						this.inSetData = true;
		//						const edr = this.editor;
		//						this.editor = null;
		//						edr.destroy();
		//						if ( this.container ) {
		//							// drop everything
		//							const cnt = this.container;
		//							this.container = null;
		//							cnt.innerHTML = '';
		//							if ( cnt.parentElement ) {
		//								cnt.parentElement.removeChild(cnt);
		//							}
		//						}
		//					} finally {
		//						this.inSetData = isd;
		//					}
		//				}
		//			} else if ( this.dlgAcv ) {
		//				if ( this.wdgReady ) {
		//					this._init(this.cwd);
		//					this.isDirty = false;
		//					this.hasNotified = false;
		//				}
		//			}
	}

	/**
	 * sets the read/only state
	 * @param {Boolean} args read/only flag
	 */
	setReaOnl( args ) {
		const ron = args || false;
		if ( this.reaOnl !== ron ) {
			this.reaOnl = ron;
			if ( this.editor ) {
				// setting "isReadOnly" to true leads to an instance that does not allow to click into the text
				// this.editor.isReadOnly = ron;
			}
		}
	}

	/**
	 * Sends the image insert request to the server.
	 * @param {Object} scope the inherited scope
	 */
	onImageInsert( scope ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		this._psa.setBscRqu();
		let par = {};
		scope.addContentParameters( par );
		scope.notifyServerAndRestoreSelection( 'insertImage', par, true );
	}

	onImageDrop( scope, event ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		if ( !event || !event.images || event.count <= 0 ) return;
		const UPLOAD = "upload";
		let id = UPLOAD.concat( "-" )
			.concat( String( scope.tempImageData.transaction++ ) );
		scope.tempImageData[ id ] = event.images;
		scope.sendImageData( scope, {
			operation: UPLOAD,
			uploadId: id
		} );
	}

	processImageUpload( args ) {
		if ( !this.editorReady ) return;
		if ( !this.edtReady || !args || !args.transactionId ||
			!this.tempImageData || !this.tempImageData[ args.transactionId ] ) return;
		try {
			let imageData = this.tempImageData[ args.transactionId ];
			delete this.tempImageData[ args.transactionId ];
			if ( args.drop ) return;
			let formData = rwt.client.FileUploader.createFormData();
			imageData.forEach( image => {
				formData.append( image.id, image.file, image.id );
			} );
			let xhr = rwt.remote.Request.createXHR();
			xhr.open( 'POST', this.imageUploadUrl );
			xhr.send( formData );
		} catch ( err ) {
			//this.reportError('HtmEdr.processImageUpload: ', err);
		}
	}

	toggleSpellcheck( scope, on ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		const cbw = this._psa.cliCbkWdg || null;
		if ( cbw ) {
			cbw.setStgVal( { name: PRP_SPELLCHECK, value: on } );
		}
	}

	closeAllBalloons( editor ) {
		if ( typeof this._isEditorStateReady == "function" &&
			!this._isEditorStateReady( editor ) ) return false;
		if ( !Validator.isObject( editor ) ) editor = this.editor;
		if ( Validator.isObjectPath( editor, "editor.objects.linkBalloon" ) &&
			Validator.isFunction( editor.objects.linkBalloon.hide ) )
			editor.objects.linkBalloon.hide();
		if ( Validator.isObject( editor.balloons ) &&
			Validator.isFunction( editor.balloons.hideAll ) )
			editor.balloons.hideAll();
		// if ( !editor || typeof editor != "object" ) {
		// 	editor = this.editor;
		// }
		// if ( !editor.objects || typeof editor.objects != "object" ||
		// 	!editor.objects.balloons ||
		// 	typeof editor.objects.balloons != "object" ||
		// 	typeof editor.objects.balloons._hideContextualBalloon != "function" )
		// 	return false;
		// editor.objects.balloons._hideContextualBalloon();
		// if ( !editor.balloons || typeof editor.balloons != "object" ||
		// 	!editor.balloons.hideAll ||
		// 	typeof editor.balloons.hideAll != "function" ) return false;
		// editor.balloons.hideAll();
		// if ( typeof editor.balloons._hideLinkBalloon != "function" ) return false;
		// editor.balloons._hideLinkBalloon();
		return true;
	}

	freezeBalloons( editor ) {
		if ( !Validator.isObject( editor ) ) editor = this.editor;
		if ( Validator.isFunction( this._isEditorStateReady ) &&
			!this._isEditorStateReady( editor ) ) return;
		if ( Validator.isObjectPath( editor, "editor.objects.linkBalloon" ) &&
			Validator.isFunction( editor.objects.linkBalloon.freeze ) )
			editor.objects.linkBalloon.freeze();
		if ( Validator.isObject( editor.balloons ) &&
			Validator.isFunction( editor.balloons.freeze ) )
			editor.balloons.freeze();
	}

	reviveBalloons( editor ) {
		if ( !Validator.isObject( editor ) ) editor = this.editor;
		if ( Validator.isFunction( this._isEditorStateReady ) &&
			!this._isEditorStateReady( editor ) ) return;
		if ( Validator.isObjectPath( editor, "editor.objects.linkBalloon" ) &&
			Validator.isFunction( editor.objects.linkBalloon.revive ) )
			editor.objects.linkBalloon.revive();
		if ( Validator.isObject( editor.balloons ) &&
			Validator.isFunction( editor.balloons.revive ) )
			editor.balloons.revive();
	}

	linkPisaObject( scope, key ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		const range = scope._recoverLastRange();
		const par = {};
		par.key = key;
		if ( range ) {
			par.rid = this.rngId;
		}
		scope.addContentParameters( par );
		// scope.notifyServer( 'insertPisaLink', par );
		scope.notifyServerAndRestoreSelection( 'insertPisaLink', par, true );
		// scope._setSelection( range );
	}

	requestPlaceholders( scope, options ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		scope.editor.objects.selection._updateLastFocusPosition();
		scope.notifyServer( 'pisaPlaceholder', options );
		this.closeAllBalloons( scope.editor );
		// scope.notifyServerAndRestoreSelection( 'pisaPlaceholder', options, true );
	}

	requestPlaceholdersRefresh( scope, placeholders ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		scope.notifyServer( 'pisaPlaceholderRefresh', placeholders );
	}

	_initSpellCheck( editor ) {
		// if ( this.basic ) return;
		const cbw = this._psa.cliCbkWdg || null;
		if ( !cbw ) return;
		let init = true;
		let spc = cbw.getStgVal( PRP_SPELLCHECK );
		if ( this._psa.isStr( spc ) && spc === 'false' ) init = false;
		if ( typeof this._isEditorStateReady == "function" &&
			!this._isEditorStateReady( editor ) ) return;
		let cmd = editor.commands.get( "pisaSpellcheck" );
		if ( !cmd || typeof cmd != "object" ) return;
		editor.executeIf( "pisaSpellcheck", { init: init } );
	}

	/**
	 * Sends an image data to the client.
	 * @param {Object} scope the execution scope
	 * @param {Object} parameters additional notification parameters
	 */
	sendImageData( scope, parameters ) {
		if ( !scope || typeof scope != "object" || !scope.editorReady ) return;
		try {
			//scope.resetDirty(scope);
			this._psa.setBscRqu();
			let par = {};
			this.addContentParameters( par );
			par.uploadId = parameters.uploadId;
			this.notifyServer( 'imageDrop', par );
		} catch ( err ) {
			//scope.reportError('HtmEdr.sendImageData: ', err);
		}
	}

	/**
	 * context menu event handler
	 * @param {Object} scope the execution scope (should be === this)
	 * @param {MouseEvent} evt the context menu event
	 */
	onContextMenu( scope, evt ) {
		evt.stopPropagation();
	}

	_findAnchor( elm ) {
		let anchor = null;
		if ( elm ) {
			let go = true;
			while ( go && elm ) {
				switch ( elm.tagName ) {
					case 'A':
						anchor = elm;
						go = false;
						break;
					case 'DIV':
					case 'P':
					case 'BODY':
						go = false;
						break;
					default:
						elm = elm.parentNode;
						break;
				}
			}
		}
		return anchor;
	}

	onElementClick( scope, evt, ded ) {
		this.selectionSetOnTop = false;
		if ( scope.isReady && ded && ded.domTarget && ded.domEvent ) {
			const dme = ded.domEvent;
			const hit = !!dme.shiftKey || !!dme.ctrlKey;
			if ( hit ) {
				const link = scope._findAnchor( ded.domTarget );
				if ( link && ( link.tagName === 'A' ) ) {
					evt.stop();
					const href = link.href || '';
					if ( this._psa.isPsaObjLink( href ) ) {
						// a PiSa sales link
						scope.nfyPsaObjLink( href );
					} else {
						// let the browser do this
						window.open( href, 'blank' );
					}
				}
			}
			this._notifyFocus( true, false );
		}
	}

	nfyPsaObjLink( href ) {
		if ( this.isReady && this._psa.isStr( href ) ) {
			this._psa.setBscRqu();
			const par = {};
			par.href = href;
			this.notifyServer( 'psaobjClick', par );
			this.hideAllBalloons();
		}
	}

	sndIsrCbk( ins ) {
		try {
			this.isDirty = false; // what for?
			this._psa.setBscRqu();
			const par = {};
			this.addContentParameters( par );
			par.ins = !!ins;
			this.notifyServerAndRestoreSelection( 'contentInserted', par, ins );
			// this.notifyServer( 'contentInserted', par );
		} catch ( err ) {
			console.warn( `Failed to send "contentInserted" notification. Error:` );
			console.warn( err );
		}
	}

	/**
	 * set placeholder data:
	 * "manager" function that decides whether the editor should update
	 * placeholders' values or insert a new ("selected") placeholder;
	 * @param {Object} args parameters that influence the decision; contains
	 * a list of all placeholders with their values and a "selected" property;
	 * @param {string} args.selected the key of the placeholder from the list
	 * that should be inserted;
	 * @param {Array.<Object>} args.entries list of all placeholder objects;
	 * a placeholder object consists of his key, value, and a "isHtml" flag
	 */
	setPlhDat( args ) {
		if ( !this.editorReady ) return;
		// if ( this.basic ) return;
		this.editor.objects.selection._setToLastFocusPosition();
		if ( !args.selected ) {
			if ( !args.entries || args.entries.length <= 0 ) return;
			this.rfsPlh( args.entries );
			return;
		}
		for ( let entry of args.entries ) {
			if ( entry.key != args.selected ) continue;
			this.insPlh( entry );
			break;
		}
	}

	/**
	 * set placeholder mode:
	 * this function only makes sure editor's internal perception of the
	 * "placeholder mode" (values or titles) he is currently in is set,
	 * WITHOUT converting the existing content (changing placeholders that
	 * are shown as values to titles or viceversa);
	 *
	 * @see {@link tglPlhTo} in order to set the mode WITH content conversion
	 * it takes care of editor's "titlesShown" state (true if in "titles" mode,
	 * false if in "values" mode) and of the "placeholder toggle button" (or
	 * buttons) wherever it (they) is (are) placed (toolbar, balloons, etc.);
	 *
	 * in case the editor is not initialized yet, the only thing the function
	 * does is change the "initialization parameters" so that editor starts in
	 * titles or values mode, depending on the value passed;
	 *
	 * @param {boolean} shwTtl "show titles mode" (true to set the placeholder mode
	 * to "titles", false to set the placeholder mode to "values"); automatically
	 * sets the "allow placeholders" flag to true;
	 */
	setPlhMode( shwTtl ) {
		if ( !this.editorReady ) return;
		// if ( this.basic ) return;
		shwTtl = !!shwTtl;
		this.iniPlh = true;
		this.iniTtl = shwTtl;
		if ( !this.editor || !this.editor.objects ||
			!this.editor.objects.placeholders ||
			this.editor.objects.placeholders.titlesShown == shwTtl ) return;
		this.editor.objects.placeholders.titlesShown = shwTtl;
		this.editor.objects.placeholders.ui._setAllToggleButtons( shwTtl );
		this.editor.fire( "editorContentSet" ); // is it necessary?
	}

	/**
	 * toggle placeholders to:
	 * executes editor's "pisaPlaceholder" command, that toggles between
	 * "titles" and "values" modes, which includes
	 * 1. converting the content (placeholder values to tiltes or viceversa)
	 * 2. changing the ui (making sure all "toggle" buttons display the current state)
	 * 3. changing editor's internal "titlesShown" state/flag (true if in "titles" mode,
	 * "false" if in values mode);
	 * @param {boolean} ttlMod "titles mode" (true to toggle to "titles", false to
	 * toggle to "values");
	 * even if editor's content is being changed, this has no influence on the "dirty" flag
	 * and it doesn't register in history (undo and redo stacks);
	 */
	tglPlhTo( ttlMod ) {
		if ( !this.editorReady ) return;
		if ( !this.editor || !this.editor.objects ||
			!this.editor.objects.placeholders ) return;
		let command = this.editor.commands.get( "pisaPlaceholder" );
		if ( !command || typeof command != "object" ) return;
		this.editor.executeIf( "pisaPlaceholder", { show: !!ttlMod } );
	}

	/**
	 * refresh placeholder buttons ui:
	 * makes sure all editor's "toggle placeholder mode" buttons display the current
	 * state (the current state corresponds to editor's internal "titlesShown" state/flag)
	 */
	rfsPlhBtnUi() {
		if ( !this.editorReady ) return;
		if ( !this.editor || !this.editor.objects ||
			!this.editor.objects.placeholders ||
			!this.editor.objects.placeholders.ui ||
			typeof this.editor.objects.placeholders.ui.harmonizeAllToggleButtons != "function" ) return;
		this.editor.objects.placeholders.ui.harmonizeAllToggleButtons();
	}

	/**
	 * refresh placeholders:
	 * updates placeholders' values with the given (new) values from the list;
	 * only updates if new value is different from old value;
	 * @param {Array.<Object>} plhLst list of all placeholders with their most recent values
	 */
	rfsPlh( plhLst ) {
		if ( !this.editorReady ) return;
		let command = this.editor.commands.get( "refreshPlaceholder" );
		if ( !command || typeof command != "object" ) return;
		let map = this.editor.objects.placeholders.toMap( plhLst );
		let dp = this.editor.data.processor || this.editor.getPsaDP();
		this.editor.objects.placeholders.map.forEach( ( value, title ) => {
			let newValue = map.get( title );
			if ( !newValue || newValue.unicodeValue == value.unicodeValue ) return;
			value.unicodeValue = newValue.unicodeValue || "";
			value.base64Value = newValue.unicodeValue ?
				dp._encode64( newValue.unicodeValue ) : "";
			this.editor.executeIf( "refreshPlaceholder", { title: title } );
		} );
	}

	/**
	 * insert placeholder:
	 * insert given placeholder into the editor;
	 * @param {Object} plh placeholder parameters
	 * @param {string} plh.key placeholder title/key
	 * @param {string} plh.value placeholder value; can be empty
	 * @param {boolean} plh.html placeholder "isHtml" property; defines if
	 * the placeholder value is a html string (or should be interpreted as one)
	 * or not;
	 */
	insPlh( plh ) {
		if ( !this.editorReady ) return;
		this.editor.objects.selection._setToLastFocusPosition();
		let command = this.editor.commands.get( "insertPlaceholder" );
		if ( !command || typeof command != "object" ) return;
		this.editor.executeIf( "insertPlaceholder", {
			key: plh.key,
			value: plh.value,
			html: plh.html
		} );
	}

	_handleContentSet( evt ) {
		this.hideAllBalloons();
		this.hideBlockScreen();
	}

	maximizeAllowed( args ) {
		this.freezeBalloons( this.editor );
		this.showBlockScreen();
	}

	maximizeRejected( args ) {
		this.reviveBalloons( this.editor );
		this.hideBlockScreen();
	}

	showBlockScreen() {
		this._shwBlkScr( true );
	}

	hideBlockScreen() {
		this._shwBlkScr( false );
	}

	_getBlockScreenManager() {
		let bscMgr = this._psa.getBscMgr();
		return bscMgr ? bscMgr : void 0;
	}

	_shwBlkScr( show = true ) {
		let bscMgr = this._getBlockScreenManager();
		if ( !bscMgr || typeof bscMgr != "object" || !bscMgr.shwBlkScr ||
			typeof bscMgr.shwBlkScr != "function" ) return;
		this.sreenBlocked = show;
		bscMgr.shwBlkScr( { mod: "trn", keepHint: true, sbc: !!show } );
	}

	onContentDelete() {
		this.notifyServerAndRestoreSelection( "requestContentDelete", {}, true );
	}

	dltCtt() {
		if ( !this.editorReady ) return;
		if ( !this.edtReady || !this.editor || typeof this.editor != "object" ||
			typeof this.editor.executeIf != "function" ) return;
		this.editor.executeIf( "pisaDeleteAll" );
	}

	onImageUpload( images ) {
		this.notifyServerAndRestoreSelection( "uploadImages", images, true );
	}

	uploadedImages( images ) {
		if ( !this.editorReady ) return;
		const dtp = this._getDP();
		if ( !dtp || typeof dtp != "object" ||
			typeof dtp.insertImageHtm != "function" ) return;
		dtp.insertImageHtm( images );
	}

	manageEnabled( args ) {
		this.enabled = !!args.enabled;
		if ( !this.editorReady ) return; // first line ?
		this.editor.editing.view._postFixersInProgress = false;
		this.editor.editing.view.isRenderingInProgress = false;
		this.editor.isReadOnly = !args.enabled;
		this.hideAllBalloons();
		!!args.enabled ? this.reviveBalloons( this.editor ) :
			this.freezeBalloons( this.editor );
	}

	hideAllBalloons() {
		return this.closeAllBalloons( this.editor );
	}

	askIfEditingAllowed() {
		// only ask if not already dirty; if already dirty, editing should be allowed
		if ( this.isDirty || this.dirtyState || this.alreadyAskedIfEditingAllowed ) return;
		this.alreadyAskedIfEditingAllowed = true;
		this.notifyServerAndRestoreSelection( "editingAllowed", {} );
	}

	setEditingAllowed( args ) {
		delete this.alreadyAskedIfEditingAllowed;
		if ( this.isDirty || this.dirtyState ) return;
		if ( !args || typeof args != "object" ) return;
		if ( args.editingAllowed == true || args.editingAllowed == "true" ) return;
		this.hideAllBalloons();
	}

	setHtmlAvailable( notificationParameters ) {
		if ( !Validator.isObject( notificationParameters ) ||
			!( "isHtmlAllowed" in notificationParameters ) ) return false;
		const previousValue = !!this.isHtmlAllowed;
		this.isHtmlAllowed = !!notificationParameters.isHtmlAllowed;
		if ( previousValue === this.isHtmlAllowed ) return true;
		const dataProcessor = this._getDP();
		if ( Validator.isObject( dataProcessor ) &&
			"htmlAvailable" in dataProcessor ) {
			dataProcessor.htmlAvailable = this.isHtmlAllowed;
			return true;
		};
		const scope = this;
		return this.registerAfterEditorInitalisationCallback( {
			prefix: "setHtmlAvailable-",
			callback: () => {
				const scopeDataProcessor = scope._getDP();
				if ( !Validator.isObject( scopeDataProcessor ) ||
					!( "htmlAvailable" in scopeDataProcessor ) ) return;
				scopeDataProcessor.htmlAvailable = scope.isHtmlAllowed;
			}
		} );
	}

	registerAfterEditorInitalisationCallback( {
		prefix = "",
		callback,
		deleteRightAfterExecution = true,
		deleteOthersWithSamePrefix = true,
		canBeDeletedByOthers = true
	} ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: "afterEditorInitialisationCallbacks",
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: deleteOthersWithSamePrefix,
			canBeDeletedByOthers: canBeDeletedByOthers
		} );
	}

	executeAfterEditorInitialisationCallbacks() {
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: "afterEditorInitialisationCallbacks"
		} );
	}

	addWidgetReadyLazySetter() {
		if ( typeof this.wdgReady == "boolean" )
			this._wdgReady = this.wdgReady;
		delete this.wdgReady;
		Object.defineProperty( this, "wdgReady", {
			set: ( newValue ) => {
				if ( !newValue ) {
					this._wdgReady = !!newValue;
					return;
				}
				delete this._wdgReady;
				delete this.wdgReady;
				this.wdgReady = !!newValue;
				console.log( "CkEdt5.js widget is ready." );
				this.doAfterWidgetBecomesReady();
			},
			get: () => { return this._wdgReady; },
			configurable: true
		} );
		return true;
	}

	registerAfterWidgetBecomesReadyCallback( {
		prefix = "",
		callback,
		deleteRightAfterExecution = true,
		deleteOthersWithSamePrefix = true,
		canBeDeletedByOthers = true
	} ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: "afterWidgetBecomesReadyCallbacks",
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: deleteOthersWithSamePrefix,
			canBeDeletedByOthers: canBeDeletedByOthers
		} );
	}

	doAfterWidgetBecomesReady() {
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: "afterWidgetBecomesReadyCallbacks"
		} );
	}

	/** register custom widget type */
	static register() {
		console.log( 'Registering custom widget CkEdt5.' );
		rap.registerTypeHandler( "psawidget.CkEdt5", {
			factory: function( properties ) {
				return new CkEdt5( properties );
			},

			destructor: "destroy",
			properties: [ "ctt", "chgMnr", "avlCtp", "selectionRange", "txtFnt",
				"dlgAcv", "reaOnl", "dirtyState", "editingAllowed", "htmlAvailable"
			],
			methods: [ "regenerateEditor", "insCtt", "insImg", "setFocus",
				"requestContentRefresh", "nfyDatSav", "nfyDatCan", "processImageUpload",
				"setPlhDat", "insPlh", "maximizeAllowed", "maximizeRejected", "dltCtt",
				"uploadedImages", "manageEnabled"
			],
			events: [ "CKE5_NFY", "HTM_EDR_IMG" ]
		} );
	}
}

console.log( 'widgets/cke5/CkEdt5.js loaded.' );
