import MBase from './MBase';
import CellCtt from './CellCtt';

/** an empty content */
const EMPTY_CTT = { html: false, text: '', prop: {} };

/**
 * data cell class
 */
export default class MCell extends MBase {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( json ) {
		super();
		this.idc = json.idc || 0;
		this.idr = json.idr || 0;
		this.ctt = new CellCtt( json.ctt || EMPTY_CTT );
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		this.ctt.destroy();
		delete this.ctt;
		delete this.idc;
		delete this.idr;
	}

	/**
	 * returns the current cell content
	 * @returns {CellCtt} the current cell content
	 */
	getCtt() {
		return this.ctt;
	}

	/**
	 * sets new cell content
	 * @param {Object} ctt new cell content
	 */
	setCtt( ctt ) {
		if ( this.ctt ) {
			this.ctt.destroy();
			this.ctt = null;
		}
		this.ctt = new CellCtt( ctt || EMPTY_CTT );
	}
}
