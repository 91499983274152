import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class SimpleLabel - provides a simple, yet flexible label widget
 */
export default class SimpleLabel {

    /**
     * constructs a new instance
     * @param {*} properties properties provided by the framework
     */
    constructor(properties) {
        this._psa = PSA.getInst();
        // setup this instance
        this._psa.bindAll(this, [ 'layout', 'onReady', 'onRender' ]);
        this.ready = false;
        this.parent = rap.getObject(properties.parent);
        this.element = document.createElement('div');
        this.textSpan = document.createElement('span');
        this.element.appendChild(this.textSpan);
        this._initDomElms();
        this.props = { text: '', font: null, fgc: null, bgc: null, csc: null, halign: 0, valign: 1 };
        this.parent.append(this.element);
        rap.on('render', this.onRender);
        this.parent.addListener('Resize', this.layout);
    }

    /**
     * destroys the widget
     */
    destroy() {
        this.ready = false;
        const elm = this.element;
        delete this.element;
        delete this.textSpan;
        this._psa.rmvDomElm(elm);
    }

    /**
     * updates the layout on resize operations
     */
    layout() {
        if ( this.ready && this.element ) {
            const area = this.parent.getClientArea();
            this.element.style.left = '' + area[0] + 'px';
            this.element.style.top = '' + area[1] + 'px';
            this.element.style.width = '' + area[2] + 'px';
            this.element.style.height = '' + area[3] + 'px';
        }
    }

    /**
     * called after first render cycle to mark this instance as ready, up&running
     */
    onReady() {
        if ( this.element ) {
            this.ready = true;
            // the RAP widget will have background styles set - drop them all!
            ItmMgr.getInst().dropCssProps(this.element.parentElement, ['background']);
            this.layout();
        }
    }

    /**
     * called at the end of the "render" phase
     */
    onRender() {
        rap.off( 'render', this.onRender );
        this.onReady();
    }

    /**
     * sets the label text
     * @param {String} args new label text
     */
    setText(args) {
        const text = args || '';
        if ( this.textSpan ) {
            this.textSpan.innerText = text;
        }
    }

    /**
     * sets the label font
     * @param {*} font font descriptor
     */
    setFont(font) {
        if ( this.textSpan ) {
            this.textSpan.style.font = ItmMgr.getInst().getFontCss(font || null);
        }
    }

    /**
     * sets the horizontal aligments
     * @param {Number} args horizontal aligment
     */
    setHorzAlign(args) {
        const align = args || 0;
        if ( this.textSpan ) {
            switch ( align ) {
                case 1:
                    this.textSpan.style.textAlign = 'center';
                    break;
                case 2:
                    this.textSpan.style.textAlign = 'right';
                    break;
                default:
                    this.textSpan.style.textAlign = 'left';
                    break;
            }
        }
    }

    /**
     * sets the vertical aligment
     * @param {Number} args vertical aligment
     */
    setVertAlign(args) {
        const align = args || 0;
        if ( this.element ) {
            switch ( align ) {
                case 0:
                    this.element.style.alignItems = 'flex-start';
                    break;
                case 2:
                    this.element.style.alignItems = 'flex-end';
                    break;
                default:
                    this.element.style.alignItems = 'center';
                    break;
            }
        }
    }

    /**
     * sets the padding
     * @param {*} args padding definition
     */
    setPadding(args) {
        if ( this.element ) {
            const style = this.element.style;
            const top = args.top || 0;
            const right = args.right || 0;
            const bottom = args.bottom || 0;
            const left = args.left || 0;
            style.paddingTop = '' + top + 'px';
            style.paddingRight = '' + right + 'px';
            style.paddingBottom = '' + bottom + 'px';
            style.paddingLeft = '' + left + 'px';
        }
    }

    /**
     * sets the foreground / text color
     * @param {*} args color descriptor
     */
    setForeground(args) {
        if ( this.textSpan ) {
            this.textSpan.style.color = ItmMgr.getInst().getRgb(args || null);
        }
    }

    /**
     * sets the background color
     * @param {*} args color descriptor
     */
    setBackground(args) {
        if ( this.element ) {
            this.element.style.backgroundColor = ItmMgr.getInst().getRgb(args || null);
        }
    }

    /**
     * changes CSS classes
     * @param {*} args arguments
     */
    setCssClass(args) {
        if ( this.element && this.textSpan ) {
            const csc = args.csc || '';
            if ( this._psa.isStr(csc) ) {
                const add = args.add || false;
                const rmv = args.rmv || false;
                const txe = args.txe || false;
                const te = txe ? this.textSpan : this.element;
                if ( rmv ) {
                    te.classList.remove(csc);
                } else if ( add ) {
                    te.classList.add(csc);
                } else {
                    te.className = csc;
                }
            }
        }
    }

    /**
     * initializes the DOM elements
     */
    _initDomElms() {
        // set style of main element
        const e = this.element;
        e.style.display = 'flex';
        e.style.flexDirection = 'row';
        e.style.overflow = 'hidden';
        e.style.padding = '2px';
        // set style of text span
        const s = this.textSpan;
        s.style.textOverflow = 'ellipsis';
        s.style.whiteSpace = 'nowrap';
        s.style.width = '100%';
        // attach "click" listener
        e.addEventListener('click', (evt) => this._onClick(evt));
    }

    /**
     * "click" listener
     * @param {MouseEvent} e the click event
     */
    _onClick(e) {
        const button = e.button || 0;
        this._nfySrv('clicked', { button: button } );
    }

    /**
     * notifies the web server
     * @param {String} code notification code
     * @param {*} par notification parameters
     */
    _nfySrv(code, par) {
        if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify("SIMPLELABEL_NFY", param);
        }
    }


    /** register custom widget type */
    static register() {
        console.log('Registering custom widget SimpleLabel.');
        rap.registerTypeHandler("psawidget.SimpleLabel", {
            factory : function(properties) {
                return new SimpleLabel(properties);
            },
            destructor : "destroy",
            properties: [ 'text', 'font', 'horzAlign', 'vertAlign', 'padding', 'foreground', 'background' ],
            methods : [ 'setCssClass' ],
            events : [ 'SIMPLELABEL_NFY' ]
        } );
    }
}

console.log('widgets/csswdg/SimpleLabel.js loaded.');