import AttachmentObject from '../../../../utils/AttachmentObject';
import Checkbox from '../editing/Checkbox';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import XtwUtils from '../../util/XtwUtils';
import XRowItem from '../../parts/XRowItem';
import GlobalCounter
 from '../../../../utils/GlobalCounter';
export default class RowKeyEventManager extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
		this.assignGettersAndSettersTo( hostPlugin );
		Object.defineProperty( hostPlugin, "keyEventManager", {
			value: {},
			configurable: false,
			writable: false
		} );
		[ "arrowUp", "arrowDown" ].forEach( keyEventType => {
			Object.defineProperty( hostPlugin.keyEventManager, keyEventType, {
				value: {},
				configurable: false,
				writable: false
			} );
		} );
		Validator.unmodifiableGetter( {
			hostObject: hostPlugin.keyEventManager,
			getterName: "shortArrowKeySpan",
			getCallback: () => { return 400; }
		} );
		hostPlugin._setUpTableBodyKeyEventManager();
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		hostPlugin.constructor = void 0;
		delete hostPlugin.constructor;
	}

	get amountOfVisibleRowItems() {
		if ( !Validator.isObject( this.tblBody ) ||
			!Validator.isArray( this.tblBody.rowItems ) ) {
			return void 0;
		}
		return this.tblBody.rowItems.length;
	}

	get _flatIndex() {
		if ( !Validator.isObject( this.item ) ) {
			return void 0;
		}
		const flatIndex = this.item._flatIndex;
		return Validator.isPositiveInteger( flatIndex ) ?
			Number( flatIndex ) : void 0;
	}

	get isTooLow() {
		if ( !this.isRendered || !Validator.isObject( this.tblBody ) ||
			!this.tblBody.isRendered ) {
			return false;
		}
		const tableRect = this.tblBody.element.getBoundingClientRect();
		const rowRect = this.clientRect;
		return rowRect.y + rowRect.height > tableRect.y + tableRect.height;
	}

	get isTooHigh() {
		if ( !this.isRendered || !Validator.isObject( this.tblBody ) ||
			!this.tblBody.isRendered ) {
			return false;
		}
		const tableRect = this.tblBody.element.getBoundingClientRect();
		const rowRect = this.clientRect;
		return rowRect.y < tableRect.y;
	}

	_setUpTableBodyKeyEventManager() {
		if ( !Validator.is( this.tblBody, "XtwBody" ) ) {
			return false;
		}
		if ( Validator.isObject( this.tblBody.keyEventManager ) ) {
			delete this._setUpTableBodyKeyEventManager;
			return true;
		}
		Object.defineProperty( this.tblBody, "keyEventManager", {
			value: {},
			configurable: false,
			writable: false
		} );
		[ "arrowUp", "arrowDown" ].forEach( keyEventType => {
			Object.defineProperty( this.tblBody.keyEventManager, keyEventType, {
				value: {},
				configurable: false,
				writable: false
			} );
		} );
		delete this._setUpTableBodyKeyEventManager;
		return true;
	}

	_getKeyEventManagerForType( keyEventType ) {
		if ( !Validator.isString( keyEventType ) ) {
			return void 0;
		}
		const keyEventManager = this.keyEventManager;
		if ( !Validator.isObject( keyEventManager ) ) {
			return void 0;
		}
		const keyEventManagerForType = keyEventManager[ keyEventType ];
		return Validator.isObject( keyEventManagerForType ) ?
			keyEventManagerForType : void 0;
	}

	onKeyDown( evt ) {
		if ( this.keyEventsFrozen ) {
			return false;
		}
		if ( Validator.isObject( evt ) && Validator.isString( evt.inputId ) ) {
			return false;
		}
		if ( XtwUtils.keyIs( evt, "a" ) &&
			XtwUtils.isCommandKeyPressed( evt ) ) {
			return this.onSelectAll( evt );
		}
		if ( [ "Space", " " ].find(
				key => XtwUtils.keyIs( evt, key ) ) ) {
			return this.onSpaceKey( evt );
		}
		if ( this.dropdownOpen ) {
			return false;
		}
		if ( XtwUtils.isArrowUp( evt ) ) {
			return this.onArrowUpKeyDown( evt );
		}
		if ( XtwUtils.isArrowDown( evt ) ) {
			return this.onArrowDownKeyDown( evt );
		}
		if ( XtwUtils.isPageUp( evt ) ) {
			return this.onPageUpKeyDown( evt );
		}
		if ( XtwUtils.isPageDown( evt ) ) {
			return this.onPageDownKeyDown( evt );
		}
	}

	onKeyUp( evt ) {
		if ( this.dropdownOpen || this.keyEventsFrozen ) {
			return false;
		}
		if ( Validator.isObject( evt ) &&
			( Validator.isString( evt.inputId ) || evt.alreadyHandled ) ) {
			return false;
		}
		if ( DomEventHelper.isArrowUp( evt ) ) {
			return this.onArrowUpKeyUp( evt );
		}
		if ( DomEventHelper.isArrowDown( evt ) ) {
			return this.onArrowDownKeyUp( evt );
		}
		if ( DomEventHelper.isPageUp( evt ) ) {
			return this.onPageUpKeyUp( evt );
		}
		if ( DomEventHelper.isPageDown( evt ) ) {
			return this.onPageDownKeyUp( evt );
		}
		if ( DomEventHelper.keyIs( evt, "F2" ) ) {
			return this.focusFirstAccessibleCell( evt );
		}
	}

	onArrowUpKeyDown( evt ) {
		return this.onVerticalArrowKeyDown( evt, true );
	}

	onArrowDownKeyDown( evt ) {
		return this.onVerticalArrowKeyDown( evt, false );
	}

	onArrowUpKeyUp( evt ) {
		return this.onVerticalArrowKeyUp( evt, true );
	}

	onArrowDownKeyUp( evt ) {
		return this.onVerticalArrowKeyUp( evt, false );
	}

	onPageUpKeyDown( evt ) {
		return this.onPageKeyDown( evt, true );
	}

	onPageDownKeyDown( evt ) {
		return this.onPageKeyDown( evt, false );
	}

	onPageUpKeyUp( evt ) {
		return this.onPageKeyUp( evt, true );
	}

	onPageDownKeyUp( evt ) {
		return this.onPageKeyUp( evt, false );
	}

	onSpaceKey( evt, rowItem = void 0 ) {
		if ( !Validator.isObject( evt ) ) {
			return false;
		}
		evt.preventDefault();
		evt.stopPropagation();
		const row = Validator.is( rowItem, "XRowItem" ) ? rowItem : this;
		if ( !row.isSelected ) {
			return row.syncSelect( true, true, false );
		}
		row.syncDeselect( true, true, false );
		return row.syncFocus( true );
	}

	onVerticalArrowKeyDown( evt, up = false ) {
		if ( !( evt instanceof KeyboardEvent ) ) {
			return false;
		}
		console.log(`#${GlobalCounter.getInst().nextValue()} Vertical arrow key DOWN. Direction=${up}`);
		//const lastPropertiesSaved = this.saveLastArrowKeyEventProperties( evt );

		evt.stopPropagation();
		evt.preventDefault();

		const siblingElement = this.getSiblingRowByElement( !!up );
		if ( !Validator.isObject( siblingElement ) ) {
			return this.scrollThenFocusSibling( evt, !!up, null, false );
		}
		return this.focusAndSelectRowItem( evt, siblingElement, false );

		// return lastPropertiesSaved;
	}

	onVerticalArrowKeyUp( evt, up = false ) {
		console.log(`#${GlobalCounter.getInst().nextValue()} Vertical arrow key UP. Direction=${up}`);
		evt.stopPropagation();
		evt.preventDefault();
		this.tblBody.selectionManager.informAboutRowSelection();
		// return this.isShortArrowKeyPress( evt ) ?
		// 	this.handleShortArrowKeyPress( evt, !!up ) :
		// 	this.handleLongArrowKeyPress( evt, !!up );
	}

	onPageKeyDown( evt, up = false ) {
		if ( evt instanceof KeyboardEvent ) {
			evt.stopPropagation();
			evt.preventDefault();
			return true;
		}
		return false;
	}

	onPageKeyUp( evt, up = false ) {
		return this.handleShortPageKeyPress( evt, !!up );
	}

	handleLongArrowKeyPress( evt, up = false ) {
		const keyEventManagerForType = this
			._getKeyEventManagerForType( !!up ? "arrowUp" : "arrowDown" );
		if ( !Validator.isObject( keyEventManagerForType ) ||
			!Validator.isPositiveInteger( keyEventManagerForType.counter ) ||
			keyEventManagerForType.counter <= 1 ) {
			return this.clearArrowKeyProperties() && false;
		}
		return this.handleVerticalArrowLongKeyPress( {
			evt: evt,
			isArrowUp: up,
			amountOfElementsToScroll: Number( keyEventManagerForType.counter )
		} );
	}

	handleShortArrowKeyPress( evt, up = false ) {
		if ( !( evt instanceof KeyboardEvent ) ) {
			return false;
		}
		this.clearArrowKeyProperties();
		evt.stopPropagation();
		evt.preventDefault();
		const siblingElement = this.getSiblingRowByElement( !!up );
		if ( !Validator.isObject( siblingElement ) ) {
			return this.scrollThenFocusSibling( evt, !!up, null, true );
		}
		return this.focusAndSelectRowItem( evt, siblingElement, true );
	}

	isShortArrowKeyPress( evt ) {
		if ( !( evt instanceof KeyboardEvent ) ) {
			return false;
		}
		const isArrowUp = XtwUtils.isArrowUp( evt );
		if ( !isArrowUp && !XtwUtils.isArrowDown( evt ) ) {
			return false;
		}
		const shortArrowKeySpan = this.keyEventManager.shortArrowKeySpan;
		const keyEventType = isArrowUp ? "arrowUp" : "arrowDown";
		const bodyRowId = Validator.isObjectPath( this.tblBody, "tblBody.keyEventManager" ) &&
			Validator.isObject( this.tblBody.keyEventManager[ keyEventType ] ) ?
			this.tblBody.keyEventManager[ keyEventType ].rowId : void 0;
		if ( Validator.isValidNumber( bodyRowId ) && bodyRowId != this.rowId ) {
			return false;
		}
		// TODO: figure out the whole timeStamp system and base calculations on that
		// if ( Validator.isValidNumber( evt.timeStamp ) &&
		// 	Validator.isValidNumber( this.keyEventManager[ keyEventType ].timeStamp ) &&
		// 	evt.timeStamp - this.keyEventManager[ keyEventType ].timeStamp <=
		// 	shortArrowKeySpan ) {
		// 	return true;
		// }
		const now = Number( Date.now() );
		const dateNow = this.keyEventManager[ keyEventType ].dateNow;
		if ( Validator.isValidNumber( now ) && Validator.isValidNumber( dateNow ) &&
			now - dateNow <= shortArrowKeySpan ) {
			return true;
		}
		return false;
	}

	handleShortPageKeyPress( domEvent, isPageUp = false ) {
		if ( !( domEvent instanceof KeyboardEvent ) ) {
			return false;
		}
		this.clearArrowKeyProperties();
		if ( !Validator.isObjectPath( this.tblBody, "tblBody.model" ) ||
			!Validator.isArray( this.tblBody.model.flatModel, true ) ) {
			// TODO warn
			return false;
		}
		const rowItemsPerPage = this.amountOfVisibleRowItems - 2;
		if ( !Validator.isPositiveInteger( rowItemsPerPage ) ) {
			Warner.traceIf( true, `Could not scroll` +
				` ${ !!isPageUp ? "up" : "down" } one page because the amount of` +
				` row items per page could not be estimated.` );
			return false;
		}
		const flatIndex = this.flatIndex;
		if ( !Validator.isPositiveInteger( flatIndex ) ) {
			Warner.traceIf( true, `Could not scroll` +
				` ${ !!isPageUp ? "up" : "down" } one page because the flat index` +
				` of the model element could not be estimated.` );
			return false;
		}
		domEvent.stopPropagation();
		domEvent.preventDefault();
		const directionProvider = !!isPageUp ? -1 : 1;
		let finalIndex = flatIndex + directionProvider * rowItemsPerPage;
		if ( finalIndex < 0 ) {
			finalIndex = 0;
		}
		const totalAmountOfModelItems = this.tblBody.model.flatModel.length;
		if ( finalIndex > totalAmountOfModelItems ) {
			finalIndex = totalAmountOfModelItems;
		}
		const modelItemToFocus = this.tblBody.model.flatModel[ finalIndex ];
		if ( !Validator.isObject( modelItemToFocus ) ) {
			return false;
		}
		this.tblBody._scrollAndClickModelItem( {
			modelItem: modelItemToFocus,
			directionProvider: directionProvider,
			positionFromTop: this.rowUiOrder,
			controlPressed: domEvent instanceof KeyboardEvent &&
				XtwUtils.isCommandKeyPressed( domEvent ),
			shiftPressed: domEvent instanceof KeyboardEvent && domEvent.shiftKey
		} );
		return true;
	}

	get rowUiOrder() {
		if ( !this.isRendered ) {
			return void 0;
		}
		const parentElement = this.element.parentElement;
		if ( !( parentElement instanceof HTMLElement ) ) {
			return void 0;
		}
		return Validator.getIndexInArray(
			[ ...parentElement.children ], this.element );
	}

	clearArrowKeyProperties() {
		const allRowsCleared = this.clearAllRowsArrowKeyEventsProperties();
		const bodyCleared = this.clearBodyArrowKeyEventsProperties();
		return allRowsCleared && bodyCleared;
	}

	clearArrowKeyEventsProperties( returnValue = void 0 ) {
		[ "arrowDown", "arrowUp" ].forEach( keyEventType => {
			const keyEventManagerForType = this
				._getKeyEventManagerForType( keyEventType );
			if ( !Validator.isObject( keyEventManagerForType ) ) {
				// TODO warn
				return;
			}
			keyEventManagerForType.timeStamp = void 0;
			delete keyEventManagerForType.timeStamp;
			keyEventManagerForType.dateNow = void 0;
			delete keyEventManagerForType.dateNow;
			keyEventManagerForType.counter = void 0;
			delete keyEventManagerForType.counter;
		} );
		return returnValue;
	}

	clearAllRowsArrowKeyEventsProperties() {
		if ( !Validator.is( this.tblBody, "XtwBody" ) ||
			!Validator.isArray( this.tblBody.rowItems, true ) ) {
			return false;
		}
		let rowItemsProcessed = 0;
		this.tblBody.rowItems.forEach( rowItem => {
			if ( !Validator.is( rowItem, "XRowItem" ) ) {
				return;
			}
			rowItem.clearArrowKeyEventsProperties();
			rowItemsProcessed++;
		} );
		return rowItemsProcessed === this.tblBody.rowItems.length;
	}

	clearBodyArrowKeyEventsProperties() {
		if ( !Validator.isObjectPath( this.tblBody, "tblBody.keyEventManager" ) ) {
			return false;
		}
		let bothEventTypesCleared = true;
		[ "arrowDown", "arrowUp" ].forEach( keyEventType => {
			const bodyKeyEventManagerForType = this.tblBody.keyEventManager[ keyEventType ];
			if ( !Validator.isObject( bodyKeyEventManagerForType ) ) {
				// TODO warn
				bothEventTypesCleared = false;
				return;
			}
			bodyKeyEventManagerForType.rowId = void 0;
			delete bodyKeyEventManagerForType.rowId;
			bodyKeyEventManagerForType.timeStamp = void 0;
			delete bodyKeyEventManagerForType.timeStamp;
			bodyKeyEventManagerForType.dateNow = void 0;
			delete bodyKeyEventManagerForType.dateNow;
		} );
		return bothEventTypesCleared;
	}

	saveLastArrowKeyEventProperties( evt ) {
		if ( !( evt instanceof KeyboardEvent ) ) {
			return false;
		}
		const isArrowUp = XtwUtils.isArrowUp( evt );
		if ( !isArrowUp && !XtwUtils.isArrowDown( evt ) ) {
			return false;
		}
		const keyEventType = isArrowUp ? "arrowUp" : "arrowDown";
		const keyEventManagerForType = this._getKeyEventManagerForType( keyEventType );
		const timeStampAlreadyPresent = Validator
			.isValidNumber( keyEventManagerForType.timeStamp );
		if ( !timeStampAlreadyPresent ) {
			return this.registerArrowKeyEventAsFirstOfType(
				evt, keyEventType, keyEventManagerForType );
		}
		const dateAlreadyPresent = Validator
			.isValidNumber( keyEventManagerForType.dateNow );
		if ( !dateAlreadyPresent ) {
			return this.registerArrowKeyEventAsFirstOfType(
				evt, keyEventType, keyEventManagerForType );
		}
		return this.registerArrowKeyEventAsStacked( keyEventManagerForType );
	}

	registerArrowKeyEventAsFirstOfType( evt, keyEventType, keyEventManagerForType ) {
		if ( !( evt instanceof KeyboardEvent ) ||
			!Validator.isString( keyEventType ) ||
			!Validator.isObject( keyEventManagerForType ) ) {
			return false;
		}
		keyEventManagerForType.counter = 1;
		let timeStampSet = false;
		if ( Validator.isValidNumber( evt.timeStamp ) ) {
			keyEventManagerForType.timeStamp = evt.timeStamp;
			timeStampSet = true;
		}
		let dateNowSet = false;
		const now = Number( Date.now() );
		if ( Validator.isValidNumber( now ) ) {
			keyEventManagerForType.dateNow = now;
			dateNowSet = true;
		}
		const rowIdSet = this
			.registerArrowKeyEventOnTableBody( keyEventType, evt.timeStamp, now );
		return timeStampSet && dateNowSet && rowIdSet;
	}

	registerArrowKeyEventOnTableBody( keyEventType, timeStamp, dateNow ) {
		if ( !Validator.isString( keyEventType ) ||
			!Validator.isObjectPath( this.tblBody, "tblBody.keyEventManager" ) ||
			!Validator.isObject( this.tblBody.keyEventManager[ keyEventType ] ) ) {
			// TODO warn
			return false;
		}
		let rowIdSet = false;
		const bodyKeyEventManagerForType = this.tblBody.keyEventManager[ keyEventType ];
		if ( Validator.isValidNumber( timeStamp ) ) {
			bodyKeyEventManagerForType.timeStamp = timeStamp;
		}
		if ( Validator.isValidNumber( dateNow ) ) {
			bodyKeyEventManagerForType.dateNow = dateNow;
		}
		const rowId = this.rowId;
		if ( Validator.isValidNumber( rowId ) ) {
			bodyKeyEventManagerForType.rowId = rowId;
			rowIdSet = true;
		}
		return rowIdSet;
	}

	registerArrowKeyEventAsStacked( keyEventManagerForType ) {
		if ( !Validator.isObject( keyEventManagerForType ) ||
			!Validator.isPositiveInteger( keyEventManagerForType.counter ) ) {
			return false;
		}
		keyEventManagerForType.counter++;
		return true;
	}

	handleVerticalArrowLongKeyPress( {
		evt,
		isArrowUp = false,
		amountOfElementsToScroll
	} ) {
		this.clearArrowKeyProperties();
		if ( !( evt instanceof KeyboardEvent ) ||
			!Validator.isPositiveInteger( amountOfElementsToScroll ) ||
			amountOfElementsToScroll <= 1 ) {
			return false;
		}
		evt.stopPropagation();
		evt.preventDefault();
		const modelItem = this.item;
		const modelItemIsValid = this.modelItemIsValid;
		if ( !modelItemIsValid ) {
			return false;
		}
		if ( !Validator.isObjectPath( this.tblBody, "tblBody.model" ) ||
			!Validator.isArray( this.tblBody.model.flatModel, true ) ) {
			return false;
		}
		const flatModel = this.tblBody.model.flatModel;
		const flatIndex = Validator.getIndexInArray( flatModel, modelItem );
		if ( !Validator.isPositiveInteger( flatIndex ) ) {
			// TODO warn
			return false;
		}
		if ( flatIndex == flatModel.length - 1 && !isArrowUp ) {
			// nowhere to scroll down, already at the bottom
			return true;
		}
		if ( flatIndex == 0 && !!isArrowUp ) {
			// nowhere to scroll up, already at the top
			return true;
		}
		const directionProvider = !!isArrowUp ? -1 : 1;
		const scrollingProperties = this.getArrowKeyScrollingProperties( {
			directionProvider: directionProvider,
			amountOfElementsToScroll: amountOfElementsToScroll,
			flatIndex: flatIndex,
			flatModel: flatModel
		} );
		if ( !Validator.isObject( scrollingProperties ) ) {
			return false;
		}
		const scrollDistanceModulus = scrollingProperties.scrollDistanceModulus;
		const modelItemToSelectAndFocus = scrollingProperties.modelItemToSelectAndFocus;
		const itemsThatWereScrolled = scrollingProperties.itemsThatWereScrolled;
		const currentlyFocusedRowUiOrder = this.rowUiOrder;
		const rowItemsPerPage = this.amountOfVisibleRowItems - 2;
		const amountOfElementsToEndOfPage = rowItemsPerPage - currentlyFocusedRowUiOrder;
		const modelItemToSelectReachedListStartOrEnd =
			Validator.isArray( itemsThatWereScrolled, true ) &&
			amountOfElementsToScroll > itemsThatWereScrolled.length;
		let allowedAmountOfScrollElements = modelItemToSelectReachedListStartOrEnd ?
			itemsThatWereScrolled.length : amountOfElementsToScroll;
		if ( directionProvider > 0 && modelItemToSelectReachedListStartOrEnd &&
			allowedAmountOfScrollElements > amountOfElementsToEndOfPage ) {
			allowedAmountOfScrollElements += -amountOfElementsToEndOfPage + 1;
		} else if ( directionProvider < 0 && modelItemToSelectReachedListStartOrEnd &&
			allowedAmountOfScrollElements > currentlyFocusedRowUiOrder ) {
			allowedAmountOfScrollElements += -currentlyFocusedRowUiOrder;
		}
		return this.forceBodyToScrollAndSelect( {
			evt: evt,
			curentModelItem: modelItem,
			modelItemToSelect: modelItemToSelectAndFocus,
			itemsInbetween: itemsThatWereScrolled,
			scrollDifference: allowedAmountOfScrollElements * directionProvider
		} );
	}

	forceBodyToScrollAndSelect( {
		evt,
		curentModelItem,
		modelItemToSelect,
		itemsInbetween,
		scrollDifference
	} ) {
		if ( !Validator.isArray( itemsInbetween ) ||
			!Validator.isObject( curentModelItem ) ||
			!Validator.isObject( modelItemToSelect ) ) {
			return this.scrollToDifference( scrollDifference );
		}
		const controlPressed = evt instanceof KeyboardEvent &&
			XtwUtils.isCommandKeyPressed( evt );
		const shiftPressed = evt instanceof KeyboardEvent && evt.shiftKey;
		this.setupModelDataCallback( "onVerticalArrowKeyDown-", () => {
			if ( !Validator.isObject( modelItemToSelect ) ) return;
			modelItemToSelect.syncFocus();
			if ( !modelItemToSelect.isSelected ||
				!Validator.isObject( modelItemToSelect.row ) ) return;
			modelItemToSelect.row.syncSelect( true, controlPressed, shiftPressed );
		} );
		if ( Validator.isObject( evt ) ) {
			evt.alreadyHandled = true;
		}
		if ( !shiftPressed && !controlPressed ) {
			// no control & no shift pressed
			const selectionManager = this.selectionManager;
			if ( Validator.isObject( selectionManager ) ) {
				selectionManager.deselectAllRows();
				selectionManager.deselectEveryModelItem();
			}
			modelItemToSelect.syncSelect();
			modelItemToSelect.syncFocus();
			return this.scrollToDifference( scrollDifference );
		}
		if ( !shiftPressed ) {
			// only control is pressed
			modelItemToSelect.syncFocus();
			return this.scrollToDifference( scrollDifference );
		}
		// shift is pressed
		for ( let modelItem of itemsInbetween ) {
			modelItem.syncSelect();
		}
		modelItemToSelect.syncFocus();
		return this.scrollToDifference( scrollDifference );
	}

	setupModelDataCallback( prefix, callback, deleteRightAfterExecution = true ) {
		if ( !Validator.isObject( this.tblBody ) ||
			!Validator.isFunction( this.tblBody.setupModelDataCallback ) ) {
			return false;
		}
		return this.tblBody.setupModelDataCallback( prefix, callback, deleteRightAfterExecution );
		//--------- old code ---------
		if ( !Validator.isObject( this.tblBody ) ||
			!Validator.isString( prefix ) ||
			!Validator.isFunction( callback ) ) {
			return false;
		}
		if ( !Validator.isMap( this.tblBody._afterModelDataCallbacks ) ) {
			this.tblBody._afterModelDataCallbacks = new Map();
		}
		const callBackId = Validator.generateRandomString( prefix );
		// due to the "registration" process this callback could be "called" when
		// this instance does not exist anymore
		const tableBody = this.tblBody;
		const callbackFunction = () => {
			callback();
			if ( !deleteRightAfterExecution || !Validator.isObject( tableBody ) ||
				!Validator.isMap( tableBody._afterModelDataCallbacks, true ) ) {
				return;
			}
			tableBody._afterModelDataCallbacks.delete( callBackId );
		}
		const sameTypeCallbacks = [ ...this.tblBody._afterModelDataCallbacks.keys() ]
			.filter( key => key.startsWith( prefix ) );
		for ( let callbackKey of sameTypeCallbacks ) {
			this.tblBody._afterModelDataCallbacks.delete( callbackKey );
		}
		this.tblBody._afterModelDataCallbacks.set( callBackId, callbackFunction );
		return true;
	}

	selectAndFocusSiblingModelItem( evt, siblingBefore ) {
		if ( !Validator.isObject( this.item ) ||
			!Validator.isObjectPath( this.tblBody, "tblBody.model" ) ||
			!Validator.isArray( this.tblBody.model.flatModel, true ) ) {
			return false;
		}
		const flatModel = this.tblBody.model.flatModel;
		const currentModelItem = this.item;
		const curentIndex = Validator.getIndexInArray( flatModel, currentModelItem );
		if ( !Validator.isPositiveInteger( curentIndex ) ) {
			return false;
		}
		const directionProvider = !!siblingBefore ? -1 : 1;
		let siblingIndex = directionProvider + curentIndex;
		if ( siblingIndex < 0 || siblingIndex >= flatModel.length ) {
			return false;
		}
		let siblingModelElement = flatModel[ siblingIndex ];
		while ( !Validator.is( siblingModelElement, "MDataRow" ) ) {
			siblingIndex = directionProvider + siblingIndex;
			if ( siblingIndex < 0 || siblingIndex >= flatModel.length ) {
				break;
			}
			siblingModelElement = flatModel[ siblingIndex ];
		}
		if ( !Validator.is( siblingModelElement, "MDataRow" ) ) {
			return false;
		}
		return this.selectAndFocusModelItem( evt, siblingModelElement );
	}

	selectAndFocusModelItem( evt, modelItem ) {
		if ( !this.isValidModelItem( modelItem ) ) {
			return false;
		}
		const successfullyFocused = modelItem.syncFocus();
		if ( this.noSelectionAllowed ) {
			return successfullyFocused;
		}
		if ( XtwUtils.isCommandKeyPressed( evt ) ) {
			return successfullyFocused;
		}
		const callback = () => {
			const successfullySelected = modelItem.syncSelect();
			return successfullySelected && successfullyFocused;
		}
		// TODO would this.tblBody.doWithFrozenFocus be more efficient?
		return Validator.isObject( this.tblBody ) &&
			Validator.isFunction( this.tblBody.doWithoutDamagingFocus ) ?
			this.tblBody.doWithoutDamagingFocus( callback ) : callback();
	}

	scrollToDifference( scrollDifference ) {
		if ( !Validator.isValidNumber( scrollDifference ) ||
			!Validator.isObject( this.tblBody ) ||
			!Validator.isFunction( this.tblBody.doWithFrozenFocus ) ) {
			return false;
		}
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ) {
			return false;
		}
		try {
			// TODO would this.tblBody.doWithoutDamagingFocus be more efficient?
			this.tblBody.doWithFrozenFocus( () => {
				const newVerticalSelection = widgetVerticalSelection._selection + scrollDifference;
				// widgetVerticalSelection._selection += scrollDifference;
				widgetVerticalSelection.setSelection( newVerticalSelection );
				this.tblBody.rquVsc = newVerticalSelection;
				this.tblBody.inScrUpd = true;
				this.tblBody._scrDoUpd();
			}, true );
		} finally {
			this.tblBody._doAfterModelData();
		}
		return true;
	}

	getArrowKeyScrollingProperties( {
		directionProvider = 1,
		amountOfElementsToScroll = 0,
		flatIndex = 0,
		flatModel
	} ) {
		if ( !Validator.isPositiveInteger( amountOfElementsToScroll, false ) ||
			!Validator.isPositiveInteger( flatIndex ) ||
			Math.abs( directionProvider ) != 1 ||
			!Validator.isArray( flatModel, true ) ) {
			return void 0;
		}
		let finalIndex = flatIndex + amountOfElementsToScroll * directionProvider;
		if ( finalIndex < 0 ) {
			finalIndex = 0;
		} else if ( finalIndex >= flatModel.length ) {
			finalIndex = flatModel.length - 1;
		}
		const isFinalIndexNotReachedYet = ( modelIndex ) => {
			return directionProvider === -1 ? modelIndex >= finalIndex : modelIndex <= finalIndex;
		}
		let modelItemToSelectAndFocus;
		let scrollDistanceModulus = 0;
		const itemsThatWereScrolled = [];
		for ( let modelIndex = flatIndex + directionProvider; isFinalIndexNotReachedYet( modelIndex ); modelIndex += directionProvider ) {
			const modelItemFromIndex = flatModel[ modelIndex ];
			const modelItemFromIndexIsValid = [ "MDataRow", "MGroup", "MRowItem" ]
				.some( className => !Validator.is( modelItemFromIndex, className ) );
			if ( !modelItemFromIndexIsValid ) {
				continue;
			}
			// we do not test for the presence of the two functions/methods because
			// we assume they should come included for the three prototypes we "allowed"
			const height = modelItemFromIndex.getHeight() + modelItemFromIndex.getVPadding();
			if ( !Validator.isValidNumber( height ) ) {
				continue;
			}
			scrollDistanceModulus += height;
			itemsThatWereScrolled.push( modelItemFromIndex );
			modelItemToSelectAndFocus = modelItemFromIndex;
		}
		return {
			scrollDistanceModulus: scrollDistanceModulus,
			modelItemToSelectAndFocus: modelItemToSelectAndFocus,
			itemsThatWereScrolled: itemsThatWereScrolled
		};
	}

	/**
	 * scrolls the view and focuses the sibling element of the currently focused element
	 * @param {KeyboardEvent} evt the keyboard event
	 * @param {Boolean} up scrolling direction
	 * @param {Boolean} notify flag whether to notfiy the web server
	 * @returns {Boolean} true if successful; false if the sibling could not be scrolled into view
	 */
	scrollThenFocusSibling( evt, up, notify ) {
		const rowHeight = this.clientHeight;
		if ( !Validator.isValidNumber( rowHeight ) ) {
			return false;
		}
		this.selectAndFocusSiblingModelItem( evt, !!up );
		this.setupModelDataCallback( "onVerticalArrowKeyDown-", () => {
			this.focusAfterScrollCallback( evt, !!up, null, notify );
		} );
		const scrollDifference = ( !!up ? -1 : 1 ); // TH - use now the index! * rowHeight;
		this.scrollToDifference( scrollDifference );
		return true;
	}

	focusAfterScrollCallback( evt, up, callBackId, notify ) {
		if ( Validator.isObject( this.tblBody ) &&
			Validator.isMap( this.tblBody._afterModelDataCallbacks, true ) &&
			Validator.isString( callBackId ) ) {
			this.tblBody._afterModelDataCallbacks.delete( callBackId );
		}
		const siblingElement = this.getSiblingRowByElement( !!up );
		const row = Validator.isObject( siblingElement ) ? siblingElement : this;
		if ( !Validator.is( row.item, "MGroup" ) ) {
			return this.focusAndSelectRowItem( evt, row, notify );
		}
		return row.scrollThenFocusSibling( evt, !!up, notify );
	}

	getSiblingRowByElement( previous = false ) {
		if ( !this.isRendered ) {
			return void 0;
		}
		const sibling = this.getSiblingElement( previous );
		if ( !sibling || !( sibling.dataset instanceof DOMStringMap ) ) {
			return void 0;
		}
		const siblingIdx = this.datasetIdToIdx( sibling.dataset.xrowItem );
		return this.getXrowItemByIdx( siblingIdx );
	}

	datasetIdToIdx( datasetId ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.datasetIdToIdx ) ) {
			return void 0;
		}
		return selectionManager.datasetIdToIdx( datasetId );
	}

	getXrowItemByIdx( flatIndex ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.getXrowItemByIdx ) ) {
			return void 0;
		}
		return selectionManager.getXrowItemByIdx( flatIndex );
	}

	scrollToSelf( showSelfOnTop = false ) {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isFunction( widgetVerticalSelection.setSelection ) ) {
			return false;
		}
		const flatIndex = this._flatIndex;
		if ( !Validator.isPositiveNumber( flatIndex ) ) {
			return false;
		}
		if ( !!showSelfOnTop ) {
			widgetVerticalSelection.setSelection( flatIndex );
			return true;
		}
		// the minus two (-2) in the end is due to the fact that the table
		// creates two "extra" elements at the bottom of the row container that
		// are not visible;
		const amountOfVisibleRowItems = this.amountOfVisibleRowItems - 2;
		if ( !Validator.isPositiveNumber( amountOfVisibleRowItems ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( flatIndex - amountOfVisibleRowItems + 1 );
		return true;
	}

	scrollDownToShowSelf() {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isFunction( widgetVerticalSelection.setSelection ) ) {
			return false;
		}
		const flatIndex = this._flatIndex;
		// the minus two (-2) in the end is due to the fact that the table
		// creates two "extra" elements at the bottom of the row container that
		// are not visible;
		const amountOfVisibleRowItems = this.amountOfVisibleRowItems - 2;
		const theoreticalSelection = Validator.isPositiveNumber( flatIndex ) &&
			Validator.isPositiveNumber( amountOfVisibleRowItems ) ?
			flatIndex - amountOfVisibleRowItems : void 0;
		const widgetSelection = widgetVerticalSelection._selection;
		const finalSelection = !Validator.isPositiveNumber( theoreticalSelection ) ?
			widgetSelection : !Validator.isPositiveNumber( widgetSelection ) ?
			theoreticalSelection : theoreticalSelection > widgetSelection ?
			theoreticalSelection : widgetSelection;
		if ( !Validator.isPositiveNumber( finalSelection ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( finalSelection + 1 );
		return true;
	}

	/**
	 * focuses the specified row item an selects it
	 * @param {KeyboardEvent} evt the keyboard event
	 * @param {XRowItem} rowItem the row item
	 * @param {Boolean} notify flag whether to notify the web server
	 */
	focusAndSelectRowItem( evt, rowItem, notify ) {
		if ( !Validator.isObject( evt ) ) {
			return false;
		}
		evt.alreadyHandled = true;
		const row = Validator.is( rowItem, "XRowItem" ) ? rowItem : this;
		if ( row.isTooLow ) {
			row.scrollDownToShowSelf();
		}
		const modelItemIsValid = row.modelItemIsValid;
		if ( modelItemIsValid ) {
			row.item.syncFocus(notify);
		}
		if ( row.noSelectionAllowed ) {
			return row.syncFocus( notify );
		}
		if ( XtwUtils.isCommandKeyPressed( evt ) ) {
			return row.syncFocus( notify );
		}
		const callback = () => {
			console.log(`#${GlobalCounter.getInst().nextValue()} - focusAndSelectRowItem() => callback()`);
			if ( modelItemIsValid ) {
				row.item.syncSelect( true );
			}
			if ( evt.shiftKey ) {
				// "shift" is kind of a "control" here, because the spec say so
				return row.syncSelect( notify, evt.shiftKey, false );
			}
			return row.syncSelect( notify );
		};
		// TODO would this.tblBody.doWithFrozenFocus be more efficient?
		console.log(`#${GlobalCounter.getInst().nextValue()} - focusAndSelectRowItem()`);
		return Validator.isObject( this.tblBody ) &&
			Validator.isFunction( this.tblBody.doWithoutDamagingFocus ) ?
			this.tblBody.doWithoutDamagingFocus( callback ) : callback();
	}

	focusFirstAccessibleCell( domEvent ) {
		const firstEditableCell = this.firstEditableCell;
		if ( !Validator.isObject( firstEditableCell ) ) {
			return false;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		if ( firstEditableCell.checkbox instanceof Checkbox ) {
			return firstEditableCell.focusCheckbox( domEvent );
		}
		return firstEditableCell.onCellContainerClick( domEvent );
	}

}
