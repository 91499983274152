import EditableElement from './EditableElement';
import Validator from '../../../../utils/Validator';
import EventListenerManager from '../../../../utils/EventListenerManager';
import Warner from '../../../../utils/Warner';
import TextareaKeyHandlingExtension from './TextareaKeyHandlingExtension';
import MaximizeButtonExtension from './MaximizeButtonExtension';
import CharactersCounterExtension from './CharactersCounterExtension';
import EditingElementSelectionManagerExtension from './EditingElementSelectionManagerExtension';

export default class Textarea extends EditableElement {

	constructor( cellObject ) {
		super( cellObject );
		cellObject.textarea = this;
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) ) {
			xtwBody.textarea = this;
		}
		new TextareaKeyHandlingExtension( this );
		new MaximizeButtonExtension( this );
		new CharactersCounterExtension( this );
		new EditingElementSelectionManagerExtension( this );
	}

	get input() {
		return this.textarea;
	}

	get inputId() {
		return this.textarea instanceof HTMLElement ? this.textarea.id : null;
	}

	get inputValue() {
		if ( !( this.textarea instanceof HTMLElement ) ) {
			return null;
		}
		let value = this.textarea.value;
		if ( Validator.isString( value ) ) {
			return String( value );
		}
		value = this.textarea.innerHTML;
		return Validator.isString( value ) ? value : null;
	}

	set inputValue( value ) {
		if ( !( this.textarea instanceof HTMLElement ) ) {
			return;
		}
		if ( !Validator.isString( value ) ) {
			value = "";
		}
		this.textarea.innerHTML = value;
		this.updateCounterContent();
	}

	get isRendered() {
		return this.container instanceof HTMLElement && this.textarea instanceof HTMLElement;
	}

	get rowElement() {
		const row = this.row;
		if ( !Validator.isObject( row ) ) {
			return null;
		}
		const rowElement = row.element;
		return rowElement instanceof HTMLElement ? rowElement : null;
	}

	get newTextarea() {
		const textarea = window.document.createElement( "textarea" );
		textarea.id = Validator.generateRandomString( "rtp-textarea-" );
		textarea.tabIndex = 1;
		this.addTextareaListeners( textarea );
		return textarea;
	}

	get newContainer() {
		const textareaDiv = window.document.createElement( "div" );
		textareaDiv.classList.add( "rtp-textarea-container" );
		textareaDiv.tabIndex = 1;
		return textareaDiv;
	}

	setEditingPermission( editingAllowed ) {
		this.editingAllowed = !!editingAllowed;
		if ( !this.editingAllowed && this.textarea instanceof HTMLElement ) {
			this.textarea.readonly = true;
		}
		return this.editingAllowed ? this.markRowAsEdited() : this.resetInput();
	}

	markRowAsEdited() {
		this.rowEdited = true;
	}

	setupRowElement() {
		const rowElement = this.rowElement;
		if ( !( rowElement instanceof HTMLElement ) ) {
			return false;
		}
		rowElement.classList.add( "has-textarea" );
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			return false;
		}
		cellElement.classList.add( "has-textarea" );
		let parentElement = cellElement.parentElement;
		while ( parentElement instanceof HTMLDivElement && parent != rowElement ) {
			parentElement.classList.add( "has-textarea" );
			parentElement = parentElement.parentElement;
		}
		return true;
	}

	cleanRowElement() {
		const rowElement = this.rowElement;
		if ( !( rowElement instanceof HTMLElement ) ) {
			return false;
		}
		rowElement.classList.remove( "has-textarea" );
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			return false;
		}
		cellElement.classList.remove( "has-textarea" );
		let parentElement = cellElement.parentElement;
		while ( parentElement instanceof HTMLDivElement && parent != rowElement ) {
			parentElement.classList.remove( "has-textarea" );
			parentElement = parentElement.parentElement;
		}
		return true;
	}

	// cleanCellElement() {
	// 	const cellElement = this.cellElement;
	// 	if ( !( cellElement instanceof HTMLElement ) ) {
	// 		return false;
	// 	}
	// 	cellElement.classList.remove( "has-textarea" );
	// 	return true;
	// }

	render() {
		if ( this.shouldBeSkipped ) {
			return false;
		}
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			Warner.traceIf( true, `Invalid cell element` );
			return false;
		}
		const innerText = cellElement.innerText;
		cellElement.innerHTML = "";
		this.container = this.newContainer;
		this.textarea = this.newTextarea;
		this.textarea.innerHTML = innerText;
		const maxCharacterCount = this.maxCharacterCount;
		if ( Validator.isPositiveInteger( maxCharacterCount ) ) {
			this.textarea.maxLength = maxCharacterCount;
		}
		const horizontalAlignment = this.horizontalAlignment;
		if ( Validator.isString( horizontalAlignment ) ) {
			this.textarea.style.textAlign = horizontalAlignment;
		}
		this.button = this.newMinimizeButton;
		this.counter = this.newCharactersCounter;
		this.updateCounterContent( innerText.length );
		this.container.appendChild( this.textarea );
		this.container.appendChild( this.button );
		this.container.appendChild( this.counter );
		cellElement.appendChild( this.container );
		if ( this.canBeEdited && this.editingAllowed ) {
			this.register();
		} else {
			this.readonly = true;
		}
		// this.setupCellElement();
		return this.setupRowElement();
	}

	resetInput() {
		if ( !( this.textarea instanceof HTMLElement ) ) {
			return false;
		}
		const originalValue = this.originalValue || "";
		this.textarea.innerHTML = originalValue;
		return true;
	}

	get contentEditableElement() {
		return this.textarea;
	}

	updateCounterContent( contentLength = void 0 ) {
		if ( !( this.counter instanceof HTMLElement ) ) {
			return false;
		}
		if ( !Validator.isPositiveInteger( Number( contentLength ) ) ) {
			contentLength = this.currentContentLength;
		}
		this.counter.innerHTML = this.getCounterContent( contentLength );
		return true;
	}

	discardUi() {
		const counterDiscarded = this._discardElementProperty( "counter" );
		const buttonDiscarded = this.discardButton();
		const everythingElseDiscarded = super.discardUi();
		const rowElementCleaned = this.cleanRowElement();
		return counterDiscarded && buttonDiscarded && everythingElseDiscarded && rowElementCleaned;
	}

	discardButton() {
		[ "mousedown", "mouseup", "click" ].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName, this.button, "ButtonContainer" );
		} );
		return this._discardElementProperty( "button" );
	}

	destroySelf() {
		this.discardUi();
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) && xtwBody.textarea === this ) {
			xtwBody.textarea = null;
			delete xtwBody.textarea;
		}
		const cellObject = this.cell;
		if ( cellObject ) {
			cellObject.textarea = null;
			delete cellObject.textarea;
			this.cell = null;
			delete this.cell;
		}
		const propertyNames = Object.getOwnPropertyNames(Object.getPrototypeOf( this ) );
		for ( let propertyName of propertyNames ) {
			Object.defineProperty( this, propertyName, {
				value: null,
				writable: true,
				configurable: true
			} );
			this[ propertyName ] = null;
			delete this[ propertyName ];
		}
		return true;
	}

	discardInput() {
		[ "keydown", "keyup", "keypress", "blur", "mousewheel", "contextmenu", "paste", "input" ]
		.forEach( eventName => {
			EventListenerManager.removeListener( this, eventName, this.textarea, "Textarea" );
		} );
		return this._discardElementProperty( "textarea" );
	}

	informAboutContentChange() {
		if ( this.originalValue == this.inputValue || !this.editingAllowed ) {
			return false;
		}
		this.informAboutSave();
		return true;
	}

}
