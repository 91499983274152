import PSA from '../../../../psa';
import ItmMgr from '../../../../gui/ItmMgr';
import MnuMgr from '../../../../gui/menu/MnuMgr';
import Validator from '../../../../utils/Validator';
import XtwSortItem from './XtwSortItem';
import XtwBody from '../../XtwBody';
import XtwHead from '../../XtwHead';

let PISASALES = void 0;
let ITEM_MANAGER = void 0;

const FLAP_WIDTH = 36;
const FLAP_PADDING_LEFT = 4;
const FLAP_PADDING_RIGHT = 4;
const FLAP_CTT_PADDING_LEFT = 8;

const FLAP_VAR_CLRBKG_NORMAL = '--pisa-flapClrBkgNormal';
const FLAP_VAR_CLRBKG_ACTIVE = '--pisa-flapClrBkgActive';
const FLAP_VAR_CLRTXT_NORMAL = '--pisa-flapClrTxtNormal';
const FLAP_VAR_CLRTXT_ACTIVE = '--pisa-flapClrTxtActive';
const FLAP_VAR_WIDTH_SHORT = '--pisa-xtwsortflap-width-short';
const FLAP_VAR_WIDTH_LONG = '--pisa-xtwsortflap-width-long';
const FLAP_VAR_PADDING_LEFT = '--pisa-xtwsortflap-padding-left';
const FLAP_VAR_PADDING_RIGHT = '--pisa-xtwsortflap-padding-right';
const FLAP_VAR_CTT_WIDTH = '--pisa-xtwsfctt-width';
const FLAP_VAR_CTT_PADDING_LEFT = '--pisa-xtwsfctt-padding-left';

const FLAP_ICO_TEXT_ASCENDING = 'fa fa-sort-alpha-asc';
const FLAP_ICO_TEXT_DESCENDING = 'fa fa-sort-alpha-desc';
const FLAP_ICO_NUMERIC_ASCENDING = 'fa fa-sort-numeric-asc';
const FLAP_ICO_NUMERIC_DESCENDING = 'fa fa-sort-numeric-desc';
const FLAP_ICO_DATE_ASCENDING = 'fa fa-sort-numeric-asc';
const FLAP_ICO_DATE_DESCENDING = 'fa fa-sort-numeric-desc';

const FLAP_TIMEOUT = 2021.22;

/**
 * class XtwSortFlap - a sliding flap that offers sort features
 */
export default class XtwSortFlap {

	/**
	 * constructs a new instance
	 * @param {XtwBody} xtb constructs a new instance
	 * @param {HTMLElement} the parent DOM element
	 * @param {Object} props initialization properties
	 */
	constructor( xtb, de, props ) {
		if ( !Validator.isObject( PISASALES ) ) {
			PISASALES = PSA.getInst();
		}
		if ( !Validator.isObject( ITEM_MANAGER ) ) {
			ITEM_MANAGER = ItmMgr.getInst();
		}
		this.xtwHead = xtb.getXtwHead();
		this.xtwBody = xtb;
		this.fldMenu = null;
		this.sortItems = new Map();
		// clone properties
		this.props = Object.assign( {}, props );

		// create DOM elements
		const element = document.createElement( 'div' );
		element.classList.add( 'flap_basic' );
		element.classList.add( 'xtwsortflap' );
		element.classList.add( 'xtwsortflap_short' );
		element.style.setProperty( FLAP_VAR_WIDTH_SHORT, '' + FLAP_WIDTH + 'px' );
		element.style.setProperty( FLAP_VAR_PADDING_LEFT, '' + FLAP_PADDING_LEFT + 'px' );
		element.style.setProperty( FLAP_VAR_PADDING_RIGHT, '' + FLAP_PADDING_RIGHT + 'px' );

		const ctt = document.createElement( 'div' );
		ctt.classList.add( 'xtwsfctt' );
		ctt.style.setProperty( FLAP_VAR_CTT_WIDTH, '100%' );
		ctt.style.setProperty( FLAP_VAR_CTT_PADDING_LEFT, '' + FLAP_CTT_PADDING_LEFT + 'px' );

		const label = document.createElement( 'span' );
		label.classList.add( 'xtwsflabel' );
		// label.innerHTML = pisasales.escHtml( this.props.txtSortLabel );
		label.innerHTML = PISASALES.escHtml( this.props.txtSortLabel );
		ctt.appendChild( label );

		const field = document.createElement( 'span' );
		field.classList.add( 'xtwsffield' );
		ctt.appendChild( field );

		const btn = document.createElement( 'div' );
		btn.classList.add( 'xtwsfbtn' );

		element.appendChild( ctt );
		element.appendChild( btn );

		const self = this;
		element.addEventListener( 'mouseenter', ( e ) => {
			self._onMouseEnter( e );
		} );
		element.addEventListener( 'mouseleave', ( e ) => {
			self._onMouseLeave( e );
		} );
		btn.addEventListener( 'click', ( e ) => {
			self._onClickButton( e );
		} );
		field.addEventListener( 'click', ( e ) => {
			self._onClickField( e );
		} );

		de.appendChild( element );
		this.element = element;
		this.btnSort = btn;
		this.ttlField = field;
		this.cttCont = ctt;
		this._setCssVars();
		this._createFieldMenu();
		this.locked = false;
		this.closeTimer = null;
	}

	/**
	 * destructor method
	 */
	destroy() {
		this._stopTimer();
		this._dropFieldMenu();
		this.sortItems.clear();
		delete this.fldMenu;
		delete this.closeTimer;
		delete this.sortItems;
		delete this.btnSort;
		delete this.ttlField;
		delete this.cttCont;
		delete this.xtwHead;
		delete this.xtwBody;
		if ( this.element ) {
			// pisasales.rmvDomElm( this.element );
			PISASALES.rmvDomElm( this.element );
			delete this.element;
		}
	}

	/**
	 * clears the current sorting column
	 */
	clearSortColumn() {
		this.setSortColumn( -1, false, false );
	}

	/**
	 * sets the current sort column
	 * @param {Number} idc column ID
	 * @param {Boolean} dir sorting direction
	 * @param {Boolean} nfy flag whether to notify the web server
	 */
	setSortColumn( idc, dir, nfy ) {
		const xth = this.xtwHead;
		if ( xth ) {
			let text = this.props.txtNotSorted;
			let type = 0;
			let id = -1;
			if ( idc > 0 ) {
				const si = this.sortItems.get( idc );
				if ( si ) {
					text = si.txt;
					id = si.id;
					type = si.type;
				}
			}
			this._setFieldTitle( text );
			this._setSortBtn( type, dir );
			if ( this.fldMenu ) {
				this.fldMenu.checkOne( id );
			}
			xth.setSortColumn( id, dir, nfy );
		}
	}

	/**
	 * updates the field menu
	 */
	updateFieldMenu() {
		// just create a new one...
		this._createFieldMenu();
	}

	/**
	 * menu handler callback: called if a menu is closed
	 */
	onMenuClose() {
		if ( !this.closeTimer ) {
			this.locked = false;
			this._toggleFlap( false );
		}
	}

	/**
	 * menu handler callback: called if a menu item was clicked
	 * @param {Number} id ID of selected menu item
	 */
	onMenuItem( id ) {
		const xth = this.xtwHead;
		if ( xth ) {
			const csc = xth.getSortColumn();
			let dir = false;
			const si = this.sortItems.get( id );
			if ( si ) {
				// get sort order from selected item
				dir = si.isDescending();
			}
			if ( ( csc > 0 ) && ( csc === id ) ) {
				// toggle direction
				dir = !xth.getSortDirection();
			}
			this.setSortColumn( id, dir, true );
		}
		this._stopTimer();
		const self = this;
		this.closeTimer = window.setTimeout( () => {
			self._onCloseTimer();
		}, FLAP_TIMEOUT );
	}

	/**
	 * sets CSS variables
	 */
	_setCssVars() {
		if ( this.element ) {
			// const im = pisasales.getItmMgr();
			const im = ITEM_MANAGER;
			const props = this.props;
			const names = [ FLAP_VAR_CLRBKG_NORMAL, FLAP_VAR_CLRBKG_ACTIVE, FLAP_VAR_CLRTXT_NORMAL, FLAP_VAR_CLRTXT_ACTIVE ];
			const clrs = [
				props.clrBkgNormal || null,
				props.clrBkgActive || null,
				props.clrTxtNormal || null,
				props.clrTxtActive || null
			];
			for ( let i = 0; i < clrs.length; ++i ) {
				const rgb = im.getRgb( clrs[ i ] );
				if ( rgb ) {
					this.element.style.setProperty( names[ i ], rgb );
				}
			}
		}
	}

	/**
	 * sets a new field title
	 * @param {String} ttl new field title
	 */
	_setFieldTitle( ttl ) {
		if ( this.element ) {
			if ( this.ttlField ) {
				// if ( pisasales.isStr( ttl ) ) {
				if ( PISASALES.isStr( ttl ) ) {
					// this.ttlField.innerHTML = pisasales.escHtml( ttl );
					this.ttlField.innerHTML = PISASALES.escHtml( ttl );
				} else {
					this.ttlField.innerHTML = '';
				}
			}
			let full_width = FLAP_WIDTH + FLAP_PADDING_LEFT + FLAP_PADDING_RIGHT + FLAP_CTT_PADDING_LEFT;
			if ( this.cttCont ) {
				// const im = pisasales.getItmMgr();
				const im = ITEM_MANAGER;
				const res = im.measureText( this.cttCont, false, 0, false );
				const width = res.sts.cx;
				full_width += width;
			}
			this.element.style.setProperty( FLAP_VAR_WIDTH_LONG, '' + full_width + 'px' );
		}
	}

	/**
	 * creates the sort button icon
	 * @param {String} ico icon name
	 * @returns {String} the inner HTML for the sort button
	 */
	_creBtnIco( ico ) {
		return '<i class="' + ico + '" style="vertical-align:middle;"></i>';
	}

	/**
	 * updates the UI of the sort button
	 * @param {Number} type data type of current sort column
	 * @param {Boolean} dir sort order
	 */
	_setSortBtn( type, dir ) {
		if ( this.btnSort ) {
			let ico = null;
			switch ( type ) {
				case 1:
					ico = dir ? FLAP_ICO_NUMERIC_DESCENDING : FLAP_ICO_NUMERIC_ASCENDING;
					break;
				case 2:
					ico = dir ? FLAP_ICO_DATE_DESCENDING : FLAP_ICO_DATE_ASCENDING;
					break;
				default:
					ico = dir ? FLAP_ICO_TEXT_DESCENDING : FLAP_ICO_TEXT_ASCENDING;
					break;
			}
			this.btnSort.innerHTML = this._creBtnIco( ico );
		}
	}

	_getRowTpl() {
		let rtp = null;
		if ( this.xtwHead && this.xtwHead.xtdTbl && this.xtwHead.xtdTbl.hasRowTpl ) {
			rtp = this.xtwHead.xtdTbl.getRowTpl();
		}
		return rtp;
	}

	_dropFieldMenu() {
		if ( this.fldMenu ) {
			try {
				this.fldMenu.destroy();
			} finally {
				this.fldMenu = null;
			}
		}
	}

	/**
	 * creates the field select menu
	 */
	_createFieldMenu() {
		this._dropFieldMenu();
		this.sortItems.clear();
		const xth = this.xtwHead;
		const xtb = this.xtwBody;
		if ( xtb && xth ) {
			const self = this;
			const si_map = this.sortItems;
			// filter column callback
			const filter = ( col ) => {
				return !xtb.noSortCols.has( col.dsc );
			}
			// create item callback
			const create_item = ( id, txt, col ) => {
				const type = col.type || 0;
				const dir = col.defSortDir;
				const si = new XtwSortItem( id, txt, type, dir, false );
				si_map.set( id, si );
				return si;
			};
			// post item create callback
			const post_creation = ( items ) => {
				// if ( pisasales.isStr( self.props.txtSortLabel ) ) {
				if ( PISASALES.isStr( self.props.txtSortLabel ) ) {
					// prepend two special items
					items.unshift(
						new XtwSortItem( 0, self.props.txtSortLabel, 0, false, true ), { id: -1, stc: true }
					);
				}
			};
			// create field menu
			this.fldMenu = xtb.createFieldMenu( true, filter, create_item, post_creation, false );
			// get current sort settings
			this.setSortColumn( xth.getSortColumn(), xth.getSortDirection(), false );
		} else {
			this.clearSortColumn();
		}
	}

	/**
	 * 'mouseenter' listener
	 * @param {MouseEvent} e the event
	 */
	_onMouseEnter( e ) {
		// pisasales.stopEvent( e );
		PISASALES.stopEvent( e );
		this._stopTimer();
		this.locked = false;
		if ( this.element && this.cttCont ) {
			this._toggleFlap( true );
		}
	}

	/**
	 * 'mouseleave' listener
	 * @param {MouseEvent} e the event
	 */
	_onMouseLeave( e ) {
		// pisasales.stopEvent( e );
		PISASALES.stopEvent( e );
		this._closeFlap();
	}

	/**
	 * stops the "close" timer
	 */
	_stopTimer() {
		if ( this.closeTimer ) {
			const tmh = this.closeTimer;
			this.closeTimer = null;
			window.clearTimeout( tmh );
		}
	}

	/**
	 * closes the flap
	 */
	_closeFlap() {
		if ( !this.locked && this.element && this.cttCont ) {
			this._toggleFlap( false );
			this._stopTimer();
		}
	}

	/**
	 * called after the "close" timout has been elapsed
	 */
	_onCloseTimer() {
		if ( this.closeTimer ) {
			this.locked = false;
			this._closeFlap();
		}
	}

	/**
	 * toggles the display mode
	 * @param {Boolean} long flag whether to show the long flap
	 */
	_toggleFlap( long ) {
		if ( long ) {
			this.element.classList.remove( 'xtwsortflap_short' );
			this.element.classList.add( 'xtwsortflap_long' );
			this.cttCont.classList.add( 'xtwsfctt_visible' );
		} else {
			this.element.classList.remove( 'xtwsortflap_long' );
			this.element.classList.add( 'xtwsortflap_short' );
			this.cttCont.classList.remove( 'xtwsfctt_visible' );
		}
	}

	/**
	 * 'click' listener for the sort button
	 * @param {MouseEvent} e the click event
	 */
	_onClickButton( e ) {
		// pisasales.stopEvent( e );
		PISASALES.stopEvent( e );
		const xth = this.xtwHead;
		if ( xth ) {
			const csc = xth.getSortColumn();
			if ( csc > 0 ) {
				const locked = this.locked;
				try {
					this.locked = true;
					const dir = !xth.getSortDirection();
					this.setSortColumn( csc, dir, true );
				} finally {
					this.locked = locked;
				}
			}
		}
	}

	/**
	 * 'click' listener for the field title
	 * @param {MouseEvent} e the click event
	 */
	_onClickField( e ) {
		// pisasales.stopEvent( e );
		PISASALES.stopEvent( e );
		if ( this.element && this.ttlField && this.fldMenu ) {
			this.locked = true;
			// pisasales.getMnuMgr().showMenu( this.fldMenu, { element: this.ttlField, rect: null }, this, true, true );
			PISASALES.getMnuMgr().showMenu( this.fldMenu, { element: this.ttlField, rect: null }, this, true, true );
		}
	}
}
