import MRowItem from './MRowItem';
import Validator from '../../../utils/Validator';
import ObjReg from '../../../utils/ObjReg';
import MGroup from './MGroup';
import MCell from './MCell';
import MDataRowEditingExtension from '../impl/editing/MDataRowEditingExtension';
import MDataRowInsertionDummyExtension from '../impl/editing/MDataRowInsertionDummyExtension';

/**
 * data row class
 */
export default class MDataRow extends MRowItem {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 * @param {Number} drh default row height
	 * @param {MGroup} group the group to which this row belongs
	 */
	constructor( json, drh, group ) {
		super( json );
		this._height = json.height || drh;
		const dummy = !!json.dummy;
		Object.defineProperty( this, "_dummy", { value: dummy, writable: false, configurable: false } );
		if ( dummy && group instanceof MGroup ) {
			group.dummyModelItem = this;
			new MDataRowInsertionDummyExtension( this );
		}
		this.cells = new ObjReg();
		this.group = group;
		this.present = dummy; // the dummy row is always present
		new MDataRowEditingExtension( this );
	}

	/**
	 * @override
	 */
	doDestroy() {
		if ( this.cells ) {
			this.cells.dstChl();
		}
		delete this.cells;
		delete this.group;
		super.doDestroy();
	}

	get rowId() {
		if ( this._dummy && !this.insertionDummy ) {
			return -1;
		}
		const rowId = Number( this.xid );
		return !Validator.isPositiveInteger( rowId, false ) ||
			!Validator.isValidNumber( rowId ) ? void 0 : rowId;
	}

	get xtwModel() {
		if ( !Validator.is( this.group, "MGroup" ) ) {
			return void 0;
		}
		return Validator.is( this.group.xtwModel, "XtwModel" ) ?
			this.group.xtwModel : void 0;
	}

	get xtwBody() {
		const xtwModel = this.xtwModel;
		if ( !Validator.isObject( xtwModel ) ||
			!Validator.is( xtwModel.tblBody, "XtwBody" ) ) {
			return void 0;
		}
		return xtwModel.tblBody;
	}

	get isRapFocus() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) || !( "isRapFocus" in xtwBody ) ) {
			return false;
		}
		return !!xtwBody.isRapFocus;
	}

	get idManager() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) ||
			!Validator.is( xtwBody.idManager, "XtwRtpIdManager" ) ) {
			return void 0;
		}
		return xtwBody.idManager;
	}

	get isRowTpl() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) || !( "isRowTpl" in xtwBody ) ) {
			return void 0;
		}
		return xtwBody.isRowTpl;
	}

	get rtpRowHeight() {
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( idManager.rowHeight ) ?
			idManager.rowHeight : void 0;
	}

	get selectionManager() {
		const xtwBody = this.xtwBody;
		if ( !Validator.is( xtwBody, "XtwBody" ) ) {
			return void 0;
		}
		const selectionManager = xtwBody.selectionManager;
		return Validator.is( selectionManager, "SelectionManager" ) ?
			selectionManager : void 0;
	}

	get row() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.getXRowItemById ) ) {
			return void 0;
		}
		return selectionManager.getXRowItemById( this.idr );
	}

	get element() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !row.isRendered ) {
			return void 0;
		}
		const element = row.element;
		return !( element instanceof HTMLElement ) ? void 0 :
			element.__mi === this ? element : void 0;
	}

	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	set ovrHeight( newValue ) {
		this._ovrHeight = newValue;
	}

	set height( newValue ) {
		this._height = newValue;
	}

	get ovrHeight() {
		if ( !this.isRowTpl ) {
			return this._ovrHeight;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ? rtpRowHeight : this._ovrHeight;
	}

	get height() {
		if ( !this.isRowTpl ) {
			return this._height;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ? rtpRowHeight : this._height;
	}

	/**
	 * @override
	 */
	isDataRow() {
		return true;
	}

	/**
	 * @override
	 */
	isDummyRow() {
		return this._dummy;
	}

	get isUneditableDummyRow() {
		return !this.insertionDummy && this.isDummyRow();
	}

	/**
	 * @override
	 */
	setData( data ) {
		super.setData( data );
		if ( ( this.idr === data.idr ) && ( data.type === false ) ) {
			const cells = data.cells || EMPTY_ARR;
			const self = this;
			cells.forEach( ( cd ) => {
				self._setCell( cd );
			} );
			this.present = true;
		} else {
			console.error( 'Invalid data item!' );
			console.error( data );
			throw new Error( 'Invalid data item!' );
		}
	}

	/**
	 * retrieves a data cell
	 * @param {Number} idc column ID
	 * @returns {MCell} the data cell or null
	 */
	getCell( idc ) {
		return this.cells.getObj( idc );
	}

	/**
	 * returns all data cells as iterable object
	 * @returns {IterableIterator<MCell>} an iterable object of all data cells
	 */
	getCells() {
		return this.cells;
	}

	/**
	 * creates or updates a data cell and sets its content
	 * @param {Object} cd cell data
	 */
	_setCell( cd ) {
		const idc = cd.idc || 0;
		if ( idc && ( idc !== -1 ) ) {
			const cell = this.cells.getObj( idc );
			if ( !cell ) {
				this.cells.addObj( idc, new MCell( cd ) );
			} else {
				cell.setCtt( cd.ctt );
			}
		}
	}

	syncSelect( updateUi = true ) {
		this.isSelected = true;
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		const rowId = this.rowId;
		if ( updateUi ) {
			const focusConservationFunctionName = this.isRapFocus ?
				"_doWithPerservingFocus" : "_doWithFrozenFocus";
			if ( selectionManager[ focusConservationFunctionName ]( () => {
					return selectionManager.select( { rowId: rowId, controlPressed: true } );
				} ) ) {
				return true;
			}
		}
		return selectionManager.addRowToCurrentlySelected( rowId );
	}

	syncDeselect( updateUi = true ) {
		this.isSelected = false;
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		const rowId = this.rowId;
		if ( updateUi && selectionManager._doWithPerservingFocus( () => {
				return selectionManager.deselect( { rowId: rowId, controlPressed: true } );
			} ) ) {
			return true;
		}
		return selectionManager.moveRowToPreviouslySelected( rowId, false );
	}

	syncFocus() {
		this.isFocused = true;
		const selectionManager = this.selectionManager;
		return !Validator.isObject( selectionManager ) ? false :
			selectionManager.focusRowById( this.rowId );
	}

	syncUnfocus() {
		this.isFocused = false;
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		if ( selectionManager.focusRowId !== this.rowId ) {
			return true;
		}
		return selectionManager._unfocus();
	}

	/**
	 * makes sure that the information about current focus and selection is in
	 * sync with (is the same as) the information from the selection manager
	 * @param {boolean} updateUi whether or not the UI (visual representations
	 * of the rows) should also be updated
	 * @see XtwRtpItm.js~SelectionManager
	 * @return {boolean} whether or not the synchronisation was successfull
	 */
	syncSelectionManager( updateUi = true ) {
		const selectionSynchronised = this.isSelected ? this.syncSelect( updateUi ) : this.syncDeselect( updateUi );
		const focusSynchronised = this.isFocused ? this.syncFocus( updateUi ) : this.syncUnfocus( updateUi );
		return selectionSynchronised && focusSynchronised;
	}
}
