import Validator from '../../../utils/Validator';
import DomEventHelper from '../../../utils/DomEventHelper';

export default class XtwUtils {

	static getCoordinateParameters( evt, element, parameters = {} ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		XtwUtils._addElementRectToParameters( parameters, element );
		XtwUtils._addEventCoordinatesToParameters( parameters, evt );
		return parameters;
	}

	static _addElementRectToParameters( parameters, element ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( !( element instanceof HTMLElement ) ) {
			return parameters;
		}
		const clientRect = element.getBoundingClientRect();
		Object.assign( parameters, {
			clientRect: [
				Math.round( clientRect.left ), Math.round( clientRect.top ),
				Math.round( clientRect.width ), Math.round( clientRect.height )
			]
		} );
		return parameters;
	}

	static _addEventCoordinatesToParameters( parameters, evt ) {
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( !Validator.couldBe( evt, "Event" ) ) {
			return parameters;
		}
		Object.assign( parameters, {
			clientX: Math.round( evt.clientX ),
			clientY: Math.round( evt.clientY )
		} );
		return parameters;
	}

	static colorArrayToRgba( sourceArray ) {
		if ( !Validator.isArray( sourceArray ) ) {
			return sourceArray;
		}
		const colorArray = XtwUtils.cleanRgbaColorArray( sourceArray );
		if ( !Validator.isArray( colorArray ) || colorArray.length < 4 ) {
			return sourceArray;
		}
		return `rgba(${ colorArray[ 0 ] },${ colorArray[ 1 ] },` +
			`${ colorArray[ 2 ] },${ colorArray[ 3 ] })`;
	}

	static cleanRgbaColorArray( sourceArray ) {
		if ( !Validator.isArray( sourceArray ) ) {
			return void 0;
		}
		const redGreenBlueAlpha = [ ...sourceArray ]
			.filter( light => Validator.isPositiveInteger( light ) && light <= 255 )
			.slice( 0, 4 );
		if ( redGreenBlueAlpha.length == 3 ) {
			return redGreenBlueAlpha.concat( 1 );
		}
		if ( redGreenBlueAlpha.length != 4 ) {
			return void 0;
		}
		let alphaOpacity = redGreenBlueAlpha[ 3 ] / 255;
		if ( alphaOpacity < 0 ) {
			alphaOpacity = 0;
		} else if ( alphaOpacity > 1 ) {
			alphaOpacity = 1;
		}
		alphaOpacity = Number( alphaOpacity.toFixed( 1 ) );
		redGreenBlueAlpha[ 3 ] = alphaOpacity;
		return redGreenBlueAlpha;
	}

	static keyIs( evt, keyOrCodeName ) {
		return DomEventHelper.keyIs(evt, keyOrCodeName);
	}

	static isCommandKeyPressed( evt ) {
		return DomEventHelper.isCtrlEvent(evt);
	}

	static isArrowUp( evt ) {
		return DomEventHelper.isArrowUp(evt);
	}

	static isArrowDown( evt ) {
		return DomEventHelper.isArrowDown(evt);
	}

	static isArrowLeft( evt ) {
		return DomEventHelper.isArrowLeft(evt);
	}

	static isArrowRight( evt ) {
		return DomEventHelper.isArrowRight(evt);
	}

	static isPageUp( evt ) {
		return DomEventHelper.isPageUp(evt);
	}

	static isPageDown( evt ) {
		return DomEventHelper.isPageDown(evt);
	}

	static isPrintableKey( evt ) {
		return DomEventHelper.isPrintableKey(evt);
	}

	static isContentChangingKey( evt ) {
		return DomEventHelper.isContentChangingKey(evt);
	}

	static syncZeroWidthClass( element, width ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		if ( !Validator.isValidNumber( width ) || width > 0 ) {
			element.classList.remove( "zero-width" );
			return true;
		}
		element.classList.add( "zero-width" );
		return true;
	}

}

console.log( 'widgets/xtw/util/XtwUtils.js loaded.' );
