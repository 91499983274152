import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import MnuMgr from '../../gui/menu/MnuMgr';
import MnuObj from '../../gui/menu/MnuObj';

import EventListenerManager from '../../utils/EventListenerManager';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import HtmHelper from '../../utils/HtmHelper';
import SelectionManager from './impl/selection/SelectionManager';
import Validator from '../../utils/Validator';
import ViewToggleButton from './parts/ViewToggleButton';
import Warner from '../../utils/Warner';
import XRowItem from './parts/XRowItem';
import XtwBodyEditingExtension from './impl/editing/XtwBodyEditingExtension';
import XtwBodyFocusExtension from './impl/selection/XtwBodyFocusExtension';
import XtwBodySelectionExtension from './impl/selection/XtwBodySelectionExtension';
import XtwBodyCallbackExtension from './impl/body/XtwBodyCallbackExtension';
import XtwBodyRowHeightAdjustmentExtension from './impl/rowheight/XtwBodyRowHeightAdjustmentExtension';
import XtwBodyScrollingExtension from './impl/scrolling/XtwBodyScrollingExtension';
import XtwBodyContextMenuExtension from './impl/contextmenu/XtwBodyContextMenuExtension';
import XtwMgr from './util/XtwMgr';
import XtwHead from './XtwHead';
import XtwModel from './model/XtwModel';
import XtwMnuItm from './parts/sort/XtwMnuItm';
import XtwRtpIdManager from './rtp/XtwRtpIdManager';
import XtwSortFlap from './parts/sort/XtwSortFlap';
import XtwUtils from './util/XtwUtils';
import MRowItem from './model/MRowItem';
import MGroup from './model/MGroup';
import FocusHolder from '../../gui/FocusHolder';

let PISASALES = void 0;
let ITEM_MANAGER = void 0;

// set to false to disable cell editing or set to true to enable cell editing
export const CELL_EDITING_ENABLED = true;

const DEF_HDR_HGT = 22;
const DEF_ROW_HGT = 22;
const TMO_FETCH = 100;

const ROW_TEMPLATE_BODY_CLASS = "row-template";
const TABLE_BODY_CLASS = "table";

const ROW_HOVERED_BACKGROUND_COLOR = "--rtp-background-hovered";
const ROW_HOVERED_TEXT_COLOR = "--rtp-text-hovered";
const ROW_SELECTED_BACKGROUND_COLOR = "--rtp-background-selected";
const ROW_SELECTED_TEXT_COLOR = "--rtp-text-selected";

const DO_LOG = false;
const DO_TRACE = DO_LOG && false;

/**
 * class XtwBody - the body of an eXtended Table Widget (XTW)
 */
export default class XtwBody {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor( properties ) {
		if ( !Validator.isObject( PISASALES ) ) {
			PISASALES = PSA.getInst();
		}
		if ( !Validator.isObject( ITEM_MANAGER ) ) {
			ITEM_MANAGER = ItmMgr.getInst();
		}
		// pisasales.bindAll( this, [ "layout", "onReady", "onRender" ] );
		PISASALES.bindAll( this, [ "layout", "onReady", "onRender" ] );
		new XtwBodySelectionExtension( this );
		new XtwBodyFocusExtension( this );
		new XtwBodyEditingExtension( this );
		new XtwBodyCallbackExtension( this );
		new XtwBodyRowHeightAdjustmentExtension( this );
		new XtwBodyScrollingExtension( this );
		new XtwBodyContextMenuExtension( this );
		this._alive = true;
		this.xtwHead = null;
		this.ready = false;
		this.visWdt = 0;
		this.visHgt = 0;
		this.grpHgt = 0;
		this.topIdx = -1;
		this.fetchPnd = false;
		this.fetchRqu = null;
		this.fetchCph = null;
		this.fetchID = 0;
		this.clrVtg = null;
		this.clrHzg = null;
		this.focRow = null;
		this.focusHolder = null;
		this.noSortCols = new Set();
		// this.selClk = pisasales.creJsPoint( 0, 0 );
		this.selClk = PISASALES.creJsPoint( 0, 0 );
		// global DEBUG flag
		// const dbg = pisasales.isDbgMode();
		const dbg = PISASALES.isDbgMode();
		// get the RAP parent element
		const idw = properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject( idw );

		// create our "own" DOM element
		const body = document.createElement( 'div' );
		body.className = 'xtwbody';
		if ( dbg && Validator.is( body.dataset, "DOMStringMap" ) ) {
			const className = Validator.getClassName( this );
			if ( Validator.isString( className ) )
				body.dataset.class = className;
		}
		// create the row container
		const rowcnt = document.createElement( 'div' );
		rowcnt.className = 'xtwrowcnt';
		body.appendChild( rowcnt );
		// store elements
		this.rowContainer = rowcnt;
		this.tblBody = body;

		// add the main DOM element to the RAP parent
		this.parent.append( this.tblBody );
		// we need the resize listener
		this.parent.addListener( "Resize", this.layout );
		// activate "render" event
		rap.on( "render", this.onRender );

		// get custom widget data
		this.xtdTbl = null;
		const cwd = this.parent.getData( "pisasales.CSTPRP.CWD" ) || {};
		const grh = cwd.grh || DEF_HDR_HGT;
		const drh = cwd.drh || DEF_ROW_HGT;
		this.defGrh = grh;
		this._defRwh = drh;
		this._rtpRwh = drh;
		this.rtpVPad = 8; // must match the total vertical padding as defined for CSS class "div.xtwrtprowitem"
		// ID string
		this.wdgIdStr = cwd.idstr || '';
		if ( body.dataset ) {
			body.dataset.idstr = this.wdgIdStr;
		}
		// read flap settings provided by custom widget data
		this.flapSettings = {};
		this._setFlapSettings( cwd );

		// update CSS variables
		this.setCssVariables( body, cwd );

		// set selection mode
		this.setSelectionMode( cwd );

		// the array of DOM items for the visible rows, actually, the array will contain instances of class XRowItem
		this.rowItems = [];
		// create model instance
		// this.model = pisasales.creXtwModel( this, grh, drh );
		Validator.setupInfiniteSetter( {
			value: new XtwModel( this, grh, drh ),
			instance: this,
			propertyName: "model",
			callback: ( value ) => {
				const isValid = value instanceof XtwModel;
				Warner.traceIf( !isValid, `The table body is being assigned an` +
					` invalid model value.` );
				return isValid;
			},
			stopSetterBasedOnCallbackResult: true
		} );
		// this.model = new XtwModel( this, grh, drh );
		this.pndModel = null;

		// row template mode
		this.rtpMode = false;
		this.syncClassWithState();
		this.rtpTgl = false;
		this.idManager = null;

		// we need the ID of our parent eXtended Table Widget
		const idp = cwd.idp || '';
		if ( idp ) {
			// const xtw = pisasales.getXtwMgr().getXtdTbl( idp );
			const xtw = XtwMgr.getInst().getXtdTbl( idp );
			if ( xtw ) {
				this.xtdTbl = xtw;
				xtw.setBodyWdg( this );
			}
		}
		// remaining initialization
		this.sortFlap = null;
		this._init( cwd.fnt || null );
	}

	/**
	 * @returns {Boolean} true if the widget is alive; false otherwise
	 */
	get alive() {
		return this._alive;
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when
	 * this item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	get isRowTpl() {
		if ( !Validator.isObjectPath( this, "this.xtdTbl.rowTpl" ) ) {
			return false;
		}
		return Validator.isBoolean( this.rtpMode ) && this.rtpMode;
	}

	get getRowTpl() {
		return this.isRowTpl ? this.xtdTbl.rowTpl : null;
	}

	get element() {
		return this.tblBody;
	}

	get clientRect() {
		if ( !this.isRendered ) {
			return void 0;
		}
		return this.element.getBoundingClientRect();
	}

	get headerHeightAdjusted() {
		return Validator.isObject( this.xtwHead ) &&
			this.xtwHead.headerHeightAdjusted;
	}

	setTableBodyHeight( heightInPixels, setByHeader = false ) {
		if ( this.isRowTpl || !Validator.isPositiveNumber( heightInPixels ) ) {
			return false;
		}
		[ this.tblBody, this.tblBody.parentElement, this.rowContainer ]
		.forEach( element => {
			if ( !( element instanceof HTMLElement ) ) return;
			HtmHelper.removeStyleProperty( element, "height" );
		} );
		if ( !setByHeader && this.headerHeightAdjusted &&
			Validator.isPositiveNumber( this.visHgt ) && this.visHgt < heightInPixels ) {
			// TODO warn
			return true;
		}
		this.visHgt = heightInPixels;
		this.bodyClientHeight = heightInPixels;
		return true;
	}

	set bodyClientHeight( heightInPixels ) {
		if ( Validator.isObject( this.xtdTbl ) &&
			"bodyClientHeight" in this.xtdTbl ) {
			this.xtdTbl.bodyClientHeight = heightInPixels;
		}
	}

	get bodyClientHeight() {
		const bodyClientRect = this.clientRect;
		if ( !( bodyClientRect instanceof DOMRect ) ) {
			return void 0;
		}
		return bodyClientRect.height;
	}

	get rtpRowHeight() {
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( idManager.rowHeight ) ?
			idManager.rowHeight : void 0;
	}

	get rtpRwh() {
		if ( !this.isRowTpl ) {
			return this._rtpRwh;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ?
			rtpRowHeight : this._rtpRwh;
	}

	set rtpRwh( newValue ) {
		this._rtpRwh = newValue;
	}

	get defRwh() {
		if ( !this.isRowTpl ) {
			return this._defRwh;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ?
			rtpRowHeight : this._defRwh;
	}

	set defRwh( newValue ) {
		this._defRwh = newValue;
	}

	toggleClass() {
		if ( !this.isRendered ) {
			return false;
		}
		if ( this.element.classList.contains( TABLE_BODY_CLASS ) ) {
			return this.changeToRowTemplateClass();
		}
		return this.changeToTableClass();
	}

	syncClassWithState() {
		if ( !this.isRendered ) {
			return false;
		}
		if ( this.isRowTpl ) {
			return this.changeToRowTemplateClass();
		}
		return this.changeToTableClass();
	}

	changeToRowTemplateClass() {
		if ( Validator.isFunctionPath( this.xtdTbl,
				"xtdTbl.changeToRowTemplateClass" ) ) {
			this.xtdTbl.changeToRowTemplateClass();
		}
		if ( !this.isRendered ) {
			return false;
		}
		const bodyElement = this.element;
		bodyElement.classList.remove( TABLE_BODY_CLASS );
		bodyElement.classList.add( ROW_TEMPLATE_BODY_CLASS );
		if ( bodyElement.parentElement instanceof HTMLElement ) {
			bodyElement.parentElement.classList.remove( TABLE_BODY_CLASS );
			bodyElement.parentElement.classList.add( ROW_TEMPLATE_BODY_CLASS );
		}
		return true;
	}

	changeToTableClass() {
		if ( Validator.isFunctionPath( this.xtdTbl, "xtdTbl.changeToTableClass" ) ) {
			this.xtdTbl.changeToTableClass();
		}
		if ( !this.isRendered ) {
			return false;
		}
		const bodyElement = this.element;
		bodyElement.classList.remove( ROW_TEMPLATE_BODY_CLASS );
		bodyElement.classList.add( TABLE_BODY_CLASS );
		if ( bodyElement.parentElement instanceof HTMLElement ) {
			bodyElement.parentElement.classList.remove( ROW_TEMPLATE_BODY_CLASS );
			bodyElement.parentElement.classList.add( TABLE_BODY_CLASS );
		}
		return true;
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		this._alive = false;
		if ( this.sortFlap ) {
			const sf = this.sortFlap;
			delete this.sortFlap;
			sf.destroy();
		}
		this.removeContextMenuListener();
		delete this.flapSettings;
		this.rowItems.forEach( ( ri ) => ri.destroy() );
		this.model.destroy();
		delete this.rcells;
		delete this.sortedColumns;
		delete this.idManager;
		delete this.clrHzg;
		delete this.clrVtg;
		delete this.focRow;
		delete this.clrRowHvrBgc;
		delete this.clrRowHvrTxc;
		delete this.clrRowSelBgc;
		delete this.clrRowSelTxc;
		delete this.clrRowSelBgcNfc;
		delete this.clrRowSelTxcNfc;
		delete this.xtwHead;
		delete this.xtdTbl;
		delete this.ready;
		delete this.selClk;
		delete this.rowContainer;
		delete this.tblBody;
		delete this.model;
	}

	/**
	 * returns the table body widget
	 * @returns {XtwBody} this
	 */
	getXtwBody() {
		return this;
	}

	/**
	 * returns the table header widget
	 * @returns {XtwHead} the tbale header widget
	 */
	getXtwHead() {
		return this.xtwHead;
	}

	getHeight() {
		return this.grpHgt;
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		Warner.traceIf( DO_LOG );
		this.ready = true;
		if ( this.pndModel ) {
			const args = this.pndModel;
			this.modelCommitted( args );
		}
		this._updateSortFlap( this.isRowTpl );
	}

	/**
	 * called by the framework in rendering phase
	 */
	onRender() {
		Warner.traceIf( DO_LOG );
		if ( this.parent ) {
			rap.off( "render", this.onRender ); // just once!
			this.onReady();
			this.layout();
			this._attachEventHandlers();
			this.ensureSelectionUpdate();
		}
	}

	/**
	 * updates internal selection state and notifies the web server about possible selection changes
	 */
	ensureSelectionUpdate() {
		if ( this.selectionManager ) {
			this.adjustSelectionAfterInitialisation();
			this.selectionManager.informAboutRowSelection( -1, true );
		}
	}

	/**
	 * updates CSS properties as provided in custom widget data
	 * @param {HTMLElement} bodyElement table bodie's main HTML element
	 * @param {*} cwd custom widget data
	 */
	setCssVariables( bodyElement, cwd ) {
		if ( !Validator.isObject( cwd ) || !( bodyElement instanceof HTMLElement ) ) {
			return false;
		}
		this.clrRowHvrBgc = { value: cwd.hvrbgc, property: ROW_HOVERED_BACKGROUND_COLOR };
		this.clrRowHvrTxc = { value: cwd.hvrtxc, property: ROW_HOVERED_TEXT_COLOR };
		this.clrRowSelBgc = { value: cwd.selbgc, property: ROW_SELECTED_BACKGROUND_COLOR };
		this.clrRowSelTxc = { value: cwd.seltxc, property: ROW_SELECTED_TEXT_COLOR };
		this.clrRowSelBgcNfc = { value: cwd.selbgcnfc, property: ROW_SELECTED_BACKGROUND_COLOR };
		this.clrRowSelTxcNfc = { value: cwd.seltxcnfc, property: ROW_SELECTED_TEXT_COLOR };

		[ this.clrRowHvrBgc, this.clrRowHvrTxc, this.clrRowSelBgc, this.clrRowSelTxc ].forEach( option => {
			if ( !Validator.isArray( option.value, 4 ) ||
				!Validator.isString( option.property ) ) {
				return;
			}
			bodyElement.style.setProperty( option.property, `rgba(${ option.value[0] },` +
				`${ option.value[1] },${ option.value[2] },${ option.value[3] })` );

		} );
		return true;
	}

	/**
	 * sets the current focus holder
	 * @param {FocusHolder} fh the current focus holder
	 */
	setFocusHolder(fh) {
		this.focusHolder = fh instanceof FocusHolder ? fh : null;
	}

	/**
	 * removes the focus holder
	 * @param {FocusHolder} fh the focus holder to be removed
	 */
	removeFocusHolder(fh) {
		if ( (fh instanceof FocusHolder) && (this.focusHolder === fh) ) {
			this.focusHolder = null;
		}
	}

	/**
	 * called by the parent table widget to notify about the RAP focus state
	 * @param {Boolean} rf "RAP focus" flag
	 */
	setRapFocus( rf ) {
		if ( this.tblBody ) {
			const body = this.tblBody;
			const prop_bgc = rf ? this.clrRowSelBgc : this.clrRowSelBgcNfc;
			const prop_txc = rf ? this.clrRowSelTxc : this.clrRowSelTxcNfc;
			[ prop_bgc, prop_txc ].forEach( prop => {
				if ( Validator.isString( prop.property ) && Validator.isArray( prop.value, 4 ) ) {
					// body.style.setProperty( prop.property, pisasales.UIUtil.getCssRgb( prop.value ) );
					body.style.setProperty( prop.property, PISASALES.UIUtil.getCssRgb( prop.value ) );
				}
			} );
		}
		if ( rf ) {
			this.makeSureARowIsFocused();
		}
	}

	setSelectionMode( widgetData ) {
		if ( !Validator.isObject( widgetData ) ||
			!Validator.isInteger( widgetData.selMode ) ) {
			return false;
		}
		Object.defineProperty( this, "selectionMode", {
			value: widgetData.selMode,
			configurable: false
		} );
		delete this.setSelectionMode;
		return true;
	}

	get noSelectionAllowed() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!( "noSelectionAllowed" in selectionManager ) ) {
			return void 0;
		}
		return selectionManager.noSelectionAllowed;
	}

	addContextMenuListener() {
		const successfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onNoMansLandContextMenu",
			element: this.rowContainer,
			useCapture: false
		} );
		return successfullyAdded;
	}

	removeContextMenuListener() {
		const successfullyRemoved = EventListenerManager
			.removeListener( this, "contextmenu", this.rowContainer );
		return successfullyRemoved;
	}

	addToggleButton() {
		if ( Validator.is( this.toggleButton, "ViewToggleButton" ) ) {
			return;
		}
		this.toggleButton = new ViewToggleButton( this );
	}

	renderToggleButton() {
		if ( !Validator.is( this.toggleButton, "ViewToggleButton" ) ) {
			return;
		}
		this.toggleButton.render();
		if ( this.isRowTpl ) {
			this.toggleButton.changeToListIcon();
		} else {
			this.toggleButton.changeToTableIcon();
		}
	}

	destroyToggleButton() {
		if ( !Validator.is( this.toggleButton, "ViewToggleButton" ) ) {
			return;
		}
		this.toggleButton.destroy();
		this.toggleButton = void 0;
		delete this.toggleButton;
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		this._basicLayout();
		return this.syncClassWithState();
	}

	/**
	 * sets the table header widget
	 * @param {XtwHead} xth the table header widget
	 */
	setTblHead( xth ) {
		this.xtwHead = xth;
	}

	/**
	 * sets the widths of fixed and dynamic parts
	 * @param {Number} fxw width of fixed part
	 * @param {Number} dnw width of dynamic part
	 */
	setPartWdt( fxw, dnw ) {
		// TODO
	}

	/**
	 * sets the height of row template rows
	 * @param {Number} hgt new height of row template rows in pixels
	 */
	setRtpRwh( hgt ) {
		if ( typeof hgt === 'number' ) {
			this.rtpRwh = hgt;
		}
	}

	/**
	 * sets new overall vertical padding for row template rows
	 * @param {Number} vpad new overall vertical padding in pixels for row template rows
	 */
	setRtpVPad( vpad ) {
		if ( typeof vpad === 'number' ) {
			this.rtpVPad = vpad;
		}
	}

	setCssVariable( cssVariableName, cssVariableValue ) {
		if ( !Validator.isObject( this.xtdTbl ) ||
			!Validator.isFunction( this.xtdTbl.setCssVariable ) ) {
			return false;
		}
		return this.xtdTbl.setCssVariable( cssVariableName, cssVariableValue );
	}

	/**
	 * sets the rows to be updated
	 * @param {Array<Number>} rows an array providing the IDs of the rows to be updated
	 */
	setUpdRows( rows ) {
		Warner.traceIf( DO_LOG );
		this.model.modelUpdate( rows );
		this._updAllRowItems();
		this._nfySrv( 'rowsUpdated', { cnt: rows.length }, false );
	}

	/**
	 * rebuilds all row items due to changes in the table (columns etc.)
	 */
	rebuildAllRows() {
		const xth = this.xtwHead;
		const self = this;
		if ( xth ) {
			self.doWithFrozenFocus( () => {
				self.rowItems.forEach( ( ri ) => ri.rebuild( xth ) );
			} );
		}
	}

	/**
	 * initializes the view mode; has no effect, if no row template is specified
	 * @param {Boolean} rtp flag, whether row template is initially visible
	 * @param {Boolean} tgl flag, whether the view mode can be toggled
	 */
	iniViewMode( rtp, tgl ) {
		Warner.traceIf( DO_LOG );
		if ( tgl ) {
			this.addToggleButton();
		} else {
			this.destroyToggleButton();
		}
		this.syncClassWithState();
		if ( this.rtpMode !== rtp ) {
			// we must re-create ther UI
			this.rtpMode = rtp;
			this.syncClassWithState();
			if ( this.ready ) {
				if ( this.idManager ) {
					delete this.idManager;
					delete this.rcells;
					delete this.sortedColumns;
				}
				this.idManager = null;
				const eff_rtp = this.isRowTpl;
				const rh = eff_rtp ? this.rtpRwh : this.defRwh;
				const vp = eff_rtp ? this.rtpVPad : 0;
				const hgt = this.model.viewModeChanged( eff_rtp, rh, vp );
				this._rmvAllDomElm();
				this._updDomElm( this.visHgt );
				this._nfySrv( "toggleDisplay", {
					idr: 0,
					rel: false,
					height: this._getEffScrHeight( hgt ),
					mode: eff_rtp
				}, false );
				// trigger data update if required
				const vps = this.vscPos;
				this.rquVsc = this.vscPos = -1;
				this.topIdx = -1;
				this._scrTriggerUpd( this.hscPos, vps );
				// update sort flap
				this._updateSortFlap( eff_rtp );
			}
		}
		this.ensureAutoFitOnTableColumns();
	}

	ensureAutoFitOnTableColumns() {
		if ( !Validator.isObject( this.xtdTbl ) ) {
			return false;
		}
		return this.xtdTbl.autoFitColumns();
	}

	/**
	 * called by the web server if the data model has been committed
	 * @param {Object} args new data model
	 */
	modelCommitted( args ) {
		Warner.traceIf( DO_LOG );
		if ( !this.alive ) {
			return;
		}
		if ( !this.ready ) {
			this.pndModel = args;
			return;
		}
		if ( this.pndModel ) {
			delete this.pndModel;
			this.pndModel = null;
		}
		const sort = args.sort || {};
		const sort_idc = sort.idc || -1;
		const sort_dir = !!sort.direction;
		if ( this.sortFlap ) {
			this.sortFlap.setSortColumn( sort_idc, sort_dir, false )
		} else if ( this.xtwHead ) {
			this.xtwHead.setSortColumn( sort_idc, sort_dir, false );
		}
		const rtp = this.isRowTpl;
		const rh = rtp ? this.rtpRwh : this.defRwh;
		const vp = rtp ? this.rtpVPad : 0;
		const hgt = this.model.modelCommitted( args, rtp, rh, vp );
		const cnt = this.model.getItemCount();
		// drop any possibly pending fetch request
		this._fetchDropRqu();
		// update all row items
		this.topIdx = -1;
		this.rquHsc = this.hscPos = -1;
		this.rquVsc = this.vscPos = -1;
		// notify web server
		if ( DO_TRACE ) {
			console.log( `XTW ${this.wdgId} - model complete: `, args );
		} else if ( DO_LOG ) {
			const items = args.model || [];
			console.log( `XTW ${this.wdgId} - model complete: got ${items.length} model items.` );
		}
		this._nfySrv( 'modelComplete', {
			height: this._getEffScrHeight( hgt ),
			count: cnt
		}, false );
		if ( this.selectionManager ) {
			const focusRow = ( typeof args.focusRow === 'number' ) ? args.focusRow : null;
			if ( focusRow !== null ) {
				this.selectionManager.focusRowById( focusRow, false );
			}
			this.ensureSelectionUpdate();
		}
	}

	/**
	 * forces an UI update
	 * @param {Number} sx horizontal scroll position
	 * @param {Number} sy vertical scroll position
	 */
	_forceUIRefresh( sx, sy ) {
		this.topIdx = -1;
		this.rquHsc = this.hscPos = -1;
		this.rquVsc = this.vscPos = -1;
		this._fetchDropRqu();
		this._scrTriggerUpd( sx, sy );
	}

	/**
	 * called by the web server to force a full UI refresh
	 */
	forceUIRefresh() {
		this.doWithFrozenFocus( () => {
			this.doForceUIRefresh();
		} );
	}

	doForceUIRefresh() {
		const hgt = this.visHgt;
		const sx = Math.max( this.hscPos, 0 );
		const sy = Math.max( this.vscPos, 0 );
		if ( DO_TRACE ) {
			console.log( `XTW ${this.wdgId} - forced UI refresh: height=${hgt}px - hsc=${sx}, vsc=${sy}.` );
		}
		this._updDomElm( hgt );
		const self = this;
		window.requestAnimationFrame( () => {
			self._forceUIRefresh( sx, sy );
		} );
	}

	/**
	 * called by the web server in response to a fetch request
	 * @param {Object} args new data
	 */
	modelData( args ) {
		const focusRow = Validator.isObject( args ) ? args.focusRow : null;
		const result = this.doWithoutLosingSelectionOnSingleSelect( () => {
			const success = this._modelData( args );
			if ( this.hasDomFocus && this.makeSureARowIsFocused() &&
				Validator.isObject( this.selectionManager ) ) {
				this.selectionManager.informAboutRowSelection( -1, true );
			}
			return success;
		} );
		if ( !Validator.isValidNumber( focusRow ) ) {
			return result;
		}
		if ( Validator.isObjectPath( this.selectionManager, "selectionManager.focusRow" ) &&
			this.selectionManager.focusRow.edited ) {
			return result;
		}
		// TODO: WE SHOULD FIND ANOTHER WAY because focusing something after model
		// data would make all focus-related callbacks obsolete
		// (everything focused by _doEnsuingModelData and _doAfterModelData will
		// lose its focus)
		this.selectionManager.focusRowById( focusRow, true );
		return result;
	}

	_modelData( args ) {
		Warner.traceIf( DO_LOG );
		if ( !this.alive ) {
			return false;
		}
		const id = args.id || 0;
		const is_last = this.fetchID === id;
		if ( this.model ) {
			try {
				// let the model process this - we've got, what we've got :-)
				if ( DO_TRACE ) {
					console.log( `XTW ${this.wdgId} - model data: `, args );
				}
				const items = args.items || [];
				if ( DO_TRACE ) {
					const cnt = items.length;
					console.log( `XTW ${this.wdgId} - model data: got ${cnt} rows; last="${is_last}".` );
				}
				this.model.modelData( items );
			} finally {
				if ( is_last ) {
					const cf = this.fetchCph;
					this.fetchCph = null;
					if ( cf ) {
						cf();
					}
				}
			}
		}
		if ( !is_last ) {
			return Warner._traceIf( `XtwBody#modelData ${this.wdgId} - processed older request "${ id }"`, DO_LOG );
		}
		Warner._traceIf( `XtwBody#modelData ${this.wdgId} - processed last request "${ id }".`, DO_LOG );
		this.fetchID = 0;
		this.setCssPyjamaLines();
		this._doAfterModelData();
		this._doEnsuingModelData();
		return true;
	}

	/**
	 * called be the header widget if one or more columns were changed (new titles etc.)
	 */
	onColumnChanged() {
		if ( this.sortFlap ) {
			this.sortFlap.updateFieldMenu();
		}
	}

	/**
	 * called after the user has changed a column width by dragging the column
	 * border in the table/excel view/display
	 * @param {XtwCol} column the affected column
	 * @param {Number} newWidth new column width
	 * @param {Number} widthDifference the difference of the width
	 * @return {Boolean} whether or not the process was carried out successfully
	 * (as requested/intended)
	 */
	onColumnWidth( column, newWidth, widthDifference ) {
		if ( this.isRowTpl || !this.isRendered ) {
			return false;
		}
		if ( !Validator.is( column, "XtwCol" ) ||
			!Validator.isPositiveNumber( newWidth ) ||
			!Validator.isValidNumber( widthDifference, false ) ) {
			return false;
		}
		const success = this.applyToAllValidRows( xRowItem => {
			if ( !Validator.isFunction( xRowItem.onColumnWidth ) ) {
				return false;
			}
			return xRowItem.onColumnWidth( column, newWidth, widthDifference ) != false;
		} );
		return success;
	}

	allRowItemsHaveCell( cellId ) {
		if ( !Validator.isString( cellId ) && !Validator.isValidNumber( cellId ) ) {
			return false;
		}
		const success = this.applyToAllValidRows( xRowItem => {
			if ( !Validator.isFunctionPath( xRowItem, "xRowItem.cells.hasObj" ) ) {
				return false;
			}
			return xRowItem.cells.hasObj( cellId );
		} );
		return success;
	}

	reactToColumnVisibilityChange( xtwCol, widthDifference ) {
		if ( !Validator.isObject( xtwCol ) ||
			!Validator.isValidNumber( widthDifference ) ) {
			return false;
		}
		if ( widthDifference > 0 && !this.allRowItemsHaveCell( xtwCol.id ) ) {
			this.rebuildAllRows();
		}
		if ( Validator.isFunction( xtwCol.getWidth ) ) {
			this.onColumnWidth( xtwCol, xtwCol.getWidth(), widthDifference );
		}
		return true;
	}

	/**
	 * moves all of the cells (precisely their elements) from the first column
	 * to a position exactly before the corresponding cells in the second column
	 * @param {XtwCol} firstColumn the first column (the one whose cells should
	 * be moved and should change their places)
	 * @param {XtwCol} secondColumn the second column (the one whose cells keep
	 * their place)
	 * @return {Boolean} true if the movement was successfull, false otherwise
	 */
	moveFirstColumnBeforeSecond( firstColumn, secondColumn ) {
		if ( [ firstColumn, secondColumn ].some( column => (
				!Validator.is( column, "XtwCol" ) ||
				!Validator.isPositiveInteger( column.id )
			) ) || firstColumn === secondColumn ) {
			return false;
		}
		const firstColumnId = firstColumn.id;
		const secondColumnId = secondColumn.id;
		let fixedWidthDifference = 0;
		let dynamicWidthDifference = 0;
		let ignoreFixedFlag = false;
		if ( firstColumn.fix === secondColumn.fix ) {
			ignoreFixedFlag = true;
		} else if ( firstColumn.fix ) {
			fixedWidthDifference -= firstColumn.width;
			dynamicWidthDifference += firstColumn.width;
		} else {
			fixedWidthDifference += firstColumn.width;
			dynamicWidthDifference -= firstColumn.width;
		}
		const success = this.applyToAllValidRows( xRowItem => {
			if ( !Validator.isFunction( xRowItem.moveFirstCellBeforeSecond ) ) {
				return false;
			}
			return xRowItem.moveFirstCellBeforeSecond( {
				firstCellId: firstColumnId,
				secondCellId: secondColumnId,
				fixedWidthDifference: fixedWidthDifference,
				dynamicWidthDifference: dynamicWidthDifference,
				ignoreFixedFlag: ignoreFixedFlag
			} ) != false;
		} );
		return success;
	}

	/**
	 * performs a callback function on every row item (XRowItem) that is
	 * "attached" to this item; also previously validates the row item if not
	 * differently specified/requested
	 * @param {Function} callbackFunction the callback function
	 * @param {Boolean} validate wheter or not every row item should be
	 * validated before appliyng the callback function with the row item as
	 * parameter
	 * @return {Boolean} whether or not the process was carried out successfully
	 * (as requested/intended)
	 */
	applyToAllValidRows( callbackFunction, validate = true ) {
		if ( !Validator.isFunction( callbackFunction ) ) {
			return false;
		}
		const xRowItems = this.rowItems;
		if ( !Validator.isIterable( xRowItems ) ) {
			return false;
		}
		let atLeastOneRowProcessed = false;
		for ( let xRowItem of [ ...xRowItems ] ) {
			if ( !!validate && !Validator.is( xRowItem, "XRowItem" ) ) {
				continue;
			}
			const callBackResult = callbackFunction( xRowItem );
			if ( callBackResult === false ) {
				continue;
			}
			atLeastOneRowProcessed = true;
		}
		return atLeastOneRowProcessed;
	}

	onNoMansLandContextMenu( domEvent ) {
		if ( Validator.isObject( domEvent ) && Validator.isString( domEvent.inputId ) ) {
			return;
		}
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const parameters = XtwUtils
			.getCoordinateParameters( domEvent, this.rowContainer );
		this._nfySrv( "contextMenu", parameters );
	}

	/**
	 * @returns {Number} the full width, required to show a data row without scrolling
	 */
	_getFullWidth() {
		let fxw = 0;
		let dnw = 0;
		const lim = this.rowItems.length;
		for ( let i = 0;
			( ( fxw === 0 ) || ( dnw === 0 ) ) && ( i < lim ); ++i ) {
			const ri = this.rowItems[ i ];
			fxw = Math.max( fxw, ri.getWdtFix() );
			dnw = Math.max( dnw, ri.getWdtDyn() );
		}
		return ( fxw > 0 ) && ( dnw > 0 ) ? ( fxw + dnw ) : 0;
	}

	/**
	 * called if a group is expanded or collapsed
	 * @param {MGroup} the group model item; may be null
	 */
	onGroupExpanded( mi ) {
		// this is similar to modeCommitted()
		const rtp = this.isRowTpl;
		const rh = rtp ? this.rtpRwh : this.defRwh;
		const vp = rtp ? this.rtpVPad : 0;
		// let the model update itself
		const hgt = this.model.modelGroupExpanded( rtp, rh, vp );
		const cnt = this.model.getItemCount();

		// update all row items - but keep scrolling position
		const vps = this.vscPos;
		this.topIdx = -1;
		this.rquHsc = this.hscPos = -1;
		this.rquVsc = this.vscPos = -1;
		this._scrTriggerUpd( 0, vps );

		if ( mi && mi.isGroupHead() && !mi.isDefault() ) {
			// send additional notification
			this._nfySrv( 'groupExpanded', { dsc: mi.getGrpDsc(), collapsed: mi.isCollapsed() }, false );
		}
		// notify web server
		this._nfySrv( 'modelComplete', { height: this._getEffScrHeight( hgt ), count: cnt }, false );
		this.setCssPyjamaLines();
	}

	setCssPyjamaLines() {
		if ( this.isRowTpl || !this.isRendered ) {
			return false;
		}
		if ( this.element.classList.contains( "row-template" ) ||
			!this.element.classList.contains( "table" ) ) {
			return false;
		}
		const rowContainerElement = this.rowContainer instanceof HTMLElement ?
			this.rowContainer : this.element.childElementCount <= 0 ?
			void 0 : this.element.children[ 0 ];
		if ( !( rowContainerElement instanceof HTMLElement ) ||
			!rowContainerElement.classList.contains( "xtwrowcnt" ) ||
			!Validator.isIterable( rowContainerElement.children ) ) {
			return false;
		}
		const rows = [ ...rowContainerElement.children ];
		let stripeCounter = 1;
		for ( let row of rows ) {
			row.classList.remove( "pyjama-1" );
			row.classList.remove( "pyjama-2" );
			if ( row.classList.contains( "group-header" ) ) {
				stripeCounter = 2;
				continue;
			}
			if ( !row.classList.contains( "xtwrowitem" ) ) {
				continue;
			}
			row.classList.add( `pyjama-${ stripeCounter }` );
			stripeCounter = stripeCounter === 1 ? 2 : 1;
		}
		return true;
	}

	removeEmptyRowsFromRowContainer() {
		if ( !this.isRendered ) {
			return false;
		}
		const rowContainerElement = this.rowContainer instanceof HTMLElement ?
			this.rowContainer : this.element.childElementCount <= 0 ?
			void 0 : this.element.children[ 0 ];
		if ( !( rowContainerElement instanceof HTMLElement ) ||
			!rowContainerElement.classList.contains( "xtwrowcnt" ) ||
			!Validator.isIterable( rowContainerElement.children ) ) {
			return false;
		}
		const visibleRows = [ ...rowContainerElement.children ];
		if ( !( this.emptyRowsInvisibleContainer instanceof HTMLElement ) ) {
			this.emptyRowsInvisibleContainer = window.document.createElement( "div" );
		}
		const invisibleRows = [ ...this.emptyRowsInvisibleContainer.children ];
		for ( let invisibleRow of invisibleRows ) {
			const rowChildren = HtmHelper.getAllLevelChildren( invisibleRow );
			if ( rowChildren.length <= 0 ) {
				continue;
			}
			rowContainerElement.appendChild( invisibleRow );
		}
		for ( let visibleRow of visibleRows ) {
			const rowChildren = HtmHelper.getAllLevelChildren( visibleRow );
			if ( rowChildren.length > 0 ) {
				continue;
			}
			this.emptyRowsInvisibleContainer.appendChild( visibleRow );
		}
		return true;
	}

	/**
	 * called if the user clicks the "select" column header element
	 */
	onSelClick() {}

	/**
	 * called if the user has clicked a cell with a hyperlink
	 * @param {Number} idc column ID
	 * @param {Number} idr row ID
	 */
	onHyperlink( idc, idr ) {
		const par = {};
		par.idc = idc || 0;
		par.idr = idr || 0;
		this._nfySrv( 'linkClicked', par, true );
	}

	/**
	 * one time initialization
	 * @param {Object} fnt the default body font
	 */
	_init( fnt ) {
		Warner.traceIf( DO_LOG );
		const elm = this.tblBody;
		if ( fnt ) {
			// pisasales.getItmMgr().setFnt( elm, fnt );
			ITEM_MANAGER.setFnt( elm, fnt );
		}
		let vgc = null;
		let hgc = null;
		if ( this.xtdTbl ) {
			vgc = this.xtdTbl.clrVtg || null;
			hgc = this.xtdTbl.clrHzg || null;
		}
		this.clrVtg = vgc;
		this.clrHzg = hgc;
	}

	/**
	 * adjusts the basic layout
	 */
	_basicLayout() {
		if ( !this.ready ) {
			return;
		}
		Warner.traceIf( DO_LOG );
		const area = this.parent.getClientArea();
		const wdt = area[ 2 ] || 0;
		const hgt = area[ 3 ] || 0;
		const ohg = this.visHgt;
		this.tblBody.style.left = '0';
		this.tblBody.style.top = '0';
		this.tblBody.style.width = wdt + 'px';
		this.rowContainer.style.width = wdt + 'px';
		if ( this.isRowTpl ) {
			this.tblBody.style.height = hgt + 'px';
			this.rowContainer.style.height = hgt + 'px';
			this.visHgt = hgt;
		} else {
			this.setTableBodyHeight( hgt );
		}
		this.visWdt = wdt;
		if ( this.tblBody.parentElement instanceof HTMLElement ) {
			HtmHelper.removeStyleProperty( this.tblBody.parentElement, "top" );
		}
		this._updDomElm( hgt );
		this.renderToggleButton();
		if ( DO_TRACE && ( hgt > ohg ) ) {
			console.log( `XTW ${this.wdgId} - height increased from ${ohg}px to ${hgt}px.` );
		}
	}

	/**
	 * attaches special event handlers
	 */
	_attachEventHandlers() {
		const parentElement = this.tblBody.parentElement;
		if ( parentElement instanceof HTMLElement ) {
			new ExternalEventsTransferManager( this, parentElement );
			parentElement.classList.add( "xtwbody-container" );
			parentElement.classList.remove( "rtp-body-container" );
			if ( parentElement.dataset instanceof DOMStringMap ) {
				parentElement.dataset.class = "XtwBody container";
				parentElement.dataset.idstr = this.wdgIdStr;
			}
		}
		this.addContextMenuListener();
	}

	/**
	 * updates flap settings
	 * @param {Object} cwd custom widget data
	 */
	_setFlapSettings( cwd ) {
		const fs = this.flapSettings;
		fs.canSort = !!cwd.canSort;
		fs.clrBkgNormal = cwd.flapClrBkgNormal || null;
		fs.clrTxtNormal = cwd.flapClrTxtNormal || null;
		fs.clrBkgActive = cwd.flapClrBkgActive || null;
		fs.clrTxtActive = cwd.flapClrTxtActive || null;
		fs.txtSortLabel = cwd.flapSortLabel || 'Order by:'
		fs.txtNotSorted = cwd.flapNotSortedText || '&lt;not sorted&gt;';
		const nsc = cwd.noSortCols || '';
		// if ( pisasales.isStr( nsc ) ) {
		if ( PISASALES.isStr( nsc ) ) {
			const set = this.noSortCols;
			nsc.split( ',' ).forEach( ( cn ) => {
				set.add( cn );
			} );
		}
	}

	/**
	 * updates the sort flap
	 * @param {Boolean} rtp row template flag
	 */
	_updateSortFlap( rtp ) {
		if ( rtp && this.flapSettings.canSort ) {
			if ( !this.sortFlap ) {
				// create sort flap
				const sf = new XtwSortFlap( this, this.tblBody, this.flapSettings );
				this.sortFlap = sf;
			}
		} else {
			// drop sort flap if present
			if ( this.sortFlap ) {
				const sf = this.sortFlap;
				this.sortFlap = null;
				sf.destroy();
			}
		}
	}

	/**
	 * gets/informs whether the selection manager is present or not
	 * @return <true> if the selection manager is present, <false> otherwise
	 */
	get hasSelectionManager() {
		return Validator.is( this.selectionManager, "SelectionManager" );
	}

	_getRowTpl() {
		let rtp = null;
		if ( this.xtdTbl && this.xtdTbl.hasRowTpl ) {
			rtp = this.xtdTbl.getRowTpl();
		}
		return rtp;
	}

	/**
	 * calculates the number of visible DOM items
	 * @param {Number} hgt total height in pixels
	 * @param {Boolean} rtp row template flag
	 * @returns {Number} the number of DOM items that are required to cover the whole body area
	 */
	_getDomElmCnt( hgt, rtp ) {
		const tix = Math.max( this.topIdx, 0 );
		const lim = this.model.getItemCount();
		const vp = rtp ? this.rtpVPad : 0;
		const drh = rtp ? ( this.rtpRwh + vp ) : this.defRwh;
		let cnt = 0;
		let shg = 0;
		while ( shg < hgt ) {
			const idx = tix + cnt;
			if ( idx < lim ) {
				// use exact height of model item
				const modelItem = this.model.getModelItem( tix, idx );
				if ( !rtp || modelItem.isGroupHead() ) {
					shg += modelItem.getHeight();
				} else {
					shg += drh;
				}
			} else {
				// beyond the data model - use default row height
				shg += drh;
			}
			++cnt;
		}
		if ( shg > hgt ) {
			++cnt;
		}
		return Math.max( cnt, 1 );
	}

	/**
	 * updates the visible row items so that the whole area is covered
	 * @param {Number} hgt total height in pixels
	 */
	_updDomElm( hgt ) {
		this.setTableBodyHeight( hgt );
		if ( this.rowContainer ) {
			if ( !this.hasSelectionManager ) {
				new SelectionManager( this, "XRowItem" );
			}
			if ( Validator.isFunction( this.setupFocusUI ) ) {
				this.setupFocusUI();
			}
			const rtp = this.isRowTpl;
			if ( rtp && !this.idManager ) {
				delete this.rcells;
				delete this.sortedColumns;
				const idm = new XtwRtpIdManager( this, true );
				this.idManager = idm;
				idm.initialize();
			}
			this._adjustRowsToMatchDisplay( hgt );
		}
	}

	_adjustRowsToMatchDisplay( height ) {
		if ( !Validator.isValidNumber( height ) ) {
			return false;
		}
		const desiredFinalNumberOfRowItems = this._getDomElmCnt( height, this.isRowTpl );
		if ( desiredFinalNumberOfRowItems === this.rowItems.length ) {
			Warner.traceIf( DO_LOG, `The required amount of row items to fill the` +
				` display corresponds with the amount of already existing row` +
				` items, so no further action such as removing or adding row items` +
				` will be performed.` );
			return true;
		}
		try {
			if ( desiredFinalNumberOfRowItems < this.rowItems.length ) {
				return this._removeXRowItems( desiredFinalNumberOfRowItems );
			}
			// numberOfRowItems > this.rowItems.length
			return this.doWithFrozenFocus( () => {
				return this._createXRowItems( desiredFinalNumberOfRowItems );
			} );
		} finally {
			const par = { count: desiredFinalNumberOfRowItems };
			this._nfySrv( 'visibleItems', par, false );
		}
	}

	_removeXRowItems( desiredFinalNumberOfRowItems ) {
		if ( !Validator.isValidNumber( desiredFinalNumberOfRowItems ) ) {
			return false;
		}
		while ( this.rowItems.length > desiredFinalNumberOfRowItems ) {
			const rowItem = this.rowItems.pop();
			rowItem.destroy();
		}
		return true;
	}

	_createXRowItems( numberOfRowItems ) {
		if ( !Validator.isValidNumber( numberOfRowItems ) ) {
			return false;
		}
		const rowContainer = this.rowContainer;
		if ( !Validator.isObject( rowContainer ) ) {
			return false;
		}
		const isRowTemplate = this.isRowTpl;
		const topIndex = Math.max( this.topIdx, 0 );
		const modelItemCount = this.model.getItemCount();
		const defaultRowHeight = isRowTemplate ? this.rtpRwh : this.defRwh;
		while ( this.rowItems.length < numberOfRowItems ) {
			const index = this.rowItems.length;
			const modelIndex = topIndex + index;
			const modelItem = modelIndex < modelItemCount ?
				this.model.getModelItem( topIndex, modelIndex ) : null;
			const rowHeight = modelItem && ( !isRowTemplate || modelItem.isGroupHead() ) ?
				modelItem.getHeight() : defaultRowHeight;
			const xRowItem = new XRowItem( this, index, rowHeight, isRowTemplate, this.rtpRwh );
			this.rowItems.push( xRowItem );
			rowContainer.appendChild( xRowItem.getDomElement() );
			this._updRowItm( xRowItem );
		}
		return true;
	}

	isValidModelItem( modelItem ) {
		return [ "MGroup", "MDataRow", "MRowItem" ]
			.some( className => Validator.is( modelItem, className ) );
	}

	/**
	 */
	_rmvAllDomElm() {
		while ( this.rowItems.length > 0 ) {
			const ri = this.rowItems.pop();
			ri.destroy();
		}
	}

	/**
	 * updates a row item
	 * @param {XRowItem} ri the row item to be updated
	 */
	_updRowItm( ri, updateSelectionUi = true ) {
		const tix = this.topIdx;
		const mix = tix + ri.getIdx();
		const modelItem = this.model.getModelItem( tix, mix );
		ri.setModelItem( this.xtwHead, modelItem, updateSelectionUi );
	}

	/**
	 * updates all row items
	 */
	_updAllRowItems() {
		Warner.traceIf( DO_LOG );
		const self = this;
		this._fetchData( () => {
			// update all row items
			Warner._traceIf( `XtwBody#_fetchData callback function`, DO_LOG );
			self.doWithFrozenFocus( () => {
				self.rowItems.forEach( ( ri ) => self._updRowItm( ri, false ) );
			}, true );
			self._doAfterModelData();
			this.updateSelectedRowsUi();
		} );
	}

	/**
	 * fetches data from model to be displayed in the UI; this may require a "data request" to be sent to the web server
	 * @param {Function} callback function to be called on completion
	 */
	_fetchData( cf ) {
		Warner.traceIf( DO_LOG );
		const rqu_lst = this.model.fetchData( this.topIdx, this.rowItems.length );
		if ( rqu_lst !== null ) {
			// we must send a request to the web server
			this._fetchTriggerRqu( rqu_lst, cf );
		} else {
			// immediate update possible
			cf();
		}
	}

	/**
	 * triggers a fetch request
	 * @param {Array} rqu_lst fetch request list
	 * @param {Function} callback function to be called on completion
	 */
	_fetchTriggerRqu( rqu_lst, cf ) {
		Warner.traceIf( DO_LOG );
		if ( !this.fetchRqu ) {
			const frq = {};
			frq.cf = cf;
			frq.rqus = [];
			frq.id = Date.now();
			this.fetchRqu = frq;
		}
		// add request list to the list of request lists :-)
		this.fetchRqu.rqus.push( rqu_lst );
		if ( !this.fetchPnd ) {
			this.fetchPnd = true;
			const self = this;
			window.setTimeout( () => {
				self._fetchSendRqu();
			}, TMO_FETCH );
		}
	}

	/**
	 * sends the fetch request to the web server
	 */
	_fetchSendRqu() {
		Warner.traceIf( DO_LOG );
		if ( this.fetchRqu ) {
			const frq = this.fetchRqu;
			this.fetchRqu = null;
			this.fetchPnd = false;
			// we must store the completion handler until this one is answered by the web server
			this.fetchCph = frq.cf;
			const par = {};
			par.rqus = frq.rqus;
			this.fetchID = par.id = frq.id;
			if ( DO_TRACE ) {
				console.log( `XTW ${this.wdgId} - sending fetch request: `, frq );
			} else if ( DO_LOG ) {
				const cnt = frq.rqus[ 0 ].length;
				console.log( `XTW ${this.wdgId} - sending fetch request for ${cnt} rows.` );
			}
			this._nfySrv( 'modelFetch', par, true );
		}
	}

	/**
	 * drops a pending fetch request
	 */
	_fetchDropRqu() {
		if ( this.fetchRqu ) {
			Warner._traceIf( `XtwBody#_fetchDropRqu - dropping pending fetch request.`, DO_LOG );
			if ( DO_LOG ) {
				console.log( `XTW ${this.wdgId} - dropping pending fetch request!` );
			}
			this.fetchRqu = null;
			this.fetchCph = null;
			this.fetchPnd = false;
		}
	}

	setRowSelectedFlag( parameters ) {
		if ( !Validator.isObject( parameters ) ||
			!Validator.isBoolean( parameters.select ) ) {
			return false;
		}
		return parameters.select ?
			this.selectDataRow( parameters.rowId, true ) :
			this.deselectDataRow( parameters.rowId, true );
	}

	setRowFocusedFlag( parameters ) {
		if ( !Validator.isObject( parameters ) ||
			!Validator.isBoolean( parameters.focus ) ) {
			return false;
		}
		return parameters.focus ?
			this.focusDataRow( parameters.rowId ) :
			this.unfocusDataRow( parameters.rowId );
	}

	/**
	 * clears all row selections
	 */
	clearAllSel() {
		const selectionManager = this.selectionManager;
		if ( Validator.isObject( selectionManager ) ) {
			selectionManager.deselectAllRows();
			selectionManager.deselectEveryModelItem();
		}
	}

	onDatSav( args ) {
		// this.saveLastEditedCell();
		// this.cleanLastEditedCell();
		// this.cleanFromEditables();
		this.setAllRowsToUnedited();
		this.regulateInputOnSave();
		this.fixDummyInsertionRowUI()
	}

	onDatCan( args ) {
		// this.cancelLastEditedCell();
		this.cleanLastEditedCell();
		this.cleanFromEditables();
		this.setAllRowsToUnedited();
		this.setupModelDataCallback( "onDatCan-", () => {
			return this.syncVerticalScrollingWithFirstVisibleRow();
		} );
	}

	onModalDialog( args ) {
		if ( !this.hasSelectionManager ) {
			return;
		}
		const modalDialogOpened = Validator.isObject( args ) && args.opened;
		if ( modalDialogOpened ) {
			this.selectionManager.freezeFocus();
			this.freezeKeyEvents();
			this.blockDomFocus();
			this.removeFocusFromTable();
		} else {
			this.selectionManager.reviveFocus();
			this.reviveKeyEvents();
			this.allowDomFocus();
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 * @param {Boolean} bsc flag whether to force a block screen request
	 */
	_nfySrv( code, par, bsc ) {
		Warner._traceIf( `XtwBody#_nfySrv code: "${ code }"`, DO_LOG );
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			if ( bsc ) {
				// pisasales.setBscRqu();
				PISASALES.setBscRqu();
			}
			rap.getRemoteObject( this ).notify( "PSA_XTW_BDY_NFY", param );
		}
	}

	/** register custom widget type */
	static register() {
		console.log( 'Registering custom widget XtwBody.' );
		rap.registerTypeHandler( 'psawidget.XtwBody', {
			factory: function ( properties ) {
				return new XtwBody( properties );
			},
			destructor: 'destroy',
			properties: [ 'rtpRwh', 'rtpVPad', 'updRows', 'cellEditingPermission',
				"cellEditingContent", "inputDropdownOpenState", "dummyInsertionRow"
			],
			methods: [ 'modelCommitted', 'modelData', 'setRowSelectedFlag',
				'setRowFocusedFlag', 'clearAllSel', 'forceUIRefresh', 'onDatSav',
				'onDatCan', 'onModalDialog', 'markRowsAsEdited',
				'scrollAndFocusEditableCell', 'restoreUserDefinedTableRowHeight',
				'restoreBeforeRowHeightChangeUiStats'
			],
			events: [ "PSA_XTW_BDY_NFY" ]
		} );
	}

}

console.log( "widgets/xtw/XtwBody.js loaded." );
