import PSA from '../../psa';
import Validator from '../../utils/Validator';
import ItmMgr from '../../gui/ItmMgr';
import SelBtnGrp from './SelBtnGrp';

/** CSS class for parent element */
const CSS_PARENT = 'sbtparent';
/** CSS class for button element  */
const CSS_BUTTON = 'sbtbutton'
/** CSS class for the label element */
const CSS_LABEL = 'sbtlabel';
/** CSS class for the check button */
const CSS_BTNCHECK = 'sbtbtncheck';
/** CSS class for radio button */
const CSS_BTNRADIO = 'stbbtnradio';
/** additional CSS class for focused "select buttons" */
const CSS_FOCUS = 'sbtfocus';
/** additional CSS class for disabled "select buttons" */
const CSS_BTNDISABLED = 'sbtbtndisabled';
/** additional CSS class for disabled "select buttons" */
const CSS_RADIODISABLED = 'sbtradiodisabled';
/** additional CSS class for disabled label */
const CSS_LBLDISABLED = 'stblbldisabled';
/** CSS class for the marker */
const CSS_MARKER = 'stbmarker';
/** CSS class for the checkbox marker */
const CSS_MRKCHECK= 'stbmrkcheck';
/** CSS class for the radio button marker */
const CSS_MRKRADIO = 'stbmrkradio';
/** CSS class for the "null" marker  */
const CSS_NULLMARKER = 'stbnullmarker';
/** CSS for round shaped elements */
const CSS_SQUARE = 'stbsquare';
/** CSS for round shaped elements */
const CSS_ROUND = 'stbround';
/** default custom widget data */
const DEF_CWD = { type: false, idp: '' };
/** tri-state "null" selection status */
const SEL_NULL = null;
/** "unselected" selection status */
const SEL_UNSELECTED = false;
/** "selected" selection status */
const SEL_SELECTED = true;

/** check mark HTML */
const CHECK_MARK = '<i class="far fa-check"></i>';
/** radio button marker HTML */
const RADIO_MARK = '<div class="stbround stbradiobullet"></div>';

/**
 * custom select button widget class
 */
export default class SelBtn {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this._psa.bindAll(this, [ 'layout', 'onReady', 'onRender' ]);
		this.itmMgr = ItmMgr.getInst();
		this.btnGrp = null;
		this.ready = false;
		this.enabled = true;
		this.visible = true;
		this.focus = false;
		this.button = null;
		this.marker = null;
		this.nullmarker = null;
		this.text = null;
		this.label = null;
		this.align = null;
		this.selection = SEL_UNSELECTED;
		this.readonly = false;
		this.tristate = false;
		this.linkonly = false;
		this.clrTxt = null;
		this.clrBkg = null;
		this.clrMrk = null;
		this.font = null;
		this.wdgId = properties.parent || '';
		this.parent = rap.getObject(this.wdgId);
		const elm = document.createElement('div');
		elm.className = CSS_PARENT;
		// get custom widget data
		const cwd = this.parent.getData("pisasales.CSTPRP.CWD") || DEF_CWD;
		// create inner structure according to the type (radio button vs. checkbox)
		const type = !!cwd.type;
		this.parId = cwd.idp || '';
		const btn = document.createElement('div');
		const mrk = document.createElement('div');
		const nmk = document.createElement('div');
		btn.className = CSS_BUTTON;
		mrk.className = CSS_MARKER;
		nmk.className = CSS_NULLMARKER;
		if ( type ) {
			// we're a radio button
			mrk.innerHTML = RADIO_MARK;
			mrk.classList.add(CSS_MRKRADIO);
			btn.classList.add(CSS_BTNRADIO);
			btn.classList.add(CSS_ROUND);
			nmk.classList.add(CSS_ROUND);
		} else {
			// we're a checkbox
			mrk.classList.add(CSS_MRKCHECK);
			btn.classList.add(CSS_BTNCHECK);
			btn.classList.add(CSS_SQUARE);
			mrk.innerHTML = CHECK_MARK;
		}
		mrk.style.display = 'none';
		nmk.style.display = 'none';
		btn.appendChild(mrk);
		btn.appendChild(nmk);
		elm.appendChild(btn);
		this.nullmarker = nmk;
		this.marker = mrk;
		this.button = btn;
		this.element = elm;
		this.type = type;
		// add click listener
		const self = this;
		this.element.addEventListener('click', (e) => {
			self._onClick(e);
		});
		// connect to RAP parent
		this.parent.append(this.element);
		// add resize listener
		this.parent.addListener('Resize', this.layout);
		// activate "render" event
		rap.on('render', this.onRender);
	}
	
	/**
	 * destructor method
	 */
	destroy() {
		if ( this.btnGrp ) {
			this.btnGrp.rmvBtn(this);
		}
		this.enabled = false;
		this.ready = false;
		delete this.align;
		delete this.label;
		delete this.marker;
		delete this.nullmarker;
		delete this.button;
		if ( this.element ) {
			const elm = this.element;
			if ( elm.parentElement ) {
				elm.parentElement._keyEvtHdl = null;
			}
			this._psa.rmvDomElm(elm);
		}
		delete this.element;
		delete this.visible;
		delete this.enabled;
		delete this.clrMrk;
		delete this.clrTxt;
		delete this.clrBkg;
		delete this.text;
		delete this.selection;
		delete this.ready;
		delete this.btnGrp;
		delete this.itmMgr;
		delete this.parent;
	}

	/**
	 * returns the widget ID
	 * @returns {String} the widget ID as string
	 */
	getID() {
		return this.wdgId;
	}

	/**
	 * returns the ID of the parent composite
	 * @returns {String} the ID of the parent composite as string
	 */
	getParId() {
		return this.parId;
	}

	/**
	 * @returns {Boolean} true if this button is selected; false otherwise
	 */
	isSelected() {
		return this.selection === SEL_SELECTED;
	}

	/**
	 * @returns {Boolean} true if this button is enabled; false otherwise
	 */
	isEnabled() {
		return this.enabled;
	}

	/**
	 * @returns {Boolean} true if this button is visible; false otherwise
	 */
	isVisible() {
		return this.visible;
	}

	/**
	 * @returns {Boolean} true if this button can be selected; false otherwise
	 */
	canSelect() {
		return this.enabled && this.visible;
	}

	/**
	 * @returns {Boolean} true if this button is focused; false otherwise
	 */
	isFocused() {
		return this.focus;
	}
	
	/**
	 * marks this instance as fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
		if ( this.type ) {
			const grp = SelBtnGrp.getSelBtnGrp(this.getParId());
			if ( grp ) {
				this.btnGrp = grp;
				this.btnGrp.addBtn(this);
			}
			this.element.parentElement.style.pointerEvents = 'auto';
		}
		// publish key event handler
		this.element._keyEvtHdl = this;
		this.element.parentElement._keyEvtHdl = this;
		// update UI & status
		this._updUI();
		this._updEna(this.enabled);
	}

	/**
	 * called if the widget hast been rendered
	 */
	onRender() {
		if ( this.element && this.element.parentElement ) {
			rap.off('render', this.onRender);
			this.onReady();
			this.layout();
		}
	}

	/**
	 * updates the layout
	 */
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const wdt = area[2];
			const hgt = area[3];
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = wdt + 'px';
			this.element.style.height = hgt + 'px';
		}
	}

	/**
	 * changes the "enabled" status
	 * @param {Boolean} arg the new enabled status
	 */
	setEnabled(arg) {
		const ena = !!arg;
		if ( this.enabled !== ena ) {
			this.enabled = ena;
			this._updEna(this.enabled);
		}
	}

	/**
	 * changes the "visible" status
	 * @param {Boolean} arg the new visible status
	 */
	setVisible(arg) {
		const vis = !!arg;
		// set nothing but the flag; RAP cares about the visibility...
		this.visible = vis;
	}

	/**
	 * changes the label text
	 * @param {String} text new label text
	 */
	setText(text) {
		this.text = text || null;
		this._updUI();
	}

	/**
	 * changes the "selection" status 
	 * @param {Boolean} args the new "selection" status
	 */
	setSelection(args) {
		let sel = SEL_NULL;
		if ( args !== null ) {
			sel = !!args ? SEL_SELECTED : SEL_UNSELECTED;
		}
		if ( this.selection !== sel ) {
			this.selection = sel;
			this._updSel(this.selection);
		}
	}

	/**
	 * changes the text font
	 * @param {Object} font font descriptor
	 */
	setFont(font) {
		this.font = font || null;
		this._updUI();
	}

	/**
	 * changes the foreground / text color
	 * @param {Object} txc color descriptor
	 */
	setClrTxt(txc) {
		this.clrTxt = txc || null;
		this._updUI();
	}

	/**
	 * changes the background color
	 * @param {Object} bgc color descriptor
	 */
	setClrBkg(bgc) {
		this.clrBkg = bgc || null;
		this._updUI();
	}

	/**
	 * changes the marker color
	 * @param {Object} mkc color descriptor
	 */
	setClrMrk(mkc) {
		this.clrMrk = mkc || null;
		this._updUI();
	}

	/**
	 * sets the read/only state
	 * @param {Boolean} ro new read/only state
	 */
	setReadOnly(ro) {
		this.readonly = !!ro;
	}

	/**
	 * sets the "tri state" flag
	 * @param {Boolean} tri "tri state" flag
	 */
	setTriState(tri) {
		this.tristate = !!tri;
	}

	/**
	 * sets the "link only" flag
	 * @param {Boolean} lo "link onyl" flag
	 */
	setLinkOnly(lo) {
		this.linkonly = !!lo;
	}

	/**
	 * sets the alignment
	 * @param {String} aln new aligment
	 */
	setAlignment(aln) {
		if ( Validator.isString(aln) ) {
			if ( aln !== this.align ) {
				this.align = aln;
				this._updUI();
			}
		}
	}

	/**
	 * forces the button to the in "unselected" status
	 */
	forceUnselected() {
		if ( this.selection !== SEL_UNSELECTED ) {
			this.setSelection(SEL_UNSELECTED);
			this._nfySelChn();
		}
	}

	/**
	 * activates this button
	 * @param {Boolean} sel flag whether to select the button
	 */
	activate(sel) {
		if ( this.ready && this.enabled ) {
			if ( sel ) {
				if ( !this.isSelected() ) {
					this.setSelection(SEL_SELECTED);
					if ( this.btnGrp ) {
						this.btnGrp.onBtnSel(this);
					}
					this._nfySelChn();
				}
			}
			this._nfyActivate();
		}
	}

	/**
	 * handles keyboard events
	 * @param {KeyboardEvent} ke the keyboard event
	 * @param {HTMLElement} tgt the real target element
	 * @returns {Boolean} true if the keyboard event was handled
	 */
	hdlKeyEvt(ke, tgt) {
		if ( !this.focus && this.btnGrp ) {
			// forward to radio button group
			return this.btnGrp.hdlKeyEvt(ke, tgt);

		}
		if ( this.ready && this.enabled && this.focus ) {
			if ( ke.altKey || ke.ctrlKey || ke.metaKey || ke.shiftKey ) {
				// no modifiers, please!
				return false;
			}
			const pe = this.element.parentElement;
			const eff_tgt = tgt || ke.target;
			if ( (pe === eff_tgt) || pe.contains(eff_tgt) ) {
				if ( this.type ) {
					// we accept [Space], [Left], [Up], [Right] and [Down]
					let hdl = false;
					let move = null;
					switch ( ke.code ) {
						case 'Space':
							this._changeSel();
							hdl = true;
							break;
						case 'ArrowLeft':
						case 'ArrowUp':
							move = false;
							hdl = true;
							break;
						case 'ArrowRight':
						case 'ArrowDown':
							move = true;
							hdl = true;
							break;
						default:
							break;
					}
					if ( move !== null ) {
						if ( this.btnGrp ) {
							this.btnGrp.moveSel(this, move);
						}
					}
					return hdl;
				} else {
					// all we accept is [Space]
					if ( ke.code === 'Space' ) {
						this._changeSel();
						return true;
					}
				}
			}
		}
		return false;
	}

	/**
	 * called by the web server to notify the control about focus changes
	 * @param {Object} args arguments
	 */
	focusChanged(args) {
		if ( this.element ) {
			const focus = !!args.focus;
			if ( this.focus !== focus ) {
				this.focus = focus;
				if ( focus ) {
					this.element.classList.add(CSS_FOCUS);
				} else {
					this.element.classList.remove(CSS_FOCUS);
				}
				if ( focus && this.btnGrp ) {
					this.btnGrp.onBtnFocus(this);
				}
			}
		}
	}

	/**
	 * updates the UI
	 */
	_updUI() {
		if ( this.ready && this.element ) {
			let ue = false;
			let has_lbl = false;
			if ( Validator.isString(this.text) ) {
				if ( !this.label ) {
					const label = document.createElement('div');
					label.className = CSS_LABEL;
					this.element.appendChild(label);
					this.label = label;
					ue = true;
				}
				this.label.innerText = this.text;
				has_lbl = true;
			} else {
				if ( this.label ) {
					this.label.innerHTML = '';
				}
			}
			if ( !has_lbl && Validator.isString(this.align) ) {
				const style = this.element.style;
				if ( this.align === 'center' ) {
					style.flexDirection = 'column';
					style.alignItems = 'center';
					style.paddingTop = '3px';
					style.paddingBottom = '';
					style.paddingRight = '3px';
				} else if ( this.align === 'right' ) {
					style.flexDirection = 'row-reverse';
					style.alignItems = '';
					style.paddingTop = '';
					style.paddingBottom = '3px';
					style.paddingRight = '3px';
				} else {
					style.flexDirection = '';
					style.alignItems = '';
					style.paddingTop = '';
					style.paddingBottom = '3px';
					style.paddingRight = '';
				}
			}
			// apply UI style settings
			if ( has_lbl && this.label ) {
				this.itmMgr.setFnt(this.label, this.font);		// ItmMgr deals with null
			}
			this.itmMgr.setFgrClr(this.element, this.clrTxt);	// ItmMgr deals with null
			const bgc_elm = this.label ? this.element : this.button;
			this.itmMgr.setBkgClr(bgc_elm, this.clrBkg, false);
			// remove any backrgound style set by RAP
			this.itmMgr.setBkgClr(this.element.parentElement, null, true);
			if ( this.type && this.marker ) {
				// set radiobutton marker's color
				this.itmMgr.setFgrClr(this.marker, this.clrMrk);
			}
			if ( ue ) {
				this._updEna(this.enabled);
			}
		}
	}

	/**
	 * updates "enabled" status visualization
	 * @param {Boolean} ena "enabled" status
	 */
	_updEna(ena) {
		if ( this.button ) {
			if ( ena ) {
				if ( this.type ) {
					this.button.classList.remove(CSS_RADIODISABLED);
				}
				this.button.classList.remove(CSS_BTNDISABLED);
				if ( this.label ) {
					this.label.classList.remove(CSS_LBLDISABLED);
				}
			} else {
				this.button.classList.add(CSS_BTNDISABLED);
				if ( this.type ) {
					this.button.classList.add(CSS_RADIODISABLED);
				}
				if ( this.label ) {
					this.label.classList.add(CSS_LBLDISABLED);
				}
			}
		}
	}

	/**
	 * updates the selection visualisation
	 * @param {Boolean} sel new selection status
	 */
	_updSel(sel) {
		if ( this.marker ) {
			if ( sel === SEL_NULL ) {
				this.nullmarker.style.display = '';
				this.marker.style.display = 'none';
			} else {
				this.nullmarker.style.display = 'none';
				this.marker.style.display = !!sel ? '' : 'none';
			}
		}
	}

	/**
	 * calculates the next selection status
	 * @returns {Boolean} the next selection status (either SEL_UNSELECTED, SEL_SELECTED or SEL_NULL if tri-state is allowed)
	 */
	_nxtSel() {
		if ( this.type ) {
			// a selected radion button cannot be unselected by clicking on it
			return SEL_SELECTED;
		}
		if ( !this.tristate ) {
			// y -> n -> y -> n ...
			return !!this.selection ? SEL_UNSELECTED : SEL_SELECTED;
		} else {
			// null -> y -> n -> null -> y -> n ...
			const sel = this.selection;
			if ( sel === SEL_NULL ) {
				return SEL_SELECTED;
			} else if ( sel === SEL_SELECTED ) {
				return SEL_UNSELECTED;
			} else {
				return SEL_NULL;
			}
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv(code, par) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify('SELBTN_NFY', param);
		}
	}

	/**
	 * notifies the web server about selection changes
	 */
	_nfySelChn() {
		const par = {};
		par.selection = this.selection;
		this._nfySrv('nfySelect', par);
	}

	/**
	 * notifies the web server about button activation
	 */
	_nfyActivate() {
		const par = {}
		par.selection = this.selection;
		par.focus = this.focus;
		this._nfySrv('nfyActivate', par);
	}

	/**
	 * "click" event listener
	 * @param {MouseEvent} e the click event
	 */
	_onClick(e) {
		if ( this.ready && this.enabled ) {
			const tgt = e.target || null;
			if ( (tgt === this.button) || this.button.contains(tgt) || (this.label && (tgt === this.label)) ) {
				this._changeSel();
			}
		}
	}

	/**
	 * changes the selection to the next possible value
	 */
	_changeSel() {
		if ( !this.linkonly && !this.readonly ) {
			this.setSelection(this._nxtSel());
		}
		if ( this.btnGrp ) {
			this.btnGrp.onBtnSel(this);
		}
		this._nfySelChn();
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget SelBtn.');
		rap.registerTypeHandler('psawidget.SelBtn', {
			factory : function(properties) {
				return new SelBtn(properties);
			},
			destructor: 'destroy',
			properties: [ 'enabled', 'visible', 'text', 'selection', 'font', 'clrTxt', 'clrBkg', 'clrMrk', 'readOnly', 'triState', 'linkOnly', 'alignment' ],
			methods: [ 'focusChanged' ],
			events: [ 'SELBTN_NFY' ]
		});
	}
}

console.log('widgets/selbtn/SelBtn.js loaded.');