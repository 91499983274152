import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class JsClock implements the custom widget psawidget.JsClock
 */
export default class JsClock {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this.itmMgr = ItmMgr.getInst();
		this._psa.bindAll(this, ["layout", "onReady", "onSend", "onRender"]);
		this.ready = false;
		this.bckClr = null;
		this.fgrClr = null;
		this.dspSec = false;
		this.clkWdt = 0;
		this.clkHgt = 0;
		this.clkSiz = 0;

		this.parent = rap.getObject(properties.parent);
		this.element = document.createElement('div');
		this.element.style.position = 'absolute';
		this.element.style.cursor = 'default';
		this.parent.append(this.element);
		this._init(properties);

		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
	}

	destroy() {
		this._cleanup();
		this.parent = null;
		this.ready = false;
	}

	onReady() {
		this.ready = true;
	}

	onRender() {
		if (this.element && this.element.parentNode) {
			rap.off("render", this.onRender);
			this.onReady();
			this.layout();
			this.idTmr = window.setInterval(pisasales.bind(this, this._drawClock), 1000);
		}
	}

	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const wdt = area[2];
			const  hgt = area[3];
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = wdt + 'px';
			this.element.style.height = hgt + 'px';
			this.clkWdt = wdt;
			this.clkHgt = hgt;
			this.clkSiz = Math.min(wdt, hgt);
			if ( this.canvas ) {
				// we must set canvas' attributes! *NOT* CSS properties... 
				this.canvas.width = wdt;
				this.canvas.height = hgt;
			}
			this._drawClock();
		}
	}

	onSend() {
		// do nothing so far...
	}

	_init(args) {
		const canvas = document.createElement('canvas');
		canvas.style.width = 'inherit';
		canvas.style.height = 'inherit';
		canvas.style.position = 'initial';
		canvas.style.top = '0px';
		canvas.style.left = '0px';
		this.element.appendChild(canvas);
		this.canvas = canvas;
		this.element.addEventListener('mouseup', this._psa.bind(this, this._onMouseUp));
	}

	_cleanup() {
		window.clearInterval(this.idTmr);
		this.ready = false;
		delete this.itmMgr;
		delete this.canvas;
	}

	_drawFace(ctx, r, clr) {
		// erase background
		ctx.clearRect(-r, -r, 2 * r, 2 * r);
		ctx.beginPath();
		ctx.arc(0, 0, r, 0, 2 * Math.PI);
		ctx.fillStyle = 'transparent';
		ctx.fill();
		ctx.beginPath();
		ctx.fillStyle = clr;
		ctx.fill();
	}

	_drawNumbers(ctx, r, clr) {
		const fsz = r * 0.15;
		const dtx = fsz > 9;
		if ( dtx ) {
			ctx.font = fsz + 'px Roboto';
			ctx.textBaseline = 'middle';
			ctx.textAlign = 'center';
		}
		for ( let num = 1; num <= 12; ++num ) {
			const ang = num * Math.PI / 6;
			ctx.rotate(ang);
			ctx.translate(0, -r * 0.85);
			if (dtx) {
				ctx.rotate(-ang);
				ctx.fillText(num.toString(), 0, 0);
				ctx.rotate(ang);
			} else {
				ctx.beginPath();
				ctx.arc(0, 0, r * 0.05, 0, 2 * Math.PI);
				ctx.fillStyle = clr;
				ctx.fill();
			}
			ctx.translate(0, r * 0.85);
			ctx.rotate(-ang);
		}
	}

	_drawTime(ctx, r, clr) {
		const now = new Date();
		let hour = now.getHours();
		let minute = now.getMinutes();
		let second = now.getSeconds();
		ctx.strokeStyle = clr;
		//hour
		hour = hour % 12;
		hour = (hour * Math.PI / 6) + (minute * Math.PI / (6 * 60)) + (second * Math.PI / (360 * 60));
		this._drawHand(ctx, hour, r * 0.5, r * 0.07, clr);
		//minute
		minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
		this._drawHand(ctx, minute, r * 0.75, r * 0.07, clr);
		if ( this.dspSec ) {
			// second
			second = (second * Math.PI / 30);
			this._drawHand(ctx, second, r * 0.85, r * 0.02);
		}
	}

	_drawHand(ctx, pos, len, wdt, clr) {
		ctx.beginPath();
		ctx.lineWidth = wdt;
		ctx.lineCap = 'round';
		ctx.moveTo(0, 0);
		ctx.rotate(pos);
		ctx.lineTo(0, -len);
		ctx.stroke();
		ctx.rotate(-pos);
	}

	_drawClock() {
		if ( this.ready && this.canvas ) {
			const clr = this.element.style.color;
			const ctx = this.canvas.getContext("2d");
			const r = this.clkSiz * 0.45;
			// reset current transformation matrix to the identity matrix
			ctx.setTransform(1, 0, 0, 1, 0, 0);
			// move to the middle point
			ctx.translate(this.clkWdt / 2, this.clkHgt / 2);
			this._drawFace(ctx, r, clr);
			this._drawNumbers(ctx, r, clr);
			this._drawTime(ctx, r, clr);
		}
	}

	_onMouseUp(evt) {
		const par = {};
		par.btn = evt.button;
		this._nfySrv("nfyClick", par);
	}

	_nfySrv(code, par) {
		if ( this.ready ) {
			const param = {};
			param.cod = code;
			param.par = par;
			rap.getRemoteObject(this).notify("JCL_NFY", param);
		}
	}

	setBgc(args) {
		this.itmMgr.setBkgClr(this.element, args, true);
	}

	setFgc(args) {
		this.itmMgr.setFgrClr(this.element, args);
	}

	setFnt(args) {
		this.itmMgr.setFnt(this.element, args);
	}

	setSec(args) {
		this.dspSec = args || false;
		this._drawClock();
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget JsClock.');
		/** register custom widget type */
		rap.registerTypeHandler("psawidget.JsClock", {
			factory: function (properties) {
				return new JsClock(properties);
			},
			destructor: "destroy",
			properties: ["bgc", "fgc", "fnt", "sec"],
			methods: [],
			events: ["JCL_NFY"]
		});
	}
}

console.log('widgets/jsclock/JsClock.js loaded.');