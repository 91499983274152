import AttachmentObject from '../../../../utils/AttachmentObject';
import DomEventHelper from '../../../../utils/DomEventHelper';
// import EventListenerManager from '../../../../utils/EventListenerManager';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class XtwTblColumnTooltipExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get tableRect() {
		if ( !( this.parElm instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.parElm.getBoundingClientRect();
	}

	get tableEndX() {
		const tableRect = this.tableRect;
		if ( !( tableRect instanceof DOMRect ) ) {
			return void 0;
		}
		return tableRect.x + tableRect.width;
	}

	focus() {
		const body = this.wdgBody;
		if ( !Validator.isFunctionPath( body, "body._updateFocusedUI" ) ) {
			return false;
		}
		return body._updateFocusedUI( true );
	}

	get hasDomFocus() {
		if ( !this.isRendered ) {
			return false;
		}
		const activeElement = window.document.activeElement;
		if ( !( activeElement instanceof HTMLElement ) ) {
			return false;
		}
		return this.parElm === activeElement ||
			this.parElm.contains( activeElement );
	}

	makeSureTableIsFocused() {
		if ( this.hasDomFocus ) {
			return true;
		}
		return this.focus();
	}

	_onKeyDown( domEvent ) {
		if ( this.handleCopyTooltipSumRequest( domEvent ) ) {
			return;
		}
	}

	handleCopyTooltipSumRequest( domEvent ) {
		if ( !DomEventHelper.keyIs( domEvent, "s" ) ||
			!DomEventHelper.isCtrlEvent( domEvent ) || !domEvent.altKey ) {
			return false;
		}
		const column = this.activeMouseoverTooltipSumColumn;
		if ( !Validator.isObject( column ) || !( "tooltipSum" in column ) ) {
			return false;
		}
		if ( !column.handleCopyTooltipSumRequest() ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		return true;
	}

	removeAllColumnTooltips() {
		const head = this.wdgHead;
		if ( !Validator.isObject( head ) || !( "allColumns" in head ) ) {
			return false;
		}
		const columns = head.allColumns;
		if ( !Validator.isArray( columns ) ) {
			return false;
		}
		for ( let column of columns ) {
			if ( Validator.isFunctionPath( column, "column.onMouseLeave" ) ) {
				column.onMouseLeave();
			}
		}
		return true;
	}

}
