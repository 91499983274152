/**
 * a file system item class
 */
export default class FSItem {
	/**
	 * constructs a new instance
	 * @param {Boolean} fse true: the item is a FileSystemEntry instance; false: the item is a File instance
	 * @param {FileSystemEntry | File} item the item
	 */
	constructor(fse, item) {
		Object.defineProperty(this, 'isFSEntry', { value: (fse || false), configurable: false, enumerable: true, writable: false});
		Object.defineProperty(this, 'fsItem', { value: (item || null), configurable: false, enumerable: true, writable: false});
		Object.freeze(this);
	}
}

console.log('dnd/FSItem.js loaded.');