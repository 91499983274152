import { BUILTIN_PSAWEBCLI_VER } from '../version';

let singleton = null;

/** internal INFO class */
export default class INFO {

	constructor() {
		if ( singleton === null ) {
			singleton = this;
		} else {
			throw new Error('Do not create an instance of INFO! Use the singleton instead.');
		}
		this._isIE = false;
		this._isOldIE = false;
		this._isEdge = false;
		this._isFF = false;
		this._isMac = false;
		this._getBrw();
	}

	/**
	 * returns the singleton instance
	 * @returns {INFO} the singleton instance
	 */
	static getInstance() {
		return singleton;
	}

	_chkOldIE(ua) {
		this._isOldIE = !(ua.indexOf('RV:11') !== -1);
	}

	_getBrw() {
		const ua = navigator.userAgent.toUpperCase();
		this._isIE = ua.indexOf('TRIDENT') !== -1;
		this._isEdge = ua.indexOf('EDGE') !== -1;
		this._isFF = ua.indexOf('FIREFOX') !== -1;
		if ( this._isIE ) {
			this._chkOldIE(ua);
		}
		this._isMac = ua.indexOf('MACINTOSH') !== -1;
	}

	/**
	 * returns the built-in web client version
	 * @returns {String} the built-in web client version
	 */
	getBuiltInVersion() {
		return BUILTIN_PSAWEBCLI_VER;
	}

	/**
	 * returns the web client version
	 * @returns {String} the web client version
	 */
	getWebClientVersion()  {
		return this.getBuiltInVersion();
	}

	isIE() {
		return this._isIE;
	}

	isOldIE() {
		return this._isOldIE;
	}

	isEdge() {
		return this._isEdge;
	}

	isMS() {
		return this.isIE() || this.isEdge();
	}

	isFF() {
		return this._isFF;
	}

	isMac() {
		return this._isMac;
	}

	isBrwOk() {
		if ( this.isIE() ) {
			// never ever!
			return false;
		}
		return this._basicJsCheck();
	}

	_basicJsCheck() {
		let brw_ok = (typeof Date.now === 'function');
		// check String prototype
		brw_ok = brw_ok && ( typeof String.prototype.startsWith === 'function' ); // sorry, IE
		brw_ok = brw_ok && ( typeof String.prototype.endsWith === 'function' );   // sorry, IE
		brw_ok = brw_ok && ( typeof String.prototype.trimRight === 'function' );  // sorry, IE
		brw_ok = brw_ok && ( typeof String.prototype.includes === 'function' );   // sorry, IE
		// check Element prototype
		brw_ok = brw_ok && ( typeof Element.prototype.remove === 'function' );    // sorry, IE
		// check Number prototype
		brw_ok = brw_ok && ( typeof Number.isInteger === 'function' );            // sorry, IE
		// done
		return brw_ok;
	}
}

singleton = new INFO();

console.log('internal/info.js loaded.');