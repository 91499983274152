import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import SelectionManagerRegistry from './SelectionManagerRegistry';
import SelectionModeExtension from './SelectionModeExtension';
import SelectionManagerHelpers from './SelectionManagerHelpers';
import GlobalCounter from '../../../../utils/GlobalCounter';

const DO_LOG = false;
/** the ID of the dummy row */
const EMPTY_ROW = 0x7fffffff; // Integer.MAX_VALUE

export default class SelectionManager {

	constructor( hostObject, rowClassName ) {
		if ( !this._addSelf( hostObject ) )
			return !DO_LOG ? void 0 : Warner._trace( `An selection manager could` +
				` not be attached to the host object.` );
		this._canFocus = true;
		this.hostObject = hostObject;
		this.focusRow = void 0;
		this.currentRowSelection = new Set();
		this.previousRowSelection = new Set();
		if ( !Validator.isString( rowClassName ) ) {
			rowClassName = "Object";
		}
		new SelectionManagerRegistry( this );
		new SelectionModeExtension( this );
		new SelectionManagerHelpers( this );
		Object.defineProperty( this, "rowClassName", {
			value: rowClassName,
			writable: false
		} );
	}

	set focusRow( newValue ) {
		this._actualFocusRow = newValue;
		if ( !DO_LOG || this.focusNotificationsFrozen ) {
			return;
		}
		if ( Validator.is( newValue, this.rowClassName ) ) {
			Warner.outputIf( {
				doOutput: DO_LOG,
				text: `The focus row of the selection manager was set to a valid` +
					` row with the id ${ this.focusRowId }.`,
				color: "#FF2281"
			} );
		} else {
			Warner.outputIf( {
				doOutput: DO_LOG,
				text: `The focus row of the selection manager was set to ${ newValue }.`,
				color: "#FF0000"
			} );
		}
	}

	get focusRow() {
		return this._actualFocusRow;
	}

	get isRapFocus() {
		return Validator.isObject( this.hostObject ) &&
			!!this.hostObject.isRapFocus;
	}

	get domFocusBlocked() {
		return Validator.isObject( this.hostObject ) &&
			this.hostObject.domFocusBlocked;
	}

	set domFocusBlocked( newValue ) {
		if ( !Validator.isObject( this.hostObject ) ||
			!( "domFocusBlocked" in this.hostObject ) ) {
			return;
		}
		this.hostObject.domFocusBlocked = newValue;
	}

	get canFocus() {
		return !!this._canFocus;
	}

	set canFocus( newValue ) {
		if ( !Validator.isBoolean( newValue ) ) {
			return;
		}
		this._canFocus = !!newValue;
	}

	freezeFocus() {
		this.canFocus = false;
		return !this.canFocus;
	}

	reviveFocus() {
		this.canFocus = true;
		return this.canFocus;
	}

	get flatModel() {
		if ( !Validator.isObjectPath( this.hostObject, "hostObject.model" ) ) {
			return void 0;
		}
		const flatModel = this.hostObject.model.flatModel;
		return Validator.isArray( flatModel ) ? [ ...flatModel ] : void 0;
	}

	focus( row ) {
		this._unfocus();
		this._focusRow( row );
	}

	_focusRow( row ) {
		// if ( this.noSelectionAllowed ) return false;
		if ( !Validator.is( row, this.rowClassName ) || !row.isRendered ) {
			return false;
		}
		this._registerAsPreviouslyFocused( this.focusRow );
		this.focusRow = row;
		row.isFocused = true;
		this._addRowIdToFocusRegistry( { row: row } );
		return true;
	}

	unfocus( row ) {
		// if ( this.noSelectionAllowed ) return false;
		if ( !Validator.is( row, this.rowClassName ) ) {
			return false;
		}
		row.isFocused = false;
		if ( this.focusRow === row ) {
			this._registerAsPreviouslyFocused( row );
			this.focusRow = void 0;
		}
		return true;
	}

	_unfocus() {
		// if ( this.noSelectionAllowed ) return false;
		if ( !Validator.is( this.focusRow, this.rowClassName ) ) {
			this.focusRow = void 0;
			return false;
		}
		this._registerAsPreviouslyFocused( this.focusRow );
		this.focusRow.isFocused = false;
		this.focusRow = void 0;
		return true;
	}

	get focusRowId() {
		if ( !Validator.is( this.focusRow, this.rowClassName ) ) return -1;
		const focusRowId = Number( this.focusRow.rowId );
		if ( !Validator.isValidNumber( focusRowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ this.focusRow.rowId }".` );
			return -1;
		}
		return focusRowId;
	}

	focusModelItemById( rowId, inform = false ) {
		if ( !this._focusModelItemById( rowId ) ) {
			return false;
		}
		return !inform ? true : this.informAboutRowSelection( -1 );
	}

	_focusModelItemById( rowId, inform = false ) {
		this.unfocusEveryModelItem();
		const modelItem = this.getModelItemByRowId( rowId );
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		modelItem.isFocused = true;
		return true;
	}

	focusRowById( rowId, inform = false ) {
		if ( !this._focusRowById( rowId ) ) {
			return false;
		}
		return !inform ? true : this.informAboutRowSelection( -1 );
	}

	_focusRowById( rowId ) {
		this.unfocusEveryModelItem();
		const xRowItem = this.getXRowItemById( rowId );
		if ( !Validator.isObject( xRowItem ) ) {
			return false;
		}
		this.focus( xRowItem );
		return true;
	}

	addRowToCurrentlySelected( rowId ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ) return false;
		if ( Validator.isString( rowId ) ) rowId = Number( rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id is "${ rowId }".` );
		}
		if ( !Validator.isPositiveNumber( rowId ) ) return false;
		if ( this.onlyOneItemCanBeSelected ) {
			this.previousRowSelection = this.currentRowSelection;
			this.currentRowSelection = new Set( [ rowId ] );
			return true;
		}
		this.currentRowSelection.add( rowId );
		return true;
	}

	removeRowFromCurrentlySelected( rowId, takeSelectionModeIntoAccount = true ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ) return false;
		if ( Validator.isString( rowId ) ) rowId = Number( rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id is "${ rowId }".` );
		}
		if ( !Validator.isPositiveNumber( rowId ) ) return false;
		if ( !this.currentRowSelection.has( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID "${ rowId }" could not be removed` +
				` from the list of currently selected row IDs, because this row ID` +
				` is already not present in the list.` );
			return false;
		}
		if ( !!takeSelectionModeIntoAccount &&
			this.somethingShouldAlwaysBeSelected &&
			this.currentRowSelection.size <= 1 ) {
			Warner.traceIf( DO_LOG, `The row ID "${ rowId }" could not be removed` +
				` from the list of currently selected row IDs, because the` +
				` selection mode imposes that at least one item is always selected.` );
			return false;
		}
		this.currentRowSelection.delete( rowId );
		return true;
	}

	addRowToPreviouslySelected( rowId ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.previousRowSelection ) ) return false;
		if ( Validator.isString( rowId ) ) rowId = Number( rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id is "${ rowId }".` );
		}
		if ( !Validator.isPositiveNumber( rowId ) ) return false;
		this.previousRowSelection.add( rowId );
		return true;
	}

	moveRowToPreviouslySelected( rowId, takeSelectionModeIntoAccount = true ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ||
			!Validator.isSet( this.previousRowSelection ) ) return false;
		if ( Validator.isString( rowId ) ) rowId = Number( rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id is "${ rowId }".` );
		}
		if ( !Validator.isPositiveNumber( rowId ) ) return false;
		if ( !!takeSelectionModeIntoAccount &&
			this.somethingShouldAlwaysBeSelected &&
			this.currentRowSelection.size <= 1 ) {
			Warner.traceIf( DO_LOG, `The row ID "${ rowId }" could not be removed` +
				` from the list of currently selected row IDs, because the` +
				` selection mode imposes that at least one item is always selected.` );
			return false;
		}
		this.currentRowSelection.delete( rowId );
		this.previousRowSelection.add( rowId );
		return true;
	}

	selectRows( rowsIdList ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ||
			!Validator.isSet( this.previousRowSelection ) ) return false;
		if ( Validator.isArray( rowsIdList ) ) {
			rowsIdList = new Set( rowsIdList );
		} else if ( Validator.isString( rowsIdList ) ) {
			let numericForm = Number( rowsIdList );
			if ( !Validator.isPositiveNumber( numericForm ) ) return false;
			rowsIdList = new Set( [ numericForm ] );
		} else if ( Validator.isPositiveNumber( rowsIdList ) ) {
			rowsIdList = new Set( [ rowsIdList ] );
		}
		if ( !Validator.isSet( rowsIdList ) ) return false;
		const filteredIds = [ ...rowsIdList ].filter( id =>
			Validator.isPositiveNumber( Number( id ) ) );
		rowsIdList = new Set( filteredIds );
		if ( this.somethingShouldAlwaysBeSelected && rowsIdList.size < 1 ) {
			return false;
		}
		if ( this.onlyOneItemCanBeSelected && rowsIdList.size > 1 ) {
			const firstValue = [ ...rowsIdList.values() ][ 0 ];
			rowsIdList.clear();
			rowsIdList.add( firstValue );
		}
		this.previousRowSelection = this.currentRowSelection;
		this.currentRowSelection = rowsIdList;
		return true;
	}

	deselectAllRows() {
		if ( this.noSelectionAllowed ) return false;
		if ( this.somethingShouldAlwaysBeSelected ) {
			Warner.traceIf( DO_LOG, `All rows will be deselected, even if it is` +
				` required by the "selection modus" of the dialog that something` +
				` is always selected. Something SHOULD be selected after that.` );
		}
		if ( !Validator.isSet( this.currentRowSelection ) ||
			!Validator.isSet( this.previousRowSelection ) ) return false;
		this.previousRowSelection = this.currentRowSelection;
		this.currentRowSelection = new Set();
		return true;
	}

	removeTableSelectedAndFocusedRows() {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isObject( this.hostObject ) ) return false;
		if ( !Validator.isFunction( this.hostObject.desAllRows ) ) return false;
		return this.hostObject.desAllRows();
	}

	informAboutRowSelection( colId = -1, ignoreFocus = false ) {
		if ( this.focusNotificationsFrozen ) {
			Warner.outputIf( {
				doOutput: DO_LOG,
				text: `The focus notifications are frozen; the web server will not` +
					` be notified about row selection.`,
				color: "#FE53BB"
			} );
			return false;
		}
		// if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ||
			!Validator.isSet( this.previousRowSelection ) ) {
			return false;
		}
		if ( ( this.currentRowSelection.size === 0 ) && ( this.previousRowSelection.size === 0 ) ) {
			return false;
		}
		if ( ( this.focusRowId === EMPTY_ROW ) && !ignoreFocus ) {
			Warner.outputIf( {
				doOutput: DO_LOG,
				text: `Sending "dummy row focused" to the web server!`,
				color: "#6c0a63"
			} );
		}
		let parameters = {
			sel: Array.from( this.currentRowSelection ),
			uns: Array.from( this.previousRowSelection ),
			mode: -1, // regular selection; (0: all unselected, +1: all selected)
			foc: this.focusRowId,
			ignoreFocus: !!ignoreFocus,
			col: colId // provide column ID of clicked cell
		};
		Warner.outputIf( {
			doOutput: DO_LOG,
			text: `The web server will be notified about row selection. Focused row: ${this.focusRowId}; ignoreFocus: ${ignoreFocus}.`,
			color: "#560A86"
		} );
		console.debug(`#${GlobalCounter.getInst().nextValue()} Sending row selection:`);
		console.debug(parameters);
		this._nfySrv( 'selChange', parameters );
		return true;
	}

	informAboutCellFocus( { rowId, columnId } ) {
		if ( Validator.isString( columnId ) ) {
			columnId = Number( columnId );
		}
		if ( Validator.isString( rowId ) ) {
			rowId = Number( rowId );
		}
		if ( [ columnId, rowId ].some( id => !Validator.isPositiveInteger( id ) ) ) {
			return false;
		}
		this._nfySrv( 'cellClicked', { idc: columnId, idr: rowId } );
		return true;
	}

	updateSelectedRowsUi() {
		if ( this.domFocusBlocked || !this.isRapFocus ) {
			return this._doWithFrozenFocus( () => {
				this._updateSelectedRowsUi();
			} );
		}
		this._updateSelectedRowsUi();
	}

	_updateSelectedRowsUi() {
		this._doWithoutDamagingFocus( () => {
			// this._doWithPerservingFocus( () => {
			this.removeUiSelectionsForPrevoiusRows();
			this.addUiSelectionsForCurrentRows();
		} );
	}

	updateSelectedModelItems() {
		this._doWithoutDamagingFocus( () => {
			// this._doWithPerservingFocus( () => {
			this.removeSelectionForPreviousModelItems();
			this.addSelectionForCurrentModelItems();
		} );
	}

	focusLastFocusedModelItem() {
		const previousFocusModelItemRowId = this.previousFocusModelItemRowId;
		if ( !Validator.isPositiveInteger( previousFocusModelItemRowId ) ) {
			return false;
		}
		const modelItem = this.getModelItemByRowId( previousFocusModelItemRowId );
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		if ( !modelItem.isRendered ) {
			modelItem.isFocused = true;
			return false;
		}
		return modelItem.syncFocus();
	}

	unfocusEveryRow() {
		// if ( this.noSelectionAllowed ) return false;
		this.forEachRow( row => {
			row.isFocused = false;
		} );
	}

	unfocusEveryModelItem() {
		// if ( this.noSelectionAllowed ) return false;
		this.forEachModelItem( modelItem => {
			modelItem.isFocused = false;
		} );
	}

	deselectEveryRow() {
		// if ( this.noSelectionAllowed ) return false;
		this.forEachRow( row => {
			row.isSelected = false;
		} );
	}

	deselectEveryModelItem() {
		// if ( this.noSelectionAllowed ) return false;
		this.forEachModelItem( modelItem => {
			modelItem.isSelected = false;
		} );
	}

	unfocusAndDeselectEveryModelItem() {
		// if ( this.noSelectionAllowed ) return false;
		this.forEachModelItem( modelItem => {
			modelItem.isFocused = false;
			modelItem.isSelected = false;
		} );
	}

	addUiSelectionsForCurrentRows() {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ) {
			return false;
		}
		const currentlySelectedRowsIds = Array.from( this.currentRowSelection );
		for ( let rowId of currentlySelectedRowsIds ) {
			let row = this.getXRowItemById( rowId );
			if ( !Validator.is( row, this.rowClassName ) ) {
				continue;
			}
			row.select();
		}
		return true;
	}

	addSelectionForCurrentModelItems() {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.currentRowSelection ) ) {
			return false;
		}
		const currentlySelectedRowsIds = Array.from( this.currentRowSelection );
		for ( let rowId of currentlySelectedRowsIds ) {
			let modelItem = this.getModelItemByRowId( rowId );
			if ( !Validator.isObject( modelItem ) ) {
				continue;
			}
			modelItem.isSelected = true;
		}
		return true;
	}

	removeUiSelectionsForPrevoiusRows() {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.previousRowSelection ) ) {
			return false;
		}
		const previouslySelectedRowsIds = Array.from( this.previousRowSelection );
		for ( let rowId of previouslySelectedRowsIds ) {
			let row = this.getXRowItemById( rowId );
			if ( !Validator.is( row, this.rowClassName ) ) {
				continue;
			}
			row.deselect();
			row.unfocus();
		}
		this._unfocus();
		return true;
	}

	removeSelectionForPreviousModelItems() {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isSet( this.previousRowSelection ) ) {
			return false;
		}
		const previouslySelectedRowsIds = Array.from( this.previousRowSelection );
		for ( let rowId of previouslySelectedRowsIds ) {
			let modelItem = this.getModelItemByRowId( rowId );
			if ( !Validator.isObject( modelItem ) ) {
				continue;
			}
			modelItem.isSelected = false;
			modelItem.isFocused = false;
		}
		return true;
	}

	select( { row, rowId, controlPressed = false, shiftPressed = false } ) {
		if ( this.noSelectionAllowed ) return false;
		return this.setRowSelection( {
			row: row,
			rowId: rowId,
			select: true,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
	}

	deselect( { row, rowId, controlPressed = false, shiftPressed = false } ) {
		if ( this.noSelectionAllowed ) return false;
		return this.setRowSelection( {
			row: row,
			rowId: rowId,
			select: false,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
	}

	setRowSelection( {
		row,
		rowId,
		select = true,
		controlPressed = false,
		shiftPressed = false
	} ) {
		if ( this.noSelectionAllowed ) return false;
		let xRowItem = row;
		if ( !Validator.is( xRowItem, this.rowClassName ) ) {
			xRowItem = this.getXRowItemById( rowId );
			if ( !Validator.isObject( xRowItem ) ) {
				return false;
			}
		}
		xRowItem.freezeSelectionUiUpdates();
		xRowItem.isSelected = !select;
		xRowItem.reviveSelectionUiUpdates();
		return this.toggleRowSelection( {
			row: xRowItem,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
	}

	/**
	 * toggles a row's "selected" state AND takes into account the "selected"
	 * states of other rows, specifically:
	 *
	 * @param {boolean} shiftPressed
	 * 1. if "shift" key is pressed, we should select a range of rows from the
	 * last focused row to this row (the range is dentified by DOM elements
	 * inbetween); the "control" key has no effect in the case when the
	 * "shiftIsPressed" parameter has the value "true" a. k. a. the "shift" key
	 * is pressed (so the value of the "controlPressed" parameter is irelevant)
	 * and the "forceSelectIfSimpleClick" parameter has no influence aswell;
	 *
	 * @param {boolean} controlPressed
	 * 2. if "control" key is pressed, we should toggle the current row's
	 * "selected" state without changing/impacting/damaging other row's
	 * "selected" states; this parameter is taken into account ONLY if the
	 * "shift" key is not pressed (if the "shiftPressed" parameter has the value
	 * "false") and the "forceSelectIfSimpleClick" parameter has no
	 * effect/influence if the value of the "controlPressed" parameter is "true"
	 * (if the "control" key is pressed);
	 *
	 * @param {boolean} forceSelectIfSimpleClick
	 * 3. this ("forceSelectIfSimpleClick") parameter was added because when
	 * clicking on a row it is now expected that it is selected, even if it was
	 * selected before (so a simple click sets the selection instead of toggling
	 * it); it is only taken into account and has an impact if both
	 * "shiftPressed" and "controlPressed" have the value "false" a. k. a. the
	 * "shift" and "control" keys are both not pressed;
	 *
	 * for the "toggling" to take effect the row or the rowId should be also
	 * specified and passed:
	 * @param row the targeted row, whose prototype should be this.rowClassName;
	 * @param rowId the row id that can be used to get/find/retrieve the
	 * corresponding row, if such exists;
	 *
	 * @return {boolean} the success of the operation
	 */
	toggleRowSelection( {
		row,
		rowId,
		controlPressed = false,
		shiftPressed = false,
		forceSelectIfSimpleClick = false
	} ) {
		if ( this.noSelectionAllowed ) return false;
		let xRowItem = row;
		if ( !Validator.is( xRowItem, this.rowClassName ) ) {
			xRowItem = this.getXRowItemById( rowId );
			if ( !Validator.isObject( xRowItem ) ) {
				return false;
			}
		}
		const selectedRows = !!shiftPressed ?
			this._getShiftSelectedRows( xRowItem ) : !!controlPressed ?
			this._getControlSelectedRows( xRowItem ) :
			this._getBasicClickSelectedRows( xRowItem, forceSelectIfSimpleClick );
		const itemThatShouldBeFocused = this.onlyOneItemCanBeSelected ? xRowItem :
			shiftPressed && Validator.is( this.focusRow, this.rowClassName ) ?
			this.focusRow : xRowItem;
		return this.selectRowsList( selectedRows, itemThatShouldBeFocused );
	}

	selectRowsList( rowsToSelect, rowToFocus = void 0 ) {
		if ( this.noSelectionAllowed ) return false;
		const itemThatShouldBeFocused =
			Validator.is( rowToFocus, this.rowClassName ) ?
			rowToFocus : this.focusRow;
		this.deselectEveryRow();
		this.unfocusAndDeselectEveryModelItem();
		if ( !this.selectRows( rowsToSelect ) ) {
			return false;
		}
		this.addSelectionForCurrentModelItems();
		// this.updateSelectedModelItems();
		this.updateSelectedRowsUi();
		this.unfocusEveryRow();
		if ( Validator.is( itemThatShouldBeFocused, this.rowClassName ) ) {
			this.focus( itemThatShouldBeFocused );
		}
		return true;
	}

	/**
	 * @param {boolean} forceSelectIfSimpleClick this parameter was added
	 * because when clicking on a row it is now expected that it is selected,
	 * even if it was selected before (so a simple click sets the selection
	 * instead of toggling it); "simple click" means both "shift" and "control"
	 * keys are not pressed
	 */
	clickRow( {
		rowId,
		colId = -1,
		controlPressed = false,
		shiftPressed = false,
		forceSelectIfSimpleClick = false
	} ) {
		if ( this.noSelectionAllowed ) {
			return this.focusRowById( rowId, true );
		}
		const rowSelectionToggled = this.toggleRowSelection( {
			rowId: rowId,
			controlPressed: controlPressed,
			shiftPressed: shiftPressed,
			forceSelectIfSimpleClick: controlPressed || shiftPressed ?
				false : forceSelectIfSimpleClick
		} );
		if ( !rowSelectionToggled ) {
			return false;
		}
		return this.informAboutRowSelection( colId );
	}

	/**
	 * focusing a cell autoamtically focuses a row and selects it in case the
	 * selection mode allows it, whilst taking the shift & command keys into
	 * account (the "selected" status of other row/model items is taken into
	 * account and may be influenced)
	 */
	focusCell( {
		rowId,
		colId,
		controlPressed = false,
		shiftPressed = false,
		forceSelectIfSimpleClick = true
	} ) {
		if ( !this.noSelectionAllowed ) {
			let xRowItem = this.getXRowItemById( rowId );
			if ( Validator.isObject( xRowItem ) && !xRowItem.isFocused ) {
				this.clickRow( {
					rowId: rowId,
					controlPressed: controlPressed,
					shiftPressed: shiftPressed,
					colId: colId,
					forceSelectIfSimpleClick: forceSelectIfSimpleClick
				} );
			}
		}
		if ( this.isFocusedCell( { columnId: colId, rowId: rowId } ) ) {
			return true;
		}
		this._addCellToFocusRegistry( { columnId: colId, rowId: rowId } );
		return this.informAboutCellFocus( { columnId: colId, rowId: rowId } );
	}

	isFocusedCell( { columnId, rowId } ) {
		const lastFocusedCell = this.lastFocusedCell;
		return Validator.isObject( lastFocusedCell ) &&
			lastFocusedCell.rowId == rowId && lastFocusedCell.columnId == columnId;
	}

	/**
	 * @param {boolean} forceSelectIfSimpleClick this parameter was added
	 * because when clicking on a row it is now expected that it is selected,
	 * even if it was selected before (so a simple click sets the selection
	 * instead of toggling it); "simple click" means both "shift" and "control"
	 * keys are not pressed
	 *
	 * 1. without "forceSelectIfSimpleClick" a.k.a. forceSelectIfSimpleClick = false:
	 * row is not selected	---simple-click--->		row is selected
	 * row is selected			---simple-click--->		row is not selected
	 * so "forceSelectIfSimpleClick = false" means "toggle selected state"
	 *
	 * 2. with "forceSelectIfSimpleClick" a.k.a. forceSelectIfSimpleClick = true:
	 * row is not selected	---simple-click--->		row is selected
	 * row is selected			---simple-click--->		row is selected
	 * so "forceSelectIfSimpleClick = true" means "set selected state to true"
	 */
	_getBasicClickSelectedRows( xRowItem, forceSelectIfSimpleClick = false ) {
		if ( this.noSelectionAllowed ) return void 0;
		if ( !Validator.isObject( xRowItem ) ) {
			return void 0;
		}
		const rowId = Number( xRowItem.rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ xRowItem.rowId }".` );
		}
		return this.somethingShouldAlwaysBeSelected || forceSelectIfSimpleClick ||
			!xRowItem.isSelected ? rowId : new Set();
	}

	_getControlSelectedRows( xRowItem ) {
		if ( this.noSelectionAllowed ) return void 0;
		if ( !Validator.isObject( xRowItem ) ) {
			return void 0;
		}
		const rowId = Number( xRowItem.rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ xRowItem.rowId }".` );
		}
		if ( this.onlyOneItemCanBeSelected )
			return this.somethingShouldAlwaysBeSelected || !xRowItem.isSelected ?
				new Set( [ rowId ] ) : new Set();
		const selectedRows = new Set( this.currentRowSelection );
		!!xRowItem.isSelected ? selectedRows.delete( rowId ) :
			selectedRows.add( rowId );
		if ( this.somethingShouldAlwaysBeSelected && selectedRows.size < 1 )
			return new Set( [ rowId ] );
		return selectedRows;
	}

	_getShiftSelectedRows( xRowItem ) {
		if ( this.noSelectionAllowed ) return void 0;
		if ( !Validator.isObject( xRowItem ) ) {
			return void 0;
		}
		const rowId = Number( xRowItem.rowId );
		if ( !Validator.isValidNumber( rowId ) ) {
			Warner.traceIf( DO_LOG, `The row ID of the xRowItem has an invalid` +
				` numeric value. The value of the row id before the conversion` +
				` to a number is "${ xRowItem.rowId }".` );
		}
		const focusedModelItem = this.lastFocusedModelItem;
		if ( this.onlyOneItemCanBeSelected ||
			!Validator.isObject( focusedModelItem ) || !focusedModelItem.isFocused ||
			focusedModelItem === xRowItem.item || !xRowItem.isRendered ) {
			return this.somethingShouldAlwaysBeSelected ? rowId :
				!!xRowItem.isSelected ? new Set() : rowId;
		}
		const modelItemsInbetween = this._getModelItemsInbetween( {
			sourceModelItem: focusedModelItem,
			goalModelItem: xRowItem.item
		} );
		const rowIds = [];
		for ( let modelItem of modelItemsInbetween ) {
			if ( !Validator.isObject( modelItem ) ) {
				continue;
			}
			const rowId = modelItem.rowId;
			if ( !Validator.isPositiveInteger( rowId ) ) {
				continue;
			}
			rowIds.push( rowId );
		}
		return new Set( rowIds );
	}

	isValidModelItem( modelItem ) {
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.isFunction( this.hostObject.isValidModelItem ) ) {
			return false;
		}
		return this.hostObject.isValidModelItem( modelItem );
	}

	getModelItemFlatIndex( modelItem ) {
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.isFunction( this.hostObject.getModelItemFlatIndex ) ) {
			return void 0;
		}
		return this.hostObject.getModelItemFlatIndex( modelItem );
	}

	_getModelItemsInbetween( {
		sourceModelItem,
		goalModelItem,
		includeSource = true,
		includeGoal = true
	} ) {
		if ( [ sourceModelItem, goalModelItem ].some(
				modelItem => !this.isValidModelItem( modelItem ) ) ) {
			return [];
		}
		if ( sourceModelItem === goalModelItem ) {
			return includeSource || includeGoal ? [ sourceModelItem ] : [];
		}
		const modelItemsInbetween = [];
		if ( includeSource ) {
			modelItemsInbetween.push( sourceModelItem );
		}
		const flatModel = this.flatModel;
		if ( !Validator.isArray( flatModel ) ) {
			return !includeGoal ? modelItemsInbetween :
				modelItemsInbetween.concat( goalModelItem );
		}
		const sourceModelItemIndex = this.getModelItemFlatIndex( sourceModelItem );
		if ( !Validator.isPositiveNumber( sourceModelItemIndex ) ) {
			return !includeGoal ? modelItemsInbetween :
				modelItemsInbetween.concat( goalModelItem );
		}
		const goalModelItemIndex = this.getModelItemFlatIndex( goalModelItem );
		if ( !Validator.isPositiveNumber( goalModelItemIndex ) ) {
			return !includeGoal ? modelItemsInbetween :
				modelItemsInbetween.concat( goalModelItem );
		}
		if ( Math.abs( sourceModelItemIndex - goalModelItemIndex ) <= 1 ) {
			return !includeGoal ? modelItemsInbetween :
				modelItemsInbetween.concat( goalModelItem );
		}
		const directionProvider = goalModelItemIndex > sourceModelItemIndex ? 1 : -1;
		const isFinalIndexReached = indexValue => {
			return indexValue <= 0 || indexValue >= flatModel.length - 1 ||
				indexValue == goalModelItemIndex;
		};
		let curentIndex = sourceModelItemIndex + directionProvider;
		while ( !isFinalIndexReached( curentIndex ) ) {
			// curentIndex += directionProvider;
			const modelItemFromIndex = flatModel[ curentIndex ];
			if ( !Validator.isObject( modelItemFromIndex ) ) {
				continue;
			}
			modelItemsInbetween.push( modelItemFromIndex );
			curentIndex += directionProvider;
		}
		const goalIndex = Validator.getIndexInArray( modelItemsInbetween, goalModelItem ) || -1;
		if ( includeGoal && goalIndex < 0 ) {
			modelItemsInbetween.push( goalModelItem );
		} else if ( !includeGoal && goalIndex >= 0 ) {
			modelItemsInbetween.splice( goalIndex, 1 );
		}
		return modelItemsInbetween;
	}

	selectAllModelItems( focusedRowItem, notifyServer = false ) {
		if ( this.noSelectionAllowed || this.onlyOneItemCanBeSelected ) return false;
		if ( !Validator.isObjectPath( this, "this.hostObject.model" ) ||
			!Validator.isArray( this.hostObject.model.flatModel ) ) {
			return false;
		}
		const allRowIds = [];
		flatModelItemsLoop:
			for ( let rowOrGroup of this.hostObject.model.flatModel ) {
				if ( !Validator.is( rowOrGroup, "MDataRow" ) ) {
					continue flatModelItemsLoop;
				}
				allRowIds.push( Number( rowOrGroup.idr ) );
			}
		const allRowsSelected = this.selectRowsList( allRowIds, focusedRowItem );
		if ( !notifyServer ) {
			return allRowsSelected;
		}
		this.informAboutRowSelection();
		return allRowsSelected;
	}

	selectAllRenderedRows( focusedRowItem ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.isIterable( this.hostObject.rowItems ) ) {
			return false;
		}
		const potentialRowsToSelect = [];
		for ( let rowItem of this.hostObject.rowItems ) {
			if ( !Validator.is( rowItem.item, "MDataRow" ) ) {
				continue;
			}
			potentialRowsToSelect.push( Number( rowItem.rowId ) );
		}
		return this.selectRowsList( potentialRowsToSelect );
	}

	get constructorName() {
		return Object.getPrototypeOf( this ).constructor.name ||
			this.__proto__.constructor.name;
	}

	_addSelf( hostObject ) {
		if ( !this._addSelfAs( hostObject, "selectionManager" ) ) return false;
		delete this._addSelf;
		return true;
	}

	_addSelfAs( hostObject, ownNameAsAProperty ) {
		if ( !Validator.isObject( hostObject ) ) return false;
		if ( !Validator.isString( ownNameAsAProperty ) ) return false;
		if ( ownNameAsAProperty in hostObject ) {
			if ( Validator.is( hostObject[ ownNameAsAProperty ], "SelectionManager" ) )
				return false;
			const oldIdManagerDescriptor = Validator
				.getDescriptor( hostObject[ ownNameAsAProperty ] );
			if ( !Validator.isObject( oldIdManagerDescriptor ) ) return false;
			if ( oldIdManagerDescriptor.configurable ) {
				delete hostObject[ ownNameAsAProperty ];
			} else if ( !oldIdManagerDescriptor.writable )
				return false;
			hostObject[ ownNameAsAProperty ] = void 0;
		}
		Object.defineProperty( hostObject, ownNameAsAProperty, {
			value: this,
			writable: false,
			configurable: false
		} );
		delete this._addSelfAs;
		return true;
	}

	_nfySrv( eventName, parameters ) {
		if ( this.noSelectionAllowed ) return false;
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.isFunction( this.hostObject._nfySrv ) ||
			!Validator.isString( eventName ) || !Validator.isObject( parameters ) )
			return false;
		this.hostObject._nfySrv( eventName, parameters );
		return true;
	}

}

console.log( 'widgets/xtw/impl/selection/SelectionManager.js loaded.' );
