/**
 * generic utility methods
 */

export default class Utils {

	/**
	 * calls function 'f' for each own property of object 'o'; * the signature of 'f' is f(name, value)
	 * @param {Object} o the target object
	 * @param {Function} f the function to be called
	 */
    static forEachProp( o, f ) {
		Object.getOwnPropertyNames( o ).forEach( function( p ) {
			if ( typeof o[ p ] !== 'function' ) {
				f( p, o[ p ] );
			}
		} );
	}

}