import Base from '../../base/base';
import ObjReg from '../../utils/ObjReg';
import PsaBtnBar from './PsaBtnBar';

/** the one and only instance, lazyly initialized */
let singleton = null;

/**
 * the button bar manager class
 */
export default class BtnBarMgr extends Base {
	
	/**
	 * constructs a new instance
	 */
	constructor() {
		super();
		if ( singleton !== null ) {
			throw new Error('Only one instance allowed!');
		}
		this.barReg = new ObjReg();
	}

	/**
	 * returns the singleton instance
	 * @returns {BtnBarMgr} the singleton instance
	 */
	static getInst() {
		if ( singleton === null ) {
			// lazy initialization
			singleton = new BtnBarMgr();
		}
		return singleton;
	}

	/**
	 * @override
	 */
	doDestroy() {
		if ( this.alive ) {
			this.barReg.destroy();
			delete this.barReg;
		}
		super.doDestroy();
	}

	/**
	 * adds a button bar
	 * @param {PsaBtnBar} bar the button bar to be added
	 */
	addBar(bar) {
		this.barReg.addObj(bar.getIdw(), bar);
	}

	/**
	 * removes a button bar
	 * @param {PsaBtnBar} bar the button bar to be removed
	 */
	rmvBar(bar) {
		this.barReg.rmvObj(bar.getIdw());
	}
	
	/**
	 * retrieves a button bar
	 * @param {String} idw ID of button bar
	 * @returns {PsaBtnBar} the button bar or null if not found
	 */
	getBar(idw) {
		return this.barReg.getObj(idw);
	}
}

console.log('widgets/psatbn/BtnBarMgr.js loaded');