import PSA from '../../../psa';
import HtmHelper from '../../../utils/HtmHelper';
import ItmMgr from '../../../gui/ItmMgr';
import RowKeyEventManager from '../impl/row/RowKeyEventManager';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import XCellItem from './XCellItem';
import { OWN_BACKGROUND_COLOR } from './XCellItem';
import XItem from './XItem';
import XtwRowTemplateRow from '../rtp/XtwRowTemplateRow';
import XtwUtils from '../util/XtwUtils';
import XRowItemEditingExtension from '../impl/editing/XRowItemEditingExtension';
import XtwRowItemInsertionDummyExtension from '../impl/editing/XtwRowItemInsertionDummyExtension';
import XRowItemHeightAdjustmentExtension from '../impl/rowheight/XRowItemHeightAdjustmentExtension';
import XRowItemGroupHeaderExtension from './XRowItemGroupHeaderExtension';
import { GROUP_HEADER_CLASS } from './XRowItemGroupHeaderExtension';
import MRowItem from '../model/MRowItem';
import CellCtt from '../model/CellCtt';

let PISASALES = void 0;
let ITEM_MANAGER = void 0;

const ROW_ITEM_DATASET_PREFIX = "xtw-row-item-#";
export const DO_LOG = false;

export const OWN_TEXT_COLOR = "--rtp-own-text-color";

/**
 * a row item in the UI
 */
export default class XRowItem extends XItem {

	/**
	 * constructs a new UI row item
	 * @param {XtwBody} bdy the table body instance
	 * @param {Number} idx item index
	 * @param {Number} rh initial row height
	 * @param {Boolean} rtp row template flag
	 * @param {Number} rth height in pixels of a row template row
	 */
	constructor( bdy, idx, rh, rtp, rth ) {
		super();
		if ( !Validator.isObject( PISASALES ) ) {
			PISASALES = PSA.getInst();
		}
		if ( !Validator.isObject( ITEM_MANAGER ) ) {
			ITEM_MANAGER = ItmMgr.getInst();
		}
		this.tblBody = bdy;
		this.idx = idx;
		this.defHgt = rh;
		this.rtpMode = rtp;
		this._rtpRwh = rth;
		this.isFocused = false;
		this._canUpdateSelectionUi = true;
		this.rtpRow = null;
		// create main DOM element
		const re = this.getDomElement();
		re.className = 'xtwrowitem';
		// create helpers
		new RowKeyEventManager( this );
		new XRowItemEditingExtension( this );
		new XtwRowItemInsertionDummyExtension( this );
		new XRowItemHeightAdjustmentExtension( this );
		new XRowItemGroupHeaderExtension( this );
		this.addClickListener();
		this.addDblclickListener();
		if ( re.dataset instanceof DOMStringMap ) {
			// we do need the idx inside a dataset property at all times, no matter
			// if we are in "debug" mode or not, so that selection and focus can
			// function properly
			re.dataset.xrowItem = ROW_ITEM_DATASET_PREFIX + idx;
		}
		// cell containers
		this.wdtFix = 0;
		this.wdtDyn = 0;
		this.ccnFix = null;
		this.scrDyn = null;
		this.ccnDyn = null;
		this.btnGcl = null;
		// model item
		this.item = null;
		// cell container
		this.cells = PISASALES.creObjReg();
	}

	/**
	 * destructor method
	 * @override
	 */
	destroy() {
		this._dropCont( true, true );
		if ( this.rtpRow ) {
			this.rtpRow.destroy();
			delete this.rtpRow;
		}
		[ "dblclick", "contextmenu" ].forEach( eventName => {
			this.removeListener( eventName );
		} );
		this.cells.dstChl();
		delete this.cells;
		delete this.btnGcl;
		delete this.ccnDyn;
		delete this.ccnFix;
		delete this.item;
		delete this.defHgt;
		delete this.idx;
		delete this.tblBody
		super.destroy();
	}

	get isRowTpl() {
		return Validator.isBoolean( this.rtpMode ) && this.rtpMode;
	}

	get getRowTpl() {
		if ( !Validator.isObject( this.tblBody ) ) {
			return null;
		}
		return this.isRowTpl ? this.tblBody.getRowTpl : null;
	}

	get idManager() {
		return this.getRowTpl && Validator.isObject( this.tblBody.idManager ) ?
			this.tblBody.idManager : null;
	}

	get rcells() {
		const mgr = this.idManager;
		return mgr ? mgr.rcells : null;
	}

	get unorderedCells() {
		if ( !Validator.isObject( this.cells ) ||
			!Validator.isMap( this.cells._objReg ) ) {
			return void 0;
		}
		return [ ...this.cells._objReg.values() ];
	}

	get orderedColumns() {
		if ( !Validator.isObjectPath( this.tblBody, "tblBody.xtwHead" ) ||
			!( "orderedColumns" in this.tblBody.xtwHead ) ) {
			return void 0;
		}
		const orderedColumns = this.tblBody.xtwHead.orderedColumns;
		return Validator.isArray( orderedColumns ) ? orderedColumns : void 0;
	}

	get orderedColumnIds() {
		const orderedColumns = this.orderedColumns;
		if ( !Validator.isArray( orderedColumns ) ) {
			return void 0;
		}
		const columnIds = [];
		for ( let column of orderedColumns ) {
			if ( !Validator.isObject( column ) ||
				!Validator.isPositiveInteger( column.id ) ) {
				continue;
			}
			columnIds.push( Number( column.id ) );
		}
		return columnIds.length > 0 ? columnIds : void 0;
	}

	get rtpRowHeight() {
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( idManager.rowHeight ) ?
			idManager.rowHeight : void 0;
	}

	get rtpRwh() {
		if ( !this.isRowTpl ) {
			return this._rtpRwh;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ?
			rtpRowHeight : this._rtpRwh;
	}

	set rtpRwh( newValue ) {
		this._rtpRwh = newValue;
	}

	/**
	 * gets & returns the row ID of this item, which corresponds to the xid of
	 * the model data row (MDataRow) assigned to this item and also to the
	 * itemId of the row template row (XtwRowTemplateRow) assigned to this item
	 * @return {Number} the row ID
	 * @see XtwModel.js~MDataRow
	 * @see XtwRtp.js~XtwRowTemplateRow
	 */
	get rowId() {
		return this.xid;
	}

	/**
	 * gets/returns the idr of this item, which corresponds with the idr of the
	 * model data row (MDataRow) corresponding/attached to this item
	 * @return {Number} idr this item's idr
	 * @see XtwModel.js~MDataRow
	 */
	get idr() {
		return [ "MDataRow", "MGroup", "MRowItem" ]
			.some( className => Validator.is( this.item, className ) ) ?
			Number( this.item.idr ) : void 0;
	}

	/**
	 * gets/returns the idt of this item, which corresponds with the idt of the
	 * model data row (MDataRow) corresponding/attached to this item
	 * @return {Number} idt this item's idt
	 * @see XtwModel.js~MDataRow
	 */
	get idt() {
		return [ "MDataRow", "MGroup", "MRowItem" ]
			.some( className => Validator.is( this.item, className ) ) ?
			Number( this.item.idt ) : void 0;
	}

	/**
	 * gets/returns the xid of this item, which corresponds with the xid of the
	 * model data row (MDataRow) corresponding/attached to this item
	 * @return {Number} xid this item's xid
	 * @see XtwModel.js~MDataRow
	 */
	get xid() {
		if ( [ "MDataRow", "MGroup", "MRowItem" ].some( className => Validator.is( this.item, className ) ) ) {
			if ( !this.item.alive || this.item.isUneditableDummyRow ) {
				return -1;
			}
			const res = this.item.xid;
			return Number( res );
		} else {
			return void 0;
		}
	}

	get flatIndex() {
		return [ "MDataRow", "MGroup", "MRowItem" ]
			.some( className => Validator.is( this.item, className ) ) ?
			Number( this.item.flatIndex ) : void 0;
	}

	/**
	 * gets & returns the selection manager of the table body, if present and
	 * valid
	 * @return {SelectionManager} the selection manager
	 * @see XtwRtpItm.js~SelectionManager
	 * @see XtwBody.js~XtwBody (the table body)
	 */
	get selectionManager() {
		const tableBody = this.tblBody;
		if ( !Validator.is( tableBody, "XtwBody" ) ) {
			return void 0;
		}
		const selectionManager = tableBody.selectionManager;
		return Validator.is( selectionManager, "SelectionManager" ) ?
			selectionManager : void 0;
	}

	get modelItemIsValid() {
		return this.isValidModelItem( this.item );
	}

	isValidModelItem( modelItem ) {
		return [ "MGroup", "MDataRow", "MRowItem" ]
			.some( className => Validator.is( modelItem, className ) );
	}

	/**
	 * returns the index of this item
	 * @returns {Number} the item index
	 */
	getIdx() {
		return this.idx;
	}

	/**
	 * @returns {Number} the width in pixels of the fixed part
	 */
	getWdtFix() {
		return this.wdtFix;
	}

	/**
	 * @returns {Number} the width in pixels of the dynamic part
	 */
	getWdtDyn() {
		return this.wdtDyn;
	}

	get clientRect() {
		if ( !this.isRendered ) {
			return void 0;
		}
		return this.element.getBoundingClientRect();
	}

	get fixedContainerClientRect() {
		if ( !( this.ccnFix instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.ccnFix.getBoundingClientRect();
	}

	get clientHeight() {
		const clientRect = this.clientRect;
		if ( !( clientRect instanceof DOMRect ) ) {
			return void 0;
		}
		const height = clientRect.height;
		return Validator.isValidNumber( height ) ? height : void 0;
	}

	get clientY() {
		const clientRect = this.clientRect;
		if ( !( clientRect instanceof DOMRect ) ) {
			return void 0;
		}
		const y = clientRect.y;
		return Validator.isValidNumber( y ) ? y : void 0;
	}

	/**
	 * sends a notification to the web server through the table body (XtwBody)
	 * corresponding/hosting this item, if the table body (XtwBody) exists, is
	 * valid and is able to send notifications
	 * @param {String} notificationCode notification code
	 * @param {Object} parameters notification parameters
	 * @param {Boolean} blockScreenRequest flag whether to force a block screen
	 * request
	 * @return {Boolean} the success of the operation; true if the operation
	 * was successfull and "_nfySrv" could be invoked, false otherwise
	 * @see XtwBody.js~XtwBody~_nfySrv
	 */
	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		const tableBody = this.tblBody;
		if ( !Validator.is( tableBody, "XtwBody" ) ||
			!Validator.isFunction( tableBody._nfySrv ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		if ( "idr" in parameters ) {
			delete parameters.idr;
		}
		Object.assign( parameters, {
			idr: this.idr,
			idt: this.idt,
			xid: this.xid,
			rowId: this.rowId,
			idx: this.idx
		} );
		tableBody._nfySrv( notificationCode, parameters, !!blockScreenRequest );
		return true;
	}

	/**
	 * notifies the web server about the current selection & focus through the
	 * selection manager (SelectionManager)
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	notifySelection() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.informAboutRowSelection ) ) {
			return false;
		}
		selectionManager.informAboutRowSelection();
		return true;
	}

	/**
	 * makes sure that the information about current focus and selection is in
	 * sync with (is the same as) the information from the selection manager
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncSelectionManager( alsoSyncFocus = true ) {
		if ( this.isSelected ) {
			this.syncSelect( false, true );
		} else if ( this.isFocused ) {
			this.syncDeselect( false, true );
			this.syncFocus( false );
		} else {
			this.syncDeselect( false, true );
			this.syncUnfocus( false );
		}
	}

	/**
	 * selects this item
	 */
	select() {
		this.isSelected = true;
	}

	/**
	 * removes the selection from this item
	 */
	deselect() {
		this.isSelected = false;
	}

	/**
	 * selects this row through the selection manager (SelectionManager),
	 * so that the information about current focus and selection is in sync with
	 * the states of this (and other) item(s); selecting the row through the
	 * selection manager automatically focuses the row;
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the selection change
	 * @param {Boolean} controlPressed whether or not the selection manager
	 * should act as if the control key is pressed
	 * @param {Boolean} shiftPressed whether or not the selection manager should
	 * act as if the shift key is pressed
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncSelect( notify = false, controlPressed = false, shiftPressed = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.select ) ) {
			return false;
		}
		selectionManager.select( {
			row: this,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * deselects this row through the selection manager (SelectionManager),
	 * so that the information about current focus and selection is in sync with
	 * the states of this (and other) item(s); deselecting the row through the
	 * selection manager automatically focuses the row;
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the selection change
	 * @param {Boolean} controlPressed whether or not the selection manager
	 * should act as if the control key is pressed
	 * @param {Boolean} shiftPressed whether or not the selection manager should
	 * act as if the shift key is pressed
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncDeselect( notify = false, controlPressed = false, shiftPressed = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.deselect ) ) {
			return false;
		}
		selectionManager.deselect( {
			row: this,
			controlPressed: !!controlPressed,
			shiftPressed: !!shiftPressed
		} );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * gets the status of the "_isSelected" property of the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * is valid
	 * @see XtwModel.js~MDataRow
	 * @return {Boolean} true if item selected, false if the model data row
	 * (MDataRow) is invalid or if the item is not selected
	 */
	get isSelected() {
		const mDataRow = this.item;
		if ( !Validator.is( mDataRow, "MDataRow" ) ||
			!( "isSelected" in mDataRow ) ) {
			return false;
		}
		return mDataRow.isSelected;
	}

	set canUpdateSelectionUi( newValue ) {
		if ( !Validator.isBoolean( newValue ) ) {
			return;
		}
		this._canUpdateSelectionUi = newValue;
	}

	get canUpdateSelectionUi() {
		return this.isRendered && !!this._canUpdateSelectionUi;
	}

	freezeSelectionUiUpdates() {
		this.canUpdateSelectionUi = false;
	}

	reviveSelectionUiUpdates() {
		this.canUpdateSelectionUi = true;
	}

	/**
	 * sets the status of the "_isSelected" property on the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow) is valid and if the updating of the "_isSelected" state on
	 * the corresponding model data row (MDataRow) is not frozen/blocked;
	 * also updates this item element's UI in case the element is rendered
	 * @see XtwModel.js~MDataRow
	 */
	set isSelected( newValue ) {
		const mDataRow = this.item;
		if ( !Validator.is( mDataRow, "MDataRow" ) ||
			!( "isSelected" in mDataRow ) ) {
			return;
		}
		const real_value = newValue && !mDataRow.isUneditableDummyRow;
		mDataRow.isSelected = real_value;
		this._updateSelectedUI( real_value );
	}

	/**
	 * updates the UI of this item's HTML element (if such exists a.k.a. is
	 * rendered) to match it's selected/unselected state
	 * @param {Boolean} setToFocused decides whether the UI should be updated
	 * for a selected element or for an unselected element; true if the UI needs
	 * to be updated to selected, false otherwise; if the value of this parameter
	 * is invalid, it will be replaced with the actual value of the "isSelected"
	 * state/flag of this item;
	 * @return {Boolean} the success of the operation; true if the update of the
	 * UI was successfull, false otherwise
	 */
	_updateSelectedUI( setToSelected ) {
		if ( !this.isRendered || !this.canUpdateSelectionUi ) {
			return false;
		}
		if ( !Validator.isBoolean( setToSelected ) ) {
			setToSelected = this.isSelected;
		}
		if ( !!setToSelected ) {
			this.element.classList.add( "rtp-selected" );
		} else {
			this.element.classList.remove( "rtp-selected" );
		}
		return true;
	}

	get selectionCell() {
		if ( !Validator.is( this.cells, "ObjReg" ) ) {
			return void 0;
		}
		let xCellItem = this.cells.getItem( "o-1" );
		if ( Validator.is( xCellItem, "XCellItem" ) ) {
			return xCellItem;
		}
		xCellItem = this.cells.getObj( "o-1" );
		if ( Validator.is( xCellItem, "XCellItem" ) ) {
			return xCellItem;
		}
		xCellItem = this.cells.getFirst();
		return Validator.is( xCellItem, "XCellItem" ) ? xCellItem : void 0;
	}

	_addSelectionArrow() {
		const selectionCell = this.selectionCell;
		if ( !Validator.is( selectionCell, "XCellItem" ) ) {
			return false;
		}
		return selectionCell.addSelectionArrow();
	}

	_removeSelectionArrow() {
		const selectionCell = this.selectionCell;
		if ( !Validator.is( selectionCell, "XCellItem" ) ) {
			return false;
		}
		return selectionCell.removeSelectionArrow();
	}

	/**
	 * focuses this item
	 */
	focus() {
		this.isFocused = true;
	}

	/**
	 * removes the focus from this item
	 */
	unfocus() {
		this.isFocused = false;
	}

	/**
	 * focuses this row through the selection manager (SelectionManager),
	 * so that the information about current focus is in sync with the states of
	 * this (and other) item(s)
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the focus change
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncFocus( notify = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.focus ) ) {
			return false;
		}
		selectionManager.focus( this );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * unfocuses this row through the selection manager (SelectionManager),
	 * so that the information about current focus is in sync with the states of
	 * this (and other) item(s)
	 * @param {Boolean} notify tells the selection manager whether or not to
	 * notify the web server about the focus change
	 * @return {Boolean} the success of the operation; true if everything went
	 * as planned, false otherwise
	 * @see XtwRtpItm.js~SelectionManager
	 */
	syncUnfocus( notify = false ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.unfocus ) ) {
			return false;
		}
		selectionManager.unfocus( this );
		return !!notify ? this.notifySelection() : true;
	}

	/**
	 * gets the status of the "_isFocused" property of the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * is valid
	 * @see XtwModel.js~MDataRow
	 * @return {Boolean} true if item focused, false if the model data row
	 * (MDataRow) is invalid or if the item is not focused
	 */
	get isFocused() {
		const mDataRow = this.item;
		if ( !Validator.is( mDataRow, "MDataRow" ) ||
			!( "isFocused" in mDataRow ) ) {
			return false;
		}
		return mDataRow.isFocused;
	}

	/**
	 * sets the status of the "_isFocused" property on the model data row
	 * (MDataRow) corresponding to this item, only if the model data row
	 * (MDataRow) is valid and if the updating of the "_isFocused" state on
	 * the corresponding model data row (MDataRow) is not frozen/blocked;
	 * also updates this item element's UI in case the element is rendered
	 * @see XtwModel.js~MDataRow
	 */
	set isFocused( newValue ) {
		const mDataRow = this.item;
		if ( !Validator.is( mDataRow, "MDataRow" ) ||
			!( "isFocused" in mDataRow ) ) {
			return;
		}
		const real_value = newValue && !mDataRow.isUneditableDummyRow;
		mDataRow.isFocused = real_value;
		this._updateFocusedUI( real_value );
	}

	get noSelectionAllowed() {
		if ( !Validator.is( this.tblBody, "XtwBody" ) ||
			!( "noSelectionAllowed" in this.tblBody ) ) {
			return void 0;
		}
		return this.tblBody.noSelectionAllowed;
	}

	/**
	 * updates the UI of this item's HTML element (if such exists a.k.a. is
	 * rendered) to match it's focused/unfocused state
	 * @param {Boolean} setToFocused decides whether the UI should be updated
	 * for a focused element or for an unfocused element; true if the UI needs
	 * to be updated to focused, false otherwise; if the value of this parameter
	 * is invalid, it will be replaced with the actual value of the "isFocused"
	 * state/flag of this item;
	 * @return {Boolean} the success of the operation; true if the update of the
	 * UI was successfull, false otherwise
	 */
	_updateFocusedUI( setToFocused ) {
		if ( !Validator.isBoolean( setToFocused ) ) {
			setToFocused = this.isFocused;
		}
		if ( Validator.isObject( this.tblBody ) &&
			Validator.isFunction( this.tblBody._updateFocusedUI ) ) {
			this.tblBody._updateFocusedUI( setToFocused );
		}
		if ( !this.isRendered ) {
			return false;
		}
		this._removeSelectionArrow();
		if ( !this.isValidHeight || !this.hasChildren ) {
			this.element.classList.remove( "rtp-focused" );
			Warner.traceIf( DO_LOG, `The row whose focus should be updated is` +
				` represented by an invalid DOM element. The row DOM element's UI` +
				` will not be updated to match it's "focused" state.` );
			return false;
		}
		if ( this.edited ) {
			return this._updateEditedFocusedUI( setToFocused );
		}
		if ( this.insertionDummy ) {
			return this._updateInsertionDummyFocusedUI( setToFocused );
		}
		if ( !!setToFocused ) {
			this.element.classList.add( "rtp-focused" );
			this._addSelectionArrow();
			return true;
		}
		this.element.classList.remove( "rtp-focused" );
		return true;
	}

	get isValidHeight() {
		const height = this.clientHeight;
		return Validator.isPositiveNumber( height, false );
	}

	get hasChildren() {
		if ( !this.isRendered ) {
			return false;
		}
		const allLevelChildren = HtmHelper.getAllLevelChildren( this.element );
		return allLevelChildren.length > 0;
	}

	selectAndFocusRow( evt ) {
		if ( !Validator.couldBe( evt, "Event" ) ) {
			return false;
		}
		let isSelected = this.isSelected; // get the value of the "selected" state
		let isFocused = this.isFocused; // get the value of the "focused" state
		if ( !isSelected ) {
			this.syncSelect( true );
		} else if ( !isFocused ) {
			this.syncFocus( true );
		}
		isSelected = this.isSelected; // update the value of the "selected" state
		isFocused = this.isFocused; // update the value of the "focused" state
		const selectedAndFocused = isSelected && isFocused;
		if ( selectedAndFocused ) {
			return true;
		}
		const onlyOneIsTrue = isSelected != isFocused;
		Warner._traceIf( `The row with the ID "${ this.itemId }" is` +
			`${ ( isSelected ? "" : " not" ) } selected` +
			` ${ ( onlyOneIsTrue ? "but" : "and" ) } is` +
			`${ ( isFocused ? "" : " not" ) } focused.`, DO_LOG );
		return false;
	}

	/**
	 * @override
	 * handles selection & focus when this item's element is "clicked"
	 * @param {MouseEvent} evt the click event
	 * @param {Object} parmeters click parameters, in case the event comes from
	 * another DOM element
	 */
	onClick( evt, parameters = void 0 ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.clickRow ) ) {
			return;
		}
		const rowId = this.rowId;
		const colId = this._getColumnIdFromEvent( evt );
		try {
			if ( !Validator.isValidNumber( rowId ) ) {
				return;
			}
			if ( !( evt instanceof MouseEvent ) &&
				!( evt instanceof KeyboardEvent ) ) {
				return selectionManager.clickRow( { rowId: rowId } );
			}
			evt.stopPropagation();
			evt.preventDefault();
			const shiftPressed = "transferredFromCellContainer" in evt &&
				!!evt.transferredFromCellContainer ? false : !!evt.shiftKey;
			selectionManager.clickRow( {
				rowId: rowId,
				colId: colId,
				controlPressed: !!XtwUtils.isCommandKeyPressed( evt ),
				shiftPressed: shiftPressed,
				forceSelectIfSimpleClick: true
			} );
		} finally {
			const par = { idc: colId, idr: rowId };
			this._nfySrv( 'cellClicked', par, false );
		}
	}

	_getColumnIdFromEvent( evt ) {
		let idc = -1000;
		if ( evt instanceof MouseEvent ) {
			const re = this.getDomElement();
			let tgt = evt.target;
			// while ( ( idc === -1000 ) && tgt && pisasales.isChildOf( tgt, re ) ) {
			while ( ( idc === -1000 ) && tgt && PISASALES.isChildOf( tgt, re ) ) {
				if ( typeof tgt.__psaidc === 'number' ) {
					idc = tgt.__psaidc;
					tgt = null;
				} else {
					tgt = tgt.parentElement;
				}
			}
		}
		if ( idc < 0 ) {
			idc = -1;
		}
		return idc;
	}

	/**
	 * called after a horizontal scroll operation was performed by the user
	 * @param {Number} hsp new horizontal scrolling position
	 */
	onHScroll( hsp ) {
		Warner.traceIf( DO_LOG );
		if ( this.ccnDyn ) {
			const left = -hsp;
			this.ccnDyn.style.left = '' + left + 'px';
		}
	}

	/**
	 * called after the user has changed a column width by dragging the column
	 * border in the table/excel view/display
	 * @param {XtwCol} column the affected column
	 * @param {Number} newWidth new column width
	 * @param {Number} widthDifference the difference of the width
	 * @return {Boolean} whether or not the process was carried out successfully
	 * (as requested/intended)
	 */
	onColumnWidth( column, newWidth, widthDifference ) {
		if ( !this.isRendered || this.isRowTpl ) {
			return false;
		}
		if ( !Validator.is( column, "XtwCol" ) ||
			!Validator.isPositiveNumber( newWidth ) ||
			!Validator.isValidNumber( widthDifference, false ) ||
			!Validator.is( this.cells, "ObjReg" ) ) {
			return false;
		}
		const xCellItem = this.cells.getObj( column.id );
		if ( !Validator.is( xCellItem, "XCellItem" ) ) {
			return false;
		}
		xCellItem.setWidth( newWidth );
		// if ( !Validator.isFunction( window.pisasales.getItmMgr ) ) {
		// 	return false;
		// }
		// const itemManager = window.pisasales.getItmMgr();
		const itemManager = ITEM_MANAGER;
		if ( !Validator.is( itemManager, "ItmMgr" ) ) {
			return false;
		}
		if ( !!column.fix ) {
			this.wdtFix += widthDifference;
			itemManager.setFlexWdt( this.ccnFix, this.wdtFix, true );
			XtwUtils.syncZeroWidthClass( this.ccnFix, this.wdtFix );
		} else {
			this.wdtDyn += widthDifference;
			itemManager.setFlexWdt( this.scrDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.scrDyn, this.wdtDyn );
			itemManager.setFlexWdt( this.ccnDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.ccnDyn, this.wdtDyn );
		}
		return true;
	}

	getSiblingElement( previous = false ) {
		if ( !this.isRendered ) {
			return void 0;
		}
		const siblingProperty = !!previous ? "previousElementSibling" :
			"nextElementSibling";
		let sibling = this.element[ siblingProperty ];
		while ( sibling instanceof HTMLElement &&
			sibling.classList.contains( GROUP_HEADER_CLASS ) ) {
			sibling = sibling[ siblingProperty ];
		}
		return sibling instanceof HTMLElement ? sibling : void 0;
	}

	onSelectAll( evt ) {
		if ( evt instanceof KeyboardEvent ) {
			evt.stopPropagation();
			evt.preventDefault();
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.selectAllModelItems ) ) {
			return false;
		}
		return selectionManager.selectAllModelItems( this, true );
	}

	get widgetVerticalSelection() {
		if ( !Validator.is( this.tblBody, "XtwBody" ) ||
			!Validator.is( this.tblBody.xtdTbl, "XtwTbl" ) ||
			!Validator.isObject( this.tblBody.xtdTbl.wdgSlv ) ) {
			return void 0;
		}
		return this.tblBody.xtdTbl.wdgSlv;
	}

	/**
	 * moves the element of the first cell before the element of the second cell
	 * and changes/adjusts the fixed and dynamic cell containers' widths in the
	 * case/scenario when the cell element is moved from a fixed container to a
	 * dynamic one or viceversa
	 * @param {Number} firstCellId the ID of the first cell (the one that should
	 * be moved and should change its place), as a natural number (positive, whole)
	 * @param {Number} secondCellId the ID of the second cell (the one that keeps
	 * its place), as a natural number (positive, whole)
	 * @param {Number} fixedWidthDifference the amount (positive or negative) the
	 * fixed container should increase (or decrease) its width by; will not be
	 * taken into consideration if "ignoreFixedFlag" has the value "true"
	 * @param {Number} dynamicWidthDifference the amount (positive or negative)
	 * the dynamic container should increase (or decrease) its width by; will not
	 * be taken into consideration if "ignoreFixedFlag" has the value "true"
	 * @param {Boolean} ignoreFixedFlag whether or not the "fix" (fixed) property
	 * of the columns the cells are attached/correspond to should be ignored, so
	 * that the fixed and dynamic container do not change their widths; true if
	 * the fixed property should be ignored, false if it should be taken into
	 * consideration; logically it should be set to true if and only if both
	 * cells come from the same type of container (both come from a fixed
	 * container or both come from a dynamic container), so the "fix" property
	 * has the same value for both cells, which means the containers' widths do
	 * not need to be adjusted
	 * @return {Boolean} true if the movement was successfull, false otherwise
	 */
	moveFirstCellBeforeSecond( {
		firstCellId,
		secondCellId,
		fixedWidthDifference = 0,
		dynamicWidthDifference = 0,
		ignoreFixedFlag = false
	} ) {
		if ( [ firstCellId, secondCellId ].some( cellId =>
				!Validator.isPositiveInteger( cellId ) ) ||
			firstCellId === secondCellId ||
			!Validator.is( this.cells, "ObjReg" ) ) {
			return false;
		}
		const firstCell = this.cells.getObj( firstCellId );
		if ( !Validator.is( firstCell, "XCellItem" ) ||
			!firstCell.isRendered ) {
			return false;
		}
		const secondCell = this.cells.getObj( secondCellId );
		if ( !Validator.is( secondCell, "XCellItem" ) ||
			!secondCell.isRendered ||
			!( secondCell.element.parentElement instanceof HTMLElement ) ) {
			return false;
		}
		secondCell.element.parentElement
			.insertBefore( firstCell.element, secondCell.element );
		if ( !!ignoreFixedFlag ) {
			return true;
		}
		// const itemManager = window.pisasales.getItmMgr();
		const itemManager = ITEM_MANAGER;
		if ( !Validator.is( itemManager, "ItmMgr" ) ) {
			return false;
		}
		if ( Validator.isValidNumber( fixedWidthDifference ) ) {
			this.wdtFix += fixedWidthDifference;
			itemManager.setFlexWdt( this.ccnFix, this.wdtFix, true );
			XtwUtils.syncZeroWidthClass( this.ccnFix, this.wdtFix );
		}
		if ( Validator.isValidNumber( dynamicWidthDifference ) ) {
			this.wdtDyn += dynamicWidthDifference;
			itemManager.setFlexWdt( this.ccnDyn, this.wdtDyn, true );
			XtwUtils.syncZeroWidthClass( this.ccnDyn, this.wdtDyn );
		}
		return true;
	}

	/**
	 * sets a new model item
	 * @param {XtwHdr} tableHeaderWidget the table header widget
	 * @param {MRowItem} modelItem the new model item
	 */
	setModelItem( tableHeaderWidget, modelItem, updateSelectionUi = true ) {
		const oldModelItem = this.item;
		const xRowItemElement = this.getDomElement();
		xRowItemElement.classList.remove( 'rtp-selected' );
		const modelItemIsValid = this.isValidModelItem( modelItem );
		if ( !modelItemIsValid || ( this.rtpMode && modelItem.isDummyRow() ) ) {
			return this._setInvalidModelItem( modelItem !== null && !modelItemIsValid );
		}
		if ( Validator.isObject( oldModelItem ) &&
			( oldModelItem.isDataRow() !== modelItem.isDataRow() ) ) {
			// force full re-create
			this._dropCont( true, true );
		}
		xRowItemElement.__mi = modelItem;
		this.item = modelItem;
		if ( xRowItemElement.dataset instanceof DOMStringMap ) {
			xRowItemElement.dataset.xrowinfo = 'xrowinfo-r' + modelItem.getRowID() + '-x' + modelItem.getExtraID();
		}
		if ( modelItem.isDataRow() ) {
			return this._setDataRowModelItem( tableHeaderWidget, modelItem, updateSelectionUi );
		}
		if ( modelItem.isGroupHead() ) {
			return this._setGroupHeaderModelItem( tableHeaderWidget, modelItem, updateSelectionUi );
		}
		this._setUnknownModelItem();
	}

	/**
	 * re-builds the row item due to changes in the table (columns etc.)
	 * @param {XtwHdr} tableHeaderWidget the table header widget
	 */
	rebuild( tableHeaderWidget ) {
		const mi = this.item;
		this._dropCont( true, true );
		this.item = null;
		this.setModelItem( tableHeaderWidget, mi );
	}

	_setInvalidModelItem( warn ) {
		const xRowItemElement = this.getDomElement();
		this.item = null;
		this._dropCont( true, true );
		if ( xRowItemElement instanceof HTMLElement ) {
			xRowItemElement.__mi = null;
			xRowItemElement.innerHTML = '';
			xRowItemElement.dataset.xrowinfo = '';
		}
		Warner.traceIf( warn && DO_LOG, `The row item with the idx ${ this.idx } has an invalid model item.` );
		this.ccnFix = null;
		this.ccnDyn = null;
		this._updateFocusedUI( false );
		this._updateSelectedUI( false );
		return;
	}

	_setDataRowModelItem( tableHeaderWidget, modelItem, updateSelectionUi = true ) {
		const xRowItemElement = this.getDomElement();
		if ( xRowItemElement instanceof HTMLElement ) {
			xRowItemElement.classList.remove( GROUP_HEADER_CLASS );
			if ( this.rtpMode ) {
				this._adjustRowItemHeight( xRowItemElement, modelItem );
				xRowItemElement.classList.add( 'xtwrtprowitem' );
			} else {
				xRowItemElement.classList.remove( 'xtwrtprowitem' );
			}
		}
		this._setupDataRow( tableHeaderWidget );
		Validator.isFunction( modelItem.syncSelectionManager ) ?
			modelItem.syncSelectionManager( updateSelectionUi ) :
			this.syncSelectionManager( updateSelectionUi );
		// this.syncSelectionManager();
	}

	_adjustRowItemHeight( xRowItemElement, modelItem ) {
		if ( !this.rtpMode || !( xRowItemElement instanceof HTMLElement ) ||
			!Validator.isObject( modelItem ) ||
			!Validator.isFunction( modelItem.getHeight ) ||
			!Validator.isFunction( modelItem.getVPadding ) ) {
			return false;
		}
		const newHeight = modelItem.getHeight() + modelItem.getVPadding();
		if ( !Validator.isValidNumber( newHeight ) ) {
			return false;
		}
		const cssHeight = '' + newHeight + 'px';
		xRowItemElement.style.height = cssHeight;
		xRowItemElement.style.maxHeight = cssHeight;
		xRowItemElement.style.minHeight = cssHeight;
		return true;
	}

	_setUnknownModelItem() {
		// ?! - what ever it is, we'll ignore it :-)
		console.warn( '#' + this.idx + ': Got an UNKNOWN model item!' );
		console.warn( this.item );
		this._updateFocusedUI( false );
		this._updateSelectedUI( false );
	}

	/**
	 * creates / updates a data row
	 * @param {XtwHdr} xth the table header widget
	 */
	_setupDataRow( xth ) {
		// make sure that the required containers exist
		const rtp = this.rtpMode;
		this._creCont( xth, true, !rtp );
		if ( rtp ) {
			// create / update row template data row
			this._setupRtpRow( xth );
		} else {
			// create / update common data row
			this._setupCommonRow( xth );
		}
	}

	/**
	 * creates / updates a common data row
	 * @param {XtwHdr} xth the table header widget
	 */
	_setupCommonRow( xth ) {
		if ( this.cells.isEmpty() ) {
			// create UI cells first
			if ( Validator.isObject( xth ) && Validator.isFunction( xth.getColumns ) ) {
				const cols = xth.getColumns( true );
				this._setupCommonCells( cols );
			}
		}
		if ( this.item.isPresent() ) {
			// the model item has data!
			const modelItem = this.item;
			this.cells.forEach( ( xc ) => {
				if ( xc.idc !== -1 ) {
					const mc = modelItem.getCell( xc.idc );
					xc.setCtt( ( mc != null ? mc.getCtt() : null ) || CellCtt.EMPTY_CONTENT );
				}
			} );
			modelItem.syncEditedUi();
		}
		if ( this.insertionDummy ) {
			this.displayAsInsertionDummy();
		}
	}

	/**
	 * creates a common data row
	 * @param {Array<XtwCol>} cols array of table columns
	 */
	_setupCommonCells( cols ) {
		const brc = this.tblBody.clrVtg || null;
		let fxw = 0;
		let dnw = 0;
		const self = this;
		Object.values( cols ).forEach( ( cc ) => {
			cc.forEach( ( col ) => {
				if ( col.available ) {
					const cell = new XCellItem( col, self, brc );
					self.cells.addObj( cell.idc, cell );
					const width = col.visible ? cell.getWidth() : 0;
					if ( col.fix ) {
						self.ccnFix.appendChild( cell.getDomElement() );
						fxw += width;
					} else {
						self.ccnDyn.appendChild( cell.getDomElement() );
						dnw += width;
					}
				}
			} );
		} );
		this.wdtFix = fxw;
		this.wdtDyn = dnw;
		// const im = pisasales.getItmMgr();
		const im = ITEM_MANAGER;
		im.setFlexWdt( this.ccnFix, fxw, true );
		XtwUtils.syncZeroWidthClass( this.ccnFix, fxw );
		im.setFlexWdt( this.scrDyn, dnw, true );
		XtwUtils.syncZeroWidthClass( this.scrDyn, dnw );
		im.setFlexWdt( this.ccnDyn, dnw, true );
		XtwUtils.syncZeroWidthClass( this.ccnDyn, dnw );
	}

	/**
	 * creates / updates a row template row
	 * @param {XtwHdr} xth the table header widget
	 */
	_setupRtpRow( xth ) {
		const tpl = this.getRowTpl;
		if ( tpl ) {
			// if ( this.rtpRow ) {
			// 	this.rtpRow.destroy();
			// 	delete this.rtpRow;
			// }
			if ( !this.rtpRow ) {
				const cols = xth.getColumns( true );
				this._setupRtpCells( tpl, cols );
			}
			if ( this.item.isPresent() ) {
				const new_rtp = !!tpl.rtpNew;
				const row = this.rtpRow;
				row.reset();
				const modelItem = this.item;
				const cells = modelItem.getCells();
				cells.forEach( ( mc ) => {
					row.updateCellContent( mc, new_rtp );
				} );
			}
		}
	}

	/**
	 * creates / updates a row template row
	 * @param {Object} tpl thr row template descriptor
	 * @param {Array<XtwCol>} cols array of table columns
	 */
	_setupRtpCells( tpl, cols ) {
		// const rcols = pisasales.creObjReg();
		const rcols = PISASALES.creObjReg();
		Object.values( cols ).forEach( ( cc ) => {
			cc.forEach( ( col ) => {
				if ( !col.select ) {
					rcols.addObj( col.id, col );
				}
			} );
		} );
		const args = {};
		args.rtp = tpl;
		args.cols = rcols;
		args.item = this.item;
		const row = new XtwRowTemplateRow( this, args );
		this.rtpRow = row;
		row.render();
	}

	/**
	 * creates the cell container elements if required
	 * @param {XtwHdr} xth the table header widget
	 * @param {Boolean} fix true to create the fixed cell container
	 * @param {Boolean} dyn true to create the dynamic cell container
	 */
	_creCont( xth, fix, dyn ) {
		if ( fix && !this.ccnFix ) {
			this.ccnFix = this._creContElm();
			this.getDomElement().appendChild( this.ccnFix );
		}
		if ( dyn && !this.ccnDyn ) {
			this.scrDyn = document.createElement( 'div' );
			this.scrDyn.className = 'xtwrowscroll';
			this.ccnDyn = this._creContElm();
			this.scrDyn.appendChild( this.ccnDyn );
			this.getDomElement().appendChild( this.scrDyn );
		}
	}

	/**
	 * creates a cell container element
	 * @returns {HTMLElement} the container element
	 */
	_creContElm() {
		const ce = document.createElement( 'div' );
		ce.className = 'xtwcellcont';
		return ce;
	}

	/**
	 * drops cell containers
	 * @param {Boolean} fix true to drop the fixed cell container
	 * @param {Boolean} dyn true to drop the dynamic cell container
	 */
	_dropCont( fix, dyn ) {
		if ( this.btnGcl ) {
			// pisasales.rmvDomElm( this.btnGcl );
			PISASALES.rmvDomElm( this.btnGcl );
			this.btnGcl = null;
		}
		if ( fix || dyn ) {
			if ( this.rtpRow ) {
				this.rtpRow.destroy();
				this.rtpRow = null;
			}
			this.cells.dstChl();
			this.wdtFix = 0;
			this.wdtDyn = 0;
		}
		if ( fix && this.ccnFix ) {
			// pisasales.rmvDomElm( this.ccnFix );
			PISASALES.rmvDomElm( this.ccnFix );
			this.ccnFix = null;
		}
		if ( dyn && this.ccnDyn ) {
			// pisasales.rmvDomElm( this.ccnDyn );
			PISASALES.rmvDomElm( this.ccnDyn );
			// pisasales.rmvDomElm( this.scrDyn );
			PISASALES.rmvDomElm( this.scrDyn );
			this.scrDyn = null;
			this.ccnDyn = null;
		}
	}

}
