import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import WdgInfo from '../../utils/WdgInfo';

/**
 * class SepLine - a special separator line that supports a marker
 * @NoMergeFrom({"8.0"})
 */
export default class SepLine {

    /**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
     constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ 'layout', 'onReady', 'onRender', 'onElementsResized' ]);
        this.observer = new ResizeObserver(this.onElementsResized);
        this.wdgMap = new Map();
		this.ready = false;
        this.element = null;
        this.marker = null;
        this.offset = 0;
        this.correction = 0;
        this.props = {};
        this.pendingWidgets = [];
        this.pendingMarker = null;
        this.parent = rap.getObject(properties.parent);
		rap.on('render', this.onRender);
    }

    /**
     * destroys this instance
     */
     destroy() {
        rap.off('render', this.onRender);
        this.observer.disconnect();
        this.wdgMap.clear();
        delete this.observer;
        delete this.wdgMap;
        delete this.pendingWidgets;
        delete this.pendingMarker;
        delete this.marker;
        delete this.element;
        delete this.props;
        delete this.ready;
        delete this.parent;
	}

    /**
     * finishes first-time initialization
     */
     onReady() {
        // get the DOM element
        this.element = this.parent.$el.get(0);
        // create the marker
        const m = document.createElement('div');
        m.style.position = 'absolute';
        m.style.top = '0px';
        m.style.display = 'none';
        this.element.appendChild(m);
        this.marker = m;
        // set "ready" flag
		this.ready = this.element && this.marker;
        // apply styles
        this._applyCss();
		// update the layout
		this.parent.addListener('Resize', this.layout);
		this.layout();
	}
	
    /**
     * called in "render" phase; processes pending requests
     */
     onRender() {
        if ( !this.ready && this.parent ) {
            this.onReady();
        }
        if ( this.ready && (this.pendingWidgets.length > 0) ) {
            const ids = this.pendingWidgets;
            this.pendingWidgets = [];
            this._addWidgets(ids);
        }
        if ( this.ready && this.pendingMarker ) {
            const args = this.pendingMarker;
            this.pendingMarker = null;
            const self = this;
            window.requestAnimationFrame( () => {
                self._setMarker(args);
            } );
        }
	}

    /**
     * updates the inner layout
     */
     layout() {
        if ( this.ready ) {
            const area = this.parent.getClientArea();
            const hgt = area[3];
            this.marker.style.height = '' + hgt + 'px';
        }
	}

    /**
     * callback for the resize observer
     * @param {ResizeObserverEntry[]} entries observed entries
     */
     onElementsResized(entries) {
        for ( let entry of entries ) {
            const element = entry.target;
            const idw = element.__psa_id || '';
            if ( this._psa.isStr(idw) ) {
                const info = this.wdgMap.get(idw);
                if ( info instanceof WdgInfo ) {
                    info.setBounds(entry.contentRect);
                }
            }
        }
    }

    /**
     * sets CSS properties
     * @param {*} args argument object providing CSS properties
     */
    setCss(args) {
        const props = this.props;
        props.bkgvar = args.bkgvar || null;
        props.bkgdef = args.bkgdef || null;
        props.mrkvar = args.mrkvar || null;
        props.mrkdef = args.mrkdef || null;
        if ( this.ready ) {
            this._applyCss();
        }
    }

    /**
     * sets the over all offset
     * @param {Number} offs over all offset
     */
    setOffset(offs) {
        if ( typeof offs === 'number' ) {
            this.offset = offs;
        }
    }

    /**
     * sets the left offset and width correction
     * @param {Number} corr left offset and width correction
     */
    setCorrection(corr) {
        if ( typeof corr === 'number' ) {
            this.correction = corr;
        }
    }

    /**
     * sets the marker to the specified widget
     * @param {*} args arguments; provides the widget ID
     */
     setMarker(args) {
        const idw = args.idw || '';
        const rect = args.rect || {};
        if ( this._psa.isStr(idw) ) {
            this.pendingMarker = { idw: idw, rect: rect };
        }
    }

    /**
     * hides the marker
     * @param {*} args arguments; ignored
     */
     hideMarker(args) {
        if ( this.ready ) {
            this.marker.style.display = 'none';
        }
    }

    /**
     * adds a widget to be observed
     * @param {*} args arguments; provides the widget ID
     */
     addWidget(args) {
        const idw = args.idw || '';
        if ( this._psa.isStr(idw) ) {
            this.pendingWidgets.push(idw);
        }
    }

    /**
     * removes a widget
     * @param {*} args arguments; provides the widget ID
     */
    rmvWidget(args) {
        if ( !this.ready ) {
            return;
        }
        const idw = args.idw || '';
        if ( this._psa.isStr(idw) ) {
            const info = this.wdgMap.get(idw);
            if ( info instanceof WdgInfo ) {
                this.observer.unobserve(info.element);
                this.wdgMap.delete(idw);
            }
            // we do not complain about missing elements - sometimes they've never been rendered...
        }
    }

    /**
     * applies CSS values
     */
     _applyCss() {
        const im = ItmMgr.getInst();
        const props = this.props;
        this._setCssVar(im, this.element, props.bkgvar, props.bkgdef);
        this._setCssVar(im, this.marker, props.mrkvar, props.mrkdef);
    }

    /**
     * sets CSS values
     * @param {ItmMgr} im item manager instance
     * @param {HTMLElement} elm the target element
     * @param {String} bkgvar CSS variable for background color
     * @param {*} bkgdef default background color
     */
    _setCssVar(im, elm, bkgvar, bkgdef) {
        const has_var = this._psa.isStr(bkgvar);
        if ( has_var || bkgdef ) {
            let bgs = '';
            const rgb = im.getRgb(bkgdef);
            if ( has_var ) {
                bgs = 'var(--pisa-' + bkgvar;
                if ( rgb ) {
                    bgs += ',' + rgb;
                }
                bgs += ')'
            } else {
                bgs = rgb;
            }
            elm.style.backgroundImage='';
            elm.style.background = '';
            elm.style.backgroundColor = bgs;
        }
    }

    /**
     * adds new widgets
     * @param {String[]} ids widget IDs
     */
     _addWidgets(ids) {
        for ( let idw of ids ) {
            if ( !this.wdgMap.has(idw) ) {
                const wdg = rwt.remote.ObjectRegistry.getObject(idw);
                if ( wdg && (wdg._element instanceof HTMLElement) ) {
                    const element = wdg._element;
                    element.__psa_id = idw;
                    const info = new WdgInfo(idw, element);
                    this.wdgMap.set(idw, info);
                    this.observer.observe(info.element, { box: 'border-box' } );
                }
                // we do not complain about missing widgets - sometimes the're already disposed
            } else {
                console.warn(`Widget "${idw}" already present! Ignoring the new request.`);
            }
        }
    }

    /**
     * sets the marker
     * @param {Object} args arguments
     */
    _setMarker(args) {
        const idw = args.idw || '';
        const rect = args.rect || {};
        if ( this._psa.isStr(idw) ) {
            const wdg = rwt.remote.ObjectRegistry.getObject(idw);
            if ( wdg && (wdg._element instanceof HTMLElement) ) {
                const m = this.marker;
                const elm = wdg._element;
                const info = this.wdgMap.get(idw);
                let left = 0;
                let width = 0;
                let gotit = false;
                if ( (typeof rect.x === 'number') && (typeof rect.width === 'number') ) {
                    left = rect.x;
                    width = rect.width;
                    gotit = true;
                }
                if ( !gotit ) {
                    const elm_rect = elm.getBoundingClientRect();
                    const elm_offs = elm.parentElement.getBoundingClientRect().x; 
                    console.warn('Using element rect!');
                    console.warn(elm_rect);
                    console.warn('Element offset: ' + elm_offs);
                    left = Math.max(elm_rect.x - elm_offs, 0);
                    width = elm_rect.width;
                }
                if ( info instanceof WdgInfo ) {
                    if ( info.bounds ) {
                        width = Math.max(width, info.bounds.width);
                    }
                }
                if ( (left > 0) && this.correction ) {
                    left -= this.correction;
                    width += this.correction;
                }
                if ( this.offset ) {
                    left += this.offset;
                }
                m.style.left = '' + left + 'px';
                m.style.width = '' + width + 'px';
                m.style.display = '';
            }
        }
    }

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget SepLine.');
		rap.registerTypeHandler("psawidget.SepLine", {
			factory : function(properties) {
				return new SepLine(properties);
			},
			destructor : 'destroy',
			properties: [ 'css', 'offset', 'correction' ],
			methods : [ 'setMarker', 'hideMarker', 'addWidget', 'rmvWidget' ],
			events : [ 'SEPLINE_NFY' ]
		} );
	}
}

console.log('widgets/csswdg/SepLine.js loaded.');