import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import AutoFitModeExtension from './AutoFitModeExtension';

export default class AutoFitModeManager extends AttachmentObject {

	constructor( hostObject, isAutoFitModeOn = false ) {
		super( hostObject );
		new AutoFitModeExtension( hostObject, isAutoFitModeOn );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		hostObject.constructor = void 0;
		delete hostObject.constructor;
	}

	getColumn( columnId ) {
		return Validator.isObject( this.wdgHead ) &&
			Validator.isFunction( this.wdgHead.getColumn ) ?
			this.wdgHead.getColumn( columnId ) : void 0;
	}

	setColumnWidth( columnId, width ) {
		if ( !this.ready || !this.alive || !Validator.isObject( this.wdgHead ) ||
			!Validator.isFunction( this.wdgHead.set_width ) ) {
			return false;
		}
		this.wdgHead.set_width( { idc: columnId, width: width } );
		return true;
	}

	autoFitColumns() {
		if ( !this.ready || !this.alive ) {
			return false;
		}
		if ( !Validator.isObject( this.parent ) ||
			!Validator.isFunction( this.parent.getClientArea ) ) {
			return this.autoFitColumnsBasedOnHeaderClientRect();
		}
		const parentClientArea = this.parent.getClientArea();
		if ( !Validator.isArray( parentClientArea ) || parentClientArea.length < 3 ) {
			return this.autoFitColumnsBasedOnHeaderClientRect();
		}
		const headerWidth = parentClientArea[ 2 ];
		if ( !Validator.isPositiveNumber( headerWidth ) ) {
			return this.autoFitColumnsBasedOnHeaderClientRect();
		}
		return this.setAutoFitModusColumnsWidths( headerWidth );
	}

	autoFitColumnsBasedOnHeaderClientRect() {
		if ( !this.ready || !this.alive || !Validator.isObject( this.wdgHead ) ) {
			return false;
		}
		const headerWidth = this.wdgHead.headClientWidth;
		if ( !Validator.isPositiveNumber( headerWidth ) ) {
			return false;
		}
		return this.setAutoFitModusColumnsWidths( headerWidth );
	}

	setAutoFitModusColumnsWidths( headerWidth ) {
		if ( !this.ready || !this.alive ||
			!Validator.isPositiveNumber( headerWidth, false ) ) {
			return false;
		}
		const autoFitAdmin = this.autoFitModeExtension;
		if ( !( autoFitAdmin instanceof AutoFitModeExtension ) ||
			!autoFitAdmin.shouldAutoFit ) {
			return false;
		}
		let availableWidth = headerWidth;
		const selectionColumnWidth = autoFitAdmin.selectionColumnWidth;
		if ( Validator.isPositiveNumber( selectionColumnWidth, false ) ) {
			availableWidth -= selectionColumnWidth;
		}
		const verticalSliderWidth = autoFitAdmin.verticalSliderWidth;
		if ( Validator.isPositiveNumber( verticalSliderWidth, false ) ) {
			availableWidth -= verticalSliderWidth;
		}
		if ( !Validator.isPositiveNumber( availableWidth, false ) ) {
			return false;
		}
		const columnsAutoFitProperties = autoFitAdmin.columnsAutoFitProperties;
		if ( !Validator.isMap( columnsAutoFitProperties, true ) ) {
			return false;
		}
		let allColumnsWidthsSet = true;
		for ( let columnId of [ ...columnsAutoFitProperties.keys() ] ) {
			const properties = columnsAutoFitProperties.get( columnId );
			if ( this.setColumnWidth( columnId, properties.quota * availableWidth ) ) {
				continue;
			}
			allColumnsWidthsSet = false;
		}
		this.syncServerColumnsWidths();
		return allColumnsWidthsSet;
	}

	resizeColumnOnAutoFit( column, newWidth ) {
		if ( !this.ready || !this.alive || !Validator.isObject( column ) ||
			!Validator.isPositiveNumber( newWidth ) ) {
			return false;
		}
		const autoFitAdmin = this.autoFitModeExtension;
		if ( !( autoFitAdmin instanceof AutoFitModeExtension ) ||
			!autoFitAdmin.shouldAutoFit ) {
			return false;
		}
		newWidth = Math.round( newWidth ); // only integer widths
		if ( newWidth < this.minimalColumnWidth ) {
			// TODO log
			// we are returning true even though the operation was not successfull,
			// in order to prevent the standard/traditional column resizing from
			// happening (the one that is not aware of auo-fit)
			return true;
		}
		const oldWidth = column.width;
		// TODO should we compare "integer" values by rounding them?
		if ( !Validator.isPositiveNumber( oldWidth ) || newWidth == oldWidth ) {
			// TODO log
			// we are returning true even though the operation was not successfull,
			// in order to prevent the standard/traditional column resizing from
			// happening (the one that is not aware of auo-fit)
			return true;
		}
		const visibleColumns = autoFitAdmin.visibleColumns;
		if ( Validator.isArray( visibleColumns ) && visibleColumns.length == 1 ) {
			// so in this case, the column that should be resized is the only
			// visible column and should take all the available space; we should
			// not allow any "manual" width changes to happen, but we might have to
			// make sure that the column ACTUALLY takes all the available space by
			// setting its width to the available width; in any case we should not
			// allow the "manually" set width to be processed by the web server
			return true;
		}
		const neighbourColumn = autoFitAdmin.getNeighbourColumn( column, true );
		if ( !Validator.isObject( neighbourColumn ) ||
			!Validator.isPositiveNumber( neighbourColumn.width ) ) {
			// TODO log
			// we are returning true even though the operation was not successfull,
			// in order to prevent the standard/traditional column resizing from
			// happening (the one that is not aware of auo-fit)
			return true;
		}
		const neighbourColumnsWidthChange = oldWidth - newWidth;
		if ( neighbourColumn.width + neighbourColumnsWidthChange < this.minimalColumnWidth ) {
			// TODO log
			// we are returning true even though the operation was not successfull,
			// in order to prevent the standard/traditional column resizing from
			// happening (the one that is not aware of auo-fit)
			return true;
		}
		if ( !this.setColumnWidth( column.id, newWidth ) ) {
			// TODO log
			// we are returning true even though the operation was not successfull,
			// in order to prevent the standard/traditional column resizing from
			// happening (the one that is not aware of auo-fit)
			return true;
		}
		if ( !this.setColumnWidth( neighbourColumn.id,
				neighbourColumn.width + neighbourColumnsWidthChange ) ) {
			// revert changes
			this.setColumnWidth( column.id, oldWidth );
			// TODO log
			// we are returning true even though the operation was not successfull,
			// in order to prevent the standard/traditional column resizing from
			// happening (the one that is not aware of auo-fit)
			return true;
		}
		this.syncServerColumnsWidths();
		return true;
	}

	reactToColumnVisibilityChange( alreadyChangedColumn ) {
		if ( !this.ready || !this.alive ) {
			return;
		}
		this.autoFitColumns();
	}

	/**
	 * inform the web server about the current columns widths, without triggering
	 * an update on the web-server part
	 */
	syncServerColumnsWidths() {
		const autoFitAdmin = this.autoFitModeExtension;
		// TODO
		if ( !( autoFitAdmin instanceof AutoFitModeExtension ) ) {
			return false;
		}
		const columnsAutoFitProperties = autoFitAdmin.columnsAutoFitProperties;
		if ( !Validator.isMap( columnsAutoFitProperties, true ) ) {
			return false;
		}
		const columnsWidths = {};
		for ( let columnId of [ ...columnsAutoFitProperties.keys() ] ) {
			const properties = columnsAutoFitProperties.get( columnId );
			columnsWidths[ String( columnId ) ] = Math.round( properties.definedWidth );
		}
		this._nfySrv( "columnsWidths", columnsWidths );
		return true;
	}

}
