import Validator from './Validator';

const UP_KEYWORDS = [ "ArrowLeft", "ArrowUp", "Home" ];
const DOWN_KEYWORDS = [ "ArrowRight", "ArrowDown", "End", "Tab" ];
const LEFT_KEYWORDS = [ "ArrowLeft" ];
const RIGHT_KEYWORDS = [ "ArrowRight" ];
const PAGE_UP_KEYWORDS = [ "PageUp", "PgUp" ];
const PAGE_DOWN_KEYWORDS = [ "PageDown", "PgDn" ];
const CHANGING_KEYS = [ "Delete", "Backspace", " ", "Space" ];

/**
 * DOM event processing helpers
 */
export default class DomEventHelper {

	/**
	 * checks whether the specified DOM event is considered to be a "control" event
	 * @param {MouseEvent | KeyboardEvent} event the DOM event
	 * @returns {Boolean} true if the event is a "control" event; false otherwise
	 */
	static isCtrlEvent( event ) {
		if ( event instanceof MouseEvent || event instanceof KeyboardEvent ) {
			return event.ctrlKey || event.metaKey;
		}
		return false;
	}

	static isShiftEvent( domEvent ) {
		return domEvent instanceof MouseEvent || domEvent instanceof KeyboardEvent ?
			domEvent.shiftKey : false;
	}

	/**
	 * checks whether a keyboard event has  a specific key name or key code
	 * @param {KeyboardEvent} evt the keyboard event
	 * @param {String} keyOrCodeName ke name or key code
	 * @returns {Boolean} true if the keyboard event has the specified key name or key code
	 */
	static keyIs( evt, keyOrCodeName ) {
		return Validator.isString( keyOrCodeName ) && evt instanceof KeyboardEvent &&
			( evt.key === keyOrCodeName || evt.code === keyOrCodeName );
	}

	/**
	 * checks whether a keyboard event is an "arrow up" event
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event is an "arrow up" event
	 */
	static isArrowUp( evt ) {
		return !!UP_KEYWORDS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	/**
	 * checks whether a keyboard event is an "arrow down" event
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event is an "arrow down" event
	 */
	static isArrowDown( evt ) {
		return !!DOWN_KEYWORDS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	/**
	 * checks whether a keyboard event is an "arrow left" event
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event is an "arrow left" event
	 */
	static isArrowLeft( evt ) {
		return !!LEFT_KEYWORDS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	/**
	 * checks whether a keyboard event is an "arrow right" event
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event is an "arrow right" event
	 */
	static isArrowRight( evt ) {
		return !!RIGHT_KEYWORDS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	/**
	 * checks whether a keyboard event is a "page up" event
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event is an "page up" event
	 */
	static isPageUp( evt ) {
		return !!PAGE_UP_KEYWORDS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	/**
	 * checks whether a keyboard event is a "page down" event
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event is an "page down" event
	 */
	static isPageDown( evt ) {
		return !!PAGE_DOWN_KEYWORDS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	static isUpNavigationEvent( domEvent ) {
		return UP_KEYWORDS.concat( PAGE_UP_KEYWORDS ).some(
			key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isDownNavigationEvent( domEvent ) {
		return DOWN_KEYWORDS.concat( PAGE_DOWN_KEYWORDS ).some(
			key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isNavigationEvent( domEvent ) {
		return DomEventHelper.isUpNavigationEvent( domEvent ) ||
			DomEventHelper.isDownNavigationEvent( domEvent );
	}

	/**
	 * checks whether a keyboard event refers to a printable character
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event refers to a printable character; false otherwise
	 */
	static isPrintableKey( evt ) {
		return ( evt instanceof KeyboardEvent ) && Validator.isString( evt.key ) && evt.key.length === 1;
	}

	/**
	 * checks whether a keyboard event would change the content of an editable element
	 * @param {KeyboardEvent} evt the keyboard event
	 * @returns {Boolean} true if the keyboard event would change the content of an editable element; false otherwise
	 */
	static isContentChangingKey( evt ) {
		return DomEventHelper.isPrintableKey( evt ) ||
			!!CHANGING_KEYS.some( key => DomEventHelper.keyIs( evt, key ) );
	}

	static isContentLengthIncreasingKey( domEvent ) {
		return DomEventHelper.isPrintableKey( domEvent ) ||
			!![ " ", "Space", ].some( key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isSelectAllEvent( domEvent ) {
		return DomEventHelper.isCtrlEvent( domEvent ) &&
			!![ "a", "KeyA" ].some( key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isContentChangingControlEvent( domEvent ) {
		return DomEventHelper.isCtrlEvent( domEvent ) &&
			!![ "v", "KeyV", "x", "KeyX" ]
			.some( key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isPasteEvent( domEvent ) {
		return DomEventHelper.isCtrlEvent( domEvent ) &&
			!![ "v", "KeyV" ].some( key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isContentKeepingControlEvent( domEvent ) {
		return DomEventHelper.isCtrlEvent( domEvent ) &&
			!![ "a", "KeyA", "c", "KeyC" ]
			.some( key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static isSaveEvent( domEvent ) {
		return DomEventHelper.isCtrlEvent( domEvent ) &&
			!![ "s", "KeyS", "S" ]
			.some( key => DomEventHelper.keyIs( domEvent, key ) );
	}

	static getClipboardDataTransferText( domEvent ) {
		if ( !( domEvent instanceof ClipboardEvent ) ||
			!( domEvent.clipboardData instanceof DataTransfer ) ||
			!Validator.isFunction( domEvent.clipboardData.getData ) ) {
			return void 0;
		}
		let plainData = void 0;
		try {
			plainData = domEvent.clipboardData.getData( "text/plain" );
		} catch ( e ) {}
		return plainData;
	}

	static stopIf( domEvent, stopPropagation = true, preventDefault = true ) {
		if ( !( domEvent instanceof Event ) ) {
			return false;
		}
		if ( stopPropagation ) {
			domEvent.stopPropagation();
		}
		if ( preventDefault ) {
			domEvent.preventDefault();
		}
		return true;
	}

}
