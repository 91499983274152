import Validator from '../../../utils/Validator';
import MBase from './MBase';
import CellProp from './CellProp';

const CTT_EMPTY = { html: false, text: '', prop: {} };
Object.freeze( CTT_EMPTY );

/**
 * XTW cell content
 */
export default class CellCtt extends MBase {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( json ) {
		super();
		this.text = json.text || '';
		this.rawText = json.rawText || this.text;
		this.html = !!json.html;
		// default cell access mode is writable (3)
		const cellAccessMode = Number( json.acc );
		this.acc = Validator.isPositiveInteger( cellAccessMode ) ? cellAccessMode : 3;
		this.prop = new CellProp( json.prop || {} );
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		this.prop.destroy();
		delete this.rawText;
		delete this.text;
		delete this.html;
		delete this.prop;
		delete this.acc;
	}

	static get EMPTY_CONTENT() {
		return Object.assign( {}, CTT_EMPTY );
	}
}
