import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import BtnBarMgr from './BtnBarMgr';

const DROP_DOWN_CLASS = 'far';
const DROP_DOWN_ICON = 'fa-angle-down';
const DRP_ICO_SMALL = {
	typ : 'DSC',
	img : {
		cssCls : DROP_DOWN_CLASS,
		icoNam : DROP_DOWN_ICON,
		icoSiz : 12
	}
};
const DRP_ICO_LARGE = {
	typ : 'DSC',
	img : {
		cssCls : DROP_DOWN_CLASS,
		icoNam : DROP_DOWN_ICON,
		icoSiz : 16
	}
};

const HVR_SHD = 'rgba(0, 0, 0, 0.25) 0px 1px 8px';
const DWN_SHD = 'rgba(0, 0, 0, 0.40) 0px 0px 8px inset';
const DIS_CLR = [ 158, 158, 158 ];
const DIS_IMG = 'brightness(1.2) grayscale(100%)';


/**
 * the class PsaBtn2 implements the custom PiSA sales button widget
 */
export default class PsaBtn2 {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		this.ready = false;
		this.enabled = false;
		this.selected = false;
		this.quiet = false;
		this.hot = false;
		this.pop = false;
		this.hidSel = false;
		this.popMnu = null;
		this.btnTxt = '';
		this.imgDsc = null;
		this.symClr = null;
		this.mainDiv = null;
		this.extDdi = null;
		this.ddnIco = null;
		this.lnkCur = false;
		this.ovlVal = null;
		this.ovlDiv = null;
		this.itmMgr = ItmMgr.getInst();
		const idw = properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject(idw);
		this.element = document.createElement('div');
		this.element.style.position = 'absolute';
		this.element.style.cursor = 'default';
		// initialize the widget
		const cwd = this.parent.getData("pisasales.CSTPRP.CWD");
		this._init(cwd);
		this.parent.append(this.element);
		// hook-in resize listener
		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		this._cleanup();
		delete this.hidSel;
		delete this.hvrShd;
		delete this.btnBar;
		delete this.fgrClr;
		delete this.symClr;
		delete this.ddnIco;
		delete this.icoSiz;
		delete this.imgDiv;
		delete this.txtDiv;
		delete this.ptsDiv;
		delete this.rquTxs;
		delete this.hotBgc;
		delete this.hotTxc;
		delete this.selBgc;
		delete this.popMnu;
		delete this.element;
		delete this.itmMgr;
		delete this.ready;
		delete this.parent;
		delete this.wdgId;
	}

	/**
	 * returns the widget ID
	 * @returns {String} the widget ID
	 */
	getIdw() {
		return this.wdgId;
	}

	/**
	 * creates an info string
	 * @returns {String} an info string
	 */
	toString() {
		let txt = this.btnTxt;
		if ( !this._psa.isStr(txt) ) {
			txt = this.inactive ? '<static>' : '<none>';
		}
		return '{' + this.wdgId + ':"' + txt + '"}';
	}

	/**
	 * returns the current "popup" state
	 * @returns {Boolean} true if button's popup menu is shown; false otherwise
	 */
	isPop() {
		return this.pop;
	}

	/**
	 * indicates whether this button has a popup menu
	 * @returns {Boolean} true if this button has a popup menu, false otherwise
	 */
	hasPopMnu() {
		return !!this.popMnu;
	}

	clsMnu() {
		if ( this.isPop() ) {
			this._psa.getMnuMgr().hideMenu(this.popMnu)
			this.onMenuClose();
		}
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
		const pe = this.element.parentElement;
		if ( this.ddnIco ) {
			pe.appendChild(this.ddnIco);
		}
		if ( !this.inactive ) {
			const self = this;
			pe.addEventListener('mouseenter', function(e) {
				self._onMouseOver(e, self.quiet, false);
			}, false);
			pe.addEventListener('mouseleave', function(e) {
				self._onMouseOver(e, true, false);
			}, false);
			pe.addEventListener('mousedown', function(e) {
				self._onMouseOver(e, self.quiet, false);
			}, false);
			pe.addEventListener('mouseup', function(e) {
				self._onMouseOver(e, self.quiet, true);
			}, false);
			pe.addEventListener('click', function(e) {
				self._onClick(e);
			}, false);
		}
	}

	/**
	 * called by the framework in rendering phase
	 */
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);			// just once!
			this.onReady();
			this.layout();
			if ( this.rquTxs && this.txtDiv ) {
				// get required text size and notify the web server
				this._rptTxs();
				let txs = this.itmMgr.measureText(this.txtDiv, false, 0, false);
				let par = {};
				par.txs = txs.sts;
				this._nfySrv('rquTxs', par);
			}
			let ppp = this.element.parentElement.parentElement;
			if ( ppp ) {
				ppp.style.overflow = 'visible';
			}
			if ( this._psa.isStr(this.ovlVal) ) {
				this._renderOvrVal();
			}
		}
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const wdt = area[2];
			const hgt = area[3];
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = wdt + 'px';
			this.element.style.height = hgt + 'px';
			if ( this.imgDiv && this.imgDsc && (this.imgDsc.typ !== 'DSC') ) {
				const offs = 2;
				const isz = this.imgDsc.isz;
				const fh = (wdt - 2*offs) / isz.cx;
				const fv = (hgt - 2*offs) / isz.cy;
				const f = Math.min(Math.min(fv, fh), 1);
				if ( f < 1.0 ) {
					const div = this.imgDiv;
					const img = div.firstChild;
					const imw = f * isz.cx;
					const imh = f * isz.cy;
					img.style.width = '' + imw + 'px';
					img.style.height = '' + imh + 'px';
					if ( !this._psa.isStr(this.btnTxt) ) {
						div.style.width = 'inherit';
						div.style.height = 'inherit';
						div.style.paddingLeft = '' + ((wdt - imw)/2) + 'px';
						div.style.paddingTop = '' + ((hgt - imh)/2) + 'px';
					}
				}
			}
		}
	}

	/**
	 * dummy so far
	 */
	onSend() {
		// do nothing so far...
	}

	/**
	 * sets new text
	 * @param {String} args new text
	 */
	setTxt(args) {
		const txt = args || '';
		if ( this.txtDiv ) {
			this.txtDiv.innerText = txt;
			if ( this.ready && this.rquTxs ) {
				// get required text size and notify the web server
				this._rptTxs();
			}
		}
		this.btnTxt = txt;
	}

	/**
	 * sets a new image
	 * @param {Object} args image descriptor
	 */
	setImg(args) {
		this.imgDsc = null;
		if ( this.imgDiv ) {
			this.imgDiv.innerHTML = '';
			const img = args || null;
			if ( img ) {
				this.imgDsc = img;
				const ics = this.icoSiz || 0;
				const ime = this.itmMgr.creImg(img, ics, ics > 16);
				if ( ime ) {
					this.imgDiv.appendChild(ime);
				}
				// update "enabled" styles if required
				this.setEna(this.enabled);
				this.layout();
			} 
		}
	}

	/**
	 * sets a new text font
	 * @param {Object} args new font descriptor
	 */
	setFnt(args) {
		if ( this.txtDiv ) {
			const fnt = args || null;
			if ( fnt ) {
				// set the text font
				this.itmMgr.setFnt(this.txtDiv, fnt);
			}
		}
	}

	/**
	 * sets a new foreground color
	 * @param {Object} args new foreground (text) color
	 */
	setFgc(args) {
		const fgc = args || null;
		this.fgrClr = fgc;
		// apply the color
		this.setEna(this.enabled);
	}

	/**
	 * sets a new symbol color
	 * @param {*} args new symbol color
	 */
	setSymClr(args) {
		const clr = args || null;
		this.symClr = clr;
		// apply the color
		this.setEna(this.enabled);
	}

	/**
	 * sets the "enabled" state and updates the control accordingly
	 * @param {Boolean} args enabled flag
	 */
	setEna(args) {
		const ena = this.inactive || !!args;
		this.enabled = ena;
		if ( this.element ) {
			if ( ena ) {
				let fgc = this.fgrClr;
				if ( this.hot && this.hotTxc ) {
					fgc = this.hotTxc;
				}
				else if ( this.selected && this.selTxc ) {
					fgc = this.selTxc;
				}
				if ( fgc ) {
					this.itmMgr.setFgrClr(this.element, fgc);
				} else {
					this.element.style.color = '';
				}
			} else {
				this.itmMgr.setFgrClr(this.element, DIS_CLR);
			}
		}
		if ( this.imgDiv ) {
			const imd = this.imgDiv;
			if ( imd.firstChild && (imd.firstChild.tagName === 'IMG') ) {
				// it's an image tag - we must change the style of the image a bit
				const itg = imd.firstChild;
				itg.style.filter = ena ? '' : DIS_IMG;
			} else {
				// apply symbol color
				this.itmMgr.setFgrClr(imd, ena ? this.symClr : null);
			}
		}
	}

	/**
	 * sets the "selected" state
	 * @param {Boolean} args selected flag
	 */
	setSel(args) {
		const sel = !!args;
		this.selected = sel;
		if ( this.element && !this.hidSel ) {
			this.setEna(this.enabled);
			if ( !this._isPartialSel() && this.selBgc ) {
				if ( sel ) {
					this.itmMgr.setBkgClr(this.element, this.selBgc, false);
				} else {
					this.element.style.backgroundColor = '';
				}
			}
		}
	}

	setQuiet(args) {
		this.quiet = !!args;
		if ( this.quiet && this.ready ) {
			this._onMouseOver(null, true, true);
		}
	}

	/**
	 * sets the "hover shadow" flag
	 * @param {Boolean} args new "hover shadow" flag
	 */
	setHvs(args) {
		this.hvrShd = !!args;
	}

	/**
	 * sets the "link cursor" flag
	 * @param {Boolean} args new "link cursor" flag
	 */
	setLnkCur(args) {
		this.lnkCur = !!args;
		if ( this.element ) {
			this.element.style.cursor = this.lnkCur ? 'pointer' : 'default';
		}
	}

	/**
	 * sets a new overlay value
	 * @param {String} args new overlay value
	 */
	setOvlVal(args) {
		const ovv = args || '';
		if ( ovv !== this.ovlVal ) {
			if ( this._psa.isStr(ovv) ) {
				this.ovlVal = ovv;
			} else {
				this.ovlVal = '';
			}
			this._renderOvrVal();
		}
	}

	/**
	 * sets the popup menu
	 * @param {Object} args menu structure
	 */
	setMnu(args) {
		if ( this.popMnu ) {
			// clean-up first!
			const mnu = this.popMnu;
			this.popMnu = null;
			this.pop = false;
			mnu.destroy();
		}
		if ( this._isAlive() ) {
			if ( args && args.items && args.items.length && (args.items.length > 0) ) {
				const nested = !!args.nested;
				let idm = 0;
				let items = null;
				if ( nested ) {
					// that's a netsted menu structure - the one and only top-level item is a clone of the button
					const top = args.items[0] || {};
					idm = top.id || 0;
					if ( top.items && (top.items.length > 0) ) {
						// ok, it provides the menu structure
						items = top.items;
					}
					
				} else {
					// that's a plain menu structure
					idm = args.id || 0;
					items = args.items;
				}
				if ( (idm > 0) && items && (items.length > 0) ) {
					this.popMnu = this._psa.getMnuMgr().createMenu(idm, items);
				}
			}
		}
	}

	/**
	 * called to update a menu item
	 * @param {Object} args arguments
	 */
	updMnu(args) {
		if ( this.popMnu ) {
			const id = args.id || 0;
			if ( id ) {
				// update the menu item
				this.popMnu.updMnuItm(id, args);
			}
		}
	}

	/**
	 * called by the button bar if a timed "hit" event occurred
	 * @param {Boolean} hit flag whether the button got a "hit" event
	 * @param {JsPoint} pos current mouse position
	 */
	onHit(hit, pos) {
		if ( this.ready && this.enabled && !this.inactive ) {
			if ( this.popMnu ) {
				// that's our job!
				this._doMnuPop(hit);
			} else {
				// notify the web server
				const par = {};
				par.idw = this.wdgId;
				par.hit = !!hit;
				par.pos = pos || {};
				this._nfySrv("hit", par);
			}
		}
	}

	/**
	 * called by the menu manager if a menu items was clicked
	 * @param {Number} id ID of the menu item
	 */
	onMenuItem(id) {
		if ( this.ready ) {
			const par = {};
			par.idw = this.wdgId;
			par.id = id || '';
			this._psa.setBscRqu();
			this._nfySrv("mnuItm", par);
		}
	}

	/**
	 * called by the menu manager if a menu was closed, that was triggered by this instance
	 */
	onMenuClose() {
		this.pop = false;
		this.setSel(false);
		if ( this.btnBar ) {
			this.btnBar.setHotBtn(this, false, true);
		}
	}

	/**
	 * initializes the button widget
	 * @param {Object} cwd custom widget data; initialization parameters
	 */
	_init(cwd) {
		const elm = this.element;
		if ( cwd ) {
			const xhtmlcontent = cwd.xhtmlcontent || '';
			const xinnerhtml = cwd.xinnerhtml || this._psa.isStr(xhtmlcontent);
			const xnogrid = cwd.xnogrid || xinnerhtml;
			const xicocss = cwd.xicocss || null;
			const xtxtcss = cwd.xtxtcss || null;
			const xptscss = cwd.xptscss || null;
			const txt = cwd.txt || '';
			const css = cwd.css || '';
			const img = cwd.img || null;
			const fgc = cwd.fgc || null;
			const htc = cwd.htc || null;
			const hbc = cwd.hbc || null;
			const sfc = cwd.sfc || null;
			const sbc = cwd.sbc || null;
			const fnt = cwd.fnt || null;
			const fxl = !!cwd.fxl;
			const hds = !!cwd.hds;
			const stc = !!cwd.stc;
			const pts = !hds && !!cwd.pts;
			const drd = cwd.drd || null;
			const exd = !!cwd.exd;
			const rqs = !!cwd.rqs;
			const bbi = cwd.bbi || '';
			const hvs = !!cwd.hvs;

			let itc = 0;
			if ( img ) {
				itc += 1;
			}
			if ( this._psa.isStr(txt) ) {
				itc += 1;
			}

			if ( this._psa.isStr(xhtmlcontent) ) {
				elm.innerHTML = xhtmlcontent;
			}
			if ( !xnogrid ) {
				// setup element's default style
				if ( this._psa.isStr(txt) ) {
					elm.style.paddingTop = '6px';
				}
				elm.style.display = 'grid';
				if ( !pts ) {
					// common grid layout
					elm.style.gridTemplateRows = '55% 45%';
				} else {
					// setup for partial selection
					elm.style.gridTemplateRows = '75% 25%';
				}
			} else if ( typeof xnogrid === 'object' ) {
				// there are custom CSS properties
				this.itmMgr.setCssPrp(elm, xnogrid);
			}

			elm.style.zIndex = 100;

			if ( img ) {
				// create the image
				let ics = cwd.ics || 0;
				let imd = this.itmMgr.creImgObj(img, ics, false, ics > 16);
				if ( !xnogrid ) {
					imd.style.textAlign = 'center';
					imd.style.alignSelf = 'center';
					if ( itc > 1) {
						// we occupy the first row
						imd.style.gridRow = '1 / span 1'; 
					} else {
						// we occupy *all* rows
						imd.style.gridRow = '1 / span 2'; 
					}
				}
				if ( xicocss && (typeof xicocss === 'object') ) {
					this.itmMgr.setCssPrp(imd, xicocss);
				}
				elm.appendChild(imd);
				this.icoSiz = ics;
				this.imgDiv = imd;
				this.imgDsc = img;
			} else {
				this.icoSiz = 0;
				this.imgDiv = null;
				this.imgDsc = null;
			}

			if ( this._psa.isStr(txt) ) {
				// create a div for button's text
				let txd = document.createElement('div');
				let has_sty = false;
				if ( this._psa.isStr(css) ) {
					// set CSS class...
					txd.className = css;
					// ... but some CSS classes contain unwanted size specifications, so we reset this here...
					txd.style.width = 'initial';
					txd.style.height = 'initial';
					has_sty = true;
				} else {
					txd.style.textAlign = 'center';
					txd.style.overflow = 'hidden';
					txd.style.textOverflow = 'ellipsis';
				}
				if ( !fxl ) {
					txd.style.whiteSpace = 'nowrap';
				}
				if ( !xnogrid ) {
					if ( itc > 1 ) {
						// we occupy the second row
						txd.style.gridRow = '2 / span 1'; 
					} else {
						// we occupy *all* rows
						txd.style.gridRow = '1 / span 2'; 
					}
				}
				if ( xtxtcss && (typeof xtxtcss === 'object') ) {
					this.itmMgr.setCssPrp(txd, xtxtcss);
					has_sty = true;
				}
				if ( xinnerhtml ) {
					txd.innerHTML = txt;
				} else {
					txd.innerText = txt;
				}
				if ( !has_sty && fnt ) {
					// set the text font
					this.itmMgr.setFnt(txd, fnt);
				}
				elm.appendChild(txd);
				this.txtDiv = txd;
			} else {
				// the button has no text
				this.txtDiv = null;
			}
			if ( pts ) {
				const	ptd = document.createElement('div');
				if ( xptscss && (typeof xptscss === 'object') ) {
					this.itmMgr.setCssPrp(ptd, xptscss);
				} else {
					// TODO: default style
					ptd.style.gridRow = '2 / span 1'; 
				}
				if ( sbc ) {
					this.itmMgr.setBkgClr(ptd, sbc, false);
				}
				// initially hidden!
				this.ptsDsp = ptd.style.display || 'block';
				ptd.style.display = 'none';
				elm.appendChild(ptd);
				this.ptsDiv = ptd;
			} else {
				this.ptsDiv = null;
				this.ptsDsp = null;
			}
			if ( fgc ) {
				// set foreground color (text color)
				this.fgrClr = fgc;
				this.itmMgr.setFgrClr(elm, fgc);
			}
			if ( drd && !exd ) {
				const ddi = this.itmMgr.creImgObj(DRP_ICO_SMALL, 0, true, false);
				ddi.style.position = 'absolute';
				ddi.style.top = '2px';
				ddi.style.right = '2px';
				ddi.style.zIndex = 101;
				this.itmMgr.setFgrClr(ddi, drd);
				ddi.style.display = 'none';
				this.ddnIco = ddi;
			} else {
				this.ddnIco = null;
			}
			if ( exd && !drd ) {
				elm.style.position = '';
				elm.style.flex = '1';
				const ddi = this.itmMgr.creImgObj(DRP_ICO_LARGE, 0, true, false);
				
				const new_elm = document.createElement('div');
				new_elm.style.position = 'absolute';
				new_elm.style.cursor = 'default';
				new_elm.style.display = 'flex';
				new_elm.style.flexDirection = 'row';
				new_elm.style.alignItems = 'center';
				new_elm.style.paddingLeft = '3px';
				new_elm.style.paddingRight = '3px';

				if ( fgc ) {
					this.itmMgr.setFgrClr(elm, null);
					this.itmMgr.setFgrClr(new_elm, fgc);
				}
				
				new_elm.appendChild(elm);
				new_elm.appendChild(ddi);
				
				this.extDdi = ddi;
				this.mainDiv = elm;
				this.element = new_elm;
			} else {
				this.extDdi = null;
				this.mainDiv = null;
			}
			this.hidSel = hds;
			this.rquTxs = rqs;
			this.hotTxc = htc;
			this.hotBgc = hbc;
			this.selTxc = sfc;
			this.selBgc = sbc;
			this.hvrShd = hvs;
			this.enabled = true;
			this.inactive = stc;
			this.btnTxt = txt;
			this.btnBar = null;
			if ( !stc && this._psa.isStr(bbi) ) {
				// we're emebedded into a button bar manager
				const btb = BtnBarMgr.getInst().getBar(bbi);
				if ( btb ) {
					// we must add us to the button bar
					btb.addBtn(this);
					this.btnBar = btb;
				}
			}
		} else {
			// ?!
			this.selTxc = null;
			this.selBgc = null;
			this.hotTxc = null;
			this.hotBgc = null;
			this.enabled = false;
			this.btnBar = null;
			this.hvrShd = false;
			this.ptsDiv = null;
			this.ptsDsp = null;
			this.ddnIco = null;
			this.extDdi = null;
			this.inactive = true;
		}
	}

	/**
	 * cleanup
	 */
	_cleanup() {
		if ( this.popMnu ) {
			const mnu = this.popMnu;
			this.popMnu = null;
			this.pop = false;
			mnu.destroy();
		}
		if ( this.btnBar ) {
			this.btnBar.rmvBtn(this);
			this.btnBar = null;
		}
		this.ready = false;
		this.enabled = false;
		this.parent = null;
	}

	/**
	 * indicates whether this instance is alive
	 * @returns {Boolean} true if this instance is alive; false otherwise
	 */
	_isAlive() {
		return !!this.parent;
	}

	/**
	 * @returns {Boolean} true if "partial" selection is active
	 */
	_isPartialSel() {
		return !!this.ptsDiv;
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv(code, par) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify("PSA_BTN_NFY", param);
		}
	}

	/**
	 * mouse hover handler
	 * @param {MouseEvent} evt mouse event
	 * @param {Boolean} off forced "off" flag
	 * @param {Boolean} fup forced "up" flag
	 */
	_onMouseOver(evt, off, fup) {
		if ( this.ready && this.enabled && !this.inactive && this.element ) {
			const elm = this.element;
			const pnt = elm.parentElement;
			elm.style.boxShadow = '';
			pnt.style.boxShadow = '';
			elm.style.backgroundColor = '';
			let tgt = pnt;
			let shd = '';
			this.hot = !off;
			if ( !off ) {
				if ( !this._isPartialSel() ) {
					const dwn = !fup && (evt.buttons === 1);
					if ( this.hvrShd ) {
						shd = dwn && !this.lnkCur ? DWN_SHD : HVR_SHD;
					}
					tgt = dwn ? elm : pnt;
					const bgc = dwn ? this.selBgc : this.hotBgc;
					const txc = dwn ? this.selTxc : this.hotTxc;
					if ( bgc && !this.lnkCur ) {
						this.itmMgr.setBkgClr(elm, bgc, false);
					}
					if ( txc && !this.lnkCur ) {
						this.itmMgr.setFgrClr(elm, txc);
					}
				}
			}
			else {
				if ( this.hotTxc ) {
					// reset text color
					this.setEna(this.enabled);
				}
				this.setSel(this.selected);
			}
			if ( this.ddnIco ) {
				this.ddnIco.style.display = off ? 'none' : '';
			}
			if ( this._isPartialSel() ) {
				this.ptsDiv.style.display = this.hot ? this.ptsDsp : 'none';
			}
			if ( shd.length > 0 ) {
				tgt.style.boxShadow = shd;
			}
			if ( this.btnBar ) {
				this.btnBar.setHotBtn(this, !off, false);
			}
			if ( off && this.isPop() && !this.lnkCur && !this.extDdi ) {
				this.setSel(true);
			}
		}
	}

	/**
	 * "click" event handler
	 * @param {MouseEvent} evt the mouse event
	 */
	_onClick(evt) {
		this._blurAllEditors();
		if ( this.ready && this.enabled && !this.inactive ) {
			const tgt = evt.target;
			let pop = true;
			if ( this.extDdi ) {
				if ( (this.extDdi === tgt) || this.extDdi.contains(tgt) ) {
					// really trigger the dropdown menu
					pop = true;
				} else {
					// we've got an extra dropdown button, but the user clicked another part, so trigger the command
					pop = false;
				}
			}
			if ( this.btnBar ) {
				this.btnBar.onBtnClk(this);
			}
			if ( pop && this.popMnu ) {
				this._doMnuPop(true);
			} else {
				// notify the web server
				const par = {};
				par.btn = evt.button;
				this._psa.setBscRqu();
				this._nfySrv('click', par);
			}
		}
	}

	/**
	 * shows the popup menu
	 * @param {Boolean} show flag whether to show or to hide the menu
	 */
	_doMnuPop(show) {
		if ( this.popMnu ) {
			if ( show ) {
				this.pop = true;
				this._psa.getMnuMgr().showMenu(this.popMnu, { element: this.element, rect: null }, this, false, false);
			} else {
				// we do *not* hide the menu here!
				this.hot = false;
				this.setSel(this.pop);
			}
		}
	}

	/**
	 * calculates the required text size and sends it to the web server
	 */
	_rptTxs() {
		// get required text size and notify the web server
		let txs = this.itmMgr.measureText(this.txtDiv, false, 0, false);
		let par = {};
		par.txs = txs.sts;
		this._nfySrv('rquTxs', par);
	}

	/**
	 * initializes the overlay DIV
	 */
	_initOvlDiv() {
		if ( this.element && !this.ovlDiv ) {
			const div = document.createElement('div');
			div.className = 'overlayText';
			this.element.appendChild(div);
			this.ovlDiv = div;
		}
	}

	/**
	 * renders the overlay value
	 */
	_renderOvrVal() {
		if ( this.element ) {
			this._initOvlDiv();
			const ovd = this.ovlDiv;
			const imd = this.imgDiv;
			if ( this._psa.isStr(this.ovlVal) ) {
				ovd.innerText = this.ovlVal;
				ovd.style.display = '';
				if ( imd ) {
					imd.classList.add('belowOverlay');
				}
			} else {
				ovd.style.display = 'none';
				if ( imd ) {
					imd.classList.remove('belowOverlay');
				}
			}
		}
	}
	
	/**
	 * removes focus from all ck editor 5 instances (blurs them)
	 * @return <true> if the action was successfull, <false> otherwise
	 * @see /webCli/src/de/pisa/webcli/cstwdg/dtrpck/js/DtrPck.js~blurAllEditors
	 */
	_blurAllEditors() {
		// TODO this is a function that copies exactly the behavior in
		// /webCli/src/de/pisa/webcli/cstwdg/dtrpck/js/DtrPck.js~blurAllEditors
		return this._psa.blurAllCke5Editors();
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget PsaBtnBar.');
		/** register custom widget type */
		rap.registerTypeHandler("psawidget.PsaBtn2", {
			factory : function(properties) {
				return new PsaBtn2(properties);
			},
			destructor: "destroy",
			properties: [ "txt", "img", "fnt", "fgc", "symClr", "ena", "sel", "quiet", "hvs", "lnkCur", "ovlVal", "mnu" ],
			methods: [ "updMnu" ],
			events: [ "PSA_BTN_NFY"]
		});
	}
}

console.log('widgets/psatbn/PsaBtn2.js loaded');