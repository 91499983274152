import Color from '../../../../utils/Color';
import Validator from '../../../../utils/Validator';
import AttachmentObject from '../../../../utils/AttachmentObject';
import ColumnDataColorManager from './ColumnDataColorManager';

export default class ColumnDataColorExtension extends AttachmentObject {

	constructor( hostObject ) {
		super( hostObject );
		Object.defineProperty( hostObject, "columnDataColorManager", {
			value: new ColumnDataColorManager(),
			writable: false,
			configurable: false
		} );
		[ "mainFontColor", "alternativeFontColor",
			"mainBackgroundColor", "alternativeBackgroundColor"
		].forEach( property => {
			Object.defineProperty( hostObject, property, {
				get: () => {
					return hostObject.columnDataColorManager[ property ];
				},
				set: ( color ) => {
					hostObject.columnDataColorManager[ property ] = color;
				},
				configurable: false
			} );
		} );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		hostObject.constructor = void 0;
		delete hostObject.constructor;
	}

	setDataFontColor( args ) {
		const mainFontColor = Validator.isObject( args ) &&
			Validator.isArray( args.txc, 4 ) ? Color.fromRgba( args.txc ) : void 0;
		this.mainFontColor = mainFontColor;
		this.alternativeFontColor = mainFontColor;
		// TODO: the font color might be adjusted in the future, but for now it
		// should be the same
		return mainFontColor instanceof Color;
	}

	setDataBackgroundColor( args ) {
		const mainBackgroundColor = Validator.isObject( args ) &&
			Validator.isArray( args.bgc, 4 ) ? Color.fromRgba( args.bgc ) : void 0;
		this.mainBackgroundColor = mainBackgroundColor;
		this.alternativeBackgroundColor = mainBackgroundColor;
		return mainBackgroundColor instanceof Color ?
			this.adjustDataBackgroundColor() : false;
	}

	adjustDataColors() {
		// TODO: the font color might be adjusted in the future, but for now it
		// should be the same
		this.adjustDataBackgroundColor();
	}

	adjustDataBackgroundColor() {
		if ( !Validator.isObjectPath( this.xthWdg, "xthWdg.xtdTbl.cssProperties" ) ||
			!( this.xthWdg.xtdTbl.cssProperties.mainPyjamaColor instanceof Color ) ||
			!( this.xthWdg.xtdTbl.cssProperties.secondPyjamaColor instanceof Color ) ) {
			return false;
		}
		const mainBackgroundColor = this.mainBackgroundColor;
		if ( !( mainBackgroundColor instanceof Color ) ) {
			return true;
		}
		const mainPyjamaColor = this.xthWdg.xtdTbl.cssProperties.mainPyjamaColor;
		const secondPyjamaColor = this.xthWdg.xtdTbl.cssProperties.secondPyjamaColor;
		const adjustment = secondPyjamaColor._getDifferenceTo( mainPyjamaColor );
		this.alternativeBackgroundColor = mainBackgroundColor.adjustSelf( adjustment );
		return true;
	}

}
