import Validator from './Validator';
import Warner from './Warner';

export default class CallbackManager {

	static addCallbackMethods( {
		instance,
		callbackMapName,
		setupMethodName,
		executionMethodName
	} ) {
		if ( !Validator.isObject( instance ) ||
			![ callbackMapName, setupMethodName, executionMethodName ].every( name =>
				Validator.isString( name ) ) ||
			![ setupMethodName, executionMethodName ].every( name =>
				!( name in instance ) ) ) {
			return false;
		}
		if ( !Validator.isMap( instance[ callbackMapName ] ) ) {
			instance[ callbackMapName ] = new Map();
		}
		instance[ setupMethodName ] = ( prefix, callback,
			deleteRightAfterExecution = true, deleteOthersWithSamePrefix = true,
			canBeDeletedByOthers = true ) => {
			return CallbackManager.setupCallback( {
				instance: instance,
				callbackMapName: callbackMapName,
				prefix: prefix,
				callback: callback,
				deleteRightAfterExecution: deleteRightAfterExecution,
				deleteOthersWithSamePrefix: deleteOthersWithSamePrefix,
				canBeDeletedByOthers: canBeDeletedByOthers
			} );
		};
		instance[ executionMethodName ] = () => {
			return CallbackManager.executeCallbacks( {
				instance: instance,
				callbackMapName: callbackMapName
			} );
		};
		return true;
	}

	static setupCallback( {
		instance,
		callbackMapName,
		prefix,
		callback,
		deleteRightAfterExecution = true,
		deleteOthersWithSamePrefix = true,
		canBeDeletedByOthers = true
	} ) {
		if ( !Validator.isObject( instance ) ||
			!Validator.isString( callbackMapName ) || !Validator.isString( prefix ) ||
			!Validator.isFunction( callback ) ) {
			return false;
		}
		if ( !Validator.isMap( instance[ callbackMapName ] ) ) {
			instance[ callbackMapName ] = new Map();
		}
		const callBackId = Validator.generateRandomString( prefix );
		// due to the "registration" process this callback could be "called" when
		// the instance does not exist anymore
		const instanceHolder = instance;
		const callbackFunction = () => {
			callback();
			if ( !deleteRightAfterExecution || !Validator.isObject( instanceHolder ) ||
				!Validator.isMap( instanceHolder[ callbackMapName ], true ) ) {
				return;
			}
			instanceHolder[ callbackMapName ].delete( callBackId );
		}
		Object.defineProperty( callbackFunction, "canBeDeletedByOthers", {
			value: !!canBeDeletedByOthers,
			writable: false,
			configurable: false
		} );
		if ( deleteOthersWithSamePrefix ) {
			CallbackManager.deleteCallbacksWithPrefix( {
				instance: instance,
				callbackMapName: callbackMapName,
				prefix: prefix,
				forceDeleteAll: false
			} );
		}
		instance[ callbackMapName ].set( callBackId, callbackFunction );
		return true;
	}

	static deleteCallbacksWithPrefix( {
		instance,
		callbackMapName,
		prefix,
		forceDeleteAll = false
	} ) {
		if ( !Validator.isObject( instance ) ||
			!Validator.isString( callbackMapName ) || !Validator.isString( prefix ) ||
			!Validator.isMap( instance[ callbackMapName ] ) ) {
			return false;
		}
		const callbackKeysWithPrefix = [ ...instance[ callbackMapName ].keys() ]
			.filter( key => key.startsWith( prefix ) );
		for ( let callbackKey of callbackKeysWithPrefix ) {
			const otherCallback = instance[ callbackMapName ].get( callbackKey );
			if ( !forceDeleteAll && Validator.isObject( otherCallback ) &&
				otherCallback.canBeDeletedByOthers == false ) continue;
			instance[ callbackMapName ].delete( callbackKey );
		}
		return true;
	}

	static executeCallbacks( {
		instance,
		callbackMapName
	} ) {
		if ( !Validator.isObject( instance ) ||
			!Validator.isString( callbackMapName ) ||
			!Validator.isMap( instance[ callbackMapName ], true ) ) {
			return false;
		}
		[ ...instance[ callbackMapName ].values() ].forEach( callback => {
			if ( !Validator.isFunction( callback ) ) {
				return;
			}
			callback();
		} );
		return true;
	}

	static executeAsync( callback ) {
		if ( !Validator.isFunction( callback ) ) {
			return void 0;
		}
		let returnValue;
		window.setTimeout( () => {
			returnValue = callback();
		}, 0 );
		return returnValue;
	}

}
