import ItmMgr from './ItmMgr';
import Validator from '../utils/Validator';

/** CSS variable prefix - keep in sync with /webCli/theme/pisa/pisatheme.css */
const VAR_PFX = '--pisa-';

/**
 * ThemeMgr - theme manager class
 */
export default class ThemeMgr {

	/**
	 * constructs a new instance
	 */
	constructor() {
		this.theme = new Map();
	}

	/**
	 * destructor method
	 */
	destroy() {
		this.theme.clear();
		delete this.theme;
	}

	/**
	 * clears the current theme
	 */
	clearCurrentTheme() {
		const map = this.theme;
		if ( map.size > 0 ) {
			try {
				const root = document.querySelector(':root');
				const style = root.style;
				const self = this;
				map.forEach( (prop) => {
					self._rmvCssProp(style, prop);
				});
			} finally {
				map.clear();
			}
		}
	}

	/**
	 * initializes the theme
	 * @param {Object} args theme data sent by the web server
	 */
	initTheme(args) {
		this.clearCurrentTheme();
		const map = this.theme;
		Object.entries(args.theme || {}).forEach(([key, value]) => {
			map.set(key, value);
		});
		this._iniCss();
	}

	/**
	 * retrives a theme property
	 * @param {String} name property name
	 * @returns {Object} the theme property or undefined if no matching property was found
	 */
	getProp(name) {
		if ( !Validator.isString(name) ) {
			return undefined;
		}
		return this.theme.get(name);
	}

	/**
	 * initializes CSS variables with theme settings
	 */
	_iniCss() {
		if ( this.theme.size > 0 ) {
			const root = document.querySelector(':root');
			const style = root.style;
			const self = this;
			const im = ItmMgr.getInst();
			this.theme.forEach((prop, key) => {
				self._setCssProp(style, im, prop);
			});
		}
	}

	/**
	 * sets a CSS property
	 * @param {CSSStyleDeclaration} style DOM's root element's CSS style object
	 * @param {ItmMgr} im itme mananger instance
	 * @param {Object} prop the theme property
	 */
	_setCssProp(style, im, prop) {
		if ( Validator.isString(prop.name) ) {
			const type = prop.type || '';
			switch ( type ) {
				case 'font':
					this._setFontProp(style, im, prop);
					break;
				case 'color':
					this._setColorProp(style, im, prop);
					break;
				case 'number':
					this._setNumberProp(style, im, prop);
					break;
				case 'size':
					break;
				case 'box':
					break;
				case 'bool':
				default:
					// everything else is just left as theme property and not set as CSS variable
					break;
			}
		}
	}

	/**
	 * removes a CSS property
	 * @param {CSSStyleDeclaration} style DOM's root element's CSS style object
	 * @param {Object} prop the theme property
	 */
	 _rmvCssProp(style, prop) {
		style.removeProperty(this._getVarName(prop));
	}

	/**
	 * creates the CSS variable name for a theme property
	 * @param {Object} prop the the property
	 * @returns {String} the corresponding variable name or undefined if an invalid theme property was specified
	 */
	_getVarName(prop) {
		if ( Validator.isString(prop.name) ) {
			return VAR_PFX + prop.name;
		} else {
			return undefined;
		}
	}

	/**
	 * sets a font property
	 * @param {CSSStyleDeclaration} style DOM's root element's CSS style object
	 * @param {ItmMgr} im item mananger instance
	 * @param {Object} prop the theme property
	 */
	_setFontProp(style, im, prop) {
		if ( prop.value !== undefined ) {
			// the value is supposed to be a valid CSS "font" value
			style.setProperty(this._getVarName(prop), im.getFontCss(prop.value.prp));
		}
	}

	/**
	 * sets a color property
	 * @param {CSSStyleDeclaration} style DOM's root element's CSS style object
	 * @param {ItmMgr} im itme mananger instance
	 * @param {Object} prop the theme property
	 */
	_setColorProp(style, im, prop) {
		if ( prop.value !== undefined ) {
			const rgba = im.getRgb(prop.value);
			if ( rgba ) {
				style.setProperty(this._getVarName(prop), rgba);
			}
		}
	}

	/**
	 * sets a color property
	 * @param {CSSStyleDeclaration} style DOM's root element's CSS style object
	 * @param {ItmMgr} im itme mananger instance
	 * @param {Object} prop the theme property
	 */
	_setNumberProp(style, im, prop) {
		if ( typeof prop.value === 'number' ) {
			const value = '' + prop.value + 'px';
			style.setProperty(this._getVarName(prop), value);
		}

	}
}

console.log('gui/ThemeMgr.js loaded.');