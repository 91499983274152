import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class JsChart - client side implementation of JsChart control
 */
export default class JsChart {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		this.ready = false;
		this.parent = rap.getObject(properties.parent);
		const xid = 'x' + this._psa.getRandomInt(10000000);
		const idw = this.parent.getData("pisasales.CSTPRP.IDW");
		this.xID = xid;
		this.wdgId = idw || xid;
		this.element = document.createElement('div');
		this.element.style.position = 'absolute';
		this.element.style.cursor = 'default';
		this.selectedChartId = '';		
		this.chartContainer = undefined;
		this.parent.append(this.element);
		this._init(properties);
		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
	}

	destroy() {
		this._cleanup();
		this._psa.rmvDomElm(this.element);
		this.parent = null;
		this.ready = false;
	}
	
	onReady() {
		this.ready = true;
	}

	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
			this.layout();
		}
	}

	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = area[2] + 'px';
			this.element.style.height = area[3] + 'px';
			this._drawChart(true);
		}
	}
	
	onSend() {
		// do nothing so far...
	}

	setMetaData(args) {
		if ( args.mtd && this.chtObj ) {
			this.chtObj.mtd = args.mtd;
			//this._drawChart(false);
			//this._redrawChart();
			
		}
	}
							
	setChartData(args) {
		if ( args.cdt && this.chtObj ) {
			this.chtObj.cdt = args.cdt; 
			//this._drawChart(false);
			//this._redrawChart();
			
		}
	}

	doSomething(args) {
		// TODO: do something :-)
	}

	_init(args) {
		this.chtObj = {};
		this.chtObj.mtd = null;
		this.chtObj.cdt = null;
		this.chtObj.chart = null;
	}

	_cleanup() {
		delete this.chtObj;
	}
	
	_canDraw() {
		return this.ready && this.chtObj && this.chtObj.mtd && this.chtObj.cdt;
	}

	_drawChart(resized) {		
		try {
			this._doDrawChart(resized);
		} catch (error) {
			console.error(error);
		}
	}
	
	_doDrawChart(resized) {
		if ( this._canDraw() ) {
			if ( !this.chtObj.chart ) {
				var Idlbl = document.createElement( "Label" );
				Idlbl.innerHTML = "ChartID";
				this.element.appendChild( Idlbl );
				var selectList = document.createElement( 'select' );
				selectList.id = 'selId';
				var df = document.createDocumentFragment();
				for ( var i = 1; i < 16; i++ ) {
					var option = document.createElement( 'option' );
					option.value = i;
					option.appendChild( document.createTextNode( i ) );
					df.appendChild( option );
				}
				selectList.appendChild( df );
				var whichOption = option;
				this.element.appendChild( selectList );
				var _this = this;
				var ex = document.getElementById( "selId" )
				selectList.addEventListener( 'change', function ( event ) {
					;
					var str = ex.options[ex.selectedIndex].value;
					var chartIdInput = str;
					_this.selectedChartId = chartIdInput;
				} );
				console.log( _this.selectedChartId );

				this.chartContainer = document.createElement( 'div' );
				this.chartContainer.style.height = (
					this.element.getBoundingClientRect().height
					-
					selectList.getBoundingClientRect().height
				) + 'px';
				this.element.appendChild( this.chartContainer );
				selectList.addEventListener( 'change', function ( event ) {
					if ( !_this.selectedChartId ) {
						return;
					}
					var cht = new pisasales.Charts.draw(
						_this.chartContainer,
						{
							width: _this.chartContainer.getBoundingClientRect().width,
							height: _this.chartContainer.getBoundingClientRect().height

						},
						{
							CRT_DAT: _this.chtObj.cdt,
							MET_DAT: _this.chtObj.mtd
						},
						_this.selectedChartId
					);

					cht.name = 'Chart';
					cht.elm = _this.element;
					cht.idw = _this.wdgId;
					cht.xid = _this.xID;
					cht.cnt = 0;

					_this.chtObj.chart = cht;
				} );
			} else {
				if ( !this.selectedChartId ) {
					return;
				}

				// TODO: (re-)draw the chart
				var cht = new pisasales.Charts.draw(
					this.chartContainer,
					{
						width: this.chartContainer.getBoundingClientRect().width,
						height: this.chartContainer.getBoundingClientRect().height
					},
					{
						CRT_DAT: this.chtObj.cdt,
						MET_DAT: this.chtObj.mtd
					},
					this.selectedChartId
				);
			}
		}
	}

	_nfySrv(code, par) {
		if ( this.ready ) {
			const param = {};
			param.cod = code;
			param.par = par;
			rap.getRemoteObject(this).notify("JSC_NFY", param);
		}
	}
	
	/** register custom widget type */
	static register() {
		console.log('Registering custom widget JsChart.');
		rap.registerTypeHandler("psawidget.JsChart", {
			factory : function(properties) {
				return new JsChart(properties);
			},
			destructor: "destroy",
			properties: [ "metaData", "chartData"],
			methods: [ "doSomething" ],
			events: [ "JSC_NFY"]
		});
	}
}

console.log('widgets/jschart/JsChart.js loaded.');