import AttachmentObject from '../../../../utils/AttachmentObject';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const DO_LOG = true;

export default class CheckboxKeyHandlingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	onInputKeyDown( domEvent ) {
		if ( [ " ", "Space" ].some( key => DomEventHelper.keyIs( domEvent, key ) ) ) {
			return this.onCheckboxSpace( domEvent );
		}
		if ( DomEventHelper.keyIs( domEvent, "Enter" ) ) {
			return this.onCheckboxEnter( domEvent );
		}
		if ( DomEventHelper.keyIs( domEvent, "Tab" ) ) {
			return this.onCheckboxTab( domEvent );
		}
		if ( DomEventHelper.isSaveEvent( domEvent ) ) {
			return this.onCheckboxSave( domEvent );
		}
		// if ( DomEventHelper.keyIs( domEvent, "Escape" ) ) {
		// 	return this.onCheckboxEscape( domEvent );
		// }
		if ( DomEventHelper.isArrowLeft( domEvent ) ) {
			return this.onCheckboxArrowLeft( domEvent );
		}
		if ( DomEventHelper.isArrowRight( domEvent ) ) {
			return this.onCheckboxArrowRight( domEvent );
		}
		if ( DomEventHelper.isArrowUp( domEvent ) ) {
			return this.onCheckboxArrowUp( domEvent );
		}
		if ( DomEventHelper.isArrowDown( domEvent ) ) {
			return this.onCheckboxArrowDown( domEvent );
		}
		if ( DomEventHelper.isPageUp( domEvent ) ) {
			return this.onCheckboxPageUp( domEvent );
		}
		if ( DomEventHelper.isPageDown( domEvent ) ) {
			return this.onCheckboxPageDown( domEvent );
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onInputKeyPress( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.input.id;
		}
	}

	onInputKeyUp( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( DomEventHelper.keyIs( domEvent, "Escape" ) ) {
			return this.onCheckboxEscape( domEvent );
		}
	}

	onCheckboxSpace( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return this.onInputClick( domEvent );
	}

	onCheckboxEnter( domEvent ) {
		this.onInputBlur( domEvent );
		const cell = this.cell;
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputEnter ) ) {
			return false;
		}
		return cell.onInputEnter( domEvent );
	}

	onCheckboxTab( domEvent ) {
		this.onInputBlur( domEvent );
		const cell = this.cell;
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputTab ) ) {
			return false;
		}
		return cell.onInputTab( domEvent );
	}

	onCheckboxSave( domEvent ) {
		if ( !this.dirty || !this.changed || !this.editingAllowed ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		this.informAboutFullSave();
		this.destroySelfAndRestoreCell();
		Warner.traceIf( DO_LOG, `A keyboard save operation was performed on a` +
			`${ this.isTripleState ? " triple state" : "" } checkbox.` );
		return true;
	}

	onCheckboxEscape( domEvent ) {
		this.destroySelfAndRestoreCell();
		this.informAboutCancel();
		const cell = this.cell;
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputEscape ) ) {
			return false;
		}
		return cell.onInputEscape( domEvent );
	}

	onCheckboxArrowLeft( domEvent ) {}

	onCheckboxArrowRight( domEvent ) {}

	onCheckboxArrowUp( domEvent ) {}

	onCheckboxArrowDown( domEvent ) {}

	onCheckboxPageUp( domEvent ) {}

	onCheckboxPageDown( domEvent ) {}

}
