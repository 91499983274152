import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';

export default class SelectionManagerRegistry extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// a row is (should be) registered/ added to this "previously focused" list
		// after it loses its focus and its "status" as the focus row
		Object.defineProperty( parentObject, "previouslyFocusedRows", {
			value: [],
			writable: false
		} );
		// a model row id is (should be) added to this "focused" list once and
		// each time the model item becomes the "focused" status
		Object.defineProperty( parentObject, "focusedModelRowIdsRegistry", {
			value: [],
			writable: false
		} );
		// a cell id (column id and row id) should be added to this registry each
		// time a cell gets focused
		Object.defineProperty( parentObject, "focusedCellsRegistry", {
			value: [],
			writable: false
		} );
		// the maximum amount of elements to be stored in the focus registries
		Object.defineProperty( parentObject, "focusRegistryMaxLength", {
			value: 10,
			writable: false
		} );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get activeRow() {
		let focusRow = this.focusRow;
		if ( Validator.isObject( focusRow ) ) {
			return focusRow;
		}
		focusRow = this.previousFocusRow;
		if ( Validator.isObject( focusRow ) ) {
			return focusRow;
		}
		return void 0;
	}

	get activeModelItem() {
		const activeRow = this.activeRow;
		if ( Validator.isObjectPath( activeRow, "activeRow.item" ) ) {
			return activeRow.item;
		}
		const modelItemRowId = this.previousFocusModelItemRowId;
		const modelItem = this.getModelItemByRowId( modelItemRowId );
		if ( Validator.isObject( modelItem ) ) {
			return modelItem;
		}
		const allFocusedModelItems = this.allFocusedModelItems;
		if ( Validator.isArray( allFocusedModelItems, true ) ) {
			return allFocusedModelItems[ 0 ];
		}
		// TODO get first selected visible (in the UI) model item
		// TODO get first visible (in the current UI) model item
		// TODO get first selected model item
		return void 0;
	}

	get allFocusedModelItems() {
		if ( !Validator.isObjectPath( this.hostObject, "hostObject.model" ) ) {
			return void 0;
		}
		const flatModel = this.hostObject.model.flatModel;
		if ( !Validator.isIterable( flatModel ) ) {
			return void 0;
		}
		return [ ...flatModel ].filter( modelItem => {
			return Validator.isObject( modelItem ) && modelItem.isFocused;
		} );
	}

	get firstFocusedModelItem() {
		const allFocusedModelItems = this.allFocusedModelItems;
		return Validator.isArray( allFocusedModelItems, true ) ?
			allFocusedModelItems[ 0 ] : void 0;
	}

	get lastFocusedModelItem() {
		let focusRow = this.focusRow;
		if ( Validator.isObjectPath( focusRow, "focusRow.item" ) &&
			!!focusRow.item.isFocused ) {
			return focusRow.item;
		}
		const modelItemRowId = this.previousFocusModelItemRowId;
		const modelItem = this.getModelItemByRowId( modelItemRowId );
		if ( Validator.isObject( modelItem ) && !!modelItem.isFocused ) {
			return modelItem;
		}
		const allFocusedModelItems = this.allFocusedModelItems;
		if ( Validator.isArray( allFocusedModelItems, true ) ) {
			return allFocusedModelItems[ 0 ];
		}
		return void 0;
	}

	get previousFocusRowId() {
		const previousFocusRow = this.previousFocusRow;
		const rowId = Validator.isObject( previousFocusRow ) ?
			Number( previousFocusRow.rowId ) : void 0;
		return Validator.isPositiveInteger( rowId ) ? rowId : void 0;
	}

	get previousFocusRow() {
		if ( !Validator.isArray( this.previouslyFocusedRows ) ) {
			this.previouslyFocusedRows = [];
			return void 0;
		}
		let lastValidIndex = this.previouslyFocusedRows.length - 1;
		let lastValidFocusedRow = this.previouslyFocusedRows[ lastValidIndex ];
		while ( !Validator.is( lastValidFocusedRow, this.rowClassName ) &&
			lastValidIndex > 0 ) {
			lastValidIndex--;
			lastValidFocusedRow = this.previouslyFocusedRows[ lastValidIndex ];
		}
		return !Validator.is( lastValidFocusedRow, this.rowClassName ) ? void 0 :
			lastValidFocusedRow;
	}

	get previousFocusModelItemRowId() {
		if ( !Validator.isArray( this.focusedModelRowIdsRegistry ) ) {
			this.focusedModelRowIdsRegistry = [];
			return void 0;
		}
		let lastValidIndex = this.focusedModelRowIdsRegistry.length - 1;
		let lastValidFocusedModelItemRowId = this.focusedModelRowIdsRegistry[ lastValidIndex ];
		while ( !Validator.isPositiveInteger( lastValidFocusedModelItemRowId ) &&
			lastValidIndex > 0 ) {
			lastValidIndex--;
			lastValidFocusedModelItemRowId = this.focusedModelRowIdsRegistry[ lastValidIndex ];
		}
		return !Validator.isPositiveInteger( lastValidFocusedModelItemRowId ) ?
			void 0 : lastValidFocusedModelItemRowId;
	}

	get previousFocusModelItem() {
		const modelItemRowId = this.previousFocusModelItemRowId;
		const modelItem = this.getModelItemByRowId( modelItemRowId );
		return Validator.isObject( modelItem ) ? modelItem : void 0;
	}

	get lastFocusedCell() {
		if ( !Validator.isArray( this.focusedCellsRegistry ) ) {
			this.focusedCellsRegistry = [];
			return void 0;
		}
		if ( this.focusedCellsRegistry.length < 1 ) {
			return void 0;
		}
		return this.focusedCellsRegistry[ this.focusedCellsRegistry.length - 1 ];
	}

	_registerAsPreviouslyFocused( row ) {
		if ( !Validator.is( row, this.rowClassName ) || !row.isRendered ) {
			return false;
		}
		if ( !Validator.isArray( this.previouslyFocusedRows ) ) {
			this.previouslyFocusedRows = [];
		}
		const lastRegisteredRow = this.previouslyFocusedRows.length < 1 ?
			void 0 :
			this.previouslyFocusedRows[ this.previouslyFocusedRows.length - 1 ];
		if ( lastRegisteredRow != row ) {
			this.previouslyFocusedRows.push( row );
		}
		return this._reduceRowFocusRegistry( true );
	}

	_addRowIdToFocusRegistry( { row, modelItem, rowId } ) {
		if ( !Validator.isArray( this.focusedModelRowIdsRegistry ) ) {
			this.focusedModelRowIdsRegistry = [];
		}
		const lastRegisteredId = this.focusedModelRowIdsRegistry.length < 1 ?
			void 0 :
			this.focusedModelRowIdsRegistry[ this.focusedModelRowIdsRegistry.length - 1 ];
		if ( Validator.is( row, this.rowClassName ) ) {
			const rowId = Number( row.rowId );
			if ( Validator.isPositiveInteger( rowId ) ) {
				if ( rowId != lastRegisteredId ) {
					this.focusedModelRowIdsRegistry.push( rowId );
				}
				return this._reduceModelFocusRegistry( true );
			}
		}
		rowId = Number( rowId );
		if ( Validator.isValidNumber( rowId ) ) {
			const modelItem = this.getModelItemByRowId( rowId );
			if ( Validator.isObject( modelItem ) ) {
				if ( rowId != lastRegisteredId ) {
					this.focusedModelRowIdsRegistry.push( rowId );
				}
				return this._reduceModelFocusRegistry( true );
			}
		}
		if ( Validator.isObject( modelItem ) ) {
			const idr = Number( modelItem.idr );
			if ( Validator.isPositiveNumber( idr ) ) {
				if ( idr != lastRegisteredId ) {
					this.focusedModelRowIdsRegistry.push( idr );
				}
				return this._reduceModelFocusRegistry( true );
			}
			const xid = Number( modelItem.xid );
			if ( Validator.isPositiveNumber( xid ) ) {
				if ( xid != lastRegisteredId ) {
					this.focusedModelRowIdsRegistry.push( xid );
				}
				return this._reduceModelFocusRegistry( true );
			}
		}
		return this._reduceModelFocusRegistry( false );
	}

	_addCellToFocusRegistry( { columnId, rowId } ) {
		if ( !Validator.isArray( this.focusedCellsRegistry ) ) {
			this.focusedCellsRegistry = [];
		}
		if ( Validator.isString( columnId ) ) {
			columnId = Number( columnId );
		}
		if ( Validator.isString( rowId ) ) {
			rowId = Number( rowId );
		}
		if ( [ columnId, rowId ].some( id => !Validator.isPositiveInteger( id ) ) ) {
			return false;
		}
		const lastFocusedCell = this.lastFocusedCell;
		if ( Validator.isObject( lastFocusedCell ) &&
			lastFocusedCell.columnId === columnId &&
			lastFocusedCell.rowId === rowId ) {
			return true;
		}
		this.focusedCellsRegistry.push( { columnId: columnId, rowId: rowId } );
		return this._reduceCellFocusRegistry( true );
	}

	_reduceRowFocusRegistry( returnValue ) {
		if ( !Validator.isArray( this.previouslyFocusedRows ) ) {
			this.previouslyFocusedRows = [];
			return returnValue;
		}
		if ( this.previouslyFocusedRows.length <= this.focusRegistryMaxLength ) {
			return returnValue;
		}
		this.previouslyFocusedRows.splice(
			0, this.previouslyFocusedRows.length - this.focusRegistryMaxLength );
		return returnValue;
	}

	_reduceModelFocusRegistry( returnValue ) {
		if ( !Validator.isArray( this.focusedModelRowIdsRegistry ) ) {
			this.focusedModelRowIdsRegistry = [];
			return returnValue;
		}
		if ( this.focusedModelRowIdsRegistry.length <= this.focusRegistryMaxLength ) {
			return returnValue;
		}
		this.focusedModelRowIdsRegistry.splice(
			0, this.focusedModelRowIdsRegistry.length - this.focusRegistryMaxLength );
		return returnValue;
	}

	_reduceCellFocusRegistry( returnValue ) {
		if ( !Validator.isArray( this.focusedCellsRegistry ) ) {
			this.focusedCellsRegistry = [];
			return returnValue;
		}
		if ( this.focusedCellsRegistry.length <= this.focusRegistryMaxLength ) {
			return returnValue;
		}
		this.focusedCellsRegistry.splice(
			0, this.focusedCellsRegistry.length - this.focusRegistryMaxLength );
		return returnValue;
	}

}
