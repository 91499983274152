import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const DO_LOG = false;

export default class XtwBodySelectionExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	updateSelectedRowsUi() {
		if ( !Validator.isObject( this.selectionManager ) ||
			!Validator.isFunction( this.selectionManager.updateSelectedRowsUi ) ) {
			return false;
		}
		this.selectionManager.updateSelectedRowsUi();
		return true;
	}

	selectDataRow( idr, updateUi = true ) {
		if ( !Validator.isObject( this.model ) ||
			!Validator.isFunction( this.model.selectDataRow ) ) {
			return false;
		}
		return this.model.selectDataRow( idr, updateUi );
	}

	selectDataRows( idrList, updateUi = true ) {
		return this._selectOrDeselectDataRows( {
			idrList: idrList,
			updateUi: updateUi,
			select: true
		} );
	}

	deselectDataRow( idr, updateUi = true ) {
		if ( !Validator.isObject( this.model ) ||
			!Validator.isFunction( this.model.deselectDataRow ) ) {
			return false;
		}
		return this.model.deselectDataRow( idr, updateUi );
	}

	deselectDataRows( idrList, updateUi = true ) {
		return this._selectOrDeselectDataRows( {
			idrList: idrList,
			updateUi: updateUi,
			select: false
		} );
	}

	focusDataRow( idr ) {
		if ( !Validator.isObject( this.model ) ||
			!Validator.isFunction( this.model.focusDataRow ) ) {
			return false;
		}
		return this.model.focusDataRow( idr );
	}

	unfocusDataRow( idr ) {
		if ( !Validator.isObject( this.model ) ||
			!Validator.isFunction( this.model.unfocusDataRow ) ) {
			return false;
		}
		return this.model.unfocusDataRow( idr );
	}

	_selectOrDeselectDataRows( { idrList, updateUi = true, select = true } ) {
		const functionName = !!select ? "selectDataRow" : "deselectDataRow";
		if ( !Validator.isObject( this.model ) ||
			!Validator.isFunction( this.model[ functionName ] ) ) {
			return false;
		}
		if ( Validator.isString( idrList ) ) {
			idrList = Number( idrList );
		}
		if ( Validator.isPositiveInteger( idrList ) ) {
			idrList = [ idrList ];
		}
		if ( !Validator.isIterable( idrList ) ) {
			return false;
		}
		const dataRowIds = [ ...idrList ];
		let allDataRowsSuccessfullyProcessed = true;
		for ( let dataRowId of dataRowIds ) {
			if ( !this.model[ functionName ]( dataRowId, false ) ) {
				allDataRowsSuccessfullyProcessed = false;
			}
		}
		if ( !updateUi ) {
			return allDataRowsSuccessfullyProcessed;
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		this.selectionManager.updateSelectedRowsUi();
		return allDataRowsSuccessfullyProcessed;
	}

	adjustSelectionAfterInitialisation() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			Warner.traceIf( DO_LOG, `The selection manager is invalid or does not` +
				` exist.` );
			// that's bad
			return false;
		}
		if ( !selectionManager.somethingShouldAlwaysBeSelected ) {
			// there is no need to "force select" something
			return true;
		}
		if ( Validator.isSet( selectionManager.currentRowSelection, true ) ) {
			// something should be selected and something is selected
			return true;
		}
		// something should be selected, but nothing is, so let's select the first
		// row
		Warner.traceIf( DO_LOG, `please select something` );
		return true;
	}

	doWithoutLosingSelectionOnSingleSelect( callback ) {
		if ( !Validator.isFunction( callback ) ) {
			return void 0;
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return callback();
		}
		const onlyOneItemCanBeSelected = selectionManager.onlyOneItemCanBeSelected;
		if ( !onlyOneItemCanBeSelected ) {
			return callback();
		}
		const selectionBefore = new Set( selectionManager.currentRowSelection );
		const returnValue = callback();
		if ( selectionBefore.size <= 0 ) {
			return returnValue;
		}
		const selectionAfter = new Set( selectionManager.currentRowSelection );
		if ( selectionAfter.size >= 1 && !selectionManager.selectionIsForcedSingle ) {
			return returnValue;
		}
		const rowsSelectedBefore = Validator.sort( selectionBefore );
		const rowsSelectedAfter = Validator.sort( selectionAfter );
		if ( Validator.arraysEqual( rowsSelectedBefore, rowsSelectedAfter ) ) {
			return returnValue;
		}
		this.selectDataRows( rowsSelectedBefore );
		if ( Validator.isObject( this.selectionManager ) ) {
			this.selectionManager.informAboutRowSelection( -1, true );
		}
		return returnValue;
	}

}
