import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import HtmParsingUtils from '../../utils/HtmParsingUtils';
import Validator from '../../utils/Validator';

const CK_EDITOR_FIVE_CLASSES = [ "ck", "ck-editor", "ck-reset",
	"ck-editor__main", "ck-content", "ck-editor__editable"
];

/**
 * JS part of HtmVwr custom widget
 */
export default class HtmVwr {

	/**
	 * constructs a new instance
	 * @param {Object} properties initialization properties
	 */
	constructor( properties ) {
		this._psa = PSA.getInst();
		this.ready = false;
		this.ifmRdy = false;
		/**The IFrame which will be build up*/
		this.ifmWin = null;
		/**The frame header*/
		this.frameHeader = "";
		/**The frame body*/
		this.frameBody = "";
		this.htmlAsIs = false;
		this.keepPlc = false;
		/** the body style */
		this.bodyStyle = '';
		this._psa.bindAll( this, [ "layout", "onReady", "onRender", 'onFocusIn' ] );
		this.widgetId = properties.parent;
		this.parent = rap.getObject( this.widgetId );
		this.element = document.createElement( "div" );
		this.parent.append( this.element );
		this.blocker = null;
		this.dmyClkLsr = this._psa.bind( this, this._onDummyImgClk );
		this._bldVwr();
		rap.on( "render", this.onRender );
		this.parent.addListener( 'FocusIn', this.onFocusIn );
		this.parent.addListener( "Resize", this.layout );
	}

	onReady() {
		this.ready = true;
		this._createIframeBlocker();
		this.layout();
	}

	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off( "render", this.onRender );
			this.onReady();
			rwt.widgets.util.IframeManager.getInstance().add( this );
		}
	}

	/**
	 * Triggerd upon gaining focus.
	 */
	onFocusIn() {
		if ( this.ready ) {
			try {
				// make sure that not a single menu is in sight
				pisasales.ScrMen.static.closeAllMenus();
				this._sendFocNfy( this );
			} catch ( err ) {
				// *gulp*
			}
		}
	}

	onLoaFrm() {
		this.ifmRdy = true;
		this._setFrameHeader( this.frameHeader );
		this._setFrameBody( this.frameBody, this.bodyStyle );

		const self = this;
		const iframeContentWindow = this.ifmWin.contentWindow;
		if ( iframeContentWindow ) {
			const doc = iframeContentWindow.document;
			iframeContentWindow.addEventListener( "blur", ( evt ) => {
				self._handleBlur( evt );
			} );
			iframeContentWindow.addEventListener( "focus", ( evt ) => {
				self._handleFocus( evt );
			} );
			doc.addEventListener( "keydown", ( evt ) => {
				self._handleKeyDown( evt );
			} );
		}
	}

	setFrameContent( content ) {
		if ( content ) {
			this._setFrameHeader( content.header );
			this._setFrameBody( content.body, content.style, content.addnfo );
		}
	}

	setHtmlAsIs( flag ) {
		this.htmlAsIs = !!flag;
	}

	setKeepPlc( flag ) {
		this.keepPlc = !!flag;
	}

	destroy() {
		delete this.ifmWin;
		delete this.blocker;
		delete this.dmyClkLsr;
		if ( this.parent && this.parent.removeListener ) {
			this.parent.removeListener( 'FocusIn', this.onFocusIn );
		}
		if ( this.element && this.element.parentNode ) {
			this.element.parentNode.removeChild( this.element );
			rwt.widgets.util.IframeManager.getInstance().remove( this );
		}
	}

	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			this.element.style.left = area[ 0 ] + "px";
			this.element.style.top = area[ 1 ] + "px";
			this.element.style.width = area[ 2 ] + "px";
			this.element.style.height = area[ 3 ] + "px";
		}
	}

	/**
	 * Returns the hash code of the widget.
	 * @return the identifier of the widget
	 */
	toHashCode() {
		return this.widgetId;
	}

	/**
	 * called by RAP's iframe manager to block mouse events for the iframe
	 */
	block() {
		if ( this.blocker ) {
			this.blocker.style.display = '';
		}
	}

	/**
	 * called by RAP's iframe manager to allow mouse events for the iframe
	 */
	release() {
		if ( this.blocker ) {
			this.blocker.style.display = 'none';
		}
	}

	/**
	 * Iframe blocker. Blocks the iframe when active incoming mouse events are detected
	 * over it. This is done in order to prevent the iframe from stealing the mouse events.
	 */
	_createIframeBlocker() {
		const blocker = document.createElement( 'div' );
		blocker.id = 'PSA.ifr.blocker-' + this.widgetId;
		var blockerStyle = blocker.style;
		blockerStyle.position = 'absolute';
		blockerStyle.left = 0;
		blockerStyle.top = 0;
		blockerStyle.width = '100%';
		blockerStyle.height = '100%';
		blockerStyle.zIndex = 1000000;
		blockerStyle.display = 'none';
		blockerStyle.background = 'transparent';
		this.element.appendChild( blocker );
		this.blocker = blocker;
	}

	_bldVwr() {
		const ifw = document.createElement( "iframe" );

		ifw.frameBorder = "0";
		ifw.frameSpacing = "0";

		ifw.marginWidth = "0";
		ifw.marginHeight = "0";

		ifw.hspace = "0";
		ifw.vspace = "0";

		ifw.border = "0";
		ifw.unselectable = "on";
		ifw.allowTransparency = "true";

		ifw.style.position = "absolute";
		ifw.style.top = 0;
		ifw.style.left = 0;

		ifw.setAttribute( "class", "htmVwrFrm" );
		ifw.width = "100%";
		ifw.height = "100%";

		//inheriting scope
		const scp = this;
		const loaHdl = () => {
			scp.onLoaFrm();
		};
		if ( ifw.addEventListener ) {
			ifw.addEventListener( "load", loaHdl, false );
		} else if ( ifw.attachEvent ) {
			ifw.attachEvent( "onload", loaHdl );
		} else {
			ifw.onload = loaHdl;
		}
		this.extCss = [];
		this.cke5StyleTag = void 0;
		const styles = document.styleSheets;
		for ( let i = 0; i < styles.length; ++i ) {
			const href = styles[ i ].href;
			if ( this._psa.isStr( href ) ) {
				if ( ( href.indexOf( 'font' ) > -1 ) || ( href.indexOf( 'roboto' ) > -1 ) || ( href.indexOf( 'pisasales' ) > -1 ) || ( href.indexOf( 'theme/pisa' ) > -1 ) ) {
					this.extCss.push( href );
				}
			}
			if ( this.cke5StyleTag instanceof HTMLElement ) continue;
			if ( !styles[ i ] || typeof styles[ i ] != "object" ||
				Object.getPrototypeOf( styles[ i ] ).constructor.name != "CSSStyleSheet" ) continue;
			if ( !( styles[ i ].ownerNode instanceof HTMLElement ) ) continue;
			if ( !styles[ i ].rules || typeof styles[ i ].rules != "object" ||
				Object.getPrototypeOf( styles[ i ].rules ).constructor.name != "CSSRuleList" ) continue;
			let anyCke5Rule = [ ...styles[ i ].rules ].find( rule => !!rule &&
				typeof rule == "object" && typeof rule.selectorText == "string" &&
				rule.selectorText.startsWith( ".ck.ck-reset, .ck.ck-reset_all" ) );
			if ( !anyCke5Rule || typeof anyCke5Rule != "object" ) continue;
			this.cke5StyleTag = styles[ i ].ownerNode.cloneNode( true );
		}
		this.ifmWin = ifw;
		this.element.appendChild( this.ifmWin );
	}

	_setFrameBody( body, style, addnfo ) {
		body = this.addCke5StyleContainer( body );
		// JSON retrieval
		this.frameBody = body || '';
		this.bodyStyle = style || '';
		if ( this.ifmRdy && this.ifmWin ) {
			const frmPlc = this.ifmWin;
			if ( frmPlc.contentDocument ) {
				const cd = frmPlc.contentDocument;
				const bdy_elm = cd.body;
				bdy_elm.innerHTML = body;
				if ( !this.keepPlc ) {
					this.replacePlaceholderNamesWithValuesOnElement( bdy_elm );
				}
				if ( this._psa.isStr( this.bodyStyle ) ) {
					bdy_elm.setAttribute( 'style', this.bodyStyle );
				} else {
					bdy_elm.removeAttribute( 'style' );
				}
				const psa = this._psa;
				this._setLinkTarget( cd, psa );
				this._setImgSize( cd );
				this._setImageLsr( cd );
				if ( this.htmlAsIs && addnfo ) {
					this._setLinkHandler( cd );
					const scr = addnfo.scripts || [];
					if ( scr.length > 0 ) {
						this._loadOneScript( bdy_elm, scr, 0, psa );
					}
				}
				// reset scrollbars
				bdy_elm.scrollTop = 0;
				bdy_elm.scrollLeft = 0;
			}
		}
	}

	addCke5StyleContainer( body ) {
		if ( typeof body != "string" ) return body;
		body = `<div class="` + CK_EDITOR_FIVE_CLASSES.join( " " ) + `">` + body + "</div>";
		return body;
	}

	_setLinkHandler( doc ) {
		const self = this;
		const psa = this._psa;
		doc._handlePsaLinks = function ( fdoc ) {
			try {
				self._setLinkTarget( fdoc || doc, psa );
			} catch ( e ) {
				console.warn( 'Error while link handling:', e );
			}
		};
	}

	_loadOneScript( bdy_elm, scr, idx, psa ) {
		if ( idx < scr.length ) {
			const s = scr[ idx ];
			if ( psa.isStr( s.src ) || psa.isStr( s.code ) ) {
				const se = document.createElement( 'script' );
				se.type = s.type || 'text/javascript';
				if ( psa.isStr( s.src ) ) {
					se.src = s.src;
				} else {
					se.innerHTML = s.code;
				}
				const self = this;
				se.onload = function () {
					// script loaded, load next
					self._loadOneScript( bdy_elm, scr, idx + 1, psa );
				}
				bdy_elm.appendChild( se );
			}
		}
	}

	_setFrameHeader( header ) {
		// JSON retrieval
		this.frameHeader = header;
		if ( this.ifmRdy && this.ifmWin ) {
			const frmPlc = this.ifmWin;
			if ( frmPlc.contentDocument ) {
				const cd = frmPlc.contentDocument;
				cd.head.innerHTML = header;
				// link style sheets so font-awesome / fontpisa / pisaext.css can be used within the frame
				for ( let i = 0; i < this.extCss.length; ++i ) {
					cd.head.innerHTML += '<link rel="stylesheet" href="' + this.extCss[ i ] + '" type="text/css">';
				}
				if ( this.cke5StyleTag instanceof HTMLElement ) {
					cd.head.appendChild( this.cke5StyleTag );
				}
			}
		}
	}

	_setLinkTarget( doc, psa ) {
		const list = doc.body.getElementsByTagName( 'a' );
		if ( list && list.length ) {
			const self = this;
			for ( let i = list.length - 1; i >= 0; --i ) {
				const a = list[ i ];
				if ( a && a.href ) {
					let href = a.href;
					let special = false;
					if ( href ) {
						if ( !psa.isStr( href ) ) {
							// could be a SVG link...
							href = href.baseVal || '';
							special = true;
						}
					}
					try {
						if ( psa.isPsaObjLink( href ) ) {
							// that's a PiSA object link - we need a special handler
							a.onclick = function ( e ) {
								self._handlePsaObjLink( e, href, psa );
							}
							// don't remove the original href, so copy&paste will contain the correct reference!
						} else if ( !special ) {
							// a regular link; just ensure that it opens a new tab
							a.target = '_blank';
						}
					} catch ( e ) {
						console.warn( 'Error while processing y hyperlink:', e );
					}
				}
			}
		}
	}

	_setImgSize( doc ) {
		const imgs = doc.body.getElementsByTagName( 'img' );
		if ( imgs && imgs.length ) {
			for ( let i = imgs.length - 1; i >= 0; --i ) {
				const img = imgs[ i ];
				if ( isCkEditor5ResizedImage( img ) ) {
					continue;
				}
				const par = img.parentElement;
				if ( par && !this._psa.isStr( par.innerText ) ) {
					// just the image - drop all size attributes and set "max-width" to 99.9%
					const sty = img.style;
					sty.width = '';
					sty.height = '';
					sty.maxHeight = '';
					sty.maxWidth = '99.9%';
					img.removeAttribute( 'width' );
					img.removeAttribute( 'height' );
				}
			}
		}
	}

	_onDummyImgClk( e ) {
		if ( e.button !== 2 ) {
			// not a right click --> do nothing
			return;
		}
		let img = null;
		const tgt = e.currentTarget || e.target;
		if ( 'SPAN' === tgt.tagName ) {
			const span = tgt;
			if ( span.children.length > 0 ) {
				const chl = span.children[ 0 ];
				if ( 'IMG' === chl.tagName ) {
					img = chl;
					span.classList.remove( 'psaimgwrp' );
				}
			}
		} else if ( 'IMG' === tgt.tagName ) {
			img = tgt;
		}
		if ( img ) {
			tgt.removeEventListener( 'contextmenu', this.dmyClkLsr );
			e.stopPropagation();
			e.preventDefault();
			if ( img.getAttribute( 'psadummy' ) ) {
				const org = img.getAttribute( 'orgsrc' );
				if ( this._psa.isStr( org ) ) {
					const wdt = img.getAttribute( 'orgwdt' );
					const hgt = img.getAttribute( 'orghgt' );
					const ttl = img.getAttribute( 'orgttl' );
					img.removeAttribute( 'psadummy' );
					img.removeAttribute( 'orgsrc' );
					img.removeAttribute( 'orgwdt' );
					img.removeAttribute( 'orghgt' );
					img.removeAttribute( 'width' );
					img.removeAttribute( 'height' );
					if ( wdt ) {
						img.setAttribute( 'width', wdt );
					}
					if ( hgt ) {
						img.setAttribute( 'height', hgt );
					}
					img.setAttribute( 'title', ( this._psa.isStr( ttl ) ? ttl : org ) );
					img.style.cursor = '';
					img.classList.remove( 'psadmyimg' );
					img.setAttribute( 'src', org );
				}
				if ( tgt !== img ) {
					// drop all content and show just the image
					tgt.innerHTML = '';
					tgt.appendChild( img );
				}
			}
		}
	}

	_setImageLsr( doc ) {
		// --- !!! --- deactivated so far
		// const imgs = doc.body.getElementsByTagName('img');
		// if ( imgs && imgs.length ) {
		// 	for ( let i=imgs.length-1 ; i >=0 ; --i ) {
		// 		const img = imgs[i];
		// 		if ( img.getAttribute('psadummy') ) {
		// 			const parent = img.parentElement;
		// 			if ( ('SPAN' === parent.tagName) && parent.className.includes('psaimgwrp') ) {
		// 				parent.addEventListener('contextmenu', this.dmyClkLsr);
		// 			}
		// 			else {
		// 				img.style.cursor = 'pointer';
		// 				img.addEventListener('contextmenu', this.dmyClkLsr);
		// 			}
		// 		}
		// 	}
		// }
	}

	_handlePsaObjLink( e, href, psa ) {
		e.stopPropagation();
		e.preventDefault();
		if ( psa.isStr( href ) ) {
			// just notify the web server to handle this...
			rap.getRemoteObject( this ).notify( 'HTM_OBJ_LNK', { href: href } );
		}
	}

	_handleBlur( evt ) {
		this._psa.cliCbkWdg._blur( evt );
	}

	_handleFocus( evt ) {
		if ( this.parent ) {
			this.parent.forceFocus( true );
		}
	}

	/**
	 * sends a "focus gained" notification
	 * @param {Object} scope the inherited scope
	 */
	_sendFocNfy( scope ) {
		if ( scope.ready ) {
			var rmo = rap.getRemoteObject( scope );
			rmo.notify( 'HTM_VWR_FOC', { operation: 'focus' } );
		}
	}

	/**
	 * handles keydown events
	 * @param {KeyboardEvent} event the keyboard event
	 * @returns {Boolean} true if finally handled; false otherwise
	 */
	_handleKeyDown( event ) {
		if ( ( event.ctrlKey || event.metaKey ) && !event.altKey && !event.shiftKey && event.key === 'c' ) {
			// don't eat <Ctrl><C>
			return false;
		}
		const hdl = this._psa.keyHdl;
		if ( hdl ) {
			const keyEvent = hdl.domKeyEventToRap( event, "keydown" );
			hdl.hdlKey( keyEvent, true );
			rwt.event.EventHandlerUtil.stopDomEvent( event );
		}
		return false;
	}

	replacePlaceholderNamesWithValuesOnElement( bodyElement ) {
		if ( !Validator.couldBe( bodyElement, "Element" ) ||
			!Validator.couldBe( bodyElement, "HTML" ) ) {
			return false;
		}
		const definitionTags = bodyElement.getElementsByTagName( "dfn" );
		if ( !Validator.isIterable( definitionTags ) ) {
			return false;
		}
		for ( let definitionTag of [ ...definitionTags ] ) {
			const parentElement = definitionTag.parentElement;
			if ( !Validator.couldBe( parentElement, "Element" ) ||
				!Validator.couldBe( parentElement, "HTML" ) ) {
				continue;
			}
			const tagContent = this.extractElementFromPlaceholderValue( definitionTag );
			if ( !Validator.couldBe( tagContent, "Element" ) ||
				!Validator.couldBe( tagContent, "HTML" ) ) {
				continue;
			}
			parentElement.insertBefore( tagContent, definitionTag );
			definitionTag.remove();
		}
		return true;
	}

	extractElementFromPlaceholderValue( definitionTag ) {
		if ( !Validator.couldBe( definitionTag, "Element" ) ||
			!Validator.couldBe( definitionTag, "HTML" ) ||
			!Validator.is( definitionTag.dataset, "DOMStringMap" ) ) {
			return void 0;
		}
		const dataValue = definitionTag.dataset.value ||
			definitionTag.getAttribute( "data-value" );
		if ( typeof dataValue != "string" ) {
			return void 0;
		}
		const dataSingleLine = definitionTag.dataset.singleline ||
			definitionTag.getAttribute( "data-singleline" );
		const isSingleLine = dataSingleLine == true || dataSingleLine == "true";
		const container = isSingleLine ? window.document.createElement( "span" ) :
			window.document.createElement( "div" );
		if ( dataValue.length < 1 ) {
			return container;
		}
		const decodedDataValue = HtmParsingUtils.decode64( dataValue );
		container.innerHTML = decodedDataValue;
		container.style = definitionTag.style;
		return container;
	}

	/** register custom widget type */
	static register() {
		console.log( 'Registering custom widget HtmVwr.' );
		rap.registerTypeHandler( "psawidget.HtmVwr", {
			factory: function ( properties ) {
				return new HtmVwr( properties );
			},
			destructor: "destroy",
			methods: [ "" ],
			properties: [ "frameContent", "htmlAsIs", "keepPlc" ],
			events: [ "HTM_VWR_FOC", "HTM_OBJ_LNK" ]
		} );
	}
}

function isCkEditor5ResizedImage( imageElement ) {
	// instanceof does not work in/on/for elements inside iframes
	if ( !Validator.is( imageElement, "HTMLImageElement" ) ||
		!Validator.is( imageElement.dataset, "DOMStringMap" ) ) {
		return false;
	}
	if ( !Validator.isString( imageElement.id ) ||
		!imageElement.id.startsWith( "pisa-ck-editor-image-" ) ) {
		return false;
	}
	if ( ![ "originalSource", "originalAlt" ].some( attribute =>
			attribute in imageElement.dataset &&
			Validator.isString( imageElement.dataset[ attribute ] ) ) ) {
		return false;
	}
	// any image inserted into the ck editor, whose height or/and width were not
	// changed, only has the "height" or/and "width" attribute(s) and style value(s);
	// any image that was resized in/by the ck editor, also has the attributes
	// "min-width" & "max-width" or "min-height" & "max-height", among the
	// standard "width" and "height" attributes and style values
	return hasAllHeightAttributes( imageElement ) || hasAllWidthAttributes( imageElement );
}

function hasAllHeightAttributes( imageElement ) {
	// instanceof does not work in/on/for elements inside iframes
	if ( !Validator.is( imageElement, "HTMLImageElement" ) ) {
		return false;
	}
	const heightAttributeValue = imageElement.getAttribute( "height" );
	return Validator.checkAttrRange(heightAttributeValue, 0.01, [ imageElement.style.height, imageElement.style.minHeight, imageElement.style.maxHeight ]);
}

function hasAllWidthAttributes( imageElement ) {
	// instanceof does not work in/on/for elements inside iframes
	if ( !Validator.is( imageElement, "HTMLImageElement" ) ) {
		return false;
	}
	const widthAttributeValue = imageElement.getAttribute( "width" );
	return Validator.checkAttrRange(widthAttributeValue, 0.01, [ imageElement.style.width, imageElement.style.minWidth, imageElement.style.maxWidth ]);
}

console.log( 'widgets/htmvwr/HtmVwr.js loaded.' );
