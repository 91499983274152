import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';

export default class XtwSelectionCellInsertionDummyExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	_addInsertionStar( focused = false ) {
		if ( !this.isRendered ) {
			return false;
		}
		this.removeSelectionArrow();
		const element = this.element;
		element.style.alignItems = "center";
		const insertionStar = !!focused ? this.focusedInsertionStar : this.insertionStar;
		element.appendChild( insertionStar );
		return true;
	}

	addInsertionStar() {
		return this._addInsertionStar( false );
	}

	removeInsertionStar() {
		return this.removeSelectionArrow();
	}

	addFocusedInsertionStar() {
		return this._addInsertionStar( true );
	}

	get insertionStar() {
		const starSpan = window.document.createElement( "span" );
		starSpan.style.marginLeft = "auto";
		starSpan.style.marginRight = "auto";
		starSpan.style.fontSize = "16px";
		starSpan.style.color = "#717171";
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( "fa", "fa-certificate" );
		starSpan.appendChild( italicIconPlaceholder );
		return starSpan;
	}

	get focusedInsertionStar() {
		const starSpan = window.document.createElement( "span" );
		starSpan.style.marginLeft = "auto";
		starSpan.style.marginRight = "auto";
		starSpan.style.fontSize = "16px";
		starSpan.style.color = "#717171";
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( "fa", "fa-certificate" );
		starSpan.appendChild( italicIconPlaceholder );
		return starSpan;
	}

}
