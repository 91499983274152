import PSA from '../psa';

const AVL_KEYS	= 'ABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890';
const KEY_UID	= 'Unidentified'
	
const KEY_BKSP	= 'Backspace';
const KEY_ESC	= 'Escape';
const KEY_RET	= 'Enter';
const KEY_DEL	= 'Delete';
const KEY_TAB	= 'Tab';

const KEY_LFT	= 'Left';
const KEY_RGT	= 'Right';
const KEY_HOM	= 'Home';
const KEY_END	= 'End';
const KEY_PUP	= 'PageUp';
const KEY_PDN	= 'PageDown';
const KEY_UP	= 'Up';
const KEY_DWN	= 'Down';
const KEY_SPC	= 'Space';

const MOD_SFT	= 0x01;
const MOD_CTR	= 0x02;
const MOD_ALT	= 0x04;
    
/**
 * key listener class for RAP widgets;
 * it supports several options that specify which key strokes are effectively reported to the web server
 */
export default class WdgKeyLsr {

	/**
	 * constructs a new instance
	 * @param {PSA} psa the PSA instance
	 * @param {String} idw widget ID
	 * @param {Widget} wdg the target widget
	 * @param {Object} opt options
	 * @param {CliCbkWdg} cbw the callback widget
	 * 
	 * we support the following options:
	 * up  - listen on "keyup" instead of "keydown"
	 * bsc - use common block screen behavior; if not set then key events are sent without triggering the block screen
	 * pch - printable characters; i.e. key events that generate real input
	 * bks - backspace
	 * tab - tab key
	 * esc - Escape key
	 * ret - Return / Enter key
	 * del - delete key
	 * csr - cursor key
	 * udo - cursor up and cursor down keys only
	 * mod - consider modifier keys <Ctrl>, <Alt>, <Shift>
	 */
	constructor(psa, idw, wdg, opt, cbw) {
		this._psa = psa;
		this.wdgID = idw;
		this.tgtWdg = wdg;
		this.cbkWdg = cbw;

		// evaluate options
		const up = !!opt.up;
		this.useBsc = !!opt.bsc;
		this.prnChr = !!opt.pch;
		this.bksKey = !!opt.bks;
		this.tabKey = !!opt.tab;
		this.escKey = !!opt.esc;
		this.retKey = !!opt.ret;
		this.delKey = !!opt.del;
		this.csrKys = !!opt.csr;
		this.csrUdo = !!opt.udo;
		this.modKey = !!opt.mod;

		// add key listener
		this.tgtWdg.addEventListener(up ? 'keyup' : 'keydown', this._onKeyEvent, this);
	}

	/**
	 * "keydown" / "keyup" event handler
	 * @param {rwt.event.KeyEvent} evt RAP key event
	 */
	_onKeyEvent(evt) {
		let dsc = '';
		let hky = false;
		let csr = false;
		let mod = 0;
		if ( evt ) {
			if ( this.tgtWdg !== evt.getTarget() ) {
				// not for use
				return;
			}
			const ctr = evt.isCtrlPressed();
			const alt = evt.isAltPressed();
			const sft = evt.isShiftPressed();
			if ( !this.modKey && (ctr || alt) ) {
				// some hotkey or anything else out of interest
				return;
			}
			if ( sft ) {
				mod |= MOD_SFT;
			}
			if ( ctr ) {
				mod |= MOD_CTR;
			}
			if ( alt ) {
				mod |= MOD_ALT;
			}
			let key = evt.getKeyIdentifier();
			switch ( key ) {
				case KEY_BKSP:
					if ( this.bksKey ) {
						dsc = key;
						hky = true;
					}
					break;
				case KEY_DEL:
					if ( this.delKey ) {
						dsc = key;
						hky = true;
					}
					break;
				case KEY_ESC:
					if ( this.escKey ) {
						dsc = key;
						hky = true;
					}
					break;
				case KEY_RET:
					if ( this.retKey ) {
						dsc = key;
						hky = true;
					}
					break;
				case KEY_TAB:
					if ( this.tabKey ) {
						dsc = key;
						hky = true;
						csr = true;
					}
					break;
				case KEY_LFT:
				case KEY_RGT:
				case KEY_UP:
				case KEY_DWN:
				case KEY_PUP:
				case KEY_PDN:
				case KEY_HOM:
				case KEY_END:
					if ( this.csrKys ) {
						dsc = key;
						hky = true;
						csr = true;
					} else if ( this.csrUdo ) {
						if ( key === KEY_DWN || key === KEY_UP ) {
							dsc = key;
							hky = true;
							csr = true;
						}
					}
					break;
				case KEY_SPC:
					if ( this.prnChr ) {
						dsc = ' ';
						hky = false;
					}
					break;
				default:
					if ( this.prnChr ) {
						// check for printable characters
						if ( KEY_UID === key ) {
							// do a bit more investigation
							let de = evt.getDomEvent() || null;
							if ( de && pisasales.isStr(de.key) && (de.key.length === 1) ) {
								dsc = de.key;
							}
						}
						else if ( AVL_KEYS.indexOf(key) >= 0 ) {
							// a supported key
							dsc = key;
						}
					}
					break;
			}
		}
		if ( this._psa.isStr(dsc) ) {
			// ok, notify web server
			evt.stopPropagation();
			evt.preventDefault();
			let par = {};
			par.key = dsc;
			par.hky = hky;
			par.csr = csr;
			par.mod = mod;
			this.cbkWdg.nfySrv(this.wdgID, 'keyevent', par, this.useBsc);
		}
	}

	/**
	 * adds a widget key listener to the specified widget
	 * @param {PSA} psa the PSA instance
	 * @param {Object} args arguments
	 * @returns {WdgKeyLsr} the key listener instance
	 */
	static addWdgKeyLsr(psa, args) {
		let idw = args.idw || '';
		let cbw = args.__cbw || null;
		if ( psa.isStr(idw) && cbw ) {
			let wdg = rwt.remote.ObjectRegistry.getObject(idw);
			if ( wdg ) {
				let opt = args.opt || {};
				return new WdgKeyLsr(psa, idw, wdg, opt, cbw);
			}
		}
		return null;
	}
}

console.log('key/WdgKeyLsr.js loaded.');