import ObjReg from '../../utils/ObjReg';
import PSA from '../../psa';
import ItmMgr from '../ItmMgr';
import JsRect from '../../utils/JsRect';
import JsPoint from '../../utils/JsPoint';

// vertical distance / offset of the QuickView dialog to the current mouse position
const VOFFS = 14;
// short hide timeout
const TMO_SHORT = 500;
// long hide timeout
const TMO_LONG = 25000;
// QuickView marker
const QVW_MARKER = '$PSAQVW$';
// "no request" marker
const QVW_NORQU = '$NONE$';

/**
 * QuickView manager class
 */
export default class QvwMgr {

	/**
	 * constructs a new instance
	 * @param {PSA} psa the PSA instance
	 * @param {CliCbkWdg} cbw callback widget
	 */
	constructor(psa, cbw) {
		this._psa = psa;
		this.dbgLog = false;
		this.cbkWdg = null;
		this.bscMgr = null;
		this.qvwDis = false;
		this.wdgReg = null;
		this.mmvLis = null;
		this.mdnLis = null;
		this.mwlLis = null;
		this.curPos = null;
		this.curIds = null;
		this.curShl = null;
		this.ctrRct = null;
		this.hotRct = null;
		this.clsImm = false;
		this.bupFrm = false;
		this.timHdl = null;
		this.shtTmo = false;
		this.blkQvw = false;
		this.curRqu = QVW_NORQU;
		this.qvwLsr = new ObjReg();
		this.sndMdn = false;
		this._init(cbw);
	}

	/**
	 * destructor method
	 */
	destroy() {
		this._clrShl();
		this._clrTim();
		this.qvwLsr.clear();
		this.qvwLsr.destroy();
		delete this.qvwLsr;
		if ( this.mmvLis ) {
			let lis = this.mmvLis;
			this.mmvLis = null;
			document.body.removeEventListener('mousemove', lis);
		}
		if ( this.mdnLis ) {
			let lis = this.mdnLis;
			this.mdnLis = null;
			document.body.removeEventListener('mousedown', lis);
		}
		if ( this.mwlLis ) {
			let lis = this.mwlLis;
			this.mwlLis = null;
			document.body.removeEventListener('wheel', lis);
		}
		if ( this.OnSend ) {
			rap.off('send', this.OnSend);
		}
		delete this.mmvLis;
		delete this.mdnLis;
		delete this.mwlLis;
		delete this.cbkWdg;
		delete this.wdgReg;
		delete this.OnSend;
	}

	isQvwDis() {
		return this.qvwDis;
	}

	setQvwDis(args) {
		this.qvwDis = !!args.dis;
	}

	/**
	 * adds a QuickView listener
	 * @param {Object} lsr QuickView listener to be added; it must provide the methods "getLsrKey()" and "onQvwShow(Boolean)"
	 */
	addQvwLsr(lsr) {
		this.qvwLsr.addObj(lsr.getLsrKey(), lsr)
	}

	/**
	 * removes a QuickView listener
	 * @param {Object} lsr QuickView listener to be removed
	 */
	rmvQvwLsr(lsr) {
		if ( this.qvwLsr ) {
			this.qvwLsr.rmvObj(lsr.getLsrKey())
		}
	}

	/**
	 * notifies the listeners
	 * @param {Boolean} show flag whether the QuickView is shown or hidden
	 */
	_nfyLsr(show) {
		if ( this.qvwLsr ) {
			this.qvwLsr.forEach( (l) => {
				l.onQvwShow(show);
			});
		}
	}

	/**
	 * sets the current QuickView dialog
	 */
	setQvwShl(args) {
		this._clrTim();
		const ids = args.ids || '';
		const rct = new JsRect(args.rct);
		const ctr = new JsRect(args.ctr);
		const cim = !!args.cim;
		const bup = !!args.bup;
		if ( this._psa.isStr(ids) && (ids !== 'none') ) {
			const shl = rwt.remote.ObjectRegistry.getObject(ids);
			if ( shl ) {
				this.curIds = ids;
				this.curShl = shl;
				this.ctrRct = ctr;
				this.hotRct = rct;
				this.clsImm = cim;
				this.bupFrm = bup;
				this._moveQvw(true);
				this._nfyLsr(true);
				if ( this.bscMgr.hasMnuPop() ) {
					const _ci = this.clsImm;
					this.clsImm = true;
					try {
						this._rquHide(true);
					} finally {
						this.clsImm = _ci;
					}
				} else {
					this._rquHide(false);
				}
			}
		} else {
			// release the current shell
			this._clrShl();
		}
	}

	/**
	 * releases the QuickView dialog
	 */
	rlsQvwShl(args) {
		this._clrTim();
		let ids = args.ids || '';
		if ( this._psa.isStr(ids) ) {
			if ( ids === this.curIds ) {
				this._clrShl();
			}
		}
	}

	/**
	 * adds a widget that is aware of QuickView functionality
	 */
	addQvwWdg(args) {
		let idw = args.idw || '';
		if ( this._psa.isStr(idw) ) {
			this.wdgReg[idw] = idw;
		}
	}

	/**
	 * removes a widget from the widget set of QuickView aware widgets
	 */
	rmvQvwWdg(args) {
		let idw = args.idw || '';
		if ( this._psa.isStr(idw) ) {
			if ( this.wdgReg[idw] !== undefined ) {
				delete this.wdgReg[idw];
			}
		}
	}

	/**
	 * forces any QuickView to be removed immediately
	 */
	frcQvwHid() {
		if ( this.curIds ) {
			if ( this._isDbgLog() ) {
				this._psa.Log.getConsole(true).log('QVWMGR - hide forced.');
			}
			this._sndHideRqu(this.curIds);
		}
	}

	/**
	 * return the current mouse cursor position
	 */
	getCurPos() {
		return new JsPoint(this.curPos.x, this.curPos.y);
	}

	/**
	 * updates the current mouse position
	 */
	updCurPos(x, y) {
		if ( (typeof x === 'number') && (typeof y === 'number') && (x >= 0) && (y >= 0) ) {
			this.curPos.x = x;
			this.curPos.y = y;
			this._moveQvw(false);
		}
	}

	/**
	 * sends the current mouse position to the web server
	 */
	sndCurPos() {
		if ( this.cbkWdg && this.curPos && !this.isQvwDis() ) {
			let par = {};
			par.x = this.curPos.x;
			par.y = this.curPos.y;
			this.cbkWdg.nfyGlb('qvwMousePos', par, false);
		}
	}

	/**
	 * returns the "debug log" flag
	 */
	_isDbgLog() {
		return this.dbgLog;
	}

	/**
	 * initializes the quick view manager
	 */
	_init(cbw) {
		this.dbgLog = false;
		this.cbkWdg = cbw;
		this.bscMgr = pisasales.getBscMgr();
		this.wdgReg = {};
		this.curPos = new JsPoint(0, 0);
		this.blkQvw = false;
		this.OnSend = this._psa.bind(this, this._onSend);
		rap.on('send', this.OnSend);
		this.mmvLis = this._psa.bind(this, this._mouseMove);
		this.mdnLis = this._psa.bind(this, this._mouseDown);
		this.mwlLis = this._psa.bind(this, this._mouseWheel);
		document.body.addEventListener('mousemove', this.mmvLis);
		document.body.addEventListener('mousedown', this.mdnLis);
		document.body.addEventListener('wheel', this.mwlLis);
		// hook into RAP's tooltip widget
		const ttp = rwt.widgets.base.WidgetToolTip.getInstance();
		if ( ttp ) {
			const self = this;
			const _fnc_show = ttp.show;
			const _fnc_aal = ttp._afterAppearLayout;
			if ( (typeof _fnc_show === 'function') && (typeof _fnc_aal === 'function') ) {
				ttp._org_show = _fnc_show;
				ttp._org_aal = _fnc_aal;
				ttp._psaBlockTtp = false;
				ttp.show = function() {
					// check the tooltip text
					ttp._psaBlockTtp = false;
					if ( self._chkTtp(ttp) ) {
						// that's a QuickView trigger; eat it!
						ttp._psaBlockTtp = true;
						return;
					}
					return ttp._org_show(arguments);
				};
				ttp._afterAppearLayout = function() {
					if ( ttp._psaBlockTtp ) {
						// RAP will cause a crash in WidgetTooltip._afterAppearLayout() if 'show' did not really show the tooltip so we block that here...
						return;
					} else {
						try {
							ttp._org_aal(arguments);
						} catch ( err ) {
							// RAP has failed to show the tooltip for whatever reason... 
						}
					}
				};
			}
		}
	}

	/**
	 * clears the current shell properties
	 */
	_clrShl() {
		this.curShl = null;
		this.curIds = null;
		this.ctrRct = null;
		this.hotRct = null;
		this.clsImm = false;
		this.bupFrm = false;
		this.curRqu = QVW_NORQU;
		this._nfyLsr(false);
	}

	/**
	 * clears the current timer
	 */
	_clrTim() {
		if ( this.timHdl ) {
			let tmh = this.timHdl;
			this.timHdl = null;
			clearTimeout(tmh);
		}
		this.shtTmo = false;
	}

	/**
	 * indicates whether a short hide timeout is active
	 */
	_isShtTmo() {
		return this.shtTmo && this.timHdl;
	}

	/***
	 * called in RAP's "send" phase
	 */
	_onSend() {
		if ( this.sndMdn && this.cbkWdg ) {
			const par = {};
			par.x = this.curPos.x;
			par.y = this.curPos.y;
			if ( this._isDbgLog() ) {
				this._psa.Log.getConsole(true).log('QVWMGR - global mouse down');
			}
			this.cbkWdg.nfyGlb('qvwMouseDown', par, null);
		}
		this.sndMdn = false;
	}
	
	/**
	 * updates the current position from a mouse event
	 * @param {MouseEvent} event the mouse event
	 * @param {Boolean} cbl "clear blocked" flag
	 * @returns {Boolean} true if the mouse position was updated; false otherwise
	 */
	_setMousePos(event, cbl) {
		const nx = event.clientX;
		const ny = event.clientY;
		if ( (nx >= 0) && (ny >= 0) ) {
			if ( !!cbl && this.blkQvw && ((this.curPos.x !== nx) || (this.curPos.y !== ny)) ) {
				// ok, clear the "blocked" flag
				this.blkQvw = false;
			}
			// update the current mouse position 
			this.curPos.x = nx;
			this.curPos.y = ny;
			return true;
		} else {
			console.warn('Negative mouse coordinates rejected!');
			return false;
		}
	}

	/**
	 * mouse move event handler
	 * @param {MouseEvent} event the mouse event
	 */
	_mouseMove(event) {
		if ( this._setMousePos(event, true) ) {
			this._moveQvw(false);
		}
	}

	/**
	 * mouse down event handler
	 * @param {MouseEvent} event the mouse event
	 */
	_mouseDown(event) {
		if ( !this.isQvwDis() ) {
			// update mouse position
			this._setMousePos(event, false);
			// set the "blocked" flag
			this.blkQvw = true;
			this.curRqu = QVW_NORQU;
			if ( this.cbkWdg ) {
				if ( this.curIds && this.curShl ) {
					if ( this._isDbgLog() ) {
						this._psa.Log.getConsole(true).log('QVWMGR - Remove existing QuickView');
					}
					// and notify the web server
					this.cbkWdg.nfySrv(this.curIds, 'qvwMouseDown', { ids : this.curIds }, true);
				} else {
					// we should defer that a little to the next regular "send" phase
					this.sndMdn = true;
				}
			}
		}
	}

	/**
	 * mouse wheel event handler
	 */
	_mouseWheel(event) {
		// force the QuickView window to disappear
		if ( this.curIds ) {
			this._sndHideRqu(this.curIds);
		}
	}

	/**
	 * moves the QuickView shell
	 * @param {Boolean} ini initialization flag
	 */
	_moveQvw(ini) {
		if ( this.curShl && this.curShl.isCreated() ) {
			// get current size and screen limits
			const shl = this.curShl;
			const scr = document.body.getBoundingClientRect();
			const wdt = shl.getWidth();
			let hgt = shl.getHeight();
			let mnh = 0;
			if ( this.bupFrm && !!ini ) {
				if ( shl._element ) {
					const she = shl._element;
					const c = she.getElementsByClassName('bupElm');
					if ( c && (c.length > 0) ) {
						const bup = c[0];
						const mgr = ItmMgr.getInst();
						const res = mgr.measureText(bup, true, wdt, false);
						if ( res.wts ) {
							mnh = res.wts.cy || 0;
							if ( mnh > hgt ) {
								// ok, we must update the layout beginning at the "bundle up" widget up to the (excluded) shell 
								const hgs = '' + mnh + 'px';
								bup.style.height = hgs;
								let par = bup.parentElement;
								while ( par && (par !== she) ) {
									par.style.height = hgs;
									par = par.parentElement;
								}
							}
						}
					}
				}
			}
			if ( hgt < mnh ) {
				shl.setHeight(mnh);
				hgt = mnh;
			}
			// initially centered above the current mouse position
			let xw = this.curPos.x - (wdt / 2);
			let yw = this.curPos.y - hgt - VOFFS;
			// boundary check
			if ( xw < (scr.left + 2) ) {
				xw = scr.left + 2;
			}
			if ( (xw + wdt) > (scr.right - 2) ) {
				xw = scr.right - wdt - 2;
			}
			if ( yw < (scr.top - 2) ) {
				yw = this.curPos.y + VOFFS;
			}
			if ( ini ) {
				// place the QuickView window
				shl.setLeft(xw);
				shl.setTop(yw);
			}
			const pt = this.curPos;
			if ( this.ctrRct && !this.ctrRct.contains(pt.x, pt.y) ) {
				// sent hide request IMMEDIATELY
				this._sndHideRqu(this.curIds);
			} else if ( !this._isShtTmo() && this.hotRct && !this.hotRct.contains(pt.x, pt.y) ) {
				// trigger short timed hide request
				this._rquHide(true);
			}
		}
	}

	/**
	 * triggers a "hide" request
	 */
	_rquHide(short) {
		if ( this.cbkWdg && this.curIds && this.curShl ) {
			this._clrTim();
			let sht = !!short;
			if ( sht && this.clsImm ) {
				// close immediately!
				this._sndHideRqu(this.curIds);
			} else {
				// use a timeout
				let self = this;
				let ids = this.curIds;
				self.timHdl = setTimeout(function() {
					self._sndHideRqu(ids);
				}, sht ? TMO_SHORT : TMO_LONG);
				this.shtTmo = sht;
			}
		}
	}

	/**
	 * sends the "hide" request
	 */
	_sndHideRqu(ids) {
		this._clrTim();
		if ( this.cbkWdg && this._psa.isStr(ids) ) {
			this.cbkWdg.nfySrv(this.curIds, 'qvwRquHide', { ids : ids }, false);
			this.curRqu = QVW_NORQU;
			this._nfyLsr(false);
		}
	}

	/**
	 * checks a tooltip request
	 */
	_chkTtp(ttp) {
		const ttx = ttp.getText() || '';
		let res = !!ttx.startsWith(QVW_MARKER);
		if ( res && this.cbkWdg && this.wdgReg ) {
			// that's our special tooltip string --> do not show this as tooltip but notify the server instead
			if ( this.blkQvw || this.isQvwDis() || this._psa.getKeyHdl().isBlocked() || (ttx === this.curRqu) ) {
				// block it at all!
				if ( this._isDbgLog() ) {
					this._psa.Log.getConsole(true).log('QVWMGR - Request "' + ttx + '" rejected.');
				}
				return true;
			}
			this.curRqu = ttx;
			if ( (ttx.length > QVW_MARKER.length) && (ttx.charAt(QVW_MARKER.length) === ':') ) {
				// new style tooltip
				const par = {};
				par.rqu = ttx;
				par.x = this.curPos.x;
				par.y = this.curPos.y;
				this.cbkWdg.nfyGlb('qvwRquShow', par, true);
				res = true;
			} else {
				// (old) common widget tooltip
				const tgt = ttp.getBoundToWidget();
				if ( tgt && this._psa.isStr(tgt._rwtId) ) {
					const idw = tgt._rwtId;
					if ( this.wdgReg[idw] !== undefined ) {
						// trigger QuickView request
						const par = {};
						par.idw = idw;
						par.x = this.curPos.x;
						par.y = this.curPos.y;
						this.cbkWdg.nfySrv(idw, 'qvwRquShow', par, true);
					}
					res = true;
				}
			}
		}
		return res;
	}
}												

console.log('gui/qvw/QvwMgr.js loaded.');