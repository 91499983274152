import MRowItem from './MRowItem';
import Validator from '../../../utils/Validator';
import ObjReg from '../../../utils/ObjReg';
import CellCtt from './CellCtt';
import MDataRow from './MDataRow';
import XtwModel from './XtwModel';

/** an empty content */
const EMPTY_CTT = { html: false, text: '', prop: {} };
/** the built-in identifier for the default group - keep in sync. with "XtdModel.java"! */
const DEFAULT_DSC = 'XTDGRP_DEFAULT_79B944AA3F7844D29F717D18D00FA7AD';

/**
 * group head item
 */
export default class MGroup extends MRowItem {

	/**
	 * constructs a new instance
	 * @param {Object} JSON data sent by the web server
	 * @param {Number} dgh default group height
	 * @param {Number} drh default row height
	 * @param {XtwModel} xtwModel the data model
	 */
	constructor( json, dgh, drh, xtwModel ) {
		super( json );
		this.idg = json.idg || 0;
		this.dsc = json.dsc || '';
		this._height = json.height || dgh;
		this.defRwh = drh;
		// title, font, colors are provided by a content object
		this.ctt = new CellCtt( json.ctt || EMPTY_CTT );
		this.defgrp = ( DEFAULT_DSC === this.dsc );
		this.collapsed = !this.defgrp && !!json.collapsed;
		this.rows = new ObjReg();
		// we're visible and present!
		this.present = true;
		this.xtwModel = xtwModel;
	}

	/**
	 * @override
	 */
	doDestroy() {
		this.clear();
		delete this.xtwModel;
		delete this.defRwh;
		delete this.rows;
		delete this.idg;
		delete this.dsc;
		delete this.collapsed;
		delete this.defgrp;
		if ( this.ctt ) {
			this.ctt.destroy();
		}
		delete this.ctt;
		super.doDestroy();
	}

	set dummyModelItem( newValue ) {
		if ( !( newValue instanceof MDataRow ) ) {
			return;
		}
		this._dummyModelItem = newValue;
	}

	get dummyModelItem() {
		return this._dummyModelItem;
	}

	nullifyDummyModelItem() {
		this._dummyModelItem = void 0;
		delete this._dummyModelItem;
	}

	/**
	 * @returns {Number} the group ID of the target group to which this item belongs
	 * @override
	 */
	getTgtID() {
		// no nested groups so far
		return 0;
	}

	/**
	 * @returns {Number} the group ID
	 */
	getGrpID() {
		return this.idg;
	}

	/**
	 * @returns {String} the group descriptor
	 */
	getGrpDsc() {
		return this.dsc;
	}

	/**
	 * @override
	 */
	isGroupHead() {
		return true;
	}

	/**
	 * @override
	 */
	hasChildren() {
		return this.rows && !this.rows.isEmpty();
	}

	/**
	 * @returns {Boolean} true if this is the default group; false otherwise
	 */
	isDefault() {
		return this.defgrp;
	}

	/**
	 * @override
	 */
	isPresent() {
		return true;
	}

	/**
	 * @returns {Boolean} true if this is group is currently collapsed (no rows visible); false otherwise
	 */
	isCollapsed() {
		return !this.defgrp && this.collapsed;
	}

	/**
	 * changes the "collapsed" status; has no effect for the default group
	 * @param {Boolean} c new "collapsed" status
	 */
	setCollapsed( c ) {
		if ( !this.defgrp ) {
			this.collapsed = !!c;
		}
	}

	/**
	 * clears all rows
	 */
	clear() {
		if ( this.rows ) {
			// drop all rows
			this.rows.dstChl();
		}
		this.nullifyDummyModelItem();
	}

	/**
	 * adds a new row
	 * @param {Object} md the model item data to be processed
	 */
	addRow( md ) {
		const idr = md.idr || 0;
		if ( idr === 0 || this.rows.hasObj( idr ) ) {
			throw new Error( 'Invalid / duplicate row ID: ' + idr + '!' );
		}
		const row = new MDataRow( md, this.defRwh, this );
		this.rows.addObj( idr, row );
		return row;
	}

	/**
	 * adds this group to the flat model
	 * @param {Array<MRowItem>} fm flat model
	 * @param {Number} top current top coordinate
	 * @param {Number} ovh the overriden height of data row items
	 * @param {Number} vpad vertical padding
	 * @returns {Number} the total height required by this group
	 */
	addToFlat( fm, top, ovh, vp ) {
		let hgt = 0;
		if ( !this.defgrp ) {
			this._flatIndex = fm.length;
			this.setTop( top );
			fm.push( this );
			hgt += this.getHeight();
		}
		if ( this.rows && !this.rows.isEmpty() ) {
			if ( !this.isCollapsed() ) {
				this.rows.forEach( ( r ) => {
					r.setTop( top + hgt );
					r.setOvrHeight( ovh, vp );
					r._flatIndex = fm.length;
					fm.push( r );
					hgt += r.getHeight() + vp;
				} );
			} else {
				this.rows.forEach( ( r ) => {
					// not part of the flat model!
					r._flatIndex = -1;
				} );
			}
		}
		return hgt;
	}

	/**
	 * @override
	 */
	setData( data ) {
		super.setData( data );
	}

	/**
	 * @override
	 */
	setOvrHeight( ovh, vpad ) {
		super.setOvrHeight( null, 0 );
	}
}
