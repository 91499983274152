import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';

export default class SelectionModeExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get selectionMode() {
		if ( !Validator.isObject( this.hostObject ) ||
			!Validator.isInteger( this.hostObject.selectionMode ) ) {
			return void 0;
		}
		const selectionMode = this.hostObject.selectionMode;
		return selectionMode < 4 && selectionMode > -2 ? selectionMode : void 0;
	}

	get noSelectionAllowed() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === -1;
	}

	get selectionIsCommon() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === 0;
	}

	get selectionIsSingle() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === 1;
	}

	get selectionIsForced() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === 2;
	}

	get selectionIsForcedSingle() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === 3;
	}

	get somethingShouldAlwaysBeSelected() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === 2 || selectionMode === 3;
	}

	get onlyOneItemCanBeSelected() {
		const selectionMode = this.selectionMode;
		if ( !Validator.isInteger( selectionMode ) ) {
			return void 0;
		}
		return selectionMode === 1 || selectionMode === 3;
	}

}
