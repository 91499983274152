import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import JsSize from '../../utils/JsSize';

const RGX_BR = /<br>/g;
const BR_TAG = '<br>';
const RGX_PLC = /\{\{\{br\}\}\}/g;
const BR_PLC = '{{{br}}}';

/**
 * class XWdg - client side implementation of XWdg
 */
export default class XWdg {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		this.ready = false;
		this.sepNfo = null;
		this.padOfs = 4;
		this.chkSiz = 6;
		this.hrzPad = 0;
		this.hrzAln = "left";
		this.vrtAln = "center";
		this.txtDiv = null;
		this.imgDiv = null;
		this.svgImg = false;
		this.isImgElm = false;
		this.ddiDiv = null;
		this.ddiSiz = null;
		this.lndDiv = null;
		this.ckmDiv = null;
		this.bkgTrn = false;
		this.bkgClr = null;
		this.fgrClr = null;
		this.chkClr = null;
		this.hvrClr = null;
		this.hasMls = false;
		this.btnDwn = false;
		this.staDis = false;
		this.frcBtn = false;
		this.sngLin = false;
		this.fixTxs = false;
		this.popMnu = null;
		this.pop = false;
		this._psa.bindAll(this, [ "layout", "onReady", "onRender" ]);
		this.wdgId = properties.parent;
		this.parent = rap.getObject(properties.parent);
		this.itmMgr = ItmMgr.getInst();
		this.element = document.createElement('div');
		this.element.style.position = 'absolute';
		this.element.style.cursor = 'default';
		this.parent.append(this.element);
		this._init(this.element, this.parent.getData("pisasales.CSTPRP.JSP"), true);
		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
	}

	_creImg(img, shrink) {
		const imd = document.createElement('div');
		const dsc = img.img;
		this.svgImg = false;
		if ( "DSC" === img.typ ) {
			// a font based icon
			this.isImgElm = false;
			const siz = dsc.icoSiz;
			const fsz = shrink ? this.itmMgr.getFntSiz(siz) : siz;
			imd.style.textAlign = 'center';
			imd.style.fontSize = fsz + 'px';
			imd.style.height = fsz + 'px';
			imd.style.width = fsz + 'px';
			const ico = this.itmMgr.creDscIco(dsc, null, shrink);
			imd.appendChild(ico);
		} else {
			if ( img.svg ) {
				// a SVG image
				const svg = this.itmMgr.creSvgImg(dsc);
				if ( svg ) {
					imd.appendChild(svg);
					this.isImgElm = true;
					this.svgImg = true;
				} else {
					// failed!
					return null;
				}
			} else {
				// a bitmap
				this.isImgElm = true;
				const itg = document.createElement('img');
				const wdt = dsc[1];
				const hgt = dsc[2];
				itg.src = dsc[0];
				itg.width = wdt;
				itg.height = hgt;
				itg._psa_width = wdt;
				itg._psa_height = hgt;
				imd.style.width = wdt + 'px';
				imd.style.height = hgt + 'px';
				imd.appendChild(itg);
			}
		}
		imd.style.position = 'absolute';
		imd.style.cursor = 'inherit';
		return imd;
	}

	_creLngBtn(img){
		const lnd = this._creImg(img, true);
		lnd.style.opacity = 0.25;
		if ( this.element ){
			const that = this;
			this.element.addEventListener("mouseover", function(lnd) {
				this.lndDiv.style.opacity = 1;
				this.element.style.cursor = 'pointer';
			}.bind(that));
			this.element.addEventListener("mouseout", function(lnd) {
				this.lndDiv.style.opacity = 0.25;
				this.element.style.cursor = 'default';
			}.bind(that));
		}

		lnd.style.float = 'right';
		lnd.style.paddingRight = ''
		lnd.style.position = 'relative';
		lnd.style.top = '0';
		return lnd;
	}

	_fmtTxt(jsp, txt) {
		let res = txt;
		if ( jsp.datFmt && (jsp.datFmt.length > 0) ) {
			if ( jsp.datFmt.startsWith('D:') ) {
				let fmt = jsp.datFmt.substr(2);
				if ( fmt.length > 0 ) {
					let tms = new Date(txt);
					let m = moment(tms);
					if ( jsp.usrLng ) {
						m.locale(jsp.usrLng);
					}
					res = m.format(fmt);
				}
			}
		}
		if ( !jsp.html ) {
			// quote any HTML tag (i.e. '<' and '>') but keep '<br>' tags
			RGX_BR.lastIndex = 0;		// !!! RegEx :-O
			RGX_PLC.lastIndex = 0;
			res = res.replace(RGX_BR, BR_PLC);
			res = this._psa.escHtml(res);
			res = res.replace(RGX_PLC, BR_TAG);
		}
		return res;
	}

	_creTxt(jsp, txt, aln) {
		const txd = document.createElement('div');
		txd.innerHTML = this._fmtTxt(jsp, txt);
		txd.style.position = 'absolute';
		txd.style.cursor = 'inherit';
		txd.style.textAlign = aln;
		txd.style.textOverflow = 'ellipsis';
		txd.style.overflow = 'hidden';
		return txd;
	}

	_addSub(div, txd, imd, ddd, lnd, ckd) {
		if ( imd ) {
			div.appendChild(imd);
			this.imgDiv = imd;
		}
		if ( txd ) {
			div.appendChild(txd);
			this.txtDiv = txd;
		}
		if ( ddd ) {
			div.appendChild(ddd);
			this.ddiDiv = ddd;
		}
		if ( lnd ) {
			div.appendChild(lnd);
			this.lndDiv = lnd;
		}
		if ( ckd ) {
			div.appendChild(ckd);
			this.ckmDiv = ckd;
		}
	}

	_getHAln(jsp) {
		let aln = jsp.aln;
		if ( !aln ) {
			aln = "left";
		}
		return aln;
	}

	_getVAln(jsp) {
		let valn = jsp.vertaln;
		if ( !valn ) {
			valn = "center";
		}
		return valn;
	}

	_getTxtAln(args) {
		let txa = "left";
		if ( (typeof args === 'string') && (args.length > 0) ) {
			txa = args;
		} else if ( args.aln ){
			txa = args.aln;
		}
		if ( txa === "inline" ) {
			txa = "left";
		}
		return txa;
	}

	_init(div, jsp, csp) {
		if ( this.sepNfo ) {
			this._makeSep(div);
		} else if ( this._psa.isStr(jsp.cusHtml) ) {
			div.innerHTML = jsp.cusHtml;
		} else {
			this.hrzAln = this._getHAln(jsp);
			let txa = this._getTxtAln(this.hrzAln);
			div.style.textAlign = txa;
			this.vrtAln = this._getVAln(jsp);
			let txd = null;
			let imd = null;
			let ddd = null;
			let lnd = null;
			let ckd	= null;
			let img = jsp.imgObj;
			let txt = jsp.text;
			let ddi = jsp.drpDwn;
			let lnb = jsp.lngBtn;
			let has_txt = !!(txt && (txt.length > 0));
			if ( img ) {
				imd = this._creImg(img, has_txt);
			}
			this.rquTxs = !!jsp.rquTxs;
			if ( has_txt ) {
				txd = this._creTxt(jsp, txt, txa);
			}
			if ( ddi ) {
				ddd = this._creImg(ddi, true);
				this.ddiSiz = this.itmMgr.getImgSiz(ddi);
			}
			if ( lnb ) {
				lnd = this._creLngBtn(lnb);
			}
			this._chkImg = false;
			if ( typeof jsp.staChk === 'boolean' ) {
				if ( !imd || ("DSC" !== img.typ) ) {
					ckd = document.createElement('div');
					ckd.style.position = 'absolute';
					this.itmMgr.setBkgClr(ckd, jsp.chkClr, false);
				} else {
					this._chkImg = true;
				}
			}
			this._addSub(div, txd, imd, ddd, lnd, ckd);
			if ( imd && this.svgImg && !txd && !ckd ) {
				const dsc = img.img;
				if ( !dsc.fix ) {
					// no fixed size
					const img = imd.children[0];
					img.removeAttribute('width');
					img.removeAttribute('height');
				} else {
					// do *not* do any special thing
					this.svgImg = false;
				}
			} else {
				// SVG image or not - we've got more things than a single image, so we can't do any special things for SVG images
				this.svgImg = false;
			}
		}
		if ( csp && jsp ) {
			this.setPar(jsp);
		}
	}

	_cleanup(full) {
		if ( this.ckmDiv && this.ckmDiv.parentNode ) {
			this.ckmDiv.parentNode.removeChild(this.ckmDiv);
		}
		if ( this.imgDiv && this.imgDiv.parentNode ) {
			this.imgDiv.parentNode.removeChild(this.imgDiv);
		}
		if ( this.ddiDiv && this.ddiDiv.parentNode ) {
			this.ddiDiv.parentNode.removeChild(this.ddiDiv);
		}
		if ( this.lndDiv && this.lndDiv.parentNode ) {
			this.lndDiv.parentNode.removeChild(this.lndDiv);
		}
		if ( this.txtDiv && this.txtDiv.parentNode ) {
			this.txtDiv.parentNode.removeChild(this.txtDiv);
		}
		if ( this.element ) {
			this.element.innerHTML = '';
		}
		if ( full ) {
			if ( this.popMnu ) {
				const mnu = this.popMnu;
				this.popMnu = null;
				this.pop = false;
				mnu.destroy();
			}
			if ( this.element && this.element.parentNode ) {
				this.element.parentNode.removeChild(this.element);
			}
			this.element = null;
		}
		this.ckmDiv = null;
		this.imgDiv = null;
		this.svgImg = false;
		this.ddiDiv = null;
		this.lndDiv = null;
		this.txtDiv = null;
	}

	_calculateFontSizeToFit(div) {
		if ( this.fixTxs ) {
			// do nothing!
			return;
		}
		let stepSize = 1; // how much the font is down sized with each iteration
		let fontSize = parseFloat(window.getComputedStyle(div, null).getPropertyValue('font-size'));
		let maxFntSiz = parseFloat(this.maxFontSize);
		let maxFontDownsize = maxFntSiz / 7;
		let minFntSiz = maxFntSiz - maxFontDownsize;

		if (div.offsetWidth < div.scrollWidth) {
			// Right now text is broken off with "...". Try down sizing the font to fit the parent.
			this._downsizeTextToFit(div, minFntSiz, maxFntSiz, stepSize);
		} else if (this.maxFontSize != null && fontSize < maxFntSiz) {
			// Text has been down sized at some point. Try re-up-sizing the font while still fitting the parent.
			this._upsizeTextToFit(div, maxFntSiz, stepSize);
		} else {
			// Text is the maximum size and fits its parent. Do nothing.
			return;
		}
		// make sure text is still vertically centered
		this._cenVert(div.parentNode, div, true, !this.sngLin, 0);
	}

	/**
	 * Makes the font smaller until the passed minimum is reached or the text does not overflow anymore.
	 * Decrements fontSize by passed stepSize in each iteration.
	 */
	_downsizeTextToFit(div, minFontSize, maxFontSize, stepSize) {
		let tempFontSize = parseFloat(window.getComputedStyle(div, null).getPropertyValue('font-size'));

		while (div.offsetWidth <= div.scrollWidth && (tempFontSize - stepSize) >= minFontSize) {
			tempFontSize -= stepSize;
			div.style.fontSize = tempFontSize + "px";
		}

		if ((tempFontSize - stepSize) <= minFontSize && div.offsetWidth < div.scrollWidth) {
			// if the text still overflows at minimum size make it maximum size.
			tempFontSize = maxFontSize;
		}
		div.style.fontSize = tempFontSize + "px";
	}

	/**
	 * Makes the font bigger until the passed maximum is reached or the text overflows.
	 * Increments fontSize by passed stepSize in each iteration.
	 */
	_upsizeTextToFit(div, maxFontSize, stepSize) {
		let tempFontSize = parseFloat(window.getComputedStyle(div, null).getPropertyValue('font-size'));
		let maxFontUpsize = maxFontSize - tempFontSize;
		let minFontSize = tempFontSize;

		let i = 0;
		while (div.offsetWidth <= div.scrollWidth && i++ < (maxFontUpsize / stepSize)) {
			tempFontSize += stepSize;
			div.style.fontSize = tempFontSize + "px";
		}

		if (div.offsetWidth < div.scrollWidth) {
			// if the element now overflows revert last up-sizing-step
			tempFontSize -= stepSize;
			div.style.fontSize = Math.max(tempFontSize - stepSize, minFontSize) + "px";
		}
	}

	onReady() {
		this.ready = true;
		// update the layout
		this.element.parentNode.style.overflow = 'visible';
		this.element.style.overflow = 'hidden';
		if (this.txtDiv) {
			this.maxFontSize = window.getComputedStyle(this.txtDiv, null).getPropertyValue('font-size');
		}
		if ( this.bkgTrn ) {
			// make us really transparent!
			this.itmMgr.rmkBkgStl(this.element.parentNode);
		}
		this.layout();
		let par = {};
		par.ini = true;
		if ( this.rquTxs ) {
			if ( this.txtDiv ) {
				par.sts = this.itmMgr.measureText(this.txtDiv, false, 0, false).sts;
			} else {
				par.sts = new JsSize(0, 0);
			}
		}
		this._nfySrv("WDGINI", par);
	}

	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
		}
	}

	/**
	 * called by the menu manager if a menu items was clicked
	 * @param {Number} id ID of the menu item
	 */
	onMenuItem(id) {
		if ( this.ready ) {
			const par = {};
			par.id = id || '';
			this._psa.setBscRqu();
			this._nfySrv("MNUITM", par);
		}
	}

	/**
	 * called by the menu manager if a menu was closed, that was triggered by this instance
	 */
	onMenuClose() {
		this.pop = false;
	}

	destroy() {
		this._cleanup(true);
		delete this.popMnu;
		this.parent = null;
		this.itmMgr = null;
		this.ready = false;
	}

	layout() {
		if ( this.sepNfo ) {
			return;
		}
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = area[2] + 'px';
			this.element.style.height = area[3] + 'px';
			let dw = 0;
			if ( this.ddiSiz ) {
				dw = this.ddiSiz.cx;
			}
			const avl_width = area[2];
			const avl_height = area[3];
			if ( (avl_width <= 0) || (avl_height <= 0) ) {
				// don't change anything!
				return;
			}
			let ew = avl_width - (2 * this.padOfs);
			const eh = avl_height;
			if ( this.imgDiv && this.txtDiv ) {
				switch ( this.hrzAln ) {
				case "left":
				case "inline":
				case "right": {
						const lft = (this.hrzAln === "left") || (this.hrzAln === "inline");
						this._cenVert(this.element, this.imgDiv, false, false, 0);
						let iw = this._getSiz(this.imgDiv, false, false, 0).width;
						let tw = ew - iw - dw - this.padOfs - this.hrzPad;
						let co = 0;
						if ( this.hrzAln === "inline" ) {
							let txs = this.itmMgr.measureText(this.txtDiv, true, tw, false);
							let mxh = eh - 2*this.padOfs;
							if ( (txs.wts.cy > 0) && (txs.wts.cy < mxh) ) {
								txs = this.itmMgr.fitTextWidth(this.txtDiv, tw, mxh);
								tw = txs.wts.cx;
							}
							let ow = iw + tw + this.padOfs;
							co = Math.max(0, Math.round((ew-ow)/2));
						} else if ( this.hrzAln === "left" ) {
							co += this.hrzPad;
						}
						this._cenVert(this.element, this.txtDiv, true, !this.sngLin, tw);
						if ( lft ) {
							this.imgDiv.style.left = co + this.padOfs + 'px';
							this.txtDiv.style.left = co + 2 + 2*this.padOfs + iw + 'px';
						} else {
							this.txtDiv.style.left = co + this.padOfs + dw + 'px';
							this.imgDiv.style.left = co + 2 + 2*this.padOfs + dw + tw + 'px';
						}
						if ( tw > 2 ) {
							this.txtDiv.style.width = tw - 2 + 'px';
						}
					}
					break;
				default: {
						let xh = eh;
						let ims = this._getSiz(this.imgDiv, false, false, 0);
						let ih = ims.height;
						let txs = this.itmMgr.measureText(this.txtDiv, true, ew, false);
						let th = txs.wts.cy;
						let sh = ih + th + this.padOfs;
						if ( xh > sh ) {
							let top = 0;
							switch ( this.vrtAln ) {
							case "top":
								top = Math.min(this.padOfs, xh-sh);
								break;
							case "bottom":
								top = Math.max(this.padOfs, xh-sh);
								break;
							default:
								top = Math.max(this.padOfs, Math.round((xh-sh)/2));
								break;
							}
							this.imgDiv.style.top = top + 'px';
							this.imgDiv.style.left = Math.round((ew-ims.width)/2) + this.padOfs + 'px';
							top += ih;
							if ( txs.sts.cy === txs.wts.cy ) {
								top += this.padOfs;
							}
							this.txtDiv.style.top = top + 'px';
							this.txtDiv.style.width = txs.wts.cx + 'px';
							this.txtDiv.style.left = Math.round((ew-txs.wts.cx)/2) + this.padOfs + 'px';
						}
					}
					break;
				}
			} else {
				let div = this.imgDiv ? this.imgDiv : this.txtDiv;
				if ( div ) {
					let ofs = this.padOfs;
					if ( div === this.imgDiv ) {
						const imr = this.imgDiv.getBoundingClientRect();
						if ( this.svgImg || this.isImgElm ) {
							const img = this.imgDiv.children[0];
							const svw = img._psa_width || 0;
							const svh = img._psa_height || 0;
							if ( (svw > 0) && (svh > 0) ) {
								ew += 2 * ofs;	// use maximal width...
								ofs = 0;		// with no padding offset
								const fh = ew / svw;
								const fv = eh / svh;
								let f = Math.min(fv, fh);
								if ( !this.svgImg ) {
									f = Math.min(f, 1);
								}
								const imw = (f * svw);
								const imh = (f * svh);
								img.style.width = '' + imw + 'px';
								img.style.height = '' + imh + 'px';
								div.style.width = '' + imw + 'px';
								div.style.height = '' + imh + 'px';
							}
						}
						else if ( imr.width > ew ) {
							// extend to the max!
							ew += 2 * ofs;
							ofs = 0;
						}
					}
					switch ( this.hrzAln ) {
					case "left": {
							ofs += this.hrzPad;
							ew -= this.hrzPad;
							div.style.textAlign = this.hrzAln;
						}
						break;
					case "right": {
							ew -= this.hrzPad;
							div.style.textAlign = this.hrzAln;
						}
						break;
					default:
						break;
					}
					this._cenVert(this.element, div, (div === this.txtDiv), !this.sngLin, ew);
					div.style.left =  ofs + 'px';
					div.style.width = ew + 'px';
					if ( div === this.txtDiv ) {
						this._calculateFontSizeToFit(div, div.clientHeight);
					}
				}
			}
			if ( this.ddiDiv ) {
				let ddi = this.ddiDiv;
				ddi.style.top = '0px';
				ddi.style.width = dw + 'px';
				switch ( this.hrzAln ) {
				case "left":
				case "inline":
				case "right":
					this._cenVert(this.element, ddi, false, false, 0);
					break;
				default:
					break;
				}
				if ( this.hrzAln === 'right' ) {
					ddi.style.left = this.padOfs + 'px';
				} else {
					ddi.style.left = (area[2] - dw - this.padOfs) + 'px';
				}
			}
			if ( this.ckmDiv ) {
				const ckh = this.chkSiz;
				this.ckmDiv.style.left = "0px";
				this.ckmDiv.style.top = (area[3] - ckh).toString() + 'px'
				this.ckmDiv.style.width = area[2] + 'px';
				this.ckmDiv.style.height = ckh + 'px';
			}
			if ( this.lndDiv ) {
				const lnd = this.lndDiv;
				lnd.style.marginRight = this.padOfs + 'px';
				const lh = this._getSiz(lnd, false, false, 0).height;
				if ( lh < avl_height ) {
					lnd.style.top = '' + ((avl_height-lh) / 2) + 'px';
				}
				else {
					lnd.style.top = '0';
				}
			}
		}
	}
	
	setJsProp(prop) {
		if ( this.element && this.element.parentNode && this.ready ) {
			const jsp = prop || {};
			jsp.update = true;
			this.setPar(jsp);
		}
	}

	/**
	 * sets the popup menu
	 * @param {Object} args menu structure
	 */
	setMnu(args) {
		if ( this.popMnu ) {
			// clean-up first!
			const mnu = this.popMnu;
			this.popMnu = null;
			this.pop = false;
			mnu.destroy();
		}
		if ( args && args.id && args.items && args.items.length && (args.items.length > 0) ) {
			// that's (hopefully) a menu structure
			const idm = args.id || 0;
			const items = args.items;
			this.popMnu = this._psa.getMnuMgr().createMenu(idm, items);
		}
	}

	setPar(jsp) {
		const upd = this.element && !!jsp.update;
		if ( upd ) {
			const csh = this._psa.isStr(jsp.cusHtml);
			const nim = "imgObj" === jsp.wdgNfo;
			const nha = this.hrzAln !== this._getHAln(jsp);
			const nva = this.vrtAln !== this._getVAln(jsp);
			let rbd = csh || nim || nha || nva;
			if ( !rbd ) {
				const txt = jsp.text;
				if ( txt && (txt.length > 0) && !this.txtDiv ) {
					rbd = true;
				}
			}
			if ( !rbd ) {
				const new_img = !!jsp.imgObj;
				const has_img = !!this.imgDiv;
				// force a complete re-build if the presence of an image changes, but also to update an existing image property
				rbd = (new_img !== has_img) || (new_img && has_img);
			}
			if ( !rbd ) {
				const ddi = jsp.drpDwn;
				if ( ddi && !this.ddiDiv ) {
					rbd = true;
				} else if ( !ddi && this.ddiDiv ) {
					rbd = true;
				}
			}
			if ( rbd ) {
				this._cleanup(false);
				this._init(this.element, jsp, false);
			} else {
				if ( this.txtDiv ) {
					const txt = jsp.text;
					if ( txt && (txt.length > 0) ) {
						this.txtDiv.innerHTML = this._fmtTxt(jsp, txt);
					}
				}
			}
		}
		if ( this.element ) {
			let pad_ofs = jsp.padOfs;
			if ( (typeof pad_ofs === 'number') && (pad_ofs >= 0) ) {
				this.padOfs = pad_ofs;
			}
			this.staDis = !!jsp.staDis;
			if ( this.staDis ) {
				this.element.style.opacity = '0.4';
			}
			else {
				this.element.style.opacity = '';
			}
			this.chkClr = jsp.chkClr || null;
			this.bkgClr = jsp.bkgClr || null;
			this.hvrClr = jsp.hvrClr || null;
			this.fgrClr = jsp.fgrClr || null;
			let bki = jsp.bkgImg || null;
			let trn = !!jsp.bkgTrn;
			if ( trn ) {
				bki = null;
				this.bkgClr = null;
				this.bkgTrn = true;
			}
			this.itmMgr.setBkgClr(this.element, this.bkgClr, false);
			this.itmMgr.setFgrClr(this.element, this.fgrClr);
			if ( trn && this.element.parentNode ) {
				this.itmMgr.rmkBkgStl(this.element.parentNode);
			}
			if ( this.txtDiv ) {
				if ( jsp.txtFnt ) {
					this.itmMgr.setFnt(this.txtDiv, jsp.txtFnt);
				} else if ( jsp.swtFnt ) {
					this.itmMgr.setFnt(this.txtDiv, jsp.swtFnt);
				}
				let sng = !!jsp.sngLin;
				this.sngLin = sng;
				this.fixTxs = !!jsp.fixTxs;
				this.txtDiv.style.whiteSpace = sng ? 'nowrap' : 'normal';
				if ( sng && this.fixTxs ) {
					this.txtDiv.style.textOverflow='ellipsis';
					this.txtDiv.style.overflow='hidden';
				}
			} else {
				this.sngLin = false;
				this.fixTxs = false;
				if ( this._psa.isStr(jsp.cusHtml) ) {
					if ( jsp.txtFnt ) {
						this.itmMgr.setFnt(this.element, jsp.txtFnt);
					} else if ( jsp.swtFnt ) {
						this.itmMgr.setFnt(this.element, jsp.swtFnt);
					}
				}
			}
			let bki_uri = null;
			let rfr_clk = false;
			if ( bki ) {
				if ( "IMG" === bki.typ ) {
					bki_uri = bki.img[0];
				}
			} else if ( this._psa.isStr(jsp.bkiUrl) ) {
				bki_uri = jsp.bkiUrl;
				rfr_clk = true;
			}
			if ( this._psa.isStr(bki_uri) ) {
				const elm = this.element;
				elm.style.backgroundSize = 'cover';
				elm.style.backgroundImage = 'url(' + bki_uri + ')';
				if ( rfr_clk && !elm.__has_upd_lsr ) {
					elm.__has_upd_lsr = true;
					const self = this;
					elm.addEventListener('contextmenu', (e) => {
						if ( e.target === elm ) {
							e.preventDefault();
							self._nfySrv('NEWBKGIMG', { bkiUrl: bki_uri });
						}
					});
				}
			}
			let pad = jsp.hrzPad;
			if ( pad && (typeof pad === 'number') ) {
				this.hrzPad = pad;
			}
			let css = jsp.cssCls;
			if ( !css ) {
				css = "";
			}
			this.element.className = css;
			let cbs = jsp.size;
			if ( cbs && (typeof cbs === 'number') && (cbs > 0) ) {
				this.chkSiz = cbs;
			}
			if ( typeof jsp.staChk === 'boolean' ) {
				this._setChkSta(!!jsp.staChk);
			}
			if ( upd ) {
				this.layout();
			}
			if ( upd && this.rquTxs ) {
				// text update --> calculate required text size and send it to the web server
				let par = {};
				if ( this.txtDiv ) {
					par.sts = this.itmMgr.measureText(this.txtDiv, false, 0, false).sts;
				} else {
					par.sts = new JsSize(0, 0);
				}
				this._nfySrv("RQUTXS", par);
			}
			const frc_mls = !!jsp.frcMls;
			const has_hvc = !!this.hvrClr;
			if ( !this.hasMls && (frc_mls || has_hvc) ) {
				this.hasMls = true;
				this.frcBtn = frc_mls;
				const self = this;
				if ( has_hvc ) {
					this.element.addEventListener("mouseenter", (evt) => {
						self._onMouseEvt(evt, true, null, false);
					}, false);
					this.element.addEventListener("mouseleave", (evt) => {
						self._onMouseEvt(evt, false, false, false);
					}, false);
				}
				this.element.addEventListener("mousedown", (evt) => {
					self._onMouseEvt(evt, true, true, false);
				}, false);
				this.element.addEventListener("mouseup", (evt) => {
					self._onMouseEvt(evt, true, false, true);
				}, false);
			}
			if ( jsp.onclick && (jsp.onclick.length > 0) ) {
				this.element.setAttribute('onclick', jsp.onclick);
			}
		}
	}
	
	/**
	 * makes this instance to look like a seperator
	 * @param {Object} args arguments
	 */
	setSepLine(args) {
		const nfo = { vert: !!args.vert };
		this.sepNfo = nfo;
		if ( this.element ) {
			this._init(this.element, null, false);
		}
	}

	/**
	 * called to update a menu item
	 * @param {Object} args arguments
	 */
	updMnu(args) {
		if ( this.popMnu ) {
			const id = args.id || 0;
			if ( id ) {
				// update the menu item
				this.popMnu.updMnuItm(id, args);
			}
		}
	}

	/**
	 * forces a layout update
	 * @param {Object} args arguments, not used here
	 */
	forceLayout(args) {
		if ( this.ready ) {
			this.layout();
		}
	}

	_makeSep(div) {
		div.innerHTML = '';
		this.txtDiv = null;
		this.imgDiv = null;
		this.ddiDiv = null;
		this.lndDiv = null;
		this.ckmDiv = null;
		const vert = !!this.sepNfo.vert;
		const brc = this._psa.UIUtil.getCssRgb(this.fgrClr) || "#e1e1e1";
		div.style.width = 'inherit';
		div.style.height = 'inherit';
		div.style.display = 'flex';
		div.style.flexDirection = vert ? 'row' : 'column';
		if ( vert ) {
			div.style.paddingTop = '4px';
			div.style.paddingBottom = '4px';
		} else {
			div.style.paddingLeft = '4px';
			div.style.paddingRight = '4px';
		}

		const sep = document.createElement('div');
		sep.style.flexBasis = '50%';
		if ( vert ) {
			sep.style.borderRight = '1px solid ' + brc;
		} else {
			sep.style.borderBottom = '1px solid ' + brc;
		}
		div.appendChild(sep);
	}

	_setChkSta(chk_sta) {
		if ( this._chkImg ) {
			let clr = chk_sta ? this.chkClr : null;
			this.itmMgr.setFgrClr(this.imgDiv, clr);
		} else if ( this.ckmDiv ) {
			this.ckmDiv.style.visibility = chk_sta ? 'visible' : 'hidden';
		}
	}

	_nfySrv(code, par) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify("XWDG_NFY", param);
		}
	}

	_getSiz(div, mea, wrp, wdt) {
		let res = {};
		if ( mea ) {
			let dsz = this.itmMgr.measureText(div, wrp, wdt, false);
			if ( wrp ) {
				res.width = dsz.wts.cx;
				res.height = dsz.wts.cy;
			} else {
				res.width = dsz.sts.cx;
				res.height = dsz.sts.cy;
			}
		} else {
			res = this.itmMgr.getElementSize(div);
		}
		return res;
	}

	_cenVert(par, div, mea, wrp, wdt) {
		let xr = this._getSiz(par, false, false, 0);
		let dr = null;
		if ( this.isImgElm && (div === this.imgDiv) && div.firstElementChild ) {
			dr = div.firstElementChild.getBoundingClientRect();
		} else {
			dr = this._getSiz(div, mea, wrp, wdt);
		}
		if ((dr.height>0) && (dr.height < xr.height)) {
			let top = 0;
			let hgt = dr.height;
			switch ( this.vrtAln ) {
			case "top":
				top = Math.min(2, xr.height-hgt);
				break;
			case "bottom":
				top = Math.max(2, xr.height-hgt-2);
				break;
			default:
				top = Math.round((xr.height-hgt)/2);
				break;
			}
			div.style.top = top + "px";
			if ( mea ) {
				div.style.height = hgt + "px";
			}
		}
	}

	/**
	 * handles mouse events
	 * @param {MouseEvent} evt mouse event
	 * @param {Boolean} hvr "hover" flag
	 * @param {Boolean} dwn "mouse button down" flag
	 * @param {Boolean} nfy "notify" flag
	 */
	_onMouseEvt(evt, hvr, dwn, nfy) {
		if ( this.element && !this.staDis && (this.frcBtn || this.hvrClr) ) {
			const nfo = this.hvrClr || {};
			const has_down = (dwn !== null) && (typeof dwn === 'boolean');
			const was_down = !!this.btnDwn;
			if ( has_down ) {
				this.btnDwn = dwn;
			}
			let bgc = this.bkgClr;
			let txc = this.fgrClr;
			let shd = '';
			if ( hvr ) {
				if ( this.btnDwn ) {
					bgc = nfo.dwnClr || bgc;
					txc = nfo.dwnTxc || txc;
				} else {
					bgc = nfo.hvrClr || bgc;
					txc = nfo.hvrTxc || txc;
				}
				if ( this.btnDwn && nfo.dwnShd ) {
					shd = nfo.dwnShd;
				}
				else if ( nfo.hvrShd ) {
					shd = nfo.hvrShd;
				}
			}
			const lnk = nfo.hypLnk ? true : false;
			const him = !!(nfo.icoDef && nfo.icoHvr);
			const div = (lnk ? this.txtDiv : this.element) || this.element;
			if ( hvr || dwn ) {
				if ( nfo.hvrCur ) {
					div.style.cursor = nfo.hvrCur;
				}
				if ( lnk ) {
					div.style.textDecoration = 'underline';
				}
			} else {
				if ( div === this.txtDiv ) {
					div.style.cursor = 'inherit';
				} else {
					div.style.cursor = 'default';
				}
				if ( lnk ) {
					div.style.textDecoration = '';
				}
			}
			if ( him && this.imgDiv ) {
				this.itmMgr.updDscIco(this.imgDiv, hvr ? nfo.icoHvr : nfo.icoDef);
			}
			this.itmMgr.setBkgClr(this.element, bgc, false);
			this.itmMgr.setFgrClr(this.element, txc);
			this.element.style.boxShadow = shd;
			if ( nfy && has_down && was_down && !this.btnDwn ) {
				if ( this.popMnu ) {
					// show popup menu
					this._showMnu();
				} else {
					// send notification to the web server
					const btn = evt.button || 0;
					const par = {};
					par.sel = true;
					par.btn = btn;
					this._psa.setBscRqu();
					this._nfySrv("WDGSEL", par);
				}
			}
		}
	}

	/**
	 * shows the popup menu
	 */
	_showMnu() {
		if ( this.popMnu && !this.pop ) {
			this.pop = true;
			this._psa.getMnuMgr().showMenu(this.popMnu, { element: this.element, rect: null, outside: true }, this, true, true);
		}
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget XWdg.');
		rap.registerTypeHandler("psawidget.XWdg", {
		
			factory : function(properties) {
				return new XWdg(properties);
			},
		
			destructor : 'destroy',
			properties : ['jsProp', 'mnu' ],
			methods : [ 'setPar', 'setSepLine', 'updMnu', 'forceLayout' ],
			events : [ 'XWDG_NFY' ]
		} );
	}
}										

console.log('widgets/xwdg/XWdg.js loaded.');