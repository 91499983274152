import AttachmentObject from '../../../utils/AttachmentObject';
import DomEventHelper from '../../../utils/DomEventHelper';
import EventListenerManager from '../../../utils/EventListenerManager';
import ItmMgr from '../../../gui/ItmMgr';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import XtwUtils from '../util/XtwUtils';
import { DO_LOG, OWN_TEXT_COLOR } from './XRowItem';
import { OWN_BACKGROUND_COLOR } from './XCellItem';

export const GROUP_HEADER_CLASS = "group-header";
const ICO_COLLAPSED = '<i class="far fa-angle-right" style="vertical-align:middle;"></i>';
const ICO_EXPANDED = '<i class="far fa-angle-down" style="vertical-align:middle;"></i>';
const ICO_EMPTY = '';

export default class XRowItemGroupHeaderExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get isGroupHead() {
		const modelItem = this.item;
		if ( Validator.is( modelItem, "MGroup" ) ) {
			return true;
		}
		if ( !Validator.isObject( modelItem ) ||
			!Validator.isFunction( modelItem.isGroupHead ) ) {
			return false;
		}
		return modelItem.isGroupHead();
	}

	addDblclickListener() {
		return this.addListener( "dblclick", "onDoubleClick" );
	}

	onDoubleClick( evt ) {
		if ( !this.isRendered || !( evt instanceof MouseEvent ) ||
			!this.isGroupHead ) {
			return false;
		}
		return this._toggleExpandedCollapsedGroupState();
	}

	_setGroupHeaderModelItem( tableHeaderWidget, modelItem, updateSelectionUi = true ) {
		const xRowItemElement = this.getDomElement();
		if ( xRowItemElement instanceof HTMLElement ) {
			xRowItemElement.classList.remove( 'xtwrtprowitem' );
			xRowItemElement.classList.add( GROUP_HEADER_CLASS );
		}
		if ( this.rtpMode ) {
			this._adjustRowItemHeight( xRowItemElement, modelItem );
		}
		this._setupGroupHeader( tableHeaderWidget );
		Validator.isFunction( modelItem.syncSelectionManager ) ?
			modelItem.syncSelectionManager( updateSelectionUi ) :
			this.syncSelectionManager( updateSelectionUi );
		// this.syncSelectionManager();
	}

	/**
	 * creates / updates a group header
	 * @param {XtwHdr} xth the table header widget
	 */
	_setupGroupHeader( xth ) {
		// drop dynamic part
		this._dropCont( true, true );
		// make sure that fixed container exists
		this._creCont( null, true, false );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onGroupHeaderContexmenu",
			callBackPrefix: "GroupHeader",
			element: this.element,
			useCapture: false
		} );
		if ( !Validator.isObjectPath( this.item, "item.ctt" ) ||
			!( this.ccnFix instanceof HTMLElement ) ) {
			return false;
		}
		this.ccnFix.classList.add( 'xtwgrouphead' );
		this._setupGroupHeaderButton();
		this._setupGroupHeaderContent();
		const backgroundColor = Validator.isObjectPath( this.item.ctt, "ctt.prop.bgc" ) ?
			this.item.ctt.prop.bgc : void 0;
		this.setGroupHeaderBackgroundColor( this.ccnFix, this.item.ctt.prop.bgc );
		return true;
	}

	_setupGroupHeaderButton() {
		if ( !Validator.isObject( this.item ) ||
			!( this.ccnFix instanceof HTMLElement ) ) {
			return false;
		}
		if ( this.btnGcl instanceof HTMLElement ) {
			Warner.traceIf( DO_LOG, `The group header row item already has an` +
				` expand/collapse button. A new button will be created to replace the` +
				` old one.` );
		}
		const expandStateButton = document.createElement( 'div' );
		expandStateButton.classList.add( 'xtwgroupcollapse' );
		if ( this.item.hasChildren() ) {
			expandStateButton.addEventListener( 'click', domEvent => {
				this._onExpandClick( domEvent );
			} );
			expandStateButton.innerHTML = this.item.isCollapsed() ?
				ICO_COLLAPSED : ICO_EXPANDED;
		} else {
			expandStateButton.innerHTML = ICO_EMPTY;
		}
		this.ccnFix.appendChild( expandStateButton );
		this.btnGcl = expandStateButton;
		return true;
	}

	_setupGroupHeaderContent() {
		if ( !Validator.isObjectPath( this.item, "item.ctt" ) ||
			!( this.ccnFix instanceof HTMLElement ) ) {
			return false;
		}
		const groupHeaderContent = document.createElement( 'div' );
		groupHeaderContent.classList.add( 'xtwgrouptext' );
		this.renderGroupHeaderContent( groupHeaderContent, this.item.ctt );
		this.ccnFix.appendChild( groupHeaderContent );
		return true;
	}

	setGroupHeaderBackgroundColor( element, color ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		let backgroundColor = XtwUtils.colorArrayToRgba( color );
		if ( !Validator.isString( backgroundColor ) ) {
			backgroundColor = null;
		}
		element.style.setProperty( OWN_BACKGROUND_COLOR, backgroundColor );
		return true;
	}

	renderGroupHeaderContent( groupTextContainerElement, contentProperties ) {
		if ( !( groupTextContainerElement instanceof HTMLElement ) ) {
			return false;
		}
		if ( !Validator.isObject( contentProperties ) ) {
			groupTextContainerElement.innerHTML = "";
			return false;
		}
		const text = Validator.isString( contentProperties.text ) ?
			contentProperties.text : "";
		groupTextContainerElement.innerHTML = text;
		const properties = contentProperties.prop;
		const backgroundColor = Validator.isObject( properties ) ?
			properties.bgc : null;
		const rgbaBackgroundColor =
			XtwUtils.colorArrayToRgba( backgroundColor );
		if ( Validator.isString( rgbaBackgroundColor ) ) {
			groupTextContainerElement.style
				.setProperty( OWN_BACKGROUND_COLOR, rgbaBackgroundColor );
		}
		const color = Validator.isObject( properties ) ? properties.txc : null;
		const rgbaColor = XtwUtils.colorArrayToRgba( color );
		if ( Validator.isString( rgbaColor ) ) {
			groupTextContainerElement.style
				.setProperty( OWN_TEXT_COLOR, rgbaColor );
		}
		const itemManager = ItmMgr.getInst();
		if ( !Validator.is( itemManager, "ItmMgr" ) ) {
			return false;
		}
		const font = Validator.isObject( properties ) ? properties.font : null;
		itemManager.setFnt( groupTextContainerElement, font );
		return true;
	}

	onGroupHeaderContexmenu( domEvent ) {
		Warner.traceIf( true, "contextmenu" );
		DomEventHelper.stopIf( domEvent );
	}

	/**
	 * handles clicks on the group expand/collapse button
	 * @param {MouseEvent} e the click event
	 */
	_onExpandClick( e ) {
		e.stopPropagation();
		e.preventDefault();
		this._toggleExpandedCollapsedGroupState();
	}

	_toggleExpandedCollapsedGroupState() {
		const modelItem = this.item;
		if ( !Validator.is( modelItem, "MGroup" ) ) {
			return false;
		}
		if ( !modelItem.hasChildren() ) {
			return false;
		}
		const newState = !modelItem.isCollapsed();
		// set the "collapsed" status of the group...
		modelItem.setCollapsed( newState );
		// ... and trigger the UI
		let uiTriggered = false;
		if ( Validator.isObject( this.tblBody ) &&
			Validator.isFunction( this.tblBody.onGroupExpanded ) ) {
			this.tblBody.onGroupExpanded( modelItem );
			uiTriggered = true;
		}
		// ... and then, this should not be necessary any longer
		let arrowIconChanged = false;
		if ( this.btnGcl instanceof HTMLElement ) {
			this.btnGcl.innerHTML = newState ? ICO_COLLAPSED : ICO_EXPANDED;
			arrowIconChanged = true;
		}
		return uiTriggered && arrowIconChanged;
	}

}
