import PSA from '../../psa';
import HtmHelper from '../../utils/HtmHelper';
import Validator from '../../utils/Validator';
import Warner from '../../utils/Warner';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import EventListenerManager from '../../utils/EventListenerManager';
import ObjReg from '../../utils/ObjReg';
import JsSize from '../../utils/JsSize';
import ColumnManagementExtension from './impl/column/ColumnManagementExtension';
import XtwHeadScrollingExtension from './impl/scrolling/XtwHeadScrollingExtension';
import XtwHeadContextMenuExtension from './impl/contextmenu/XtwHeadContextMenuExtension';
import XtwMgr from './util/XtwMgr';
import XtwTbl from './XtwTbl';
import XtwBody from './XtwBody';
import XtwCol from './parts/XtwCol';
import ItmMgr from '../../gui/ItmMgr';
import XtwUtils from './util/XtwUtils';
import MnuObj from '../../gui/menu/MnuObj';
import DomEventHelper from '../../utils/DomEventHelper';
import RequestHolder from '../../utils/RequestHolder';
import CellCtt from './model/CellCtt';

const OWN_BACKGROUND_COLOR = "--rtp-own-background-color";
const OWN_TEXT_COLOR = "--rtp-own-text-color";

const DO_LOG = false;

/**
 * class XtwHead - the header of an eXtended Table Widget (XTW)
 */
export default class XtwHead {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor( properties ) {
		this._psa = PSA.getInst();
		this._psa.bindAll( this, [ "layout", "onReady", "onInitRender" ] );
		this._alive = true;
		this.ready = false;
		this.xtwBody = null;
		this.element = null;
		this.clrTxt = null;
		this.sortColumn = -1;
		this.sortDirection = false;
		this.fieldMenu = null;
		this.rcoArgs = null;
		this.size = new JsSize( -1, -1 );
		this.renderRqu = this._psa.getRequestHolder();
		// the main column container
		this.columns = new ObjReg();
		new ColumnManagementExtension( this );
		new XtwHeadScrollingExtension( this );
		new XtwHeadContextMenuExtension( this );
		this.rtpColumns = null;
		// an "ordered" column container
		const oc = {};
		oc.fix = [];
		oc.dyn = [];
		this.ordCol = oc;
		// get the RAP parent element
		const idw = '' + properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject( idw );
		// create our "own" DOM element
		this.element = document.createElement( 'div' );
		this.element.classList.add( "xtwhead" );
		if ( Validator.is( this.element.dataset, "DOMStringMap" ) ) {
			const className = Validator.getClassName( this );
			if ( Validator.isString( className ) )
				this.element.dataset.class = className;
		}
		// create scroll container
		const sc = document.createElement( 'div' );
		if ( Validator.is( sc.dataset, "DOMStringMap" ) )
			sc.dataset.class = "scroll container";
		// create "fixed" and "dynamic" column containers
		const cf = document.createElement( 'div' );
		if ( Validator.is( cf.dataset, "DOMStringMap" ) )
			cf.dataset.class = "fixed container";
		const cd = document.createElement( 'div' );
		if ( Validator.is( cd.dataset, "DOMStringMap" ) )
			cd.dataset.class = "dynamic container";
		this.element.appendChild( cf );
		sc.appendChild( cd );
		this.element.appendChild( sc );
		this.scrCnt = sc;
		this.ccnFix = cf;
		this.ccnDyn = cd;
		this.wdtFix = 0;
		this.wdtDyn = 0;
		this.visWdt = 0;
		// add the main DOM element to the RAP parent
		this.parent.append( this.element );
		// we need the resize listener
		this.parent.addListener( "Resize", this.layout );
		// activate "render" event
		rap.on( "render", this.onInitRender );
		// get custom widget data - we need the ID of our parent eXtended Table Widget
		this.xtdTbl = null;
		const cwd = this.parent.getData( "pisasales.CSTPRP.CWD" ) || {};
		if ( Validator.isString( cwd.searchInColumnsMenuOptionTitle ) ) {
			Object.defineProperty( this, "searchInColumnsMenuOptionTitle", {
				value: cwd.searchInColumnsMenuOptionTitle,
				writable: false,
				configurable: false
			} );
		}
		// ID string
		this.wdgIdStr = cwd.idstr || '';
		if ( this.element.dataset ) {
			this.element.dataset.idstr = this.wdgIdStr;
		}
		const idp = cwd.idp || '';
		if ( idp ) {
			const xtw = XtwMgr.getInst().getXtdTbl( idp );
			if ( xtw instanceof XtwTbl ) {
				xtw.setHeadWdg( this );
				this.xtdTbl = xtw;
			}
		}
		this.selAllTtl = cwd.selallttl || '';
		this._init();
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		this._alive = false;
		this.ready = false;
		this.ordCol.fix = [];
		this.ordCol.dyn = [];
		if ( this.rtpColumns ) {
			this.rtpColumns.clear();
			delete this.rtpColumns;
		}
		this.removeContextMenuListener();
		this._dropFieldMenu();
		delete this.fieldMenu;
		delete this.ordCol;
		this.columns.destroy();
		delete this.xtwBody;
		delete this.xtdTbl;
		delete this.columns;
		delete this.ready;
		delete this.size;
		delete this.clrTxt;
		delete this.renderRqu;
		if ( this.element && this.element.parentNode ) {
			this.element.parentNode.removeChild( this.element );
		}
		delete this.ccnDyn;
		delete this.ccnFix;
		delete this.scrCnt;
		delete this.element;
	}

	/**
	 * @returns {Boolean} true if the widget is alive; false otherwise
	 */
	get alive() {
		return this._alive;
	}

	/**
	 * @return {Boolean} whether or not the current template is a "row template";
	 * "true" if the current template is a "row template", "false" otherwise
	 */
	get isRowTpl() {
		if ( !Validator.is( this.xtwBody, "XtwBody" ) ||
			!( "isRowTpl" in this.xtwBody ) ) {
			return false;
		}
		return this.xtwBody.isRowTpl;
	}

	/**
	 * @return {Boolean} whether or not the "row template" view/display is
	 * supported
	 */
	get hasRowTpl() {
		if ( !Validator.is( this.xtdTbl, "XtwTbl" ) ||
			!( "hasRowTpl" in this.xtdTbl ) ) {
			return false;
		}
		return this.xtdTbl.hasRowTpl === true;
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
	}

	/**
	 * called by the framework in rendering phase - this is the initial "render" listener
	 */
	onInitRender() {
		if ( this.parent ) {
			rap.off( "render", this.onInitRender ); // just once!
			this.onReady();
			this.layout();
			this._attachEventHandlers();
			this._rebuildHeader();
		}
	}

	/**
	 * handles the "mouseleave" event when it happens on the table widget element
	 * @param {MouseEvent} evt the "mouseleave" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	onTblMouseLeave( evt ) {
		return this.onMouseLeave( evt );
	}

	/**
	 * handles the "mousemove" event when it happens on the table widget element
	 * @param {MouseEvent} evt the "mousemove" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	onTblMouseMove( evt ) {
		// TODO
		// Warner.traceIf( DO_LOG );
	}

	/**
	 * handles the "mouseup" event when it happens on the table widget element
	 * @param {MouseEvent} evt the "mouseup" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	onTblMouseUp( evt ) {
		Warner.traceIf( DO_LOG );
		return this.voidMovingProperties( true );
	}

	/**
	 * handles the "wheel" event when it happens on the table widget element
	 * @param {Event} evt the "wheel" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	onTblMouseWheel( evt ) {
		Warner.traceIf( DO_LOG );
		return this.voidMovingProperties( true );
	}

	/**
	 * initiates a column drag operation
	 * @param {MouseEvent} evt the mouse event
	 * @param {XtwCol} col the affected column
	 * @param {HTMLElement} elm the "hot" element
	 * @param {Boolean} full "full column" drag
	 */
	onColumnDrag( evt, col, elm, full ) {
		Warner.traceIf( DO_LOG );
		this.mouseDownEvent = evt;
		if ( Validator.isObject( this.xtdTbl ) &&
			Validator.isFunction( this.xtdTbl.onColumnDrag ) ) {
			this.voidMousedownProperties();
			this.xtdTbl.onColumnDrag( evt, col, elm, full );
		}
	}

	/**
	 * called if the user clicks the "select" column header element
	 * @param {MouseEvent} evt the "click" mouse event
	 */
	onSelClick( evt ) {
		Warner.traceIf( DO_LOG );
		if ( !( evt instanceof MouseEvent ) ) {
			Warner.traceIf( DO_LOG, `XtwHead#onSelClick was called with an invalid` +
				` parameter, that is not an instace of <MouseEvent>.` );
		}
		if ( Validator.is( this.xtwBody, "XtwBody" ) ) {
			this.xtwBody.onSelClick();
		}
		return true;
	}

	/**
	 * called after the user has changed a column with by dragging the column border
	 * @param {XtwCol} col the affected column
	 * @param {Number} width new column width
	 * @param {MouseEvent} evt the mouse up event
	 */
	onColumnWidth( col, width, evt ) {
		Warner.traceIf( DO_LOG );
		if ( Validator.isString( width ) ) {
			width = Number( width );
		}
		if ( !Validator.isValidNumber( width ) ) {
			return;
		}
		width = Math.round( width ); // only integer widths
		if ( Validator.isObject( this.xtdTbl ) &&
			Validator.isFunction( this.xtdTbl.resizeColumnOnAutoFit ) &&
			this.xtdTbl.resizeColumnOnAutoFit( col, width ) ) {
			// the table widget handled the column resize due to the fact that
			// the auto-fit mode is active; no further handling needed
			return;
		}
		const par = {};
		par.idc = col.id;
		par.width = width;
		this._nfySrv( 'columnWidth', par );
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		if ( !this.ready ) {
			return;
		}
		const area = this.parent.getClientArea();
		const wdt = area[ 2 ] || 0;
		const hgt = area[ 3 ] || 0;
		this.element.style.left = '0';
		this.element.style.top = '0';
		this.visWdt = wdt;
		this.element.style.width = wdt + 'px';
		// this.element.style.height = hgt + 'px';
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when this
	 * item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	get columnsArray() {
		if ( !Validator.is( this.columns, "ObjReg" ) ) {
			return void 0;
		}
		const columnsIterator = this.columns.getIterable();
		if ( !Validator.isIterable( columnsIterator ) ) {
			return void 0;
		}
		return [ ...columnsIterator ];
	}

	/**
	 * adds listeners to this item's element that are meant to assist the
	 * process of "column movement"
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	addListeners() {
		const mouseMoveListenerAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousemove",
			functionName: "onMouseMove"
		} );
		return mouseMoveListenerAdded;
	}

	/**
	 * gets & returns the HTML parent element of the table widget
	 * @see XtwTbl.js~XtwTbl~parElm
	 * @return {HTMLElement} the table widget's parent element
	 */
	get tableParentElement() {
		return !Validator.is( this.xtdTbl, "XtwTbl" ) ? void 0 :
			this.xtdTbl.parElm instanceof HTMLElement ? this.xtdTbl.parElm : void 0;
	}

	/**
	 * gets & returns the HTML parent element of the HTML element corresponding
	 * to this item
	 * @return {HTMLElement} the parent element of this item's element
	 */
	get parentElement() {
		return !( this.element instanceof HTMLElement ) ? void 0 :
			!( this.element.parentElement instanceof HTMLElement ) ? void 0 :
			this.element.parentElement;
	}

	/**
	 * gets the coordinates (x and y) of this position relative to the table
	 * widget element, based on the coordinates of the event
	 * @param {Event} evt the event
	 * @return {Object} the position object, with to properties: x and y
	 * @see XtwTbl.js~XtwTbl~_getEffPos
	 */
	getEffectivePosition( evt ) {
		return Validator.is( this.xtdTbl, "XtwTbl" ) ?
			this.xtdTbl._getEffPos( evt ) : void 0;
	}

	/**
	 * removes the temporary properties and flags that were set to assist during
	 * the process of "column movement"; also removes the column title preview
	 * HTML element from the DOM
	 * @param returnValue what this method should return
	 * @return the initial "returnValue" parameter
	 */
	voidMovingProperties( returnValue = void 0 ) {
		// Warner.traceIf( DO_LOG );
		this.movedColumn = void 0;
		delete this.movedColumn;
		if ( this.movedColumnPreviewElement instanceof HTMLElement ) {
			[ "mousemove", "mouseup" ].forEach( eventName => {
				EventListenerManager.removeListener( this, eventName,
					this.movedColumnPreviewElement, "ColumnMovementPreviewElement" );
			} );
			const movedColumnPreviewElement = this.movedColumnPreviewElement;
			movedColumnPreviewElement.remove();
		}
		this.movedColumnPreviewElement = void 0;
		delete this.movedColumnPreviewElement;
		return returnValue;
	}

	voidMousedownProperties( returnValue = void 0 ) {
		// Warner.traceIf( DO_LOG );
		this.mouseDownEvent = void 0;
		delete this.mouseDownEvent;
		return returnValue;
	}

	/**
	 * handles the "mouseleave" event when it happens on this item's element
	 * @param {MouseEvent} evt the "mouseleave" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	onMouseLeave( evt ) {
		// Warner.traceIf( DO_LOG );
		this.voidMousedownProperties();
		return this.voidMovingProperties( true );
	}

	/**
	 * handles the "mousemove" event when it happens on this item's element
	 * @param {MouseEvent} evt the "mousemove" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	onMouseMove( evt ) {
		// Warner.traceIf( DO_LOG );
		const previewElement = this.getMovedColumnPreviewElement();
		if ( !( previewElement instanceof HTMLElement ) ) {
			return this.voidMovingProperties( false );
		}
		const effectivePosition = this.getEffectivePosition( evt );
		if ( !Validator.isObject( effectivePosition ) ) {
			return this.voidMovingProperties( false );
		}
		previewElement.style.left = `${ effectivePosition.x + 5 }px`;
		evt.stopPropagation();
		evt.preventDefault();
		return true;
	}

	/**
	 * gets and returns the temporary "preview" HTML element that is attached
	 * to the table head element during the process of column movement and that
	 * replicates/ilustrates the title cell element from the column that is
	 * supposed to be moved; if the preview element already exists (is already
	 * present), this method simply returns it, otherwise the element is newly
	 * rendered
	 * @return {HTMLDivElement} the temporary element for the column moving
	 * preview
	 */
	getMovedColumnPreviewElement() {
		if ( this.movedColumnPreviewElement instanceof HTMLElement ) {
			return this.movedColumnPreviewElement;
		}
		if ( !Validator.is( this.movedColumn, "XtwCol" ) ||
			!( this.movedColumn.element instanceof HTMLElement ) ) {
			return this.voidMovingProperties();
		}
		const headParentElement = this.parentElement;
		if ( !Validator.isObject( headParentElement ) ) {
			return this.voidMovingProperties();
		}
		const copyOfMovedColumn = this.movedColumn.element.cloneNode( true );
		copyOfMovedColumn.classList.add( "temporary" );
		copyOfMovedColumn.style.zIndex = "99";
		copyOfMovedColumn.style.position = "absolute";
		copyOfMovedColumn.style.backgroundColor = "gray"; // TODO change
		copyOfMovedColumn.style.color = "white"; // TODO change
		EventListenerManager.addListener( {
			instance: this,
			eventName: "mousemove",
			functionName: "onMouseMove",
			element: copyOfMovedColumn,
			callBackPrefix: "ColumnMovementPreviewElement"
		} );
		EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "columnMovementPreviewElementMouseUp",
			element: copyOfMovedColumn,
			callBackPrefix: "ColumnMovementPreviewElement"
		} );
		headParentElement
			.insertBefore( copyOfMovedColumn, headParentElement.firstChild );
		this.movedColumnPreviewElement = copyOfMovedColumn;
		return this.movedColumnPreviewElement;
	}

	/**
	 * handles the "mousedown" event when it happens on the "main span" of a
	 * column that should potentially be moved
	 * @param {MouseEvent} evt the "mousedown" event
	 * @param {XtwCol} movedColumn the column that should be moved (on whose
	 * "main span" the event happened)
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	spanMouseDown( evt, movedColumn ) {
		Warner.traceIf( DO_LOG );
		if ( !( evt instanceof MouseEvent ) || evt.button !== 0 ) {
			return false;
		}
		this.mouseDownEvent = evt;
		if ( !Validator.is( movedColumn, "XtwCol" ) ) {
			return this.voidMovingProperties( false );
		}
		this.movedColumn = movedColumn;
		evt.stopPropagation();
		evt.preventDefault();
		return true;
	}

	/**
	 * handles the "mouseup" event when it happens on the temporary HTML
	 * element for the column moving preview
	 * @param {MouseEvent} evt the "mouseup" event
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	columnMovementPreviewElementMouseUp( evt ) {
		this.columnTitleMouseUp( evt );
		return true;
	}

	/**
	 * called if the user has clicked on the secondary ("language") icon
	 * @param {XtwCol} column the column
	 */
	onSecondIconClicked( column ) {
		this._psa.cliCbkWdg.setBscRqu();
		this._nfySrv( 'secondIconClicked', { idc: column.id } );
	}

	/**
	 * handles the "mouseup" event when it happens on a XtwCol item's HTML
	 * element, which corresponds to the title of a column
	 * @param {MouseEvent} evt the "mouseup" event
	 * @param {XtwCol} targetColumn the column on whose title the event happened
	 * @return {Boolean} true if the operation was successfull, false otherwise
	 */
	columnTitleMouseUp( evt, targetColumn ) {
		Warner.traceIf( DO_LOG );
		if ( !this.moveOneColumnBeforeTheOther( this.movedColumn, targetColumn ) ) {
			const titleColumnSorted = this.sortTitleColumn( evt, targetColumn );
			this.voidMousedownProperties();
			return this.voidMovingProperties( false ) && titleColumnSorted;
		}
		if ( this.mousedownAndMouseupHappenedOnSameSpot( evt ) ) {
			Warner.traceIf( DO_LOG, `One column is being moved before a second,` +
				` target column. However, the movement of the column was minimal,` +
				` therefore a movement should NOT be happening.` );
		}
		evt.stopPropagation();
		evt.preventDefault();
		this.voidMousedownProperties();
		// notify the web server about the new column order
		this.notifyColumnOrder();
		// ok, clean-up
		return this.voidMovingProperties( true );
	}

	/**
	 * sends a column order notification to the web server
	 */
	notifyColumnOrder() {
		const par = {};
		par.cols = [];
		const cnts = [ this.ccnFix, this.ccnDyn ];
		cnts.forEach( ( cont ) => {
			const cc = cont.children.length;
			for ( let i = 0; i < cc; ++i ) {
				const ce = cont.children[ i ];
				if ( ce.__cid > 0 ) {
					par.cols.push( { idc: ce.__cid, fix: !!ce.__fix } );
				}
			}
		} );
		this._nfySrv( 'columnOrder', par );
	}

	sortTitleColumn( evt, targetColumn ) {
		Warner.traceIf( DO_LOG );
		if ( !Validator.is( targetColumn, "XtwCol" ) ) {
			this.voidMousedownProperties();
			return this.voidMovingProperties( false );
		}
		if ( !this.mousedownAndMouseupHappenedOnSameSpot( evt ) ) {
			this.voidMousedownProperties();
			return this.voidMovingProperties( false );
		}
		const isSortedDescending = targetColumn.toggleSortingState();
		const otherColumnsVoided = this.voidOtherColumnsSortingStates( targetColumn );
		if ( this.ccnDyn instanceof HTMLElement &&
			Validator.is( this.xtwBody, "XtwBody" ) &&
			Validator.isFunction( this.xtwBody.addHorizontalScrollAfterModelData ) ) {
			this.xtwBody.addHorizontalScrollAfterModelData( evt, "onSortColumn-" );
		}
		this.setSortColumn( targetColumn.id, isSortedDescending, true );
		this.voidMousedownProperties();
		this.voidMovingProperties();
		return otherColumnsVoided;
	}

	mousedownAndMouseupHappenedOnSameSpot( mouseUpEvent ) {
		if ( !( mouseUpEvent instanceof MouseEvent ) ||
			!( this.mouseDownEvent instanceof MouseEvent ) ) {
			return false;
		}
		const xAxisDifference =
			Math.abs( this.mouseDownEvent.clientX - mouseUpEvent.clientX );
		return Validator.isValidNumber( xAxisDifference ) && xAxisDifference < 3;
	}

	voidOtherColumnsSortingStates( targetColumn ) {
		const columns = this.columnsArray;
		if ( !Validator.isArray( columns, true ) ) {
			return false;
		}
		for ( let column of columns ) {
			if ( !Validator.is( column, "XtwCol" ) || column === targetColumn ) {
				continue;
			}
			column.voidSortingState();
		}
		return true;
	}

	/**
	 * returns an array of all columns
	 * @param {Boolean} vis if true, then the visible order must be considered
	 * @return {Array | Object} an array of all columns if "vis" is false or an object providing fixed and dynamic columns
	 */
	getColumns( vis ) {
		if ( vis ) {
			return this.ordCol;
		} else {
			// just return the raw collection
			return this.columns.getValues();
		}
	}

	/**
	 * sets the table body widget
	 * @param {XtwBody} xtb the table body widget
	 */
	setTblBody( xtb ) {
		this.xtwBody = xtb;
	}

	/**
	 * sets the sort order
	 * @param {Number} idc column ID
	 * @param {Boolean} desc "descending" flag; false: ascending sort order; true: descending sort order
	 * @param {Boolean} nfy flag whether to notify the web server
	 */
	setSortColumn( idc, desc, nfy ) {
		if ( this.alive && ( typeof idc === 'number' ) ) {
			if ( idc < 0 ) {
				this.voidOtherColumnsSortingStates();
			}
			const cols = this.columns;
			let eff_idc = -1;
			let eff_desc = !!desc;
			if ( ( idc > 0 ) && cols.hasObj( idc ) ) {
				eff_idc = idc;
			} else {
				eff_desc = false;
			}
			this.sortColumn = eff_idc;
			this.sortDirection = eff_desc;
			cols.forEach( ( c ) => {
				if ( !c.select ) {
					c.setSortingState( c.id === eff_idc ? eff_desc : null );
				}
			} );
			if ( nfy && ( eff_idc > 0 ) ) {
				// notify web server
				const par = { idc: eff_idc, direction: eff_desc };
				this._nfySrv( 'sortOrder', par );
			}
		}
	}

	/**
	 * @returns {Number} the ID of the current sorting column
	 */
	getSortColumn() {
		return this.sortColumn;
	}

	/**
	 * @returns {Boolean} the current sorting direction
	 */
	getSortDirection() {
		return this.sortDirection;
	}

	get bodyClientRect() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) || !( "clientRect" in xtwBody ) ) {
			return void 0;
		}
		return xtwBody.clientRect;
	}

	get headClientRect() {
		if ( !this.isRendered ) {
			return void 0;
			// return new DOMRect();
		}
		return this.element.getBoundingClientRect();
	}

	get fixedContainerRect() {
		if ( !( this.ccnFix instanceof HTMLElement ) ) {
			return void 0;
			// return new DOMRect();
		}
		return this.ccnFix.getBoundingClientRect();
	}

	get dynamicContainerRect() {
		if ( !( this.ccnDyn instanceof HTMLElement ) ) {
			return void 0;
			// return new DOMRect();
		}
		return this.ccnDyn.getBoundingClientRect();
	}

	get headClientWidth() {
		const headClientRect = this.headClientRect;
		if ( !( headClientRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( headClientRect.width );
	}

	get headClientHeight() {
		const headClientRect = this.headClientRect;
		if ( !( headClientRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( headClientRect.height );
	}

	set headClientHeight( heightInPixels ) {
		if ( Validator.isObject( this.xtdTbl ) &&
			"headClientHeight" in this.xtdTbl ) {
			this.xtdTbl.headClientHeight = heightInPixels;
		}
	}

	get fixedContainerWidth() {
		const fixedContainerRect = this.fixedContainerRect;
		if ( !( fixedContainerRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( fixedContainerRect.width );
	}

	get dynamicContainerWidth() {
		const dynamicContainerRect = this.dynamicContainerRect;
		if ( !( dynamicContainerRect instanceof DOMRect ) ) {
			return 0;
		}
		return Number( dynamicContainerRect.width );
	}

	get isHorizontalScrollingNecessary() {
		return this.headClientWidth < this.fixedContainerWidth + this.dynamicContainerWidth;
	}

	/**
	 * sets the common text color
	 * @param {Object} args parameter object
	 */
	setTxc( args ) {
		const txc = args.txc || null;
		this.clrTxt = txc;
		this._applyTxc();
	}

	/**
	 * sets the common background color
	 * @param {Object} args parameter object
	 */
	setBgc( args ) {
		if ( !Validator.isObject( args ) || !Validator.isArray( args.bgc ) ) {
			this.setBackgroundColor( null, false );
			return;
		}
		const color = XtwUtils.colorArrayToRgba( args.bgc );
		this.setBackgroundColor( color, true );
	}

	setBackgroundColor( color, validate = true ) {
		if ( !!validate && !Validator.isString( color ) ) {
			return false;
		}
		[ this.ccnFix, this.ccnDyn, this.element ].forEach( element => {
			if ( !( element instanceof HTMLElement ) ) {
				return;
			}
			element.style.setProperty( OWN_BACKGROUND_COLOR, color );
		} );
		return true;
	}

	/**
	 * sets new content of a column
	 * @param {Object} args parameter object providing column ID and new content
	 */
	set_ctt( args ) {
		const idc = this._colID( args.idc );
		const ctt = args.ctt || CellCtt.EMPTY_CONTENT;
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.ctt = ctt;
			col.update();
			this._dropFieldMenu();
			const name = this._getRequestId('columnUpdate');
			if ( !this.renderRqu.hasRequest(name) ) {
				const self = this;
				this.renderRqu.addRequest(name, () => {
					if ( self.ready && self.alive && self.xtwBody ) {
						self.xtwBody.onColumnChanged();
					}
				} );
			}
		}
	}

	/**
	 * sets the content aligment of a column
	 * @param {Object} args parameter object providing column ID and new alignment
	 */
	set_aln( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.setAlignment(args.aln || '');
		}
	}

	/**
	 * sets new width of a column
	 * @param {Object} args parameter object providing column ID and new width
	 */
	set_width( args ) {
		const idc = this._colID( args.idc );
		const wdt = args.width || 0;
		const col = this.columns.getObj( idc );
		if ( col ) {
			const widthSuccessfullySet = col.setWidth( wdt );
			if ( !widthSuccessfullySet ) {
				// TODO log
			}
			// const diff = wdt - col.width;
			// col.width = wdt;
			// col.update();
			// if ( col.visible ) {
			// 	this._renderAllCols();
			// 	if ( this.xtwBody ) {
			// 		// notify the table body instance
			// 		this.xtwBody.onColumnWidth( col, wdt, diff );
			// 	}
			// }
		}
	}

	toggleColumnVisibility( columnId ) {
		const column = this.getColumn( columnId );
		if ( !Validator.isObject( column ) ) {
			return false;
		}
		column.visible = !column.visible;
		column.update();
		return true;
	}

	setColumnVisibility( columnId, setToVisible = true ) {
		const column = this.getColumn( columnId );
		if ( !Validator.isObject( column ) ) {
			return false;
		}
		if ( column.visible === !!setToVisible ) {
			return true;
		}
		column.visible = !!setToVisible;
		column.update();
		return true;
	}

	/**
	 * changes the visibility of a column
	 * @param {Object} args parameter object providing column ID and the visibility flag
	 */
	set_vis( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( !col ) {
			return false;
		}
		const vis = ( args.vis !== undefined ) ? !!args.vis : true;
		if ( vis === col.visible ) {
			return false;
		}
		Warner.traceIf( DO_LOG && vis && !col.available, `A column that is not available` + ` is being set as visible.` );
		const keep_mnu = !!args.keep_mnu;
		if ( !keep_mnu ) {
			this._dropFieldMenu();
		}
		col.visible = vis;
		let widthSetToMinimalRequired = false;
		if ( this.ready && col.width == 0 ) {
			widthSetToMinimalRequired = col.adjustToMinimalRequiredWidth();
		}
		const diff = vis ? col.width : -col.width;
		col.update();
		this._renderAllCols();
		if ( Validator.isFunctionPath( this.xtwBody, "xtwBody.reactToColumnVisibilityChange" ) ) {
			// notify the table body instance
			this.xtwBody.reactToColumnVisibilityChange( col, diff );
		}
		if ( Validator.isObject( this.xtdTbl ) &&
			Validator.isFunction( this.xtdTbl.reactToColumnVisibilityChange ) ) {
			this.xtdTbl.reactToColumnVisibilityChange( col );
		}
		if ( widthSetToMinimalRequired && Validator.isObject( this.xtdTbl ) &&
			Validator.isFunction( this.xtdTbl.syncServerColumnsWidths ) ) {
			this.xtdTbl.syncServerColumnsWidths();
		}
		this._triggerColumnFit();
	}

	/**
	 * changes the "available" state of a column; if it becomes unavailable, the it becomes invisible as well
	 * @param {Object} args parameter object providing column ID and the availability flag
	 */
	set_avl( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			const avl = ( args.avl !== undefined ) ? !!args.avl : true;
			if ( avl !== col.available ) {
				this._dropFieldMenu();
				const vis = col.visible;
				col.available = avl;
				if ( !avl && vis ) {
					const va = { idc: args.idc, vis: false };
					this.set_vis( va );
				} else if ( avl ) {
					// a column that was not available before became available so we have to re-build everything
					this._triggerColumnFit();
					this.xtwBody.rebuildAllRows();
				}
			}
		}
	}

	/**
	 * sets the default data font of a column
	 * @param {Object} args parameter object providing column ID and the data font descriptor
	 */
	set_datfnt( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.dataFont = args.font || null;
		}
	}

	/**
	 * sets the data alignment of a column
	 * @param {Object} args parameter object providing column ID and the data alignment
	 */
	set_dataln( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.dataAlign = args.aln || '';
		}
	}

	/**
	 * sets the default data color of a column
	 * @param {Object} args parameter object providing column ID and the default data
	 */
	set_dattxc( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( !Validator.isObject( col ) ||
			!Validator.isFunction( col.setDataFontColor ) ) {
			return false;
		}
		return col.setDataFontColor( args );
	}

	set_datbgc( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( !Validator.isObject( col ) ||
			!Validator.isFunction( col.setDataBackgroundColor ) ) {
			return false;
		}
		return col.setDataBackgroundColor( args );
	}

	/**
	 * sets the hyperlink flag of a column
	 * @param {Object} args parameter object providing column ID and the hyperlink flag
	 */
	set_link( args ) {
		const idc = this._colID( args.idc );
		const col = this.columns.getObj( idc );
		if ( col && !col.select ) {
			col.link = !!args.link;
		}
	}

	/**
	 * sets new secondary image of a column
	 * @param {Object} args parameter object providing column ID and new secondary image
	 */
	set_sim( args ) {
		const idc = this._colID( args.idc );
		const img = args.sim || null;
		const col = this.columns.getObj( idc );
		if ( col ) {
			col.secImg = img;
			col.update();
		}
	}

	/**
	 * creates a new column
	 * @param {Object} args parameter object providing the column ID of the new column and optionally more parameters
	 */
	addCol( args ) {
		this._dropFieldMenu();
		const idc = this._colID( args.idc );
		if ( !idc ) {
			return;
		}
		const ref = args.ref || 0;
		const col = new XtwCol( this, idc, args );
		col.clrBrd = this.xtdTbl ? this.xtdTbl.clrVtg : null;
		this.columns.addObj( idc, col );
		const cc = col.fix ? this.ordCol.fix : this.ordCol.dyn;
		if ( ref > 0 ) {
			const idx = cc.findIndex( c => c.id === ref );
			if ( idx !== -1 ) {
				// ok, insert it
				cc.splice( idx + 1, 0, col );
			} else {
				// ?!?! - add it anyway
				cc.push( col );
			}
			// we *must* rebuild the column map!!!
			this._rebuildColumnMap();
		} else {
			// just add the column
			cc.push( col );
		}
		const rowTemplateAvailable = this.hasRowTpl;
		const currentViewIsRowTemplate = rowTemplateAvailable && this.isRowTpl;
		if ( this.ready && !currentViewIsRowTemplate ) {
			if ( ref > 0 ) {
				// full refresh
				this._rebuildHeader();
				this.xtwBody.rebuildAllRows();
			} else {
				// just render the column
				this._renderCol( col );
			}
		} else if ( rowTemplateAvailable ) {
			this._regRtpCol( idc, col );
		}
	}

	/**
	 * renders/updates the column headers in the table/excel display/view
	 */
	renderColumns() {
		this._rebuildHeader();
	}

	/**
	 * ensures that a column is visible
	 * @param {Object} args parameter object providing the column ID
	 */
	ensureVisible( args ) {
		const idc = this._colID( args.idc );
		return this.scrollToColumn( this.columns.getObj( idc ) );
	}

	/**
	 * restores a previously stored column order
	 * @param {Object} args parameter object providing an array specifying the column order
	 */
	restoreColumnOrder( args ) {
		if ( !this.ready || this.isRowTpl ) {
			// not yet, please!
			this.rcoArgs = args;
			return;
		}
		const cols = args.cols || [];
		if ( cols.length > 0 ) {
			// ensure that all columns are rendered
			this._renderAllCols();
			// collect all columns in a special map
			const col_map = new Map();
			try {
				this.ordCol.fix = [];
				this.ordCol.dyn = [];
				const ocf = this.ordCol.fix;
				const ocd = this.ordCol.dyn;
				this.columns.forEach( ( c ) => {
					if ( !c.select && c.available ) {
						// store column in the map
						col_map.set( c.id, c )
						if ( c.element ) {
							// and remove it's DOM element temporarily from DOM, if present
							this._psa.rmvDomElm( c.element );
						}
					} else if ( c.select ) {
						ocf.push( c );
					}
				} );
				const dcf = this.ccnFix;
				const dcd = this.ccnDyn;
				cols.forEach( ( ci ) => {
					const col = col_map.get( ci.idc );
					if ( col ) {
						col_map.delete( ci.idc );
						const fix = !!ci.fix;
						const dcont = fix ? dcf : dcd;
						const ocont = fix ? ocf : ocd;
						col.fix = fix;
						if ( col.element ) {
							col.element.__fix = fix;
							dcont.appendChild( col.element );
						}
						ocont.push( col );
					}
				} );
				if ( col_map.size > 0 ) {
					// there are remaining columns
					col_map.forEach( ( col ) => {
						const fix = !!col.fix;
						const dcont = fix ? dcf : dcd;
						const ocont = fix ? ocf : ocd;
						if ( col.element ) {
							col.element.__fix = fix;
							dcont.appendChild( col.element );
						}
						ocont.push( col );
					} );
				}
			} finally {
				col_map.clear();
			}
			this._renderAllCols(); // yes, once again - that will correct the widths of bot column containers etc.
			if ( this.xtwBody ) {
				// the body widget must rebuild all row items
				this.xtwBody.rebuildAllRows();
			}
			// send back effective column order
			this.notifyColumnOrder();
		}
	}

	/**
	 * registers a column for row template processing
	 * @param {Number} id column ID
	 * @param {XtwCol} col the column
	 */
	_regRtpCol( id, col ) {
		if ( ( id > 0 ) && col ) {
			const sid = String( id );
			if ( !this.rtpColumns ) {
				this.rtpColumns = new Map();
			}
			this.rtpColumns.set( sid, col );
		}
	}

	/**
	 * called by the web server if it needs the currently required header width
	 */
	rptHdrWidth() {
		const cols = this.columns.getValues();
		let wdt = 0;
		cols.forEach( ( c ) => {
			wdt += c.getWidth();
		} );
		const par = {}
		par.width = wdt;
		this._nfySrv( 'headerWidth', par );
	}

	_init() {
		const elm = this.element;
		const sc = this.scrCnt;
		const cf = this.ccnFix;
		const cd = this.ccnDyn;

		elm.style.position = 'absolute';
		elm.style.display = 'flex';
		elm.style.flexDirection = 'row';
		elm.style.flexWrap = 'nowrap';
		elm.style.overflow = 'hidden';

		sc.style.height = 'inherit';
		sc.style.overflow = 'hidden';

		cf.style.display = 'flex';
		cf.style.flexDirection = 'row';
		cf.style.flexWrap = 'nowrap';
		cf.style.overflow = 'hidden';
		cf.style.height = 'inherit';

		cd.style.position = 'relative';
		cd.style.display = 'flex';
		cd.style.flexDirection = 'row';
		cd.style.flexWrap = 'nowrap';
		cd.style.overflow = 'hidden';
		cd.style.height = 'inherit';

		this._applyTxc();
	}

	/**
	 * creates a render request ID from a name
	 * @param {String} name the actual request name
	 * @returns the render request ID
	 */
	_getRequestId(name) {
		return this.wdgId + '-' + name;
	}

	/**
	 * ensures that the column ID's type is "number"
	 * @param {*} id column ID
	 * @returns {Number} the effective number ID of type "number"
	 */
	_colID( id ) {
		if ( typeof id !== 'number' ) {
			return 0;
		}
		return Number( id );
	}

	/**
	 * attaches special event handlers
	 */
	_attachEventHandlers() {
		const parentElement = this.parentElement;
		new ExternalEventsTransferManager( this, parentElement );
		if ( parentElement instanceof HTMLElement ) {
			parentElement.classList.add( "xtwhead-container" );
			HtmHelper.removeStyleProperty( parentElement, "height" );
			if ( parentElement.dataset instanceof DOMStringMap ) {
				parentElement.dataset.class = "XtwHead container";
				parentElement.dataset.idstr = this.wdgIdStr;
			}
		}
		this.addListeners();
	}

	/**
	 * (re-)builds the table header rendering all columns and restoring column order
	 */
	_rebuildHeader() {
		this._renderAllCols();
		if ( this.rcoArgs ) {
			const args = this.rcoArgs;
			delete this.rcoArgs;
			this.restoreColumnOrder( args );
		}
		this.addContextMenuListener();
	}

	/**
	 * re-builds the column map according to the current / new column order
	 */
	_rebuildColumnMap() {
		const columns = this.columns;
		columns.clear();
		const ccs = [ this.ordCol.fix, this.ordCol.dyn ];
		ccs.forEach( ( cc ) => {
			cc.forEach( ( col ) => {
				columns.addObj( col.id, col );
			} );
		} );
	}

	/**
	 * renders all columns and updates internal widths
	 */
	_renderAllCols() {
		if ( this.isRowTpl || !this.ready || !this.element ) {
			return;
		}
		let fxw = 0;
		let dnw = 0;
		const self = this;
		const ccs = [ this.ordCol.fix, this.ordCol.dyn ];
		ccs.forEach( ( cc ) => {
			cc.forEach( ( column ) => {
				if ( !Validator.is( column, 'XtwCol' ) || !column.available ) {
					return;
				}
				// we *must* render (or re-add) each available column, regardless of width and/or visibility
				self._renderCol( column );
				if ( column.fix ) {
					fxw += column.getWidth();
				} else {
					dnw += column.getWidth();
				}
			} );
		} );
		const im = ItmMgr.getInst();
		im.setFlexWdt( this.ccnFix, fxw, true );
		XtwUtils.syncZeroWidthClass( this.ccnFix, fxw );
		this.wdtFix = fxw;
		im.setFlexWdt( this.scrCnt, dnw, true );
		XtwUtils.syncZeroWidthClass( this.scrCnt, dnw );
		im.setFlexWdt( this.ccnDyn, dnw, true );
		XtwUtils.syncZeroWidthClass( this.ccnDyn, dnw );
		this.wdtDyn = dnw;
		if ( this.xtdTbl ) {
			this.xtdTbl.setPartWdt( fxw, dnw );
		}
		// tell the server to maybe re-think its decision to hide or show the
		// "scroll widgets" after all visible columns were rendered
		this.rptHdrWidth();
	}

	/**
	 * renders a column
	 * @param {XtwCol} c the column to be rendered
	 */
	_renderCol( c ) {
		if ( c.available && this.element ) {
			const pe = c.fix ? this.ccnFix : this.ccnDyn;
			if ( !c.element ) {
				// create a DIV element for the column itself
				const ce = document.createElement( 'div' );
				c.render( ce );
				pe.appendChild( ce );
			} else {
				// re-add it
				const ce = c.element;
				ce.parentElement.removeChild( ce );
				pe.appendChild( ce );
				XtwUtils.syncZeroWidthClass( ce, c.width );
			}
		}
	}

	/**
	 * applies the common text color
	 */
	_applyTxc() {
		if ( !( this.element instanceof HTMLElement ) ) {
			return;
		}
		let color = XtwUtils.colorArrayToRgba( this.clrTxt );
		if ( !Validator.isString( color ) ) {
			color = null;
		}
		this.element.style.setProperty( OWN_TEXT_COLOR, color );
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv( code, par ) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject( this ).notify( "PSA_XTW_HDR_NFY", param );
		}
	}

	_triggerColumnFit() {
		if ( this.ready && this.renderRqu ) {
			const name = this._getRequestId('columnFit');
			const rr = this.renderRqu;
			if ( !rr.hasRequest(name) ) {
				const self = this;
				rr.addRequest(name, () => {
					self._ensureColumnFit();
				});
			}
		}
	}

	_ensureColumnFit() {
		if ( this.ready && this.xtwBody ) {
			this.xtwBody.ensureAutoFitOnTableColumns();
		}
	}

	handleTransferredEvent( domEvent ) {
		if ( XtwUtils.isArrowUp( domEvent ) || XtwUtils.isArrowDown( domEvent ) ) {
			if ( !Validator.is( this.xtwBody, "XtwBody" ) ||
				!Validator.isFunction( this.xtwBody.handleTransferredEvent ) ) {
				Warner.traceIf( DO_LOG, `The DOM arrow navigation event could not be` +
					` transferred from the table header widget to the table body` +
					` widget, because the table body widget does not have a` +
					` method to handle transferred DOM events.` );
				return false;
			}
			return this.xtwBody.handleTransferredEvent( domEvent )
		}
	}

	changeColumnTooltip( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const column = this.getColumn( parameters.idc );
		if ( !Validator.isObject( column ) ||
			!Validator.isFunction( column.setTooltip ) ) {
			return false;
		}
		return column.setTooltip( parameters.tooltip );
	}

	addSumToColumnTooltip( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const column = this.getColumn( parameters.idc );
		if ( !Validator.isObject( column ) ||
			!Validator.isFunction( column.setTooltip ) ) {
			return false;
		}
		return column.addSumToTooltip( parameters.sum );
	}

	setColumnEditingPen( parameters ) {
		if ( !Validator.isObject( parameters ) ||
			!Validator.isBoolean( parameters.hasEditingPen ) ) {
			return false;
		}
		const column = this.getColumn( parameters.idc );
		if ( !Validator.isObject( column ) || !( "hasEditingPen" in column ) ) {
			return false;
		}
		column.hasEditingPen = parameters.hasEditingPen;
	}

	/** register custom widget type */
	static register() {
		console.log( 'Registering custom widget XtwHead.' );
		rap.registerTypeHandler( 'psawidget.XtwHead', {
			factory: function ( properties ) {
				return new XtwHead( properties );
			},
			destructor: 'destroy',
			properties: [ 'txc', 'bgc', 'scb' ],
			methods: [ 'addCol', 'rptHdrWidth', 'set_ctt', 'set_aln',
				'set_width', 'set_vis', 'set_avl', 'set_datfnt', 'set_dataln', 'set_dattxc', 'set_datbgc',
				'set_link', 'set_sim', 'renderColumns', 'ensureVisible', 'restoreColumnOrder',
				'changeColumnTooltip', 'addSumToColumnTooltip', "setColumnEditingPen"
			],
			events: [ "PSA_XTW_HDR_NFY" ]
		} );
	}
}

console.log( "widgets/xtw/XtwHead.js loaded." );
