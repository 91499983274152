/**
 * common base class
 */
export default class Base {

	/**
	 * constructs a new instance
	 */
	constructor() {
		this._alive = true;
	}

	/**
	 * destructor method
	 */
	destroy() {
		if ( this._alive ) {
			this.doDestroy();
			this._alive = false;
		}
	}

	/**
	 * destructor method - derived classes should override this
	 */
	doDestroy() {
		// do nothing here
	}

	/**
	 * instance's "alive" flag
	 * @returns {Boolean} true if this instance is alive; false otherwise
	 */
	get alive() {
		return !!this._alive;
	}
}

console.log('base/base.js loaded.');