import HtmHelper from '../../../utils/HtmHelper';
import Validator from '../../../utils/Validator';
import XtwRowTemplateExtendedItem from './XtwRowTemplateExtendedItem';

const CHK_CHECKED = '<i class="fp fp-check-1"></i>';
const CHK_UNCHECKED = '<i class="fp fp-check-2"></i>';
const CHK_NULL = '<i class="fp fp-check-3"></i>';

export default class XtwRowTemplateCellContentItem extends XtwRowTemplateExtendedItem {

	create() {
		super.create();
		this.renderBlocked = true;
		this.predefinedGetterElement = "newCellContentItem";
	}

	ruin() {
		super.ruin();
	}

	render() {
		if ( !this.isVisible || this.renderBlocked ) {
			return;
		}
		super.render();
		this.setDescription();
		this.setTitleName();
		this.setIsBlob();
	}

	setDescription() {
		if ( !this.isRendered ||
			!Validator.is( this.element.dataset, "DOMStringMap" ) ) return;
		const columnDescriptor = this.columnDescriptor;
		if ( !Validator.isObject( columnDescriptor ) ||
			!Validator.isString( columnDescriptor.rtpDscName ) ) return;
		this.element.dataset.descriptionName = columnDescriptor.rtpDscName;
	}

	setTitleName() {
		if ( !this.isRendered ||
			!Validator.is( this.element.dataset, "DOMStringMap" ) ) return;
		const parameters = this.parameters;
		if ( !Validator.isObjectPath( parameters, "parameters.rtpTtl" ) ||
			!Validator.isString( parameters.rtpTtl.rtpDscName ) ) return;
		this.element.dataset.titleDescriptionName = parameters.rtpTtl.rtpDscName;
	}

	setIsBlob() {
		if ( !this.isRendered ||
			!Validator.is( this.element.dataset, "DOMStringMap" ) ) return;
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) || !( "isBlob" in parameters ) ) return;
		this.element.dataset.isBlob = parameters.isBlob;
	}

	setContent( innerHtml, formatting ) {
		if ( !this.isRendered ) {
			this.render();
			if ( !this.isRendered ) {
				return;
			}
		}
		this.show();
		// because formatting is not "bound" to the cell descriptor and is
		// content-dependent, we should remove the reminescing format from the
		// previous content insertion before we add the "default" format from the
		// cell descriptor & the specific formatting that comes with this content
		this.removeFormatting();
		this.element.innerHTML = '';
		const cell = this;
		// check type	
		let content = innerHtml;	
		let logic = false;
		if ( Validator.isObjectPath(cell, 'cell._creationParameters.parameters.columnObject') ) {
			const column = cell._creationParameters.parameters.columnObject;
			logic = column.type === 3;
		}
		if ( logic ) {
			if ( Validator.isString(content) ) {
				// "true" or "false"
				content = (Validator.isTrue(content) ? CHK_CHECKED : CHK_UNCHECKED);
			} else {
				// "null"
				content = CHK_NULL;
			}
		}
		// look for a title
		let title = null;
		if ( Validator.isString( content ) ) {
			if ( Validator.isObjectPath( cell, 'cell._creationParameters.parameters.rtpTtl' ) ) {
				if ( cell._creationParameters.parameters.rtpTtl.rtpDscVis ) {
					// title element is visible
					title = cell._creationParameters.parameters.rtpTtl.rtpDscTitle || '';
				}
			}
		}
		if ( Validator.isString( title ) ) {
			const span_ttl = document.createElement( 'span' );
			const span_ctt = document.createElement( 'span' );
			const ce = this.element;
			span_ttl.className = 'xtwrtptitle';
			span_ttl.innerText = title + ':';
			span_ctt.innerHTML = content;
			ce.appendChild( span_ttl );
			ce.appendChild( span_ctt );
		} else {
			this.element.innerHTML = content || '';
		}
		if ( this.removeIfEmpty() ) {
			return;
		}
		this.beautify( formatting );
		this.addListeners();
		this.placeAccordingToPosition();
	}

	removeIfEmpty() {
		if ( !this.isRendered ) {
			return true;
		}
		if ( Validator.isString( this.element.innerHTML ) ) {
			// is not empty so we can't delete it
			return false;
		}
		this.removeSelf();
		return true;
	}

	removeOwnElement() {
		if ( this.isRendered ) {
			this.removeClickListener();
			this.removeContextMenuListener();
			this.removeTooltipListeners();
		}
		super.removeOwnElement();
	}

	removeFormatting() {
		if ( !this.isRendered ) return false;
		const elm = this.element;
		const css = elm.style;
		[ "fontFamily", "fontSize", "fontStyle", "fontWeight", "textDecoration",
			"color", "backgroundColor", "flexGrow"
		].forEach( property => {
			css[ property ] = '';
		} );
		return true;
	}

	addFormatting( formatting ) {
		if ( !this.isRendered ) {
			return false;
		}
		if ( !Validator.isObject( formatting ) ) {
			return false;
		}
		this.setElementFont( formatting.font || null );
		this.setElementColor( formatting.txc || null, false );
		this.setElementColor( formatting.bgc || null, true );
		return true;
	}

	addListeners() {
		if ( !this.isRendered ) {
			return false;
		}
		this.addClickListener();
		this.addContextMenuListener();
		this.addTooltipListeners();
		return true;
	}

	setImageTagSize() {
		if ( !this.isRendered ) {
			return false;
		}
		let imageChildren = this.element.getElementsByTagName( "img" );
		if ( !Validator.isIterable( imageChildren ) ||
			imageChildren.length < 1 ) {
			return false;
		}
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) || !( "isBlob" in parameters ) ||
			!parameters.isBlob ) {
			return false;
		}
		let horizontalPadding = this._getSummarizedHorizontalPadding();
		let verticalPadding = this._getSummarizedVericalPadding();
		imageChildren = [ ...imageChildren ];
		const height = Validator.isObject( parameters.rtpCol ) &&
			Validator.isPositiveNumber( parameters.rtpCol.rtpDscHeight ) ?
			parameters.rtpCol.rtpDscHeight - verticalPadding : void 0;
		const width = Validator.isPositiveNumber( parameters.width ) ?
			parameters.width - horizontalPadding : void 0;
		const portionalWith = Validator.isPositiveNumber( width ) ?
			width / imageChildren.length : void 0;
		const heightIsValid = Validator.isPositiveNumber( height, false );
		const widthIsValid = Validator.isPositiveNumber( portionalWith, false );
		if ( !heightIsValid && !widthIsValid ) {
			return false;
		}
		for ( let imageElement of imageChildren ) {
			if ( heightIsValid ) {
				imageElement.style.maxHeight = `${ height }px`;
			}
			if ( widthIsValid ) {
				imageElement.style.maxWidth = `${ portionalWith }px`;
			}
		}
		return true;
	}

	_getSummarizedHorizontalPadding() {
		const parameters = this.parameters;
		if ( !Validator.isObjectPath( parameters, "parameters.rtpCol.rtpDscPadding" ) ) {
			return 0;
		}
		let horizontalPadding = 0;
		const descriptorPadding = parameters.rtpCol.rtpDscPadding;
		if ( Validator.isPositiveNumber( descriptorPadding.left, false ) ) {
			horizontalPadding += descriptorPadding.left;
		}
		if ( Validator.isPositiveNumber( descriptorPadding.right, false ) ) {
			horizontalPadding += descriptorPadding.right;
		}
		return horizontalPadding;
	}

	_getSummarizedVericalPadding() {
		const parameters = this.parameters;
		if ( !Validator.isObjectPath( parameters, "parameters.rtpCol.rtpDscPadding" ) ) {
			return 0;
		}
		let verticalPadding = 0;
		const descriptorPadding = parameters.rtpCol.rtpDscPadding;
		if ( Validator.isPositiveNumber( descriptorPadding.top, false ) ) {
			verticalPadding += descriptorPadding.top;
		}
		if ( Validator.isPositiveNumber( descriptorPadding.bottom, false ) ) {
			verticalPadding += descriptorPadding.bottom;
		}
		return verticalPadding;
	}

	setIconSymbolFontSize() {
		if ( !this.isRendered ) return false;
		let italicTagsChildren = this.element.getElementsByTagName( "i" );
		if ( !Validator.isIterable( italicTagsChildren ) ||
			italicTagsChildren.length < 1 ) return false;
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) || !( "isBlob" in parameters ) ||
			!parameters.isBlob ) return false;
		let horizontalPadding = this._getSummarizedHorizontalPadding();
		let verticalPadding = this._getSummarizedVericalPadding();
		italicTagsChildren = [ ...italicTagsChildren ];
		const height = Validator.isObject( parameters.rtpCol ) &&
			Validator.isPositiveNumber( parameters.rtpCol.rtpDscHeight ) ?
			parameters.rtpCol.rtpDscHeight - verticalPadding : void 0;
		const width = !parameters.fixedWidth ||
			!Validator.isPositiveNumber( parameters.width ) ?
			void 0 : parameters.width - horizontalPadding;
		let size = !height ? width : !width ? height : Math.min( width, height );
		if ( !size ) return false;
		size = HtmHelper.getIconFontSize( size );
		for ( let italicTag of italicTagsChildren ) {
			italicTag.style.fontSize = `${ size }px`;
		}
		return true;
	}

	discardMinimalWidth() {
		const camelCaseRemoved = HtmHelper
			.removeStyleProperty( this.element, "minWidth" );
		const cssNotationRemoved = HtmHelper
			.removeStyleProperty( this.element, "min-width" );
		return camelCaseRemoved && cssNotationRemoved;
	}

	setBlobMinimalWidth() {
		// TODO should this be done for images (img tags) aswell? as of now, it is
		// only done for icons after italic (i) tags
		return this.setIconSymbolMinimalWidth();
	}

	setIconSymbolMinimalWidth() {
		if ( !this.isRendered ) return false;
		const allChildren = HtmHelper.getAllLevelChildren( this.element );
		if ( allChildren.length <= 0 ||
			!allChildren.every( child => child.tagName === "I" ) ) {
			return false;
		}
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) || !( "isBlob" in parameters ) ||
			!parameters.isBlob || parameters.fixedWidth ||
			!Validator.isPositiveNumber( parameters.width, false ) ) {
			return false;
		}
		this.element.style.minWidth = `${ parameters.width }px`;
		return true;
	}

	placeAccordingToPosition() {
		if ( !this.isRendered ) return false;
		const parentElement = this.parentObjectElement;
		if ( !( parentElement instanceof HTMLElement ) ) return false;
		let siblings = [ ...parentElement.children ].filter( sibling =>
			sibling instanceof HTMLElement && sibling !== this.element &&
			Validator.is( sibling.dataset, "DOMStringMap" ) &&
			Validator.isString( sibling.dataset.position ) );
		if ( !Validator.isArray( siblings, true ) ) return true;
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) || !( "position" in parameters ) )
			return false;
		const position = Number( parameters.position );
		if ( !Validator.isValidNumber( position ) ) return false;
		siblings = siblings.reverse();
		for ( let sibling of siblings ) {
			const siblingPosition = Number( sibling.dataset.position );
			if ( !Validator.isValidNumber( siblingPosition ) ) continue;
			if ( siblingPosition < position ) break;
			parentElement.insertBefore( this.element, sibling );
		}
		return true;
	}

	beautify( formatting ) {
		if ( !this.isRendered ) {
			return;
		}
		this.discardMinimalWidth();
		this.setElementFont( null );
		this.setElementColor( null, false );
		this.setElementColor( null, true );
		// this.setFontColor();
		// this.setBackgroundColor();
		this.setElementAlignment();
		this.setLineHeight();
		this.setPadding();
		this.setHyperlink();
		this.setElementAdditionalStyle();
		this.setOverflow();
		this.setFlexGrow();
		this.addFormatting( formatting );
		this.setImageTagSize();
		this.setIconSymbolFontSize();
		this.setBlobMinimalWidth();
	}

	onClick( evt, parameters = void 0 ) {
		const creationParameters = this.parameters;
		if ( !Validator.isObject( creationParameters ) ||
			creationParameters.isLink !== true ) return;
		if ( !Validator.isObject( parameters ) ) parameters = {};
		parameters.idc = Number( this.itemId );
		if ( !Validator.isString( parameters.fromItem ) )
			parameters.fromItem = Validator.getClassName( this );
		super.onClick( evt, parameters );
	}

	onContextMenu( evt, parameters = void 0 ) {
		parameters = this.getFullParameters( evt, parameters );
		if ( !Validator.isObject( parameters ) ) return;
		super.onContextMenu( evt, parameters );
	}

	onTooltipTimer() {
		if ( !this.isRendered ) {
			return this.clearTooltipTimer();
			// return super.onTooltipTimer();
		}
		const parameters = this.getFullParameters( this.lastTooltipEvent );
		if ( !Validator.isObject( parameters ) ) {
			return this.clearTooltipTimer();
			// return super.onTooltipTimer();
		}
		this._nfySrv( "tooltipRequest", parameters );
		this.clearTooltipTimer();
		// super.onTooltipTimer();
	}

	getFullParameters( evt, parameters ) {
		if ( !this.isRendered ) {
			return parameters;
		}
		parameters = this.getCoordinateParameters( evt, this.element, parameters );
		let rowParent = this;
		while ( Validator.isObject( rowParent.parentObject ) && !rowParent.isRow ) {
			rowParent = rowParent.parentObject;
		}
		if ( !rowParent.isRow ) {
			return parameters;
		}
		let cellId = Number( this.itemId );
		if ( !Validator.isPositiveInteger( cellId ) &&
			Validator.isObject( this.parentObject ) ) {
			cellId = Number( this.parentObject.itemId );
		}
		if ( !Validator.isPositiveInteger( cellId ) ) {
			return parameters;
		}
		Object.assign( parameters, {
			idc: cellId,
			idr: Number( rowParent.itemId ),
			groupId: this.parameters.groupId,
			internalGroupNameId: this.parameters.internalGroupNameId
		} );
		return parameters;
	}

}
