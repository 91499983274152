/**
 * generic object that can hold the DOM focus
 */
export default class FocusHolder {

    /**
     * constructs a new instance
     */
    constructor() {
        this._locked = 0;
    }

    /**
     * @returns {Boolean} true if the focus is locked by this element; false otherwise
     */
    get locked() {
        return this._locked > 0;
    }

    /**
     * locks the focus by this element
     */
    lock() {
        ++this._locked;
    }

    /**
     * releases the focus by this element
     */
    release() {
        --this._locked;
        if ( this._locked < 0 ) {
            throw new Error('Mismatch in lock() / release() calls!');
        }
    }

    /**
     * sets the focus to this element
     * @abstract
     */
    setFocus() { /* abstract */ }

    /**
     * notifies this instance about forced blur
     * @abstract
     */
     forceBlur() { /* abstract */ }
}