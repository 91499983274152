import AttachmentObject from '../../../../utils/AttachmentObject';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Warner from '../../../../utils/Warner';

const DO_LOG = true;

/** minimal scrollbar's thumb size */
const MIN_THUMB_SIZE = 17;

export default class XtwTblScrollingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	setupVerticalScrollbar() {
		if ( !Validator.isString( this.idwSlv ) ) {
			return false;
		}
		const verticalScrollbar = rwt.remote.ObjectRegistry.getObject( this.idwSlv );
		if ( !Validator.isObject( verticalScrollbar ) ) {
			return false;
		}
		this.wdgSlv = verticalScrollbar;
		this.setupVerticalScrollbarMouseUp( verticalScrollbar );
		verticalScrollbar.addEventListener( 'selectionChanged', this._onVScroll, this );
		if ( verticalScrollbar._element instanceof HTMLElement ) {
			verticalScrollbar._element.classList.add( "xtw-scrollbar", "vertical" );
			[ "top", "height" ].forEach( styleProperty => {
				HtmHelper.removeStyleProperty( verticalScrollbar._element, styleProperty );
			} );
		}
		if ( this._psa.isDbgMode() &&
			Validator.hasDataset( verticalScrollbar._element ) ) {
			verticalScrollbar._element.dataset.class = "vertical scrollbar"
		}
		// enforce minimal thumb size
		verticalScrollbar.setMinThumbSize(this.minimalThumbSize);
		return true;
	}

	setupVerticalScrollbarMouseUp( verticalScrollbar ) {
		this._setupVerticalScrollbarMouseUp( verticalScrollbar );
		this._setupVerticalScrollbarThumbMouseUp( verticalScrollbar );
		this._setupVerticalScrollbarMouseUpThumb( verticalScrollbar );
		delete this.setupVerticalScrollbarMouseUp;
	}

	_setupVerticalScrollbarMouseUp( verticalScrollbar ) {
		const isValidScrollBarObject = ( scrollBarObject ) => {
			return Validator.isFunctionPath( scrollBarObject, "scrollBarObject._onMouseUp" );
		};
		if ( !isValidScrollBarObject( verticalScrollbar ) ) {
			verticalScrollbar = this.wdgSlv;
			if ( !isValidScrollBarObject( verticalScrollbar ) ) {
				return false;
			}
		}
		const self = this;
		const onMouseUp = ( event ) => {
			if ( Validator.isFunctionPath( self,
					"self.onVerticalScrollbarMouseUp" ) &&
				self.onVerticalScrollbarMouseUp( event ) ) return;
			if ( Validator.isFunctionPath( verticalScrollbar,
					"verticalScrollbar._onOriginalThumbMouseUp" ) )
				verticalScrollbar._onOriginalMouseUp( event );
		};
		verticalScrollbar._onOriginalMouseUp = verticalScrollbar._onMouseUp;
		verticalScrollbar.removeEventListener( "mouseup",
			verticalScrollbar._onMouseUp, verticalScrollbar );
		verticalScrollbar.addEventListener( "mouseup", onMouseUp, verticalScrollbar );
		delete this._setupVerticalScrollbarMouseUp;
		return true;
	}

	_setupVerticalScrollbarThumbMouseUp( verticalScrollbar ) {
		const isValidScrollBarObject = ( scrollBarObject ) => {
			return Validator.isObjectPath( scrollBarObject, "scrollBarObject._thumb" ) &&
				Validator.isFunction( scrollBarObject._onThumbMouseUp );
		};
		if ( !isValidScrollBarObject( verticalScrollbar ) ) {
			verticalScrollbar = this.wdgSlv;
			if ( !isValidScrollBarObject( verticalScrollbar ) ) {
				return false;
			}
		}
		const self = this;
		const onThumbMouseUp = ( event ) => {
			if ( Validator.isFunctionPath( self,
					"self.onVerticalScrollbarThumbMouseUp" ) &&
				self.onVerticalScrollbarThumbMouseUp( event ) ) return;
			if ( Validator.isFunctionPath( verticalScrollbar,
					"verticalScrollbar._onOriginalThumbMouseUp" ) )
				verticalScrollbar._onOriginalThumbMouseUp( event );
		};
		verticalScrollbar._onOriginalThumbMouseUp = verticalScrollbar._onThumbMouseUp;
		verticalScrollbar._thumb.removeEventListener( "mouseup",
			verticalScrollbar._onThumbMouseUp, verticalScrollbar );
		verticalScrollbar._thumb.addEventListener( "mouseup", onThumbMouseUp, verticalScrollbar );
		delete this._setupVerticalScrollbarThumbMouseUp;
		return true;
	}

	_setupVerticalScrollbarMouseUpThumb( verticalScrollbar ) {
		const isValidScrollBarObject = ( scrollBarObject ) => {
			return Validator.isFunctionPath( scrollBarObject, "scrollBarObject._thumb._onMouseUp" );
		};
		if ( !isValidScrollBarObject( verticalScrollbar ) ) {
			verticalScrollbar = this.wdgSlv;
			if ( !isValidScrollBarObject( verticalScrollbar ) ) {
				return false;
			}
		}
		const self = this;
		const onMouseUpThumb = ( event ) => {
			if ( Validator.isFunctionPath( self,
					"self.onVerticalScrollbarThumbMouseUp" ) &&
				self.onVerticalScrollbarMouseUpThumb( event ) ) return;
			if ( Validator.isFunctionPath( verticalScrollbar,
					"verticalScrollbar._thumb._onOriginalMouseUp" ) )
				verticalScrollbar._thumb._onOriginalMouseUp( event );
		};
		verticalScrollbar._thumb._onOriginalMouseUp = verticalScrollbar._thumb._onMouseUp;
		verticalScrollbar._thumb.removeEventListener( "mouseup",
			verticalScrollbar._thumb._onMouseUp, verticalScrollbar._thumb );
		verticalScrollbar._thumb.addEventListener( "mouseup", onMouseUpThumb, verticalScrollbar._thumb );
		delete this._setupVerticalScrollbarMouseUpThumb;
		return true;
	}

	onVerticalScrollbarMouseUpThumb( event ) {
		return this.onVerticalScrollbarMouseUp( event );
	}

	onVerticalScrollbarThumbMouseUp( event ) {
		return this.onVerticalScrollbarMouseUp( event );
	}

	onVerticalScrollbarMouseUp( event ) {
		if ( !this.isVerticalScrollbarRedundant ) {
			return false;
		}
		if ( Validator.isObject( event ) ) {
			DomEventHelper.stopIf( event._valueDomEvent );
		}
		Warner.traceIf( DO_LOG );
		return true;
	}

	setupHorizontalScrollbar() {
		if ( !Validator.isString( this.idwSlh ) ) {
			return false;
		}
		const horizontalScrollbar = rwt.remote.ObjectRegistry.getObject( this.idwSlh );
		if ( !Validator.isObject( horizontalScrollbar ) ) {
			return false;
		}
		this.wdgSlh = horizontalScrollbar;
		horizontalScrollbar.addEventListener( 'selectionChanged', this._onHScroll, this );
		if ( horizontalScrollbar._element instanceof HTMLElement ) {
			horizontalScrollbar._element.classList.add( "xtw-scrollbar", "horizontal" );
		}
		if ( this._psa.isDbgMode() &&
			Validator.hasDataset( horizontalScrollbar._element ) ) {
			horizontalScrollbar._element.dataset.class = "horizontal scrollbar"
		}
		// enforce a minimal thumb size
		horizontalScrollbar.setMinThumbSize(this.minimalThumbSize);
		return true;
	}


	/**
	 * provides the minimal scrollbar's thumb size
	 * @returns {Number} the minimal scrollbar's thumb size
	 */
	get minimalThumbSize() {
		// we use the same property for both horizontal and vertical scrollbars
		return Math.max(Validator.isNumber(this.verticalSliderOriginalWidth) ? this.verticalSliderOriginalWidth : MIN_THUMB_SIZE, MIN_THUMB_SIZE);
	}

	_onVScroll() {
		if ( this.wdgSlv && this.wdgBody ) {
			// the body scrolls vertically, nobody else
			this.wdgBody.onVScroll( this.wdgSlv._selection );
		}
		this.adjustVerticalScrollbarBasedOnHeight();
	}

	get isVerticalScrollbarRedundant() {
		if ( !Validator.isObject( this.wdgSlv ) ||
			!( this.wdgSlv._element instanceof HTMLElement ) ||
			!Validator.isIterable( this.wdgSlv._element.children ) ) {
			return false;
		}
		const verticalScrollbarRect = this.wdgSlv._element.getBoundingClientRect();
		if ( !( verticalScrollbarRect instanceof DOMRect ) ||
			!Validator.isPositiveNumber( verticalScrollbarRect.height ) ) {
			return false;
		}
		let totalChildHeight = 0;
		for ( let element of [ ...this.wdgSlv._element.children ] ) {
			if ( !( element instanceof HTMLElement ) ) {
				continue;
			}
			const elementRect = element.getBoundingClientRect();
			if ( !( elementRect instanceof DOMRect ) ||
				!Validator.isPositiveNumber( elementRect.height ) ) {
				continue;
			}
			totalChildHeight += elementRect.height;
		}
		const heightDifference = Math.abs(
			Math.trunc( verticalScrollbarRect.height - totalChildHeight ) );
		return Validator.isValidNumber( heightDifference ) &&
			heightDifference < 4;
	}

	adjustVerticalScrollbarBasedOnHeight() {
		if ( !this.isVerticalScrollbarRedundant ) {
			return false;
		}
		this._nfySrv( "scrollbarVisibility", {
			vertical: true,
			visible: false
		} );
		Warner.traceIf( DO_LOG, `The vertical scrollbar is obsolete and should be hidden.` );
		return true;
	}

	_onHScroll() {
		if ( this.wdgSlh && this.wdgBody && this.wdgHead ) {
			if ( !this.isRowTpl &&
				"isHorizontalScrollingNecessary" in this.wdgHead &&
				!this.wdgHead.isHorizontalScrollingNecessary ) {
				// ONLY in the table/excel display/view and
				// NOT in the row template display/view
				if ( !this.horizontalScrollWidgetShown ) {
					// there is no horizontal scroll widget element so we're (hopefully)
					// fine
					return;
				}
				if ( Validator.isFunction( this.wdgHead.rptHdrWidth ) ) {
					// suggest to the server-side table widget that maybe we don't need
					// a horizontal scroll widget
					this.wdgHead.rptHdrWidth();
				}
				return Warner.traceIf( true, `Horizontal scrolling is not necessary` +
					` due to the table header element being wide enough to fit both` +
					` the fixed and dynamic containers for column header cells. The` +
					` client width of the table header is greater than or equal to` +
					` the sum of both containers' widths. If the horizontal scrolling` +
					` widget is still present, it is strongly recommended to remove it.` );
			};
			// header and body scroll horizontally - the "dynamic" part
			const pos = this.wdgSlh._selection;
			console.log( "Scrolled horizontally: " + pos );
			this.wdgHead.onHScroll( pos );
			this.wdgBody.onHScroll( pos );
		}
	}

	get horizontalScrollWidgetShown() {
		if ( !Validator.isObject( this.wdgSlh ) ||
			!( this.wdgSlh._element instanceof HTMLElement ) ) {
			return false;
		}
		const scrollWidgetElement = this.wdgSlh._element;
		if ( scrollWidgetElement.style.display === "none" ) {
			return false;
		}
		if ( scrollWidgetElement.style.height === "0px" ) {
			return false;
		}
		return true;
	}

	onScrollbarVisibilityChange( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		return Validator.false( parameters.vertical ) ?
			this.onHorizontalScrollbarVisibilityChange( parameters.visible ) :
			Validator.true( parameters.vertical ) ?
			this.onVerticalScrollbarVisibilityChange( parameters.visible ) : false;
	}

	onVerticalScrollbarVisibilityChange( visible ) {
		return Validator.false( visible ) ? this.onVerticalScrollbarHidden() :
			Validator.true( visible ) ? this.onVerticalScrollbarShown() : false;
	}

	onVerticalScrollbarShown() {
		let headScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgHead, "wdgHead.onVerticalScrollbarShown" ) ) {
			headScrollResult = this.wdgHead.onVerticalScrollbarShown();
		}
		let bodyScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgBody, "wdgBody.onVerticalScrollbarShown" ) ) {
			bodyScrollResult = this.wdgBody.onVerticalScrollbarShown();
		}
		return headScrollResult && bodyScrollResult;
	}

	onVerticalScrollbarHidden() {
		let headScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgHead, "wdgHead.onVerticalScrollbarHidden" ) ) {
			headScrollResult = this.wdgHead.onVerticalScrollbarHidden();
		}
		let bodyScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgBody, "wdgBody.onVerticalScrollbarHidden" ) ) {
			bodyScrollResult = this.wdgBody.onVerticalScrollbarHidden();
		}
		return headScrollResult && bodyScrollResult;
	}

	onHorizontalScrollbarVisibilityChange( visible ) {
		return Validator.false( visible ) ? this.onHorizontalScrollbarHidden() :
			Validator.true( visible ) ? this.onHorizontalScrollbarShown() : false;
	}

	onHorizontalScrollbarShown() {
		let headScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgHead, "wdgHead.onHorizontalScrollbarShown" ) ) {
			headScrollResult = this.wdgHead.onHorizontalScrollbarShown();
		}
		let bodyScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgBody, "wdgBody.onHorizontalScrollbarShown" ) ) {
			bodyScrollResult = this.wdgBody.onHorizontalScrollbarShown();
		}
		return headScrollResult && bodyScrollResult;
	}

	onHorizontalScrollbarHidden() {
		let headScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgHead, "wdgHead.onHorizontalScrollbarHidden" ) ) {
			headScrollResult = this.wdgHead.onHorizontalScrollbarHidden();
		}
		let bodyScrollResult = true;
		if ( Validator.isFunctionPath( this.wdgBody, "wdgBody.onHorizontalScrollbarHidden" ) ) {
			bodyScrollResult = this.wdgBody.onHorizontalScrollbarHidden();
		}
		return headScrollResult && bodyScrollResult;
	}

}
