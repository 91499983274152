import TimMgr from './TimMgr';

/**
 * timer object class
 */
export default class TimObj {

	/**
	 * constructs a new instance
	 * @param {TimMgr} mgr timer manager
	 * @param {String} nam timer name
	 * @param {Number} tmo timeout time
	 * @param {Boolean} sng "single shot" flag
	 * @param {Boolean} bsc block screen flag
	 */
	constructor(mgr, nam, tmo, sng, bsc) {
		this.timMgr = mgr;
		this.timNam = nam;
		this.bsyScr = bsc;
		const self = this;
		const cbk = function() {
			const tmm = self.timMgr;
			tmm._nfySrv(self);
			if ( self.timSng ) {
				self.timHdl = null;
				const obj = {};
				obj.nam = self.timNam;
				tmm._killTimer(obj);
			}
		};
		if ( sng ) {
			this.timSng = true;
			this.timHdl = setTimeout(cbk, tmo);
		} else {
			this.timSng = false;
			this.timHdl = setInterval(cbk, tmo);
		}
	}

	/**
	 * destroys a timer instance
	 */
	destroy() {
		if ( this.timHdl ) {
			const hdl = this.timHdl;
			this.timHdl = null;
			if ( this.timSng ) {
				clearTimeout(hdl);
			} else {
				clearInterval(hdl);
			}
		}
		delete this.timHdl;
		delete this.timSng;
		delete this.bsyScr;
		delete this.timNam;
		delete this.timMgr;
	}
}

console.log('ssn/TimObj.js loaded');