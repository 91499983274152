import PSA from '../../psa';
import ObjReg from '../../utils/ObjReg';
import JsPoint from '../../utils/JsPoint';
import BtnBarMgr from './BtnBarMgr';
import PsaBtn2 from './PsaBtn2';

const TMO_OFF =  500;
const TMO_HIT = 1250;

/**
 * the class PsaBtnBar implements the client side of PiSA sales button manager class
 */
export default class PsaBtnBar {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// bind RAP related methods
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		// initialize the widget
		this.ready = false;
		const idw = properties.parent;
		this.wdgId = idw;
		this.btnReg = new ObjReg();
		this.hotBtn = null;
		this.hitAct = false;
		this.tmhHit = null;
		this.tmhOff = null;
		this.curPos = new JsPoint(0, 0);
		this.parent = rap.getObject(idw);
		// hook in global mouse listener
		this.mmvLsr = this._psa.bind(this, this._onMouseMove);
		document.body.addEventListener('mousemove', this.mmvLsr);
		// bind timer functions
		this.onTimHit = this._psa.bind(this, this._onTimHit);
		this.onTimOff = this._psa.bind(this, this._onTimOff);
		// hook-in resize listener
		this.parent.addListener("Resize", this.layout);
		// activate "render" event
		rap.on("render", this.onRender);
		// add us to the bar manager
		BtnBarMgr.getInst().addBar(this);
		// set internal "Ok" flag
		this._ok = true;
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		document.body.removeEventListener('mousemove', this.mmvLsr);
		this._ok = false;
		this.ready = false;
		this._clrTim();
		BtnBarMgr.getInst().rmvBar(this);
		delete this.curPos;
		delete this.mmvLsr;
		delete this.onTimHit;
		delete this.onTimOff;
		delete this.hitAct;
		delete this.tmhHit;
		delete this.tmhOff;
		delete this.hotBtn;
		this.btnReg.destroy();
		delete this.btnReg;
	}

	/**
	 * returns the widget ID
	 * @returns {String} the widget ID
	 */
	getIdw() {
		return this.wdgId;
	}

	/**
	 * adds a button to this button manager
	 * @param {PsaBtn2} btn the button to be added to this button manager
	 */
	addBtn(btn) {
		this.btnReg.addObj(btn.getIdw(), btn);
	}

	/**
	 * removes a button from this button manager
	 * @param {PsaBtn2} btn the button to be removed from this button manager
	 */
	rmvBtn(btn) {
		if ( this.btnReg ) {
			this.btnReg.rmvObj(btn.getIdw());
		}
	}

	/**
	 * retrieves a button
	 * @param {String} idw button's widget ID
	 * @returns {PsaBtn2} the button or null if not found
	 */
	getBtn(idw) {
		return this.btnReg.getObj(idw);
	}

	/**
	 * changes the "hot" state of a button
	 * @param {PsaBtn2} btn the affected button
	 * @param {Boolean} hot new "hot" state of the specified button
	 * @param {Boolean} imm "immediately" flag
	 */
	setHotBtn(btn, hot, imm) {
		if ( this.getBtn(btn.getIdw()) ) {
			// it is really one of our buttons :-)
			const old = this.hotBtn;
			const pop = old ? old.isPop() : false;
			const hit = this.hitAct || pop || btn.isPop();
			if ( (btn !== old) || (hot && !this.hitAct) ) {
				if ( hot ) {
					this._clrTim();
					this.hotBtn = btn;
					if ( hit || imm ) {
						// we have a "hit" state!
						this.hitAct = true;
						this._hitBtn(this.hotBtn, true);
					} else {
						// we do nothing due to the "click" requirement
					}
					if ( old && old.hasPopMnu() ) {
						old.clsMnu();
					}
				}
			} else {
				if ( !hot ) {
					if ( !btn.isPop() ) {
						if ( imm ) {
							// immediately off
							this._onTimOff();
						} else {
							// trigger "off"
							this.tmhOff = setTimeout(this.onTimOff, TMO_OFF);
						}
					}
				}
			}
		} else {
			console.warn("Unknown button: " + btn.toString());
		}
	}

	/**
	 * called by a button if it is clicked
	 * @param {PsaBtn2} btn the affected button
	 */
	onBtnClk(btn) {
		if ( this.getBtn(btn.getIdw()) ) {
			// it is really one of our buttons :-)
			// stop everything...
			this._clrTim();
			// ... but set/restore the "hot" button - a clicked button is always hot :-)
			this.hitAct = true;
			this.hotBtn = btn;
		}
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
	}

	/**
	 * called by the framework in rendering phase
	 */
	onRender() {
		rap.off("render", this.onRender);			// just once!
		this.onReady();
	}
	
	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		if ( this.ready ) {
			// we do *nothing* here!
		}
	}

	/**
	 * dummy so far
	 */
	onSend() {
		// do nothing so far...
	}

	/**
	 * clears all timers
	 */
	_clrTim() {
		this.hitAct = false;
		if ( this.tmhHit ) {
			let tmh = this.tmhHit;
			this.tmhHit = null;
			clearTimeout(tmh);
		}
		if ( this.tmhOff ) {
			let tmh = this.tmhOff;
			this.tmhOff = null;
			clearTimeout(tmh);
		}
	}

	_onMouseMove(evt) {
		this.curPos.x = evt.clientX;
		this.curPos.y = evt.clientY;
	}

	_onTimHit() {
		if ( this._ok && this.ready ) {
			this._clrTim();
			if ( this.hotBtn ) {
				this.hitAct = true;
				this._hitBtn(this.hotBtn, true);
			}
		}
	}

	_onTimOff() {
		if ( this._ok && this.ready ) {
			const btn = this.hotBtn;
			const hit = this.hitAct;
			this._clrTim();
			this.hotBtn = null;
			if ( btn && hit ) {
				this._hitBtn(btn, false);
				if ( btn.isPop() ) {
					btn.clsMnu();
				}
			}
		}
	}

	_hitBtn(btn, hit) {
		btn.onHit(hit, this.curPos);
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget PsaBtnBar.');
		/** register custom widget type */
		rap.registerTypeHandler("psawidget.PsaBtnBar", {
			factory : function(properties) {
				return new PsaBtnBar(properties);
			},
			destructor: "destroy",
			properties: [ ],
			methods: [ ],
			events: [ "PSA_PBB_NFY" ]
		});
	}
}
	
console.log('widgets/psatbn/PsaBtnBar.js loaded');