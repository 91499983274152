import PSA from '../../../../psa';
import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import XtwMnuItm from '../../parts/sort/XtwMnuItm';
import SearchableMenuObject from './SearchableMenuObject';

/** field menu ID */
let ID_FIELD_MENU = 100000000;
const MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY = 5000;		// a pretty large limit :-)

export default class XtwBodyContextMenuExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	/**
	 * creates a field menu
	 * @param {Boolean} useRowTemplate flag whether to check Row Template definition
	 * @param {Function} filter optional callback that can filter out items
	 * @param {Function} createMenuItem optional callback function to create a menu item descriptor
	 * @param {Function} doAfterCreatingMenuItems optional callback function that's called after all menu item descriptors have beed created
	 * @param {Boolean} useDescription flag whether to use the column descriptor instead of the column title
	 * @returns {MnuObj} the created menu
	 */
	createFieldMenu( useRowTemplate, filter, createMenuItem,
		doAfterCreatingMenuItems, useDescription ) {
		// TODO this method's signature should contain one object due to amount of arguments
		const columnMap = new Map();
		if ( useRowTemplate ) {
			this._addRowTemplateDescriptorColumnsToMap( columnMap );
		}
		const menuItems = this.createFieldMenuItems(
			columnMap, filter, createMenuItem, useDescription );
		if ( !Validator.isArray( menuItems, false ) ) {
			return void 0;
		}
		const collator = new Intl.Collator();
		menuItems.sort( ( a, b ) => collator.compare( a._comp, b._comp ) );
		if ( Validator.isFunction( doAfterCreatingMenuItems ) ) {
			doAfterCreatingMenuItems( menuItems );
		}
		return new SearchableMenuObject( PSA.instance.getMnuMgr(), ++ID_FIELD_MENU, menuItems );
		// return PSA.instance.getMnuMgr().createMenu( ++ID_FIELD_MENU, menuItems );
	}

	/**
	 * creates the menu items for a field menu
	 * @param {Map} columnMap a map of clumns to create menu items from
	 * @param {Function} filterColumn optional callback that can filter out items
	 * @param {Function} createMenuItem optional callback function to create a
	 * menu item descriptor
	 * @param {Boolean} useDescription flag whether to use the column descriptor instead
	 * of the column title
	 * @returns {Array} the list of created menu items
	 */
	createFieldMenuItems( columnMap, filterColumn, createMenuItem, useDescription ) {
		if ( !Validator.isMap( columnMap ) ||
			!Validator.isFunctionPath( this.xtwHead, "xtwHead.getColumns" ) ) {
			return void 0;
		}
		const columns = this.xtwHead.getColumns( false );
		if ( !Validator.isIterable( columns ) ) {
			return void 0;
		}
		if ( !Validator.isFunction( filterColumn ) ) {
			filterColumn = function ( column ) { return true; };
		}
		if ( !Validator.isFunction( createMenuItem ) ) {
			createMenuItem = function ( columnId, menuItemText, column ) {
				return new XtwMnuItm( columnId, menuItemText, false );
			};
		}
		const menuItems = [];
		columns.forEach( ( column ) => {
			const menuItem = this.createFieldMenuItemForColumn( {
				column: column,
				filterColumn: filterColumn,
				createMenuItem: createMenuItem,
				useDescription: useDescription,
				columnMap: columnMap
			} );
			if ( Validator.isObject( menuItem ) ) {
				menuItems.push( menuItem );
			}
		} );
		return menuItems.length > 0 ? menuItems : void 0;
	}

	createFieldMenuItemForColumn( {
		column,
		filterColumn,
		createMenuItem,
		useDescription,
		columnMap
	} ) {
		if ( !Validator.isObject( column ) || column.select ||
			!filterColumn( column ) ) {
			return void 0;
		}
		const columnId = column.id;
		if ( !Validator.isPositiveInteger( columnId, false ) ||
			( !column.available && columnMap.size != 0 &&
				!columnMap.has( columnId ) ) ) {
			return;
		}
		const menuItemText = getTextFromColumn( column, useDescription );
		const menuItemIcon = ( useDescription ? null : column.image ) || null;
		if ( !Validator.isString( menuItemText ) && !menuItemIcon ) {
			return;
		}
		// column is not filtered out -> create a menu item object
		const menuItem = createMenuItem( columnId, menuItemText, column );
		if ( menuItemIcon ) {
			menuItem.ttlImg = menuItemIcon;
		}
		// set a "compare" text
		menuItem._comp = Validator.isString( menuItemText ) ? menuItemText :
			`000-${ 1000000 + menuItems.length }`;
		return menuItem;
	}

	_addRowTemplateDescriptorColumnsToMap( columnMap ) {
		if ( !Validator.isMap( columnMap ) ) {
			return false;
		}
		const rowTemplateDescriptor = this._getRowTpl();
		if ( !Validator.isObject( rowTemplateDescriptor ) ||
			!Validator.isIterable( rowTemplateDescriptor.columns ) ) {
			return false;
		}
		// get visible RTP columns
		rowTemplateDescriptor.columns.forEach( ( rowTemplateDescriptorColumn ) => {
			if ( !Validator.isObjectPath( rowTemplateDescriptorColumn,
					"rowTemplateDescriptorColumn.rtpCol" ) ||
				!rowTemplateDescriptorColumn.rtpCol.rtpDscVis ) {
				return;
			}
			// ok, column is visible in RTP
			columnMap.set( rowTemplateDescriptorColumn.idc,
				rowTemplateDescriptorColumn.rtpCol );
		} );
		return true;
	}

}

function getTextFromColumn( columnObject, useDescription = true ) {
	if ( !Validator.isObject( columnObject ) ) {
		return "";
	}
	let textToDisplay = !!useDescription &&
		Validator.isString( columnObject.dsc ) ? columnObject.dsc :
		columnObject.menuText;
	if ( Validator.isString( textToDisplay ) ) {
		textToDisplay = textToDisplay.replace( /\n/g, " " )
			.replace( /<br>/g, " " ).replace( /<br\/>/g, " " );
		// TODO add maximal character count
		if ( textToDisplay.length > MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY ) {
			textToDisplay = textToDisplay
				.substring( 0, MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY + 1 ).concat( "..." );
		}
	}
	return Validator.isString( textToDisplay ) ? textToDisplay : "";
}
