import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import { ROW_HEIGHT_CSS_VARIABLE } from '../../XtwTbl';

export default class XtwBodyRowHeightAdjustmentExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	/**
	 * called after the user has changed the height of a row
	 * @param {Number} newRowHeight the new row height
	 */
	onRowHeight( newRowHeight, notify = true ) {
		this.nullifyBeforeRowHeightChangeUiStats();
		const previousRowHeight = this.definedRowHeight;
		if ( !this.updateRowHeightValues( newRowHeight ) ) {
			return false;
		}
		this._rmvAllDomElm();
		this._updDomElm( this.visHgt );
		this.updateWidgetVerticalSelection();
		this.forceSyncHorizontalScrolling();
		this.setCssPyjamaLines();
		if ( !notify ) {
			return true;
		}
		if ( newRowHeight < previousRowHeight ) {
			this.saveBeforeRowHeightChangeUiStats();
		}
		this._nfySrv( "tableRowHeight", { rowHeight: Math.round( newRowHeight ) }, false );
		return true;
	}

	get definedRowHeight() {
		if ( Validator.isObject( this.model ) &&
			Validator.isPositiveNumber( this.model.defRwh ) ) {
			return Number( this.model.defRwh );
		}
		if ( Validator.isPositiveNumber( this._defRwh ) ) {
			return Number( this._defRwh );
		}
		return void 0;
	}

	get widgetVerticalSelectionTopIndex() {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isPositiveNumber( widgetVerticalSelection._selection ) ) {
			return void 0;
		}
		return Number( widgetVerticalSelection._selection );
	}

	updateWidgetVerticalSelection() {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isFunction( widgetVerticalSelection.setSelection ) ||
			!Validator.isPositiveNumber( widgetVerticalSelection._selection ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( widgetVerticalSelection._selection );
		return true;
	}

	setWidgetVerticalSelection( selectionIndex ) {
		if ( !Validator.isPositiveNumber( selectionIndex ) ) {
			return false;
		}
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isFunction( widgetVerticalSelection.setSelection ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( selectionIndex );
		return true;
	}

	updateRowHeightValues( newRowHeight ) {
		if ( !Validator.isPositiveNumber( newRowHeight ) ) {
			return false;
		}
		if ( Validator.isObject( this.model ) && "defRwh" in this.model ) {
			this.model.defRwh = newRowHeight;
		}
		if ( "_defRwh" in this ) {
			this._defRwh = newRowHeight;
		}
		const cssVariableSet =
			this.setCssVariable( ROW_HEIGHT_CSS_VARIABLE, `${ newRowHeight }px` );
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.forEachModelItem ) ) {
			return false;
		}
		selectionManager.forEachModelItem( modelItem => {
			modelItem.isGroupHead() ? modelItem.defRwh = newRowHeight :
				modelItem.height = newRowHeight;
		} );
		return cssVariableSet;
	}

	restoreBeforeRowHeightChangeUiStats() {
		if ( !Validator.isObject( this.beforeRowHeightChangeUiStats ) ) {
			return false;
		}
		let somethingSet = false;
		const verticalScrolling = this.beforeRowHeightChangeUiStats.verticalScrolling;
		if ( Validator.isPositiveNumber( verticalScrolling ) &&
			verticalScrolling != this.widgetVerticalSelectionTopIndex ) {
			this.setWidgetVerticalSelection( verticalScrolling );
			somethingSet = true;
		}
		const horizontalScrolling = this.beforeRowHeightChangeUiStats.horizontalScrolling;
		if ( Validator.isPositiveNumber( horizontalScrolling ) &&
			horizontalScrolling != this.hscPos ) {
			this._scrHorz( horizontalScrolling );
			somethingSet = true;
		}
		this.nullifyBeforeRowHeightChangeUiStats();
		return somethingSet;
	}

	saveBeforeRowHeightChangeUiStats() {
		this.beforeRowHeightChangeUiStats = {
			verticalScrolling: this.widgetVerticalSelectionTopIndex,
			horizontalScrolling: Number( this.hscPos )
		};
	}

	nullifyBeforeRowHeightChangeUiStats() {
		this.beforeRowHeightChangeUiStats = void 0;
		delete this.beforeRowHeightChangeUiStats;
	}

	/**
	 * initiates a row height adjustment operation
	 * @param {MouseEvent} e the mouse event
	 * @param {XRowItem} row the affected row
	 * @param {HTMLElement} elm the "hot" element
	 */
	onRowDrag( e, row, elm ) {
		if ( this.xtdTbl ) {
			this.xtdTbl.onRowDrag( e, row, elm );
		}
	}

	restoreUserDefinedTableRowHeight( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const rowHeight = Number( parameters.rowHeight );
		if ( !Validator.isPositiveInteger( rowHeight, false ) ) {
			return false;
		}
		this.setupModelDataCallback( "restoreUserDefinedTableRowHeight-", () => {
			this.onRowHeight( rowHeight, false );
		} );
		Warner.traceIf( true );
		return true;
	}

}
