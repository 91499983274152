import Base from '../../../base/base';

/**
 * abstract base class for model classes
 * @abstract
 */
export default class MBase extends Base {

	/**
	 * constructs the instance
	 */
	constructor() {
		super();
	}

	/**
	 * destructor method
	 */
	 doDestroy() {
		super.doDestroy();
	}
}
