import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import MGroup from '../../model/MGroup';

export default class MDataRowInsertionDummyExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		parentObject._insertionDummy = false;
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get insertionDummy() {
		if ( !this._insertionDummy ) {
			return false;
		}
		if ( this.group instanceof MGroup ) {
			return this.group.dummyModelItem === this;
		}
		return true;
	}

	set insertionDummy( newValue ) {
		this._insertionDummy = !!newValue;
		if ( !!newValue && this.group instanceof MGroup ) {
			this.group.dummyModelItem = this;
		}
		this.syncInsertionDummyUi();
	}

	syncInsertionDummyUi() {
		const row = this.row;
		if ( !Validator.isObject( row ) ) {
			return false;
		}
		if ( this._insertionDummy &&
			Validator.isFunction( row.displayAsInsertionDummy ) ) {
			return row.displayAsInsertionDummy();
		} else if ( !this._insertionDummy &&
			Validator.isFunction( row.displayAsNotInsertionDummy ) ) {
			return row.displayAsNotInsertionDummy();
		}
		return false;
	}

}
