import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import XtwModel from '../../model/XtwModel';
import { CELL_EDITING_ENABLED } from '../../XtwBody';

const DO_LOG = false;

export default class XtwBodyScrollingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		parentObject.hscPos = 0;
		parentObject.vscPos = 0;
		parentObject.rquHsc = 0;
		parentObject.rquVsc = 0;
		parentObject.inScrUpd = false;
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get widgetHorizontalSelection() {
		if ( !Validator.is( this.xtdTbl, "XtwTbl" ) ||
			!Validator.isObject( this.xtdTbl.wdgSlh ) ) {
			return void 0;
		}
		return this.xtdTbl.wdgSlh;
	}

	/**
	 * calculates the page scroll distance
	 * @param {Boolean} up direction flag
	 * @param {Boolean} wheel flag whether a "mouse wheel" event is beeing processed
	 * @returns {Number} the page scroll distance in pixels
	 */
	getPgScrDist( up, wheel ) {
		// forward to data model
		const cnt = wheel ? Math.min( this.rowItems.length, 1 ) : this.rowItems.length;
		return this.model.getPgScrDist( up, this.topIdx, cnt, this.isRowTpl );
	}

	/**
	 * calculates the effective scrolling height
	 * @param {Number} hgt height in pixels of all visible model items
	 * @returns {Number} the required scrolling height in pixels
	 */
	_getEffScrHeight( hgt ) {
		let eff_hgt = hgt;
		if ( hgt > this.visHgt ) {
			const rtp = this.isRowTpl;
			const rh = rtp ? this.rtpRwh : this.defRwh;
			eff_hgt += rh;
		}
		return eff_hgt;
	}

	forceSyncHorizontalScrolling() {
		this._scrHorz( this.rquHsc );
		return true;
	}

	forceSyncHorizontalScrollbar() {
		const widgetHorizontalSelection = this.widgetHorizontalSelection;
		if ( !Validator.isObject( widgetHorizontalSelection ) ) {
			return false;
		}
		let operationCount = 0;
		if ( Validator.isFunction( widgetHorizontalSelection.setSelection ) ) {
			widgetHorizontalSelection
				.setSelection( widgetHorizontalSelection._selection );
			operationCount++;
		}
		if ( Validator.isFunction( widgetHorizontalSelection._renderThumb ) ) {
			widgetHorizontalSelection._renderThumb();
			operationCount++;
		}
		return operationCount == 2;
	}

	/**
	 * called if the user scrolls vertically
	 * @param {Number} pos current scrolling position
	 */
	onVScroll( pos ) {
		if ( this.visHgt && ( this.vscPos !== pos ) ) {
			this.addHorizontalScrollAfterModelData( null, "onVerticalScroll-" );
			this._scrTriggerUpd( this.hscPos, pos );
		}
	}

	/**
	 * called if the user scrolls horizontally
	 * @param {Number} pos current scrolling position
	 */
	onHScroll( pos ) {
		Warner.traceIf( DO_LOG );
		if ( this.isRowTpl ) {
			return;
		}
		if ( this.visWdt && ( this.hscPos !== pos ) ) {
			this._scrTriggerUpd( pos, this.vscPos );
		}
		if ( CELL_EDITING_ENABLED && Validator.isObject( this.inputField ) &&
			Validator.isFunction( this.inputField.destroySelfAndRestoreCell ) ) {
			this.inputField.destroySelfAndRestoreCell();
		}
	}

	/**
	 * triggers a scroll update
	 * @param {Number} sx new horizontal scrolling position
	 * @param {Number} sy new vertical scrolling position
	 */
	_scrTriggerUpd( sx, sy ) {
		Warner.traceIf( DO_LOG );
		if ( this.rquHsc !== sx ) {
			this.rquHsc = sx;
		}
		if ( this.rquVsc !== sy ) {
			this.rquVsc = sy;
		}
		if ( !this.inScrUpd ) {
			this.inScrUpd = true;
			const self = this;
			window.requestAnimationFrame( () => self._scrDoUpd() );
		}
		this.setCssPyjamaLines();
	}

	/**
	 * finally updates the vew accordingt to current scrolling positions
	 */
	_scrDoUpd() {
		return this.doWithoutLosingSelectionOnSingleSelect( () => {
			const viewUpdated = this._updateTheViewAccordingToScrollingPosition();
			this._doAfterScrollViewUpdate();
			return viewUpdated;
		} );
	}

	_updateTheViewAccordingToScrollingPosition() {
		Warner.traceIf( DO_LOG );
		try {
			if ( this.rquHsc !== this.hscPos ) {
				// we must scroll horizontally
				this._scrHorz( this.rquHsc );
			}
			if ( this.rquVsc !== this.vscPos ) {
				// we must scroll vertically
				const up = this.rquVsc < this.vscPos;
				const model = this.model;
				if ( !( model instanceof XtwModel ) ) {
					Warner.traceIf( DO_LOG, `The table body has an invalid model.` );
					return;
				}
				const tix = Math.max( model.getTopIdx( this.rquVsc, this.topIdx, up ), 0 );
				if ( tix !== this.topIdx ) {
					// update all row items
					if ( DO_LOG ) {
						console.log( `XTW ${this.wdgId} - new top index = ${tix}.` );
					}
					this.vscPos = this.rquVsc;
					this.topIdx = tix;
					this._updDomElm( this.visHgt );
					this._updAllRowItems();
				}
			}
		} finally {
			this.hscPos = this.rquHsc;
			this.vscPos = this.rquVsc;
			this.inScrUpd = false;
		}
		this.setCssPyjamaLines();
		this.updateSelectedRowsUi();
		this.focusLastFocusedModelItem();
	}

	/**
	 * performs horizontal scrolling if required
	 * @param {Number} hsp new horizontal scroll postion
	 */
	_scrHorz( hsp ) {
		this.hscPos = hsp;
		if ( this.visWdt > 0 ) {
			let eff_pos = hsp;
			if ( hsp > 0 ) {
				const vwd = this.visWdt;
				const full_wdt = this._getFullWidth();
				if ( ( vwd + hsp ) > full_wdt ) {
					eff_pos -= vwd + hsp - full_wdt;
					this.hscPos = this.rquHsc = eff_pos; // the calling method sets 'this.hscPos' in its finally block, so we must force the correct value!
				}
			}
			this.rowItems.forEach( ( ri ) => {
				ri.onHScroll( eff_pos );
			} );
		}
	}

	addHorizontalScrollAfterModelData( evt, callBackPrefix = "" ) {
		if ( !Validator.isString( callBackPrefix ) ) {
			callBackPrefix = "";
		}
		const callBackId = Validator.generateRandomString( callBackPrefix );
		const callback = () => {
			this.scrollHorizontallyAfterModelDataCallback( evt, callBackId );
		}
		if ( !Validator.isMap( this._afterModelDataCallbacks ) ) {
			this._afterModelDataCallbacks = new Map();
		}
		const sameTypeCallbacks = !Validator.isString( callBackPrefix ) ?
			Array.from( [] ) : [ ...this._afterModelDataCallbacks.keys() ]
			.filter( key => key.startsWith( callBackPrefix ) );
		for ( let callbackKey of sameTypeCallbacks ) {
			this._afterModelDataCallbacks.delete( callbackKey );
		}
		this._afterModelDataCallbacks.set( callBackId, callback );
		return true;
	}

	scrollHorizontallyAfterModelDataCallback( evt, callBackId ) {
		Warner.traceIf( DO_LOG );
		if ( !Validator.is( this.xtdTbl, "XtwTbl" ) ) {
			return false;
		}
		this.xtdTbl._onHScroll();
		this._scrHorz( this.hscPos );
		if ( !Validator.isString( callBackId ) ||
			!Validator.isMap( this._afterModelDataCallbacks ) ) {
			return true;
		}
		this._afterModelDataCallbacks.delete( callBackId );
		return true;
	}

	onHorizontalScrollbarHidden() {
		this.rquHsc = 0;
		this._scrHorz( 0 );
		return true;
	}

	onHorizontalScrollbarShown() {
		const widgetHorizontalSelection = this.widgetHorizontalSelection;
		if ( !Validator.isObject( widgetHorizontalSelection ) ||
			!Validator.isValidNumber( widgetHorizontalSelection._selection ) ) {
			return false;
		}
		this.rquHsc = widgetHorizontalSelection._selection;
		this._scrHorz( widgetHorizontalSelection._selection );
		return true;
	}

	syncVerticalScrollingWithFirstVisibleRow() {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ) {
			return false;
		}
		const firstVisibleXRowItem = this.firstVisibleXRowItem;
		if ( !Validator.isObject( firstVisibleXRowItem ) ||
			!( "flatIndex" in firstVisibleXRowItem ) ) {
			return false;
		}
		const flatIndex = firstVisibleXRowItem.flatIndex;
		if ( !Validator.isPositiveInteger( flatIndex ) ) {
			return false;
		}
		if ( widgetVerticalSelection._selection === flatIndex ) {
			return true;
		}
		widgetVerticalSelection.setSelection( flatIndex );
		return true;
	}

	onTblMouseWheel( domEvent ) {
		if ( this.isRowTpl ) {
			return true;
		}
		if ( CELL_EDITING_ENABLED && Validator.isObject( this.inputField ) &&
			Validator.isFunction( this.inputField.onInputScroll ) ) {
			return this.inputField.onInputScroll( domEvent );
		}
		return true;
	}

}
