import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

export default class RtpTblMgr {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor(properties) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll(this, ["onSend", "onRender", "onReady"]);
		this.classname = "psawidget.RtpTblMgr";
		this.tblCtl = null;
		this.ready = false;
		this.itmMgr = ItmMgr.getInst();
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			const idt = cwd.tbl;
			if ( idt ) {
				const tbl = rwt.remote.ObjectRegistry.getObject(idt);
				if ( tbl ) {
					this.tblCtl = tbl;
					this._initRC(this.tblCtl.getRowContainer());
					this.sngSel = !!parent.getData("pisasales.CSTPRP.SNG");
					tbl.addEventListener("mousedown", this._onMouDow, this);
				}
			}
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	destroy() {
		if ( this.tblCtl ) {
			const tbl = this.tblCtl;
			this.tblCtl = null;
			this._doneRC(this.rc);
			tbl.removeEventListener("mousedown", this._onMouDow, this);
		}
	}

	onSend() {
		// nothing
	}

	onReady() {
		this.ready = true;
	}

	onRender() {
		rap.off("render", this.onRender);
		this.onReady();
		this._nfySrv('nfyRendered', { rendered: true }, false);
	}

	drwRowTmp(rowTem) {
		if ( rowTem.cls ) {
			const wdg = this.tblCtl._layoutImpl.getWidget();
			const rowCon = wdg.getRowContainer();
			if ( rowCon.getRenderConfig() ) {
				const rndCfg = rowCon.getRenderConfig();
				if ( rndCfg.rowTemplate && (rndCfg.rowTemplate._cells.length === rowTem.cls.length) ) {
					rndCfg.rowTemplate._cells = rowTem.cls;
					try {
						rndCfg.rowTemplate._parseCells();
						rowCon._renderAll();
					} catch (e) {
						this._psa.Log.logErr("Error occured while rendering the row template: " + e);
					}
				}
			}
		}
	}
	
	_initRC(rc) {
		if ( rc ) {
			this._hookRC(rc);
		}
		this.rc = rc;
	}

	_hookRC(rc) {
		if ( rc._container && rc._container.length && (rc._container.length > 0) ) {
			for ( let i = rc._container.length - 1; i >= 0; --i ) {
				this._hookRC(rc._container[i]);
			}
		} else if ( typeof rc.requestToolTipText === 'function' ) {
			const psa = this._psa;
			rc._org_rquttx = rc.requestToolTipText;
			rc.requestToolTipText = function () {
				const qvm = psa.getQvwMgr();
				if ( qvm ) {
					qvm.sndCurPos();
				}
				return rc._org_rquttx.call(rc, arguments);
			};
		}
	}

	_doneRC(rc) {
		if ( rc ) {
			this._unhookRC(rc);
		}
	}

	_unhookRC(rc) {
		if ( rc._container && rc._container.length && (rc._container.length > 0) ) {
			for ( let i = rc._container.length - 1; i >= 0; --i ) {
				this._unhookRC(rc._container[i]);
			}
		} else if (typeof rc._org_rquttx === 'function') {
			const fnc = rc._org_rquttx;
			rc.requestToolTipText = fnc;
			delete rc._org_rquttx;
		}
	}

	/**
	 * Returns the focused row's index (the row with the arrow in front). If there is no focused row -1 is returned.
	 */
	_getFocRow() {
		const userData = this.tblCtl.getUserData("org.eclipse.swt.widgets.Widget#data") || null;
		if ( userData !== null ) {
			if ( userData["pisasales.CSTPRP.CWD"].FOC_ROW !== null ) {
				// property was set by the server in TblColSel#getImage
				return userData["pisasales.CSTPRP.CWD"].FOC_ROW;
			}
		}
		return -1;
	}

	/**
	 * informs the client side, if a hyperlink(anchor-tag) or just a DIV-tag has been clicked.
	 */
	clcTxtSiz(evt) {
		const tgt = evt.getTarget();
		if ( tgt ) {
			const bn = tgt.basename || '';
			const cn = tgt.classname || '';
			if ( (bn === 'Button') || cn.includes('Button') ) {
				// *not* for us! probably a combobox's dropdown button
				return;
			}
		}
		const tag = evt.getDomTarget().tagName;
		let hypLnk = false;
		if ((tag == "A") || (tag == "I") || (tag == "IMG")) {
			hypLnk = true;
		}
		const par = {};
		par.x = evt.getClientX();
		par.y = evt.getClientY();
		par.hyp = hypLnk;
		par.ref = evt.getDomTarget().getAttribute("href");
		let tmpDiv = evt.getDomTarget();
		while ( hypLnk && !par.ref && !!tmpDiv.parentNode && (tmpDiv.parentNode !== window.document) ) {
			tmpDiv = tmpDiv.parentNode;
			par.ref = tmpDiv.getAttribute("href");
		}
		par.sft = evt.isShiftPressed();
		par.ctr = evt.isCtrlPressed();
		this._nfySrv("tblEdrAtv", par, hypLnk);
	}

	/**
	 * initialize a multi select
	 * @param  {MouseEvent} evt
	 */
	_initMultiSelect(evt) {
		const tbl = this.tblCtl.getElement();
		const that = this;
		const shift = evt.isShiftPressed();
		const ctrl = evt.isCtrlPressed();
		let firstSelRow = this.rc.findRowByElement(evt._valueDomTarget);
		if ( !firstSelRow || shift ) {
			// if click was on selector column in header (to select all) return
			return;
		}
		this.firstSelIdx = this.rc.getRowIndex(firstSelRow); // index of row where mouse went down
		const lastPageRowCount = this.tblCtl._getLastPageRowCount();
		while ( !firstSelRow._item && (this.firstSelIdx > 0) ) {
			// if first selection row index exceeds row count
			this.firstSelIdx--;
			firstSelRow = this.rc.getRow(this.firstSelIdx);
		}
		if ( !firstSelRow._item ) {
			// no selection can be made -> table is empty -> abandon
			return;
		}
		this.newSel = []; // array of row indices
		this.oldSel = []; // array of row indices
		this.selRange = [this.firstSelIdx, this.firstSelIdx]; // this is the current row selection (selRange[0] the rowIdx at mouseDownPos & selRange[1] the rowIdx at mouseMovePos)
	
		if ( shift ) {
			// shift-click
			this._initShiftSelect();
		} else {
			// ctrl-click
			this._initCtrlSelect(firstSelRow);
		}
	}

	_initShiftSelect() {
		const tmpFocIdx = this._getFocRow();
		this.focRow = tmpFocIdx;
		this.arrowIdx = tmpFocIdx == -1 ? 0 : tmpFocIdx;
		this.selRange = [this.arrowIdx, this.firstSelIdx];
		this.deselect = false;
		this._doSelect(true, false);
	}
	
	_initCtrlSelect(firstSelRow) {
		for ( let i = 0; i < this.tblCtl.getSelection().length; ++i ) {
			this.oldSel[i] = this.tblCtl.getSelection()[i].getFlatIndex();
		}
		this.focRow = this.rc.getRowIndex(firstSelRow);
		this.deselect = !this.tblCtl.isItemSelected(firstSelRow._item); // if the click deselected an item -> set deselect-flag to true
	}

	_doSelect(isShift, isCtrl) {
		let min = 0;
		let max = 0;
		if ( isShift ) {
			// the rows between the firstly focused row (the one with the arrow) and the now hovered row matter. The rest will remain untouched.
			min = Math.min(this.selRange[1], this.arrowIdx);
			max = Math.max(this.selRange[1], this.arrowIdx);
		} else {
			// Only the rows between the currently hovered rowIdx and the previously hovered rowIdx matter. The rest will remain untouched.
			min = Math.min(this.selRange[1], this.tmpPrvSelRng[1]);
			max = Math.max(this.selRange[1], this.tmpPrvSelRng[1]);
		}
		while ( min <= max ) {
			// update array with current (de)selection (and keep entries unique)
			const arrIdx = this.newSel.indexOf(min);
			if ( arrIdx === -1 ) {
				this.newSel.push(min);
			}
			// render (de)selection, limited to the visible area because table is virtual and getRow(index) will fail for non-displayed rows
			const relIdx = min; // relative index inside displayed table area
			if ( (relIdx >= 0) && (relIdx < this.rc.getRowCount()) ) {
				const row = this.rc.getRow(min);
				if ( row ) {
					const item = this.rc.findItemByRow(row);
					if ( item ) {
						if ( isShift ) {
							this.tblCtl._selectItem(item, true);
						} else if ( isCtrl ) {
							this.deselect ? this.tblCtl._deselectItem(item, true) : this.tblCtl._selectItem(item, true);
						} else {
							this.tblCtl._selectItem(item, true);
						}
					}
				}
			}
			++min;
		}
	}


	_onKeyDown(event) {
		// nothing so far
	}
	
	_onMouDow(evt) {
		if ( evt._valueButton === "left" ) {
			if ( !this.sngSel && (evt.isShiftPressed() || evt.isCtrlPressed()) ) {
				this._initMultiSelect(evt);
			} else {
				this.clcTxtSiz(evt);
			}
		}
	}
	
	_onScroll() {
		// nothing so far
	}

	_nfySrv(code, par, bsc) {
		if ( this.ready ) {
			if ( bsc )  {
				this._psa.setBscRqu();
			}
			const param = {};
			param.cod = code;
			param.par = par;
			rap.getRemoteObject(this).notify("RTT_NFY_SRV", param);
		}
	}

	_callSrv(code, par) {
		const param = {};
		param.cod = code;
		param.par = par;
		let hdl = false;
		try {
			if ( rap.getRemoteObject(this) ) {
				rap.getRemoteObject(this).call("RTT_NFY_SRV", param);
				hdl = true;
			}
		} catch (e) {
			console.warn("Exception occurred: " + e);
		} finally {
			if ( !hdl ) {
				const cwd = this.parent.getData("pisasales.CSTPRP.CWD");
				if ( cwd ) {
					const idw = cwd.tbl;
					this._psa.cliCbkWdg.callSrv(idw, code, par);
				}
			}
		}
	}
	
	_sndRes(par) {
		this._log("sndRes", par);
		this._callSrv("result", par);
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget RtpTblMgr.');
		rap.registerTypeHandler("psawidget.RtpTblMgr", {
		
			factory: function (properties) {
				return new RtpTblMgr(properties);
			},							
		
			destructor: "destroy",
			methods: ["drwRowTmp"],
			events: ["RTT_NFY_SRV"]
		});							
	}
}							

console.log('widgets/rchtable/RtpTblMgr.js loaded.');