import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';

/**
 * class FntIco - client side part of Font based Icon widget
 */
export default class FntIco {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	 constructor(properties) {
		this._psa = PSA.getInst();
		this._psa.bindAll(this, [ "layout", "onReady", "onSend", "onRender" ]);
		const parent = rap.getObject(properties.parent);
		this.parent = parent;
		this.element = null;
		this.parent.addListener("Resize", this.layout);
		this.ready = false;
		this.icoClr = null;
		this.zIdx = 0;
		const cwd = parent.getData("pisasales.CSTPRP.CWD");
		if ( cwd ) {
			const dsc = cwd["dsc"];
			if ( dsc ) {
				const cls = dsc["cssCls"];
				const icn = dsc["icoNam"];
				const siz = dsc["icoSiz"];
				const clr = dsc["icoClr"];
				const fnm = dsc["fntNam"];
				if ( (typeof cls === "string") && (typeof icn === "string") && (typeof siz === "number") ) {
					const mgr = ItmMgr.getInst();
					const elm = mgr.creFntIco(this.parent, cls, icn, siz, clr, fnm, null, cwd.addCss);
					if ( clr ) {
						this.icoClr = clr;
					}
					this.element = elm.div;
					this.ico = elm.ico
					const fgc = cwd["fgrClr"];
					if ( fgc ) {
						mgr.setFgrClr(this.element, fgc);
					}
					if ( cwd.addCss ) {
						const zix = cwd.addCss.zIndex || 0;
						if ( zix ) {
							this.zIdx = zix;
						}
					}
				}
			}
		}
		// activate "render" event
		rap.on("render", this.onRender);
	}

	onReady() {
		this.ready = true;
		// update the layout
		this.layout();
		if ( (this.zIdx > 0) && this.element && this.element.parentNode ) {
			this.element.parentNode.style.zIndex = this.zIdx;
		}
	}
	
	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off("render", this.onRender);
			this.onReady();
		}
	}
	
	onSend() {
		// do nothing so far...
	}
	
	destroy() {
		this._psa.rmvDomElm(this.element);
		this.ico = null;
		this.element = null;
	}
	
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			this.element.style.left = area[0] + "px";
			this.element.style.top = area[1] + "px";
			this.element.style.width = area[2] + "px";
			this.element.style.height = area[3] + "px";
		}
	}
	
	setFgrClr(args) {
		if ( this.element ) {
			ItmMgr.getInst().setFgrClr(this.element, args["fgrClr"]);
		}
	}
	
	setSiz(args) {
		if ( this.element ) {
			const siz = args["size"] || 0;
			if ( siz && (siz > 0) ) {
				const mgr = ItmMgr.getInst();
				mgr.setFntSiz(this.element, siz);
				if ( this.ico ) {
					mgr.setFntSiz(this.ico, siz);
				}
			}
		}
	}
	
	setEna(args) {
		if ( this.element && this.ico && this.icoClr ) {
			const dis = !!args.staDis;
			ItmMgr.getInst().setFgrClr(this.ico, dis ? null : this.icoClr);
		}
	}

	/** register custom widget type */
	static register() {
		console.log('Registering custom widget FntIco.');
		rap.registerTypeHandler("psawidget.FntIco", {
			factory : function(properties) {
				return new FntIco(properties);
			},
			destructor : "destroy",
			methods : [ "setFgrClr", "setSiz", "setEna" ],
			events : [ "FNTICO_NFY" ]
		} );
	}
}

console.log('widgets/csswdg/FntIco.js loaded.');