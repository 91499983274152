import PSA from '../../psa';
import Color from '../../utils/Color';
import DomEventHelper from '../../utils/DomEventHelper';
import HtmHelper from '../../utils/HtmHelper';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import Validator from '../../utils/Validator';
import Warner from '../../utils/Warner';
import JsSize from '../../utils/JsSize';
import AutoFitModeManager from './impl/autofit/AutoFitModeManager';
import XtwTblRowHeightAdjustmentExtension from './impl/rowheight/XtwTblRowHeightAdjustmentExtension';
import XtwTblScrollingExtension from './impl/scrolling/XtwTblScrollingExtension';
import XtwTblColumnTooltipExtension from './impl/tooltip/XtwTblColumnTooltipExtension';
import XtwMgr from './util/XtwMgr';
import XtwUtils from './util/XtwUtils';
import XtwHead from './XtwHead';

export const ROW_HEIGHT_CSS_VARIABLE = "--rtp-table-row-height";
export const HEADER_HEIGHT_CSS_VARIABLE = "--rtp-table-header-height";
const BODY_HEIGHT_CSS_VARIABLE = "--rtp-table-body-height";

const COLOR_PROPERTIES_TO_CSS_VARS = new Map();
COLOR_PROPERTIES_TO_CSS_VARS.set( "pyj", "--rtp-table-main-pyjama-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "hgc", "--rtp-border-bottom-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "vgc", "--rtp-border-right-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "ghb", "--rtp-group-header-background-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "ght", "--rtp-group-header-text-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "hlc", "--rtp-header-border-right-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "hbc", "--rtp-header-background-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "htc", "--rtp-header-text-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "scc", "--pisa-tblColSelBgc" );
const LENGTH_PROPERTIES_TO_CSS_VARS = new Map();
LENGTH_PROPERTIES_TO_CSS_VARS.set( "rwh", ROW_HEIGHT_CSS_VARIABLE );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "hlw", "--rtp-border-bottom-width" );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "vlw", "--rtp-border-right-width" );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "thh", HEADER_HEIGHT_CSS_VARIABLE );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "grh", "--rtp-group-header-height" );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "hvw", "--rtp-header-border-right-width" );

const MINIMAL_COLUMN_WIDTH = 10;

const DO_LOG = false;

/**
 * class XtwTbl - the master component of eXtended Table Widget (XTW);
 * this class is pretty simple because it is just the container managing the global layout and nothing else
 */
export default class XtwTbl {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor( properties ) {
		this._psa = PSA.getInst();
		this._psa.bindAll( this, [ "layout", "onReady", "onRender" ] );
		this._alive = true;
		this.ready = false;
		// initialize the size cache
		this.size = new JsSize( -1, -1 );
		// get the RAP parent element
		const idw = properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject( idw );
		this.parElm = null; // not yet available
		// add the resize listener
		this.parent.addListener( "Resize", this.layout );
		// activate "render" event
		rap.on( "render", this.onRender );
		// evaluate custom widget data - we need access to our scrollbars (a.k.a "sliders") and more
		const cwd = this.parent.getData( "pisasales.CSTPRP.CWD" ) || {};
		this.wdgIdStr = cwd.idstr || '';
		this.idwSlv = cwd.slv || '';
		this.idwSlh = cwd.slh || '';
		this.wdgSlv = null;
		this.wdgSlh = null;
		// get grid colors
		this.clrVtg = cwd.vgc || null;
		this.clrHzg = cwd.hgc || null;
		// register this instance
		XtwMgr.getInst().addXtdTbl( this );
		// later, we'll get our table header widget
		this.wdgHead = null;
		// later, we'll get or table body widget
		this.wdgBody = null;
		// later, we'll create the column drag DIV
		this.cdrElm = null;
		// column drag info
		this.colDrg = null;
		// Row Template
		this.rowTpl = null;
		this.rtpMode = false;
		this.rtpTgl = false;
		// logical "RAP" focus
		this.rapFocus = false;
		if ( Validator.isPositiveNumber( cwd.tss, false ) ) {
			Object.defineProperty( this, "verticalSliderOriginalWidth", {
				value: cwd.tss,
				writable: false,
				configurable: false
			} );
		}
		const minimalColumnWidth = Validator.isPositiveNumber( cwd.mcw ) ?
			cwd.mcw : MINIMAL_COLUMN_WIDTH;
		Object.defineProperty( this, "minimalColumnWidth", {
			value: minimalColumnWidth,
			writable: false,
			configurable: false
		} );
		new AutoFitModeManager( this, !!cwd.afm );
		new XtwTblRowHeightAdjustmentExtension( this );
		new XtwTblScrollingExtension( this );
		new XtwTblColumnTooltipExtension( this );
	}

	get tableHeight() {
		const tableRect = this.tableRect;
		if ( !( tableRect instanceof DOMRect ) ) {
			return void 0;
		}
		return tableRect.height;
	}

	set headClientHeight( headerHeightInPixels ) {
		if ( !Validator.isPositiveInteger( headerHeightInPixels ) ) {
			return;
		}
		this.setCssVariable( HEADER_HEIGHT_CSS_VARIABLE, `${ headerHeightInPixels }px` );
		const tableHeight = this.tableHeight;
		if ( !Validator.isPositiveNumber( tableHeight ) ) {
			return;
		}
		let horizontalScrollbarHeight = this.horizontalScrollbarClientHeight;
		if ( !Validator.isPositiveNumber( horizontalScrollbarHeight, true ) ) {
			horizontalScrollbarHeight = 12; // default
		}
		const requiredBodyHeight =
			tableHeight - headerHeightInPixels - horizontalScrollbarHeight;
		if ( Validator.isFunctionPath( this.wdgBody, "wdgBody.setTableBodyHeight" ) ) {
			this.wdgBody.setTableBodyHeight( requiredBodyHeight, true );
		}
		this.horizontalScrollbarClientTop = headerHeightInPixels + requiredBodyHeight;
		// this.bodyClientHeight = requiredBodyHeight;
		// TODO notify server ??
	}

	get headClientHeight() {
		return Validator.isObject( this.wdgHead ) &&
			"headClientHeight" in this.wdgHead ?
			this.wdgHead.headClientHeight : void 0;
	}

	set bodyClientHeight( bodyHeightInPixels ) {
		if ( !Validator.isPositiveNumber( bodyHeightInPixels ) ) {
			// TODO warn
			return;
		}
		this.setCssVariable( BODY_HEIGHT_CSS_VARIABLE, `${ bodyHeightInPixels }px` );
	}

	get bodyClientHeight() {
		return Validator.isObject( this.wdgBody ) &&
			"bodyClientHeight" in this.wdgBody ?
			this.wdgBody.bodyClientHeight : void 0;
	}

	setCssVariables() {
		const parentElement = this.parentElement;
		if ( !( parentElement instanceof HTMLElement ) ||
			!Validator.isObject( parentElement.style ) ||
			!Validator.isFunction( parentElement.style.setProperty ) ) {
			return false;
		}
		const customWidgetData = this.customWidgetData;
		if ( !Validator.isObject( customWidgetData ) ) {
			return false;
		}
		this.setColorCssVariables( parentElement, customWidgetData );
		this.setLengthCssVariables( parentElement, customWidgetData );
		this.setPyjamaColorProperties( customWidgetData );
		return true;
	}

	setCssVariable( cssVariableName, cssVariableValue ) {
		if ( !Validator.isString( cssVariableName ) ) {
			return false;
		}
		const parentElement = this.parentElement;
		if ( !( parentElement instanceof HTMLElement ) ||
			!Validator.isObject( parentElement.style ) ||
			!Validator.isFunction( parentElement.style.setProperty ) ) {
			return false;
		}
		parentElement.style.setProperty( cssVariableName, cssVariableValue );
		return true;
	}

	setColorCssVariables( element, widgetData ) {
		if ( !Validator.isObject( widgetData ) ||
			!( element instanceof HTMLElement ) ) {
			return false;
		}
		COLOR_PROPERTIES_TO_CSS_VARS.forEach( ( cssVariable, property ) => {
			const color = XtwUtils.colorArrayToRgba( widgetData[ property ] );
			if ( !Validator.isString( color ) ) {
				return;
			}
			element.style.setProperty( cssVariable, color );
		} );
		delete this.setColorCssVariables;
		return true;
	}

	setLengthCssVariables( element, widgetData ) {
		if ( !Validator.isObject( widgetData ) ||
			!( element instanceof HTMLElement ) ) {
			return false;
		}
		LENGTH_PROPERTIES_TO_CSS_VARS.forEach( ( cssVariable, property ) => {
			const numericValue = Number( widgetData[ property ] );
			if ( !Validator.isPositiveInteger( numericValue ) ) {
				return;
			}
			element.style.setProperty( cssVariable, `${ numericValue }px` );
		} );
		delete this.setLengthCssVariables;
		return true;
	}

	setPyjamaColorProperties( widgetData ) {
		if ( !Validator.isObject( widgetData ) ) {
			return false;
		}
		const mainPyjamaColor = Color.fromRgba( widgetData.pyj );
		if ( !( mainPyjamaColor instanceof Color ) ) {
			return false;
		}
		Object.defineProperty( this, "cssProperties", {
			value: {},
			configurable: false,
			writable: false
		} );
		Object.defineProperty( this.cssProperties, "mainPyjamaColor", {
			value: mainPyjamaColor,
			configurable: false,
			writable: false
		} );
		Object.defineProperty( this.cssProperties, "secondPyjamaColor", {
			value: new Color( {} ),
			configurable: false,
			writable: false
		} );
		this.adjustColumnsDataColors();
		delete this.setPyjamaColorProperties;
		return true;
	}

	changeToRowTemplateClass() {
		if ( !Validator.isObject( this.wdgSlv ) ||
			!( this.wdgSlv._element instanceof HTMLElement ) ) {
			return false;
		}
		this.wdgSlv._element.classList.add( "row-template" );
		this.wdgSlv._element.classList.remove( "table" );
		this.wdgSlv._element.style.height = `${ this.bodyClientHeight }px`;
		this.wdgSlv._element.style.top = "0px";
		return true;
	}

	changeToTableClass() {
		if ( !Validator.isObject( this.wdgSlv ) ||
			!( this.wdgSlv._element instanceof HTMLElement ) ) {
			return false;
		}
		this.wdgSlv._element.classList.add( "table" );
		this.wdgSlv._element.classList.remove( "row-template" );
		[ "top", "height" ].forEach( styleProperty => {
			HtmHelper.removeStyleProperty( this.wdgSlv._element, styleProperty );
		} );
		return true;
	}

	get horizontalScrollbarClientHeight() {
		if ( !Validator.isObject( this.wdgSlh ) ||
			!( this.wdgSlh._element instanceof HTMLElement ) ) {
			return false;
		}
		const horizontalScrollbarClientRect = this.wdgSlh._element
			.getBoundingClientRect();
		return horizontalScrollbarClientRect.height;
	}

	set horizontalScrollbarClientHeight( heightInPixels ) {
		if ( !Validator.isPositiveNumber( heightInPixels, true ) ||
			!Validator.isObject( this.wdgSlh ) ||
			!( this.wdgSlh._element instanceof HTMLElement ) ) {
			return;
		}
		this.wdgSlh._element.style.height = `${ heightInPixels }px`;
	}

	set horizontalScrollbarClientTop( valueInPixels ) {
		if ( !Validator.isPositiveNumber( valueInPixels, true ) ||
			!Validator.isObject( this.wdgSlh ) ||
			!( this.wdgSlh._element instanceof HTMLElement ) ) {
			return;
		}
		this.wdgSlh._element.style.top = `${ valueInPixels }px`;
	}

	forEachColumn( columnCallback ) {
		if ( !Validator.isObject( this.wdgHead ) ||
			!Validator.is( this.wdgHead.columns, "ObjReg" ) ||
			!Validator.isMap( this.wdgHead.columns._objReg ) ) {
			return false;
		}
		let allCallbacksSuccessfull = true;
		for ( let column of [ ...this.wdgHead.columns._objReg.values() ] ) {
			const callBackResult = columnCallback( column );
			if ( callBackResult == false ) {
				allCallbacksSuccessfull = false;
			}
		}
		return allCallbacksSuccessfull;
	}

	adjustColumnsDataColors() {
		const allColumnsDataColorsAdjusted = this.forEachColumn( column => {
			if ( !Validator.is( column, "XtwCol" ) ) {
				return false;
			}
			return column.adjustDataColors();
		} );
		delete this.adjustColumnsDataColors;
		return allColumnsDataColorsAdjusted;
	}

	/**
	 * called by the framework to destroy the widget
	 */
	destroy() {
		// unregister this instance
		this._alive = false;
		XtwMgr.getInst().rmvXtdTbl( this );
		// clean-up
		this._cleanup();
		delete this.colDrg;
		delete this.rowDrg;
		delete this.size;
		delete this.ready;
		delete this.parent;
		delete this.wdgSlv;
		delete this.wdgSlh;
		delete this.idwSlv;
		delete this.idwSlh;
		delete this.clrVtg;
		delete this.clrHzg;
		delete this.wdgHead;
		delete this.wdgBody;
		delete this.parElm;
	}

	/**
	 * @returns {Boolean} true if the widget is alive; false otherwise
	 */
	get alive() {
		return this._alive;
	}

	/**
	 * returns the widget ID
	 * @returns {String} the widget ID
	 */
	getId() {
		return this.wdgId;
	}

	get customWidgetData() {
		if ( !Validator.isObject( this.parent ) ||
			!Validator.isFunction( this.parent.getData ) ) {
			return void 0;
		}
		const customData = this.parent.getData( "pisasales.CSTPRP.CWD" );
		return Validator.isObject( customData ) ? customData : void 0;
	}

	get parentElement() {
		if ( this.parElm instanceof HTMLElement ) {
			return this.parElm;
		}
		if ( !Validator.isObjectPath( this.parent, "parent.$el" ) ||
			!Validator.isFunction( this.parent.$el.get ) ) {
			return void 0;
		}
		const element = this.parent.$el.get( 0 );
		if ( !( element instanceof HTMLElement ) ) {
			return void 0;
		}
		this.parElm = element;
		return element;
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
		const dbg = this._psa.isDbgMode();
		this.setCssVariables();
		this.setupVerticalScrollbar();
		this.setupHorizontalScrollbar();
		const de = this.parent.$el.get( 0 );
		if ( de ) {
			this.parElm = de;
			new ExternalEventsTransferManager( this, this.parElm );
			if ( dbg && ( this.parElm instanceof HTMLElement ) &&
				( this.parElm.dataset instanceof DOMStringMap ) ) {
				this.parElm.dataset.class = "XtwTbl parent element";
				this.parElm.dataset.idstr = this.wdgIdStr;
			}
			const self = this;
			const passive = this._psa.canPassiveListeners;
			const opts = passive ? { passive: true } : false;
			de.addEventListener( 'mouseleave', ( e ) => {
				self._onMouseLeave( e );
			}, false );
			de.addEventListener( 'mousemove', ( e ) => {
				self._onMouseMove( e );
			}, false );
			de.addEventListener( 'mouseup', ( e ) => {
				self._onMouseUp( e );
			}, false );
			de.addEventListener( 'wheel', ( e ) => {
				self._onMouseWheel( e, passive );
			}, opts );
			de.addEventListener( 'click', ( e ) => {
				self._onClick( e );
			}, false );
			de.addEventListener( 'keyup', ( e ) => {
				self._onKeyUp( e );
			}, false );
			de.addEventListener( 'keydown', domEvent => {
				self._onKeyDown( domEvent );
			}, false );
			// create column drag widget
			const cdw = document.createElement( 'div' );
			if ( dbg && ( cdw instanceof HTMLElement ) && ( cdw.dataset instanceof DOMStringMap ) ) {
				cdw.dataset.class = "column drag widget";
			}
			cdw.style.position = 'absolute';
			cdw.style.left = 0;
			cdw.style.top = 0;
			cdw.style.width = '1px';
			cdw.style.height = 'inherit';
			cdw.style.borderLeft = '1px solid #000';
			cdw.style.zIndex = 1000000;
			cdw.style.display = 'none';
			this.cdrElm = cdw;
			de.appendChild( cdw );
			// create row drag widget
			this.createRowDragWidgetElement( dbg );
			de.appendChild( this.rdrElm );
		}
	}

	/**
	 * called by the framework in rendering phase
	 */
	onRender() {
		if ( this.parent ) {
			rap.off( "render", this.onRender ); // just once!
			this.onReady();
			this.layout();
		}
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		if ( this.ready ) {
			// all we have to do is to inform the server side if the size as been changed
			const area = this.parent.getClientArea();
			const wdt = area[ 2 ] || 0;
			const hgt = area[ 3 ] || 0;
			this._updSize( wdt, hgt );
		}
	}

	/**
	 * sets the row template descriptor
	 * @param {Object} args row tzemplate descriptor
	 */
	setRowTpl( args ) {
		if ( ( typeof args === 'object' ) && !!args.rowTpl ) {
			this.rowTpl = args;
		} else {
			this.rowTpl = null;
		}
	}

	/**
	 * returns the row template descriptor
	 * @returns {Object} the row template descrptor or null
	 */
	getRowTpl() {
		return this.rowTpl;
	}

	/**
	 * sets the "RAP" focus flag
	 * @param {Boolean} rf the "RAP" focus flag
	 */
	setRapFocus( rf ) {
		this.rapFocus = !!rf;
		if ( this.wdgBody ) {
			this.wdgBody.setRapFocus( this.rapFocus );
		}
	}

	/**
	 * returns the current "RAP focus" state
	 * @returns {Boolean} true if the table has the focus; false otherwise
	 */
	get isRapFocus() {
		return this.rapFocus;
	}

	/**
	 * initializes the view mode
	 * @param {Object} args arguments
	 */
	iniViewMode( args ) {
		if ( this.rowTpl ) {
			// only if a row template is available!
			const rtp = !!args.rtp;
			this.rtpTgl = !!args.tgl;
			if ( this.rtpMode !== rtp ) {
				this.rtpMode = rtp;
				if ( this.wdgBody ) {
					this.wdgBody.iniViewMode( this.rtpMode, this.rtpTgl );
				}
			}
		}
	}

	/**
	 * sets the header widget
	 * @param {XtwHead} xth the header widget
	 */
	setHeadWdg( xth ) {
		this.wdgHead = xth;
		if ( this.wdgBody ) {
			this.wdgBody.setTblHead( this.wdgHead );
			this.wdgHead.setTblBody( this.wdgBody );
		}
	}

	/**
	 * sets the body widget
	 * @param {XtwBody} xtb the body widget
	 */
	setBodyWdg( xtb ) {
		this.wdgBody = xtb;
		if ( this.wdgHead ) {
			this.wdgBody.setTblHead( this.wdgHead );
			this.wdgHead.setTblBody( this.wdgBody );
			if ( this.rowTpl ) {
				const args = { rtp: this.rtpMode, tgl: this.rtpTgl };
				this.rtpMode = !this.rtpMode; // force iniViewMode() to update the body widget
				this.iniViewMode( args );
			}
		}
	}

	/**
	 * sets the widths of fixed and dynamic parts
	 * @param {Number} fxw width of fixed part
	 * @param {Number} dnw width of dynamic part
	 */
	setPartWdt( fxw, dnw ) {
		if ( this.wdgBody ) {
			this.wdgBody.setPartWdt( fxw, dnw );
		}
	}

	/**
	 * initiates a column drag operation
	 * @param {MouseEvent} e the mouse event
	 * @param {XtwCol} col the affected column
	 * @param {HTMLElement} elm the "hot" element
	 * @param {Boolean} full "full column" drag
	 */
	onColumnDrag( e, col, elm, full ) {
		// initialize column drag
		this._cdrStart( e, col, elm, full );
	}

	/**
	 * performs cleanup on destruction
	 */
	_cleanup() {
		if ( this.cdrElm ) {
			const cdw = this.cdrElm;
			delete this.cdrElm;
			this._psa.rmvDomElm( cdw );
		}
		if ( this.rdrElm ) {
			this.cleanupRowDragElement();
		}
		if ( this.wdgSlv ) {
			const slv = this.wdgSlv;
			this.wdgSlv = null;
			slv.removeEventListener( 'selectionChanged', this._onVScroll, this );
		}
		if ( this.wdgSlh ) {
			const slh = this.wdgSlh;
			this.wdgSlh = null;
			slh.removeEventListener( 'selectionChanged', this._onHScroll, this );
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv( code, par ) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject( this ).notify( "PSA_XTD_TBL_NFY", param );
		}
	}

	/**
	 * updates the cached size values and notifies the server side if the size has been changed
	 * @param {Number} cx new width in pixels
	 * @param {Number} cy new height in pixels
	 */
	_updSize( cx, cy ) {
		const widthChanged = this.size.cx !== cx;
		const chn = widthChanged || ( this.size.cy != cy );
		if ( widthChanged ) {
			this.setAutoFitModusColumnsWidths( cx );
		}
		if ( chn ) {
			this.size.cx = cx;
			this.size.cy = cy;
			this._nfySrv( 'resize', this.size );
		}
	}

	_onMouseLeave( e ) {
		if ( this.colDrg ) {
			// stop any column drag
			this._cdrStop();
		}
		if ( this.rowDrg ) {
			// stop any row drag
			this._rdrStop();
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseLeave( e );
		}
	}

	_onMouseMove( e ) {
		if ( this.colDrg ) {
			this.cdrElm.style.left = this._getEffPos( e ).x + 'px';
		}
		if ( this.rowDrg ) {
			this.onRowDragMouseMove( e );
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseMove( e );
		}
	}

	_onMouseUp( e ) {
		if ( this.colDrg ) {
			const xp = this._getEffPos( e ).x;
			const dw = xp - this.colDrg.pos;
			// at this point we have to update all columns and to notify the server side about changed size requirements
			const col = this.colDrg.col;
			const wdt = Math.max( col.width + dw, 4 );
			// stop!
			this._dragStop();
			// call the "official" method to notify all parts that need to know this
			this.wdgHead.onColumnWidth( col, wdt, e )
		}
		if ( this.rowDrg ) {
			this.onRowDragMouseUp( e );
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseUp( e );
		}
	}

	_onMouseWheel( e, passive ) {
		this._dragStop();
		if ( this.wdgSlv && this.wdgBody ) {
			if ( e.deltaY ) {
				// we don't care about the mode, we just want the direction
				const sb = this.wdgSlv;
				const up = e.deltaY < 0;
				const dist = 3; // we use a fixed "wheel" distance of 3 items - was: //this.wdgBody.getPgScrDist( up, true );
				if ( dist !== 0 ) {
					const mx = sb.getMaximum();
					const mn = sb.getMinimum();
					const tb = sb.getThumb();
					const dv = tb > 0 ? Math.min( tb, dist ) : dist;
					const sp = sb._selection;
					const np = up ? Math.max( sp - dv, mn ) : Math.min( sp + dv, mx - tb );
					if ( np != sp ) {
						sb.setSelection( np );
					}
					if ( !passive ) {
						e.preventDefault();
						e.stopPropagation();
					}
				}
			}
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseWheel( e );
		}
		if ( Validator.is( this.wdgBody, "XtwBody" ) ) {
			this.wdgBody.onTblMouseWheel( e );
		}
	}

	/**
	 * handles mouse clicks somewhere in the table
	 * @param {MouseEvent} e the mouse event
	 */
	_onClick( e ) {
		const tgt = e.target;
		let done = false;
		if ( tgt && this._psa.isStr( tgt.__psanfo ) ) {
			// that's a marked element!
			const marker = tgt.__psanfo;
			const pc = marker.indexOf( ':' );
			if ( pc !== -1 ) {
				const pa = marker.indexOf( '@' );
				const ns = ( pa !== -1 ) ? marker.substring( pc + 1, pa ) : marker.substring( pc + 1 );
				const idc = parseInt( ns, 10 );
				if ( !isNaN( idc ) && ( idc !== -1 ) ) {
					// a valid column ID that is *not* the "select" column
					const idr = ( pa !== -1 ) ? parseInt( marker.substring( pa + 1 ) ) : 0;
					if ( !isNaN( idr ) ) {
						const par = { idc: idc, idr: idr };
						this._nfySrv( 'partClick', par );
						done = true;
					}
				}
			}
		}
		if ( !done ) {
			// we got a mouse click somewhere else; just trigger the focus trackers
			const par = { idc: 0, idr: 0 };
			this._nfySrv( 'partClick', par );
		}
	}

	_onKeyUp( evt ) {
		if ( this.handleRefreshAllRequest( evt ) ) {
			return;
		}
	}

	handleRefreshAllRequest( evt ) {
		if ( !( evt instanceof KeyboardEvent ) || !evt.altKey ||
			!XtwUtils.isCommandKeyPressed( evt ) ||
			![ "R", "r", "KeyR" ].find( key =>
				XtwUtils.keyIs( evt, key ) ) ) {
			return false;
		}
		this._nfySrv( "refreshAll", {} );
		return true;
	}

	/**
	 * @return {Boolean} whether or not the current template is a "row template";
	 * "true" if the current template is a "row template", "false" otherwise
	 */
	get isRowTpl() {
		if ( !Validator.is( this.wdgBody, "XtwBody" ) ||
			!( "isRowTpl" in this.wdgBody ) ) {
			return false;
		}
		return this.wdgBody.isRowTpl;
	}

	/**
	 * @return {Boolean} whether or not the "row template" view/display is
	 * supported
	 */
	get hasRowTpl() {
		if ( !Validator.isObject( this.rowTpl ) ) {
			return false;
		}
		return !!this.rowTpl.rowTpl;
	}

	_getEffPos( e ) {
		let x = e.clientX;
		let y = e.clientY;
		if ( this.parElm ) {
			const r = this.parElm.getBoundingClientRect();
			x -= r.left;
			y -= r.top;
		}
		return { x: x, y: y };
	}

	/**
	 * stops all dragging operations
	 */
	_dragStop() {
		this._cdrStop();
		this._rdrStop();
	}

	/**
	 * starts a column drag operation
	 * @param {MouseEvent} e the mouse event
	 * @param {XtwCol} col the affected column
	 * @param {HTMLElement} elm the "hot" element
	 * @param {Boolean} full "full column" drag
	 */
	_cdrStart( e, col, elm, full ) {
		this._dragStop();
		const cd = {};
		const xp = this._getEffPos( e ).x;
		cd.pos = xp;
		cd.col = col;
		cd.elm = elm;
		cd.full = full;
		this.colDrg = cd;
		this.cdrElm.style.left = xp + 'px';
		this.cdrElm.style.display = '';
		this.parElm.style.cursor = 'col-resize';
	}

	/**
	 * stops a column drag operation
	 */
	_cdrStop() {
		if ( this.colDrg ) {
			delete this.colDrg;
			this.colDrg = null;
			if ( this.cdrElm ) {
				this.cdrElm.style.display = 'none';
			}
			if ( this.parElm ) {
				this.parElm.style.cursor = '';
			}
		}
	}

	resetColumnsDefaultProperties( orderedColumnsProperties ) {
		const headSuccessfullyReset = !Validator.is( this.wdgHead, "XtwHead" ) ? false :
			this.wdgHead.resetColumnsDefaultProperties( orderedColumnsProperties );
		this.autoFitColumns();
		Warner.traceIf( !headSuccessfullyReset && DO_LOG, `Could not reset default` +
			` columns properties on the table widget head <XtwHead>.` );
	}

	handleTransferredEvent( domEvent ) {
		if ( XtwUtils.isArrowUp( domEvent ) ||
			XtwUtils.isArrowDown( domEvent ) ) {
			if ( !Validator.is( this.wdgBody, "XtwBody" ) ||
				!Validator.isFunction( this.wdgBody.handleTransferredEvent ) ) {
				Warner.traceIf( DO_LOG, `The DOM arrow navigation event could not be` +
					` transferred from the table widget to the table body` +
					` widget, because the table body widget does not have a` +
					` method to handle transferred DOM events.` );
				return false;
			}
			return this.wdgBody.handleTransferredEvent( domEvent )
		}
	}

	/** register custom widget type */
	static register() {
		console.log( 'Registering custom widget XtwTbl.' );
		rap.registerTypeHandler( 'psawidget.XtwTbl', {
			factory: function ( properties ) {
				return new XtwTbl( properties );
			},
			destructor: 'destroy',
			properties: [ 'rowTpl', 'rapFocus' ],
			methods: [ 'iniViewMode', 'resetColumnsDefaultProperties', 'onScrollbarVisibilityChange' ],
			events: [ 'PSA_XTD_TBL_NFY' ]
		} );
	}
}

console.log( "widgets/xtw/XtwTbl.js loaded." );
