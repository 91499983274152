import AttachmentObject from '../../../../utils/AttachmentObject';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

const MINIMAL_ROW_HEIGHT = 4;

export default class XtwTblRowHeightAdjustmentExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;

		// later, we'll create the row drag DIV
		parentObject.rdrElm = null;
		// row drag info
		parentObject.rowDrg = null;
	}

	onHeightAdjustmentDivMouseDown( domEvent, xRowItem ) {
		Warner.traceIf( true );
		this._rdrStart( domEvent, xRowItem, xRowItem.element );
		return true;
	}

	/**
	 * initiates a row height adjustment operation
	 * @param {MouseEvent} domEvent the mouse event
	 * @param {XRowItem} xRowItem the affected row
	 * @param {HTMLElement} element the "hot" element
	 */
	onRowDrag( domEvent, xRowItem, element ) {
		// initialize row drag
		this._rdrStart( element, xRowItem, element );
	}

	/**
	 * starts a row drag operation
	 * @param {MouseEvent} domEvent the mouse event
	 * @param {XRowItem} row the affected row
	 * @param {HTMLElement} element the "hot" element
	 */
	_rdrStart( domEvent, row, element ) {
		this._dragStop();
		const rd = {};
		const yp = this._getEffPos( domEvent ).y;
		rd.pos = yp;
		rd.row = row;
		rd.elm = element;
		this.rowDrg = rd;
		this.rdrElm.style.top = yp + 'px';
		this.rdrElm.style.display = '';
		this.parElm.style.cursor = 'row-resize';
	}

	/**
	 * strops a row drag operation
	 */
	_rdrStop() {
		if ( !Validator.isObject( this.rowDrg ) ) {
			return false;
		}
		delete this.rowDrg;
		this.rowDrg = void 0;
		if ( this.rdrElm instanceof HTMLElement ) {
			this.rdrElm.style.display = 'none';
		}
		if ( this.parElm instanceof HTMLElement ) {
			this.parElm.style.cursor = '';
		}
		return true;
	}

	onRowDragMouseUp( domEvent ) {
		if ( !Validator.isObjectPath( this.rowDrg, "rowDrg.row" ) ||
			!Validator.isPositiveNumber( this.rowDrg.pos ) ) {
			this._dragStop(); // stop!
			return false;
		}
		// at this point we have to update all affected elements and to notify the
		// server side about changed size requirements
		const xRowItem = this.rowDrg.row;
		if ( xRowItem.isGroupHead ) {
			this._dragStop(); // stop!
			return false;
		}
		const xRowItemHeight = Number( xRowItem.height );
		if ( !Validator.isPositiveNumber( xRowItemHeight ) ) {
			this._dragStop(); // stop!
			return false;
		}
		const xRowItemClientHeight = Number( xRowItem.clientHeight );
		Warner.traceIf( xRowItemHeight != xRowItemClientHeight, `The XRowItem` +
			` height registered on the model item has the value of` +
			` "${ xRowItemHeight }" and is different from the estimated client` +
			` height of the XRowItem's DOM element, which has the value of` +
			` "${ xRowItemClientHeight }".` );
		const eventEffectivePositionY = Number( this._getEffPos( domEvent ).y );
		if ( !Validator.isPositiveNumber( eventEffectivePositionY ) ) {
			this._dragStop(); // stop!
			return false;
		}
		const rowDragPosition = Number( this.rowDrg.pos );
		if ( !Validator.isPositiveNumber( rowDragPosition ) ) {
			this._dragStop(); // stop!
			return false;
		}
		const heightDifference = eventEffectivePositionY - rowDragPosition;
		const resultantHeight = Math.max( xRowItemHeight + heightDifference,
			MINIMAL_ROW_HEIGHT );
		this._dragStop(); // stop!
		// call the "official" method to notify all parts that need to know this
		return this.wdgBody.onRowHeight( resultantHeight );
	}

	onRowDragMouseMove( domEvent ) {
		this.rdrElm.style.top = this._getEffPos( domEvent ).y + 'px';
	}

	cleanupRowDragElement() {
		const rdw = this.rdrElm;
		delete this.rdrElm;
		this._psa.rmvDomElm( rdw );
	}

	createRowDragWidgetElement( debug = false ) {
		const rowDragWidgetElement = window.document.createElement( "div" );
		if ( debug && rowDragWidgetElement instanceof HTMLElement &&
			rowDragWidgetElement.dataset instanceof DOMStringMap ) {
			rowDragWidgetElement.dataset.class = "row drag widget";
		}
		rowDragWidgetElement.style.position = 'absolute';
		rowDragWidgetElement.style.left = 0;
		rowDragWidgetElement.style.top = 0;
		rowDragWidgetElement.style.width = 'inherit';
		rowDragWidgetElement.style.height = '1px';
		rowDragWidgetElement.style.borderTop = '1px solid #000';
		rowDragWidgetElement.style.zIndex = 1000000;
		rowDragWidgetElement.style.display = 'none';
		this.rdrElm = rowDragWidgetElement;
	}

}
