import AttachmentObject from '../../../../utils/AttachmentObject';
import Checkbox from './Checkbox';
import DropdownInputField from './DropdownInputField';
import EventListenerManager from '../../../../utils/EventListenerManager';
import InputField from './InputField';
import Textarea from './Textarea';
import Validator from '../../../../utils/Validator';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Warner from '../../../../utils/Warner';
import XtwUtils from '../../util/XtwUtils';
import XtwCol from '../../parts/XtwCol';
import { CELL_EDITING_ENABLED } from '../../XtwBody';

const DO_LOG = true;

export default class XtwCellEditingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	registerEditing() {
		if ( !( this.inputField instanceof InputField ) ) {
			return false;
		}
		return this.inputField.register();
	}

	setContentText( newContentText ) {
		if ( typeof newContentText != "string" ||
			!Validator.isObject( this.ctt ) ) {
			return false;
		}
		this.ctt.text = newContentText;
		this.ctt.rawText = newContentText;
		this.setCtt( this.ctt );
		return true;
	}

	get cellAccessMode() {
		// default cell access mode is writable (3)
		if ( Validator.isObject( this.row ) && this.row.insertionDummy ) {
			return 3;
		}
		return Validator.isObject( this.ctt ) &&
			Validator.isPositiveInteger( this.ctt.acc ) ?
			Number( this.ctt.acc ) : 3;
	}

	get isReadOnly() {
		const cellAccessMode = this.cellAccessMode;
		return [ 2 ].indexOf( cellAccessMode ) >= 0;
	}

	get canBeEdited() {
		const cellAccessMode = this.cellAccessMode;
		return [ 3, 4 ].indexOf( cellAccessMode ) >= 0;
	}

	get shouldBeSkipped() {
		const cellAccessMode = this.cellAccessMode;
		return Validator.isPositiveInteger( cellAccessMode ) && cellAccessMode < 2;
	}

	get isWritable() {
		return this.cellAccessMode === 3;
	}

	get isMandatory() {
		return this.cellAccessMode === 4;
	}

	get xtwBody() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "tblBody" in row ) ) {
			return void 0;
		}
		return row.tblBody;
	}

	get model() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isObject( xtwBody ) || !( "model" in xtwBody ) ) {
			return void 0;
		}
		return xtwBody.model;
	}

	get flatModel() {
		const model = this.model;
		if ( !Validator.isObject( model ) ||
			!Validator.isArray( model.flatModel ) ) {
			return void 0;
		}
		return [ ...model.flatModel ];
	}

	get _flatIndex() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "_flatIndex" in row ) ) {
			return void 0;
		}
		return row._flatIndex;
	}

	get selectionManager() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "selectionManager" in row ) ) {
			return void 0;
		}
		return row.selectionManager;
	}

	get isBlob() {
		const column = this.column;
		return Validator.isObject( column ) && "isBlob" in column ?
			column.isBlob : false;
	}

	get isFocused() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.isFocusedCell ) ) {
			return false;
		}
		const row = this.row;
		if ( !Validator.isObject( row ) ) {
			return false;
		}
		return selectionManager.isFocusedCell( {
			columnId: this.idc,
			rowId: Number( row.rowId )
		} );
	}

	get canHaveDropdown() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return !!column.dropdown;
	}

	get type() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) || !( "type" in column ) ) {
			return void 0;
		}
		return column.type;
	}

	get isTextDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 0;
	}

	get isNumericDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 1;
	}

	get isDateTimeDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 2;
	}

	get isLogicDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 3;
	}

	get isBlobDataType() {
		const column = this.column;
		if ( !( column instanceof XtwCol ) ) {
			return false;
		}
		return column.type === 4;
	}

	addEditingListeners() {
		const cellContainerListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "click",
				functionName: "onCellContainerClick",
				callBackPrefix: "CellContainer",
				element: this.element,
				useCapture: false
			} );
		if ( cellContainerListenerSuccessfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addEditingListeners;
		}
		return cellContainerListenerSuccessfullyAdded;
	}

	addKeyListeners() {
		if ( !this.isRendered ) {
			return false;
		}
		this.element.tabIndex = 1;
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onCellContainerKeyUp",
			callBackPrefix: "CellContainer",
			element: this.element,
			useCapture: false
		} );
		if ( keyupListenerSuccessfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addKeyListeners;
		}
		return keyupListenerSuccessfullyAdded;
	}

	addDoubleClickListener() {
		const cellContainerListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "dblclick",
				functionName: "onCellContainerDoubleClick",
				callBackPrefix: "CellContainer",
				element: this.element,
				useCapture: false
			} );
		if ( cellContainerListenerSuccessfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addDoubleClickListener;
		}
		return cellContainerListenerSuccessfullyAdded;
	}

	/**
	 * focusing a cell autoamtically focuses a row and selects it in case the
	 * selection mode allows it, whilst taking the shift & command keys into
	 * account (the "selected" status of other row/model items is taken into
	 * account and may be influenced)
	 */
	focusCell( domEvent ) {
		if ( !this.isRendered ) {
			return false;
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.focusCell ) ) {
			return false;
		}
		const domEventIsValid = domEvent instanceof MouseEvent ||
			domEvent instanceof KeyboardEvent;
		const shiftPressed = !domEventIsValid ? false :
			"transferredFromCellContainer" in domEvent &&
			!!domEvent.transferredFromCellContainer ? false : !!domEvent.shiftKey;
		const controlPressed = domEventIsValid &&
			!!XtwUtils.isCommandKeyPressed( domEventIsValid );
		const rowId = Validator.isObject( this.row ) ? Number( this.row.rowId ) : void 0;
		return selectionManager.focusCell( {
			rowId: rowId,
			colId: this.idc,
			controlPressed: controlPressed,
			shiftPressed: shiftPressed,
			forceSelectIfSimpleClick: true
		} );
	}

	get editingPen() {
		const penSpan = window.document.createElement( "span" );
		penSpan.style.marginLeft = "auto";
		penSpan.style.marginRight = "auto";
		penSpan.style.fontSize = "16px";
		penSpan.style.color = "#717171";
		// penSpan.innerHTML = "&#128393;";
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( "fa", "fa-pencil" );
		penSpan.appendChild( italicIconPlaceholder );
		return penSpan;
	}

	get focusedEditingPen() {
		const penSpan = window.document.createElement( "span" );
		penSpan.style.marginLeft = "auto";
		penSpan.style.marginRight = "auto";
		penSpan.style.fontSize = "16px";
		penSpan.style.color = "#717171";
		// penSpan.innerHTML = "&#128397;";
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( "fa", "fa-pencil" );
		penSpan.appendChild( italicIconPlaceholder );
		return penSpan;
	}

	_addPen( focused = false ) {
		if ( !this.isRendered ) {
			return false;
		}
		this.removeSelectionArrow();
		const element = this.element;
		element.style.alignItems = "center";
		const editingPen = !!focused ? this.focusedEditingPen : this.editingPen;
		element.appendChild( editingPen );
		return true;
	}

	addEditingPen() {
		return this._addPen( false );
	}

	removeEditingPen() {
		return this.removeSelectionArrow();
	}

	addFocusedEditingPen() {
		return this._addPen( true );
	}

	cleanLastEditedCell() {
		const tableBody = this.xtwBody;
		if ( !Validator.isObject( tableBody ) ||
			!Validator.isFunction( tableBody.cleanLastEditedCell ) ) {
			return false;
		}
		return tableBody.cleanLastEditedCell();
	}

	cleanFromEditables() {
		const tableBody = this.xtwBody;
		if ( !Validator.isObject( tableBody ) ||
			!Validator.isFunction( tableBody.cleanFromEditables ) ) {
			return false;
		}
		return tableBody.cleanFromEditables();
	}

	/**
	 * @override
	 * @see XCellItem.js~XCellItem#onCellContentClick
	 */
	onCellContentClick( domEvent ) {
		if ( !CELL_EDITING_ENABLED ) {
			return this.link ? this.onLinkClick( domEvent ) : false;
		}
		if ( !this.link ) {
			return this.onCellContainerClick( domEvent );
		}
		const editingAttempt = !Validator.isObject( domEvent ) ? false :
			!!domEvent.shiftKey || !!domEvent.transferredFromNeighbour;
		return editingAttempt ? this.onCellContainerClick( domEvent ) :
			this.onLinkClick( domEvent );
	}

	isHyperlinkClick( domEvent ) {
		return !this.link ? false : this.shouldBeSkipped ? true :
			!DomEventHelper.isShiftEvent( domEvent );
	}

	onCellContainerClick( domEvent ) {
		this.triggerBlurOnPreviousCell();
		if ( this.isHyperlinkClick( domEvent ) && this.checkbox instanceof Checkbox ) {
			return this.onCheckboxHyperlink( domEvent );
		}
		if ( this.shouldBeSkipped ) {
			// TODO should it be done after the cell is focused?
			return false;
		}
		const eventComesFromAnotherCell = Validator.isObject( domEvent ) &&
			!!domEvent.transferredFromNeighbour;
		if ( !this.isFocused || eventComesFromAnotherCell ) {
			if ( Validator.isObject( domEvent ) ) {
				domEvent.transferredFromCellContainer = true;
			}
			this.focusCell( domEvent );
		}
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( this.isLogicDataType ) {
			if ( !this.thisCellIsCurrentlyEdited ) {
				this.cleanLastEditedCell();
				this.cleanFromEditables();
			}
			return !eventComesFromAnotherCell ? true :
				this.checkbox instanceof Checkbox && this.canBeEdited ?
				this.checkbox.onInputClick( domEvent ) : true;
		}
		if ( this.isBlobDataType ) {
			return false;
		}
		return this.createAndFocusInputField( domEvent );
		// this.createAndFocusSelectDropdown( domEvent );
	}

	triggerBlurOnPreviousCell() {
		if ( this.thisCellIsCurrentlyEdited ) {
			return true;
		}
		Warner.traceIf( DO_LOG );
		const body = this.xtwBody;
		return Validator.isFunctionPath( body, "body.blurLastEditedCell" ) ?
			body.blurLastEditedCell() : false;
	}

	focusCheckbox( domEvent ) {
		if ( !( this.checkbox instanceof Checkbox ) ) {
			return false;
		}
		if ( !this.thisCellIsCurrentlyEdited ) {
			this.cleanLastEditedCell();
			this.cleanFromEditables();
		}
		const eventComesFromAnotherCell = Validator.isObject( domEvent ) &&
			!!domEvent.transferredFromNeighbour;
		if ( !this.isFocused || eventComesFromAnotherCell ) {
			if ( Validator.isObject( domEvent ) ) {
				domEvent.transferredFromCellContainer = true;
			}
			this.focusCell( domEvent );
		}
		return this.checkbox.focusSelf();
	}

	get thisCellIsCurrentlyEdited() {
		const body = this.xtwBody;
		if ( !Validator.isObject( body ) ) {
			return false;
		}
		if ( this.textarea instanceof Textarea &&
			body.textarea === this.textarea ) {
			return true;
		}
		if ( this.inputField instanceof InputField &&
			body.inputField === this.inputField ) {
			return true;
		}
		if ( this.checkbox instanceof Checkbox &&
			body.inputField === this.checkbox ) {
			return true;
		}
		return false;
	}

	onCellContainerKeyUp( domEvent ) {
		if ( DomEventHelper.keyIs( domEvent, "F2" ) ) {
			if ( domEvent instanceof KeyboardEvent ) {
				domEvent.stopPropagation();
				domEvent.preventDefault();
				// domEvent.alreadyHandled = true;
			}
			return this.onCellContainerClick( domEvent );
		}
		return true;
	}

	onCheckboxHyperlink( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		this.onLinkClick( domEvent );
		return true;
	}

	onCellContainerDoubleClick( domEvent ) {
		if ( !this.isBlobDataType ) {
			return false;
		}
		this._nfySrv( "blobDoubleClick" );
		return true;
	}

	createAndFocusInputField( domEvent ) {
		if ( !( this.inputField instanceof InputField ) ) {
			this.cleanLastEditedCell();
			this.cleanFromEditables();
			this.canHaveDropdown ?
				new DropdownInputField( this ) : new InputField( this );
			this.inputField.render();
		}
		if ( !( this.inputField instanceof InputField ) ) {
			return false;
		}
		if ( window.document.activeElement != this.inputField.input ) {
			this.inputField.input.focus();
		}
		if ( Validator.isFunction( this.inputField.addBlurListener ) ) {
			this.inputField.addBlurListener();
		}
		if ( Validator.isFunction( this.inputField.addMouseWheelListener ) ) {
			this.inputField.addMouseWheelListener();
		}
		return true;
	}

	createAndFocusTextarea() {
		if ( !( this.textarea instanceof Textarea ) ) {
			new Textarea( this );
			this.textarea.render();
		}
		if ( !( this.textarea instanceof Textarea ) ) {
			return false;
		}
		if ( window.document.activeElement != this.textarea.textarea ) {
			this.textarea.textarea.focus();
		}
		return true;
	}

	destroyInputField() {
		if ( !( this.inputField instanceof InputField ) ) {
			return true;
		}
		if ( Validator.isFunction( this.inputField.destroySelfAndRestoreCell ) ) {
			return this.inputField.destroySelfAndRestoreCell();
		}
		if ( Validator.isFunction( this.inputField.destroySelf ) ) {
			return this.inputField.destroySelf();
		}
		return false;
	}

	destroyTextarea() {
		if ( !( this.textarea instanceof Textarea ) ) {
			return true;
		}
		if ( Validator.isFunction( this.textarea.destroySelfAndRestoreCell ) ) {
			return this.textarea.destroySelfAndRestoreCell();
		}
		if ( Validator.isFunction( this.textarea.destroySelf ) ) {
			return this.textarea.destroySelf();
		}
		return false;
	}

	restoreContentSpan() {
		if ( this.isLogicDataType ) {
			if ( !( this.checkbox instanceof Checkbox ) ) {
				return false;
			}
			return this.checkbox.resetInput();
		}
		if ( !( this.alnElm instanceof HTMLElement ) ||
			!( this.cttElm instanceof HTMLElement ) ) {
			return false;
		}
		this.alnElm.appendChild( this.cttElm );
		return true;
	}

	getNeighbourEditableCell( getNextCell = true ) {
		if ( !Validator.isObjectPath( this.row, "row.cells" ) ||
			!Validator.isFunction( this.row.cells.getItem ) ) {
			return void 0;
		}
		const orderedCellIds = this.row.orderedColumnIds;
		if ( !Validator.isArray( orderedCellIds ) ) {
			return void 0;
		}
		const curentCellOrderIndex =
			Validator.getIndexInArray( orderedCellIds, this.idc );
		if ( !Validator.isPositiveInteger( curentCellOrderIndex ) ) {
			return void 0;
		}
		const cells = this.row.cells;
		const directionProvider = !!getNextCell ? 1 : -1;
		let curentIndex = curentCellOrderIndex + directionProvider;
		let neighbourEditableCell = void 0;
		while ( curentIndex >= 0 && curentIndex < orderedCellIds.length ) {
			neighbourEditableCell =
				cells.getItem( orderedCellIds[ curentIndex ] );
			if ( Validator.isObject( neighbourEditableCell ) &&
				!neighbourEditableCell.isBlob &&
				!neighbourEditableCell.shouldBeSkipped ) {
				// we found the editable cell
				break;
			}
			curentIndex += directionProvider;
		}
		if ( Validator.isObject( neighbourEditableCell ) &&
			!neighbourEditableCell.shouldBeSkipped &&
			!neighbourEditableCell.isBlob ) {
			return neighbourEditableCell;
		}
		const neighbourRow = !!getNextCell ? this.rowBelow : this.rowAbove;
		if ( !Validator.isObject( neighbourRow ) ) {
			return void 0;
		}
		const editableCellInNeighbourRow = !!getNextCell ?
			neighbourRow.firstEditableVisibleInputCell :
			neighbourRow.lastEditableVisibleInputCell;
		if ( !Validator.isObject( editableCellInNeighbourRow ) ) {
			return void 0;
		}
		return !editableCellInNeighbourRow.isBlob &&
			!editableCellInNeighbourRow.shouldBeSkipped ?
			editableCellInNeighbourRow :
			editableCellInNeighbourRow.getNeighbourEditableCell( !!getNextCell );
	}

	get previousEditableCell() {
		return this.getNeighbourEditableCell( false );
	}

	get nextEditableCell() {
		return this.getNeighbourEditableCell( true );
	}

	getNeighbourModelItem( getNextItem = true ) {
		const flatModel = this.flatModel;
		if ( !Validator.isArray( flatModel ) ) {
			return void 0;
		}
		const flatIndex = this._flatIndex;
		if ( !Validator.isPositiveInteger( flatIndex ) ) {
			return void 0;
		}
		const potentialNeighbourModelItemIndex = !!getNextItem ?
			flatIndex + 1 : flatIndex - 1;
		if ( potentialNeighbourModelItemIndex >= flatModel.length ||
			potentialNeighbourModelItemIndex < 0 ) {
			return void 0;
		}
		const neighbourModelItem = flatModel[ potentialNeighbourModelItemIndex ];
		return Validator.isObject( neighbourModelItem ) ?
			neighbourModelItem : void 0;
	}

	get previousModelItem() {
		return this.getNeighbourModelItem( false );
	}

	get nextModelItem() {
		return this.getNeighbourModelItem( true );
	}

	getNeighbourRow( getNextRow = true ) {
		const neighbourModelItem = this.getNeighbourModelItem( !!getNextRow );
		if ( !Validator.isObject( neighbourModelItem ) ) {
			return void 0;
		}
		const neighbourRow = neighbourModelItem.row;
		return Validator.isObject( neighbourRow ) ? neighbourRow : void 0;
	}

	get rowAbove() {
		return this.getNeighbourRow( false );
	}

	get rowBelow() {
		return this.getNeighbourRow( true );
	}

	get cellBelow() {
		const nextRow = this.rowBelow;
		if ( !Validator.isObject( nextRow ) ||
			!Validator.is( nextRow.cells, "ObjReg" ) ) {
			// TODO if row is not rendered yet, scroll & render it
			return void 0;
		}
		const nextCell = nextRow.cells.getItem( this.idc );
		return Validator.isObject( nextCell ) ? nextCell : void 0;
	}

	get bodyClientRect() {
		const body = this.xtwBody;
		if ( !Validator.isObject( body ) || !( "clientRect" in body ) ) {
			return void 0;
		}
		return body.clientRect;
	}

	get fixedContainerClientRect() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "fixedContainerClientRect" in row ) ) {
			return void 0;
		}
		return row.fixedContainerClientRect;
	}

	get isInTheVisibleRange() {
		return !this.isHiddenOnTheLeft && !this.isHiddenOnTheRight;
	}

	get isHiddenOnTheRight() {
		if ( this.isHiddenUnderFixedContainer ) {
			return true;
		}
		const cellRect = this.clientRect;
		if ( !( cellRect instanceof DOMRect ) ) {
			return false;
		}
		const bodyRect = this.bodyClientRect;
		if ( !( bodyRect instanceof DOMRect ) ) {
			return false;
		}
		return bodyRect.x + bodyRect.width < cellRect.x + cellRect.width;
	}

	get isHiddenOnTheLeft() {
		if ( this.isHiddenUnderFixedContainer ) {
			return true;
		}
		const cellRect = this.clientRect;
		if ( !( cellRect instanceof DOMRect ) ) {
			return false;
		}
		const bodyRect = this.bodyClientRect;
		if ( !( bodyRect instanceof DOMRect ) ) {
			return false;
		}
		return cellRect.x < bodyRect.x;
	}

	get isHiddenUnderFixedContainer() {
		if ( this.fix ) {
			return false;
		}
		const cellRect = this.clientRect;
		if ( !( cellRect instanceof DOMRect ) ) {
			return false;
		}
		const fixedContainerClientRect = this.fixedContainerClientRect;
		if ( !( fixedContainerClientRect instanceof DOMRect ) ) {
			return false;
		}
		return fixedContainerClientRect.x + fixedContainerClientRect.width >
			cellRect.x;
	}

	onInputTab( domEvent ) {
		this.destroyInputField();
		this.destroyTextarea();
		this.restoreContentSpan();
		const isShiftEvent = DomEventHelper.isShiftEvent( domEvent );
		const closestPotentiallyEditableNeighbourCell = isShiftEvent ?
			this.previousEditableCell : this.nextEditableCell;
		if ( !Validator.isObject( closestPotentiallyEditableNeighbourCell ) ) {
			return !isShiftEvent ? false :
				this.onInputShiftTabInFirstCellInFirstRow( domEvent );
		}
		DomEventHelper.stopIf( domEvent );
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		let scrolledToCell = false;
		if ( closestPotentiallyEditableNeighbourCell.row.isTooLow ) {
			scrolledToCell = closestPotentiallyEditableNeighbourCell
				.row.scrollDownToShowSelf();
		}
		if ( closestPotentiallyEditableNeighbourCell.ensureCellIsInTheVisibleRange() ) {
			scrolledToCell = true;
		}
		if ( !scrolledToCell ) {
			return closestPotentiallyEditableNeighbourCell.enterEditingMode( domEvent );
		}
		const xtwBody = this.xtwBody;
		if ( !Validator.isFunctionPath( xtwBody,
				"xtwBody.setupAfterScrollViewUpdateCallback" ) ) {
			return false;
		}
		xtwBody.setupAfterScrollViewUpdateCallback( "onInputTab-", () => {
			if ( Validator.isFunction( xtwBody.forceSyncHorizontalScrollbar ) ) {
				xtwBody.forceSyncHorizontalScrollbar();
			}
			return closestPotentiallyEditableNeighbourCell.enterEditingMode( domEvent );
		} );
		return true;
	}

	onInputShiftTabInFirstCellInFirstRow( domEvent ) {
		// TODO THIS NEEDS REWORKING
		const row = this.row;
		if ( !Validator.isObject( row ) || !row.isFirstRenderedRow ||
			!Validator.isFunctionPath( row.tblBody, "tblBody.doAfterScrollingToModelItem" ) ) {
			return false;
		}
		const previousModelItem = this.previousModelItem;
		if ( !Validator.isObject( previousModelItem ) ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		if ( !row.tblBody.scrollAndClickModelItem( previousModelItem ) ) {
			return false;
		}
		const callback = () => {
			if ( !Validator.isObjectPath( previousModelItem, "previousModelItem.row" ) ) {
				return false;
			}
			if ( !previousModelItem.row.isRendered ) {
				return false;
			}
			const lastEditableVisibleInputCell =
				previousModelItem.row.lastEditableVisibleInputCell;
			if ( !Validator.isObject( lastEditableVisibleInputCell ) ) {
				return false;
			}
			// TODO redundant code
			const scrolledToCell =
				lastEditableVisibleInputCell.ensureCellIsInTheVisibleRange();
			if ( !scrolledToCell ) {
				return lastEditableVisibleInputCell.enterEditingMode( domEvent );
			}
			const xtwBody = previousModelItem.xtwBody;
			if ( !Validator.isFunctionPath( xtwBody,
					"xtwBody.setupAfterScrollViewUpdateCallback" ) ) {
				return false;
			}
			xtwBody.setupAfterScrollViewUpdateCallback(
				"onInputShiftTabInFirstCellInFirstRow-", () => {
					if ( Validator.isFunction( xtwBody.forceSyncHorizontalScrollbar ) ) {
						xtwBody.forceSyncHorizontalScrollbar();
					}
					return lastEditableVisibleInputCell.enterEditingMode( domEvent );
				} );
			return true;
		};
		row.tblBody.setupModelDataCallback(
			"onInputShiftTabInFirstCellInFirstRow-", callback );
		row.tblBody.setupEnsuingModelDataCallback(
			"onInputShiftTabInFirstCellInFirstRow-", callback );
		return true;
	}

	enterEditingMode( domEvent ) {
		if ( this.checkbox instanceof Checkbox ) {
			return this.focusCheckbox( domEvent );
		}
		return this.onCellContainerClick( domEvent );
	}

	ensureCellIsInTheVisibleRange() {
		if ( this.isInTheVisibleRange ) {
			return false;
		}
		const xtwBody = this.xtwBody;
		if ( !Validator.isFunctionPath( xtwBody, "xtwBody.xtwHead.scrollToColumn" ) ) {
			return false;
		}
		return xtwBody.xtwHead.scrollToColumn( this.column );
	}

	onInputEnter( domEvent ) {
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const sameCellInTheRowUnder = this.cellBelow;
		if ( !Validator.isObject( sameCellInTheRowUnder ) ) {
			return false;
		}
		this.destroyInputField();
		this.destroyTextarea();
		this.restoreContentSpan();
		if ( Validator.isObject( domEvent ) ) {
			domEvent.transferredFromNeighbour = true;
		}
		if ( sameCellInTheRowUnder.checkbox instanceof Checkbox ) {
			this.focusCell( domEvent );
			return sameCellInTheRowUnder.focusCheckbox( domEvent );
		}
		return sameCellInTheRowUnder.onCellContainerClick( domEvent );
	}

	onInputEscape( domEvent ) {
		this.destroyInputField();
		this.destroyTextarea();
		this.restoreContentSpan();
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( !this.isFocused ) {
			this.focusCell( domEvent );
		}
		if ( this.isRendered ) {
			this.element.tabIndex = 1;
			this.element.focus();
		}
		return true;
	}

}
