import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import DomEventHelper from '../../../../utils/DomEventHelper';
import XtwUtils from '../../util/XtwUtils';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import { DO_LOG } from './InputField';

export default class InputFieldEventManagingExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	onInputKeyDown( domEvent ) {
		Warner.traceIf( DO_LOG );
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.input.id;
		}
		if ( DomEventHelper.keyIs( domEvent, "Tab" ) ) {
			return this.onInputTab( domEvent );
		}
		if ( XtwUtils.isArrowLeft( domEvent ) ||
			XtwUtils.isArrowRight( domEvent ) ) {
			domEvent.stopPropagation();
			return true;
		}
		if ( DomEventHelper.keyIs( domEvent, "ArrowUp" ) ||
			DomEventHelper.keyIs( domEvent, "ArrowDown" ) ) {
			return this.onVerticalArrowKeyDown( domEvent );
		}
		//TODO replace with DomEventHelper.isSaveEvent( domEvent )
		if ( DomEventHelper.isCtrlEvent( domEvent ) && [ "s", "S" ].some(
				key => DomEventHelper.keyIs( domEvent, key ) ) ) {
			return this.saveAllAndKeepFocus( domEvent );
		}
		if ( XtwUtils.isContentChangingKey( domEvent ) ) {
			return this.handleContentChangingKeyDown( domEvent );
		}
	}

	onInputKeyUp( domEvent ) {
		Warner.traceIf( DO_LOG );
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.input.id;
		}
		if ( XtwUtils.keyIs( domEvent, "Enter" ) ) {
			return this.onInputEnter( domEvent );
		}
		if ( XtwUtils.keyIs( domEvent, "Escape" ) ) {
			return this.onInputEscape( domEvent );
		}
		if ( XtwUtils.isContentChangingKey( domEvent ) ) {
			return this.handleContentChangingKeyUp( domEvent );
		}
		if ( XtwUtils.keyIs( domEvent, "F2" ) ) {
			return this.handleMaximizeRequest( domEvent );
		}
		return true;
	}

	onInputKeyPress( domEvent ) {
		Warner.traceIf( DO_LOG );
		if ( this.isReadOnly ) {
			domEvent.preventDefault();
			return true;
		}
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.inputId = this.input.id;
		}
		if ( this.dirtyInsertionDummy ) {
			DomEventHelper.stopIf( domEvent );
			return true;
		}
		return true;
	}

	onInputContextMenu( domEvent ) {
		if ( domEvent instanceof Event ) {
			domEvent.inputId = this.input.id;
		}
	}

	onInputPaste( domEvent ) {
		const pastedContent = DomEventHelper.getClipboardDataTransferText( domEvent );
		if ( !Validator.isString( pastedContent ) ) {
			return false;
		}
		if ( this.allowedInsertionLength < pastedContent.length ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		} else {
			const self = this;
			setTimeout(() => {
				self.informAboutContentChange();
			}, 0);
		}
		return true;
	}

	onInputScroll( domEvent ) {
		this.informAboutContentChange();
		return this.destroySelfAndRestoreCell();
	}

	onButtonContainerClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return this.handleMaximizeRequest( domEvent );
	}

	handleContentChangingKeyUp( domEvent ) {
		// if ( !this.canBeEdited && domEvent instanceof KeyboardEvent ) {
		// 	domEvent.stopPropagation();
		// 	domEvent.preventDefault();
		// 	this.dirty = false;
		// 	return true;
		// }
		if ( DomEventHelper.isSelectAllEvent( domEvent ) ) {
			if ( this.input instanceof HTMLElement ) {
				this.input.select();
			}
			return true;
		}
		if ( DomEventHelper.isContentKeepingControlEvent( domEvent ) ) {
			return true;
		}
		if ( this.dirtyInsertionDummy ) {
			DomEventHelper.stopIf( domEvent );
			return true;
		}
		if ( this.dirty || this.originalValue == this.inputValue ) {
			return true;
		}
		this.informAboutEditing();
		this.dirty = true;
		return true;
	}

	handleContentChangingKeyDown( domEvent ) {
		if ( DomEventHelper.isContentKeepingControlEvent( domEvent ) ) {
			return true;
		}
		if ( this.dirtyInsertionDummy ) {
			return DomEventHelper.stopIf( domEvent );
		}
		if ( !this.canBeEdited || !this.editingAllowed ||
			this.eventSurpassesMaximumCharacterCount( domEvent ) ) {
			if ( domEvent instanceof Event ) {
				domEvent.preventDefault();
			}
			return false;
		}
		return true;
	}

	addBasicListeners( input ) {
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onInputKeyDown",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keyupListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onInputKeyUp",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const keypressListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keypress",
			functionName: "onInputKeyPress",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const contextmenuListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onInputContextMenu",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const pasteListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "paste",
			functionName: "onInputPaste",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const changeListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "change",
			functionName: "onInputChange",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const selectListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "select",
			functionName: "onInputSelect",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		const mousemoveListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousemove",
			functionName: "onInputMousemove",
			callBackPrefix: "Input",
			element: input,
			useCapture: false
		} );
		return keydownListenerSuccessfullyAdded && keyupListenerSuccessfullyAdded &&
			keypressListenerSuccessfullyAdded && contextmenuListenerSuccessfullyAdded &&
			pasteListenerSuccessfullyAdded && changeListenerSuccessfullyAdded &&
			selectListenerSuccessfullyAdded && mousemoveListenerSuccessfullyAdded;
	}

	addBlurListener() {
		const blurListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "blur",
			functionName: "onInputBlur",
			callBackPrefix: "Input",
			element: this.input,
			useCapture: false
		} );
		const focusoutListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "focusout",
			functionName: "onInputFocusout",
			callBackPrefix: "Input",
			element: this.input,
			useCapture: false
		} );
		if ( blurListenerSuccessfullyAdded && focusoutListenerSuccessfullyAdded ) {
			delete this.addBlurListener;
		}
		return blurListenerSuccessfullyAdded;
	}

	addMouseWheelListener() {
		const mousewheelListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "mousewheel",
				functionName: "onInputScroll",
				callBackPrefix: "Input",
				element: this.input,
				useCapture: false
			} );
		const wheelListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "wheel",
				functionName: "onInputScroll",
				callBackPrefix: "Input",
				element: this.input,
				useCapture: false
			} );
		const scrollListenerSuccessfullyAdded =
			EventListenerManager.addListener( {
				instance: this,
				eventName: "scroll",
				functionName: "onInputScroll",
				callBackPrefix: "Input",
				element: this.input,
				useCapture: false
			} );
		if ( mousewheelListenerSuccessfullyAdded && wheelListenerSuccessfullyAdded &&
			scrollListenerSuccessfullyAdded ) {
			delete this.addMouseWheelListener;
		}
		return mousewheelListenerSuccessfullyAdded &&
			wheelListenerSuccessfullyAdded && scrollListenerSuccessfullyAdded;
	}

}
