import Validator from './Validator';

export default class Color {

	constructor( { rgba, red, green, blue, alpha = 1, hex } ) {
		const colorArray = Color.cleanRgbaColorArray( rgba );
		if ( Validator.isArray( colorArray, 4 ) && this._define( {
				red: colorArray[ 0 ],
				green: colorArray[ 1 ],
				blue: colorArray[ 2 ],
				alpha: colorArray[ 3 ]
			} ) ) {
			delete this._defineAsPlainWhite;
			return;
		}
		if ( this._define( {
				red: red,
				green: green,
				blue: blue,
				alpha: alpha
			} ) ) {
			delete this._defineAsPlainWhite;
			return;
		}
		const rgbaArray = Color.hexToRgbaArray( hex, alpha );
		if ( Validator.isArray( rgbaArray, 4 ) && this._define( {
				red: rgbaArray[ 0 ],
				green: rgbaArray[ 1 ],
				blue: rgbaArray[ 2 ],
				alpha: rgbaArray[ 3 ]
			} ) ) {
			delete this._defineAsPlainWhite;
			return;
		}
		this._defineAsPlainWhite();
	}

	_defineAsPlainWhite() {
		this._define( {
			red: 255,
			green: 255,
			blue: 255,
			alpha: 1
		} );
		delete this._defineAsPlainWhite;
	}

	_define( { red, green, blue, alpha } ) {
		if ( [ red, green, blue ].some( light =>
				!Validator.isPositiveInteger( light ) || light > 255 ) ) {
			return false;
		}
		alpha = Color.refineAlphaOpacity( alpha );
		Object.defineProperties( this, {
			red: {
				value: red,
				configurable: false,
				writable: false
			},
			green: {
				value: green,
				configurable: false,
				writable: false
			},
			blue: {
				value: blue,
				configurable: false,
				writable: false
			},
			alpha: {
				value: alpha,
				configurable: false,
				writable: false
			}
		} );
		delete this._define;
		return true;
	}

	toArray() {
		return [ this.red, this.green, this.blue, this.alpha ];
	}

	stringify() {
		return `rgba(${ this.red },${ this.green },${ this.blue },${ this.alpha })`;
	}

	hexify() {
		return `#${ Color._getHexValue( this.red ) }` +
			`${ Color._getHexValue( this.green ) }` +
			`${ Color._getHexValue( this.blue ) }`;
	}

	equals( color ) {
		return color instanceof Color && [ "red", "green", "blue", "alpha" ]
			.every( property => this[ property ] === color[ property ] );
	}

	static fromHex( hex, alphaOpacity ) {
		return new Color( { hex: hex, alpha: alphaOpacity } );
	}

	static stringify( rgbaArray ) {
		const color = Color.fromRgba( rgbaArray );
		return color.stringify();
	}

	static fromRgba( rgbaArray ) {
		return new Color( { rgba: rgbaArray } );
	}

	static hexToRgba( hex, alphaOpacity ) {
		const color = Color.fromHex( hex, alphaOpacity );
		return color.stringify();
	}

	static rgbaToHex( rgbaArray ) {
		const color = Color.fromRgba( rgbaArray );
		return color.hexify();
	}

	static cleanRgbaColorArray( sourceArray ) {
		if ( !Validator.isArray( sourceArray ) ) {
			return void 0;
		}
		const redGreenBlueAlpha = [ ...sourceArray ]
			.filter( light => Validator.isPositiveInteger( light ) && light <= 255 )
			.slice( 0, 4 );
		if ( redGreenBlueAlpha.length == 3 ) {
			return redGreenBlueAlpha.concat( 1 );
		}
		if ( redGreenBlueAlpha.length != 4 ) {
			return void 0;
		}
		const alphaOpacity = Color.refineAlphaOpacity( redGreenBlueAlpha[ 3 ] );
		redGreenBlueAlpha[ 3 ] = alphaOpacity;
		return redGreenBlueAlpha;
	}

	static refineAlphaOpacity( alpha ) {
		if ( Validator.isString( alpha ) ) {
			alpha = Number( alpha );
		}
		if ( !Validator.isPositiveNumber( alpha ) ) {
			alpha = 0;
		} else if ( alpha > 255 ) {
			alpha = 1;
		} else if ( alpha > 1 && alpha <= 255 ) {
			alpha = alpha / 255;
		}
		alpha = Number( alpha.toFixed( 1 ) );
		return alpha;
	}

	static hexadecimalToColorLight( hexadecimalValue ) {
		if ( !Validator.isString( hexadecimalValue ) ) {
			return void 0;
		}
		const colorLight = parseInt( hexadecimalValue, 16 );
		if ( !Validator.isPositiveInteger( colorLight ) || colorLight > 255 ) {
			return void 0;
		}
		return colorLight;
	}

	static extractHexValue( hexString ) {
		if ( !Validator.isString( hexString ) ) {
			return void 0;
		}
		hexString = hexString.replaceAll( /[^a-fA-F0-9_]+/gi, "" ).toUpperCase();
		if ( hexString.length < 3 ) {
			return void 0;
		}
		if ( hexString.length > 6 ) {
			hexString = hexString.substring( 0, 6 );
		}
		if ( hexString.length === 6 ) {
			return hexString;
		}
		hexString = hexString.substring( 0, 3 );
		const extendedHexValue = hexString.charAt( 0 ) + hexString.charAt( 0 ) +
			hexString.charAt( 1 ) + hexString.charAt( 1 ) + hexString.charAt( 2 ) +
			hexString.charAt( 2 );
		return extendedHexValue;
	}

	static hexToRgbaArray( hex, alpha ) {
		const hexValue = Color.extractHexValue( hex );
		if ( !Validator.isString( hexValue ) ) {
			return void 0;
		}
		const red = Color.hexadecimalToColorLight( hexValue.substring( 0, 2 ) );
		if ( !Validator.isPositiveInteger( red ) ) {
			return void 0;
		}
		const green = Color.hexadecimalToColorLight( hexValue.substring( 2, 4 ) );
		if ( !Validator.isPositiveInteger( green ) ) {
			return void 0;
		}
		const blue = Color.hexadecimalToColorLight( hexValue.substring( 4, 6 ) );
		if ( !Validator.isPositiveInteger( blue ) ) {
			return void 0;
		}
		alpha = Color.refineAlphaOpacity( alpha );
		return [ red, green, blue, alpha ];
	}

	blendWith( otherColor, otherColorAmount = 0.5 ) {
		if ( !( otherColor instanceof Color ) ) {
			return void 0;
		}
		if ( !Validator.isPositiveNumber( otherColorAmount ) ||
			otherColorAmount > 1 ) {
			otherColorAmount = 0.5;
		}
		otherColorAmount = Number( otherColorAmount.toFixed( 1 ) );
		const difference = this._getDifferenceTo( otherColor );
		const red = Math.round( this.red + difference.red * otherColorAmount );
		const green = Math.round( this.green + difference.green * otherColorAmount );
		const blue = Math.round( this.blue + difference.blue * otherColorAmount );
		const alpha = Color.refineAlphaOpacity( this.alpha * ( 1 - otherColorAmount ) +
			otherColor.alpha * otherColorAmount ); // this line(s) is (are) surely wrong
		return new Color( {
			red: red,
			green: green,
			blue: blue,
			alpha: alpha
		} );
	}

	adjustSelf( adjustment ) {
		return Color.getAdjustedColor( this, adjustment );
	}

	static getBlendedColor( firstColor, secondColor, secondColorAmount ) {
		if ( !( firstColor instanceof Color ) ) {
			return void 0;
		}
		return firstColor.blendWith( secondColor, secondColorAmount );
	}

	static getAdjustedColor( color, adjustment ) {
		if ( !( color instanceof Color ) || !Validator.isObject( adjustment ) ) {
			return void 0;
		}
		let red = color.red + adjustment.red;
		if ( !Validator.isPositiveInteger( red ) || red > 255 ) {
			red = 255;
		}
		let green = color.green + adjustment.green;
		if ( !Validator.isPositiveInteger( green ) || green > 255 ) {
			green = 255;
		}
		let blue = color.blue + adjustment.blue;
		if ( !Validator.isPositiveInteger( blue ) || blue > 255 ) {
			blue = 255;
		}
		const alpha = Color.refineAlphaOpacity( color.alpha + adjustment.alpha ); // this line is surely wrong
		return new Color( {
			red: red,
			green: green,
			blue: blue,
			alpha: alpha
		} );
	}

	_getDifferenceTo( otherColor ) {
		if ( !( otherColor instanceof Color ) ) {
			return void 0;
		}
		const differences = {};
		[ "red", "green", "blue", "alpha" ].forEach( value => {
			const difference = otherColor[ value ] - this[ value ];
			differences[ value ] = difference;
		} );
		return differences;
	}

	static _getHexValue( light ) {
		if ( !Validator.isPositiveInteger( light ) || light > 255 ) {
			return void 0;
		}
		let hex = light.toString( 16 );
		if ( hex.length < 2 ) {
			hex = "0" + hex;
		}
		return hex.toUpperCase();
	}

}

console.log( 'utils/Color.js loaded.' );
