import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import ObjReg from '../../../utils/ObjReg';
import MBase from './MBase';
import MGroup from './MGroup';

/** the built-in identifier for the default group - keep in sync. with "XtdModel.java"! */
const DEFAULT_DSC = 'XTDGRP_DEFAULT_79B944AA3F7844D29F717D18D00FA7AD';
/** an empty object */
const EMPTY_OBJ = {};

/** an empty array */
const EMPTY_ARR = [];

const DO_LOG = false;



/**
 * eXtended Table Widget Data Model class
 */
export default class XtwModel extends MBase {

	/**
	 * constructs a new instance
	 * @param {XtwBody} xtb the associated table widget's body
	 * @param {Number} dgh default group height
	 * @param {Number} drh default row height
	 */
	constructor( xtb, dgh, drh ) {
		super();
		this.tblBody = xtb;
		this.flatModel = [];
		this.defGrh = dgh;
		this.defRwh = drh;
		this.groups = new ObjReg();
		this.items = new Map();
		this.defGrp = new MGroup( { idg: 0, dsc: DEFAULT_DSC, height: 0 }, 0, this.defRwh, this );
	}

	/**
	 * @override
	 */
	doDestroy() {
		this.clear();
		this.groups.destroy();
		this.defGrp.destroy();
		delete this.defGrh;
		delete this.defRwh;
		delete this.groups;
		delete this.defGrp;
		delete this.flatModel;
		delete this.tblBody;
		super.doDestroy();
	}

	/**
	 * drops all current data
	 */
	clear() {
		this.nullifyDummyModelItem();
		this.items.clear();
		this.groups.dstChl();
		this.defGrp.clear();
		if ( this.flatModel.length > 0 ) {
			this.flatModel = [];
		}
	}

	nullifyDummyModelItem() {
		let atLeastSomethingNullified = false;
		if ( this.defGrp instanceof MGroup ) {
			this.defGrp.nullifyDummyModelItem();
			atLeastSomethingNullified = true;
		}
		if ( !( this.groups instanceof ObjReg ) ) {
			return atLeastSomethingNullified;
		}
		this.groups.forEach( group => {
			if ( !( group instanceof MGroup ) ) {
				return;
			}
			atLeastSomethingNullified = true;
			group.nullifyDummyModelItem();
		} );
		return atLeastSomethingNullified;
	}

	/**
	 * returns the total number of items in the flat model
	 * @returns {Number} the total number of items in the flat model
	 */
	getItemCount() {
		return this.flatModel.length;
	}

	/**
	 * retrieves a model item by index
	 * @param {Number} tix current top index
	 * @param {Number} idx item index
	 * @returns {MRowItem} the model item or null if the index is out of bounds
	 */
	getModelItem( tix, idx ) {
		const fm = this.flatModel;
		if ( ( idx >= 0 ) && ( idx < fm.length ) ) {
			return fm[ idx ];
		} else {
			return null;
		}
	}

	/**
	 * retrieves a group
	 * @param {String} dsc group descriptor (group name)
	 * @returns {MGroup} the matching group or null if there's no such group
	 */
	getGroup(dsc) {
		return this.groups.getObj( dsc );
	}

	get dummyModelItem() {
		if ( !( this.defGrp instanceof MGroup ) ) {
			return void 0;
		}
		return this.defGrp.dummyModelItem;
	}

	/**
	 * calculates the page scroll distance
	 * @param {Boolean} up direction flag
	 * @param {Number} tix current top index
	 * @param {Number} cnt number of visible row items
	 * @returns {Number} the page scroll distance in pixels
	 */
	getPgScrDist( up, tix, cnt ) {
		const fm = this.flatModel;
		const lim = fm.length;
		let dist = 0;
		if ( ( lim > 0 ) && ( tix >= 0 ) && ( cnt > 0 ) ) {
			let idx = Math.min( tix, lim - 1 );
			if ( up && ( tix < cnt ) ) {
				idx = Math.min( tix + 1, lim - 1 );
			}
			let i = 0;
			if ( up ) {
				while ( ( i < cnt ) && ( idx >= 0 ) ) {
					dist += fm[ idx ].getHeight();
					++i;
					--idx;
				}
			} else {
				while ( ( i < cnt ) && ( idx < lim ) ) {
					dist += fm[ idx ].getHeight();
					++i;
					++idx;
				}
			}
		}
		return dist;
	}

	/**
	 * retrieves the index of the row item at the specified vertical scroll position
	 * @param {Number} vsp the vertical scroll position
	 * @param {Number} cti current top index
	 * @param {Boolean} up scrolling direction
	 * @returns {Number} the index of the top item or -1 if there's no such item
	 */
	getTopIdx( vsp, cti, up ) {
		// some pre-conditions and checks for both ends
		const fi = this.flatModel;
		if ( !fi.length ) {
			return -1;
		}
		if ( vsp <= 0 ) {
			return 0;
		}
		const last = fi.length - 1;
		if ( last === 0 ) {
			return 0;
		}
		let tix = vsp;
		if ( ( tix !== -1 ) && ( tix !== last ) && ( tix === cti ) ) {
			if ( up ) {
				tix = Math.max( cti - 1, 0 );
			} else {
				tix = Math.min( cti + 1, last );
			}
		}
		return tix;
	}

	/**
	 * called by the web server if the data model has been committed
	 * @param {Object} json new data model
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @returns {Number} the total height in pixels of all items
	 */
	modelCommitted( json, rtp, rh, vpad ) {
		Warner.traceIf( DO_LOG );
		// console.log( [ ...this.items.values() ] );
		// drop current data
		this.clear();
		// fill model
		const self = this;
		const model = json.model || EMPTY_ARR;
		model.forEach( ( md ) => {
			self._createModelItem( md );
		} );
		// process model UI changes
		return this.modelGroupExpanded( rtp, rh, vpad );
	}

	/**
	 * called if a group was expanded or collapsed
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @returns {Number} the total height in pixels of all items
	 */
	modelGroupExpanded( rtp, rh, vpad ) {
		const vp = rtp ? vpad : 0;
		return this._updateFlatModel( rtp, rh, vp );
	}

	/**
	 * processes model data sent by the web server
	 * @param {Array} items array of data items
	 */
	modelData( items ) {
		Warner.traceIf( DO_LOG );
		// console.log( [ ...this.items.values() ] );
		const self = this;
		( items || EMPTY_ARR ).forEach( ( item ) => {
			self._updateModelData( item );
		} );
	}

	/**
	 * sets the rows to be updated
	 * @param {Array<Number>} rows an array providing the IDs of the rows to be updated
	 */
	modelUpdate( rows ) {
		Warner.traceIf( DO_LOG );
		const items = this.items;
		if ( items.size === rows.length ) {
			// *all* model items!
			items.forEach( ( mi ) => {
				mi.invalidate();
			} );
		} else {
			rows.forEach( ( idr ) => {
				const mi = items.get( idr ) || null;
				if ( mi ) {
					mi.invalidate();
				}
			} );
		}
	}

	/**
	 * called if the view mode was changed (classic view vs. Row Template)
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad total vertical padding in pixels
	 * @returns {Number} the total height in pixels of all items
	 */
	viewModeChanged( rtp, rh, vpad ) {
		Warner.traceIf( DO_LOG );
		const fm = this.flatModel;
		if ( !fm.length ) {
			return 0;
		}
		const ovh = rtp ? rh : null;
		const vp = rtp ? vpad : 0;
		let top = 0;
		fm.forEach( ( mi ) => {
			mi.setOvrHeight( ovh, vp );
			mi.setTop( top );
			top += mi.getHeight() + vp;
		} );
		return top;
	}

	/**
	 * updates a model item
	 * @param {Object} item data item as sent by the web server
	 */
	_updateModelData( item ) {
		const di = item.ri || EMPTY_OBJ;
		const idr = item.idr || 0;
		if ( idr <= 0 || idr !== di.idr ) {
			Warner._traceIf( `XtwModel#_updateModelData - invalid JSON data!` +
				` Different ID values "${ idr }" !== "${ di.idr }".`, DO_LOG );
			return;
		}
		const mi = this.items.get( idr );
		if ( !mi ) {
			// Warner._traceIf( `XtwModel#_updateModelData - could not find any` +
			// 	` model item with the <idr> with a value of "${ idr }".`, DO_LOG );
			return;
		}
		mi.setData( di );
	}

	/**
	 * returns an information which data items must be fetched from web server
	 * @param {Number} tix top / starting index
	 * @param {Number} cnt number of items to be fetched
	 */
	fetchData( tix, cnt ) {
		const rqu_lst = [];
		if ( tix < 0 || cnt <= 0 ) {
			Warner._traceIf( `XtwModel#fetchData - an invalid requested list of` +
				` items to be fetched will be returned, because of an invalid` +
				` top/starting index (with a value of "${ tix }") or beacause of` +
				` an invalid number of items to be fetched (with a value of` +
				` "${ cnt }").`, DO_LOG );
			// console.log( [ ...this.items.values() ] );
			return rqu_lst.length > 0 ? rqu_lst : null;
		}
		Warner.traceIf( DO_LOG );
		// console.log( [ ...this.items.values() ] );
		const fm = this.flatModel;
		const lim = fm.length;
		let idx = tix;
		for ( let i = 0;
			( i < cnt ) && ( idx < lim ); ++i, ++idx ) {
			const mi = fm[ idx ];
			if ( !mi.isPresent() ) {
				rqu_lst.push( mi.getRowID() );
			}
		}
		if ( ( rqu_lst.length > 0 ) && ( ( rqu_lst.length < cnt ) || ( tix === 0 ) ) ) {
			// fill it up!
			const max_cnt = 2 * cnt;
			idx = tix + rqu_lst.length;
			for ( let hit = rqu_lst.length;
				( hit < max_cnt ) && ( idx < lim ); ++idx ) {
				const mi = fm[ idx ];
				if ( !mi.isPresent() ) {
					rqu_lst.push( mi.getRowID() );
					++hit;
				}
			}
		}
		return ( rqu_lst.length > 0 ) ? rqu_lst : null;
	}

	/**
	 * creates a model item from data sent by the web server
	 * @param {Object} md the model item data to be processed
	 * @returns {MRowItem} the model item
	 */
	_createModelItem( md ) {
		const type = !!md.type;
		const mi = type ? this._creGrp( md ) : this._getGrp( md ).addRow( md );
		this.items.set( mi.getRowID(), mi );
		return mi;
	}

	/**
	 * updates the current flat model
	 * @param {Boolean} rtp Row Template mode flag
	 * @param {Number} rh default row height
	 * @param {Number} vpad vertical padding in pixels
	 * @returns {Number} the height in pixels of the created row item
	 */
	_updateFlatModel( rtp, rh, vpad ) {
		Warner.traceIf( DO_LOG );
		let top = 0;
		this.flatModel = [];
		const fm = this.flatModel;
		const ovh = rtp ? rh : null;
		const vp = rtp ? vpad : 0;
		if ( this.defGrp ) {
			top += this.defGrp.addToFlat( fm, top, ovh, vp );
		}
		if ( this.groups && !this.groups.isEmpty() ) {
			this.groups.forEach( ( g ) => {
				top += g.addToFlat( fm, top, ovh, vp );
			} );
		}
		return top;
	}

	/**
	 * retrieves the group of an item
	 * @param {Object} mi model item
	 * @returns {MGroup} the target group
	 */
	_getGrp( mi ) {
		const idg = mi.idg || 0;
		const dsc = mi.dsc || '';
		if ( idg === 0 || DEFAULT_DSC === dsc ) {
			return this.defGrp;
		}
		const grp = this.groups.getObj( dsc );
		return grp ? grp : this.defGrp;
	}

	/**
	 * creates a new group
	 * @param {Object} md the model item data to be processed
	 * @returns {MGroup} new created group
	 */
	_creGrp( md ) {
		const idg = md.idg || 0;
		const dsc = md.dsc || '';
		if ( idg === 0 || DEFAULT_DSC === dsc || this.groups.hasObj( dsc ) ) {
			throw new Error( 'Invalid group descriptor "' + dsc + '" (id=' + idg + ')!' );
		}
		const grp = new MGroup( md, this.defGrh, this.defRwh, this );
		this.groups.addObj( dsc, grp );
		return grp;
	}

	getDataRowModelItem( idr ) {
		if ( !Validator.isPositiveInteger( idr ) ) {
			Warner.traceIf( DO_LOG, `A model item with an invalid <idr> can not` +
				` be found.` );
			return void 0;
		}
		const modelItem = this.items.get( idr );
		if ( !Validator.is( modelItem, "MDataRow" ) ) {
			Warner.traceIf( DO_LOG, `The model item with the idr "${ idr }" is` +
				` not of prototype <MDataRow>.` );
			return void 0;
		}
		return modelItem;
	}

	_syncSetModelDataRowSelection( { idr, updateUi = true, select = true } ) {
		const modelItem = this.getDataRowModelItem( idr );
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		return !!select ? modelItem.syncSelect( updateUi ) :
			modelItem.syncDeselect( updateUi );
	}

	selectDataRow( idr, updateUi = true ) {
		return this._syncSetModelDataRowSelection( {
			idr: idr,
			updateUi: updateUi,
			select: true
		} );
	}

	deselectDataRow( idr, updateUi = true ) {
		return this._syncSetModelDataRowSelection( {
			idr: idr,
			updateUi: updateUi,
			select: false
		} );
	}

	_syncSetModelDataRowFocus( { idr, focus = true } ) {
		const modelItem = this.getDataRowModelItem( idr );
		if ( !Validator.isObject( modelItem ) ) {
			return false;
		}
		return !!focus ? modelItem.syncFocus() : modelItem.syncUnfocus();
	}

	focusDataRow( idr ) {
		return this._syncSetModelDataRowFocus( { idr: idr, focus: true } );
	}

	unfocusDataRow( idr ) {
		return this._syncSetModelDataRowFocus( { idr: idr, focus: false } );
	}
}
