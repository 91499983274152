import DomEventHelper from '../../../../utils/DomEventHelper';
import DropdownArrowExtension from './DropdownArrowExtension';
import EventListenerManager from '../../../../utils/EventListenerManager';
import InputField from './InputField';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import XtwUtils from '../../util/XtwUtils';

const INPUT_DROPDOWN_EVENT = "dropdown";
const INPUT_DROPDOWN_EVENT_CLOSE = "dropdownClose";
const DROPDOWN_ARROW_WIDTH = 14;
export default class DropdownInputField extends InputField {

	constructor( cellObject ) {
		super( cellObject );
		const column = cellObject.column || {};
		const align = column.dataAlign || '';
		const right = 'right' !== align;	// the dropdown is an the right edge if the content is *not* right aligned!
		new DropdownArrowExtension( this );
		this.dropdownClosedByArrow = false;
		this._dropdownOpen = false;
		// store positioning flag
		this._rightegde = !!right;
	}

	get isOnRightEdge() {
		return this._rightegde;
	}

	get dropdownOpen() {
		return !!this._dropdownOpen;
	}

	set dropdownOpen( newValue ) {
		this._dropdownOpen = !!newValue;
	}

	get isDummyRow() {
		const row = this.row;
		if ( !Validator.isObjectPath( row, "row.item" ) ) {
			return false;
		}
		return !!row.item._dummy;
	}

	render() {
		super.render();
		this.dropdownArrow = this.newArrow;
		this.container.appendChild( this.dropdownArrow );
		if ( this.input && !this.isOnRightEdge ) {
			// this.input.style.left = `${DROPDOWN_ARROW_WIDTH}px`;
		}
		return true;
	}

	discardUi() {
		const arrowDiscarded = this.discardArrow();
		return super.discardUi() && arrowDiscarded;
	}

	discardArrow() {
		[ "mousedown", "mouseup", "click" ].forEach( eventName => {
			EventListenerManager
				.removeListener( this, eventName, this.dropdownArrow, "DropdownArrow" );
		} );
		return this._discardElementProperty( "dropdownArrow" );
	}

	onDropdownArrowClick( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		return this.dropdownOpen ?
			this.closeDropdown( domEvent ) : this.openDropdown( domEvent );
	}

	onInputBlur( domEvent ) {
		if ( this.isRelatedTargetCancelButton( domEvent ) ) {
			return this.destroySelfAndRestoreCell();
		}
		if ( this.dropdownOpen ) {
			Warner.traceIf( true, `The input will not be destroyed on blur,` +
				` because the input dropdown is active.` );
			return false;
		}
		return super.onInputBlur( domEvent );
	}

	onInputEnter( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputEnter( domEvent );
	}

	onInputTab( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputTab( domEvent );
	}

	onInputEscape( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		return super.onInputEscape( domEvent );
	}

	onVerticalArrowKeyDown( domEvent ) {
		if ( this.dropdownOpen ) {
			return false;
		}
		if ( DomEventHelper.keyIs( domEvent, "ArrowDown" ) && domEvent.altKey ) {
			return this.openDropdown( domEvent );
		}
		return super.onVerticalArrowKeyDown( domEvent );
	}

	isRelatedTargetCancelButton( domEvent ) {
		if ( !( domEvent instanceof Event ) ||
			!( domEvent.relatedTarget instanceof HTMLDivElement ) ) {
			return false;
		}
		const iconContainerChildren = HtmHelper
			.getAllLevelChildren( domEvent.relatedTarget ).filter( element =>
				element instanceof HTMLDivElement &&
				element.classList.contains( "icoDscDiv" ) );
		if ( !Validator.isArray( iconContainerChildren, 1 ) ) {
			return false;
		}
		const icons = HtmHelper.getAllLevelChildren( iconContainerChildren[ 0 ] );
		if ( !Validator.isArray( icons, 1 ) ) {
			return false;
		}
		return icons[ 0 ].tagName == "I" && [ "fa", "fa-close" ]
			.every( className => icons[ 0 ].classList.contains( className ) );
	}

	setDropdownOpenState( open ) {
		if ( !open && !this.dropdownClosedByArrow &&
			window.document.activeElement != this.input ) {
			this.dropdownOpen = false;
			return this.onInputBlur();
		}
		this.dropdownClosedByArrow = false;
		if ( !open ) {
			this.input.focus();
		}
		this.dropdownOpen = !!open;
		this.syncContainerWithOpenState();
		return this.syncArrowDirectionWithOpenState();
	}

	syncContainerWithOpenState() {
		if ( !( this.container instanceof HTMLElement ) ) {
			return false;
		}
		this.dropdownOpen ? this.container.classList.add( "rtp-dropdown-open" ) :
			this.container.classList.remove( "rtp-dropdown-open" );
		return true;
	}

	syncArrowDirectionWithOpenState() {
		this.dropdownOpen ? this.pointArrowUp( this.dropdownArrow ) :
			this.pointArrowDown( this.dropdownArrow );
	}

	closeDropdown( domEvent ) {
		this.dropdownClosedByArrow = true;
		// this.dropdownOpen = false;
		// this.pointArrowDown( this.dropdownArrow );
		this.input.focus();
		return this.informAboutDropdownClose();
	}

	openDropdown( domEvent ) {
		if ( this.isDummyRow && !this.insertionDummy ) {
			return false;
		}
		this.dropdownClosedByArrow = false;
		// this.pointArrowUp( this.dropdownArrow );
		const cellElement = Validator.isObject( this.cell ) ?
			this.cell.element : void 0;
		const parameters = XtwUtils.getCoordinateParameters( domEvent, cellElement );
		return this.informAboutDropdown( parameters );
	}

	informAboutDropdown( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput(
			INPUT_DROPDOWN_EVENT, parameters, !!blockScreenRequest );
	}

	informAboutDropdownClose( parameters = {}, blockScreenRequest = false ) {
		return this.informAboutInput(
			INPUT_DROPDOWN_EVENT_CLOSE, parameters, !!blockScreenRequest );
	}

}
