import PSA from '../../../psa';
import Checkbox from '../impl/editing/Checkbox';
import TripleStateCheckbox from '../impl/editing/TripleStateCheckbox';
import Color from '../../../utils/Color';
import HtmHelper from '../../../utils/HtmHelper';
import ItmMgr from '../../../gui/ItmMgr';
import Validator from '../../../utils/Validator';
import XItem from './XItem';
import XtwCol from './XtwCol';
import XRowItem from './XRowItem';
import XtwTooltipListenerExtension from '../util/XtwTooltipListenerExtension';
import EditableElement from '../impl/editing/EditableElement';
import XtwCellEditingExtension from '../impl/editing/XtwCellEditingExtension';
import XtwSelectionCellInsertionDummyExtension from '../impl/editing/XtwSelectionCellInsertionDummyExtension';
import SelectionCellRowHeightExtension from '../impl/rowheight/SelectionCellRowHeightExtension';
import XtwUtils from '../util/XtwUtils';
import { CELL_EDITING_ENABLED } from '../XtwBody';
import CellCtt from '../model/CellCtt';

export const OWN_BACKGROUND_COLOR = "--rtp-own-background-color";
export const ITEM_SPECIFIC_TEXT_COLOR = "--rtp-item-specific-text-color";
export const ITEM_SPECIFIC_BACKGORUND_COLOR = "--rtp-item-specific-backgorund-color";
export const ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR = "--rtp-item-specific-alternative-background-color";

/**
 * a cell item in the UI
 */
export default class XCellItem extends XItem {

	/**
	 * constructs a new instance
	 * @param {XtwCol} col the column
	 * @param {XRowItem} row the row
	 * @param {*} brc boder color
	 */
	constructor( col, row, brc ) {
		super();
		this.idc = col.id;
		this.column = col;
		this.row = row;
		this.select = !!this.column.select;
		this.defBrc = brc || null;
		this.ctt = CellCtt.EMPTY_CONTENT;
		// this._onTooltipTimer = window.pisasales.bind( this, this.onTooltipTimer );
		this._onTooltipTimer = PSA.getInst().bind( this, this.onTooltipTimer );
		new XtwTooltipListenerExtension( this );
		new XtwCellEditingExtension( this );
		if ( this.isLogicDataType ) {
			this.isWritable ? new TripleStateCheckbox( this ) : new Checkbox( this );
		}
		const elm = this.getDomElement();
		elm.className = 'xtwcell';
		if ( this.select ) {
			this._iniSelectCll( elm );
			new XtwSelectionCellInsertionDummyExtension( this );
			new SelectionCellRowHeightExtension( this );
		} else {
			this._initDataCell( elm );
		}
		if ( col.link ) {
			elm.classList.add( "rtp-link" );
		}
		elm.__psaidc = this.idc;
		this.addListeners( elm );
		this.cellwdt = 0;
		this.cellFont = null;
		this.setWidth( col.getWidth() );
	}

	/**
	 * destructor method
	 * @override
	 */
	destroy() {
		if ( Validator.isFunction( this.destroyHeightAdjustmentDiv ) ) {
			this.destroyHeightAdjustmentDiv();
		}
		this.removeCellContentListener();
		this.removeContextMenuListener();
		this.removeTooltipListeners();
		delete this.ctt;
		delete this.defBrc;
		delete this.select;
		delete this.idc;
		delete this.row;
		delete this.cellwdt;
		delete this.cellFont;
		delete this.column;
		delete this.cttElm;
		delete this.alnElm;
		super.destroy();
	}

	get _im() {
		return ItmMgr.getInst();
	}

	/**
	 * adds necessary listeners to the HTM element
	 * @param {HTMLElement} element the element to whom the listener is added
	 */
	addListeners( element ) {
		this.addTooltipListeners( element );
		return this.addContextMenuListener( element );
		// return this.addClickListener( element );
	}

	/**
	 * gets if this cell is of type "link" or not, which is directly dependent
	 * on whether or not the column (XtwCol) corresponding/assigned to this cell
	 * is of type "link" or not
	 * @return {Boolean} true if the cell is of type link, false otherwise
	 */
	get link() {
		return Validator.is( this.column, "XtwCol" ) && !!this.column.link;
	}

	/**
	 * gets if this cell is fixed or not, which is directly dependent on whether
	 * or not the column (XtwCol) corresponding/assigned to this cell is fixed
	 * or not
	 * @return true if the cell is fixed, false if it is not and "undefined" if
	 * there is no column
	 */
	get fix() {
		return Validator.is( this.column, "XtwCol" ) ? !!this.column.fix : void 0;
	}

	/**
	 * sends a notification to the web server through the row item (XRowItem)
	 * corresponding/hosting this item, if the row item (XRowItem) exists, is
	 * valid and is able to send notifications
	 * @param {String} notificationCode notification code
	 * @param {Object} parameters notification parameters
	 * @param {Boolean} blockScreenRequest flag whether to force a block screen
	 * request
	 * @return {Boolean} the success of the operation; true if the operation
	 * was successfull and "_nfySrv" could be invoked, false otherwise
	 * @see XtwBody.js~XRowItem~_nfySrv
	 */
	_nfySrv( notificationCode, parameters = {}, blockScreenRequest = false ) {
		if ( !Validator.isString( notificationCode ) ||
			!Validator.is( this.row, "XRowItem" ) ||
			!Validator.isFunction( this.row._nfySrv ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		Object.assign( parameters, { idc: this.idc } );
		return this.row
			._nfySrv( notificationCode, parameters, !!blockScreenRequest );
	}

	/**
	 * reacts to "click" mouse events
	 * @param {MouseEvent} evt the "click" mouse event
	 * @param {Object} parameters handling parameters, in case this was invoked
	 * by another method instead of by a DOM listener
	 */
	onClick( evt, parameters = void 0 ) {}

	/**
	 * reacts to "click" mouse events if this cell if of type "link"
	 * @param {MouseEvent} evt the "click" mouse event
	 */
	onLinkClick( evt ) {
		if ( evt instanceof MouseEvent ) {
			evt.stopPropagation();
			evt.preventDefault();
		}
		return this._nfySrv( "linkClicked" );
	}

	onTooltipTimer() {
		const parameters = this.getCoordinateParameters(
			this.lastTooltipEvent, this.element );
		if ( [ this.checkbox, this.inputField, this.textarea ].every( parameter =>
				!( parameter instanceof EditableElement ) ) ) {
			this._nfySrv( "tooltipRequest", parameters );
		}
		this.clearTooltipTimer();
	}

	addContextMenuListener() {
		return this.addListener( "contextmenu", "onContextMenu" );
	}

	removeContextMenuListener() {
		return this.removeListener( "contextmenu" );
	}

	onContextMenu( evt ) {
		if ( Validator.isObject( evt ) && Validator.isString( evt.inputId ) ) {
			return;
		}
		this.selectAndFocusRow( evt );
		if ( evt instanceof MouseEvent ) {
			evt.stopPropagation();
			evt.preventDefault();
		}
		const parameters = this.getCoordinateParameters(
			evt, this.element );
		this._nfySrv( "contextMenu", parameters );
	}

	selectAndFocusRow( evt ) {
		if ( !Validator.is( this.row, "XRowItem" ) ||
			!Validator.isFunction( this.row.selectAndFocusRow ) ) {
			return false;
		}
		return this.row.selectAndFocusRow( evt );
	}

	get selectionArrow() {
		const span = window.document.createElement( "span" );
		span.style.marginLeft = "auto";
		span.style.marginRight = "auto";
		span.style.fontSize = "16px";
		span.style.color = "#717171";
		// span.innerHTML = "&#129122;";
		const italicIconPlaceholder = window.document.createElement( "i" );
		italicIconPlaceholder.classList.add( "fp", "fp-arrow-right" );
		span.appendChild( italicIconPlaceholder );
		return span;
	}

	addSelectionArrow() {
		if ( !this.isRendered ) {
			return false;
		}
		this.removeSelectionArrow();
		const element = this.element;
		element.style.alignItems = "center";
		const selectionArrow = this.selectionArrow;
		element.appendChild( selectionArrow );
		return true;
	}

	removeSelectionArrow() {
		if ( !this.isRendered ) {
			return false;
		}
		const element = this.element;
		element.style.alignItems = "";
		element.style.removeProperty( "alignItems" );
		const children = element.getElementsByTagName( "*" );
		if ( Validator.isIterable( children ) ) {
			for ( let child of children ) {
				if ( child === this.heightAdjustmentDiv ) {
					continue;
				}
				child.remove();
			}
		}
		element.innerHTML = "";
		if ( Validator.isFunction( this.reappendHeightAdjustmentDiv ) ) {
			this.reappendHeightAdjustmentDiv();
		}
		return true;
	}

	/**
	 * initializes a cell of a "select" column
	 * @param {HTMLElement} element the DOM element
	 */
	_iniSelectCll( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return;
		}
		element.classList.add( 'xtwcellselect' );
		if ( !Validator.isObject( this.column ) ) {
			element.style.setProperty( OWN_BACKGROUND_COLOR, null );
			return;
		}
		let color = XtwUtils.colorArrayToRgba( this.column.cellbgc );
		if ( !Validator.isString( color ) ) {
			color = null;
		}
		element.style.setProperty( OWN_BACKGROUND_COLOR, color );
		// TODO: element.style.backgroundColor = color;
	}

	_initDataCell( elm ) {
		this.setDatasetProperties( elm );
		// const im = pisasales.getItmMgr();
		const im = this._im;
		const col = this.column;
		// flex row --> center children vertically
		elm.style.alignItems = 'center';
		// we need another DIV to control the horizontal aligment
		const ald = document.createElement( 'div' );
		ald.style.width = 'inherit';
		im.setTxtOvrFlw( ald, false );
		ald.style.display = 'flex';
		ald.style.flexDirection = 'row';
		this.setHorizontalAlignment( ald );
		this.cttElm = this.newContentSpan;
		ald.appendChild( this.cttElm );
		elm.appendChild( ald );
		this.alnElm = ald;
		// set cell's default font
		this.cellFont = col.getDataFont();
		im.setFnt( elm, this.cellFont );
		if ( CELL_EDITING_ENABLED ) {
			this.addEditingListeners();
			this.addKeyListeners();
		}
		if ( this.isBlobDataType ) {
			this.addDoubleClickListener();
		}
		// we add a special markers to the elements
		// elm.__psanfo = marker;
		// ald.__psanfo = marker;
		// cte.__psanfo = marker;
	}

	get newContentSpan() {
		const span = document.createElement( 'span' );
		this.addListener( "click", "onCellContentClick", span );
		let single = true;
		const cbm = this.column.contentBreakMode;
		if ( cbm && Validator.isString(cbm.mode) && Validator.isString(cbm.whiteSpace) ) {
			// set as specified
			span.style.whiteSpace = cbm.whiteSpace;
			span.style.wordBreak = 'normal';
			single = !cbm.canBreak;
		}
		if ( single ) {
			// not specified or single line forced --> single line
			this._im.setTxtOvrFlw( span, true );
		}
		return span;
	}

	onCellContentClick( evt ) {
		if ( this.link ) {
			return this.onLinkClick( evt );
		}
		return false;
	}

	removeCellContentListener() {
		this.removeListener( "click", this.cttElm );
	}

	/**
	 * indicates whether this is a cell that's part of the "select" column
	 * @returns {Boolean} true if this cell is part of the "select" column; false: regular data cell
	 */
	isSelect() {
		return this.select;
	}

	/**
	 * @returns {Number} the current width of this cell in pixels
	 */
	getWidth() {
		return this.cellwdt;
	}

	/**
	 * sets a new cell width
	 * @param {Number} width new width in pixels
	 */
	setWidth( width ) {
		// const im = pisasales.getItmMgr();
		const im = this._im;
		const elm = this.getDomElement();
		im.setFlexWdt( elm, width, true );
		XtwUtils.syncZeroWidthClass( elm, width );
		this.cellwdt = width;
	}

	get clientRect() {
		if ( !this.isRendered ) {
			return void 0;
		}
		return this.element.getBoundingClientRect();
	}

	get clientHeight() {
		const clientRect = this.clientRect;
		if ( !( clientRect instanceof DOMRect ) ) {
			return void 0;
		}
		const height = clientRect.height;
		return Validator.isValidNumber( height ) ? height : void 0;
	}

	setDatasetProperties( element ) {
		if ( !( element instanceof HTMLElement ) ||
			!( element.dataset instanceof DOMStringMap ) ||
			!Validator.is( this.column, "XtwCol" ) ) {
			return false;
		}
		if ( Validator.isString( this.column.dsc ) ) {
			element.dataset.descriptionName = this.column.dsc;
		}
		if ( Validator.isString( this.column.ttip ) ) {
			element.dataset.tooltip = this.column.ttip;
		}
		if ( Validator.isObject( this.column.ctt ) &&
			Validator.isString( this.column.ctt.text ) ) {
			element.dataset.columnText = this.column.ctt.text;
		}
		return true;
	}

	get horizontalAlignment() {
		return Validator.is( this.column, "XtwCol" ) &&
			Validator.isString( this.column.dataAlign ) ?
			this.column.dataAlign : void 0;
	}

	setHorizontalAlignment( element ) {
		return HtmHelper
			.justifyContentBasedOnTextAlignment( element, this.horizontalAlignment );
	}

	/**
	 * sets new cell content
	 * @param {*} ctt new cell content
	 */
	setCtt( ctt ) {
		this.ctt = ctt || CellCtt.EMPTY_CONTENT;
		this.renderContent();
		this.setColorCssVariables();
		this.scaleBlobs();
	}

	scaleBlobs() {
		if ( Validator.is( this.column, "XtwCol" ) && !this.column.isBlob ) {
			return false;
		}
		const imageTagsScaled = this.setImageTagSize();
		const iconSymbolsScaled = this.setIconSymbolFontSize();
		return imageTagsScaled || iconSymbolsScaled;
	}

	get referenceWidth() {
		return Validator.is( this.column, "XtwCol" ) &&
			Validator.isPositiveInteger( this.column.width, false ) ?
			this.column.width : void 0;
	}

	get referenceHeight() {
		const height = this.clientHeight;
		const tagHeight = Validator.is( this.column, "XtwCol" ) &&
			this.column.blobHeightDefined ?
			this.column.tagHeight : void 0;
		let size;
		if ( Validator.isPositiveNumber( height, false ) ) {
			size = height;
		}
		if ( Validator.isPositiveNumber( tagHeight, false ) ) {
			size = !Validator.isPositiveNumber( size, false ) ? tagHeight :
				Math.min( size, tagHeight );
		}
		return size;
	}

	get referenceSize() {
		const width = this.referenceWidth;
		const height = this.referenceHeight;
		let size;
		if ( Validator.isPositiveNumber( width, false ) ) {
			size = width;
		}
		if ( Validator.isPositiveNumber( height, false ) ) {
			size = !Validator.isPositiveNumber( size, false ) ? height :
				Math.min( size, height );
		}
		return size;
	}

	setImageTagSize() {
		if ( Validator.is( this.column, "XtwCol" ) && !this.column.isBlob ) {
			return false;
		}
		if ( !( this.cttElm instanceof HTMLElement ) ||
			!Validator.is( this.ctt, "CellCtt" ) || !this.ctt.html ) {
			return false;
		}
		const allChildren = HtmHelper.getAllLevelChildren( this.cttElm );
		if ( allChildren.length <= 0 ||
			!allChildren.every( child => child.tagName === "IMG" ) ) {
			return false;
		}
		const width = this.referenceWidth;
		const height = this.referenceHeight;
		allChildren.forEach( image => {
			[ "width", "height" ].forEach( measurement => {
				image.removeAttribute( measurement );
			} );
			[ "width", "height", "minWidth", "min-width", "minHeight", "min-height" ]
			.forEach( measurement => {
				HtmHelper.removeStyleProperty( image, measurement );
			} );
		} );
		let atLeastOneBoundarySet = false;
		if ( Validator.isValidNumber( width ) ) {
			const maxWidth = `${ width / allChildren.length }px`;
			allChildren.forEach( image => {
				this._setSvgSize(image, false);
				image.style.maxWidth = maxWidth;
			});
			atLeastOneBoundarySet = true;
		}
		if ( Validator.isValidNumber( height ) ) {
			const maxHeight = `${ height }px`;
			allChildren.forEach( image => {
				this._setSvgSize(image, true);
				image.style.maxHeight = maxHeight;
			});
			atLeastOneBoundarySet = true;
		}
		return atLeastOneBoundarySet;
	}

	setIconSymbolFontSize() {
		if ( Validator.is( this.column, "XtwCol" ) && !this.column.isBlob ) {
			return false;
		}
		if ( !( this.cttElm instanceof HTMLElement ) ||
			!Validator.is( this.ctt, "CellCtt" ) || !this.ctt.html ) {
			return false;
		}
		const allChildren = HtmHelper.getAllLevelChildren( this.cttElm );
		if ( allChildren.length <= 0 ||
			!allChildren.every( child =>
				child.tagName === "I" && !this.isCheckboxItalicTag( child ) ) ) {
			return false;
		}
		const size = this.referenceSize;
		if ( !Validator.isValidNumber( size ) ) {
			return false;
		}
		const iconFontSize = HtmHelper.getIconFontSize( size );
		const fontSize = `${ iconFontSize }px`;
		allChildren.forEach( italicTag => italicTag.style.fontSize = fontSize );
		return true;
	}

	isCheckboxItalicTag( element ) {
		if ( !( element instanceof HTMLElement ) || element.tagName !== "I" ) {
			return false;
		}
		if ( !element.classList.contains( "fp" ) ) {
			return false;
		}
		return [ "fp-check-1", "fp-check-2" ].some( className =>
			element.classList.contains( className ) );
	}

	renderContent() {
		if ( this.checkbox instanceof Checkbox ) {
			const must_tristate = this.isWritable;
			if ( must_tristate !== this.checkbox.isTripleState ) {
				this.checkbox.destroySelf();
				if ( must_tristate ) {
					new TripleStateCheckbox( this );
				} else {
					new Checkbox(this);
				}
			}

			return this.checkbox.render();
		}
		if ( !( this.cttElm instanceof HTMLElement ) ) {
			return false;
		}
		if ( !Validator.isObject( this.ctt ) ) {
			this.cttElm.innerHTML = "";
			return true;
		}
		const innerHtml = Validator.isString( this.ctt.text ) ? this.ctt.text : "";
		this.cttElm.innerHTML = innerHtml;
		const font = Validator.isObject( this.ctt.prop ) ? this.ctt.prop.font : null;
		this._im.setFnt( this.cttElm, font );
		return true;
	}

	setColorCssVariables() {
		if ( !this.isRendered ) {
			return false;
		}
		const properties = !Validator.isObject( this.ctt ) ? {} :
			!Validator.is( this.ctt.prop, "CellProp" ) ? {} : this.ctt.prop;
		const mainBackgroundColor = Validator.isArray( properties.bgc, 4 ) ?
			Color.fromRgba( properties.bgc ) : void 0;
		if ( mainBackgroundColor instanceof Color &&
			Validator.isObject( this.column ) &&
			mainBackgroundColor.equals( this.column.mainBackgroundColor ) ) {
			this.element.style.setProperty(
				ITEM_SPECIFIC_BACKGORUND_COLOR, mainBackgroundColor.stringify() );
			const alternativeBackgroundColor =
				this.column.alternativeBackgroundColor instanceof Color ?
				this.column.alternativeBackgroundColor : mainBackgroundColor;
			this.element.style.setProperty(
				ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR, alternativeBackgroundColor.stringify() );
		} else {
			const rgbaBackgroundColor = XtwUtils.colorArrayToRgba( properties.bgc );
			this.element.style.setProperty( ITEM_SPECIFIC_BACKGORUND_COLOR, rgbaBackgroundColor );
			this.element.style.setProperty( ITEM_SPECIFIC_ALTERNATIVE_BACKGROUND_COLOR, rgbaBackgroundColor );
		}
		const rgbaTextColor = XtwUtils.colorArrayToRgba( properties.txc );
		this.element.style.setProperty( ITEM_SPECIFIC_TEXT_COLOR, rgbaTextColor );
		return true;
	}

	/**
	 * sets the right border if required
	 * @param {pisasales.ItmMgr} im item manager instance
	 * @param {HTMLElement} elm the HTML DOM element
	 * @param {Object} brc border color
	 */
	_setBorder( im, elm, brc ) {
		if ( brc && this.column.isVisible() ) {
			elm.style.borderRight = '1px solid ' + im.getRgb( brc );
		} else {
			elm.style.borderRight = '';
		}
	}

	/**
	 * sets a size property of a SVG image
	 * @param {HTMLImageElement} image the image element
	 * @param {Boolean} what selects the property: false -> width; true -> height
	 */
	_setSvgSize(image, what) {
		if ( 'svg' === image.getAttribute('_psa_type') ) {
			const name = what ? '_psa_height' : '_psa_width';
			const attr = image.getAttribute(name);
			if ( Validator.isString(attr) ) {
				const value = Number.parseInt(attr);
				if ( Validator.isPositiveNumber(value) ) {
					if ( what ) {
						image.style.height = '' + value + 'px';
					} else {
						image.style.width = '' + value + 'px';
					}
				}
			}
		}
	}

}
