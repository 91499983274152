import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import XtwUtils from '../../util/XtwUtils';
import FocusHolder from '../../../../gui/FocusHolder';

const DO_LOG = false;

export default class XtwBodyFocusExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		parentObject._domFocusBlocked = false;
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get isRapFocus() {
		const xtwTbl = this.xtdTbl;
		if ( !Validator.isObject( xtwTbl ) || !( "isRapFocus" in xtwTbl ) ) {
			return false;
		}
		return !!xtwTbl.isRapFocus;
	}

	set domFocusBlocked( newValue ) {
		this._domFocusBlocked = !!newValue;
	}

	get domFocusBlocked() {
		return !!this._domFocusBlocked;
	}

	blockDomFocus() {
		this.domFocusBlocked = true;
	}

	allowDomFocus() {
		this.domFocusBlocked = false;
	}

	_updateFocusedUI( setToFocused ) {
		if ( !this.isRendered ) {
			return false;
		}
		if ( this.focusHolder instanceof FocusHolder ) {
			this.focusHolder.setFocus();
			return true;
		}
		this.element.classList.remove( "rtp-focused" );
		if ( !setToFocused ) {
			return true;
		}
		this.element.classList.add( "rtp-focused" );
		if ( this.canBeFocused && this.isRapFocus ) {
			this.element.focus();
		}
		return true;
	}

	get hasDomFocus() {
		if ( !this.isRendered ) {
			return false;
		}
		const activeElement = window.document.activeElement;
		if ( !( activeElement instanceof HTMLElement ) ) {
			return false;
		}
		return this.element === activeElement ||
			this.element.contains( activeElement );
	}

	get canBeFocused() {
		if ( !this.isRendered ) {
			return false;
		}
		const selectionManager = this.selectionManager;
		return Validator.isObject( selectionManager ) &&
			!!selectionManager.canFocus;
	}

	get activeModelItem() {
		return this._getFromSelectionManager( "activeModelItem" );
	}

	get activeRow() {
		return this._getFromSelectionManager( "activeRow" );
	}

	_getFromSelectionManager( propertyName ) {
		if ( !Validator.isString( propertyName ) ) {
			return void 0;
		}
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!( propertyName in selectionManager ) ) {
			return void 0;
		}
		return selectionManager[ propertyName ];
	}

	get widgetVerticalSelection() {
		if ( !Validator.is( this.xtdTbl, "XtwTbl" ) ||
			!Validator.isObject( this.xtdTbl.wdgSlv ) ) {
			return void 0;
		}
		return this.xtdTbl.wdgSlv;
	}

	getModelItemByRowId( rowId ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager.getModelItemByRowId ) ) {
			return void 0;
		}
		return selectionManager.getModelItemByRowId( rowId );
	}

	setupFocusUI() {
		const tabIndexSet = this.setTabIndex();
		const blurListenerAdded = this.addBlurListener();
		const keydownListenerAdded = this.addKeydownListener();
		const keyupListenerAdded = this.addKeyupListener();
		const allSet = tabIndexSet && blurListenerAdded && keydownListenerAdded &&
			keyupListenerAdded;
		if ( allSet ) {
			// this is supposed to be a one-time call
			delete this.setupFocusUI;
		}
		return allSet;
	}

	setTabIndex() {
		if ( !this.isRendered ) {
			return false;
		}
		this.element.tabIndex = 0;
		// this is supposed to be a one-time call
		delete this.setTabIndex;
		return true;
	}

	addBlurListener() {
		const successfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "blur",
			functionName: "onTableElementBlur",
			element: this.element,
			useCapture: false
		} );
		if ( successfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addBlurListener;
		}
		return successfullyAdded;
	}

	addKeydownListener() {
		const successfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onTableElementKeyDown",
			element: this.element,
			useCapture: false
		} );
		if ( successfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addKeydownListener;
		}
		return successfullyAdded;
	}

	addKeyupListener() {
		const successfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keyup",
			functionName: "onTableElementKeyUp",
			element: this.element,
			useCapture: false
		} );
		if ( successfullyAdded ) {
			// this is supposed to be a one-time call
			delete this.addKeyupListener;
		}
		return successfullyAdded;
	}

	onTableElementBlur( domEvent ) {
		if ( !this.isRendered || !( domEvent instanceof FocusEvent ) ||
			domEvent.target !== this.element ) {
			return false;
		}
		if ( this.isRelatedTargetInside( domEvent ) ) {
			return false;
		}
		return this.removeFocusFromTable( domEvent );
	}

	onTableElementKeyDown( domEvent ) {
		if ( this.handleCopyTooltipSumRequest( domEvent ) ) {
			return true;
		}
		if ( this.keyEventsFrozen || this.dropdownOpen ) {
			return false;
		}
		const activeRow = this.activeRow;
		if ( Validator.isObject( activeRow ) && activeRow.isRendered &&
			Validator.isFunction( activeRow.onKeyDown ) ) {
			return activeRow.onKeyDown( domEvent );
		}
		// TODO scroll to the model item and handle through the model item
		return false;
	}

	onTableElementKeyUp( domEvent ) {
		if ( this.keyEventsFrozen || this.dropdownOpen ) {
			return false;
		}
		if ( Validator.isObject( domEvent ) && domEvent.alreadyHandled ) {
			return false;
		}
		const activeRow = this.activeRow;
		if ( Validator.isObject( activeRow ) && activeRow.isRendered &&
			Validator.isFunction( activeRow.onKeyUp ) ) {
			return activeRow.onKeyUp( domEvent );
		}
		// TODO scroll to the model item and handle through the model item
		return false;
	}

	isRelatedTargetInside( domEvent ) {
		if ( !( domEvent instanceof FocusEvent ) || !this.isRendered ||
			!( domEvent.relatedTarget instanceof HTMLElement ) ) {
			return false;
		}
		const allLevelChildren = HtmHelper.getAllLevelChildren( this.element );
		if ( !Validator.isIterable( allLevelChildren ) ) {
			return false;
		}
		return [ ...allLevelChildren ].indexOf( domEvent.relatedTarget ) >= 0;
	}

	handleCopyTooltipSumRequest( domEvent ) {
		return Validator.isFunctionPath( this.xtdTbl,
				"xtdTbl.handleCopyTooltipSumRequest" ) ?
			this.xtdTbl.handleCopyTooltipSumRequest( domEvent ) : false;
	}

	removeFocusFromTable( domEvent ) {
		this._updateFocusedUI( false );
		// TODO
		Warner.traceIf( DO_LOG, `Remove focus from table body.` );
	}

	handleTransferredEvent( domEvent ) {
		if ( Validator.isObject( domEvent ) &&
			Validator.isString( domEvent.inputId ) ) {
			return false;
		}
		if ( XtwUtils.isArrowUp( domEvent ) ) {
			return this.handleTransferredArrowNavigationEvent( domEvent, true );
		}
		if ( XtwUtils.isArrowDown( domEvent ) ) {
			return this.handleTransferredArrowNavigationEvent( domEvent, false );
		}
		Warner.traceIf( DO_LOG, `The widget body failed to handle the` +
			` transferred keyboard navigation DOM event.` );
		return false;
	}

	handleTransferredArrowNavigationEvent( domEvent, up = true ) {
		if ( this.keyEventsFrozen || this.dropdownOpen ) {
			return false;
		}
		if ( !this.hasSelectionManager || !Validator.isObject( this.model ) ||
			!Validator.isArray( this.model.flatModel, true ) ||
			!Validator.isMap( this.model.items ) ) {
			Warner.traceIf( DO_LOG, `The widget body failed to handle the` +
				` transferred keyboard navigation DOM event.` );
			return false;
		}
		// TODO find out at which point "in time" the event should be stopped
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const flatModel = this.model.flatModel;
		const selectionManager = this.selectionManager;
		const focusedModelItem = this.activeModelItem;
		let focusedRowId = !Validator.isObject( focusedModelItem ) ? void 0 :
			focusedModelItem.idr;
		// the focused row is the "first" row registered in the current
		// selection, if such exists
		if ( !Validator.isPositiveInteger( focusedRowId, false ) &&
			Validator.isSet( selectionManager.currentRowSelection, true ) ) {
			focusedRowId = Array.from( selectionManager.currentRowSelection )[ 0 ];
		}
		// the focused row is the "first" selected model item, if such exists
		if ( !Validator.isPositiveInteger( focusedRowId, false ) ) {
			let firstSelectedModelItem = flatModel.find( modelItem => {
				return Validator.isObject( modelItem ) && modelItem.isSelected;
			} );
			focusedRowId = Validator.isObject( firstSelectedModelItem ) ?
				firstSelectedModelItem.idr : focusedRowId;
		}
		// TODO find out if it is possible to find the active row by location of
		// the mouse/cursor/caret
		if ( !Validator.isPositiveInteger( focusedRowId, false ) ) {
			Warner.traceIf( DO_LOG, `The widget body failed to handle the` +
				` transferred keyboard navigation DOM event.` );
			return false;
		}
		const activeModelItem = this.model.items.get( focusedRowId );
		if ( !this.isValidModelItem( activeModelItem ) ) {
			Warner.traceIf( DO_LOG, `The widget body failed to handle the` +
				` transferred keyboard navigation DOM event.` );
			return false;
		}
		const activeModelItemIndex = Validator.getIndexInArray( flatModel, activeModelItem );
		if ( !Validator.isPositiveInteger( activeModelItemIndex ) ) {
			Warner.traceIf( DO_LOG, `The widget body failed to handle the` +
				` transferred keyboard navigation DOM event.` );
			return false;
		}
		const directionProvider = !!up ? -1 : 1;
		const itemToFocusIndex = directionProvider + activeModelItemIndex;
		let modelItemToFocus = itemToFocusIndex >= 0 &&
			itemToFocusIndex < flatModel.length ? flatModel[ itemToFocusIndex ] :
			activeModelItem;
		if ( !this.isValidModelItem( modelItemToFocus ) ) {
			modelItemToFocus = activeModelItem;
		}
		return this._scrollAndClickModelItem( {
			modelItem: modelItemToFocus,
			directionProvider: directionProvider,
			controlPressed: domEvent instanceof KeyboardEvent &&
				XtwUtils.isCommandKeyPressed( domEvent ),
			shiftPressed: domEvent instanceof KeyboardEvent && domEvent.shiftKey
		} );
	}

	get amountOfVisibleRowItems() {
		if ( !Validator.isArray( this.rowItems ) ) {
			return void 0;
		}
		return this.rowItems.length;
	}

	scrollToModelItem( modelItem, displayAtTheTop = false ) {
		if ( !this.isValidModelItem( modelItem ) ) {
			return false;
		}
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isFunction( widgetVerticalSelection.setSelection ) ) {
			return false;
		}
		const flatIndex = this.getModelItemFlatIndex( modelItem );
		if ( !Validator.isPositiveNumber( flatIndex ) ) {
			return false;
		}
		if ( !!displayAtTheTop ) {
			widgetVerticalSelection.setSelection( flatIndex );
			return true;
		}
		// the minus two (-2) in the end is due to the fact that the table
		// creates two "extra" elements at the bottom of the row container that
		// are not visible;
		const amountOfVisibleRowItems = this.amountOfVisibleRowItems - 2;
		if ( !Validator.isPositiveNumber( amountOfVisibleRowItems ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( flatIndex - amountOfVisibleRowItems + 1 );
		return true;
	}

	scrollAndShowModelItemAtPosition( { modelItem, positionFromTop = 0 } ) {
		if ( !this.isValidModelItem( modelItem ) ) {
			return false;
		}
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) ||
			!Validator.isFunction( widgetVerticalSelection.setSelection ) ) {
			return false;
		}
		const flatIndex = this.getModelItemFlatIndex( modelItem );
		if ( !Validator.isPositiveNumber( flatIndex ) ) {
			return false;
		}
		if ( !Validator.isPositiveInteger( positionFromTop ) ) {
			positionFromTop = 0;
		}
		const amountOfVisibleRowItems = this.amountOfVisibleRowItems - 2;
		if ( !Validator.isPositiveNumber( amountOfVisibleRowItems ) ) {
			widgetVerticalSelection.setSelection( flatIndex );
			return false;
		}
		if ( positionFromTop > amountOfVisibleRowItems ) {
			positionFromTop = amountOfVisibleRowItems;
		}
		const selectionIndex = flatIndex - positionFromTop;
		widgetVerticalSelection.setSelection( selectionIndex );
		widgetVerticalSelection._selectionChanged(); // DO NOT REMOVE THIS
		widgetVerticalSelection._scheduleSendChanges(); // DO NOT REMOVE THIS
		return true;
	}

	deselectEverything() {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ) {
			return false;
		}
		selectionManager.deselectAllRows();
		selectionManager.deselectEveryModelItem();
		return true;
	}

	/**
	 * shift pressed -> select all model items between the current (active) item
	 * and the model item to be clicked and focus the model item to be clicked;
	 *
	 * control or command pressed -> focus the model item to be clicked without
	 * changing the current selection;
	 *
	 * no shift and no control pressed -> focus and select the model item to be
	 * "clicked"
	 */
	_scrollAndClickModelItem( {
		modelItem,
		directionProvider = 1,
		positionFromTop,
		shiftPressed = false,
		controlPressed = false
	} ) {
		if ( Math.abs( directionProvider ) != 1 ) {
			directionProvider = 1;
		}
		const successfullyScrolledToModelItem =
			Validator.isPositiveNumber( positionFromTop ) ?
			this.scrollAndShowModelItemAtPosition( {
				modelItem: modelItem,
				positionFromTop: positionFromTop
			} ) :
			this.scrollToModelItem( modelItem, directionProvider < 0 )
		if ( !successfullyScrolledToModelItem ) {
			return false;
		}
		if ( !shiftPressed && !controlPressed ) {
			this.deselectEverything();
			modelItem.syncSelect();
			modelItem.syncFocus();
			return true;
		}
		// control pressed
		if ( !shiftPressed ) {
			modelItem.syncFocus();
			return true;
		}
		// shiftPressed
		const activeModelItem = this.activeModelItem;
		if ( !this.isValidModelItem( activeModelItem ) ) {
			modelItem.syncSelect();
			modelItem.syncFocus();
			return false;
		}
		this.deselectEverything();
		const itemsInbetweenSelected = this._selectItemsInbetween( {
			sourceModelItem: activeModelItem,
			goalModelItem: modelItem
		} );
		modelItem.syncFocus();
		return itemsInbetweenSelected;
	}

	getModelItemFlatIndex( modelItem ) {
		if ( !this.isValidModelItem( modelItem ) ) {
			return void 0;
		}
		const propertyFlatIndex = modelItem._flatIndex;
		if ( !Validator.isObject( this.model ) ||
			!Validator.isArray( this.model.flatModel ) ) {
			return Validator.isPositiveNumber( propertyFlatIndex ) ?
				propertyFlatIndex : void 0;
		}
		const indexInFlatModel = Validator.getIndexInArray(
			this.model.flatModel, modelItem );
		Warner.traceIf( ( indexInFlatModel != propertyFlatIndex ) && DO_LOG,
			`The actual index of the model item in the flat model does not` +
			` correspond to the value of the "_flatIndex" property of the model` +
			` item.` );
		return Validator.isPositiveNumber( indexInFlatModel ) ? indexInFlatModel :
			Validator.isPositiveNumber( propertyFlatIndex ) ? propertyFlatIndex :
			void 0;
	}

	_selectItemsInbetween( { sourceModelItem, goalModelItem } ) {
		const selectionManager = this.selectionManager;
		if ( !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager._getModelItemsInbetween ) ) {
			return false;
		}
		const modelItemsInbetween = selectionManager._getModelItemsInbetween( {
			sourceModelItem: sourceModelItem,
			goalModelItem: goalModelItem
		} );
		if ( !Validator.isArray( modelItemsInbetween, true ) ) {
			return false;
		}
		for ( let modelItem of modelItemsInbetween ) {
			modelItem.syncSelect();
		}
		return true;
	}

	doWithFrozenFocus( callbackFunction, isTheCallbackAllowedToChangeTheFocus = false ) {
		const selectionManager = this.selectionManager;
		if ( Validator.isObject( selectionManager ) &&
			Validator.isFunction( selectionManager._doWithFrozenFocus ) ) {
			return selectionManager._doWithFrozenFocus(
				callbackFunction, !!isTheCallbackAllowedToChangeTheFocus );
		}
		if ( !Validator.isFunction( callbackFunction ) ) {
			return void 0;
		}
		Warner.traceIf( DO_LOG, `The focus could not be frozen before executing the` +
			` callback and revived after, because the selection manager is` +
			` invalid or does not provide this functionality.` );
		const activeElement = window.document.activeElement;
		const activeElementWasValid = activeElement instanceof HTMLElement;
		const returnValue = callbackFunction();
		if ( window.document.activeElement === activeElement ) {
			return returnValue;
		}
		if ( isTheCallbackAllowedToChangeTheFocus ) {
			return returnValue;
		}
		Warner.traceIf( DO_LOG, `The focus was changed from the previously` +
			` active element despite executing the callback with frozen focus.` +
			` If the callback is supposed to change the focus, please specify` +
			` this when calling/using this method.` );
		if ( HtmHelper.isElementInDocument( activeElement ) &&
			HtmHelper.isElementInBody( activeElement ) ) {
			activeElement.focus();
			return returnValue;
		}
		if ( !activeElementWasValid ) {
			return returnValue;
		}
		Warner.traceIf( DO_LOG, `Could not restore the focus back to the` +
			` element that was active before executing the callback with` +
			` frozen focus. The element that was previously active does not` +
			` exist anymore and/or is no longer valid. The focus change must be` +
			` the result or consequence of the callback itself. ` );
		return returnValue;
	}

	doWithoutDamagingFocus( callbackFunction ) {
		if ( !Validator.isFunction( callbackFunction ) ) {
			return void 0;
		}
		const selectionManager = this.selectionManager;
		return !Validator.isObject( selectionManager ) ||
			!Validator.isFunction( selectionManager._doWithoutDamagingFocus ) ?
			callbackFunction() :
			selectionManager._doWithoutDamagingFocus( callbackFunction );
	}

	focusLastFocusedModelItem() {
		const selectionManager = this.selectionManager;
		return !Validator.isObject( selectionManager ) ? false :
			selectionManager.focusLastFocusedModelItem();
	}

	makeSureARowIsFocused() {
		if ( !this.hasSelectionManager ) {
			return false;
		}
		if ( this.somethingIsFocused ) {
			return true;
		}
		if ( this.focusFirstSelectedRow() ) {
			return true;
		}
		return this.focusFirstVisibleRow();
	}

	get somethingIsFocused() {
		if ( !this.hasSelectionManager ) {
			return false;
		}
		const lastFocusedModelItem = this.selectionManager.lastFocusedModelItem;
		if ( Validator.isObject( lastFocusedModelItem ) &&
			lastFocusedModelItem.isFocused ) {
			return true;
		}
		const previousFocusModelItem = this.selectionManager.previousFocusModelItem;
		if ( Validator.isObject( previousFocusModelItem ) &&
			previousFocusModelItem.isFocused ) {
			return true;
		}
		const firstFocusedModelItem = this.selectionManager.firstFocusedModelItem;
		if ( Validator.isObject( firstFocusedModelItem ) &&
			firstFocusedModelItem.isFocused ) {
			return true;
		}
		const focusRow = this.selectionManager.focusRow;
		return Validator.isObject( focusRow ) && focusRow.isFocused;
	}

	get firstSelectedXRowItem() {
		if ( !this.hasSelectionManager || !( this.rowItems instanceof Array ) ||
			this.rowItems.length < 1 ) {
			return void 0;
		}
		const currentRowSelection = this.selectionManager.currentRowSelection;
		if ( !( currentRowSelection instanceof Set ) || currentRowSelection.size < 1 ) {
			return void 0;
		}
		const selectedRowsIdrs = [ ...currentRowSelection ];
		let firstSelectedRow = void 0;
		rowItemsLoop:
			for ( let rowItem of this.rowItems ) {
				if ( !Validator.isObject( rowItem ) ||
					selectedRowsIdrs.indexOf( rowItem.idr ) < 0 ) {
					continue rowItemsLoop;
				}
				firstSelectedRow = rowItem;
				break rowItemsLoop;
			}
		return firstSelectedRow;
	}

	focusFirstSelectedRow() {
		const firstSelectedRow = this.firstSelectedXRowItem;
		if ( !Validator.isObject( firstSelectedRow ) ) {
			return false;
		}
		if ( Validator.isObject( firstSelectedRow.item ) &&
			Validator.isFunction( firstSelectedRow.item.syncFocus ) ) {
			firstSelectedRow.item.syncFocus();
		} else {
			firstSelectedRow.syncFocus();
		}
		// firstSelectedRow.focus();
		return true;
	}

	get firstVisibleXRowItem() {
		if ( !( this.rowItems instanceof Array ) || this.rowItems.length < 1 ) {
			return void 0;
		}
		let firstValidRow = void 0;
		rowItemsLoop:
			for ( let rowItem of this.rowItems ) {
				if ( !Validator.isObject( rowItem ) ) {
					continue rowItemsLoop;
				}
				firstValidRow = rowItem;
				break rowItemsLoop;
			}
		return firstValidRow;
	}

	focusFirstVisibleRow() {
		const firstValidRow = this.firstVisibleXRowItem;
		if ( !Validator.isObject( firstValidRow ) ) {
			return false;
		}
		if ( Validator.isObject( firstValidRow.item ) &&
			Validator.isFunction( firstValidRow.item.syncFocus ) ) {
			firstValidRow.item.syncFocus();
		} else {
			firstValidRow.syncFocus();
		}
		// firstValidRow.focus();
		return true;
	}

}
