import AttachmentObject from '../../../../utils/AttachmentObject';
import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class CharactersCounterExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get newCharactersCounter() {
		const counterContainer = window.document.createElement( "div" );
		counterContainer.classList.add( "rtp-character-count" );
		return counterContainer;
	}

	get maxCharacterCount() {
		const column = this.column;
		if ( !Validator.isObject( column ) || !( "maxCharacterCount" in column ) ) {
			return void 0;
		}
		return column.maxCharacterCount;
	}

	get currentContentLength() {
		const inputValue = this.inputValue;
		const inputValueLength = Validator.isString( inputValue ) ?
			inputValue.length : 0;
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return inputValueLength;
		}
		const editableElementTextLength = contentEditableElement.textLength;
		if ( Validator.isPositiveInteger( editableElementTextLength ) ) {
			return editableElementTextLength;
		}
		return inputValueLength;
	}

	get remainingContentLength() {
		const currentContentLength = this.currentContentLength;
		if ( !Validator.isPositiveInteger( currentContentLength ) ) {
			return 0;
		}
		const maxCharacterCount = this.maxCharacterCount;
		if ( !Validator.isPositiveInteger( maxCharacterCount, false ) ) {
			return 0;
		}
		const remainingCharactersAmount = maxCharacterCount - currentContentLength;
		return Validator.isPositiveInteger( remainingCharactersAmount ) ?
			remainingCharactersAmount : 0;
	}

	get selectedContentLength() {
		const contentEditableElement = this.contentEditableElement;
		if ( !( contentEditableElement instanceof HTMLElement ) ) {
			return 0;
		}
		const selectionStart = contentEditableElement.selectionStart;
		if ( !Validator.isPositiveInteger( selectionStart ) ) {
			return 0;
		}
		const selectionEnd = contentEditableElement.selectionEnd;
		if ( !Validator.isPositiveInteger( selectionEnd ) ) {
			return 0;
		}
		return Math.abs( selectionEnd - selectionStart );
	}

	get maximumCharacterCountReached() {
		return this.allowedInsertionLength <= 0;
	}

	get allowedInsertionLength() {
		return this.remainingContentLength + this.selectedContentLength;
	}

	eventSurpassesMaximumCharacterCount( domEvent ) {
		return this.maximumCharacterCountReached &&
			( DomEventHelper.isContentLengthIncreasingKey( domEvent ) ||
				DomEventHelper.isPasteEvent( domEvent ) );
	}

	getCounterContent( currentCharacterCount ) {
		const maxCharacterCount = this.maxCharacterCount;
		const currentCharactersAmountIsValid =
			Validator.isPositiveInteger( Number( currentCharacterCount ) );
		if ( !Validator.isPositiveInteger( maxCharacterCount ) ) {
			return currentCharactersAmountIsValid ? String( currentCharacterCount ) : "";
		}
		return !currentCharactersAmountIsValid ? String( maxCharacterCount ) :
			`${ currentCharacterCount } / ${ maxCharacterCount }`;
	}

}
