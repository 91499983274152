import ObjReg from '../../../utils/ObjReg';
import XtwTbl from '../XtwTbl';

// the one and only instance
let singleton = null;

export default class XtwMgr {

	/**
	 * constructs a new instance
	 */
	constructor() {
		this._xtwReg = new ObjReg();
	}

	/**
	 * returns the singleton instance
	 * @returns {XtwMgr} the singleton instance
	 */
	static getInst() {
		return singleton;
	}

	/**
	 * adds a table widget to the register
	 * @param {XtwTbl} xtw the table widget to be added
	 */
	addXtdTbl(xtw) {
		this._xtwReg.addObj(xtw.getId(), xtw);
	}

	/**
	 * removes a previously registered table widget
	 * @param {XtwTbl} xtw 
	 * @returns {XtwTbl} the removed table widget
	 */
	rmvXtdTbl(xtw) {
		return this._xtwReg.rmvObj(xtw.getId());
	}

	/**
	 * retrieves an XtwTbl instance
	 * @param {String} idw widget ID
	 * @returns {XtwTbl} the table widget or null if not found
	 */
	getXtdTbl(idw) {
		return this._xtwReg.getObj(idw);
	}
}

singleton = new XtwMgr();

console.log( "widgets/xtw/util/XtwMgr.js loaded." );

