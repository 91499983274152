import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
// import DomEventHelper from '../../../../utils/DomEventHelper';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class XtwColTooltipExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get actualTooltip() {
		return Validator.isString( this.ttip ) ? this.ttip : "";
	}

	get tableEndX() {
		return Validator.isObject( this.xtdTbl ) && "tableEndX" in this.xtdTbl ?
			this.xtdTbl.tableEndX : void 0;
	}

	get spanTooltipRect() {
		if ( !( this.spanTooltip instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.spanTooltip.getBoundingClientRect();
	}

	get spanTooltipWidth() {
		const spanTooltipRect = this.spanTooltipRect;
		if ( !( spanTooltipRect instanceof DOMRect ) ) {
			return void 0;
		}
		return spanTooltipRect.width;
	}

	get spanTooltipEndX() {
		const spanTooltipRect = this.spanTooltipRect;
		if ( !( spanTooltipRect instanceof DOMRect ) ) {
			return void 0;
		}
		return spanTooltipRect.x + spanTooltipRect.width;
	}

	set spanTooltipWidth( amountOfPixels ) {
		if ( !( this.spanTooltip instanceof HTMLElement ) ||
			!Validator.isPositiveNumber( amountOfPixels ) ) {
			return;
		}
		this.spanTooltip.style.width = `${ amountOfPixels }px`;
	}

	setAsActiveSumColumn() {
		const table = this.xtdTbl;
		if ( !Validator.isObject( table ) ) {
			return false;
		}
		table.activeMouseoverTooltipSumColumn = this;
		return true;
	}

	removeAsActiveSumColumn() {
		const table = this.xtdTbl;
		if ( !Validator.isObject( table ) ||
			table.activeMouseoverTooltipSumColumn != this ) {
			return false;
		}
		table.activeMouseoverTooltipSumColumn = void 0;
		delete table.activeMouseoverTooltipSumColumn;
		return true;
	}

	addTooltipListeners() {
		const mouseEnterListenerAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseenter",
			functionName: "onMouseEnter"
		} );
		const mouseLeaveListenerAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseleave",
			functionName: "onMouseLeave"
		} );
		// const mouseMoveListenerAdded = EventListenerManager.addListener( {
		// 	instance: this,
		// 	eventName: "mousemove",
		// 	functionName: "onMouseMove"
		// } );
		return mouseEnterListenerAdded && mouseLeaveListenerAdded;
	}

	removeTooltipListeners() {
		[ "mouseenter", "mouseleave" ].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName );
		} );
	}

	onMouseEnter( domEvent ) {
		this.onMouseLeave();
		this.removeExistingTooltips();
		if ( !Validator.isString( this.actualTooltip ) && this.type != 1 ) {
			// avoid showing empty tooltips
			return;
		}
		if ( this.createSpanTooltip( domEvent.clientY, domEvent.clientX ) ) {
			const self = this;
			window.setTimeout( () => {
				if ( !Validator.isObject( self ) || !self.isRendered ||
					!( self.spanTooltip instanceof HTMLElement ) ) {
					return false;
				}
				self.spanTooltip.classList.remove( "invisible" );
				return true;
			}, 500 );
		}
		this.tooltipSum = void 0;
		delete this.tooltipSum;
		this.removeAsActiveSumColumn();
		if ( this.type == 1 ) { // if the type is equal to 1, it is a numeric field
			this.notifyServer( "columnHover" );
		}
		if ( !this.makeSureTableIsFocused() ) {
			Warner.traceIf( true, `The table is not focused while the mouse is` +
				` hovering a colum header/title. DOM keyboard events may be ignored.` );
		}
	}

	removeExistingTooltips() {
		const table = this.xtdTbl;
		return Validator.isFunctionPath( table, "table.removeAllColumnTooltips" ) ?
			table.removeAllColumnTooltips() : false;
	}

	onMouseLeave( domEvent ) {
		this.tooltipSum = void 0;
		delete this.tooltipSum;
		this.destroySpanTooltip();
		this.removeAsActiveSumColumn();
	}

	// onMouseMove( domEvent ) {
	// 	if ( this.type != 1 ) {
	// 		return;
	// 	}
	// 	Warner.traceIf( true );
	// }

	createSpanTooltip( top, left ) {
		if ( !this.isRendered ) {
			return false;
		}
		this.element.title = "";
		this.spanTooltip = this.newSpanTooltip;
		if ( Validator.isValidNumber( top ) ) {
			this.spanTooltip.style.top = top + 5 + "px";
		}
		if ( Validator.isValidNumber( left ) ) {
			this.spanTooltip.style.left = left + 5 + "px";
		}
		this.element.appendChild( this.spanTooltip );
		this.spanTooltip.classList.add( "invisible" );
		this.adjustSpanTooltipWidth();
		return true;
	}

	destroySpanTooltip() {
		if ( this.isRendered ) {
			this.element.title = this.actualTooltip;
		}
		let element = this.spanTooltip;
		this.spanTooltip = void 0;
		delete this.spanTooltip;
		if ( !( element instanceof HTMLElement ) ) {
			return true;
		}
		element.innerHTML = "";
		element.remove();
		element = void 0;
		return true;
	}

	adjustSpanTooltipWidth() {
		const spanTooltipEndX = this.spanTooltipEndX;
		if ( !Validator.isValidNumber( spanTooltipEndX ) ) {
			return false;
		}
		const tableEndX = this.tableEndX;
		if ( !Validator.isValidNumber( tableEndX ) ) {
			return false;
		}
		if ( tableEndX >= spanTooltipEndX ) {
			return true;
		}
		const widthAdjustment = spanTooltipEndX - tableEndX;
		this.spanTooltipWidth = this.spanTooltipWidth - widthAdjustment;
		return true;
	}

	get newSpanTooltip() {
		const spanTooltip = window.document.createElement( "span" );
		spanTooltip.classList.add( "xtwheader-tooltip" );
		spanTooltip.role = "tooltip";
		spanTooltip.innerHTML = this.actualTooltip;
		if ( this.mainSpan instanceof HTMLElement ) {
			spanTooltip.style.fontFamily = this.mainSpan.style.fontFamily;
			spanTooltip.style.fontSize = this.mainSpan.style.fontSize;
			spanTooltip.style.fontWeight = this.mainSpan.style.fontWeight;
		}
		return spanTooltip;
	}

	setTooltip( newTooltipValue ) {
		if ( !Validator.isString( newTooltipValue ) ) {
			return false;
		}
		if ( this.element instanceof HTMLElement ) {
			this.element.title = newTooltipValue;
		}
		return true;
	}

	addToTooltip( addition ) {
		if ( !Validator.isString( addition ) ||
			!( this.element instanceof HTMLElement ) ) {
			return false;
		}
		this.element.title = this.actualTooltip + addition;
		return true;
	}

	addToSpanTooltip( addition ) {
		if ( !( this.spanTooltip instanceof HTMLElement ) ) {
			return false;
		}
		this.spanTooltip.innerHTML = this.actualTooltip + "<br/>" + addition;
		return true;
	}

	addSumToTooltip( sum ) {
		if ( this.type != 1 ) { // if the type is equal to 1, it is a numeric field
			return false;
		}
		this.tooltipSum = sum;
		this.setAsActiveSumColumn();
		return this.addToSpanTooltip( "Σ=" + sum );
	}

	handleCopyTooltipSumRequest() {
		if ( this.type != 1 ) { // if the type is equal to 1, it is a numeric field
			return false;
		}
		Warner.traceIf( true );
		this.notifyServer( "copyTooltipSum", { tooltipSum: this.tooltipSum } );
		return true;
	}

	makeSureTableIsFocused() {
		const table = this.xtdTbl;
		if ( !Validator.isFunctionPath( table, "table.makeSureTableIsFocused" ) ) {
			return false;
		}
		return table.makeSureTableIsFocused();
	}

}
