import AttachmentObject from '../../../../utils/AttachmentObject';
import EventListenerManager from '../../../../utils/EventListenerManager';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';

export default class MaximizeButtonExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
	}

	get newMaximizeButton() {
		return this.getNewButton( true );
	}

	get newMinimizeButton() {
		return this.getNewButton( false );
	}

	getNewButton( expandIcon = true ) {
		const buttonContainer = window.document.createElement( "div" );
		buttonContainer.classList.add( "rtp-maximize" );
		const iconItalicElement = window.document.createElement( "i" );
		iconItalicElement.classList.add( "fp",
			( !!expandIcon ? "fp-expand" : "fp-close-3" ) );
		buttonContainer.appendChild( iconItalicElement );
		const clickListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "click",
			functionName: "onButtonContainerClick",
			callBackPrefix: "ButtonContainer",
			element: buttonContainer,
			useCapture: false
		} );
		const mouseDownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mousedown",
			functionName: "onButtonContainerMousedown",
			callBackPrefix: "ButtonContainer",
			element: buttonContainer,
			useCapture: false
		} );
		const mouseUpListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "mouseup",
			functionName: "onButtonContainerMouseup",
			callBackPrefix: "ButtonContainer",
			element: buttonContainer,
			useCapture: false
		} );
		return buttonContainer;
	}

	// onButtonContainerClick( domEvent ) {
	// 	Warner.traceIf( true );
	// 	if ( domEvent instanceof MouseEvent ) {
	// 		domEvent.stopPropagation();
	// 		domEvent.preventDefault();
	// 	}
	// }

	onButtonContainerMousedown( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

	onButtonContainerMouseup( domEvent ) {
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
	}

}
