import AttachmentObject from '../../../utils/AttachmentObject';
import Validator from '../../../utils/Validator';
import XtwUtils from './XtwUtils';

const DEFAULT_TOOLTIP_SHOW_DELAY = 1250;
let TOOLTIP_SHOW_DELAY = -1;

export default class XtwTooltipListenerExtension extends AttachmentObject {

	constructor( hostPlugin ) {
		super( hostPlugin );
		this._updateTtpTmo();
	}

	_updateTtpTmo() {
		if ( TOOLTIP_SHOW_DELAY === -1 ) {
			let tmo = -1;
			const prop = pisasales.getThemeMgr().getProp( 'tooltipShowDelay' );
			if ( ( prop !== undefined ) && ( 'number' === prop.type ) ) {
				tmo = prop.value;
			}
			TOOLTIP_SHOW_DELAY = Validator.isPositiveInteger( tmo ) ? tmo : DEFAULT_TOOLTIP_SHOW_DELAY;
		}
	}

	addTooltipListeners( element = void 0 ) {
		this.addListener( "mouseenter", "onMouseEnter", element );
		this.addListener( "mouseover", "onMouseOver", element );
		this.addListener( "mouseleave", "onMouseLeave", element );
	}

	removeTooltipListeners( element = void 0 ) {
		this.clearTooltipTimer();
		this.removeListener( "mouseenter", element );
		this.removeListener( "mouseover", element );
		this.removeListener( "mouseleave", element );
	}

	onMouseEnter( evt, parameters = void 0 ) {
		this.clearTooltipTimer();
		this.initializeTooltipTimer();
		if ( Validator.is( evt, "MouseEvent" ) ) {
			evt.stopPropagation();
			this.lastTooltipEvent = evt;
		}
	}

	onMouseOver( evt, parameters = void 0 ) {
		if ( !Validator.isNumber( this.tooltipTimerTimeoutID ) ) {
			this.onMouseEnter( evt, parameters );
		} else if ( Validator.is( evt, "MouseEvent" ) ) {
			evt.stopPropagation();
			this.lastTooltipEvent = evt;
		}
	}

	onMouseLeave( evt, parameters = void 0 ) {
		if ( Validator.is( evt, "MouseEvent" ) ) {
			evt.stopPropagation();
		}
		this.clearTooltipTimer();
	}

	onTooltipTimer() {
		this.clearTooltipTimer();
	}

	initializeTooltipTimer() {
		if ( Validator.isNumber( this.tooltipTimerTimeoutID ) ) {
			return; // there is a timer already
		}
		this.tooltipTimerTimeoutID = setTimeout( this._onTooltipTimer, TOOLTIP_SHOW_DELAY );
	}

	clearTooltipTimer() {
		this.lastTooltipEvent = void 0;
		delete this.lastTooltipEvent;
		if ( !Validator.isNumber( this.tooltipTimerTimeoutID ) ) {
			return;
		}
		clearTimeout( this.tooltipTimerTimeoutID );
		this.tooltipTimerTimeoutID = void 0;
		delete this.tooltipTimerTimeoutID;
	}

	getCoordinateParameters( evt, element, parameters = {} ) {
		if ( !( element instanceof HTMLElement ) ) {
			element = this.element;
		}
		return XtwUtils.getCoordinateParameters( evt, element, parameters );
	}

}

console.log( 'widgets/xtw/util/XtwTooltipListenerExtension.js loaded.' );
