/**
 * a file drop handler class;
 * this is just an example showing the expected interface
 */
export default class FileDropHandler {

	/**
	 * constructs an instance
	 */
	constructor() {
		// empty
	}

	/**
	 * handles a file drop
	 * @param {File[]} files array of dropped files
	 */
	handleDrop(files) {
	}

	/**
	 * shows a (warning) message
	 * @param {String} msg message text
	 */
	showMessage(msg) {
	}
}

console.log('dnd/FileDropHandler.js loaded.');