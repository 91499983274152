import DomEventHelper from '../../../../utils/DomEventHelper';
import EditableElement from './EditableElement';
import EventListenerManager from '../../../../utils/EventListenerManager';
import Validator from '../../../../utils/Validator';
import Warner from '../../../../utils/Warner';
import Textarea from './Textarea';
import MaximizeButtonExtension from './MaximizeButtonExtension';
import CharactersCounterExtension from './CharactersCounterExtension';
import EditingElementSelectionManagerExtension from './EditingElementSelectionManagerExtension';
import InputFieldEventManagingExtension from './InputFieldEventManagingExtension';
import BscMgr from '../../../../gui/BscMgr';

export const DO_LOG = true;

export default class InputField extends EditableElement {

	constructor( cellObject ) {
		super( cellObject );
		cellObject.inputField = this;
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) ) {
			xtwBody.inputField = this;
		}
		new InputFieldEventManagingExtension( this );
		new MaximizeButtonExtension( this );
		new CharactersCounterExtension( this );
		new EditingElementSelectionManagerExtension( this );
	}

	get inputId() {
		return this.input instanceof HTMLElement && Validator.isString( this.input.id ) ? String( this.input.id ) : '';
	}

	get inputValue() {
		return this.input instanceof HTMLElement ? String(this.input.value) : '';
	}

	get rawCellText() {
		const cellContentObject = this.cellContent;
		return Validator.isObject( cellContentObject ) && Validator.isString( cellContentObject.rawText ) ? cellContentObject.rawText : '';
	}

	get cellText() {
		const cellContentObject = this.cellContent;
		return Validator.isObject( cellContentObject ) && Validator.isString( cellContentObject.text ) ? cellContentObject.text : '';
	}

	get cellInnerText() {
		const cellElement = this.cellElement;
		return cellElement instanceof HTMLElement ? cellElement.innerText : '';
	}

	get contentEditableElement() {
		return this.input;
	}

	get somethingChanged() {
		return this.lastlyRegistered || this.originalValue !== this.inputValue;
	}

	get insertionDummy() {
		const row = this.row;
		if ( !Validator.isObject( row ) || !( "insertionDummy" in row ) ) {
			return false;
		}
		return !!row.insertionDummy;
	}

	get dirtyInsertionDummy() {
		return this.insertionDummy && this.dirty;
	}

	/**
	 * @override
	 */
	setFocus() {
		console.debug(`${this.instanceID}: Got a setFocus() call.`);
		if ( this.input instanceof HTMLElement ) {
			this.input.focus();
		}
	}

	/**
	 * @override
	 */
	forceBlur() {
		console.debug(`${this.instanceID}: Got a forceBlur() call.`);
		this.onInputBlur(null);
	}

	render() {
		if ( this.shouldBeSkipped ) {
			return false;
		}
		const cellElement = this.cellElement;
		if ( !( cellElement instanceof HTMLElement ) ) {
			Warner.traceIf( true, `Invalid cell element` );
			return false;
		}
		this.discardUi();
		super.render();
		const innerText = this.rawCellText || this.cellInnerText || this.cellText || "";
		cellElement.innerHTML = "";
		this.input = this.newInput;
		this.input.value = innerText;
		this.button = this.newMaximizeButton;
		this.container = this.newContainer;
		this.container.appendChild( this.input );
		this.container.appendChild( this.button );
		cellElement.appendChild( this.container );
		const horizontalAlignment = this.horizontalAlignment;
		if ( Validator.isString( horizontalAlignment ) ) {
			this.input.style.textAlign = horizontalAlignment;
		}
		this.input.select();
		return true;
	}

	handleMaximizeRequest( domEvent ) {
		const currentValue = this.inputValue;
		const selectionStart = this.selectionStart;
		const selectionEnd = this.selectionEnd;
		const selectionDirection = this.selectionDirection;
		const cell = this.cell;
		this.destroySelf();
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.createAndFocusTextarea ) ) {
			return false;
		}
		cell.createAndFocusTextarea();
		if ( !( cell.textarea instanceof Textarea ) ) {
			return false;
		}
		cell.textarea.inputValue = currentValue;
		cell.textarea.setSelection( selectionStart, selectionEnd, selectionDirection );
		return true;
	}

	informAboutContentChange() {
		console.debug(`Processing content change of editable element "${this.instanceID}":`);
		if ( this.editingAllowed && (this.originalValue !== this.inputValue) ) {
			console.debug(`---> sending "${this.inputValue}"...`);
			this.informAboutSave();
			return true;
		} else {
			console.debug(`---> unchanged!`);
			return false;
		}
	}

	setEditingPermission( editingAllowed ) {
		this.editingAllowed = !!editingAllowed;
		return this.editingAllowed ? this.markRowAsEdited() : this.resetInput();
	}

	markRowAsEdited() {
		this.rowEdited = true;
	}

	resetInput() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		const originalValue = this.originalValue || "";
		this.input.value = originalValue;
		return true;
	}

	syncInputContentWithDropdown( parameters ) {
		if ( !this.editingAllowed || !Validator.isObject( parameters ) ||
			!Validator.isString( parameters.content ) ||
			!( this.input instanceof HTMLElement ) || !this.canBeEdited ) {
			return false;
		}
		this.input.innerHTML = parameters.content;
		this.input.value = parameters.content;
		this.dirty = true;
		this.setEditingPermission( true );
		this.selectAndFocusEndOfInput();
		if ( parameters.registerInputFocusCallback === true ||
			Validator.isTrue( parameters.registerInputFocusCallback ) ) {
			return this.registerInputFocusCallback();
		}
		// this.informAboutEditing();
		return true;
	}

	registerInputFocusCallback() {
		const xtwBody = this.xtwBody;
		if ( !Validator.isFunctionPath( xtwBody, "xtwBody.setupEnsuingModelDataCallback" ) ) {
			return false;
		}
		const cell = this.cell;
		if ( !Validator.isObject( cell ) ) {
			return false;
		}
		const instance = this;
		return xtwBody.setupEnsuingModelDataCallback(
			"registerInputFocusCallback-", () => {
				if ( !Validator.isFunctionPath( cell, cell.createAndFocusInputField ) ) {
					return false;
				}
				if ( Validator.isFunctionPath( instance, "instance.destroySelf" ) ) {
					instance.destroySelf();
				}
				if ( Validator.isFunctionPath( cell, "cell.focusCell" ) ) {
					cell.focusCell();
				}
				cell.createAndFocusInputField();
				let success = false;
				if ( Validator.isFunctionPath( cell, "cell.inputField.selectAndFocusEndOfInput" ) ) {
					success = cell.inputField.selectAndFocusEndOfInput();
				}
				return success;
				// return instance.selectAndFocusEndOfInput();
			} );
	}

	selectAndFocusEndOfInput() {
		if ( !( this.input instanceof HTMLElement ) ) {
			return false;
		}
		const selectionEnd = this.selectionEnd;
		this.setSelection( selectionEnd, selectionEnd );
		this.input.focus();
		return true;
	}

	destroySelf() {
		this.discardUi();
		const xtwBody = this.xtwBody;
		if ( Validator.isObject( xtwBody ) && xtwBody.inputField === this ) {
			xtwBody.inputField = void 0;
			delete xtwBody.inputField;
		}
		const cellObject = this.cell;
		if ( Validator.isObject( cellObject ) ) {
			cellObject.inputField = void 0;
			delete cellObject.inputField;
		}
		this.cell = void 0;
		delete this.cell;
		const propertyNames = Object.getOwnPropertyNames(
			Object.getPrototypeOf( this ) );
		for ( let propertyName of propertyNames ) {
			Object.defineProperty( this, propertyName, {
				value: void 0,
				writable: true,
				configurable: true
			} );
			this[ propertyName ] = void 0;
			delete this[ propertyName ];
		}
		return true;
	}

	discardUi() {
		const buttonDiscarded = this.discardButton();
		const everythingElseDiscarded = super.discardUi();
		return buttonDiscarded && everythingElseDiscarded;
	}

	discardButton() {
		[ "mousedown", "mouseup", "click" ].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName, this.button, "ButtonContainer" );
		} );
		return this._discardElementProperty( "button" );
	}

	discardInput() {
		[ "keydown", "keyup", "keypress", "blur", "mousewheel", "contextmenu",
			"paste", "change", "focusout", "select", "mousemove"
		].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName, this.input, "Input" );
		} );
		return this._discardElementProperty( "input" );
	}

	get newInput() {
		const cbm = this.cell.column.contentBreakMode;
		const can_break = cbm && !!cbm.canBreak;
		let ie = null;
		if ( can_break ) {
			// multi-line text area element
			const textarea = document.createElement('textarea');
			textarea.className = 'xtw-cell-edit';
			textarea.wrap = cbm.mode === 'breakline' ? 'off' : 'soft';
			ie = textarea;
		} else {
			// single line input element
			const input = window.document.createElement( "input" );
			input.type = "text";
			input.id = Validator.generateRandomString( "rtp-input-" );
			ie = input;
		}
		ie.tabIndex = 1;
		this.addBasicListeners( ie );
		return ie;
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 */
	onInputBlur( domEvent ) {
		Warner.traceIf( DO_LOG );
		if ( !this.locked ) {
			console.debug(`${this.instanceID}: Focus lost - self-destruction.`);
			// console.debug(domEvent);
			// console.debug(Warner.getStack());
			return BscMgr.getInstance().runWithLockedFocus(this, () => {
				this.informAboutContentChange();
				return this.destroySelfAndRestoreCell();
			});
		} else {
			console.debug(`${this.instanceID}: Focus locked - doing nothing.`);
			return false;
		}
	}

	onInputFocusout( domEvent ) {
		return this.onInputBlur( domEvent );
	}

	onInputChange( domEvent ) {
		return this.onInputBlur( domEvent );
	}

	onInputSelect( domEvent ) {
		// this is solely to prevent drag events on table level that might cause
		// adding a new row
		Warner.traceIf( DO_LOG );
		return DomEventHelper.stopIf( domEvent, true, false );
	}

	onInputMousemove( domEvent ) {
		// this is solely to prevent drag events on table level that might cause
		// adding a new row
		Warner.traceIf( DO_LOG );
		return DomEventHelper.stopIf( domEvent, true, false );
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 */
	onInputEnter( domEvent ) {
		const cell = this.cell;
		this.informAboutContentChange();
		this.destroySelfAndRestoreCell();
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputEnter ) ) {
			return false;
		}
		return cell.onInputEnter( domEvent );
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 */
	onInputTab( domEvent ) {
		Warner.traceIf( DO_LOG );
		const cell = this.cell;
		this.informAboutContentChange();
		this.destroySelfAndRestoreCell();
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputTab ) ) {
			return false;
		}
		return cell.onInputTab( domEvent );
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 */
	onInputEscape( domEvent ) {
		Warner.traceIf( DO_LOG );
		const cell = this.cell;
		if ( this.somethingChanged ) {
			this.informAboutCancel();
		}
		this.destroySelfAndRestoreCell();
		if ( !Validator.isObject( cell ) ||
			!Validator.isFunction( cell.onInputEscape ) ) {
			return false;
		}
		return cell.onInputEscape( domEvent );
	}

	/**
	 * this method needs to be defined on the prototype of the object and not on
	 * any of its extensions, because a subclass is overwriting this method and
	 * using a reference to this prototype (super)
	 */
	onVerticalArrowKeyDown( domEvent ) {
		const row = this.row;
		this.informAboutContentChange();
		this.destroySelfAndRestoreCell();
		if ( domEvent instanceof KeyboardEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		if ( Validator.isObject( row ) ) {
			row.syncFocus();
			row.onVerticalArrowKeyDown( domEvent,
				DomEventHelper.keyIs( domEvent, "ArrowUp" ) );
		}
	}

	saveAllAndKeepFocus( domEvent ) {
		if ( !this.dirty || this.originalValue === this.inputValue || !this.editingAllowed ) {
			return false;
		}
		Warner.traceIf( DO_LOG );
		this.informAboutFullSave();
		this.destroySelfAndRestoreCell();
		return true;
	}

}
