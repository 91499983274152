import Base from '../base/base';
import PSA from '../psa';
import FileDropHandler from './FileDropHandler';
import FSItem from './FSItem';

/** flag whether DataTransferItem.webkitGetAsEntry() is available */
let HAS_GETASENTRY = false;

(function init() {
	HAS_GETASENTRY = (typeof DataTransferItem.prototype.webkitGetAsEntry === 'function');
	console.log(`FILEDROP: Availability of "DataTransferItem.prototype.webkitGetAsEntry" = ${HAS_GETASENTRY}.`);
}());

/**
 * a file drop target class
 */
export default class FileDropTarget extends Base {

	/**
	 * constructs a new instance
	 * @param {HTMLElement} element the target DOM element
	 * @param {FileDropHandler} handler the file drop handler
	 */
	constructor(element, handler) {
		super();
		const psa = PSA.getInst();
		this.element = element;
		this.handler = handler;
		this.lsrDrgOvr = psa.bind(this, this._onDragOver);
		this.lsrDrop = psa.bind(this, this._onDrop);
		this.element.addEventListener('dragover', this.lsrDrgOvr, true);
		this.element.addEventListener('drop', this.lsrDrop, true);
	}

	/**
	 * destructor method
	 * @override
	 */
	doDestroy() {
		if ( this.element ) {
			const elm = this.element;
			this.element = null;
			elm.removeEventListener('dragover', this.lsrDrgOvr, true);
			elm.removeEventListener('drop', this.lsrDrop, true);
		}
		delete this.lsrDrgOvr;
		delete this.lsrDrop;
		delete this.handler;
		delete this.element;
		super.doDestroy();
	}

	/**
	 * drag over listener
	 * @param {DragEvent} e drag'n'drop event
	 */
	_onDragOver(e) {
		const dtf = e.dataTransfer;
		const files = this._getFiles(e, false, false);
		if ( files.length > 0 ) {
			e.preventDefault();
			if ( dtf ) {
				dtf.effectAllowed = 'copy';
				dtf.dropEffect = 'copy';			// that's the important thing for Outlook! Exactly here!
			}
		}
	}

	/**
	 * drop listener
	 * @param {DragEvent} e drag'n'drop event
	 */
	_onDrop(e) {
		const dtf = e.dataTransfer;
		const files = this._getFiles(e, true, true);
		if ( files.length > 0 ) {
			e.preventDefault();
			if ( dtf ) {
				dtf.dropEffect = 'copy';
			}
			let dc = 0;
			for ( let i=0 ; i < files.length ; ++i ) {
				const fi = files[i];
				if ( fi.isFSEntry ) {
					const fse = fi.fsItem;
					if ( fse.isDirectory ) {
						++dc;
					}
				}
			}
			if ( dc == files.length ) {
				// only directories dropped
				e.stopPropagation();
			}
			this.handler.handleDrop(files);
		}
	}

	/**
	 * retrieves a list of dragged files from a drag'n'drop event
	 * @param {DragEvent} e drag'n'drop event
	 * @param {Boolean} ent flag whether to retrieve FileSystemEntries (if possible)
	 * @param {Boolean} rpt flag whether to report missing transfer data
	 * @returns {FSItem[]} an array of files provided by this drag'n'drop operation
	 */
	_getFiles(e, ent, rpt) {
		const files = [];
		const dtf = e.dataTransfer;
		if ( dtf ) {
			if ( ent && HAS_GETASENTRY ) {
				for ( let i=0 ; i < dtf.items.length ; ++i ) {
					const entry = dtf.items[i].webkitGetAsEntry();
					if ( entry && (entry.isFile || entry.isDirectory) ) {
						files.push(new FSItem(true, entry));
					}
				}
			} else {
				// go the standard way
				if ( dtf.files.length > 0 ) {
					for ( let i=0 ; i < dtf.files.length ; ++i ) {
						files.push(new FSItem(false, dtf.files[i]));
					}
				} else if ( dtf.items.length > 0 ) {
					for ( let i=0 ; i < dtf.items.length ; ++i ) {
						const item = dtf.items[i];
						if ( item.kind === 'file' ) {
							files.push(new FSItem(false, item.getAsFile()));
						}
					}
				}
			}
		} else if ( rpt ) {
			this.handler.showMessage('Drag&drop event without any data!');
		}
		return files;
	}
}

console.log('dnd/FileDropTarget.js loaded.');