import Validator from '../utils/Validator';

/** default RGB value */
const DEFAULT_RGB = [0, 0, 0];
/** default color value */
const DEFAULT_CLR = "000000";

let singleton = null;

/** UI utility class */
export default class UIUtil {

	/** constructs a new instance */
	constructor() {
		if ( singleton === null ) {
			singleton = this;
		} else {
			throw new Error('Do not create an instance of UIUtil! Use the singleton instead.');
		}
		this._ckeColorList = this._iniClr();
	}
	
	/**
	 * returns the singleton instance
	 * @returns {UIUtil} the singleton instance
	 */
	static getInstance() {
		return singleton;
	}

	/**
	 * returns a string suitable as CKEditor's "color list" configuration value
	 * @returns {String} a string providing a color list for CKEditor's color picker
	 */
	getClrLst () {
		return this._ckeColorList;
	}

	/**
	 * creates a CSS color descriptor
	 * @param {Array|Object} clr the color specification; either an array or an object
	 * @returns {String} the RGB descriptor
	 */
	getCssRgb( clr ) {
		if ( !clr || ( typeof clr === "string" ) ) {
			return null;
		}
		let rgb = null;
		let r = null;
		let g = null;
		let b = null;
		let a = null;
		if ( clr instanceof Array ) {
			r = clr[0];
			g = clr[1];
			b = clr[2];
			if ( clr.length > 3 ) {
				a = clr[3];
			}
		} else {
			r = clr.red;
			g = clr.green;
			b = clr.blue;
		}
		if ( ( typeof r === "number" ) && ( typeof g === "number" ) && ( typeof b === "number" ) ) {
			if ( ( typeof a === "number" ) && ( a < 255 ) ) {
				const alpha = ( a * 10.0 ) / 2550.0;
				rgb = 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
			} else {
				rgb = 'rgb(' + r + ',' + g + ',' + b + ')';
			}
		}
		return rgb;
	}

	/**
	 * parses a color string
	 * @param {String} str the color string
	 * @returns {Array} an array providing the RGB value
	 */
	parseClrStr( str ) {
		if ( !Validator.isString( str ) ) {
			return DEFAULT_RGB;
		}
		let eff_str = str;
		if ( str.charAt( 0 ) === '#' ) {
			eff_str = str.substring( 1 );
		}
		if ( eff_str.length === 3 || eff_str.length === 6 ) {
			if ( eff_str.length === 3 ) {
				let dup = '';
				for ( let i = 0; i < 3; ++i ) {
					let c = eff_str.charAt( i );
					dup += c;
					dup += c;
				}
				eff_str = dup;
			}
			let rgb = [];
			try {
				for ( let i = 0; i < 3; ++i ) {
					rgb.push( Number.parseInt( eff_str.substr( 2 * i, 2 ), 16 ) );
				}
			}
			catch ( e ) {
				rgb = DEFAULT_RGB;
			}
			return rgb;
		}
		return DEFAULT_RGB;
	}

	/**
	 * creates a string from a RGB value
	 * @param {Array} rgb the RGB value array
	 * @returns {String} the corresponding string value
	 */
	rgb2str( rgb ) {
		if ( !( rgb instanceof Array ) || ( rgb.length < 3 ) ) {
			return DEFAULT_CLR;
		}
		let res = '';
		for ( let i = 0; i < 3; ++i ) {
			let s = rgb[i].toString( 16 );
			s = s.length == 0 ? "00" :
				s.length == 1 ? "0" + s :
					s.length == 2 ? s :
						s.substring( s.length - 2, s.length );
			res += s;
		}
		return res;
	}

	/**
	 * converts a RGB color value into a HSB color value
	 * @param {Array<Number>} rgb an array providing the numbers for the Red, Green and Blue values
	 * @returns {Array<Number>} an array containing the matching Hue (0 - 360°), Saturation and Brightness ("Value") values
	 */
	rgb2hsb360( rgb ) {
		// algorithm taken from http://www.javascripter.net/faq/rgb2hsv.htm
		if ( !( rgb instanceof Array ) || ( rgb.length < 3 ) ) {
			return DEFAULT_RGB;
		}
		let hue = 0;
		let sat = 0;
		let brt = 0;
		let red = rgb[0];
		let grn = rgb[1];
		let blu = rgb[2];
		if ( red < 0 || grn < 0 || blu < 0 || red > 255 || grn > 255 || blu > 255 ) {
			return DEFAULT_RGB;
		}
		red = red / 255;
		grn = grn / 255;
		blu = blu / 255;
		let minRGB = Math.min( red, Math.min( grn, blu ) );
		let maxRGB = Math.max( red, Math.max( grn, blu ) );
		// Black-gray-white
		if ( minRGB == maxRGB ) {
			brt = minRGB;
			return [0, 0, brt];
		}
		// Colors other than black-gray-white:
		let d = ( red == minRGB ) ? grn - blu : ( ( blu == minRGB ) ? red - grn : blu - red );
		let z = ( red == minRGB ) ? 3 : ( ( blu == minRGB ) ? 1 : 5 );
		hue = 60 * ( z - d / ( maxRGB - minRGB ) );
		sat = ( maxRGB - minRGB ) / maxRGB;
		brt = maxRGB;
		return [hue, sat, brt];
	}

	/**
	 * converts a RGB color value into a HSV color value
	 * @param {Array<Numer>} rgb an array providing the numbers for the Red, Green and Blue values in the range [0, 255]
	 * @returns {Array<Number>} an array containing the matching Hue, Saturation and Brightness ("Value") values, all in the range [0.0, 1.0]
	 */
	rgb2hsv( rgb ) {
		// see https://gist.github.com/mjackson/5311256
		// https://de.wikipedia.org/wiki/HSV-Farbraum#Umrechnung_RGB_in_HSV/HSL
		if ( !( rgb instanceof Array ) || ( rgb.length < 3 ) ) {
			return DEFAULT_RGB;
		}
		const r = rgb[0] / 255;
		const g = rgb[1] / 255;
		const b = rgb[2] / 255;
		const max = Math.max( r, g, b );
		const min = Math.min( r, g, b );
		const d = max - min;
		const s = max == 0 ? 0 : d / max;
		const v = max;
		let h = 0;
		if ( max == min ) {
			h = 0; // achromatic
		} else {
			switch ( max ) {
				case r:
					h = ( g - b ) / d + ( g < b ? 6 : 0 );
					break;
				case g:
					h = ( b - r ) / d + 2;
					break;
				case b:
					h = ( r - g ) / d + 4;
					break;
			}
			h /= 6;
		}
		return [h, s, v];
	}

	/**
	 * converst a color from HSV color space to RGB color space
	 * @param {Array<Number>} hsv color value in Hue, Saturation and Brightness ("Value")
	 * @returns {Array<Number>} an array with the corresponding RGB values
	 */
	hsv2rgb( hsv ) {
		// see https://de.wikipedia.org/wiki/HSV-Farbraum#Umrechnung_RGB_in_HSV/HSL
		if ( !( hsv instanceof Array ) || ( hsv.length < 3 ) ) {
			return DEFAULT_RGB;
		}
		const h = hsv[0];
		const s = hsv[1];
		const v = hsv[2];
		if ( h < 0 || s < 0 || v < 0 || h > 1 || s > 1 || v > 1 ) {
			return DEFAULT_RGB;
		}
		let r, g, b;
		if ( s == 0 ) {
			r = g = b = v;
		} else {
			const hi = Math.floor( h * 6 );
			const f = h * 6 - hi;
			const p = v * ( 1 - s );
			const q = v * ( 1 - s * f );
			const t = v * ( 1 - s * ( 1 - f ) );
			switch ( hi ) {
				default:
					// 0 or 6
					r = v; g = t; b = p;
					break;
				case 1:
					r = q; g = v; b = p;
					break;
				case 2:
					r = p; g = v; b = t;
					break;
				case 3:
					r = p; g = q; b = v;
					break;
				case 4:
					r = t; g = p; b = v;
					break;
				case 5:
					r = v; g = p; b = q;
					break;
			}
		}
		// scale to RGB range
		r = Math.round(r * 255);
		g = Math.round(g * 255);
		b = Math.round(b * 255);
		// done
		return [r, g, b];
	}

	/**
	 * converts a RGB color value into a HSL color value
	 * @param {Array<Number>} rgb an array providing the numbers for the Red, Green and Blue values
	 * @returns {Array<Number>} an array containing the matching Hue (0.0 - 1.0), Saturation and Lightness values
	 */
	rgb2hsl( rgb ) {
		// see https://gist.github.com/mjackson/5311256
		if ( !( rgb instanceof Array ) || ( rgb.length < 3 ) ) {
			return DEFAULT_CLR;
		}
		let r = rgb[0] / 255;
		let g = rgb[1] / 255;
		let b = rgb[2] / 255;

		let max = Math.max( r, g, b ), min = Math.min( r, g, b );
		let h, s, l = ( max + min ) / 2;

		if ( max == min ) {
			h = s = 0; // achromatic
		} else {
			let d = max - min;
			s = l > 0.5 ? d / ( 2 - max - min ) : d / ( max + min );
			switch ( max ) {
				case r:
					h = ( g - b ) / d + ( g < b ? 6 : 0 );
					break;
				case g:
					h = ( b - r ) / d + 2;
					break;
				case b:
					h = ( r - g ) / d + 4;
					break;
			}
			h /= 6;
		}
		return [h, s, l];
	}

	/**
	 * calculates the luminosity of a RGB color value
	 * @param {Array<Number>} rgb an array providing the numbers for the Red, Green and Blue values
	 * @returns {Number} the luminosity value (0.0 - 1.0)
	 */
	rgb2lum( rgb ) {
		// see also https://www.alanzucconi.com/2015/09/30/colour-sorting/
		if ( !( rgb instanceof Array ) || ( rgb.length < 3 ) ) {
			return 0;
		}
		let red = rgb[0];
		let grn = rgb[1];
		let blu = rgb[2];
		if ( red < 0 || grn < 0 || blu < 0 || red > 255 || grn > 255 || blu > 255 ) {
			return 0;
		}
		red = red / 255;
		grn = grn / 255;
		blu = blu / 255;
		return Math.sqrt( 0.241 * red + 0.691 * grn + 0.068 * blu );
	}

	/**
	 * initializes the color list for the CKE 5 editor
	 * @returns {String} a string containing the color list
	 */
	 _iniClr() {
		const self = this;
		// B/W: from black via shades of grey to white
		let gry_str = '000000,606060,808080,a0a0a0,c0c0c0,d0d0d0,e0e0e0,ffffff';
		// our colors
		let clr_str = '';
		// default web colors
		clr_str += 'ff0000,c00000,800000,ffff00,c0c000,808000,00ff00,00c000,';
		clr_str += '008000,00ffff,00c0c0,008080,0000ff,0000c0,000080,ff00ff,';
		clr_str += 'c000c0,800080,';
		// material.io colors
		// "500"
		clr_str += 'F44336,E91E63,9C27B0,673AB7,3F51B5,2196F3,03A9F4,00BCD4,009688,4CAF50,8BC34A,CDDC39,FFEB3B,FFC107,FF9800,FF5722,795548,607D8B,';
		// "100"
		clr_str += 'FFCDD2,F8BBD0,E1BEE7,D1C4E9,C5CAE9,BBDEFB,B3E5FC,B2EBF2,B2DFDB,C8E6C9,DCEDC8,F0F4C3,FFF9C4,FFECB3,FFE0B2,FFCCBC,D7CCC8,CFD8DC,';
		// "900"
		clr_str += 'B71C1C,880E4F,4A148C,311B92,1A237E,0D47A1,01579B,006064,004D40,1B5E20,33691E,827717,F57F17,FF6F00,E65100,BF360C,3E2723,263238';

		// create a list of HSL values
		let hsl_lst = [];
		let clr_lst = clr_str.split( "," );
		clr_lst.forEach( function ( str ) {
			let rgb = self.parseClrStr( str );
			let hsl = self.rgb2hsl( rgb );
			let itm = {};
			itm.rgb = rgb;
			itm.hsl = hsl;
			hsl_lst.push( itm );
		} );
		let FCT = 10;

		// sort that list by Hue (primary) and brightness (secondary)
		let hsv_cmp = function ( i1, i2 ) {
			let h1 = Math.round( FCT * i1.hsl[0] );
			let h2 = Math.round( FCT * i2.hsl[0] );
			let d = h1 - h2;
			if ( d === 0 ) {
				let l1 = Math.round( FCT * self.rgb2lum( i1.rgb ) );
				let l2 = Math.round( FCT * self.rgb2lum( i2.rgb ) );
				d = l1 - l2;
				if ( d === 0 ) {
					d = i1.hsl[1] - i2.hsl[1];
				}
			}
			return d;
		}
		hsl_lst.sort( hsv_cmp );

		// create result string
		let res = gry_str;
		hsl_lst.forEach( function ( itm ) {
			let str = self.rgb2str( itm.rgb );
			if ( res.length > 0 ) {
				res += ',';
			}
			res += str;
		} );
		// done
		return res;
	}
}	

singleton = new UIUtil();

console.log('gui/uiutils.js loaded.');