import AttachmentObject from './AttachmentObject';
import Validator from './Validator';
import Warner from './Warner';

export default class ExternalEventsTransferManager extends AttachmentObject {

	constructor( hostPlugin, parentElement ) {
		super( hostPlugin );
		hostPlugin.decorateParentElement( parentElement );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		hostPlugin.constructor = void 0;
		delete hostPlugin.constructor;
	}

	decorateParentElement( parentElement ) {
		if ( !( parentElement instanceof HTMLElement ) ) {
			Warner.traceIf( true, `Could not add a method to transfer DOM` +
				` events to the parent element of the widget, because the parent` +
				` element does not exist.` );
			return false;
		}
		const self = this;
		if ( Validator.isFunction( parentElement._transferDomEvent ) ) {
			Warner.traceIf( true, `The DOM parent element of the widget already` +
				` has a method that transfers DOM events. The function will be` +
				` overriden with a new one that accesses the current widget.` );
		}
		parentElement._transferDomEvent = ( evt ) => {
			if ( !( evt instanceof Event ) ) {
				Warner.traceIf( true, `Could not transfer an invalid DOM event to` +
					` the widget.` );
				return false;
			}
			if ( !Validator.isObject( self ) ) {
				Warner.traceIf( true, `There is no widget to transfer the DOM event to.` );
				return false;
			}
			if ( !Validator.isFunction( self.handleTransferredEvent ) ) {
				Warner.traceIf( true, `The DOM event could not be transferred to` +
					` the corresponding widget, because the widget does not have a` +
					` method to handle transferred DOM events.` );
				return false;
			}
			return self.handleTransferredEvent( evt );
		}
	}
}

console.log( 'utils/ExternalEventsTransferManager.js loaded.' );
