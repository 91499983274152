import PSA from '../../../psa';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import EventListenerManager from '../../../utils/EventListenerManager';

let PISASALES = void 0;

const DO_LOG = false;

/**
 * generic UI item
 */
export default class XItem {

	/**
	 * constructs a new instance
	 */
	constructor() {
		if ( !Validator.isObject( PISASALES ) ) {
			PISASALES = PSA.getInst();
		}
		// this.dbgMode = pisasales.isDbgMode();
		this.dbgMode = PISASALES.isDbgMode();
		this.element = document.createElement( 'div' );
	}

	/**
	 * destructor method
	 */
	destroy() {
		this.removeClickListener();
		// pisasales.rmvDomElm( this.element );
		PISASALES.rmvDomElm( this.element );
		delete this.element;
		delete this.dbgMode;
	}

	/**
	 * @returns {Boolean} true if DEBUG mode is active
	 */
	get isDbgMode() {
		return this.dbgMode;
	}

	/**
	 * returns the main element of this UI item
	 * @returns {HTMLElement} the DOM element of this UI item
	 */
	getDomElement() {
		return this.element;
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when this
	 * item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	/**
	 * dummy method
	 */
	onClick( evt, parameters = void 0 ) {}

	/**
	 * adds a event listener to this item's HTML element, if such exists
	 * @see XtwRtpItm.js~EventListenerManager
	 */
	addListener( eventName, functionName, element = void 0, useCapture = false ) {
		const message = `Could not add a "${ eventName }" event listener to the` +
			` XItem's element.`;
		const successfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: eventName,
			functionName: functionName,
			element: element,
			useCapture: useCapture
		} );
		if ( successfullyAdded ) {
			return true;
		}
		Warner.traceIf( DO_LOG, message );
		return false;
	}

	/**
	 * removes an event listener from this item's HTML element if the
	 * element is valid/rendered and the event listener exists (was previously
	 * added)
	 * @see XtwRtpItm.js~EventListenerManager
	 */
	removeListener( eventName, element = void 0 ) {
		const message = `Could not remove the "${ eventName }" event listener` +
			` from the XItem's element.`;
		const successfullyRemoved = EventListenerManager
			.removeListener( this, eventName, element );
		if ( successfullyRemoved ) {
			return true;
		}
		Warner.traceIf( DO_LOG, message );
		return false;
	}

	/**
	 * adds a "click" event listener to this item's HTML element, if such exists
	 * @see XtwRtpItm.js~EventListenerManager
	 */
	addClickListener( element = void 0 ) {
		return this.addListener( "click", "onClick", element );
	}

	/**
	 * removes the "click" event listener from this item's HTML element if the
	 * element is valid/rendered and the event listener exists (was previously
	 * added)
	 * @see XtwRtpItm.js~EventListenerManager
	 */
	removeClickListener( element = void 0 ) {
		return this.removeListener( "click", element );
	}

}
