import Validator from './Validator';

export default class EventListenerManager {

	static getCallbackFunctionName( eventName, callBackPrefix = "" ) {
		if ( !Validator.isString( eventName ) ) return void 0;
		return "on" + eventName.charAt( 0 ).toUpperCase() + eventName.slice( 1 ) +
			( Validator.isString( callBackPrefix ) ? callBackPrefix : "" ) + "Callback";
	}

	static addListener( {
		instance,
		eventName,
		functionName,
		element = void 0,
		debounceTimeout,
		overrideCallback = true,
		callBackPrefix = "",
		useCapture = false
	} ) {
		if ( !Validator.isObject( window.pisasales ) ||
			!Validator.isFunction( window.pisasales.bind ) ||
			!Validator.isString( eventName ) || eventName.length < 2 ||
			!Validator.isObject( instance ) ||
			!Validator.isString( functionName ) ||
			!Validator.isFunction( instance[ functionName ] ) ) return false;
		if ( !( element instanceof HTMLElement ) ) {
			if ( !instance.isRendered ) return false;
			element = instance.element;
		}
		if ( !Validator.isString( callBackPrefix ) ) callBackPrefix = "";
		const callbackFunctionName = EventListenerManager
			.getCallbackFunctionName( eventName, callBackPrefix );
		if ( overrideCallback &&
			Validator.isFunction( instance[ callbackFunctionName ] ) ) return false;
		instance[ callbackFunctionName ] = window.pisasales.bind( instance,
			instance[ functionName ] );
		if ( Validator.isPositiveInteger( debounceTimeout ) &&
			Validator.isFunction( window.pisasales.debounce ) )
			instance[ callbackFunctionName ] =
			window.pisasales.debounce( instance[ callbackFunctionName ], debounceTimeout );
		element.addEventListener( eventName, instance[ callbackFunctionName ], !!useCapture );
		return true;
	}

	static removeListener( instance, eventName, element = void 0, callBackPrefix = "" ) {
		if ( !Validator.isObject( instance ) ) return false;
		if ( !( element instanceof HTMLElement ) ) {
			if ( !instance.isRendered ) return false;
			element = instance.element;
		}
		if ( !Validator.isString( callBackPrefix ) ) callBackPrefix = "";
		const callbackFunctionName = EventListenerManager
			.getCallbackFunctionName( eventName, callBackPrefix );
		if ( !Validator.isString( callbackFunctionName ) ||
			!Validator.isFunction( instance[ callbackFunctionName ] ) ) return false;
		element.removeEventListener( eventName, instance[ callbackFunctionName ] );
		instance[ callbackFunctionName ] = void 0;
		delete instance[ callbackFunctionName ];
		return true;
	}

}

console.log( 'utils/EventListenerManager.js loaded.' );
